<div class="row align-items-center p-3">
  <div class="col-auto">
    <h3 class="m-0">
      <span class="is-bold">{{"PlannedMeetings.PlannedMeetings" | translate}}</span>
    </h3>
  </div>
  <div class="col">
    <button class="btn btn-app app-primary float-end me-2" type="submit" (click)="exportPlannedMeetings()">
      <fa-icon [icon]="['fas', 'file-csv']" class="me-2"></fa-icon>{{"PlannedMeetings.ExportPlannedMeetings" | translate }}
    </button>
  </div>
</div>
<div class="row">
  <div [ngClass]="{'col-12': !selectedPlannedMeetingId, 'col-8': selectedPlannedMeetingId}">
    <app-admin-card>
      <div class="row mb-2">
        <div class="col-12">
          <app-calendar-picker-double [from]="from" [to]="to" [labelFrom]="'PlannedMeetings.StartDate'" [labelTo]="'PlannedMeetings.EndDate'"></app-calendar-picker-double>
        </div>
      </div>
      <app-mat-table-custom *ngIf="displayedColumns && !refreshTable && plannedMeetingsService" [hoverable]="false" [selectedRowItem]="'id'" [selectedRowId]="selectedPlannedMeetingId" [componentName]="'PlannedMeetings'" [displayedColumns]="displayedColumns" [dataService]="plannedMeetingsService" [defaultSort]="defaultSort" (dataError)="onDataError($event)" (actionClick)="onActionClick($event)">
      </app-mat-table-custom>
    </app-admin-card>
  </div>
  <div class="col-4" *ngIf="selectedPlannedMeetingId">
    <router-outlet (deactivate)="componentEditPlannedMeetingRemoved()"></router-outlet>
  </div>
</div>
<div class="modal fade custom-app-modal" id="modalOnDeletePlannedMeetings" role="dialog" aria-labelledby="modalOnDeletePlannedMeetingsLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content flat">
      <div class="modal-header">
        <h3 class="modal-title text-center" id="modalOnDeletePlannedMeetingsLabel">{{"PlannedMeetings.Delete" |
          translate}}</h3>
      </div>
      <div class="modal-body">
        <div class="is-bold">{{"PlannedMeetings.SureDeleteMeeting" | translate}}</div>
        <div class="mb-3" *ngIf="selectedPlannedMeeting">{{selectedPlannedMeeting.title}}</div>
        <div class="dual-button-container">
          <button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal">
            {{"PlannedMeetings.Cancel" | translate}}
          </button>
          <button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="confirmDeletePlannedMeeting()">
            {{"PlannedMeetings.Confirm" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>