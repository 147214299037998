import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { MeetingRoom } from '../meeting-room.model';
import { MeetingRoomsService } from '../meeting-rooms.service';

@Component({
  selector: 'app-edit-meeting-room',
  templateUrl: './edit-meeting-room.component.html',
  styleUrls: ['./edit-meeting-room.component.scss']
})
export class EditMeetingRoomComponent implements OnInit, OnDestroy {

  currentMeetingRoom: MeetingRoom;
  hasError = false;
  finished = false;
  paramSubscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute, private meetingRoomsService: MeetingRoomsService, private notifService: NotifService) { }

  ngOnInit(): void {
    this.paramSubscription = this.route.paramMap.subscribe(params => {
      let meetingRoomId = params.get('meetingRoomId');
      if (meetingRoomId) {
        this.getMeetingRoom(meetingRoomId);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  getMeetingRoom(meetingRoomId: string): void {
    this.meetingRoomsService.getMeetingRoom(meetingRoomId).subscribe(result => {
      this.currentMeetingRoom = result;
    })
  }

  confirmEditMeetingRoom(editMeetingRoomForm: NgForm, editMeetingRoomFormData: MeetingRoom): void {
    if (editMeetingRoomForm.valid) {
      this.currentMeetingRoom.name = editMeetingRoomFormData.name;
      this.currentMeetingRoom.description = editMeetingRoomFormData.description;
      this.hasError = false;
      this.meetingRoomsService.editMeetingRoom(this.currentMeetingRoom.id, this.currentMeetingRoom).subscribe({
        next: (result) => {
          this.finished = true;
          this.notifService.addSuccessNotif('EditMeetingRoom.SuccessEditMeetingRoom');
          this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: (error) => {
          this.finished = true;
          this.hasError = true;
          this.notifService.addErrorNotif('EditMeetingRoom.ErrorEditMeetingRoom', error);
        }
      })
    } else {
      this.hasError = true;
    }
  }

}
