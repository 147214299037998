import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { SharedService } from '../../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class MultiRoleGuardGuard implements CanActivate, CanActivateChild {

  constructor(private sharedService: SharedService) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const acls = route.data.acls;
    const tenantId = state.url.split('/')[2];
    return this.sharedService._connectedUser().pipe(
      switchMap((user: ConnectedUser) => {
        if (!user) {
          return of(false);
        } else {
          if (user.hasRole('SUPER_ADMIN') || user.hasRole('ADMIN') || user.hasRole('MARKETING')) {
            if (state.url.includes('/management/trials') && user.isGranted(user.tenantId, 'TRIALS')) {
              return of(true);
            } else if (user.isMultipleGranted(tenantId, acls)) {
              return of(true);
            } else {
              return of(false);
            }
          } else {
            return of(false);
          }
        }
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const acls = childRoute.data.acls;
    const tenantId = state.url.split('/')[2];
    return this.sharedService._connectedUser().pipe(
      switchMap((user: ConnectedUser) => {
        if (!user) {
          return of(false);
        } else {
          if (user.hasRole('SUPER_ADMIN') || user.hasRole('ADMIN') || user.hasRole('MARKETING')) {
            if (state.url.includes('/management/trials') && user.isGranted(user.tenantId, 'TRIALS')) {
              return of(true);
            } else if (user.isMultipleGranted(tenantId, acls)) {
              return of(true);
            } else {
              return of(false);
            }
          } else {
            return of(false);
          }
        }
      })
    );
  }

}
