<ng-container *ngIf="connectedUser && (tenant$ | async) as tenant">
    <div class="p-3">
        <h3 class="is-bold">
            {{"SyncTenantComponent.Title" | translate}}: <span class="is-primary"> {{tenant.name | uppercase}}</span>
        </h3>
    </div>
    <app-admin-card>
        <div *ngIf="!showLoaderSync && !syncDone">
            <div class="row">
                <div class="col-12 self-centered solo-item-container" style="height: 200px">
                    <button type="button" class="btn btn-app app-primary btn-big"
                    (click)="getSyncStatus(tenant);">{{"SyncTenantComponent.Button.Sync" | translate}}</button>
                </div>
            </div>
        </div>
        <div *ngIf="!showLoaderSync && syncDone">
            <div class="row">
                <div class="col-10">
                    <h2 *ngIf="!showDetails">{{"SyncTenantComponent.Result" | translate}}</h2>
                    <h2 *ngIf="showDetails">{{"SyncTenantComponent.Details" | translate}}</h2>
                </div>
                <div class="col-2 self-centered" *ngIf="!showLoaderSync">
                    <button type="button" class="btn btn-app app-primary" (click)="syncTenant(tenant)">{{"SyncTenantComponent.Button.Resync" | translate}}</button>
                </div>
            </div>
            <div class="row" *ngIf="!showLoaderSync && !gotErrorSync">
                <div class="col-1"></div>
                <div class="col-4">{{"SyncTenantComponent.NbGroups" | translate}}</div>
                <div class="col-4">
                    {{syncedGroups?.length}}
                    <button type="button" class="btn btn-app app-primary ms-3" (click)="showDetails = true;"
                    *ngIf="!showDetails">{{"SyncTenantComponent.ShowDetails" | translate}}</button>
                    <button type="button" class="btn btn-app app-primary ms-3"
                    (click)="showDetails = false;" *ngIf="showDetails">{{"SyncTenantComponent.HideDetails" | translate}}</button>
                </div>
                <div class="col-3"></div>
            </div>
            <div class="row mt-5" *ngIf="!showLoaderSync && !gotErrorSync && showDetails">
                <div class="col-12">
                    <div *ngFor="let sync of syncedGroups">
                        <div class="sync-info sync-info-parent">
                            <div class="sync-info-title collapsed pointer" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#target_'+sync.id" aria-expanded="false"
                            [attr.aria-controls]="'target_'+sync.id">
                                <div class="d-inline-block is-regular" *ngIf="sync.group">{{sync.group.name}}</div>
                                <fa-icon [icon]="['fas', 'eye']" class="pointer float-end"></fa-icon>
                            </div>
                            <div class="collapse sync-info-body" id="target_{{sync.id}}">
                                <div class="is-regular" *ngIf="sync.error">
                                    {{"SyncTenantComponent.Errors.Title" | translate}} : <i>{{"SyncTenantComponent.Errors."+sync.error | translate}}</i>
                                    <div *ngIf="sync.error !== 'UNKNOWN'">
                                        <div *ngIf="sync.error === 'UNAUTHENTICATED' || sync.error === 'MISSING_CONSENT'">
                                            {{"SyncTenantComponent.Errors." + sync.error + "_details" | translate}}</div>
                                        <div *ngIf="sync.error === 'NOT_FOUND'">{{sync.error + "_details" | translate}}
                                        </div>
                                    </div>
                                    <div *ngIf="sync.error === 'UNKNOWN'">{{"SyncTenantComponent.Details" | translate}} : {{sync.errorDetails}}
                                    </div>
                                </div>
                                <div *ngIf="!sync.error">
                                    <div *ngIf="sync.newUsers" class="padding-bottom-10">
                                        <div class="row" *ngIf="sync.newUsers?.length === 0">
                                            <div class="col-6">{{"SyncTenantComponent.NbNewUsers" | translate}}</div>
                                            <div class="col-6">{{sync.newUsers?.length}}</div>
                                        </div>
                                        <div *ngIf="sync.newUsers && sync.newUsers.length > 0">
                                            <div class="sync-info">
                                                <div class="sync-info-title collapsed pointer" data-bs-toggle="collapse"
                                                [attr.data-bs-target]="'#target_'+sync.id+'_newUsers'" aria-expanded="false"
                                                [attr.aria-controls]="'target_'+sync.id+'_newUsers'">
                                                    <div class="d-inline-block is-regular" *ngIf="sync.group">
                                                        {{"SyncTenantComponent.NbNewUsers" | translate}} : {{sync.newUsers?.length}}</div>
                                                    <fa-icon [icon]="['fas', 'eye']" class="pointer float-end"></fa-icon>
                                                </div>
                                                <div class="collapse sync-info-body" id="target_{{sync.id}}_newUsers">
                                                    <div *ngFor="let newUser of sync.newUsers; let isLast = last;">
                                                        <div>
                                                            <span *ngIf="!newUser.email || newUser.email === ''"><i>{{"SyncTenantComponent.NoEmail"
                                                                    | translate}}</i>, </span>
                                                            <span>{{newUser.email}}, </span>
                                                            <span>{{newUser.getDisplayName()}}, </span>
                                                            <span *ngIf="!newUser.phone || newUser.phone === ''"><i>{{"SyncTenantComponent.NoPhone"
                                                                    | translate}}</i></span>
                                                            <span>{{newUser.phone}}</span>
                                                        </div>
                                                        <hr *ngIf="!isLast" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="sync.updatedUsers" class="padding-bottom-10">
                                        <div class="row" *ngIf="sync.updatedUsers?.length === 0">
                                            <div class="col-6">{{"SyncTenantComponent.NbUpdatedUsers" | translate}}</div>
                                            <div class="col-6">{{sync.updatedUsers?.length}}</div>
                                        </div>
                                        <div *ngIf="sync.updatedUsers && sync.updatedUsers.length > 0">
                                            <div class="sync-info">
                                                <div class="sync-info-title collapsed pointer" data-bs-toggle="collapse"
                                                [attr.data-bs-target]="'#target_'+sync.id+'_updatedUsers'" aria-expanded="false"
                                                [attr.aria-controls]="'target_'+sync.id+'_updatedUsers'">
                                                    <div class="d-inline-block is-regular" *ngIf="sync.group">
                                                        {{"SyncTenantComponent.NbUpdatedUsers" | translate}} : {{sync.updatedUsers?.length}}
                                                    </div>
                                                    <fa-icon [icon]="['fas', 'eye']" class="pointer float-end"></fa-icon>
                                                </div>
                                                <div class="collapse sync-info-body" id="target_{{sync.id}}_updatedUsers">
                                                    <div *ngFor="let updatedUser of sync.updatedUsers; let isLast = last;">
                                                        <div>
                                                            <span
                                                            *ngIf="!updatedUser.user.email || updatedUser.user.email === ''"><i>{{"SyncTenantComponent.NoEmail" | translate}}</i>, </span>
                                                            <span>{{updatedUser.user.email}}, </span>
                                                            <span>{{updatedUser.user.getDisplayName()}}, </span>
                                                            <span
                                                            *ngIf="!updatedUser.user.phone || updatedUser.user.phone === ''"><i>{{"SyncTenantComponent.NoPhone" | translate}}</i></span>
                                                            <span>{{updatedUser.user.phone}}</span> <br />
                                                            {{"SyncTenantComponent.Details" | translate}} : <br />
                                                            <div *ngFor="let details of updatedUser.fields">{{details.key}} :
                                                                {{details.old}} -> {{details.new}}</div>
                                                        </div>
                                                        <hr *ngIf="!isLast" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="sync.deletedUsers" class="padding-bottom-10">
                                        <div class="row" *ngIf="sync.deletedUsers?.length === 0">
                                            <div class="col-6">{{"SyncTenantComponent.NbDeletedUsers" | translate}}</div>
                                            <div class="col-6">{{sync.deletedUsers?.length}}</div>
                                        </div>
                                        <div *ngIf="sync.deletedUsers && sync.deletedUsers.length > 0">
                                            <div class="sync-info">
                                                <div class="sync-info-title collapsed pointer" data-bs-toggle="collapse"
                                                [attr.data-bs-target]="'#target_'+sync.id+'_deletedUsers'" aria-expanded="false"
                                                [attr.aria-controls]="'target_'+sync.id+'_deletedUsers'">
                                                    <div class="d-inline-block is-regular" *ngIf="sync.group">
                                                        {{"SyncTenantComponent.NbDeletedUsers" | translate}} : {{sync.deletedUsers?.length}}
                                                    </div>
                                                    <fa-icon [icon]="['fas', 'eye']" class="pointer float-end"></fa-icon>
                                                </div>
                                                <div class="collapse sync-info-body" id="target_{{sync.id}}_deletedUsers">
                                                    <div *ngFor="let deletedUser of sync.deletedUsers; let isLast = last;">
                                                        <div>
                                                            <span *ngIf="!deletedUser.email || deletedUser.email === ''"><i>{{"SyncTenantComponent.NoEmail" | translate}}</i>, </span>
                                                            <span>{{deletedUser.email}}, </span>
                                                            <span>{{deletedUser.getDisplayName()}}, </span>
                                                            <span *ngIf="!deletedUser.phone || deletedUser.phone === ''"><i>{{"SyncTenantComponent.NoPhone" | translate}}</i></span>
                                                            <span>{{deletedUser.phone}}</span>
                                                        </div>
                                                        <hr *ngIf="!isLast" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="sync.addedToGroup" class="padding-bottom-10">
                                        <div class="row" *ngIf="sync.addedToGroup?.length === 0">
                                            <div class="col-6">{{"SyncTenantComponent.NbAddToGroup" | translate}}</div>
                                            <div class="col-6">{{sync.addedToGroup?.length}}</div>
                                        </div>
                                        <div *ngIf="sync.addedToGroup && sync.addedToGroup.length > 0">
                                            <div class="sync-info">
                                                <div class="sync-info-title collapsed pointer" data-bs-toggle="collapse"
                                                [attr.data-bs-target]="'#target_'+sync.id+'_addedToGroup'" aria-expanded="false"
                                                [attr.aria-controls]="'target_'+sync.id+'_addedToGroup'">
                                                    <div class="d-inline-block is-regular" *ngIf="sync.group">
                                                        {{"SyncTenantComponent.NbAddToGroup" | translate}} :
                                                        {{sync.addedToGroup?.length}}</div>
                                                    <fa-icon [icon]="['fas', 'eye']" class="pointer float-end"></fa-icon>
                                                </div>
                                                <div class="collapse sync-info-body" id="target_{{sync.id}}_addedToGroup">
                                                    <div *ngFor="let addedToGroupUser of sync.addedToGroup; let isLast = last;">
                                                        <div>
                                                            <span
                                                            *ngIf="!addedToGroupUser.email || addedToGroupUser.email === ''"><i>{{"SyncTenantComponent.NoEmail" | translate}}</i>, </span>
                                                            <span>{{addedToGroupUser.email}}, </span>
                                                            <span>{{addedToGroupUser.getDisplayName()}}, </span>
                                                            <span
                                                            *ngIf="!addedToGroupUser.phone || addedToGroupUser.phone === ''"><i>{{"SyncTenantComponent.NoPhone" | translate}}</i></span>
                                                            <span>{{addedToGroupUser.phone}}</span>
                                                        </div>
                                                        <hr *ngIf="!isLast" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="sync.removedFromGroup" class="padding-bottom-10">
                                        <div class="row" *ngIf="sync.removedFromGroup?.length === 0">
                                            <div class="col-6">{{"SyncTenantComponent.NbRemoveFromGroup" | translate}}</div>
                                            <div class="col-6">{{sync.removedFromGroup?.length}}</div>
                                        </div>
                                        <div *ngIf="sync.removedFromGroup && sync.removedFromGroup.length > 0">
                                            <div class="sync-info">
                                                <div class="sync-info-title collapsed pointer" data-bs-toggle="collapse"
                                                [attr.data-bs-target]="'#target_'+sync.id+'_removedFromGroup'"
                                                aria-expanded="false"
                                                [attr.aria-controls]="'target_'+sync.id+'_removedFromGroup'">
                                                    <div class="d-inline-block is-regular" *ngIf="sync.group">
                                                        {{"SyncTenantComponent.NbRemoveFromGroup" | translate}} :
                                                        {{sync.removedFromGroup?.length}}</div>
                                                    <fa-icon [icon]="['fas', 'eye']" class="pointer float-end"></fa-icon>
                                                </div>
                                                <div class="collapse sync-info-body" id="target_{{sync.id}}_removedFromGroup">
                                                    <div
                                                    *ngFor="let removedFromGroupUser of sync.removedFromGroup; let isLast = last;">
                                                        <div>
                                                            <span
                                                            *ngIf="!removedFromGroupUser.email || removedFromGroupUser.email === ''"><i>{{"SyncTenantComponent.NoEmail" | translate}}</i>, </span>
                                                            <span>{{removedFromGroupUser.email}}, </span>
                                                            <span>{{removedFromGroupUser.getDisplayName()}}, </span>
                                                            <span
                                                            *ngIf="!removedFromGroupUser.phone || removedFromGroupUser.phone === ''"><i>{{"SyncTenantComponent.NoPhone" | translate}}</i></span>
                                                            <span>{{removedFromGroupUser.phone}}</span>
                                                        </div>
                                                        <hr *ngIf="!isLast" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="showLoaderSync">
            <div class="col-12 solo-item-container" style="height: 200px">
                <div class="center-spinner-text-meeting">
                    <app-spinner-custom [isWhite]="false"></app-spinner-custom>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!showLoaderSync && gotErrorSync">
            <div class="col-12">
                <table class="table table-bordered table-hover errorSyncTable">
                    <thead>
                        <tr>
                            <th>{{"status" | translate}}</th>
                            <th>{{"statusText" | translate}}</th>
                            <th>{{"message" | translate}}</th>
                            <th>{{"error" | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{error.status}}</td>
                            <td>{{error.statusText}}</td>
                            <td>{{error.message}}</td>
                            <td>{{error.error}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="!showLoaderSync && alreadySync">
            <div class="col-12 align-center">
                <div *ngIf="currentSync.statusData.result !== 'sync_cancelled'">
                    <h3>{{"SyncTenantComponent.AlreadySync" | translate}}</h3>
                </div>
                <div *ngIf="currentSync.statusData.result === 'sync_cancelled'">
                    <h3>{{"SyncTenantComponent.LockSync" | translate}}</h3>
                    <button type="button" class="btn btn-app app-primary" (click)="unlockSync(tenant)">{{"SyncTenantComponent.Button.Unlock" | translate}}</button>
                </div>
            </div>
        </div>

    </app-admin-card>
</ng-container>