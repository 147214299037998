<div *ngIf="!currentMeetingRoom.endpoint">
  {{"EndpointStatus.NoEndpoint" | translate}}
</div>
<ng-container *ngIf="currentMeetingRoom.endpoint">
  <ng-container *ngIf="endpointStatuses$ | async as endpointStatuses; else loading">
    <ng-container *ngIf="(currentConnectedUser$ | async) as currentConnectedUser">
      <div class="row">
        <div class="col-5">
          <app-datetime-picker-field class="form-control" [max]="to" [textLabel]="'EndpointStatus.From'" [nameInput]="'EndpointStatus.From'" [myInputModel]="from" [connectedUser]="currentConnectedUser" (dateSelect)="onFromChange($event)"></app-datetime-picker-field>
        </div>
        <div class="col-1"></div>
        <div class="col-5">
          <app-datetime-picker-field class="form-control" [min]="from" [textLabel]="'EndpointStatus.To'" [nameInput]="'EndpointStatus.To'" [myInputModel]="to" [connectedUser]="currentConnectedUser" (dateSelect)="onToChange($event)"></app-datetime-picker-field>
        </div>
        <div class="col-1"></div>
      </div>
      <div class="accordion" id="accordionEdnpointStatus">
        <div class="accordion-item" *ngFor="let endpointStatus of endpointStatuses; let i = index">
          <h2 class="accordion-header" id="{{ 'heading' + i }}">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
              <fa-icon [icon]="['fas', 'circle']" [class]="getEndpointStatusError(endpointStatus)"></fa-icon>
              <span class="mx-2">{{endpointStatus.when | date:kloodDateFormat.pipe[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</span>
              <span *ngIf="endpointStatus.error" class="is-red">({{"EndpointStatus.Error" | translate}})</span>
            </button>
          </h2>
          <div id="{{ 'collapse' + i }}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionEdnpointStatus">
            <div class="accordion-body">
              <span *ngIf="endpointStatus.error" class="is-red">{{"EndpointStatus.Error" | translate}} :</span> {{endpointStatus.error}}
              <!-- <div *ngIf="endpointStatus.items">
            <div *ngFor="let item of endpointStatus.items | keyvalue">
              <span class="is-bold">{{item.value.title}} :</span> {{item.value.message}} - {{item.value.status}} <span *ngIf="item.value.error" class="is-red">{{item.value.error}}</span>
            </div>x
          </div> -->
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="endpointStatuses.length === 0" class="p-3">{{"EndpointStatus.NoStatus" | translate}}</div>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div class="row h-100 d-flex justify-content-center align-items-center">
      <div class="col-auto my-auto">
        <app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
      </div>
    </div>
  </ng-template>
</ng-container>