<div class="details-bloc">
    <div class="details-bloc-header" *ngIf="blocTitle || closeControl">
        <div class="details-bloc-title" *ngIf="blocTitle">{{blocTitle | translate}}</div>
        <div class="push" *ngIf="closeControl">
            <div class="go-back">
                <a (click)="closeDetails()">
                    <fa-icon class="pointer control-icon" [icon]="['fas', 'times']"></fa-icon>
                </a>
            </div>
        </div>
    </div>
    <div class="details-control-bloc">
        <div class="control">
            <app-toolbar [blocControls]="blocControls" (requestAction)="onRequestAction($event)"
                (switchChange)="onSwitchChange($event)" [needDetails]="true"></app-toolbar>
        </div>
    </div>
    <div class="details-bloc-body">
        <ng-content></ng-content>
    </div>
</div>