import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';

const debug = Debug('virtuvisio-administration:home-management-component');

@Component({
  selector: 'app-home-management',
  templateUrl: './home-management.component.html',
  styleUrls: ['./home-management.component.scss'],
})
export class HomeManagementComponent {
  connectedUser$: Observable<ConnectedUser>;

  constructor(private sharedService: SharedService) {
    this.connectedUser$ = this.sharedService.currentConnectedUser;
  }

}
