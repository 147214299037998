import { Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from '../services/config/app.config.service';

@Pipe({
  name: 'makeSrcUrl',
})
export class MakeSrcUrl implements PipeTransform {
  constructor(private configUi: AppConfigService) { }

  transform(src: string): string {
    return src + '?version=' + this.configUi.settings.APP_VERSION;
  }
}
