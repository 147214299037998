import { ThemeModel } from "./theme.model";

export const virtuvisioAppTheme: ThemeModel = {
  // colors variable
  'app-primaryColor': '189, 49, 86',
  'app-secondaryColor': '40, 54, 58',
  'app-thirdColor': '255, 100, 107',
  'app-darkText': '40, 54, 58',
  'app-lightText': '241, 243, 244',
  'app-greyText': '83, 94, 97',
  'app-lightGrey': '219, 218, 218',
  'app-whitish': '241, 243, 244',
  'app-greyFont': '83, 94, 97',
  'app-linearGradient': 'linear-gradient(90deg, rgb(189, 49, 86) 0%, rgb(40, 54, 58) 100%)',
  'app-linearGradient-50': 'linear-gradient(90deg, rgb(241, 243, 244) 50%, rgb(40, 54, 58) 50%)',
  'app-lightGreyBackground': '227, 227, 227',
  'app-green': '100, 167, 99',
  'app-red': '255, 0, 0',
  'app-orange': '255, 165, 0',
  'app-grey': '128, 128, 128',

  // shadow variable
  'card-shadow': '6px 15px 10px 5px rgba(0, 0, 0, 0.25)',
  'input-shadow': '0px 4px 4px 0px rgba(40, 54, 58, 0.5)',
  'button-shadow': '0px 4px 4px 0px rgba(40, 54, 58, 0.5)',
  'dropdown-shadow': '0px 4px 4px 0px rgba(40, 54, 58, 0.5)',
  'text-shadow': '4px 3px 3px rgba(40, 54, 58, 0.3)',
  'text-shadow-white': '4px 4px 3px rgba(40, 54, 58, 0.4)',
}