import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ConnectedUserService } from 'src/app/users/user-connected/connected-user.service';
import { UserDataCustom } from 'src/app/users/user-connected/user.connected.data.custom';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { THEMES } from 'src/assets/scss/theme/theme.config';
import { SharedService } from '../shared/shared.service';
import Debug from 'debug';
const debug = Debug('virtuvisio-administration:theme-service');

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(@Inject(DOCUMENT) private document: Document, private sharedService: SharedService, private userService: ConnectedUserService) { }

  setTheme(name = 'virtuvisio') {
    this.clearTheme();
    const theme = THEMES[name];
    Object.keys(theme).forEach((key) => {
      this.document.documentElement.style.setProperty(`--${key}`, theme[key]);
      this.document.body.style.setProperty(`--${key}`, theme[key]);
    });
    this.document.body.classList.add(name);
    this.sharedService.updateTheme(name);
  }

  getTheme(): string {
    return "virtuvisio";
  }

  initTheme(name = 'virtuvisio') {
    this.setTheme('virtuvisio');
  }

  clearTheme() {
    this.document.body.classList.remove("virtuvisio");
  }
}
