<ng-container *ngIf="tenant$ | async as tenant">
    <ng-container *ngIf="connectedUser$ | async as connectedUser">
        <div *ngIf="connectedUser.isGranted(tenant.id)">
            <div class="row align-items-center p-3">
                <div class="col-auto">
                    <h3 class="m-0">
                        <span class="is-bold">{{"OffersComponent.Title" | translate}}:</span>
                        <span class="is-bold is-primary"> {{tenant.name | uppercase}}</span>
                    </h3>
                </div>
                <div class="col" *ngIf="this.offersType.length !== 0 && connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
                    <button class="btn btn-app app-primary float-end" type="submit" *ngIf="!addingOffer" (click)="addingOffer = !addingOffer">
                        <fa-icon [icon]="['fas', 'plus-circle']" class="me-2"></fa-icon>{{"OffersComponent.Button.Add" | translate }}
                    </button>
                    <button class="btn btn-app app-secondary float-end" type="submit" *ngIf="addingOffer" (click)="addingOffer = !addingOffer">
                        <fa-icon [icon]="['fas', 'arrow-left']" class="me-2"></fa-icon>{{"OffersComponent.Button.Cancel" | translate }}
                    </button>
                </div>
            </div>
            <div class="row">
                <div [ngClass]="{
                    'col-9': this.offersType.length > 0 && addingOffer,
                    'col-12': this.offersType.length === 0
                }">
                    <app-admin-bloc>
                        <div class="parent-offers">
                            <div class="child-offer" *ngFor="let offer of tenant.offers | keyvalue">
                                <app-admin-infos-bloc  
                                    [blocTitle]="'OffersComponent.Offer.'+offer.key"
                                    [blocControls]="(connectedUser.isGranted(tenant.id, 'MANAGEMENT') && !connectedUser.hasRole('MARKETING')) ? [renewControl, editControl, {
                                                        icon: 'times',
                                                        doubleIcon: false,
                                                        secondIcon: 'times',
                                                        controleType: 'ACTION',
                                                        link: {
                                                            url: 'not-found'
                                                        },
                                                        tooltip: 'OffersComponent.Tooltip.Enable',
                                                        isSwitch: true,
                                                        switchName: 'offer-switch-'+offer.key,
                                                        switchStatus: !offer.value.disabledAt
                                                    } ] : []" 
                                    (requestAction)="onRequestedAction($event, offer)"
                                    (switchChanged)="onSwitchChanged($event, offer, tenant)">
                                    <app-offer-view *ngIf="!editingOffer || selectedOffer !== offer.key" [offer]="offer.value" [kind]="offer.key" [connectedUser]="connectedUser" [tenant]="tenant" [renewOffer]="(renewingOffer && selectedOffer === offer.key) ? true: false" (cancelRenew)="onCancelRenew()"></app-offer-view>
                                    <app-offer-form *ngIf="editingOffer && selectedOffer === offer.key" [offer]="offer.value" [kind]="offer.key" [connectedUser]="connectedUser" [tenant]="tenant" (cancelEdit)="onCancelEdit()"></app-offer-form>
                                </app-admin-infos-bloc>
                            </div>
                        </div>
                    </app-admin-bloc>
                </div>
                <div class="col-3" *ngIf="this.offersType.length > 0 && addingOffer" [@slideFromRight]="true">
                    <app-admin-bloc [blocTitle]="'OffersComponent.NewOffer'">
                        <div *ngFor="let newOfferType of this.offersType">
                            <a class="pointer add-new-offer-link" (click)="addOffer(tenant, newOfferType)">
                                <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>&nbsp;{{'OffersComponent.Offer.'+newOfferType | translate}}
                            </a>
                        </div>
                    </app-admin-bloc>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
