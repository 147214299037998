import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

@Injectable()
export class AppConfigService {
  public settings: UiConfig;
  private readonly config: Observable<UiConfig> = of();

  constructor(private http: HttpClient) {
    const jsonFile = 'config/config.json';
    const randId = Math.floor(Math.random() * 1000000000);
    const urlFile = jsonFile + '?randId=' + randId;
    this.config = this.http.get<UiConfig>(urlFile).pipe(
      catchError((err) => {
        console.log('Failed to load the config file, use default config');
        return of(DefaultConfig);
      }),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );
  }

  load(): Observable<UiConfig> {
    return this.config;
  }

  loadStart(): Promise<void> {
    const jsonFile = 'config/config.json';
    const randId = Math.floor(Math.random() * 1000000000);
    const urlFile = jsonFile + '?randId=' + randId;
    return new Promise<void>((resolve, reject) => {
      lastValueFrom(this.http.get(urlFile))
        // .toPromise()   
        .then((response) => {
          this.settings = <UiConfig>response;
          resolve(); //Return Sucess
        })
        .catch((response) => {
          this.settings = DefaultConfig;
          reject(`Failed to load the config file`);
        });
    });
  }
}

const DefaultConfig = {
  API_URI: 'https://broker.local.klood.io',
  AUTH_URI: 'https://auth.local.klood.io',
  REDIRECT_URI: 'https://localhost:4200',
  PEXIP_MEETING_NODE: 'webrtc.dev.klood.io',
  APP_VERSION: 'lastest',
  REDIRECT_LOGIN_URI: 'https://localhost:4200/login'
};

export interface UiConfig {
  API_URI: string;
  AUTH_URI: string;
  REDIRECT_URI: string;
  PEXIP_MEETING_NODE: string;
  APP_VERSION: string;
  REDIRECT_LOGIN_URI: string;
}
