<div *ngIf="(user$ | async) as user">
  <app-admin-details-bloc [blocTitle]="user.displayName" [blocControls]="user.authProvider === 'microsoft' ? [{
        icon: 'times',
        doubleIcon: false,
        secondIcon: 'times',
        controleType: 'ACTION',
        link: {
            url: 'not-found'
        },
        tooltip: 'UserViewComponent.Tooltip.Enable',
        isSwitch: true,
        switchName: 'offer-switch-'+user.id,
        switchStatus: !user.disabled
    }] : [{
        icon: 'times',
        doubleIcon: false,
        secondIcon: 'times',
        controleType: 'ACTION',
        link: {
            url: 'not-found'
        },
        tooltip: 'UserViewComponent.Tooltip.Enable',
        isSwitch: true,
        switchName: 'offer-switch-'+user.id,
        switchStatus: !user.disabled
    }, sendPasswordControl, resetPasswordControl, deleteControl, editControl, editPasswordControl]" (closeEvent)="backToList()" (requestAction)="onRequestedAction($event, user, tenant)" (switchChanged)="onSwitchChanged($event, user, tenant)">
    <div class="p-3">
      <div class="row">
        <div class="col-7">
          <app-admin-border-bloc>
            <div class="align-items-center is-bold mb-3">{{"UserViewComponent.Informations" | translate }}</div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.Name" | translate}} : 
              </div>
              <div class="col">
                {{user.getDisplayName()}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.Email" | translate}} : 
              </div>
              <div class="col">
                {{user.email}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.LastAccess" | translate}} : 
              </div>
              <div class="col">
                {{user.lastAccess | date:kloodDateFormat.pipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.Phone" | translate}} : 
              </div>
              <div class="col">
                {{user.phone}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.Alias" | translate}} : 
              </div>
              <div class="col">
                {{user.privateRoomAlias}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.ModeratorCode" | translate}} : 
              </div>
              <div class="col">
                {{user.moderatorCode}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.Role" | translate}}: 
              </div>
              <div class="col">
                {{user.role}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.Language" | translate}} : 
              </div>
              <div class="col">
                {{user.lang | translate}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.Tenant" | translate}} : 
              </div>
              <div class="col">
                {{user.tenantName}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.AuthProvider" | translate}} : 
              </div>
              <div class="col">
                {{user.authProvider}}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto is-bold pe-0">
                {{"UserViewComponent.Services.Title" | translate}} : 
              </div>
              <div class="col">
                <span *ngFor="let service of user.services; let isLast = last;">{{"UserViewComponent.Services."+service | translate}}<span *ngIf="!isLast">, </span></span>
              </div>
            </div>
          </app-admin-border-bloc>
        </div>
        <div class="col-5">
          <app-admin-border-bloc>
            <div class="align-items-center is-bold mb-3">{{"UserViewComponent.Offers" | translate }}</div>
            <!-- TODO gére activation/désactivation de l'offer au clic checkbox -->
            <div class="one">
              <div *ngIf="tenant.hasOffer('ONE')">
                <app-checkbox-field 
                  [nameInput]="'one'" [myInputModel]="user.hasAllowedOffer('ONE')" [checked]="user.hasAllowedOffer('ONE')" [textLabel]="'UserViewComponent.Offer.ONE'" (checkedEvent)="enableOffer($event, user, 'ONE')"
                  [disabled]="!tenant.hasAccess('ONE') || (!user.hasPhone() && tenant.hasAccess('ONE')) || !connectedUser.canEdit(tenant.id) || user.disabled || user.expired"></app-checkbox-field>
                <div class="is-italic is-small-text mb-3" *ngIf="!user.hasPhone() && tenant.hasAccess('ONE')">{{"UserViewComponent.PhoneNeeded" | translate}}</div>
              </div>
              <div *ngIf="!tenant.hasOffer('ONE')">
                <app-checkbox-field [nameInput]="'noOne'" [myInputModel]="user.hasAllowedOffer('ONE')" [checked]="user.hasAllowedOffer('ONE')" [textLabel]="'UserViewComponent.Offer.ONE'" [disabled]="true">
                </app-checkbox-field>
              </div>
            </div>
            <div class="pro">
              <div *ngIf="tenant.hasOffer('PRO')">
                <app-checkbox-field 
                  [nameInput]="'pro'" [myInputModel]="user.hasAllowedOffer('PRO')" [checked]="user.hasAllowedOffer('PRO') && tenant.hasAccess('PRO')" [textLabel]="'UserViewComponent.Offer.PRO'" (checkedEvent)="enableOffer($event, user, 'PRO')"
                  [disabled]="!tenant.hasAccess('PRO') || !connectedUser.canEdit(tenant.id) || user.disabled || user.expired"></app-checkbox-field>
              </div>
              <div *ngIf="!tenant.hasOffer('PRO')">
                <app-checkbox-field [nameInput]="'noPro'" [myInputModel]="user.hasAllowedOffer('PRO')" [checked]="user.hasAllowedOffer('PRO')" [textLabel]="'UserViewComponent.Offer.PRO'" [disabled]="true">
                </app-checkbox-field>
              </div>
            </div>
            <div class="gateway">
              <div *ngIf="tenant.hasOffer('GATEWAY')">
                <app-checkbox-field 
                  [nameInput]="'gateway'" [myInputModel]="user.hasAllowedOffer('GATEWAY')" [checked]="user.hasAllowedOffer('GATEWAY') && tenant.hasAccess('GATEWAY')" [textLabel]="'UserViewComponent.Offer.GATEWAY'" (checkedEvent)="enableOffer($event, user, 'GATEWAY')"
                  [disabled]="!tenant.hasAccess('GATEWAY') || !connectedUser.canEdit(tenant.id) || user.disabled || user.expired"></app-checkbox-field>
              </div>
              <div *ngIf="!tenant.hasOffer('GATEWAY')">
                <app-checkbox-field [nameInput]="'noGateway'" [myInputModel]="user.hasAllowedOffer('GATEWAY')" [checked]="user.hasAllowedOffer('GATEWAY')" [textLabel]="'UserViewComponent.Offer.GATEWAY'" [disabled]="true">
                </app-checkbox-field>
              </div>
            </div>
            <div class="recording" [ngClass]="{active: tenant.hasOffer('RECORDING') && user.hasAllowedOffer('RECORDING')}">
              <div *ngIf="tenant.hasOffer('RECORDING')">
                <app-checkbox-field 
                  [nameInput]="'recording'" [myInputModel]="user.hasAllowedOffer('RECORDING')" [checked]="user.hasAllowedOffer('RECORDING') && tenant.hasAccess('RECORDING')" [textLabel]="'UserViewComponent.Offer.RECORDING'" (checkedEvent)="enableOffer($event, user, 'RECORDING')"
                  [disabled]="!tenant.hasAccess('RECORDING') || !connectedUser.canEdit(tenant.id) || user.disabled || user.expired"></app-checkbox-field>
              </div>
              <div *ngIf="!tenant.hasOffer('RECORDING')">
                <app-checkbox-field [nameInput]="'noRecording'" [myInputModel]="user.hasAllowedOffer('RECORDING')" [checked]="user.hasAllowedOffer('RECORDING')" [textLabel]="'UserViewComponent.Offer.RECORDING'" [disabled]="true">
                </app-checkbox-field>
              </div>
              <div *ngIf="tenant.hasOffer('RECORDING')">
                <div *ngIf="user.hasAllowedOffer('RECORDING') && tenant.hasAccess('RECORDING')">
                  <div>
                    <ng-select class="ng-select-bottom-margin margin-bottom-0" [items]="recordingRetentionOptions" bindLabel="label" bindValue="value" placeholder="{{'UserViewComponent.RecordRetention.Title' | translate}}" name="recordRetention" [(ngModel)]="currentRetention" [disabled]="!connectedUser.canEdit(tenant.id) || user.disabled || user.expired" (change)="editRecordRetention(currentRetention, user)" required [clearable]="false"> </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="one-codec">
              <div *ngIf="tenant.hasOffer('ONE_CODEC')">
                <app-checkbox-field 
                  [nameInput]="'oneCodec'" [myInputModel]="user.hasAllowedOffer('ONE_CODEC')" [checked]="user.hasAllowedOffer('ONE_CODEC') && tenant.hasAccess('ONE_CODEC')" [textLabel]="'UserViewComponent.Offer.ONE_CODEC'" (checkedEvent)="enableOffer($event, user, 'ONE_CODEC')"
                  [disabled]="!tenant.hasAccess('ONE_CODEC') || !connectedUser.canEdit(tenant.id) || user.disabled || user.expired"></app-checkbox-field>
              </div>
              <div *ngIf="!tenant.hasOffer('ONE_CODEC')">
                <app-checkbox-field [nameInput]="'noOneCodec'" [myInputModel]="user.hasAllowedOffer('ONE_CODEC')" [checked]="user.hasAllowedOffer('ONE_CODEC')" [textLabel]="'UserViewComponent.Offer.ONE_CODEC'" [disabled]="true">
                </app-checkbox-field>
              </div>
            </div>
            <div class="teams-gateway">
              <div *ngIf="tenant.hasOffer('TEAMS_GATEWAY')">
                <app-checkbox-field 
                  [nameInput]="'teamsGateway'" [myInputModel]="user.hasAllowedOffer('TEAMS_GATEWAY')" [checked]="user.hasAllowedOffer('TEAMS_GATEWAY') && tenant.hasAccess('TEAMS_GATEWAY')" [textLabel]="'UserViewComponent.Offer.TEAMS_GATEWAY'" (checkedEvent)="enableOffer($event, user, 'TEAMS_GATEWAY')"
                  [disabled]="!tenant.hasAccess('TEAMS_GATEWAY') || !connectedUser.canEdit(tenant.id) || user.disabled || user.expired"></app-checkbox-field>
              </div>
              <div *ngIf="!tenant.hasOffer('TEAMS_GATEWAY')">
                <app-checkbox-field [nameInput]="'noTeamsGateway'" [myInputModel]="user.hasAllowedOffer('TEAMS_GATEWAY')" [checked]="user.hasAllowedOffer('TEAMS_GATEWAY')" [textLabel]="'UserViewComponent.Offer.TEAMS_GATEWAY'" [disabled]="true">
                </app-checkbox-field>
              </div>
            </div>
            <div class="hangout-gateway">
              <div *ngIf="tenant.hasOffer('HANGOUT_GATEWAY')">
                <app-checkbox-field 
                  [nameInput]="'hangoutGateway'" [myInputModel]="user.hasAllowedOffer('HANGOUT_GATEWAY')" [checked]="user.hasAllowedOffer('HANGOUT_GATEWAY') && tenant.hasAccess('HANGOUT_GATEWAY')" [textLabel]="'UserViewComponent.Offer.HANGOUT_GATEWAY'" (checkedEvent)="enableOffer($event, user, 'HANGOUT_GATEWAY')"
                  [disabled]="!tenant.hasAccess('HANGOUT_GATEWAY') || !connectedUser.canEdit(tenant.id) || user.disabled || user.expired"></app-checkbox-field>
              </div>
              <div *ngIf="!tenant.hasOffer('HANGOUT_GATEWAY')">
                <app-checkbox-field [nameInput]="'noHangoutGateway'" [myInputModel]="user.hasAllowedOffer('HANGOUT_GATEWAY')" [checked]="user.hasAllowedOffer('HANGOUT_GATEWAY')" [textLabel]="'UserViewComponent.Offer.HANGOUT_GATEWAY'" [disabled]="true">
                </app-checkbox-field>
              </div>
            </div>
          </app-admin-border-bloc>
        </div>
      </div>
    </div>
  </app-admin-details-bloc>
  <div class="modal fade custom-app-modal" id="modalOnDeleteUser" role="dialog" aria-labelledby="modalOnDeleteUserLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content flat">
        <div class="modal-header">
          <h3 class="modal-title text-center" id="modalOnDeleteUserLabel">
            {{"UserViewComponent.Modal.Deleting" | translate}}</h3>
        </div>
        <div class="modal-body">
          <div class="is-bold">{{"UserViewComponent.Modal.SureDeleteUser" | translate}}</div>
          <div class="mb-3">{{user.email}}, {{user.getDisplayName()}}</div>
          <div class="dual-button-container">
            <button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal">
              {{"UserViewComponent.Modal.Cancel" | translate}}
            </button>
            <button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="confirmDeleteUser(user)">
              {{"UserViewComponent.Modal.Confirm" | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>