import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/services/shared/shared.service';
import { KloodDateFormat } from './dates.format.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { NotifService } from '../notifs/notif.service';

@Injectable()
export class DatesManager {
  kloodDateFormat = KloodDateFormat;
  lang = 'fr-FR';

  constructor(private sharedService: SharedService, private datePipe: DatePipe, private notif: NotifService) {
    this.sharedService.currentConnectedUser.subscribe((connectedUser: ConnectedUser) => {
      this.lang = connectedUser.lang;
    });
  }

  isValidDate(value: string): boolean {
    const timestamp = Date.parse(value);
    return isNaN(timestamp) ? false : true;
  }

  toDatePipeLocale(value: Date): string {
    return <string>this.datePipe.transform(value, this.kloodDateFormat.pipe[this.lang], undefined, this.kloodDateFormat.locale[this.lang]);
  }

  toDateShortLocale(value: Date): string | null {
    return this.datePipe.transform(value, 'short', undefined, this.kloodDateFormat.locale[this.lang]);
  }

  toISOString(date: Date): string {
    return new Date(date).toISOString();
  }

  addMonth(date: Date, i: number): Date {
    return new Date(date.setMonth(date.getMonth() + i));
  }

  removeMonth(date: Date, i: number): Date {
    return new Date(date.setMonth(date.getMonth() - i));
  }

  addTimeISO(): string {
    return new Date(new Date().getTime() + 600000).toISOString();
  }

  fromStartDay(date: Date): Date {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  }

  toEndDay(date: Date): Date {
    return new Date(new Date(date).setHours(23, 59, 59, 999));
  }

  checkDate(startDate: Date, endDate: Date, meetingId?: string): boolean {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const oneHourPastDate = new Date(new Date().getTime() - 3600000);
    if (oneHourPastDate.getMinutes() >= 0 && oneHourPastDate.getMinutes() < 30) {
      oneHourPastDate.setMinutes(0);
    } else if (oneHourPastDate.getMinutes() >= 30 && oneHourPastDate.getMinutes() <= 59) {
      oneHourPastDate.setMinutes(30);
    }
    oneHourPastDate.setSeconds(0);
    if (startDate && !endDate) {
      return true;
    }
    if (endDate && !startDate) {
      this.notif.addErrorNotif('DateManager.NeedStartDate');
      return false;
    }
    if (startDate && endDate) {
      if (endDate <= startDate) {
        return false;
      } else if (startDate <= oneHourPastDate && !meetingId) {
        this.notif.addErrorNotif('DateManager.NoPastStartDate');
        return false;
      } else if (endDate < new Date() && !meetingId) {
        this.notif.addErrorNotif('DateManager.NoPastEndDate');
        return false;
      } else if (endDate.getTime() > startDate.getTime() + 172800000) {
        this.notif.addErrorNotif('DateManager.NoMoreThanTwo');
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  checkDateBooking(startDate: Date, endDate: Date, bookingId: string): boolean {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const oneHourPastDate = new Date(new Date().getTime() - 3600000);
    if (oneHourPastDate.getMinutes() >= 0 && oneHourPastDate.getMinutes() < 30) {
      oneHourPastDate.setMinutes(0);
    } else if (oneHourPastDate.getMinutes() >= 30 && oneHourPastDate.getMinutes() <= 59) {
      oneHourPastDate.setMinutes(30);
    }
    oneHourPastDate.setSeconds(0);
    if (startDate && !endDate) {
      return true;
    }
    if (endDate && !startDate) {
      this.notif.addErrorNotif('DateManager.NeedStartDate');
      return false;
    }
    if (startDate && endDate) {
      if (endDate <= startDate) {
        return false;
      } else if (startDate <= oneHourPastDate && !bookingId) {
        this.notif.addErrorNotif('DateManager.NoPastStartDate');
        return false;
      } else if (endDate < new Date() && !bookingId) {
        this.notif.addErrorNotif('DateManager.NoPastEndDate');
        return false;
      } else if (endDate.getTime() > startDate.getTime() + 86400000) {
        this.notif.addErrorNotif('DateManager.NoMoreThanTwo');
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  invalidStart(startDate: Date, endDate: Date, meetingId?: string): boolean {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const oneHourPastDate = new Date(new Date().getTime() - 3600000);
    if (oneHourPastDate.getMinutes() >= 0 && oneHourPastDate.getMinutes() < 30) {
      oneHourPastDate.setMinutes(0);
    } else if (oneHourPastDate.getMinutes() >= 30 && oneHourPastDate.getMinutes() <= 59) {
      oneHourPastDate.setMinutes(30);
    }
    oneHourPastDate.setSeconds(0);
    if (startDate && endDate) {
      if (startDate <= oneHourPastDate && !meetingId) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  invalidEnd(startDate: Date, endDate: Date, meetingId?: string): boolean {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    if (startDate && endDate) {
      if (endDate <= startDate) {
        return false;
      } else if (endDate < new Date() && !meetingId) {
        return false;
      } else if (endDate.getTime() > startDate.getTime() + 172800000) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  invalidStartBooking(startDate: Date, endDate: Date, bookingId: string): boolean {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const oneHourPastDate = new Date(new Date().getTime() - 3600000);
    if (oneHourPastDate.getMinutes() >= 0 && oneHourPastDate.getMinutes() < 30) {
      oneHourPastDate.setMinutes(0);
    } else if (oneHourPastDate.getMinutes() >= 30 && oneHourPastDate.getMinutes() <= 59) {
      oneHourPastDate.setMinutes(30);
    }
    oneHourPastDate.setSeconds(0);
    if (startDate && endDate) {
      if (startDate <= oneHourPastDate && !bookingId) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  invalidEndBooking(startDate: Date, endDate: Date, bookingId: string): boolean {
    if (startDate && endDate) {
      if (endDate <= startDate) {
        return false;
      } else if (endDate < new Date() && !bookingId) {
        return false;
      } else if (endDate.getTime() > startDate.getTime() + 86400000) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  calculateDuration(startTime: Date, endTime?: Date): string {
    let momentEnd;
    if (endTime) {
      momentEnd = moment(new Date(endTime));
    } else {
      momentEnd = moment(new Date(Date.now()));
    }
    const momentStart = moment(new Date(startTime));
    const durationTemp = moment.duration(momentEnd.diff(momentStart));

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ms = durationTemp.milliseconds().toString();
    let s = durationTemp.seconds().toString();
    let m = durationTemp.minutes().toString();
    let h = durationTemp.hours().toString();
    const day = durationTemp.days().toString();

    if (s?.length < 2) {
      s = '0' + s;
    }
    if (m?.length < 2) {
      m = '0' + m;
    }
    if (h?.length < 2) {
      h = '0' + h;
    }
    let duration;
    if (durationTemp.days() === 0) {
      if (h === '00') {
        if (m === '00') {
          if (s === '00') {
            duration = '< 1s';
          } else {
            duration = s + 's';
          }
        } else {
          duration = m + 'm' + s + 's';
        }
      } else {
        duration = h + 'h' + m + 'm' + s + 's';
      }
    } else {
      duration = day + 'd : ' + h + 'h' + m + 'm' + s + 's';
    }
    return duration;
  }

  calculateExpiration(startingDate: Date, recordRetention: number): Date {
    const date = new Date(startingDate);
    switch (recordRetention) {
      case 1: {
        return new Date(date.setDate(startingDate.getDate() + 3));
      }
      case 2: {
        return new Date(date.setDate(startingDate.getDate() + 30));
      }
      case 3: {
        return new Date(date.setDate(startingDate.getDate() + 180));
      }
      default: {
        return new Date();
      }
    }
  }

  secondsToHms(d: number): string | number {
    if (d <= 0) {
      return 0;
    } else {
      d = Number(d);
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor((d % 3600) % 60);
      const hDisplay = h > 0 ? h + (h === 1 ? ' h, ' : ' h, ') : '';
      const mDisplay = m > 0 ? m + (m === 1 ? ' m, ' : ' m, ') : '';
      const sDisplay = s > 0 ? s + (s === 1 ? ' s' : ' s') : '';
      return hDisplay + mDisplay + sDisplay;
    }
  }
}
