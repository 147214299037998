import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';

const debug = Debug('virtuvisio-administration:header-main-menu');

@Component({
  selector: 'app-main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.scss'],
})
export class MainNavbarComponent {
  @Input() connectedUser: ConnectedUser;

  constructor(public router: Router) { }

  navigateHome(): void {
    let kloodWebappUrl;
    const newWebappBaseUrl = window.location.origin.replace('admin.', 'app.');
    console.log('Navigating to : ' + kloodWebappUrl);
    kloodWebappUrl = newWebappBaseUrl + '/home';
    window.location.href = kloodWebappUrl;
  }
}
