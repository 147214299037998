import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';

declare let $: any;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[notif-toastr-component]',
  styles: [
    `
      :host {
        border-radius: 12px;
        box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.25);
      }
    `,
  ],
  templateUrl: './notif.toastr.html',
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          // display: 'none',
          opacity: 0,
        })
      ),
      transition(
        'inactive => active',
        animate(
          '50ms ease-out',
          keyframes([
            style({
              opacity: 0,
              bottom: '-15px',
              'max-height': 0,
              'max-width': 0,
              'margin-top': 0,
            }),
            style({
              opacity: 0.8,
              bottom: '-3px',
            }),
            style({
              opacity: 1,
              bottom: '0',
              // 'max-height': '200px',
              'margin-top': '12px',
              // 'max-width': '400px',
            }),
          ])
        )
      ),
      state(
        'active',
        style({
          bottom: '0',
          // 'max-height': '200px',
          'margin-top': '12px',
          // 'max-width': '400px',
        })
      ),
      transition(
        'active => removed',
        animate(
          '300ms ease-out',
          keyframes([
            style({
              opacity: 0.6,
              bottom: 0,
            }),
            style({
              opacity: 0.1,
              bottom: '-3px',
            }),
            style({
              opacity: 0,
              bottom: '-15px',
            }),
          ])
        )
      ),
    ]),
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NotifToastr extends Toast {

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  happenTime: Date;
  kloodDateFormat = KloodDateFormat;


  showFullError(message?: any): void {
    if (message) {
      message.traceId = message.headers.get('trace-id');
      message.spanId = message.headers.get('span-id');
      this.happenTime = new Date();
      $('#notifErrorModal').modal('show');
    }
  }

  reloadNewVersion(): void {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach(async (name) => {
          await caches.delete(name);
        });
      });
      window.location.reload();
    }
  }
}
