export class Geozone {
  id: string;
  code: string;
  continentId: string;
  phoneNumber: string;
  createdAt: Date;
  deleted: boolean;
  deletedAt: Date;
  updatedAt: Date;
  translations: {
    "fr-FR": string | null;
    "en-US": string | null;
  };
  
  constructor(obj: any) {
    Object.assign(this, obj);
    this.translations = {
      "fr-FR": null,
      "en-US": null,
    };
  }
  
  getId(): string {
    return this.id;
  }
}
