<div class="row align-items-center p-3">
  <div class="col-auto">
    <h3 class="m-0">
      <span class="is-bold">{{"ImportMeetingRooms.ImportMeetingRooms" | translate}}</span>
    </h3>
  </div>
  <div class="col">
    <button class="btn btn-app app-secondary float-end" type="submit" [routerLink]="['../']">
      <fa-icon [icon]="['fas', 'arrow-left']" class="me-2"></fa-icon>{{"ImportMeetingRooms.BackToMeetingRooms" | translate }}
    </button>
  </div>
</div>
<app-admin-card>
  <form #importMeetingRoom="ngForm" id="importMeetingRoom" name="importMeetingRoom">
    <div class="row align-items-center">
      <div class="col-4">
        <app-input-form-field [nameInput]="'email'" [typeInput]="'text'" [myInputModel]="emailImportMeetingRoom.email"
          [textLabel]="'ImportMeetingRooms.Form.Email'" [form]="importMeetingRoom" class="form-control"
          [required]="true">
        </app-input-form-field>
      </div>
      <div class="col-2">
        <app-loading-button class="form-control" (submitEvent)="confirmImportMeetingRoom(importMeetingRoom.value, importMeetingRoom)"
          [targetStyle]="'primary'" [finished]="finished" [error]="hasError"
          [initialText]="'ImportMeetingRooms.Form.Button.Import'" [finalText]="'ImportMeetingRooms.Form.Button.Imported'">
        </app-loading-button>
      </div>
      <div class="col-6">
        <div class="is-third fields-error" *ngIf="importMeetingRoom.submitted && !importMeetingRoom.valid">
          {{'ImportMeetingRooms.Form.Button.SomeFields' | translate}}
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col is-primary">
      <hr />
    </div>
    <div class="col-auto">
      <h3>{{'ImportMeetingRooms.Or' | translate}}</h3>
    </div>
    <div class="col is-primary">
      <hr />
    </div>
  </div>
  <app-mat-table-custom *ngIf="displayedColumns && !refreshTable && importableMeetingRooms" [componentName]="'MeetingRooms'" [hoverable]="false" [displayedColumns]="displayedColumns" [dataSource]="importableMeetingRooms" [defaultSort]="defaultSort" (actionClick)="onImportClick($event)">
  </app-mat-table-custom>
  <div class="row h-100 d-flex justify-content-center align-items-center" *ngIf="refreshTable">
    <div class="col-auto my-auto">
        <app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
    </div>
  </div>
</app-admin-card>