<app-admin-bloc [blocTitle]="'Edit'">
  <form #editMeetingRoom="ngForm" id="editMeetingRoom" name="editMeetingRoom" *ngIf="currentMeetingRoom">
    <app-input-form-field [nameInput]="'name'" [typeInput]="'text'" [myInputModel]="currentMeetingRoom.name"
      [textLabel]="'EditMeetingRoom.Form.Name'" [form]="editMeetingRoom" class="form-control" [required]="true">
    </app-input-form-field>
    <app-input-form-field [nameInput]="'description'" [typeInput]="'text'" [myInputModel]="currentMeetingRoom.description"
      [textLabel]="'EditMeetingRoom.Form.Description'" [form]="editMeetingRoom" class="form-control">
    </app-input-form-field>
    <app-input-form-field [nameInput]="'email'" [typeInput]="'text'" [myInputModel]="currentMeetingRoom.email"
      [textLabel]="'EditMeetingRoom.Form.Email'" [form]="editMeetingRoom" class="form-control" [required]="true" [disabled]="true">
    </app-input-form-field>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary w-100" type="reset"
      [routerLink]="['../../']">{{"EditMeetingRoom.Form.Button.Cancel" | translate }}</button>
      <app-loading-button (submitEvent)="confirmEditMeetingRoom(editMeetingRoom, editMeetingRoom.value)" [targetStyle]="'primary'"
        [finished]="finished" [error]="hasError" [initialText]="'EditMeetingRoom.Form.Button.Edit'"
        [finalText]="'EditMeetingRoom.Form.Button.Edited'">
      </app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="editMeetingRoom.submitted && !editMeetingRoom.valid">
      {{'EditMeetingRoom.Form.Button.SomeFields' | translate}}</div>
  </form>
</app-admin-bloc>