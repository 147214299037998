import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
	selector: '[appMaxMask]',
	providers: [{ provide: NG_VALIDATORS, useExisting: MaxMaskValidatorDirective, multi: true }],
})
export class MaxMaskValidatorDirective implements Validator {
	@Input('appMaxMask') maximumMask!: number;
	
	validate(control: AbstractControl): { [key: string]: any } | null {
		return this.maximumMask ? maxMaskValidator(this.maximumMask)(control) : null;
	}
}

export function maxMaskValidator(max: number): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		let forbidden = false;
		if (control.value > max) {
			forbidden = true;
		}
		return forbidden ? { maximumMask: { value: control.value } } : null;
	};
}
