<ul class="dropdown-menu dropdown-menu-lg-start scrollable" aria-labelledby="dropdownMenuButton" [ngClass]="{'show': textInput}">
  <li *ngIf="(contacts$ | async)?.length === 0"><button class="dropdown-item" type="button">{{"SuggestContact.NoContact" | translate}}</button></li>
  <li *ngFor="let contact of contacts$ | async" (click)="selectContact(contact)">
      <button class="dropdown-item" type="button">
          <span *ngIf="contact.getDisplayName() === contact.email">
              <span class="name-suggest" [innerHTML]="contact.getDisplayName() | boldTextPipe:textInput"></span><br />
              <span *ngIf="contact.phone"><span [innerHTML]="contact.phone | boldTextPipe:textInput"></span></span>
          </span>
          <span *ngIf="contact.getDisplayName() !== contact.email">
              <span class="name-suggest" [innerHTML]="contact.getDisplayName() | boldTextPipe:textInput"></span><br *ngIf="contact.getDisplayName()" />
              <span [innerHTML]="contact.email | boldTextPipe:textInput"></span>
              <span *ngIf="contact.phone"> - </span>
              <span *ngIf="contact.phone" [innerHTML]="contact.phone | boldTextPipe:textInput"></span>
          </span>
      </button>
  </li>
</ul>
