import { Component, Input, OnInit } from '@angular/core';
import { PexipParticipant } from 'src/app/pexip-participant/pexip-participant.model';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { MeetingHistory } from '../../history-meeting.model';
import { ParticipantDetailsHistoryMeetingsService } from './participant-details-history-meetings.service';

@Component({
    selector: 'app-participant-details-history-meetings',
    templateUrl: './participant-details-history-meetings.component.html',
    styleUrls: ['./participant-details-history-meetings.component.scss'],
    providers: [ParticipantDetailsHistoryMeetingsService],
})
export class ParticipantDetailsHistoryMeetingsComponent implements OnInit {
    @Input() selectedHistoryMeeting: MeetingHistory;
    @Input() currentConnectedUser: ConnectedUser;
    selectedParticipant: PexipParticipant | null;
    participants: PexipParticipant[];
    kloodDateFormat = KloodDateFormat;
    oby = 'displayName';

    constructor(private datesManager: DatesManager, private participantDetailsHistoryMeetingsService: ParticipantDetailsHistoryMeetingsService) { }

    ngOnInit(): void {
        this.getParticipants();
    }

    getParticipants(): void {
        this.participantDetailsHistoryMeetingsService.getParticipants(this.selectedHistoryMeeting.id).subscribe((participants: PexipParticipant[]) => {
            this.participants = participants;
        })
    }

    getConnectedTime(participant: PexipParticipant): string | number {
        let timeSeconds: string | number = 0;
        if (participant.startTime && participant.endTime) {
            timeSeconds = (new Date(participant.endTime).getTime() - new Date(participant.startTime).getTime()) / 1000;
            timeSeconds = this.datesManager.secondsToHms(timeSeconds);
        }
        return timeSeconds;
    }

    closeAction(): void {
        this.selectedParticipant = <any>null;
    }

}
