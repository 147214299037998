<div *ngFor="let regexp of obtpRegexps | keyvalue" class="mb-3">
  <app-admin-border-bloc>
    <div class="row align-items-center">
      <div class="col-auto is-bold pe-0">
        {{"ObtpComponent.Target" | translate}} : 
      </div>
      <div class="col">
        {{regexp.key}}
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-auto is-bold pe-0">
        {{"ObtpComponent.FinalLinkFormat" | translate}} : 
      </div>
      <div class="col">
        {{regexp.value.finalMeetingLinkFormat}}
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-auto is-bold pe-0 align-self-start">
        {{"ObtpComponent.Matchers" | translate}} : 
      </div>
      <div class="col">
        <div *ngFor="let matcher of regexp.value.matchers">
          {{matcher}}
        </div>
      </div>
    </div>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary w-100" type="reset" (click)="removeTarget(regexp.key)"><fa-icon [icon]="['fas', 'trash']" class="me-2"></fa-icon>{{"ObtpComponent.Button.Remove" | translate }}</button>
      <button class="btn btn-app app-primary w-100" type="submit" (click)="verifyTarget(regexp.key, regexp.value)"><fa-icon [icon]="['fas', 'bell']" class="me-2"></fa-icon>{{"ObtpComponent.Button.Verify" | translate }}</button>
    </div>
  </app-admin-border-bloc>
</div>
<div *ngIf="!addingNewTarget" class="mb-5">
  <button class="btn btn-app app-primary" type="submit" (click)="addingNewTarget = true;">
    <fa-icon [icon]="['fas', 'plus-circle']" class="me-2"></fa-icon>{{"ObtpComponent.Button.AddTarget" | translate }}
  </button>
</div>
<div *ngIf="addingNewTarget" class="mb-5">
  <app-admin-border-bloc>
    <form #addNewTargetForm="ngForm" id="addNewTargetForm" name="addNewTargetForm">
      <app-input-form-field [nameInput]="'target'" [typeInput]="'text'" [myInputModel]="newRegexpTarget" [textLabel]="'ObtpComponent.Target'" [form]="addNewTargetForm" class="form-control" [required]="true"></app-input-form-field>
      <app-input-form-field [nameInput]="'finalMeetingLinkFormat'" [typeInput]="'text'" [myInputModel]="newRegexpConfig.finalMeetingLinkFormat" [textLabel]="'ObtpComponent.FinalLinkFormat'" [form]="addNewTargetForm" class="form-control" [required]="true"></app-input-form-field>
      <div class="mb-3">
        <div class="is-bold">{{"ObtpComponent.Matchers" | translate}} :</div>
        <div *ngFor="let matcher of newRegexpConfig.matchers; let i = index;">
          <div class="matchers-input">
            <label for="new-matcher-{{i}}" class="w-100">
              <div class="input-group">
                <input type="text" class="form-control" name="{{matcher}}" id="new-matcher-{{i}}" value="{{matcher}}" placeholder="{{'ObtpComponent.Matcher' | translate }}" readonly>
                <div class="input-group-append">
                  <button class="btn btn-app" type="button" (click)="dropNewMatcher(matcher)">
                    {{"Button.Delete" | translate }}
                  </button>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!addingNewMatcher">
        <div class="mb-3">
          <button class="btn btn-app app-primary" type="submit" (click)="addingNewMatcher = true;">
            <fa-icon [icon]="['fas', 'plus-circle']" class="me-2"></fa-icon>{{"ObtpComponent.Button.AddMatcher" | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="addingNewMatcher">
        <div class="matchers-input">
          <label for="newMatcher" class="w-100">
            <div class="input-group">
              <input type="text" class="form-control" name="newMatcher" id="newMatcher" [(ngModel)]="newMatcher" placeholder="{{'ObtpComponent.Matcher' | translate }}" required>
              <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)="addingNewMatcher = false; newMatcher = '';">
                  {{"Button.Cancel" | translate }}
                </button>
                <button class="btn btn-app" type="button" (click)="addNewMatcher(newMatcher)" [disabled]="!newMatcher">
                {{"Button.Add" | translate }}
                </button>
              </div>
            </div>
            <span [ngClass]="{'light': false}">{{"ObtpComponent.Matcher" | translate }}</span>
            <div class="form-text is-third" *ngIf="false">{{'InputFormField.IsInvalid'|translate}}</div>
          </label>
        </div>
      </ng-container>
      <div class="dual-button-container">
        <button class="btn btn-app app-secondary w-100" type="reset" (click)="addingNewTarget = false;">{{"ObtpComponent.Button.Cancel" | translate }}</button>
        <button class="btn btn-app app-primary w-100" type="submit" [disabled]="!addNewTargetForm.valid" (click)="addNewTarget(addNewTargetForm)">{{"ObtpComponent.Button.Add" | translate }}</button>
      </div>
      <div class="is-third fields-error" *ngIf="addNewTargetForm.submitted && !addNewTargetForm.valid">{{'ObtpComponent.Form.SomeFields' | translate}}</div>
    </form>
  </app-admin-border-bloc>
</div>
<div class="mt-5">
  <div class="dual-button-container">
    <button class="btn btn-app app-secondary w-100" type="reset" (click)="cancelEditConfig()">
      <fa-icon [icon]="['fas', 'ban']" class="me-2"></fa-icon>{{"ObtpComponent.Button.CancelEdit" | translate }}
    </button>
    <button class="btn btn-app app-primary w-100" type="submit" (click)="saveConfig()">
      <fa-icon [icon]="['fas', 'check']" class="me-2"></fa-icon>{{"ObtpComponent.Button.ValidEdit" | translate }}
    </button>
  </div>
</div>

<div class="modal fade custom-app-modal" id="modalVerifyRegexp" role="dialog" aria-labelledby="modalVerifyRegexpLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content flat">
      <ng-container *ngIf="selectedTarget && selectedRegexp">
        <div class="modal-header">
          <ng-container *ngIf="!hasResult">
            <h3 class="modal-title text-center" id="modalVerifyRegexpLabel">{{"ObtpComponent.Verify.Check.Title" | translate}}</h3>
          </ng-container>
          <ng-container *ngIf="hasResult">
            <h3 class="modal-title text-center" id="modalVerifyRegexpLabel">{{"ObtpComponent.Verify.Result.Title" | translate}}</h3>
          </ng-container>            
        </div>
        <div class="modal-body">
          <ng-container *ngIf="!hasResult">
            <app-input-field [nameInput]="'target'" [typeInput]="'text'" [myInputModel]="selectedTarget" [textLabel]="'ObtpComponent.Target'" class="form-control" [readonly]="true"></app-input-field>
            <app-input-field [nameInput]="'finalMeetingLinkFormat'" [typeInput]="'text'" [myInputModel]="selectedRegexp.finalMeetingLinkFormat" [textLabel]="'ObtpComponent.FinalLinkFormat'" class="form-control" [readonly]="true"></app-input-field>
            <div class="mb-3">
              <div class="ms-2">{{"ObtpComponent.Matchers" | translate}} :</div>
              <div *ngFor="let matcher of selectedRegexp.matchers; let i = index;">
                <div class="matchers-input">
                  <label for="selectMatcher-{{i}}" class="w-100">
                    <input type="text" class="form-control no-label" name="{{matcher}}" id="selectMatcher-{{i}}" value="{{matcher}}" placeholder="{{'ObtpComponent.Matcher' | translate }}" readonly>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-control">
              <label for="verifyContent">
                <textarea
                    #inputModel="ngModel"
                    name="verifyContent"
                    id="verifyContent"
                    placeholder="{{'ObtpComponent.Verify.Content'|translate}}"
                    [(ngModel)]="verifyContent"
                    [required]="true"
                    class="scrollable"
                ></textarea>
                <span>{{"ObtpComponent.Verify.Content"|translate}}</span>
              </label>
            </div>
            <div class="dual-button-container">
              <button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="cancelVerifyTarget()">
                {{"Button.Cancel" | translate}}
              </button>
              <button class="btn btn-sm btn-app app-primary w-100" type="submit" (click)="confirmVerifyTarget()">
                {{"ObtpComponent.Button.Verify" | translate}}
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="hasResult">
            <ng-container *ngIf="!verifiedTarget">
              {{"ObtpComponent.Verify.Result.NoDetails" | translate}}
            </ng-container>
            <ng-container *ngIf="verifiedTarget">
              <div class="row align-items-center">
                <div class="col is-bold is-underline">
                  {{"ObtpComponent.Verify.Result.RetrievedMeeting" | translate}} : 
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">
                  {{"ObtpComponent.Verify.Result.ProviderName" | translate}} : 
                </div>
                <div class="col">
                  {{verifiedTarget.retrievedMeeting.providerName}}
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">
                  {{"ObtpComponent.Verify.Result.Found" | translate}} : 
                </div>
                <div class="col">
                  <div *ngIf="verifiedTarget.retrievedMeeting.found"><fa-icon [icon]="['fas', 'check']" class="me-2 is-valid"></fa-icon>{{"ObtpComponent.Verify.Result.Yes" | translate}}</div>
                  <div *ngIf="!verifiedTarget.retrievedMeeting.found"><fa-icon [icon]="['fas', 'times']" class="me-2 is-invalid"></fa-icon>{{"ObtpComponent.Verify.Result.No" | translate}}</div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto is-bold pe-0 align-self-start">
                  {{"ObtpComponent.Verify.Result.MeetingId" | translate}} : 
                </div>
                <div class="col">
                  {{verifiedTarget.retrievedMeeting.meetingId}}
                </div>
              </div>
              <div class="row align-items-center mb-3">
                <div class="col-auto is-bold pe-0 align-self-start">
                  {{"ObtpComponent.Verify.Result.FinalMeetingLink" | translate}} : 
                </div>
                <div class="col">
                  {{verifiedTarget.retrievedMeeting.finalMeetingLink}}
                </div>
              </div>
              <div class="accordion" id="accordionDetailsResult">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingAccordionDetailsResult">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseAccordionDetailsResult'" aria-expanded="false" [attr.aria-controls]="'collapseAccordionDetailsResult'">
                      {{"OfferViewComponent.MoreInfos" | translate}}
                    </button>
                  </h2>
                  <div id="collapseAccordionDetailsResult" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingAccordionDetailsResult'" data-bs-parent="#accordionDetailsResult">
                    <div class="accordion-body">
                      <div class="mt-3">
                        <app-input-field [nameInput]="'finalMeetingLinkFormat'" [typeInput]="'text'" [myInputModel]="verifiedTarget.matcher.finalMeetingLinkFormat" [textLabel]="'ObtpComponent.FinalLinkFormat'" [readonly]="true"></app-input-field>
                        <div class="mb-3">
                          <div class="ms-2">{{"ObtpComponent.Matchers" | translate}} :</div>
                          <div *ngFor="let matcher of verifiedTarget.matcher.matchers; let i = index;">
                            <div class="matchers-input">
                              <label for="resultMatcher-{{i}}" class="w-100">
                                <input type="text" class="form-control no-label" name="{{matcher}}" id="resultMatcher-{{i}}" value="{{matcher}}" placeholder="{{'ObtpComponent.Matcher' | translate }}" readonly>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="form-control">
                          <label for="verifyContent">
                            <textarea
                                #inputModel="ngModel"
                                name="verifyContent"
                                id="verifyContent"
                                placeholder="{{'ObtpComponent.Verify.Content'|translate}}"
                                [(ngModel)]="verifiedTarget.originalContent"
                                class="scrollable"
                                readonly
                            ></textarea>
                            <span>{{"ObtpComponent.Verify.Content"|translate}}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="mt-3">
              <button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="okVerify()">
                {{"Button.Ok" | translate}}
              </button>
            </div>
          </ng-container>
          
        </div>
      </ng-container>
    </div>
  </div>
</div>
