import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { ValidatorsList } from 'src/app/validators/validators.model';
import { AlternativeDomains, Network, Offer } from '../offer.model';
import { OfferFormService } from './offer-form.service';

@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.scss'],
  providers: [OfferFormService]
})
export class OfferFormComponent implements OnInit {
  @Input() connectedUser: ConnectedUser;
  @Input() tenant: Tenant;
  @Input() offer: Offer;
  @Input() kind: string;
  @Output() cancelEdit = new EventEmitter<boolean>();
  editedOffer: Offer;
  nowDate = new Date();
  illimity = false;
  recordRetentions = [
    {
      label: 'OfferFormComponent.RecordRetention.Unlimited',
      value: 0,
    },
    {
      label: 'OfferFormComponent.RecordRetention.72h',
      value: 1,
    },
    {
      label: 'OfferFormComponent.RecordRetention.30d',
      value: 2,
    },
    {
      label: 'OfferFormComponent.RecordRetention.180d',
      value: 3,
    },
  ];
  newNetwork: Network;
  validators = ValidatorsList;
  addNewNetwork: boolean;
  netWorksList: Network[] = [];
  addNewAlternativeDomain: boolean;
  alternativeDomains: string;
  alternativeDomainsList: string[] = [];

  constructor(private notif: NotifService, private translate: TranslateService, private offerService: OfferFormService) { }

  ngOnInit(): void {
    this.editedOffer = new Offer({ ...this.offer });
    this.netWorksList = [...this.offer.networks];
    this.alternativeDomainsList = [...this.offer.alternativeDomains];
    this.illimity = this.editedOffer.count === -1 ? true : false;
  }

  onDateSelect(date: Date): void {
    this.editedOffer.expiresAt = new Date(date.toISOString());
  }

  onCheckIllimity(illimity: boolean): void {
    if (illimity) {
      this.editedOffer.count = -1;
    } else {
      this.editedOffer.count = this.offer.count;
    }
    this.illimity = illimity;
  }

  onCheckEnableLobbyByPass(enable: boolean): void {
    this.editedOffer.enableLobbyBypass = enable;
  }

  onCheckFilterTrustedDevicesOnPexipLocation(filterPexipLocation: boolean): void {
    this.editedOffer.filterTrustedDevicesOnPexipLocation = filterPexipLocation;
  }

  onCheckFilterTrustedDevicesOnPexipRegistration(filterPexipRegistration: boolean): void {
    this.editedOffer.filterTrustedDevicesOnPexipRegistration = filterPexipRegistration;
  }

  cancelAddNetwork(): void {
    this.addNewNetwork = false;
    this.newNetwork = {
      ip: '',
      mask: 0,
    };
  }

  validAddNetwork(newNet: Network): void {
    if (this.netWorksList.length > 0) {
      if (this.netWorksList.some((el) => el.ip === newNet.ip)) {
        let msg = newNet.ip;
        msg += ' ';
        msg += this.translate.instant('OfferFormComponent.AlreadyTrustedNetwork');
        this.notif.addErrorNotif(msg);
      } else {
        this.netWorksList.push(newNet);
        this.addNewNetwork = false;
        this.newNetwork = {
          ip: '',
          mask: 0,
        };
      }
    } else {
      this.netWorksList.push(newNet);
      this.addNewNetwork = false;
      this.newNetwork = {
        ip: '',
        mask: 0,
      };
    }
  }

  removeNetwork(network: Network): void {
    if (this.netWorksList.length > 0) {
      let l = this.netWorksList.length;
      while (l--) {
        if (this.netWorksList[l].ip === network.ip) {
          this.netWorksList.splice(l, 1);
        }
      }
    }
  }

  cancelAddAlternativeDomain(): void {
    this.addNewAlternativeDomain = false;
    this.alternativeDomains = '';
  }

  validAddAlternativeDomains(alternativeDomain: AlternativeDomains): void {
    const domain = alternativeDomain.alternativeDomains;
    if (this.alternativeDomainsList.length > 0) {
      if (this.alternativeDomainsList.some((el) => el === domain)) {
        let msg = domain;
        msg += ' ';
        msg += this.translate.instant('OfferFormComponent.AlreadyAlternativeDomain');
        this.notif.addErrorNotif(msg);
      } else {
        this.alternativeDomainsList.push(domain);
        this.addNewAlternativeDomain = false;
        this.alternativeDomains = '';
      }
    } else {
      this.alternativeDomainsList.push(domain);
      this.addNewAlternativeDomain = false;
      this.alternativeDomains = '';
    }
  }

  removeAlternativeDomain(domain: string): void {
    if (this.alternativeDomainsList.length > 0) {
      let l = this.alternativeDomainsList.length;
      while (l--) {
        if (this.alternativeDomainsList[l] === domain) {
          this.alternativeDomainsList.splice(l, 1);
        }
      }
    }
  }

  validate(offerFormValue: any, kind: string, offer: Offer, tenant: Tenant): void {
    if (kind === 'TEAMS_GATEWAY' || kind === 'HANGOUT_GATEWAY') {
      offerFormValue.alternativeDomains = this.alternativeDomainsList;
      offerFormValue.networks = this.netWorksList;
    }
    const finalOffer: Offer = new Offer({ ...offer, ...offerFormValue });
    const translateValueParameter = this.translate.instant('OfferFormComponent.Offer.' + kind);
    tenant.offers[kind] = finalOffer;
    this.offerService.saveTenant(tenant).subscribe({
      next: (tenant: Tenant) => {
        this.offer = tenant.offers[kind];
        const successNotif = this.translate.instant('OfferFormComponent.SUCCESS.Edit', { value: translateValueParameter });
        this.notif.addSuccessNotif(successNotif);
      },
      error: (error) => {
        const errorNotif = this.translate.instant('OfferFormComponent.ERROR.Edit', { value: translateValueParameter });
        this.notif.addErrorNotif(errorNotif, error);
      }
    });
    this.cancel();
  }

  cancel(): void {
    this.cancelEdit.emit(true);
  }
}
