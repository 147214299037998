import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { RequestFilterBuilder } from "src/app/services/request-filter-builder/request-filter-builder";
import { suggestPattern } from "src/app/operators/mongo-operators/mongo-operators";
import { ActivatedRoute } from "@angular/router";
import { PaginationInfo } from "../commons/mat-table-custom/mat-table-custom.model";
import { DataApiOptionService } from "../services/data-api-option/data-api-option.service";
import { CustomHttpParamEncoderService } from "../services/http-encoder/custom-http-param-encoder.service";
import { OauthClient } from "./oauth-client.model";
import { Tenant } from "src/app/tenant/tenant.model";

@Injectable()
export class OauthClientsService {

  constructor(private http: HttpClient, private optionService: DataApiOptionService, private route: ActivatedRoute) {}

  buildParamsUrl(paginationInfo: PaginationInfo, moreParams?: string[]): HttpParams {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() });
    if (moreParams) {
      for (let p in moreParams) {
        let customParam = <string>paginationInfo[<keyof PaginationInfo>moreParams[p]];
        params = params.set(moreParams[p], customParam);
      }
    }
    const filter = new RequestFilterBuilder();
    if (paginationInfo.suggest) {
      filter.set("suggests", suggestPattern(paginationInfo.suggest));
    }
    const q = filter.serialize();
    return (params = params.set("q", q));
  }

  getHead(paginationInfo: PaginationInfo): Observable<number> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        const params = this.buildParamsUrl(paginationInfo);
        return this.http.head(apiUri + "/oauth2/clients", {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: "response",
          withCredentials: true,
        }).pipe(map((result) => parseInt(<string>result.headers.get("Count"))));
      })
      );
  }

  getData(paginationInfo: PaginationInfo): Observable<OauthClient[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        const params = this.buildParamsUrl(paginationInfo, ["sort", "order", "skip", "limit"]);
        return this.http.get<OauthClient[]>(apiUri + "/oauth2/clients", {
          params: params,
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        })
      })
      );
  }

  add(client: OauthClient): Observable<OauthClient> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.post<OauthClient>(apiUri + "/oauth2/clients", client, {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        })
      })
      );
  }

  save(client: OauthClient): Observable<OauthClient> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.put<OauthClient>(apiUri + "/oauth2/clients/"+client.id, client, {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        })
      })
      );
  }

  delete(group: OauthClient): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.delete<unknown>(apiUri + "/oauth2/clients/"+group.id, {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        })
      })
      );
  }

  getOauthClients(): Observable<OauthClient[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<OauthClient[]>(apiUri + "/oauth2/clients", {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        })
      })
      );
  }

  getOauthClient(groupId: string): Observable<OauthClient> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<OauthClient>(apiUri + "/oauth2/clients/"+groupId, {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        })
      })
      );
  }

  getTenants(): Observable<Tenant[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<Tenant[]>(apiUri + "/tenants", {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        })
      })
      );
  }

}
