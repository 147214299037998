import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { DatesManager } from "src/app/services/dates/dates.manager";
import { RequestFilterBuilder } from "src/app/services/request-filter-builder/request-filter-builder";
import { dateGreaterOrEqual, dateLowerOrEqual } from "src/app/operators/mongo-operators/mongo-operators";
import { DataApiOptionService } from "../data-api-option/data-api-option.service";
import { CustomHttpParamEncoderService } from "../http-encoder/custom-http-param-encoder.service";
import { ActivatedRoute } from "@angular/router";

@Injectable()
export class ExportService {
  tenantId: Observable<string> = of("");
  constructor(private route: ActivatedRoute, private http: HttpClient, private optionService: DataApiOptionService, private datesManager: DatesManager) {
    this.tenantId = <Observable<string>>this.route.parent?.paramMap.pipe(map((params) => params.get("tenantId")));
  }

  exportData(url: string, fields: string, startDate?: Date, endDate?: Date): Observable<string> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() }).set("fields", fields);
            const filter = new RequestFilterBuilder();
            if (startDate) {
              filter.set("startedAt", dateGreaterOrEqual(this.datesManager.fromStartDay(startDate)));
            }
            if (endDate) {
              filter.set("terminatedAt", dateLowerOrEqual(this.datesManager.toEndDay(endDate)));
            }
            const q = filter.serialize();
            params = params.set("q", q);
            return this.http
              .get(apiUri + url, {
                params: params,
                headers: this.optionService.getHeadersExportCsv(),
                responseType: "text",
                withCredentials: true,
              })
              .pipe(map((data) => data));
          })
        );
      })
    );
  }

  exportDataScheduledMeetings(url: string, fields: string, startDate?: Date, endDate?: Date): Observable<string> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() }).set("fields", fields);
            params = params.set("showDeleted", "true");
            const filter = new RequestFilterBuilder();
            if (startDate) {
              filter.set("beginDate", dateGreaterOrEqual(this.datesManager.fromStartDay(startDate)));
            }
            if (endDate) {
              filter.set("endDate", dateLowerOrEqual(this.datesManager.toEndDay(endDate)));
            }
            const q = filter.serialize();
            params = params.set("q", q);
            return this.http
              .get(apiUri + url, {
                params: params,
                headers: this.optionService.getHeadersExportCsv(),
                responseType: "text",
                withCredentials: true,
              })
              .pipe(map((data) => data));
          })
        );
      })
    );
  }

  exportDataHistoryMeetings(url: string, fields: string, startDate?: Date, endDate?: Date): Observable<string> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() }).set("fields", fields);
            const filter = new RequestFilterBuilder();
            if (startDate) {
              filter.set("startedAt", dateGreaterOrEqual(this.datesManager.fromStartDay(startDate)));
            }
            if (endDate) {
              filter.set("terminatedAt", dateLowerOrEqual(this.datesManager.toEndDay(endDate)));
            }
            const q = filter.serialize();
            params = params.set("q", q);
            return this.http
              .get(apiUri + url, {
                params: params,
                headers: this.optionService.getHeadersExportCsv(),
                responseType: "text",
                withCredentials: true,
              })
              .pipe(map((data) => data));
          })
        );
      })
    );
  }

  exportDataUsers(url: string, fields: string): Observable<string> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() }).set("fields", fields);
            const filter = new RequestFilterBuilder();
            const q = filter.serialize();
            params = params.set("q", q);
            return this.http
              .get(apiUri + url, {
                params: params,
                headers: this.optionService.getHeadersExportCsv(),
                responseType: "text",
                withCredentials: true,
              })
              .pipe(map((data) => data));
          })
        );
      })
    );
  }

}
