<div class="row align-items-center p-3">
  <div class="col">
    <h3 class="m-0">
      <span class="is-bold">{{"AuthorizationsComponent.Title" | translate}}</span>
    </h3>
  </div>
  <div class="col-2 text-center">
    {{"AuthorizationsComponent.TargetOrganizationTenantForUserAuthorizations" | translate}} :
  </div>
  <div class="col-3">
    <app-input-dropdown-field *ngIf="tenants" [defaultText]="'AuthorizationsComponent.Role'" [modelKey]="'id'" [displayKey]="'name'" [selectedElement]="targetTenant" [dataSource]="tenants" (selectedData)="onSelectTenant($event)" [disabled]="isTenantSelectorLocked"></app-input-dropdown-field>
  </div>
  <div class="col-auto">
    <button class="btn btn-app app-primary float-end" type="submit" (click)="isTenantSelectorLocked=!isTenantSelectorLocked">
      <span [hidden]="!isTenantSelectorLocked">
        <fa-icon [icon]="['fas', 'lock-open']" class="mx-1"></fa-icon>{{"AuthorizationsComponent.UnlockSelector" | translate}}
      </span>
      <span [hidden]="isTenantSelectorLocked">
        <fa-icon [icon]="['fas', 'lock']" class="mx-1"></fa-icon>{{"AuthorizationsComponent.LockSelector" | translate}}
      </span>
    </button>
  </div>
</div>
<app-admin-card>
  <div class="custom-table-filter" id="custom-table-container" *ngIf="paginatorReady">
    <mat-form-field appearance="outline" class="mat-form-field-custom" id="custom-search-2">
      <mat-icon matPrefix>
        <fa-icon [icon]="['fas', 'search']"></fa-icon>
      </mat-icon>
      <input matInput (ngModelChange)="onSuggest($event)" [(ngModel)]="paginationInfo.suggest" name="search" placeholder="{{'MatTable.Search' | translate}}" />
    </mat-form-field>
    <div class="border-container" id="custom-table">
      <table mat-table [dataSource]="dataSource" [matSortActive]="paginationInfo.sort" [matSortDirection]="paginationInfo.order" matSort (matSortChange)="onSort($event);" class="custom-table" [hidden]="loading">
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"AuthorizationsComponent.Email" | translate}}</th>
          <td mat-cell *matCellDef="let user" attr.data-th='{{"AuthorizationsComponent.email" | translate}}' class="small-right">{{user.email}}</td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"AuthorizationsComponent.Role" | translate}}</th>
          <td mat-cell *matCellDef="let user" attr.data-th='{{"AuthorizationsComponent.email" | translate}}' class="small-right">
            <app-input-dropdown-field [defaultText]="'AuthorizationsComponent.Role'" [modelKey]="'value'" [displayKey]="'label'" [selectedElement]="user.role" [dataSource]="userRoles" (selectedData)="user.role=$event.value;changeRole(user)" [disabled]="isUserDisabled(user)"></app-input-dropdown-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="acls">
          <th mat-header-cell *matHeaderCellDef class=" w-50" mat-sort-header>{{"AuthorizationsComponent.Acls" | translate}}</th>
          <td mat-cell *matCellDef="let user" attr.data-th='{{"acls" | translate}}' class="small-right">
            <div *ngIf="!user.hasRole('ADMIN') && !user.hasRole('MARKETING')">{{"AuthorizationsComponent.NoAclsNeededForThisUser" | translate}}</div>
            <div class="row" *ngIf="user.hasRole('ADMIN') || user.hasRole('MARKETING')">
              <div class="col-auto px-2">
                <app-checkbox-field [textLabel]="'AuthorizationsComponent.UsersAcl'" [nameInput]="'AuthorizationsComponent.UsersAcl'+user.id" [myInputModel]="isUserChecked(user, 'USERS')" [checked]="isUserChecked(user, 'USERS')" (checkedEvent)="switchAcl($event, user, targetTenant, 'USERS')" [disabled]="isUserDisabled(user)" #tooltip="matTooltip" matTooltip="{{'AuthorizationsComponent.Tooltip.Users' | translate}}" matTooltipClass="custom-tooltip"></app-checkbox-field>
              </div>
              <div class="col-auto">
                <app-checkbox-field [textLabel]="'AuthorizationsComponent.TrialsAcl'" [nameInput]="'AuthorizationsComponent.TrialsAcl'+user.id" [myInputModel]="isUserChecked(user, 'TRIALS')" [checked]="isUserChecked(user, 'TRIALS')" (checkedEvent)="switchAcl($event, user, targetTenant, 'TRIALS')" [disabled]="isUserDisabled(user)" #tooltip="matTooltip" matTooltip="{{'AuthorizationsComponent.Tooltip.Trials' | translate}}" matTooltipClass="custom-tooltip"></app-checkbox-field>
              </div>
              <div class="col-auto">
                <app-checkbox-field [textLabel]="'AuthorizationsComponent.MeetingRoomsAcl'" [nameInput]="'AuthorizationsComponent.MeetingRoomsAcl'+user.id" [myInputModel]="isUserChecked(user, 'MEETING_ROOMS')" [checked]="isUserChecked(user, 'MEETING_ROOMS')" (checkedEvent)="switchAcl($event, user, targetTenant, 'MEETING_ROOMS')" [disabled]="isUserDisabled(user)" #tooltip="matTooltip" matTooltip="{{'AuthorizationsComponent.Tooltip.MeetingRooms' | translate}}" matTooltipClass="custom-tooltip"></app-checkbox-field>
              </div>
              <div class="col-auto">
                <app-checkbox-field [textLabel]="'AuthorizationsComponent.MeetingsAcl'" [nameInput]="'AuthorizationsComponent.MeetingsAcl'+user.id" [myInputModel]="isUserChecked(user, 'MEETINGS')" [checked]="isUserChecked(user, 'MEETINGS')" (checkedEvent)="switchAcl($event, user, targetTenant, 'MEETINGS')" [disabled]="isUserDisabled(user)" #tooltip="matTooltip" matTooltip="{{'AuthorizationsComponent.Tooltip.Meetings' | translate}}" matTooltipClass="custom-tooltip"></app-checkbox-field>
              </div>
              <div class="col-auto">
                <app-checkbox-field [textLabel]="'AuthorizationsComponent.ConferencesAcl'" [nameInput]="'AuthorizationsComponent.ConferencesAcl'+user.id" [myInputModel]="isUserChecked(user, 'CONFERENCES')" [checked]="isUserChecked(user, 'CONFERENCES')" (checkedEvent)="switchAcl($event, user, targetTenant, 'CONFERENCES')" [disabled]="isUserDisabled(user)" #tooltip="matTooltip" matTooltip="{{'AuthorizationsComponent.Tooltip.Conferences' | translate}}" matTooltipClass="custom-tooltip"></app-checkbox-field>
              </div>
              <div class="col-auto">
                <app-checkbox-field [textLabel]="'AuthorizationsComponent.ManagementAcl'" [nameInput]="'AuthorizationsComponent.ManagementAcl'+user.id" [myInputModel]="isUserChecked(user, 'MANAGEMENT')" [checked]="isUserChecked(user, 'MANAGEMENT')" (checkedEvent)="switchAcl($event, user, targetTenant, 'MANAGEMENT')" [disabled]="isUserDisabled(user)" #tooltip="matTooltip" matTooltip="{{'AuthorizationsComponent.Tooltip.Management' | translate}}" matTooltipClass="custom-tooltip"></app-checkbox-field>
              </div>
              <div class="col-auto">
                <app-checkbox-field [textLabel]="'AuthorizationsComponent.StatsAcl'" [nameInput]="'AuthorizationsComponent.StatsAcl'+user.id" [myInputModel]="isUserChecked(user, 'STATS')" [checked]="isUserChecked(user, 'STATS')" (checkedEvent)="switchAcl($event, user, targetTenant, 'STATS')" [disabled]="isUserDisabled(user)" #tooltip="matTooltip" matTooltip="{{'AuthorizationsComponent.Tooltip.Stats' | translate}}" matTooltipClass="custom-tooltip"></app-checkbox-field>
              </div>
              <div class="col-auto">
                <app-checkbox-field [textLabel]="'AuthorizationsComponent.ContentAcl'" [nameInput]="'AuthorizationsComponent.ContentAcl'+user.id" [myInputModel]="isUserChecked(user, 'CONTENT')" [checked]="isUserChecked(user, 'CONTENT')" (checkedEvent)="switchAcl($event, user, targetTenant, 'CONTENT')" [disabled]="isUserDisabled(user)" #tooltip="matTooltip" matTooltip="{{'AuthorizationsComponent.Tooltip.Content' | translate}}" matTooltipClass="custom-tooltip"></app-checkbox-field>
              </div>
              <div class="col-auto">
                <app-checkbox-field [textLabel]="'AuthorizationsComponent.MasterAcl'" [nameInput]="'AuthorizationsComponent.MasterAcl'+user.id" [myInputModel]="isUserChecked(user, 'MASTER')" [checked]="isUserChecked(user, 'MASTER')" (checkedEvent)="switchAcl($event, user, targetTenant, 'MASTER')" [disabled]="isUserDisabled(user)" #tooltip="matTooltip" matTooltip="{{'AuthorizationsComponent.Tooltip.Master' | translate}}" matTooltipClass="custom-tooltip"></app-checkbox-field>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="paginationInfo.displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: paginationInfo.displayedColumns;"></tr>
      </table>
    </div>
    <div *ngIf="!loading && (dataSource && dataSource.data?.length === 0)" class="ms-2 mt-2">
      <i>{{"MatTable.NoAvailableData" | translate}}...</i>
    </div>
    <mat-paginator *ngIf="!loading" class="mat-paginator-custom" [length]="paginationInfo.listLength" [pageSize]="paginationInfo.pageSize" [pageIndex]="paginationInfo.pageIndex" [pageSizeOptions]="paginationInfo.pageSizeOptions" showFirstLastButtons (page)="onChangePagination($event)"></mat-paginator>
  </div>
  <div class="row d-flex justify-content-center align-items-center my-5" *ngIf="loading">
    <div class="col-auto my-auto">
      <app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
    </div>
  </div>
</app-admin-card>