import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ContactService } from 'src/app/contact/contact.service';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { Contact } from 'src/app/contact/contact.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import Debug from 'debug';
const debug = Debug('virtuvisio-administration:coorganizers-list-component');

@Component({
	selector: 'app-coorganizers-list',
	templateUrl: './coorganizers-list.component.html',
	styleUrls: ['./coorganizers-list.component.scss'],
	providers: [ContactService]
})
export class CoorganizersListComponent implements OnInit, OnDestroy {
	@Input() coorganizersList: string[];
	@Input() validateMailBeforeValidate: EventEmitter<any>;
	@Input() noChange: boolean;
	@Input() editing: boolean;
	@Input() connectedUser: ConnectedUser;
	@Output() checkEmailDone: EventEmitter<any> = new EventEmitter<any>();
	emailCoorganizer = '';
	contactsSuggest: any[];
	displaySuggest = false;
	isCoOrganized = false;
	private onInitSubscriptions: Subscription[] = [];

	constructor(private contactService: ContactService, private translateService: TranslateService, private validatorService: ValidatorService, private notif: NotifService) { }

	ngOnInit(): void {
		if (this.validateMailBeforeValidate) {
			this.onInitSubscriptions.push(this.validateMailBeforeValidate.subscribe((data) => {
				this.addCoorganizerFromSubmit(data);
			}));
		}
		if (this.coorganizersList?.length > 0) {
			this.isCoOrganized = true;
		}
	}

	ngOnDestroy(): void {
		this.onInitSubscriptions.forEach((subscription) => {
			subscription.unsubscribe();
		});
	}

	setCoOrganized(ev: boolean): void {
		this.isCoOrganized = ev;
		if (ev) {
			this.addManagerToCoorganizer();
		} else {
			this.coorganizersList = [];
		}
	}

	addManagerToCoorganizer(): void {
		if (this.coorganizersList.some((el) => el === this.connectedUser.email)) {
			let msg = this.connectedUser.email;
			msg += ' ';
			msg += this.translateService.instant('Coorganizer.AlreadyInList');
			this.notif.addErrorNotif(msg);
		} else {
			this.coorganizersList.push(this.connectedUser.email);
			debug(this.coorganizersList);
		}
	}

	editCoorganizer(coorganizer: string): void {
		for (let i = 0; i < this.coorganizersList?.length; i++) {
			if (this.coorganizersList[i] === coorganizer) {
				this.emailCoorganizer = this.coorganizersList[i];
				this.coorganizersList.splice(i, 1);
			}
		}
	}

	removeCoorganizer(coorganizer: string): void {
		for (let i = 0; i < this.coorganizersList?.length; i++) {
			if (this.coorganizersList[i] === coorganizer) {
				this.coorganizersList.splice(i, 1);
			}
		}
	}

	addCoorganizer(event: KeyboardEvent | FocusEvent): void {
		const e = <KeyboardEvent>event;
		const key = e.key;
		const code = e.code;
		let split;
		if (e.key && e.code) {
			if ((key === 'Enter' && code === 'Enter') || (key === ' ' && code === 'Space') || (key === ',' && code === 'KeyM') || (key === ',' && code === 'Comma') || (key === ';' && code === 'Comma') || (key === ';' && code === 'Semicolon') || e.type === 'blur') {
				if (this.emailCoorganizer && this.emailCoorganizer?.length > 0) {
					if (this.emailCoorganizer.indexOf(',') !== -1) {
						split = ',';
						this.coorganizerLoop(this.emailCoorganizer, split);
					} else if (this.emailCoorganizer.indexOf(';') !== -1) {
						split = ';';
						this.coorganizerLoop(this.emailCoorganizer, split);
					} else if (this.emailCoorganizer.indexOf(' ') !== -1) {
						split = ' ';
						this.coorganizerLoop(this.emailCoorganizer, split);
					} else {
						if (this.validatorService.validateEmail(this.emailCoorganizer)) {
							let coorganizerTemp;
							if (this.coorganizersList?.length > 0) {
								if (this.coorganizersList.some((el) => el === this.emailCoorganizer)) {
									let msg = this.emailCoorganizer;
									msg += ' ';
									msg += this.translateService.instant('Coorganizer.AlreadyInList');
									this.notif.addErrorNotif(msg);
								} else {
									coorganizerTemp = this.emailCoorganizer;
									this.coorganizersList.push(coorganizerTemp);
								}
							} else {
								coorganizerTemp = this.emailCoorganizer;
								this.coorganizersList.push(coorganizerTemp);
							}
							this.emailCoorganizer = '';
						} else {
							this.notif.addErrorNotif('Coorganizer.NotValidMail');
						}
					}
					e.preventDefault();
				} else {
					this.emailCoorganizer = '';
					e.preventDefault();
				}
			} else if (key === 'Backspace' && code === 'Backspace' && !this.emailCoorganizer && this.coorganizersList && this.coorganizersList?.length > 0) {
				this.emailCoorganizer = this.coorganizersList[this.coorganizersList?.length - 1];
				this.coorganizersList.pop();
				e.preventDefault();
			}
		}
	}

	checkContact(): void {
		if (this.emailCoorganizer !== '' && this.emailCoorganizer) {
			this.contactService.getSuggest(this.emailCoorganizer).subscribe({
				next: (contactsSuggest) => {
					this.contactsSuggest = contactsSuggest;
					let l = this.contactsSuggest?.length;
					while (l--) {
						if (!this.contactsSuggest[l]['email']) {
							this.contactsSuggest.splice(l, 1);
						}
						for (const a in this.coorganizersList) {
							if (this.coorganizersList[a] && this.contactsSuggest[l]) {
								if (this.coorganizersList[a] === this.contactsSuggest[l]) {
									this.contactsSuggest.splice(l, 1);
								}
							}
						}
					}
					if (this.contactsSuggest?.length > 0) {
						this.displaySuggest = true;
					}
				},
				error: (error) => {
					debug('%O', error);
					this.notif.addErrorNotif('Contact.Error.Get');
				}
			});
		} else {
			this.displaySuggest = false;
		}
	}

	addContactToCoorganizer(contact: Contact): void {
		let coorganizerTemp;
		if (this.coorganizersList?.length > 0) {
			if (this.coorganizersList.some((el) => el === contact.email)) {
				let msg = contact.email;
				msg += ' ';
				msg += this.translateService.instant('Coorganizer.AlreadyInList');
				this.notif.addErrorNotif(msg);
			} else {
				coorganizerTemp = contact.email;
				this.coorganizersList.push(coorganizerTemp);
			}
		} else {
			coorganizerTemp = contact.email;
			this.coorganizersList.push(coorganizerTemp);
		}
		this.emailCoorganizer = '';
		this.displaySuggest = false;
	}

	addCoorganizerFromSubmit(data: any): void {
		let split;
		if (this.emailCoorganizer && this.emailCoorganizer?.length > 0) {
			if (!this.coorganizersList.find((c) => c === this.emailCoorganizer)) {
				if (this.emailCoorganizer.indexOf(',') !== -1) {
					split = ',';
					this.coorganizerLoop(this.emailCoorganizer, split);
				} else if (this.emailCoorganizer.indexOf(';') !== -1) {
					split = ';';
					this.coorganizerLoop(this.emailCoorganizer, split);
				} else if (this.emailCoorganizer.indexOf(' ') !== -1) {
					split = ' ';
					this.coorganizerLoop(this.emailCoorganizer, split);
				} else {
					if (this.validatorService.validateEmail(this.emailCoorganizer)) {
						const coorganizerTemp = this.emailCoorganizer;
						this.coorganizersList.push(coorganizerTemp);
						data.coorganizersEmailOk = true;
						this.checkEmailDone.emit(data);
					} else {
						this.notif.addErrorNotif('Coorganizer.NotValidMail');
						data.coorganizersEmailOk = false;
						this.checkEmailDone.emit(data);
					}
				}
			} else {
				data.coorganizersEmailOk = true;
				this.checkEmailDone.emit(data);
			}
		} else {
			data.coorganizersEmailOk = true;
			this.checkEmailDone.emit(data);
		}
	}

	coorganizerLoop(emailList: string, split: string): void {
		let list: any[];
		if (emailList.endsWith(split)) {
			emailList = emailList.substr(0, emailList?.length - 1);
		}
		list = emailList.split(split);
		for (const j in list) {
			list[j] = list[j].trim();
		}
		// set only allow unique value, all duplicates will be removes
		// spread operatror... convert set back to an array
		list = [...new Set(list)];
		let l = list?.length;
		while (l--) {
			if (this.validatorService.validateEmail(list[l])) {
				if (this.coorganizersList.some((el) => el === list[l])) {
					let msg = list[l];
					msg += ' ';
					msg += this.translateService.instant('Coorganizer.AlreadyInList');
					this.notif.addErrorNotif(msg);
				} else {
					const coorganizerTemp = list[l];
					this.coorganizersList.push(coorganizerTemp);
					list.splice(l, 1);
				}
			} else {
				this.notif.addErrorNotif('Coorganizer.NotValidMail');
			}
		}
		if (list?.length > 0) {
			this.emailCoorganizer = '';
			for (const i in list) {
				this.emailCoorganizer += list[i];
				this.emailCoorganizer += split;
			}
		} else {
			this.emailCoorganizer = '';
		}
	}

	onSelectContactEvent(contact: Contact): void {
		this.emailCoorganizer = contact.email;
		this.addContactToCoorganizer(contact);
	}

	onCloseSuggestEvent(value: boolean): void {
		this.displaySuggest = value;
	}
}
