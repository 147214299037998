export class RequestFilterBuilder {
  filter: any = {};

  set(type: string, value: Serializable): Serializable {
    return (this.filter[type] = value);
  }

  remove(type: string): unknown {
    delete this.filter[type];
    return this.filter;
  }

  serialize(): string {
    const res: any = {};
    for (const key in this.filter) {
      res[key] = this.filter[key].serialize();
    }
    return JSON.stringify(res);
  }
}

interface Serializable {
  serialize(): any;
}
