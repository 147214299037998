export class Group {
    name: string | null;
    id: string;
    companyId: string;
    createdAt: Date;
    deleted: boolean;
    deletedAt: Date;
    remoteId: string;
    tenantId: string;
    updatedAt: Date;
    email: string;
    autoSync: boolean;

    constructor(obj: any) {
        this.name = null;
        Object.assign(this, obj);
    }

    getId(): string {
        return this.id;
    }
}

export interface ImportableGroup {
    name: string;
    description: string;
    ref: string;
}
