import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-custom',
  templateUrl: './spinner-custom.component.html',
  styleUrls: ['./spinner-custom.component.scss'],
})
export class SpinnerCustomComponent {
  @Input() isWhite = true;
}
