<label for="{{nameInput}}">
    <textarea
        #inputModel="ngModel"
        name="{{nameInput}}"
        id="{{nameInput}}"
        placeholder="{{textLabel|translate}}"
        [(ngModel)]="myInputModel"
        [ngModelOptions]="{updateOn: 'change'}"
        [required]="required"
        [disabled]="disabled"
        [readonly]="readonly"
        (blur)="onBlur($event)"
        class="scrollable"
    ></textarea>
    <span>{{textLabel|translate}}</span>
</label>
