import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';

const debug = Debug('virtuvisio-administration:home-administration-component');

@Component({
  selector: 'app-home-administraion',
  templateUrl: './home-administration.component.html',
  styleUrls: ['./home-administration.component.scss'],
})
export class HomeAdministrationComponent {
  connectedUser$: Observable<ConnectedUser>;

  constructor(private sharedService: SharedService) {
    this.connectedUser$ = this.sharedService.currentConnectedUser;
  }

}
