<div class="d-flex w-100 h-100 mx-auto flex-column">
    <app-header></app-header>
    <main role="main" class="cover">
        <div class="home">
            <div class="container-fluid p-2">
                <router-outlet></router-outlet>
            </div>
            <!-- <app-footer class="footer"></app-footer> -->
        </div>
    </main>
</div>

