import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';
import { Tenant } from 'src/app/tenant/tenant.model';
import { SidebarService } from './sidebar.service';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from 'src/app/tenant/tenant.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { QueryParamsManager } from 'src/app/services/query-params-manager/query-params-manager';
import { TranslateService } from '@ngx-translate/core';
const debug = Debug('virtuvisio-administration:sidebar-component');

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [TenantService, QueryParamsManager, SidebarService],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() connectedUser: ConnectedUser;
  private onInitSubscriptions: Subscription[] = [];
  tenants: Tenant[];
  selectedTenant: string;
  tenantId: string;
  currentTenant$: Observable<Tenant>;
  isActive = true;

  constructor(
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private router: Router,
    private tenantService: TenantService,
    private sharedService: SharedService,
    private queryParamsManager: QueryParamsManager,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(this.route.queryParamMap.subscribe((params) => {
      if (params.get('isActive')) {
        if (params.get('isActive') === "true") {
          this.isActive = true;
        } else if (params.get('isActive') === "false") {
          this.isActive = false;
        }
        this.getOrderedTenants(this.isActive);
      }
    }))
    this.onInitSubscriptions.push(this.sharedService.currentUpdateTenantList.subscribe((updateTenantList) => {
      if (updateTenantList) {
        this.queryParamsManager.changeQueryParams({ isActive: true });
      }
    }));
    this.onInitSubscriptions.push(this.route.paramMap.subscribe((params) => {
      this.tenantId = params.get('tenantId') ? (params.get('tenantId') as string) : this.connectedUser.tenantId;
      this.getOrderedTenants(this.isActive);
      this.getCurrentTenant();
    }));
  }

  getOrderedTenants(active: boolean): void {
    this.sidebarService
      .getOrderedTenants('name', 'asc', active)
      .subscribe((tenants: Tenant[]) => {
        this.tenants = tenants;
        if (this.connectedUser.hasRole('SUPER_ADMIN')) {
          const allTenants: Tenant = new Tenant({});
          allTenants.name = this.translate.instant('SidebarComponent.AllTenants');
          allTenants.id = '*';
          this.tenants.unshift(allTenants);
        }
        this.selectedTenant = this.tenantId
          ? this.tenantId
          : this.connectedUser.tenantId;
      });
  }

  setIsActive(ev: boolean) {
    this.isActive = ev;
    this.getOrderedTenants(ev);
    this.queryParamsManager.changeQueryParams({ isActive: this.isActive });
  }

  getCurrentTenant(): void {
    this.currentTenant$ = this.tenantService.getTenant(this.tenantId);
  }

  onSelectedTenant(tenant: Tenant): void {
    let link;
    if (tenant.id === '*') {
      link = ['/administration/' + tenant.id + '/users'];
    } else {
      if (this.router.url.includes('/visio-configuration') && this.connectedUser.isGranted(tenant.id)) {
        link = ['/administration/' + tenant.id + '/visio-configuration'];
      } else if (this.router.url.includes('/consents') && this.connectedUser.isGranted(tenant.id, 'MANAGEMENT')) {
        link = ['/administration/' + tenant.id + '/consents'];
      } else if (this.router.url.includes('/offers') && this.connectedUser.isGranted(tenant.id)) {
        link = ['/administration/' + tenant.id + '/offers'];
      } else if (this.router.url.includes('/users') && this.connectedUser.isGranted(tenant.id, 'USERS')) {
        link = ['/administration/' + tenant.id + '/users'];
      } else if (this.router.url.includes('/groups') && this.connectedUser.isGranted(tenant.id, 'GROUPS')) {
        link = ['/administration/' + tenant.id + '/groups'];
      } else if (this.router.url.includes('/meeting-rooms') && this.connectedUser.isGranted(tenant.id, 'MEETING_ROOMS')) {
        link = ['/administration/' + tenant.id + '/meeting-rooms'];
      } else if (this.router.url.includes('/obtp') && this.connectedUser.isGranted(tenant.id, 'MEETING_ROOMS')) {
        link = ['/administration/' + tenant.id + '/obtp'];
      } else if (this.router.url.includes('/planned-meetings') && this.connectedUser.isGranted(tenant.id, 'MEETINGS')) {
        link = ['/administration/' + tenant.id + '/planned-meetings'];
      } else if (this.router.url.includes('/history-meetings') && this.connectedUser.isGranted(tenant.id, 'MEETINGS')) {
        link = ['/administration/' + tenant.id + '/history-meetings'];
      } else if (this.router.url.includes('/live-conferences') && this.connectedUser.isGranted(tenant.id, 'CONFERENCES')) {
        link = ['/administration/' + tenant.id + '/live-conferences'];
      } else if (this.router.url.includes('/historic-policy-rejects') && this.connectedUser.isGranted(tenant.id, 'MEETINGS')) {
        link = ['/administration/' + tenant.id + '/historic-policy-rejects'];
      } else if (this.router.url.includes('/admin-stats') && this.connectedUser.isGranted(tenant.id, 'STATS')) {
        link = ['/administration/' + tenant.id + '/admin-stats'];
      } else if (this.router.url.includes('/mails-configuration') && this.connectedUser.isGranted(tenant.id, 'CONTENT')) {
        link = ['/administration/' + tenant.id + '/mails-configuration'];
      } else if (this.router.url.includes('/invitations-configuration') && this.connectedUser.isGranted(tenant.id, 'CONTENT')) {
        link = ['/administration/' + tenant.id + '/invitations-configuration'];
      } else {
        link = [this.checkAccess(tenant.id)];
      }
    }
    this.router.navigate(link);
  }

  checkAccess(tenantId: string): string {
    let url = "";
    if (this.connectedUser.isGranted(tenantId)) {
      if (this.connectedUser.hasRole("SUPER_ADMIN")) {
        if (tenantId === "*") {
          url = "/administration/" + tenantId + "/users";
        } else {
          url = "/administration/" + tenantId + "/overview";
        }
      } else if (this.connectedUser.isGranted(tenantId, "MANAGEMENT") && this.connectedUser.isGranted(tenantId, "MEETING_ROOMS") && this.connectedUser.isGranted(tenantId, "USERS")) {
        if (tenantId === "*") {
          url = "/administration/" + tenantId + "/users";
        } else {
          url = "/administration/" + tenantId + "/overview";
        }
      } else {
        const grantedList: string[] = this.connectedUser.grantedList(tenantId) ? this.connectedUser.grantedList(tenantId) as string[] : [];
        const firstList = grantedList[0];
        if (firstList === "USERS") {
          url = "/administration/" + tenantId + "/users";
        } else if (firstList === "TRIALS") {
          url = "/management/trials";
        } else if (firstList === "MEETING_ROOMS") {
          url = "/administration/" + tenantId + "/meeting-rooms/configuration";
        } else if (firstList === "MEETINGS") {
          url = "/administration/" + tenantId + "/meetings";
        } else if (firstList === "CONFERENCES") {
          url = "/administration/" + tenantId + "/live-conferences";
        } else if (firstList === "BOOKINGS") {
          url = "/administration/" + tenantId + "/bookings";
        } else if (firstList === "STATS") {
          url = "/administration/" + tenantId + "/admin-stats";
        } else if (firstList === "CONTENT") {
          url = "/administration/" + tenantId + "/mail/templates";
        } else if (firstList === "BUILDINGS") {
          url = "/administration/" + tenantId + "/location";
        } else if (firstList === "MASTER") {
          url = "/administration/" + tenantId + "/authorization";
        } else {
          url = "";
        }
      }
      return url;
    } else {
      return url;
    }
  }


  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
