import { Offer } from 'src/app/offer/offer.model';
import { AuthConfig } from 'src/app/tenant/authConfig.model';

export class Tenant {
  // need for new
  name: string;
  description: string;
  authConfig: AuthConfig;
  authProvider: string;
  environmentId: string;
  // add by broker
  id: string;
  alias: string;
  allowedServices: string[];
  consented: string[];
  createdAt: Date;
  creator: string;
  defaultLanguage: string;
  deleted: boolean;
  deletedAt: Date;
  disableChat: boolean;
  disabledServices: string[];
  offers: {
    ONE: Offer;
    PRO: Offer;
    GATEWAY: Offer;
    BUSINESS: Offer;
    PHONE: Offer;
    RECORDING: Offer;
    ONE_CODEC: Offer;
    HANGOUT_GATEWAY: Offer;
    TEAMS_GATEWAY: Offer;
    MEAL: Offer;
    SUPPORT: Offer;
    PARKING: Offer;
    WORKPLACE: Offer;
    MEETING_ROOM: Offer;
    VIRTUAL_ROOM: Offer;
    MAPPING: Offer;
    ISSUES: Offer;
    [key: string]: Offer;
  };
  enableOverlayText: boolean;
  ewsConfig: {
    username: string;
    password: string;
    domain: string;
    ewsUrl: string;
  } | null;
  participantLimit: number;
  remoteId: string;
  reportEmail: string;
  shareContacts: boolean;
  support: boolean;
  tenantId: string;
  updatedAt: Date;
  // need to display
  authConfigKey?: string;
  authConfigValue?: string;
  adminEmail: string;
  groupLength: number;
  userLength: number;
  expiresIn: number;
  isExpire: boolean;

  constructor(obj: any) {
    // this.name = null;
    // this.description = null;
    // this.authProvider = null;
    if (!this.allowedServices) {
      this.allowedServices = [];
    }
    if (!this.disabledServices) {
      this.disabledServices = [];
    }
    if (!obj.offers) {
      obj.offers = {};
    }
    for (const o in obj.offers) {
      obj.offers[o] = new Offer(obj.offers[o]);
    }
    obj.authConfig = new AuthConfig(obj.authConfig);
    Object.assign(this, obj);
  }

  getId(): string {
    return this.id;
  }

  hasOffer(offerType: string): boolean {
    if (this.offers && this.offers[offerType]) {
      return true;
    }
    return false;
  }

  hasDisableOffer(offerType: string): boolean {
    if (this.offers && this.offers[offerType].disabledAt) {
      return true;
    }
    return false;
  }

  hasAccess(offerType: string): boolean {
    if (this.offers && this.offers[offerType] && !this.offers[offerType].disabledAt && new Date() < this.offers[offerType].expiresAt) {
      return true;
    }
    return false;
  }

  consentedApp(applications: Map<string, string>, app: string): boolean {
    if (applications && applications.get(app)) {
      if (this.consented && this.consented.indexOf(<string>applications.get(app)) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

export class EwsConfig {
  username: string;
  password: string;
  domain: string;
  ewsUrl: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export interface UpdateTenantEvent {
  refresh: boolean;
  openedOffer: boolean;
}

export interface AppConsent {
  key: string;
  value: {
    id: string;
    requiredScopes: [];
  };
}

export interface ConsentResult {
  appName: string;
  tenantId: string;
  queryParams: {
    tenant: string;
  };
  error: {
    error: string;
    description: string;
    uri: string;
  };
}
