<div *ngIf="connectedUser" class="sidebar-nav">
	<div class="tenant-bar">
		<div>
			<fa-icon class="pointer" [routerLink]="['/administration/'+tenantId+'/add-tenant']" routerLinkActive="active" #tooltip="matTooltip" matTooltip="{{'SidebarComponent.SetupTenant' | translate}}" matTooltipClass="custom-tooltip" id="setupTenant" [icon]="['fas', 'plus-circle']"></fa-icon>
		</div>
		<div class="max-width-200">
			<ng-select class="width-auto" [notFoundText]="'SidebarComponent.NoMatchingTenant' | translate" [clearable]="false" [items]="tenants" bindLabel="name" bindValue="id" [(ngModel)]="selectedTenant" (change)="onSelectedTenant($event)"></ng-select>
		</div>
		<div class="check">
			<app-checkbox-field [nameInput]="'isActive'" [myInputModel]="isActive" [checked]="isActive" [inverted]="true" (checkedEvent)="setIsActive($event)" #tooltip="matTooltip" matTooltip="{{'SidebarComponent.IsActive' | translate}}" matTooltipClass="custom-tooltip"></app-checkbox-field>
		</div>
	</div>
	<div class="bottom-sidebar scrollable dark mt-3">
		<div class="category" *ngIf="tenantId !== '*' && (connectedUser.isGranted(tenantId) || connectedUser.isGranted(tenantId, 'MANAGEMENT'))">
			<div class="fa-icon-sidebar">
				<fa-icon id="configurationIcon" [icon]="['fas', 'sliders-h']"></fa-icon>
			</div>
			{{"SidebarComponent.Title.Configuration" | translate}}
			<span class="line-separator"></span>
		</div>
		<div class="nav-buttons" *ngIf="tenantId !== '*' && connectedUser.isGranted(tenantId)">
			<a [routerLink]="['/administration/'+tenantId+'/overview']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.General"|translate}}</p>
			</a>
		</div>
		<div class="nav-buttons" *ngIf="tenantId !== '*' && connectedUser.isGranted(tenantId, 'MANAGEMENT')">
			<a [routerLink]="['/administration/'+tenantId+'/visio-configuration']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.Visio"|translate}}</p>
			</a>
		</div>
		<div class="nav-buttons" *ngIf="tenantId !== '*' && connectedUser.isGranted(tenantId, 'MANAGEMENT')">
			<a [routerLink]="['/administration/'+tenantId+'/consents']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.Consents"|translate}}</p>
			</a>
		</div>
		<div class="nav-buttons" *ngIf="tenantId !== '*' && connectedUser.hasRole('SUPER_ADMIN')">
			<a [routerLink]="['/administration/'+tenantId+'/offers']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.Offers"|translate}}</p>
			</a>
		</div>
		<div class="category" *ngIf="connectedUser.isGranted(tenantId, 'USERS') || connectedUser.isGranted(tenantId, 'MASTER') || connectedUser.isGranted(tenantId, 'MANAGEMENT')">
			<div class="fa-icon-sidebar">
				<fa-icon id="users" [icon]="['fas', 'users']"></fa-icon>
			</div>
			{{"SidebarComponent.Title.Users" | translate}}
			<span class="line-separator"></span>
		</div>
		<div class="nav-buttons" *ngIf="connectedUser.isGranted(tenantId, 'USERS')">
			<a [routerLink]="['/administration/'+tenantId+'/users']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.Users"|translate}}</p>
			</a>
		</div>
		<div class="nav-buttons" *ngIf="tenantId !== '*' && connectedUser.isGranted(tenantId, 'MASTER')">
			<a [routerLink]="['/administration/'+tenantId+'/authorizations']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.Authorizations"|translate}}</p>
			</a>
		</div>
		<ng-container *ngIf="(currentTenant$ | async) as currentTenant">
			<div class="nav-buttons" *ngIf="connectedUser.isGranted(tenantId, 'USERS') && currentTenant.authProvider === 'microsoft'">
				<a [routerLink]="['/administration/'+tenantId+'/groups']" routerLinkActive="active" class="nav-links pointer">
					<p>{{"SidebarComponent.Link.Groups"|translate}}</p>
				</a>
			</div>
		</ng-container>
		<div class="category" *ngIf="connectedUser.isGranted(tenantId, 'MEETING_ROOMS')">
			<div class="fa-icon-sidebar">
				<fa-icon id="meetingRooms" [icon]="['fas', 'city']"></fa-icon>
			</div>
			{{"SidebarComponent.Title.MeetingRooms" | translate}}
			<span class="line-separator"></span>
		</div>
		<div class="nav-buttons" *ngIf="connectedUser.isGranted(tenantId, 'MEETING_ROOMS')">
			<a [routerLink]="['/administration/'+tenantId+'/meeting-rooms']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.MeetingRooms"|translate}}</p>
			</a>
		</div>
		<div class="nav-buttons" *ngIf="tenantId !== '*' && connectedUser.isGranted(tenantId, 'MANAGEMENT') && connectedUser.isGranted(tenantId, 'MEETING_ROOMS')">
			<a [routerLink]="['/administration/'+tenantId+'/obtp']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.Obtp"|translate}}</p>
			</a>
		</div>
		<div class="category" *ngIf="connectedUser.isGranted(tenantId, 'CONFERENCES') || connectedUser.isGranted(tenantId, 'MEETINGS')">
			<div class="fa-icon-sidebar">
				<fa-icon id="PlannedMeetings" [icon]="['fas', 'calendar-alt']"></fa-icon>
			</div>
			{{"SidebarComponent.Title.Meetings" | translate}}
			<span class="line-separator"></span>
		</div>
		<div class="nav-buttons" *ngIf="tenantId !== '*' && connectedUser.isGranted(tenantId, 'CONFERENCES')">
			<a [routerLink]="['/administration/'+tenantId+'/live-conferences']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.LiveConferences"|translate}}</p>
			</a>
		</div>
		<div class="nav-buttons" *ngIf="connectedUser.isGranted(tenantId, 'MEETINGS')">
			<a [routerLink]="['/administration/'+tenantId+'/planned-meetings']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.PlannedMeetings"|translate}}</p>
			</a>
		</div>
		<div class="nav-buttons" *ngIf="connectedUser.isGranted(tenantId, 'MEETINGS')">
			<a [routerLink]="['/administration/'+tenantId+'/history-meetings']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.HistoricMeetings"|translate}}</p>
			</a>
		</div>
		<div class="nav-buttons" *ngIf="connectedUser.isGranted(tenantId, 'MEETINGS')">
			<a [routerLink]="['/administration/'+tenantId+'/historic-policy-rejects']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.PolicyRejects"|translate}}</p>
			</a>
		</div>
		<div class="nav-buttons" *ngIf="connectedUser.isGranted(tenantId, 'STATS')">
			<a [routerLink]="['/administration/'+tenantId+'/admin-stats']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.Statistics"|translate}}</p>
			</a>
		</div>
		<div class="category" *ngIf="tenantId !== '*' && connectedUser.isGranted(tenantId, 'CONTENT')">
			<div class="fa-icon-sidebar">
				<fa-icon id="mails" [icon]="['fas', 'mail-bulk']"></fa-icon>
			</div>
			{{"SidebarComponent.Title.Mails" | translate}}
			<span class="line-separator"></span>
		</div>
		<div class="nav-buttons" *ngIf="tenantId !== '*' && connectedUser.isGranted(tenantId, 'CONTENT')">
			<a [routerLink]="['/administration/'+tenantId+'/mails-configuration']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.Mails"|translate}}</p>
			</a>
		</div>
		<div class="nav-buttons" *ngIf="tenantId !== '*' && connectedUser.isGranted(tenantId, 'CONTENT')">
			<a [routerLink]="['/administration/'+tenantId+'/invitations-configuration']" routerLinkActive="active" class="nav-links pointer">
				<p>{{"SidebarComponent.Link.Invitations"|translate}}</p>
			</a>
		</div>
	</div>
</div>
