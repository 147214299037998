import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { ConsentResultService } from './consent-result.service';

@Component({
  selector: 'app-consent-result',
  templateUrl: './consent-result.component.html',
  styleUrls: ['./consent-result.component.scss'],
  providers: [ConsentResultService],
})
export class ConsentResultComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  tenant$: Observable<Tenant>;
  appName: string;
  targetTenantId: string;
  errors: any;

  constructor(private route: ActivatedRoute, private consentService: ConsentResultService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe((connectedUser: ConnectedUser) => {
      if (connectedUser) {
        this.connectedUser = connectedUser;
      }
    }));
    if (this.route.parent) {
      this.onInitSubscriptions.push(this.route.parent.paramMap.subscribe((params) => {
        if (params.get('tenantId')) {
          this.getTenant(params.get('tenantId') as string);
        }
      }));
    }
    this.onInitSubscriptions.push(this.route.paramMap.subscribe((params) => {
      this.appName = params.get('appName') as string;
    }));
    this.onInitSubscriptions.push(this.route.queryParamMap.subscribe((params) => {
      if (params.get('error') && params.get('error_description') && params.get('error_uri')) {
        this.errors = {};
        this.errors = {
          error: params.get('error'),
          description: params.get('error_description') as string,
          uri: params.get('error_uri') as string,
        };
      }
      this.targetTenantId = params.get('tenant') as string;
    }));
  }

  getTenant(tenantId: string): void {
    this.tenant$ = this.consentService.getTenant(tenantId).pipe(catchError(this.handleError<Tenant>('getTenant')),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
