import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { User } from '../user.model';

@Injectable()
export class UserViewService {
  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService
  ) {}

  getUser(tenant: Tenant, userId: string): Observable<User> {
    return this.optionService.getApiUrl(tenant.id).pipe(
      switchMap((apiUri) => {
        return this.http.get<User>(apiUri + '/users/' + userId, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new User(result))
    );
  }

  save(tenant: Tenant, user: User): Observable<User> {
    return this.optionService.getApiUrl(tenant.id).pipe(
      switchMap((apiUri) => {
        return this.http.put<User>(apiUri + '/users/' + user.id, user, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new User(result))
    );
  }

  addOffer(tenantId: string, user: User, offer: string): Observable<unknown> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http.put<User>(
          apiUri + '/users/' + user.id + '/offers/' + offer,
          null,
          {
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          }
        );
      }),
      map((result) => result)
    );
  }

  removeOffer(
    tenantId: string,
    user: User,
    offer: string
  ): Observable<unknown> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http.delete<User>(
          apiUri + '/users/' + user.id + '/offers/' + offer,
          {
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          }
        );
      }),
      map((result) => result)
    );
  }

  /**
   * FOR USER CONNEXION
   */
  sendCreatePassword(user: User): Observable<unknown> {
    return this.optionService.getApiUrl(user.tenantId).pipe(
      switchMap((apiUri) => {
        return this.http.post(
          apiUri + '/users/' + user.id + '/createPassword',
          null,
          {
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          }
        );
      }),
      map((result) => result)
    );
  }

  sendResetPassword(user: User): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.post(apiUri + '/resetPassword/' + user.email, null, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => result)
    );
  }

  /**
   * FOR USER DELETION
   */
  delete(user: User): Observable<unknown> {
    return this.optionService.getApiUrl(user.tenantId).pipe(
      switchMap((apiUri) => {
        return this.http
          .delete(apiUri + '/users/' + user.id, {
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          })
          .pipe(map((result) => result));
      })
    );
  }
}
