import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObtpRegexp, ObtpRegexps, VerifiedObtpRegexp, VerifyObtpRegexp } from '../obtp.model';
import Debug from 'debug';
import { NgForm } from '@angular/forms';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { EditObtpService } from './edit-obtp.service';
const debug = Debug('virtuvisio-administration:obtp-edit-component');

@Component({
  selector: 'app-edit-obtp',
  templateUrl: './edit-obtp.component.html',
  styleUrls: ['./edit-obtp.component.scss'],
  providers: [EditObtpService]
})
export class EditObtpComponent implements OnInit {
  @Input() tenantId: string;
  @Input() editObtpRegexps: ObtpRegexps;
  @Output() brodcastEditEvent: EventEmitter<string> = new EventEmitter<string>();

  newRegexpTarget: string;
  newRegexpConfig: ObtpRegexp = {} as ObtpRegexp;
  addingNewTarget = false;
  addingNewMatcher = false;
  newMatcher: string;
  obtpRegexps: ObtpRegexps
  selectedRegexp: ObtpRegexp;
  selectedTarget: string;
  hasResult = false;
  verifyContent: string;
  verifiedTarget: VerifiedObtpRegexp;

  constructor(private notif: NotifService, private obtpService: EditObtpService) { }

  ngOnInit(): void {
    this.obtpRegexps = this.editObtpRegexps ? { ...this.editObtpRegexps } : {}
  }

  removeTarget(target: string): void {
    delete this.obtpRegexps[target];
  }

  addNewTarget(addNewTargetForm: NgForm): void {
    if (!this.newRegexpConfig.matchers || this.newRegexpConfig.matchers.length === 0) {
      this.notif.addErrorNotif('ObtpComponent.Form.NoEmptyMacthers');
    } else {
      if (this.obtpRegexps[addNewTargetForm.value.target]) {
        this.notif.addErrorNotif('ObtpComponent.Form.ExistingTarget')
      } else {
        const target = addNewTargetForm.value.target;
        const targetConfig = {
          finalMeetingLinkFormat: addNewTargetForm.value.finalMeetingLinkFormat,
          matchers: this.newRegexpConfig.matchers
        }
        this.obtpRegexps[target] = targetConfig;
        this.addingNewTarget = false;
        this.newRegexpTarget = '';
        this.newRegexpConfig = {} as ObtpRegexp;
      }
    }
  }

  addNewMatcher(newMatcher: string): void {
    if (!this.newRegexpConfig.matchers) {
      this.newRegexpConfig.matchers = [];
    }
    if (this.newRegexpConfig.matchers.includes(newMatcher)) {
      this.notif.addErrorNotif('ObtpComponent.DuplicateMatcher');
    } else {
      this.newRegexpConfig.matchers.push(newMatcher);
      this.newMatcher = '';
      this.addingNewMatcher = false
    }
  }

  dropNewMatcher(matcher: string): void {
    this.newRegexpConfig.matchers = this.newRegexpConfig.matchers.filter(item => item !== matcher);
  }

  verifyTarget(target: string, regexp: ObtpRegexp): void {
    this.selectedTarget = target;
    this.selectedRegexp = regexp;
    $("#modalVerifyRegexp").modal("show");
  }

  confirmVerifyTarget(): void {
    const verifyTarget: VerifyObtpRegexp = {
      content: this.verifyContent,
      matcher: {
        finalMeetingLinkFormat: this.selectedRegexp.finalMeetingLinkFormat,
        matchers: this.selectedRegexp.matchers
      }
    }
    this.obtpService.verifyRegexp(this.tenantId, verifyTarget).subscribe({
      next: (result: VerifiedObtpRegexp) => {
        this.verifiedTarget = result;
        this.hasResult = true;
      },
      error: (error) => {
        this.notif.addErrorNotif('ObtpComponent.Verify.Check.Error', error);
      }
    })
  }

  cancelVerifyTarget(): void {
    $("#modalVerifyRegexp").modal("hide");
    this.selectedTarget = '';
    this.selectedRegexp = {} as ObtpRegexp;
    this.verifyContent = '';
  }

  okVerify(): void {
    $("#modalVerifyRegexp").modal("hide");
    this.selectedTarget = '';
    this.selectedRegexp = {} as ObtpRegexp;
    this.verifiedTarget = {} as VerifiedObtpRegexp;
    this.hasResult = false;
  }

  saveConfig(): void {
    this.obtpService.addRegexp(this.tenantId, this.obtpRegexps).subscribe({
      next: (result) => {
        debug(result);
        this.notif.addSuccessNotif('ObtpComponent.Save.Success');
        this.brodcastEditEvent.emit('save');
        this.obtpRegexps = {};
      },
      error: (error) => {
        debug(error);
        this.notif.addErrorNotif('ObtpComponent.Save.Error', error)
      }
    })
  }

  cancelEditConfig(): void {
    this.brodcastEditEvent.emit('cancel');
    this.obtpRegexps = {};
  }

}
