import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { AppConfigService } from './services/config/app.config.service';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { createTranslateLoader, initializeApp } from './app.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './commons/footer/footer.component';
import { HeaderComponent } from './commons/header/header.component';
import { CustomHttpInterceptor } from './services/interceptor/custom.interceptor.service';
import { ErrorService } from './services/error/error.service';
import { GlobalErrorHandlerService } from './services/error-handler/global.error.handler.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NotifToastr } from './services/notifs/notif.toastr';
import { NotifToastrComponent } from './services/notifs/notif.toastr.component';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { MainNavbarComponent } from './commons/header/main-navbar/main-navbar.component';
import { UserMenuComponent } from './commons/header/user-menu/user-menu.component';
import { ManagementNavbarComponent } from './commons/header/management-navbar/management-navbar.component';
import { SidebarComponent } from './commons/sidebar/sidebar.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MY_MATCALENDAR_MOMENT_FORMATS,
  MY_NGXMATCALENDAR_MOMENT_FORMATS,
} from './services/calendar/calendar.format.service';
import {
  NgxMatMomentModule,
  NGX_MAT_MOMENT_FORMATS,
} from '@angular-material-components/moment-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeGb from '@angular/common/locales/en-GB';
import localeGbExtra from '@angular/common/locales/extra/en-GB';
import { CustomPipeModule } from './pipe/custom-pipe.module';
import { InputFieldComponent } from './commons/input-field/input-field.component';
import { InputFormFieldComponent } from './commons/input-form-field/input-form-field.component';
import { InputDropdownFieldComponent } from './commons/input-dropdown-field/input-dropdown-field.component';
import { InputDropdownFormFieldComponent } from './commons/input-dropdown-form-field/input-dropdown-form-field.component';
import { TextareaFieldComponent } from './commons/textarea-field/textarea-field.component';
import { DatePickerFieldComponent } from './commons/date-picker-field/date-picker-field.component';
import { DatePickerFormFieldComponent } from './commons/date-picker-form-field/date-picker-form-field.component';
import { DatetimePickerFormFieldComponent } from './commons/datetime-picker-form-field/datetime-picker-form-field.component';
import { DatetimePickerFieldComponent } from './commons/datetime-picker-field/datetime-picker-field.component';
import { CheckboxFieldComponent } from './commons/checkbox-field/checkbox-field.component';
import { CheckboxFormFieldComponent } from './commons/checkbox-form-field/checkbox-form-field.component';
import { RadioFormFieldComponent } from './commons/radio-form-field/radio-form-field.component';
import { RadioFieldComponent } from './commons/radio-field/radio-field.component';
import { LoadingButtonComponent } from './commons/loading-button/loading-button.component';
import { OnlyLoadingButtonComponent } from './commons/only-loading-button/only-loading-button.component';
import { SpinnerCustomComponent } from './commons/spinner-custom/spinner-custom.component';
import { CustomSpinnerModule } from './commons/spinner/custom.spinner.module';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { FourHoFourComponent } from './four-ho-four/four-ho-four.component';
import { HomeAdministrationComponent } from './home-administration/home-administration.component';
import { HomeManagementComponent } from './home-management/home-management.component';
import { AdminCardComponent } from './commons/admin-card/admin-card.component';
import { AdminBlocComponent } from './commons/admin-bloc/admin-bloc.component';
import { AdminDetailsBlocComponent } from './commons/admin-details-bloc/admin-details-bloc.component';
import { SwitchFieldComponent } from './commons/switch-field/switch-field.component';
import { OfferManagementComponent } from './offer/offer-management/offer-management.component';
import { ExpiredOfferListComponent } from './offer/expired-offer-list/expired-offer-list.component';
import { HistoryPolicyRejectsComponent } from './history-policy-rejects/history-policy-rejects.component';
import { MatTableCustomComponent } from './commons/mat-table-custom/mat-table-custom.component';
import { CalendarPickerDoubleComponent } from './commons/calendar-picker-double/calendar-picker-double.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { OverviewComponent } from './overview/overview.component';
import { AdminInfosBlocComponent } from './commons/admin-infos-bloc/admin-infos-bloc.component';
import { ToolbarComponent } from './commons/toolbar/toolbar.component';
import { LiveConferencesComponent } from './live-conferences/live-conferences.component';
import { VisioComponent } from './visio/visio.component';
import { DetailsLiveConferenceComponent } from './live-conferences/details-live-conference/details-live-conference.component';
import { PexipParticipantComponent } from './live-conferences/participants-live-conference/pexip-participant.component';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
registerLocaleData(localeGb, 'en-GB', localeGbExtra);

import * as $ from 'jquery';
import * as bootstrap from 'bootstrap';

import { HistoryMeetingsComponent } from './history-meetings/history-meetings.component';
import { ConsentApplicationComponent } from './consent/consent-application/consent-application.component';
import { WaitingConsentComponent } from './consent/waiting-consent/waiting-consent.component';
import { ConsentResultComponent } from './consent/consent-result/consent-result.component';
import { DetailsHistoryMeetingsComponent } from './history-meetings/details-history-meetings/details-history-meetings.component';
import { ParticipantDetailsHistoryMeetingsComponent } from './history-meetings/details-history-meetings/participant-details-history-meetings/participant-details-history-meetings.component';
import { AdminBorderBlocComponent } from './commons/admin-border-bloc/admin-border-bloc.component';
import { OffersAdministrationComponent } from './offer/offer-administration/offers-administration.component';
import { OfferViewComponent } from './offer/offer-view/offer-view.component';
import { SwitchFieldSimpleComponent } from './commons/switch-field-simple/switch-field-simple.component';
import { OfferFormComponent } from './offer/offer-form/offer-form.component';
import { PlannedMeetingsComponent } from './planned-meetings/planned-meetings.component';
import { EditPlannedMeetingComponent } from './planned-meetings/edit-planned-meeting/edit-planned-meeting.component';
import { AttendeesListComponent } from './attendee/attendees-list/attendees-list.component';
import { SuggestContactComponent } from './contact/suggest-contact/suggest-contact.component';
import { CoorganizersListComponent } from './coorganizer/coorganizers-list/coorganizers-list.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UsersComponent } from './users/users.component';
import { UserViewComponent } from './users/user-view/user-view.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UserAddComponent } from './users/user-add/user-add.component';
import { UserEditPasswordComponent } from './users/user-edit-password/user-edit-password.component';
import { SyncTenantComponent } from './tenant/sync-tenant/sync-tenant.component';
import { MeetingRoomsComponent } from './meeting-rooms/meeting-rooms.component';
import { ImportMeetingRoomsComponent } from './meeting-rooms/import-meeting-rooms/import-meeting-rooms.component';
import { DetailsMeetingRoomComponent } from './meeting-rooms/details-meeting-room/details-meeting-room.component';
import { AddMeetingRoomComponent } from './meeting-rooms/add-meeting-room/add-meeting-room.component';
import { EditMeetingRoomComponent } from './meeting-rooms/edit-meeting-room/edit-meeting-room.component';
import { AddEndpointComponent } from './endpoints/add-endpoint/add-endpoint.component';
import { EditEndpointComponent } from './endpoints/edit-endpoint/edit-endpoint.component';
import { EndpointsComponent } from './endpoints/endpoints.component';
import { EndpointStatusComponent } from './endpoints/endpoint-status/endpoint-status.component';
import { AddSipAccountComponent } from './endpoints/add-sip-account/add-sip-account.component';
import { AuthorizationsComponent } from './authorizations/authorizations.component';
import { GroupsComponent } from './groups/groups.component';
import { EditGroupComponent } from './groups/edit-group/edit-group.component';
import { ImportGroupComponent } from './groups/import-group/import-group.component';
import { TrialsComponent } from './trials/trials.component';
import { EditTrialComponent } from './trials/edit-trial/edit-trial.component';
import { EnvironmentsComponent } from './environments/environments.component';
import { AddEnvironmentComponent } from './environments/add-environment/add-environment.component';
import { EditEnvironmentComponent } from './environments/edit-environment/edit-environment.component';
import { OauthClientsComponent } from './oauth-clients/oauth-clients.component';
import { AddOauthClientComponent } from './oauth-clients/add-oauth-client/add-oauth-client.component';
import { EditOauthClientComponent } from './oauth-clients/edit-oauth-client/edit-oauth-client.component';
import { DetailsOauthClientComponent } from './oauth-clients/details-oauth-client/details-oauth-client.component';
import { GeozonesComponent } from './geozones/geozones.component';
import { MailsConfigurationComponent } from './mails-configuration/mails-configuration.component';
import { InvitationsConfigurationComponent } from './invitations-configuration/invitations-configuration.component';
import { AddTenantComponent } from './tenant/add-tenant/add-tenant.component';
import { ContextComponent } from "src/app/mails/context.component";
import { MasqueradeMenuComponent } from './commons/header/masquerade-menu/masquerade-menu.component';
import { ErrorsComponent } from './errors/errors.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoginService } from './login/login.service';
import { DataApiOptionService } from './services/data-api-option/data-api-option.service';
import { DatesManager } from './services/dates/dates.manager';
import { SharedService } from './services/shared/shared.service';
import { ConnectedUserService } from './users/user-connected/connected-user.service';
import { UsageComponent } from './usage/usage.component';
import { LiveUsageComponent } from './usage/live-usage/live-usage.component';
import { StatsUsageComponent } from './usage/stats-usage/stats-usage.component';
import { ObtpComponent } from './obtp/obtp.component';
import { EditObtpComponent } from './obtp/edit-obtp/edit-obtp.component';
import { ViewObtpComponent } from './obtp/view-obtp/view-obtp.component';
import { HistoryObtpComponent } from './obtp/history-obtp/history-obtp.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeAdministrationComponent,
    HomeManagementComponent,
    HeaderComponent,
    MainNavbarComponent,
    ManagementNavbarComponent,
    UserMenuComponent,
    SidebarComponent,
    FooterComponent,
    NotifToastr,
    NotifToastrComponent,
    InputFieldComponent,
    InputFormFieldComponent,
    InputDropdownFieldComponent,
    InputDropdownFormFieldComponent,
    TextareaFieldComponent,
    DatePickerFieldComponent,
    DatePickerFormFieldComponent,
    DatetimePickerFormFieldComponent,
    DatetimePickerFieldComponent,
    CheckboxFieldComponent,
    CheckboxFormFieldComponent,
    RadioFormFieldComponent,
    RadioFieldComponent,
    SwitchFieldComponent,
    LoadingButtonComponent,
    OnlyLoadingButtonComponent,
    SpinnerCustomComponent,
    FourHoFourComponent,
    AdminCardComponent,
    AdminBlocComponent,
    AdminDetailsBlocComponent,
    AdminInfosBlocComponent,
    ExpiredOfferListComponent,
    //administration parts
    //management parts
    OfferManagementComponent,
    HistoryPolicyRejectsComponent,
    MatTableCustomComponent,
    CalendarPickerDoubleComponent,
    StatisticsComponent,
    OverviewComponent,
    ToolbarComponent,
    LiveConferencesComponent,
    VisioComponent,
    DetailsLiveConferenceComponent,
    PexipParticipantComponent,
    HistoryMeetingsComponent,
    ConsentApplicationComponent,
    WaitingConsentComponent,
    ConsentResultComponent,
    DetailsHistoryMeetingsComponent,
    ParticipantDetailsHistoryMeetingsComponent,
    OffersAdministrationComponent,
    OfferViewComponent,
    SwitchFieldSimpleComponent,
    AdminBorderBlocComponent,
    OfferFormComponent,
    PlannedMeetingsComponent,
    EditPlannedMeetingComponent,
    AttendeesListComponent,
    SuggestContactComponent,
    CoorganizersListComponent,
    UsersListComponent,
    UsersComponent,
    UserViewComponent,
    UserEditComponent,
    UserAddComponent,
    UserEditPasswordComponent,
    SyncTenantComponent,
    MeetingRoomsComponent,
    ImportMeetingRoomsComponent,
    DetailsMeetingRoomComponent,
    AddMeetingRoomComponent,
    EditMeetingRoomComponent,
    AddEndpointComponent,
    EditEndpointComponent,
    EndpointsComponent,
    EndpointStatusComponent,
    AddSipAccountComponent,
    AuthorizationsComponent,
    GroupsComponent,
    EditGroupComponent,
    ImportGroupComponent,
    TrialsComponent,
    EditTrialComponent,
    EnvironmentsComponent,
    AddEnvironmentComponent,
    EditEnvironmentComponent,
    OauthClientsComponent,
    AddOauthClientComponent,
    EditOauthClientComponent,
    DetailsOauthClientComponent,
    GeozonesComponent,
    MailsConfigurationComponent,
    InvitationsConfigurationComponent,
    AddTenantComponent,
    ContextComponent,
    MasqueradeMenuComponent,
    ErrorsComponent,
    UsageComponent,
    LiveUsageComponent,
    StatsUsageComponent,
    ObtpComponent,
    EditObtpComponent,
    ViewObtpComponent,
    HistoryObtpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      extendedTimeOut: 1000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
      newestOnTop: true,
      tapToDismiss: true,
      enableHtml: false,
      progressBar: true,
      progressAnimation: 'increasing',
      closeButton: true,
      maxOpened: 2,
      toastComponent: NotifToastr,
    }),
    ToastContainerModule,
    FontAwesomeModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    CustomPipeModule,
    CustomSpinnerModule,
    FormsModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    NgSelectModule,
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_MATCALENDAR_MOMENT_FORMATS,
    },
    {
      provide: NGX_MAT_MOMENT_FORMATS,
      useValue: MY_NGXMATCALENDAR_MOMENT_FORMATS,
    },
    ErrorService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    CookieService,
    DatePipe,
    LoginService,
    DataApiOptionService,
    DatesManager,
    ErrorService,
    SharedService,
    ConnectedUserService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}
