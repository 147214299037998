import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PreviousRouteService } from 'src/app/services/previous-route/previous-route.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { Actions, Controls, ControlType } from '../toolbar/toolbar.model';

@Component({
  selector: 'app-admin-infos-bloc',
  templateUrl: './admin-infos-bloc.component.html',
  styleUrls: ['./admin-infos-bloc.component.scss'],
})
export class AdminInfosBlocComponent implements OnDestroy, OnInit {
  @Input() scrollable: boolean;
  @Input() blocTitle: string;
  @Input() blocControls: Controls[];
  @Output() requestAction = new EventEmitter<string>();
  @Output() switchChanged = new EventEmitter<boolean>();
  connectedUser: ConnectedUser;
  onInitSubscriptions: Subscription[] = [];
  Type = ControlType;
  actions = Actions;
  joinFrom: string;
  fullParams: ParamMap;

  constructor(
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    private previousRoute: PreviousRouteService
  ) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(
      this.sharedService.currentConnectedUser.subscribe(
        (user: ConnectedUser) => {
          if (user) {
            this.connectedUser = user;
          }
        }
      )
    );
    this.onInitSubscriptions.push(
      this.route.queryParamMap.subscribe((params) => {
        this.joinFrom = params.get('joinFrom') as string;
        this.fullParams = params;
      })
    );
  }

  onSwitchChange(event: boolean) {
    this.switchChanged.emit(event);
  }

  onRequestAction(action?: string): void {
    this.requestAction.emit(action);
  }

  getPrevisouRoute(): string[] {
    if (this.previousRoute.getPreviousUrl() === this.router.url) {
      return ['/home']
    } else {
      return [this.previousRoute.getPreviousUrl()]
    }
  }

  navigatePrevious(): void {
    let link;
    const navigationExtras: NavigationExtras = {};
    navigationExtras.queryParams = {};
    if (this.connectedUser) {
      link = this.joinFrom ? [this.joinFrom] : this.getPrevisouRoute();
      if (this.joinFrom) {
        this.fullParams.get('meetingAlias')
          ? (navigationExtras.queryParams.meetingAlias =
            this.fullParams.get('meetingAlias'))
          : null;
        this.fullParams.get('meetingAlias')
          ? (navigationExtras.queryParams.meetingId =
            this.fullParams.get('meetingAlias'))
          : null;
        this.fullParams.get('moderatorCode')
          ? (navigationExtras.queryParams.moderatorCode =
            this.fullParams.get('moderatorCode'))
          : null;
      }
    } else {
      link = this.joinFrom ? [this.joinFrom] : ['/login'];
      if (this.joinFrom) {
        this.fullParams.get('meetingAlias')
          ? (navigationExtras.queryParams.meetingAlias =
            this.fullParams.get('meetingAlias'))
          : null;
        this.fullParams.get('meetingName')
          ? (navigationExtras.queryParams.meetingName =
            this.fullParams.get('meetingName'))
          : null;
        this.fullParams.get('userAlias')
          ? (navigationExtras.queryParams.userAlias =
            this.fullParams.get('userAlias'))
          : null;
        this.fullParams.get('userAlias')
          ? (navigationExtras.queryParams.meetingName =
            this.fullParams.get('userAlias'))
          : null;
        this.fullParams.get('moderatorCode')
          ? (navigationExtras.queryParams.moderatorCode =
            this.fullParams.get('moderatorCode'))
          : null;
      }
    }
    this.router.navigate(link, navigationExtras);
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
