import { NgModule } from '@angular/core';
import { CustomSearch } from './customsearch.pipe';
import { OrderBy } from './orderby.pipe';
import { IsoDateToDate } from './isoDate-toDate.pipe';
import { FilterBy } from './filterBy.pipe';
import { MakeSrcUrl } from './custom-src-url.pipe';
import { BoldTextPipe } from './bold-text.pipe';
import { TranslateItemPipe } from './translate-item.pipe';
import { TranslateCountryPipe } from './translate-country.pipe';

@NgModule({
  declarations: [CustomSearch, OrderBy, IsoDateToDate, FilterBy, MakeSrcUrl, BoldTextPipe, TranslateItemPipe, TranslateCountryPipe],
  exports: [CustomSearch, OrderBy, IsoDateToDate, FilterBy, MakeSrcUrl, BoldTextPipe, TranslateItemPipe, TranslateCountryPipe],
})
export class CustomPipeModule { }
