import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Environment } from '../environments/environment.model';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from '../services/http-encoder/custom-http-param-encoder.service';
import { Tenant } from '../tenant/tenant.model';

@Injectable()
export class OverviewService {
  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService
  ) { }

  getTenant(tenantId: string): Observable<Tenant> {
    if (tenantId === '*') {
      const allTenants: Tenant = new Tenant({});
      allTenants.name = 'all tenants';
      allTenants.id = '*';
      return of(allTenants);
    } else {
      return this.optionService.getApiUrl(tenantId).pipe(
        switchMap((apiUri) => {
          return this.http.get<Tenant>(apiUri, {
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          });
        }),
        map((result) => new Tenant(result))
      );
    }
  }

  save(tenant: Tenant): Observable<Tenant> {
    return this.optionService.getApiUrl(tenant.id).pipe(
      switchMap((apiUri) => {
        return this.http.put<Tenant>(apiUri, tenant, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new Tenant(result))
    );
  }

  delete(tenant: Tenant): Observable<unknown> {
    return this.optionService.getApiUrl(tenant.id).pipe(
      switchMap((apiUri) => {
        return this.http.delete<Tenant>(apiUri, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => result)
    );
  }

  saveSettings(tenant: Tenant): Observable<unknown> {
    return this.optionService.getApiUrl(tenant.id).pipe(
      switchMap((apiUri) => {
        return this.http.put<Tenant>(apiUri + '/settings', tenant, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => result)
    );
  }

  enableService(tenant: Tenant, service: string): Observable<unknown> {
    return this.optionService.getApiUrl(tenant.id).pipe(
      switchMap((apiUri) => {
        return this.http.put<Tenant>(
          apiUri + '/services/' + service + '/enable',
          null,
          {
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          }
        );
      }),
      map((result) => result)
    );
  }

  disableService(tenant: Tenant, service: string): Observable<unknown> {
    return this.optionService.getApiUrl(tenant.id).pipe(
      switchMap((apiUri) => {
        return this.http.put<Tenant>(
          apiUri + '/services/' + service + '/disable',
          null,
          {
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          }
        );
      }),
      map((result) => result)
    );
  }

  getEnvironments(): Observable<Environment[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<Environment[]>(apiUri + '/environments', {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        const environments = [];
        for (const u in result) {
          environments.push(new Environment(result[u]));
        }
        return environments;
      })
    );
  }

  getGroupsLength(tenantId: string): Observable<number> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        let params = new HttpParams({
          encoder: new CustomHttpParamEncoderService(),
        });
        return this.http
          .head(apiUri + '/groups', {
            params: params,
            headers: this.optionService.getHeaders(),
            observe: 'response',
            withCredentials: true,
          })
          .pipe(map((result) => parseInt(<string>result.headers.get('Count'))));
      })
    );
  }

  getUsersLength(tenantId: string): Observable<number> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        let params = new HttpParams({
          encoder: new CustomHttpParamEncoderService(),
        });
        return this.http
          .head(apiUri + '/users', {
            params: params,
            headers: this.optionService.getHeaders(),
            observe: 'response',
            withCredentials: true,
          })
          .pipe(map((result) => parseInt(<string>result.headers.get('Count'))));
      })
    );
  }

  getMeetingRoomsLength(tenantId: string): Observable<number> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        let params = new HttpParams({
          encoder: new CustomHttpParamEncoderService(),
        });
        return this.http
          .head(apiUri + '/meetingRooms', {
            params: params,
            headers: this.optionService.getHeaders(),
            observe: 'response',
            withCredentials: true,
          })
          .pipe(map((result) => parseInt(<string>result.headers.get('Count'))));
      })
    );
  }

  validateEwsConfig(tenantId: string): Observable<unknown> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http.get(apiUri + "/validateEwsConfig", {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => result)
    );
  }
}
