import { Component, OnInit, OnDestroy } from '@angular/core';
import { Group } from 'src/app/groups/group.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GroupsService } from 'src/app/groups/groups.service';
import { NotifService } from 'src/app/services/notifs/notif.service';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss'],
  providers: [GroupsService]
})
export class EditGroupComponent implements OnInit, OnDestroy {

  currentGroup: Group;
  hasError = false;
  finished = false;
  onInitSubscriptions: Subscription[] = [];
  constructor(private router: Router, private route: ActivatedRoute, private groupsService: GroupsService, private notifService: NotifService) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(this.route.paramMap.subscribe(params => {
      let groupId = params.get('groupId');
      if (groupId) {
        this.getGroup(groupId);
      }
    }))
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  getGroup(groupId: string): void {
    this.groupsService.getGroup(groupId).subscribe(result => {
      this.currentGroup = result;
    })
  }

  confirmEditGroup(editGroupFrom: NgForm): void {
    if (editGroupFrom.valid) {
      this.currentGroup.autoSync = editGroupFrom.value.autoSync;
      this.currentGroup.name = editGroupFrom.value.name;
      this.groupsService.save(this.currentGroup).subscribe({
        next: () => {
          this.finished = true;
          this.hasError = false;
          this.notifService.addSuccessNotif('EditGroup.SuccessEditGroup');
          this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: (error) => {
          this.finished = true;
          this.hasError = true;
          this.notifService.addErrorNotif('EditGroup.ErrorEditGroup', error);
        }
      });
    } else {
      this.hasError = true;
    }
  }

}
