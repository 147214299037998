/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Pipe, PipeTransform } from '@angular/core';

/*
 *
 * To use it, import it in your component :
 * import {OrderBy} from "./..";
 * And add the following property in your @component{()} declaration :
 * pipes: [OrderBy]
 *
 *
 * In order to fix the problem of refreshing repeat when array is modify add the follwing import in your component :
 * import {ChangeDetectionStrategy} from "angular2/core";
 * And add the follwing property in your @component{()} declaration :
 * changeDetection: ChangeDetectionStrategy.OnPush or changeDetection: ChangeDetectionStrategy.All
 *
 */

@Pipe({
  name: 'orderBy',
  pure: false,
})
export class OrderBy implements PipeTransform {
  value: string[] = [];

  static _orderByComparator(a: any, b: any): number {
    if (a === null || typeof a === 'undefined') {
      a = 0;
    }
    if (b === null || typeof b === 'undefined') {
      b = 0;
    }

    if (isNaN(parseFloat(a)) || !isFinite(a) || isNaN(parseFloat(b)) || !isFinite(b)) {
      // Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
    } else {
      // Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) {
        return -1;
      }
      if (parseFloat(a) > parseFloat(b)) {
        return 1;
      }
    }

    return 0; // equal each other
  }

  transform(input: any, config = '+'): any {
    /* little fix for when data are load after the template or when deleting item */
    if (!input) {
      return [];
    }
    /* */

    // make a copy of the input's reference
    this.value = [...input];
    const value = this.value;

    if (!Array.isArray(value)) {
      return value;
    }

    if (!Array.isArray(config) || (Array.isArray(config) && config?.length === 1)) {
      const propertyToCheck: string = !Array.isArray(config) ? config : config[0];
      const desc = propertyToCheck.substr(0, 1) === '-';

      // Basic array
      if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
        return !desc ? value.sort() : value.sort().reverse();
      } else {
        const property: string = propertyToCheck.substr(0, 1) === '+' || propertyToCheck.substr(0, 1) === '-' ? propertyToCheck.substr(1) : propertyToCheck;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return value.sort(function (a: any, b: any) {
          return !desc ? OrderBy._orderByComparator(a[property], b[property]) : -OrderBy._orderByComparator(a[property], b[property]);
        });
      }
    } else {
      // Loop over property of the array in order and sort
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return value.sort(function (a: any, b: any) {
        for (let i = 0; i < config?.length; i++) {
          const desc = config[i].substr(0, 1) === '-';
          const property = config[i].substr(0, 1) === '+' || config[i].substr(0, 1) === '-' ? config[i].substr(1) : config[i];

          const comparison = !desc ? OrderBy._orderByComparator(a[property], b[property]) : -OrderBy._orderByComparator(a[property], b[property]);

          // Don't return 0 yet in case of needing to sort by next property
          if (comparison !== 0) {
            return comparison;
          }
        }

        return 0; // equal each other
      });
    }
  }
}

export const ORDERBY_PROVIDERS = [OrderBy];
