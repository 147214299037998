import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { MasqueradeMenuService } from './masquerade-menu.service';
import { User } from 'src/app/users/user.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { AppConfigService } from 'src/app/services/config/app.config.service';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-masquerade-menu',
  templateUrl: './masquerade-menu.component.html',
  styleUrls: ['./masquerade-menu.component.scss'],
  providers: [MasqueradeMenuService],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MasqueradeMenuComponent {
  @Input() connectedUser: ConnectedUser;
  @Input() display = false;
  @Output() justDisplayMasqueradeMenu = new EventEmitter<boolean>();

  kloodDateFormat = KloodDateFormat;

  masqueradeUser: string;
  usersSuggest: User[];
  displaySuggest = false;

  api_uri: string;

  constructor(
    private eRef: ElementRef,
    private masqueradeService: MasqueradeMenuService,
    private notif: NotifService,
    private configUi: AppConfigService,
    private platformLocation: PlatformLocation
  ) {
    this.api_uri = this.configUi.settings.API_URI;
  }

  displayMenu(): void {
    this.display = !this.display;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (this.eRef.nativeElement.contains(event.target) && this.display) {
      this.display = true;
    } else {
      this.display = false;
      this.masqueradeUser = '';
      this.displaySuggest = false;
    }
    this.justDisplayMasqueradeMenu.emit(this.display);
  }

  onMasqueradeChange(text: string): void {
    this.masqueradeUser = text;
    if (this.masqueradeUser !== '') {
      this.checkUser(this.masqueradeUser);
    }
  }

  checkUser(searchUser: string): void {
    this.masqueradeService.getSuggest('*', searchUser, 10).subscribe({
      next: (users: User[]) => {
        this.usersSuggest = users;
        if (this.usersSuggest?.length > 0) {
          this.displaySuggest = true;
        }
      },
      error: (error) => {
        this.notif.addErrorNotif('MasqueradeMenuComponent.Error.Get', error);
      }
    });
  }

  validateImpersonate(user: User): void {
    if (localStorage.getItem('impersonatePreviousUrl')) {
      localStorage.removeItem('impersonatePreviousUrl');
    }
    this.masqueradeService.activeImpersonate(user).subscribe({
      next: (result: any) => {
        let url = 'https://' + result['host'];
        url +=
          '?impersonatePreviousUrl=' +
          encodeURIComponent(this.platformLocation.href);
        window.location.href = url;
      },
      error: (error) => {
        console.log(error)
        if (error.status === 412) {
          let msg;
          switch (error.error.code) {
            case 'NEVER_LOGGED':
              msg = 'MasqueradeMenuComponent.Error.ActivateNeverLogged';
              break;
            case 'MISSING_SCOPES':
              msg = 'MasqueradeMenuComponent.Error.ActivateMissingScopes';
              break;
            case 'INVALIDATED_TOKEN':
              msg = 'MasqueradeMenuComponent.Error.ActivateInvalidToken';
              break;
            case 'USER_DISABLED':
              msg = 'MasqueradeMenuComponent.Error.ActivateDisable';
              break;
            default:
              msg = 'MasqueradeMenuComponent.Error.Activate';
              break;
          }
          this.notif.addErrorNotif(msg, error);
        } else {
          this.notif.addErrorNotif(
            'MasqueradeMenuComponent.Error.Activate',
            error
          );
        }
      }
    });
  }

  wrongImpersonate(user: User): void {
    if (user.disabled) {
      this.notif.addErrorNotif('MasqueradeMenuComponent.Error.ActivateDisable');
    } else if (!user.lastAccess) {
      this.notif.addErrorNotif(
        'MasqueradeMenuComponent.Error.ActivateNeverLogged'
      );
    } else {
      return;
    }
  }
}
