import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ServerInfos } from 'src/app/server-infos/server.infos.model';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from 'src/app/services/http-encoder/custom-http-param-encoder.service';
import { AppConsent, Tenant } from 'src/app/tenant/tenant.model';

@Injectable()
export class ConsentApplicationService {
  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService
  ) {}

  getTenant(tenantId: string): Observable<Tenant> {
    if (tenantId === '*') {
      const allTenants: Tenant = new Tenant({});
      allTenants.name = 'all tenants';
      allTenants.id = '*';
      return of(allTenants);
    } else {
      return this.optionService.getApiUrl(tenantId).pipe(
        switchMap((apiUri) => {
          return this.http.get<Tenant>(apiUri, {
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          });
        }),
        map((result) => new Tenant(result))
      );
    }
  }

  getServerInfos(): Observable<ServerInfos> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<ServerInfos>(apiUri + '/discovery', {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new ServerInfos(result))
    );
  }

  consentApp(
    tenant: Tenant,
    redirect_uri: string,
    type: string,
    app?: AppConsent
  ): string {
    redirect_uri = encodeURI(redirect_uri);
    let targetUri: string;
    if (app) {
      targetUri = 'apps/' + app.value.id + '?redirect_uri=' + redirect_uri;
    } else {
      if (type === 'CONSENT_SYNCHRONISATION') {
        targetUri = 'backend/client?redirect_uri=' + redirect_uri;
      } else if (type === 'CONSENT_UI_WEB') {
        targetUri = 'backend/users?redirect_uri=' + redirect_uri;
      } else {
        targetUri = '';
      }
    }
    const uri =
      this.optionService.getApiUrlString(tenant.id) + '/consents/' + targetUri;
    return uri;
  }

  askConsentApp(
    tenant: Tenant,
    redirect_uri: string,
    email: string,
    type: string,
    app?: AppConsent
  ): Observable<unknown> {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoderService(),
    })
      .set('redirect_uri', redirect_uri)
      .set('email', email);
    let targetUri: string;
    if (app) {
      targetUri = 'apps/' + app.value.id;
    } else {
      if (type === 'CONSENT_SYNCHRONISATION') {
        targetUri = 'backend/client';
      } else if (type === 'CONSENT_UI_WEB') {
        targetUri = 'backend/users';
      } else {
        targetUri = '';
      }
    }
    return this.optionService.getApiUrl(tenant.id).pipe(
      switchMap((apiUri) => {
        return this.http.post(apiUri + '/consents/' + targetUri, null, {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        return result;
      })
    );
  }
}
