<div class="attendee-email-field">
  <label for="attendee">
      <input
          type="email"
          name="attendee"
          id="attende"
          placeholder='{{"Attendees.Email" | translate}}'
          (keydown.enter)="$event.preventDefault();"
          (keydown)="addAttendee($event)"
          (blur)="addAttendee($event)"
          (keyup)="checkContact()"
          [(ngModel)]="emailAttendee"
          email
          [disabled]="noChange"
          autocomplete="off"
      />
      <span>{{"Attendees.Email" | translate}}</span>
  </label>
</div>
<app-suggest-contact *ngIf="displaySuggest" [textInput]="emailAttendee" (selectContactEvent)="onSelectContactEvent($event)" (closeSuggestEvent)="onCloseSuggestEvent($event)"> </app-suggest-contact>
<ul id="email-tag-ul" class="list-tag-ul">
  <div class="list-tag" role="alert" *ngFor="let attendee of attendeesList">
      <span (click)="!noChange ? editAttendee(attendee) : false">{{ attendee.email }}</span>
      <button type="button" class="button-list-tag" data-dismiss="alert" aria-label="Close" (click)="removeAttendee(attendee)" *ngIf="!noChange">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
      </button>
  </div>
</ul>
