<div class="toolbar">
    <div class="control mx-2" *ngFor="let control of blocControls" [ngClass]="{'no-details': !needDetails, 'details': needDetails}">
        <div *ngIf="control.isSwitch">
            <div *ngIf="control.controleType === Type.Link">
                <app-switch-field-simple [nameInput]="control.switchName ? control.switchName : 'no-name'" [toolTipSwitch]="control.tooltip" [switchState]="control.switchStatus ? control.switchStatus : false"
                    (switchStateEvent)="onSwitchChange($event)">
                </app-switch-field-simple>
            </div>
            <div *ngIf="control.controleType === Type.Action">
                <app-switch-field-simple [nameInput]="control.switchName ? control.switchName : 'no-name'" [toolTipSwitch]="control.tooltip" [switchState]="control.switchStatus ? control.switchStatus : false"
                    (switchStateEvent)="onSwitchChange($event)">
                </app-switch-field-simple>
            </div>
        </div>
        <div *ngIf="!control.isSwitch">
            <div *ngIf="!control.doubleIcon">
                <div *ngIf="control.controleType === Type.Link">
                    <a [routerLink]="[control.link?.url]" class="oval-button pointer" #tooltip="matTooltip"
                        matTooltip="{{control.tooltip | translate}}" matTooltipClass="custom-tooltip">
                        <fa-icon class="control-icon" [icon]="['fas', control.icon]"></fa-icon>
                    </a>
                </div>
                <div *ngIf="control.controleType === Type.Action">
                    <a (click)="doAction(control.action)" class="oval-button pointer" #tooltip="matTooltip"
                        matTooltip="{{control.tooltip | translate}}" matTooltipClass="custom-tooltip">
                        <fa-icon [icon]="['fas', control.icon]" *ngIf="!needDetails"></fa-icon>
                        <fa-icon class="control-icon" [icon]="['fas', control.icon]" *ngIf="needDetails"></fa-icon>
                    </a>
                </div>
            </div>
            <div *ngIf="control.doubleIcon">
                <div *ngIf="control.controleType === Type.Link">
                    <a [routerLink]="[control.link?.url]" class="oval-button pointer" #tooltip="matTooltip"
                        matTooltip="{{control.tooltip | translate}}" matTooltipClass="custom-tooltip">
                        <fa-layers class="control-icon">
                            <fa-icon [icon]="['fas', control.icon]"></fa-icon>
                            <fa-icon *ngIf="control.secondIcon" class="secondary-icon" [icon]="['fas', control.secondIcon]"
                                transform="shrink-6 right-5 down-3"></fa-icon>
                        </fa-layers>
                    </a>
                </div>
                <div *ngIf="control.controleType === Type.Action">
                    <a class="oval-button pointer" (click)="doAction(control.action)" #tooltip="matTooltip"
                        matTooltip="{{control.tooltip | translate}}" matTooltipClass="custom-tooltip">
                        <fa-layers class="control-icon">
                            <fa-icon [icon]="['fas', control.icon]"></fa-icon>
                            <fa-layers>
                                <fa-icon [icon]="['fas', 'circle']" transform="shrink-4 right-7" style="color:#ff646b">
                                </fa-icon>
                                <fa-icon *ngIf="control.secondIcon" [icon]="['fas', control.secondIcon]"
                                    transform="shrink-7 right-7">
                                </fa-icon>
                            </fa-layers>
                        </fa-layers>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>