import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription, throwError, tap } from 'rxjs';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';
import { HttpErrorResponse } from '@angular/common/http';
import { UserViewService } from './user-view.service';
import { catchError, shareReplay } from 'rxjs/operators';
import { User } from '../user.model';
import { Actions, Controls, ControlType } from 'src/app/commons/toolbar/toolbar.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';
import { RecordRentention } from 'src/app/offer/offer.model';
const debug = Debug('virtuvisio-administration:user-view-component');

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss'],
  providers: [UserViewService]
})
export class UserViewComponent implements OnInit, OnDestroy {
  kloodDateFormat = KloodDateFormat;
  private onInitSubscriptions: Subscription[] = [];
  @Input() connectedUser: ConnectedUser;
  @Input() tenant: Tenant;
  user$: Observable<User>;
  blocControls: Controls[];
  editControl: Controls = {
    icon: 'edit',
    doubleIcon: false,
    secondIcon: 'edit',
    controleType: ControlType.Action,
    action: Actions.Edit,
    tooltip: 'UserViewComponent.Tooltip.Edit',
    isSwitch: false,
  };
  sendPasswordControl: Controls = {
    icon: 'envelope',
    doubleIcon: true,
    secondIcon: 'plus',
    controleType: ControlType.Action,
    action: Actions.Send,
    tooltip: 'UserViewComponent.Tooltip.SendPassword',
    isSwitch: false,
  };
  resetPasswordControl: Controls = {
    icon: 'envelope',
    doubleIcon: true,
    secondIcon: 'redo-alt',
    controleType: ControlType.Action,
    action: Actions.Reset,
    tooltip: 'UserViewComponent.Tooltip.ResetPassword',
    isSwitch: false,
  };
  deleteControl: Controls = {
    icon: 'trash-alt',
    doubleIcon: false,
    secondIcon: 'trash-alt',
    controleType: ControlType.Action,
    action: Actions.Delete,
    tooltip: 'UserViewComponent.Tooltip.Delete',
    isSwitch: false,
  };
  editPasswordControl: Controls = {
    icon: 'key',
    doubleIcon: false,
    secondIcon: 'key',
    controleType: ControlType.Action,
    action: Actions.Password,
    tooltip: 'UserViewComponent.Tooltip.EditPassword',
    isSwitch: false,
  };
  @Output() destroyUserAddEvent = new EventEmitter<boolean>();
  recordingRetentionOptions: RecordRentention[];
  currentRetention: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserViewService,
    private notif: NotifService,
    private translate: TranslateService
  ) {
    this.recordingRetentionOptions = [
      {
        value: -1,
        label: this.translate.instant("UserViewComponent.RecordRetention.Default")
      },
      {
        value: 0,
        label: this.translate.instant("UserViewComponent.RecordRetention.Illimity"),
      },
      {
        value: 1,
        label: this.translate.instant("UserViewComponent.RecordRetention.72h"),
      },
      {
        value: 2,
        label: this.translate.instant("UserViewComponent.RecordRetention.30days(1month)"),
      },
      {
        value: 3,
        label: this.translate.instant("UserViewComponent.RecordRetention.180days(6months)"),
      },
    ];
  }

  ngOnInit(): void {
    if (this.route.firstChild) {
      this.onInitSubscriptions.push(
        this.route.firstChild.paramMap.subscribe((params) => {
          if (params.get('userId')) {
            debug(params.get('userId'));
            this.getUser(params.get('userId') as string);
          }
        })
      );
    }
  }

  getUser(userId: string): void {
    this.user$ = this.userService.getUser(this.tenant, userId).pipe(
      tap(user => {
        this.currentRetention = user.recordRetention ? user.recordRetention : -1;
      }),
      catchError(this.handleError<User>('getUser')),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  backToList(): void {
    this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve',
    });
  }

  onRequestedAction(action: string, user: User, tenant: Tenant): void {
    if (action === 'EDIT') {
      this.router.navigate(['./edit', user.id], {
        relativeTo: this.route,
        queryParamsHandling: 'preserve',
      });
    }
    if (action === 'SEND') {
      this.createPassword(user);
    }
    if (action === 'RESET') {
      this.resetPassword(user);
    }
    if (action === 'DELETE') {
      $('#modalOnDeleteUser').modal('show');
    }
    if (action === 'RESET_PASSWORD') {
      this.router.navigate(['./edit-password', user.id], {
        relativeTo: this.route,
        queryParamsHandling: 'preserve',
      });
    }
  }

  onSwitchChanged(state: boolean, user: User, tenant: Tenant) {
    if (state) {
      this.enableUser(state, user, tenant);
    } else {
      this.disableUser(state, user, tenant);
    }
  }

  enableUser(state: boolean, user: User, tenant: Tenant): void {
    const enabledUser: User = new User({ ...user });
    enabledUser.disabled = !state;
    this.userService.save(tenant, enabledUser).subscribe({
      next: (result) => {
        const successNotif = this.translate.instant(
          'UserViewComponent.SUCCESS.Enable',
          {
            value: user.getDisplayName(),
          }
        );
        this.notif.addSuccessNotif(successNotif);
        // this.getUser(user.id);
      },
      error: (error) => {
        const errorNotif = this.translate.instant(
          'UserViewComponent.ERROR.Enable',
          {
            value: user.getDisplayName(),
          }
        );
        this.notif.addErrorNotif(errorNotif, error);
        this.getUser(user.id);
      }
    });
  }

  disableUser(state: boolean, user: User, tenant: Tenant): void {
    const disabledUser: User = new User({ ...user });
    disabledUser.disabled = !state;
    console.warn(disabledUser);
    this.userService.save(tenant, disabledUser).subscribe({
      next: (result) => {
        const successNotif = this.translate.instant(
          'UserViewComponent.SUCCESS.Disable',
          {
            value: user.getDisplayName(),
          }
        );
        this.notif.addSuccessNotif(successNotif);
        // this.getUser(user.id);
      },
      error: (error) => {
        const errorNotif = this.translate.instant(
          'UserViewComponent.ERROR.Disable',
          {
            value: user.getDisplayName(),
          }
        );
        this.notif.addErrorNotif(errorNotif, error);
        this.getUser(user.id);
      }
    });
  }

  createPassword(user: User): void {
    this.userService.sendCreatePassword(user).subscribe({
      next: () => {
        this.notif.addSuccessNotif('UserViewComponent.SUCCESS.SendPassword');
      },
      error: (error) => {
        this.notif.addErrorNotif('UserViewComponent.ERROR.SendPassword', error);
      }
    });
  }

  resetPassword(user: User): void {
    this.userService.sendResetPassword(user).subscribe({
      next: () => {
        this.notif.addSuccessNotif('UserViewComponent.SUCCESS.ResetPassword');
      },
      error: (error) => {
        this.notif.addErrorNotif(
          'UserViewComponent.ERROR.ResetPassword',
          error
        );
      }
    });
  }

  enableOffer(active: boolean, user: User, offerType: string): void {
    const translateValueParameter = this.translate.instant(
      'UserViewComponent.Offer.' + offerType
    );
    if (active) {
      this.userService.addOffer(user.tenantId, user, offerType).subscribe({
        next: () => {
          const successNotif = this.translate.instant(
            'UserViewComponent.SUCCESS.Add',
            {
              value: translateValueParameter,
            }
          );
          this.notif.addSuccessNotif(successNotif);
          if (!user.allowedOffers) {
            user.allowedOffers = [];
          }
          user.allowedOffers.push(offerType);
        },
        error: (error) => {
          if (error.status === 412) {
            this.notif.addErrorNotif(
              'UserViewComponent.ERROR.MaxLicence',
              error
            );
          } else if (error.status === 409) {
            this.notif.addErrorNotif(
              'UserViewComponent.ERROR.AlreadyUse',
              error
            );
          } else {
            const errorNotif = this.translate.instant(
              'UserViewComponent.ERROR.Add',
              {
                value: translateValueParameter,
              }
            );
            this.notif.addErrorNotif(errorNotif, error);
          }
        }
      });
    } else {
      this.userService.removeOffer(user.tenantId, user, offerType).subscribe({
        next: () => {
          const successNotif = this.translate.instant(
            'UserViewComponent.SUCCESS.Remove',
            {
              value: translateValueParameter,
            }
          );
          this.notif.addSuccessNotif(successNotif);
          user.allowedOffers.splice(user.allowedOffers.indexOf(offerType), 1);
        },
        error: (error) => {
          const errorNotif = this.translate.instant(
            'UserViewComponent.ERROR.Remove',
            {
              value: translateValueParameter,
            }
          );
          this.notif.addErrorNotif(errorNotif, error);
        }
      });
    }
  }

  confirmDeleteUser(user: User): void {
    this.userService.delete(user).subscribe({
      next: (result) => {
        this.router.navigate(['./list'], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve',
        });
        this.notif.addSuccessNotif('UserViewComponent.SUCCESS.Delete');
      },
      error: (error) => {
        this.notif.addErrorNotif('UserViewComponent.ERROR.Delete', error);
      }
    });
  }

  editRecordRetention(recordRetention: number, user: User): void {
    if (recordRetention === -1) {
      delete user.recordRetention;
    } else {
      user.recordRetention = recordRetention;
    }
    this.userService.save(this.tenant, user).subscribe({
      next: () => {
        this.notif.addSuccessNotif('UserViewComponent.RecordRetention.Success.Edit');
      },
      error: (error) => {
        let msg;
        if (error.status === 412) {
          msg = "UserViewComponent.RecordRetention.Error.EditDuration";
        } else {
          msg = "UserViewComponent.RecordRetention.Error.Edit";
        }
        this.notif.addErrorNotif(msg, error);
      }
    });
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.destroyUserAddEvent.emit(true);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
