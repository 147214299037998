<div class="row align-items-center p-3">
  <div class="col-auto">
    <h3 class="m-0">
      <span class="is-bold">{{"OauthClientsComponent.Title" | translate}}</span>
    </h3>
  </div>
  <div class="col">
    <button class="btn btn-app app-primary float-end" type="submit" [routerLink]="['./add']">
      <fa-icon [icon]="['fas', 'plus-circle']" class="mx-1"></fa-icon>{{"OauthClientsComponent.AddOauthClient" | translate}}
    </button>
  </div>
</div>
<div class="row">
  <div class="col" [ngClass]="{'col-12': !isRoute('/add') && !isRoute('/edit') && !isRoute('/details'), 'col-8': isRoute('/add') || isRoute('/edit') || isRoute('/details')}">
    <app-admin-card>
      <div class="custom-table-filter" id="custom-table-container" *ngIf="paginatorReady">
        <mat-form-field appearance="outline" class="mat-form-field-custom" id="custom-search-2">
          <mat-icon matPrefix>
            <fa-icon [icon]="['fas', 'search']"></fa-icon>
          </mat-icon>
          <input matInput (ngModelChange)="onSuggest($event)" [(ngModel)]="paginationInfo.suggest" name="search" placeholder="{{'MatTable.Search' | translate}}" />
        </mat-form-field>
        <div class="border-container" id="custom-table">
          <table mat-table [dataSource]="dataSource" [matSortActive]="paginationInfo.sort" [matSortDirection]="paginationInfo.order" matSort (matSortChange)="onSort($event);" class="custom-table" [hidden]="loading">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"OauthClientsComponent.Id" | translate}}</th>
              <td mat-cell *matCellDef="let client" attr.data-th='{{"OauthClientsComponent.id" | translate}}' class="small-right" (click)="viewClient(client);" [ngClass]="{'selected-row': isRoute(client.id)}">{{client.id}}</td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"OauthClientsComponent.Name" | translate}}</th>
              <td mat-cell *matCellDef="let client" attr.data-th='{{"OauthClientsComponent.name" | translate}}' class="small-right" (click)="viewClient(client);" [ngClass]="{'selected-row': isRoute(client.id)}">{{client.name}}</td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"OauthClientsComponent.Description" | translate}}</th>
              <td mat-cell *matCellDef="let client" attr.data-th='{{"OauthClientsComponent.description" | translate}}' class="small-right" (click)="viewClient(client);" [ngClass]="{'selected-row': isRoute(client.id)}">{{client.description}}</td>
            </ng-container>
            <ng-container matColumnDef="scopes">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-50">{{"OauthClientsComponent.Scopes" | translate}}</th>
              <td mat-cell *matCellDef="let client" attr.data-th='{{"OauthClientsComponent.scopes" | translate}}' class="small-right" (click)="viewClient(client);" [ngClass]="{'selected-row': isRoute(client.id)}">
                <div class="mb-2" *ngFor="let scopes of client.scopes | keyvalue; let i = index; let last = last;">
                  <div>
                    <b><u>{{"OauthClientsComponent.TenantScopesTarget" | translate}}</u> : </b>{{getTenant(scopes.key) | translate}}
                  </div>
                  <div>
                    <b><u>{{"OauthClientsComponent.Scopes" | translate}}</u> : </b>
                    <span *ngFor="let scope of getScopes(scopes.value); let last = last;">{{scope}}<span *ngIf="!last">, </span></span>
                  </div>
                  <hr *ngIf="!last" class="my-2"/>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"OauthClientsComponent.Action" | translate}}</th>
              <td mat-cell *matCellDef="let client" attr.data-th='{{"OauthClientsComponent.action" | translate}}' class="small-right" [ngClass]="{'selected-row': isRoute(client.id)}">
                <div class="w-100 text-center">
                  <a class="pointer" (click)="editClient(client)">
                    <fa-icon [icon]="['fas', 'edit']" class="mx-1"></fa-icon>
                  </a>
                  <span> - </span>
                  <a class="pointer" (click)="selectedOauthClient=client" data-bs-toggle="modal" data-bs-target="#modalOnDeleteOauthClient">
                    <fa-icon [icon]="['fas', 'trash-alt']" class="mx-1"></fa-icon>
                  </a>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="paginationInfo.displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: paginationInfo.displayedColumns;" class="pointer table-hover"></tr>
          </table>
        </div>
        <div *ngIf="!loading && (dataSource && dataSource.data?.length === 0)" class="ms-2 mt-2">
          <i>{{"MatTable.NoAvailableData" | translate}}...</i>
        </div>
        <mat-paginator *ngIf="!loading" class="mat-paginator-custom" [length]="paginationInfo.listLength" [pageSize]="paginationInfo.pageSize" [pageIndex]="paginationInfo.pageIndex" [pageSizeOptions]="paginationInfo.pageSizeOptions" showFirstLastButtons (page)="onChangePagination($event)"></mat-paginator>
      </div>
      <div class="row d-flex justify-content-center align-items-center my-5" *ngIf="loading">
        <div class="col-auto my-auto">
            <app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
        </div>
      </div>
    </app-admin-card>
  </div>
  <div class="col-4" *ngIf="isRoute('/add') || isRoute('/edit') || isRoute('/details')">
    <router-outlet (deactivate)="componentOauthClientRemoved()"></router-outlet>
  </div>
</div>
<div class="modal fade custom-app-modal" id="modalOnDeleteOauthClient" role="dialog" aria-labelledby="modalOnDeleteOauthClientLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content flat">
      <div class="modal-header">
        <h3 class="modal-title text-center" id="modalOnDeleteOauthClientLabel">{{"OauthClientsComponent.Delete" |
          translate}}</h3>
      </div>
      <div class="modal-body">
        <div class="is-bold">{{"OauthClientsComponent.SureDeleteOauthClient" | translate}}</div>
        <div class="mb-3" *ngIf="selectedOauthClient">{{selectedOauthClient.id}}</div>
        <div class="dual-button-container">
          <button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal">
            {{"OauthClientsComponent.Cancel" | translate}}
          </button>
          <button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="confirmDeleteOauthClient()">
            {{"OauthClientsComponent.Confirm" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>