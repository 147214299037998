import { Component, OnInit, OnDestroy } from '@angular/core';
import { DisplayColumn, ActionEvent } from '../commons/mat-table-custom/mat-table-custom.model';
import { TrialsService } from './trials.service';
import { QueryParamsManager } from 'src/app/services/query-params-manager/query-params-manager';
import { ExportService } from 'src/app/services/export/export.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Subscription } from 'rxjs';
import { TrialUser } from 'src/app/trials/trial.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { ActivatedRoute } from '@angular/router';
declare var navigator: any;

@Component({
  selector: 'app-trials',
  templateUrl: './trials.component.html',
  styleUrls: ['./trials.component.scss'],
  providers: [TrialsService, QueryParamsManager, ExportService]
})
export class TrialsComponent implements OnInit, OnDestroy {

  activeTrialCheck = true;
  defaultSort = "email";
  displayedColumns: DisplayColumn[];
  refreshTable = false;
  onInitSubscriptions: Subscription[] = [];
  selectedTrial: TrialUser;
  currentConnectedUser: ConnectedUser;
  editTrial = false;
  hasError = false;
  finished = false;
  constructor(private route: ActivatedRoute, public trialsService: TrialsService, private queryParamsManager: QueryParamsManager, private exportService: ExportService, private sharedService: SharedService, private notifService: NotifService) { }

  ngOnInit(): void {
    this.displayedColumns = [
      { name: "email", type: "any" },
      { name: "displayName", type: "any" },
      { name: "phone", type: "any" },
      { name: "trial.company", type: "any" },
      { name: "trial.how", type: "any" },
      { name: "createdAt", type: "date" },
      { name: "trial.validated", type: "any" },
      { name: "expired", type: "any" },
      { name: "lastAccess", type: "date" },
      { name: "trial.expires", type: "date" },
      { name: "action", type: "action", actions: [{ action: "edit", icon: "edit" }, { action: "delete", icon: "trash-alt" }] }
    ]
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe(result => {
      this.currentConnectedUser = result;
    }))
    this.onInitSubscriptions.push(this.route.queryParams.subscribe((queryParams) => {
      if (queryParams["active"]) {
        if (queryParams["active"] === "false") {
          this.showActiveTrials(false);
        } else {
          this.showActiveTrials(true);
        }
      } else {
        this.showActiveTrials(true);
      }
    }));
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  onDataError(event: any): void {
    console.log(event);
  }

  onActionClick(event: ActionEvent): void {
    this.editTrial = false;
    this.selectedTrial = event.data;
    if (event.action.action === 'delete') {
      $('#modalOnDeleteTrial').modal('show');
    }
    if (event.action.action === 'edit') {
      this.editTrial = true;
      $('#modalOnEditExpirationTrial').modal('show');
    }
  }

  async showActiveTrials(event: boolean): Promise<void> {
    this.activeTrialCheck = event;
    await this.queryParamsManager.changeQueryParams({
      active: this.activeTrialCheck
    });
  }

  confirmDeleteTrial(): void {
    this.trialsService.delete(this.selectedTrial).subscribe({
      next: () => {
        this.refreshTableData();
        this.notifService.addSuccessNotif('TrialsComponent.TrialDeleted');
      },
      error: (error: HttpErrorResponse) => {
        this.refreshTableData();
        this.notifService.addErrorNotif('TrialsComponent.ErrorDeleteTrial', error);
      }
    })
  }

  onTrialEditedEvent(event: boolean): void {
    this.editTrial = false;
    this.refreshTableData();
  }

  exportTrials(): void {
    const fields = "email,firstName,lastName,phone,trial.company,trial.how,createdAt,expired,lastAccess,trial.validated,trial.expires";
    this.exportService.exportData("/trial", fields).subscribe((result) => {
      this.downloadExport(result);
    });
  }

  downloadExport(res: string): void {
    let localeDateString = this.currentConnectedUser.lang.split('-')[0];
    let date = new Date().toLocaleDateString(localeDateString);
    let time = new Date().toLocaleTimeString(localeDateString);
    const splitDate = date.split("/");
    date = splitDate[0] + "-" + splitDate[1] + "-" + splitDate[2];
    const splitTime = time.split(":");
    time = splitTime[0] + "h" + splitTime[1] + "m" + splitTime[2];
    let split;
    if (localeDateString === "fr") {
      split = " à ";
    } else {
      split = ", ";
    }
    const exportDate = date + split + time;
    const exportFilename = "export trials " + exportDate + ".csv";

    const csvData = new Blob([res], { type: "text/csv;charset=utf-8;" });
    // IE11 & Edge
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(csvData, exportFilename);
    } else {
      // In FF link must be added to DOM to be clicked
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute("download", exportFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  refreshTableData(): void {
    this.refreshTable = true;
    setTimeout(() => {
      this.refreshTable = false;
    })
  }

}
