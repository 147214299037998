<ng-container *ngIf="connectedUser && (tenant$ | async) as tenant">
    <div *ngIf="connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
        <div class="p-3">
            <h3>
                <span class="is-bold">{{"ConsentResultComponent.Title" | translate}}:</span>
                <span class="is-bold is-primary"> {{tenant.name | uppercase}}</span>
            </h3>
            <app-admin-bloc [blocTitle]="'ConsentResultComponent.ConsentedApp.SUCCESS'" [translateParams]="{value: 'ConsentResultComponent.AppName.'+appName | translate}" *ngIf="!errors"></app-admin-bloc>
            <app-admin-bloc [blocTitle]="'ConsentResultComponent.ConsentedApp.ERROR'" [translateParams]="{value: 'ConsentResultComponent.AppName.'+appName | translate}" *ngIf="!errors">
                <app-admin-infos-bloc>
                    <p class="is-bold">{{"ConsentResultComponent.Details" | translate}}</p>
                    <p>
                        <span class="is-bold">{{"ConsentResultComponent.Error" | translate}}</span> : {{errors?.error}}
                    </p>
                    <p>
                        <span class="is-bold">{{"ConsentResultComponent.Description" | translate}}</span> : {{errors?.description}}
                    </p>
                    <p>
                        <span class="is-bold">{{"ConsentResultComponent.Url" | translate}}</span> : {{errors?.url}}
                    </p>
                </app-admin-infos-bloc>
            </app-admin-bloc>
        </div>
    </div>
</ng-container>
