import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { Controls } from '../commons/toolbar/toolbar.model';
import { Environment } from '../environments/environment.model';
import { NotifService } from '../services/notifs/notif.service';
import { SharedService } from '../services/shared/shared.service';
import { EwsConfig, Tenant } from '../tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { ValidatorsList } from '../validators/validators.model';
import { OverviewService } from './overview.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  providers: [OverviewService]
})
export class OverviewComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  tenant$: Observable<Tenant>;
  groupsLength$: Observable<number>;
  usersLength$: Observable<number>;
  meetingRoomsLength$: Observable<number>;
  environments: Environment[];
  infosToolbar: Controls[] = [];
  editControl: Controls = {
    icon: 'edit',
    controleType: 'ACTION',
    tooltip: 'OverviewComponent.Toolbar.EditTooltip',
    action: 'EDIT',
    isSwitch: false,
  };
  deleteControl: Controls = {
    icon: 'trash-alt',
    controleType: 'ACTION',
    tooltip: 'OverviewComponent.Toolbar.DeleteTooltip',
    action: 'DELETE',
    isSwitch: false,
  };
  saveControl: Controls = {
    icon: 'save',
    controleType: 'ACTION',
    tooltip: 'OverviewComponent.Toolbar.SaveTooltip',
    action: 'SAVE',
    isSwitch: false,
  };
  cancelControl: Controls = {
    icon: 'times',
    controleType: 'ACTION',
    tooltip: 'OverviewComponent.Toolbar.CancelTooltip',
    action: 'CANCEL',
    isSwitch: false,
  };
  editEwsControl: Controls = {
    icon: 'edit',
    controleType: 'ACTION',
    tooltip: 'OverviewComponent.Toolbar.EditEwsTooltip',
    action: 'EDIT',
    isSwitch: false,
  };
  deleteEwsControl: Controls = {
    icon: 'trash-alt',
    controleType: 'ACTION',
    tooltip: 'OverviewComponent.Toolbar.DeleteEwsTooltip',
    action: 'DELETE',
    isSwitch: false,
  };
  editingInfos = false;
  languages = [
    {
      lang: 'fr-FR',
    },
    {
      lang: 'en-US',
    },
  ];
  ewsConfig: EwsConfig = new EwsConfig({
    username: "",
    password: "",
    domain: "",
    ewsUrl: ""
  });
  editingEwsConfig = false;
  deletingEwsConfig = false;
  ewsToolbar: Controls[] = [];
  validatorsList = ValidatorsList;
  tenantNameToDelete: string;
  //Ews check
  checkResult = false;
  ewsOk = false;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private overviewService: OverviewService,
    private sharedService: SharedService,
    private notif: NotifService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.infosToolbar.push(this.editControl);
    this.ewsToolbar.push(this.editEwsControl);
    this.ewsToolbar.push(this.deleteEwsControl);
    this.onInitSubscriptions.push(
      this.sharedService.currentConnectedUser.subscribe(
        (connectedUser: ConnectedUser) => {
          if (connectedUser) {
            this.connectedUser = connectedUser;
            this.connectedUser.isMultipleGranted(this.connectedUser.tenantId, ['MANAGEMENT', 'MEETING_ROOMS'])
            if (this.connectedUser.hasRole('SUPER_ADMIN')) {
              this.getEnvironments();
            }
          }
        }
      )
    );
    if (this.route.parent) {
      this.onInitSubscriptions.push(
        this.route.parent.paramMap.subscribe((params) => {
          if (params.get('tenantId')) {
            let tenantId = params.get('tenantId') as string;
            this.getTenant(params.get('tenantId') as string);
            if (this.connectedUser.isGranted(tenantId, 'USERS')) {
              if (!this.connectedUser.hasRole('MARKETTING')) {
                this.groupsLength$ = this.overviewService.getGroupsLength(tenantId);
              }
              this.usersLength$ = this.overviewService.getUsersLength(tenantId);
            }
            if (this.connectedUser.isGranted(tenantId, 'MEETING_ROOMS')) {
              this.meetingRoomsLength$ = this.overviewService.getMeetingRoomsLength(tenantId);
            }
          }
        })
      );
    }
  }

  getTenant(tenantId: string): void {
    this.tenant$ = this.overviewService.getTenant(tenantId).pipe(
      catchError(this.handleError<Tenant>('getTenant')),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
    this.tenant$.subscribe((tenant) => {
      let lenToolbarEl = this.infosToolbar.length;
      while (lenToolbarEl--) {
        if (this.infosToolbar[lenToolbarEl].action === 'DELETE') {
          this.infosToolbar.splice(lenToolbarEl, 1);
        }
      }
      if (tenant.authProvider !== 'microsoft') {
        this.infosToolbar.push(this.deleteControl);
      }
    });
  }

  getEnvironments() {
    this.overviewService.getEnvironments().subscribe({
      next: (environments: Environment[]) => {
        this.environments = environments;
        const defEnv = new Environment({});
        defEnv.host = "default";
        defEnv.id = "";
        this.environments.unshift(defEnv);
      },
      error: catchError(this.handleError<Environment[]>('getEnvironments'))
    });
  }

  getEnvironmentName(environmentId: string): string {
    if (this.environments) {
      if (environmentId) {
        for (let env of this.environments) {
          if (env.id === environmentId) {
            return env.host ? env.host : 'OverviewComponent.Default';
          }
        }
      }
      return 'OverviewComponent.Default';
    }
    return '';
  }

  onInformationsAction(event: string, tenant: Tenant): void {
    if (event === 'EDIT') {
      this.editingInfos = true;
      this.infosToolbar = [];
    }
    if (event === 'SAVE') {
      this.editTenant(tenant);
    }
    if (event === 'CANCEL') {
      this.editingInfos = false;
      this.infosToolbar = [this.editControl];
    }
    if (event === 'DELETE') {
      $('#modalOnDeleteTenant').modal('show');
      this.tenantNameToDelete = '';
    }
  }

  editTenant(tenant: Tenant): void {
    if (this.connectedUser.hasRole('SUPER_ADMIN')) {
      this.saveTenant(
        tenant,
        'OverviewComponent.EditInfos.SUCCESS',
        'OverviewComponent.EditInfos.ERROR'
      );
    } else {
      this.saveInfos(
        tenant,
        'OverviewComponent.EditInfos.SUCCESS',
        'OverviewComponent.EditInfos.ERROR'
      );
    }
  }

  saveTenant(
    tenant: Tenant,
    succesNotif: string,
    errorNotif: string,
    ewsConfig?: EwsConfig
  ): void {
    const previousConfig = tenant.ewsConfig;
    if (ewsConfig) {
      tenant.ewsConfig = ewsConfig;
    }
    this.overviewService.save(tenant).subscribe({
      next: (tenant: Tenant) => {
        this.notif.addSuccessNotif(succesNotif);
        this.editingInfos = false;
        this.editingEwsConfig = false;
        this.deletingEwsConfig = false;
        this.infosToolbar = [this.editControl];
        this.ewsToolbar = [this.editEwsControl, this.deleteEwsControl];
      },
      error: (error) => {
        tenant.ewsConfig = previousConfig;
        this.notif.addErrorNotif(errorNotif, error);
        this.editingInfos = false;
        this.editingEwsConfig = false;
        this.deletingEwsConfig = false;
        this.infosToolbar = [this.editControl];
        this.ewsToolbar = [this.editEwsControl, this.deleteEwsControl];
      }
    });
  }

  saveInfos(
    tenant: Tenant,
    succesNotif: string,
    errorNotif: string,
    ewsConfig?: EwsConfig
  ): void {
    const previousConfig = tenant.ewsConfig;
    if (ewsConfig) {
      tenant.ewsConfig = ewsConfig;
    }
    this.overviewService.saveSettings(tenant).subscribe({
      next: (result: any) => {
        this.notif.addSuccessNotif(succesNotif);
        this.editingInfos = false;
        this.editingEwsConfig = false;
        this.deletingEwsConfig = false;
        this.infosToolbar = [this.editControl];
        this.ewsToolbar = [this.editEwsControl, this.deleteEwsControl];
      },
      error: (error) => {
        tenant.ewsConfig = previousConfig;
        this.notif.addErrorNotif(errorNotif, error);
        this.editingInfos = false;
        this.editingEwsConfig = false;
        this.deletingEwsConfig = false;
        this.infosToolbar = [this.editControl];
        this.ewsToolbar = [this.editEwsControl, this.deleteEwsControl];
      }
    });
  }

  onEwsConfigAction(event: string, tenant: Tenant, ewsConfig: EwsConfig): void {
    if (event === 'EDIT') {
      this.editingEwsConfig = true;
      if (tenant.ewsConfig) {
        this.ewsConfig = tenant.ewsConfig;
      }
      this.ewsToolbar = [];
    }
    if (event === 'SAVE') {
      this.editEwsConfig(tenant, ewsConfig);
    }
    if (event === 'CANCEL') {
      this.editingEwsConfig = false;
      this.deletingEwsConfig = false;
      this.ewsToolbar = [this.editEwsControl, this.deleteEwsControl];
    }
    if (event === 'DELETE') {
      this.deletingEwsConfig = true;
      this.ewsToolbar = [];
    }
  }

  validateEwsConfig(tenant: Tenant): void {
    this.loading = true;
    this.overviewService.validateEwsConfig(tenant.id).subscribe({
      next: (result) => {
        this.loading = false;
        this.checkResult = true;
        this.ewsOk = true;
        let resTimeout = setTimeout(() => {
          this.checkResult = false;
          clearTimeout(resTimeout);
        }, 10000);
      },
      error: (error) => {
        this.loading = false;
        this.checkResult = true;
        this.ewsOk = false;
        let resTimeout = setTimeout(() => {
          this.checkResult = false;
          clearTimeout(resTimeout);
        }, 10000);
      }
    });
  }

  editEwsConfig(tenant: Tenant, ewsConfig: EwsConfig): void {
    if (this.connectedUser.hasRole('SUPER_ADMIN')) {
      this.saveTenant(
        tenant,
        'OverviewComponent.EWSConfig.Form.SUCCESS.Edit',
        'OverviewComponent.EWSConfig.Form.ERROR.Edit',
        ewsConfig
      );
    } else {
      this.saveInfos(
        tenant,
        'OverviewComponent.EWSConfig.Form.SUCCESS.Edit',
        'OverviewComponent.EWSConfig.Form.ERROR.Edit',
        ewsConfig
      );
    }
  }

  confirmDeleteEwsConfig(tenant: Tenant): void {
    tenant.ewsConfig = null;
    if (this.connectedUser.hasRole('SUPER_ADMIN')) {
      this.saveTenant(
        tenant,
        'OverviewComponent.EWSConfig.Form.SUCCESS.Delete',
        'OverviewComponent.EWSConfig.Form.ERROR.Delete',
        undefined
      );
    } else {
      this.saveInfos(
        tenant,
        'OverviewComponent.EWSConfig.Form.SUCCESS.Delete',
        'OverviewComponent.EWSConfig.Form.ERROR.Delete',
        undefined
      );
    }
  }

  allowKloodApp(check: boolean, tenant: Tenant): void {
    if (check) {
      if (tenant.allowedServices?.length > 0) {
        tenant.allowedServices.push('KLOOD_APP');
      } else {
        tenant.allowedServices = ['KLOOD_APP'];
      }
      this.saveTenant(
        tenant,
        'OverviewComponent.Services.KloodApp.SUCCESS.Add',
        'OverviewComponent.Services.KloodApp.ERROR.Add'
      );
    } else {
      tenant.allowedServices.splice(
        tenant.allowedServices.indexOf('KLOOD_APP'),
        1
      );
      this.saveTenant(
        tenant,
        'OverviewComponent.Services.KloodApp.SUCCESS.Remove',
        'OverviewComponent.Services.KloodApp.ERROR.Remove'
      );
    }
  }

  disableKloodApp(check: boolean, tenant: Tenant, service: string): void {
    if (check) {
      this.overviewService.enableService(tenant, service).subscribe({
        next: (result) => {
          this.notif.addSuccessNotif(
            'OverviewComponent.Services.KloodApp.SUCCESS.Enable'
          );
        },
        error: (error) => {
          this.notif.addErrorNotif(
            'OverviewComponent.Services.KloodApp.ERROR.Enable',
            error
          );
        }
      });
    } else {
      this.overviewService.disableService(tenant, service).subscribe({
        next: (result) => {
          this.notif.addSuccessNotif(
            'OverviewComponent.Services.KloodApp.SUCCESS.Disable'
          );
        },
        error: (error) => {
          this.notif.addErrorNotif(
            'OverviewComponent.Services.KloodApp.ERROR.Disable',
            error
          );
        }
      });
    }
  }

  countOffers(obj: any): number {
    let count = 0;
    for (const property in obj) {
      count++;
    }
    return count;
  }

  countEnabledOffers(obj: any): number {
    let count = 0;
    for (const property in obj) {
      count++;
    }
    return count;
  }

  hidePasswordText(pass: string): string {
    return pass.replace(/./g, '*');
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }

  confirmDeleteTenant(): void {
    this.tenant$.subscribe((tenant) => {
      this.overviewService.delete(tenant).subscribe({
        next: (result) => {
          this.notif.addSuccessNotif(
            'OverviewComponent.Tenant.Delete.SuccessDeleteTenant'
          );
          this.router.navigate(['/administration']);
        },
        error: (error) => {
          this.notif.addErrorNotif(
            'OverviewComponent.Tenant.Delete.ErrorDeleteTenant',
            error
          );
        }
      });
    });
  }
}
