import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/services/error/error.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<any> {
    const errorService = this.injector.get(ErrorService);
    return next.handle(request).pipe(
      // retry(1),
      map((event) => {
        if (event instanceof HttpResponse) {
          if (event.headers.get('Deprecation')) {
            console.warn('url deprecation');
            console.warn(event.url);
          }
          return event;
        } else {
          return;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          errorService.handleError(error);
        }
        // if (error.headers.get('trace-id')) {
        //     error['trace-id'] = error.headers.get('trace-id');
        // }
        // if (error.headers.get('span-id')) {
        //     error['span-id'] = error.headers.get('span-id');
        // }
        // error['trace-id'] = error.headers.get('trace-id');
        // error['span-id'] = error.headers.get('span-id');
        return throwError(() => error);
      })
    );
  }
}
