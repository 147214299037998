import { animate, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { Actions, Controls, ControlType } from 'src/app/commons/toolbar/toolbar.model';
import { Offer, OfferKeyValue, OffersType } from 'src/app/offer/offer.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { OffersAdministrationService } from './offers-administration.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers-administration.component.html',
  styleUrls: ['./offers-administration.component.scss'],
  providers: [OffersAdministrationService],
  animations: [
    trigger('slideFromRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate(
          '0s 200ms cubic-bezier(1, -0.45, 0, 1.47)',
          style({ transform: 'translateX(0)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate(
          '0.5s cubic-bezier(1, -0.45, 0, 1.47)',
          style({ transform: 'translateX(100%)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class OffersAdministrationComponent implements OnInit, OnDestroy {
  private paramsSubscription: Subscription;
  connectedUser$: Observable<ConnectedUser>;
  tenant$: Observable<Tenant>;
  editControl: Controls = {
    icon: 'edit',
    doubleIcon: false,
    secondIcon: 'edit',
    controleType: ControlType.Action,
    action: Actions.Edit,
    tooltip: 'OffersComponent.Tooltip.Edit',
    isSwitch: false,
  };
  renewControl: Controls = {
    icon: 'sync-alt',
    doubleIcon: false,
    secondIcon: 'sync-alt',
    controleType: ControlType.Action,
    action: Actions.Renew,
    tooltip: 'OffersComponent.Tooltip.Renew',
    isSwitch: false,
  };
  renewingOffer: boolean;
  selectedOffer: string | null;
  editingOffer: boolean;
  offersTypeInit = OffersType;
  offersType: string[];
  addingOffer: boolean;

  constructor(
    private route: ActivatedRoute,
    private offersService: OffersAdministrationService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private notif: NotifService
  ) { }

  ngOnInit(): void {
    this.connectedUser$ = this.sharedService.currentConnectedUser;
    if (this.route.parent) {
      this.paramsSubscription = this.route.parent.paramMap.subscribe((params) => {
        if (params.get('tenantId')) {
          this.getTenant(params.get('tenantId') as string);
        }
      });
    }
  }

  getTenant(tenantId: string): void {
    this.tenant$ = this.offersService.getTenant(tenantId).pipe(
      catchError(this.handleError<Tenant>('getTenant')),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      }),
      tap((tenant: Tenant) => {
        this.offersType = [...this.offersTypeInit];
        for (const tenantOffer in tenant.offers) {
          let oL = this.offersType.length;
          while (oL--) {
            if (this.offersType[oL] === tenantOffer) {
              this.offersType.splice(oL, 1);
            }
          }
        }
      })
    );
  }

  addOffer(tenant: Tenant, offerType: string): void {
    const translateValueParameter = this.translate.instant('OfferFormComponent.Offer.' + offerType);
    const expire = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    const offer = new Offer({});
    offer.expiresAt = expire;
    offer.alternativeDomains = [];
    offer.networks = [];
    const editedTenant: Tenant = new Tenant({ ...tenant });
    editedTenant.offers[offerType] = offer;
    this.offersService.saveTenant(editedTenant).subscribe({
      next: () => {
        const successNotif = this.translate.instant('OfferFormComponent.SUCCESS.Add', { value: translateValueParameter });
        this.notif.addSuccessNotif(successNotif);
        this.getTenant(tenant.id);
      },
      error: (error) => {
        if (error.status === 412) {
          const errorNotif = this.translate.instant('OfferFormComponent.ERROR.Duplicate', { value: translateValueParameter });
          this.notif.addErrorNotif(errorNotif, error);
        } else {
          const errorNotif = this.translate.instant('OfferFormComponent.ERROR.Add', { value: translateValueParameter });
          this.notif.addErrorNotif(errorNotif, error);
        }
      }
    });
  }

  onRequestedAction(action: string, offer: OfferKeyValue): void {
    if (action === 'RENEW') {
      if (this.selectedOffer === offer.key) {
        if (this.editingOffer) {
          this.editingOffer = false;
          this.renewingOffer = true;
        } else {
          this.selectedOffer = null;
          this.renewingOffer = false;
        }
      } else {
        this.selectedOffer = offer.key;
        this.renewingOffer = true;
      }
    }
    if (action === 'EDIT') {
      if (this.checkExpired(offer.value) !== -1) {
        if (this.selectedOffer === offer.key) {
          if (this.renewingOffer) {
            this.renewingOffer = false;
            this.editingOffer = true;
          } else {
            this.selectedOffer = null;
            this.editingOffer = false;
          }
        } else {
          this.selectedOffer = offer.key;
          this.editingOffer = true;
        }
      } else {
        this.notif.addErrorNotif('OffersComponent.NoEditExpired');
      }
    }
  }

  onSwitchChanged(state: boolean, offer: OfferKeyValue, tenant: Tenant) {
    if (state) {
      this.enableOffer(state, offer, tenant);
    } else {
      this.disableOffer(state, offer, tenant);
    }
  }

  onCancelRenew(): void {
    this.selectedOffer = null;
    this.renewingOffer = false;
  }

  onCancelEdit(): void {
    console.warn('allo');
    this.selectedOffer = null;
    this.editingOffer = false;
  }

  enableOffer(state: boolean, offer: OfferKeyValue, tenant: Tenant): void {
    const translateValueParameter = this.translate.instant('OffersComponent.Offer.' + offer.key);
    this.offersService.enableOffer(tenant, offer.key).subscribe({
      next: (result) => {
        const successNotif = this.translate.instant('OfferFormComponent.SUCCESS.Enable', { value: translateValueParameter });
        this.notif.addSuccessNotif(successNotif);
        this.getTenant(tenant.id);
      },
      error: (error) => {
        const errorNotif = this.translate.instant('OfferFormComponent.ERROR.Enable', { value: translateValueParameter });
        this.notif.addErrorNotif(errorNotif, error);
        this.getTenant(tenant.id);
      }
    });
  }

  disableOffer(state: boolean, offer: OfferKeyValue, tenant: Tenant): void {
    const translateValueParameter = this.translate.instant('OffersComponent.Offer.' + offer.key);
    this.offersService.disableOffer(tenant, offer.key).subscribe({
      next: (result) => {
        const successNotif = this.translate.instant('OfferFormComponent.SUCCESS.Disable', { value: translateValueParameter });
        this.notif.addSuccessNotif(successNotif);
        this.getTenant(tenant.id);
      },
      error: (error) => {
        const errorNotif = this.translate.instant('OfferFormComponent.ERROR.Disable', { value: translateValueParameter });
        this.notif.addErrorNotif(errorNotif, error);
        this.getTenant(tenant.id);
      }
    });
  }

  checkExpired(offer: Offer): number {
    if (new Date().getTime() > new Date(offer.expiresAt).getTime()) {
      return -1;
    } else {
      if (0 < new Date(offer.expiresAt).getTime() - new Date().getTime() && new Date(offer.expiresAt).getTime() - new Date().getTime() <= 1296000000) {
        return 15;
      } else if (1296000000 < new Date(offer.expiresAt).getTime() - new Date().getTime() && new Date(offer.expiresAt).getTime() - new Date().getTime() <= 2592000000) {
        return 30;
      }
      return 0;
    }
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
