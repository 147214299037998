<div>
    <div *ngIf="kind !== 'RECORDING'" class="dual-div">
        <div class="is-bold">{{"OfferViewComponent.Count" | translate}} :</div>
        <div>{{offer.count !== -1 ? offer.count : ('OfferViewComponent.NoLimit' | translate) }}</div>
    </div>
    <div>
        <div class="row align-items-center">
            <div class="col-auto my-auto">
                <div class="is-bold">{{"OfferViewComponent.Expiration" | translate}} : </div> 
            </div>
            <div class="col my-auto">
                <div class="float-end" *ngIf="checkExpired() !== -1 && !renewOffer" [ngClass]="{
                    'expires-at-15-txt': checkExpired() === 15 && !offer.disabledAt,
                    'expires-at-30-txt': checkExpired() === 30 && !offer.disabledAt,
                    'is-regular': (checkExpired() === 15 || checkExpired() === 30) && !offer.disabledAt,
                    'line-txt': offer.disabledAt
                }">
                    {{offer.expiresAt | date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
                </div>
                <div class="float-end is-bold" *ngIf="checkExpired() === -1 && !renewOffer">
                    <i>{{"OfferViewComponent.Expired" | translate}}</i>
                </div>
                <div class="float-end" *ngIf="renewOffer">
                    <app-date-picker-field [connectedUser]="connectedUser" [min]="nowDate" [nameInput]="'renew-'+kind" [myInputModel]="renewDate" (dateSelect)="onDateSelect($event)"></app-date-picker-field>
                </div>
            </div>
        </div>
        <div class="dual-button-container my-3" *ngIf="renewOffer">
            <button class="btn btn-app app-secondary width-100" type="reset" (click)="cancel()">
                {{"Button.Cancel" | translate }}
            </button>
            <button class="btn btn-app app-primary width-100" type="submit" (click)="renew(renewDate, offer, kind, tenant)">
                {{"Button.Renew" | translate }}
            </button>
        </div>
    </div>
    <div *ngIf="kind === 'PRO' || kind === 'PRO_AUDIO'" class="dual-div">
        <div class="is-bold">{{"OfferViewComponent.Coorganization" | translate}} :</div>
        <div>
            <app-checkbox-field 
                [textLabel]="''"
                [nameInput]="'coorganization-'+kind" 
                [myInputModel]="offer.coOrganization"
                [disabled]="!connectedUser.isGranted(tenant.id, 'MANAGEMENT') || connectedUser.hasRole('MARKETING')"
                [checked]="offer.coOrganization"
                (checkedEvent)="editCoOrganization($event, kind, offer, tenant)"></app-checkbox-field>
        </div>
    </div>
    <div *ngIf="kind === 'RECORDING'" class="dual-div">
        <div class="is-bold">{{"OfferViewComponent.RecordRetention.Delay" | translate}} :</div> 
        <div *ngIf="offer.recordRetention === 0">{{"OfferViewComponent.RecordRetention.Unlimited" | translate}}</div>
        <div *ngIf="offer.recordRetention === 1">{{"OfferViewComponent.RecordRetention.72h" | translate}}</div>
        <div *ngIf="offer.recordRetention === 2">{{"OfferViewComponent.RecordRetention.30d" | translate}}</div>
        <div *ngIf="offer.recordRetention === 3">{{"OfferViewComponent.RecordRetention.180d" | translate}}</div>
    </div>
    <ng-container *ngIf="kind === 'TEAMS_GATEWAY' || kind === 'HANGOUT_GATEWAY'">
        <div class="accordion accordion-offer mt-2" id="{{'accordionOffer'+kind}}">
            <div class="accordion-item">
                <h2 class="accordion-header" id="{{'headingOffer'+kind}}">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+kind" aria-expanded="false" [attr.aria-controls]="'#collapse'+kind">
                        {{"OfferViewComponent.MoreInfos" | translate}}
                    </button>
                </h2>
                <div id="{{'collapse'+kind}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'#headingOffer'+kind" [attr.data-bs-parent]="'#accordionOffer'+kind">
                    <div class="accordion-body">
                        <div *ngIf="kind === 'TEAMS_GATEWAY'">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.TeamsConnector" | translate}} : </span>{{offer.teamsConnector}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.OtherConnection" | translate}} : </span>{{offer.otherConnectionModeUrlTemplate}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.AlternativeDomains" | translate}} : </span>
                                    <span *ngIf="offer.alternativeDomains.length > 0">
                                        <span *ngFor="let alternativeDomain of offer.alternativeDomains">{{alternativeDomain}} </span>
                                    </span>  
                                </div>
                            </div>
                        </div>
                        <div *ngIf="kind === 'HANGOUT_GATEWAY'">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.AccessToken" | translate}} : </span>{{offer.accessToken}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.IdLength" | translate}} : </span>{{offer.idLength}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="kind === 'TEAMS_GATEWAY' || kind === 'HANGOUT_GATEWAY'">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.Domain" | translate}} : </span>{{offer.domain}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.Prefix" | translate}} : </span>{{offer.prefix}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.EnableLobbyBypass" | translate}} : </span>
                                    <span *ngIf="offer.enableLobbyBypass" class="is-true"><fa-icon [icon]="['fas', 'check']"></fa-icon></span>
                                    <span *ngIf="!offer.enableLobbyBypass" class="is-false"><fa-icon [icon]="['fas', 'times']"></fa-icon></span>  
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.FilterTrustedDevicesOnNetwork" | translate}} : </span>
                                    <span *ngIf="offer.filterTrustedDevicesOnNetwork" class="is-true"><fa-icon [icon]="['fas', 'check']"></fa-icon></span>
                                    <span *ngIf="!offer.filterTrustedDevicesOnNetwork" class="is-false"><fa-icon [icon]="['fas', 'times']"></fa-icon></span>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.FilterTrustedDevicesOnPexipLocation" | translate}} : </span>
                                    <span *ngIf="offer.filterTrustedDevicesOnPexipLocation" class="is-true"><fa-icon [icon]="['fas', 'check']"></fa-icon></span>
                                    <span *ngIf="!offer.filterTrustedDevicesOnPexipLocation" class="is-false"><fa-icon [icon]="['fas', 'times']"></fa-icon></span>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.FilterTrustedDevicesOnPexipRegistration" | translate}} : </span>
                                    <span *ngIf="offer.filterTrustedDevicesOnPexipRegistration" class="is-true"><fa-icon [icon]="['fas', 'check']"></fa-icon></span>
                                    <span *ngIf="!offer.filterTrustedDevicesOnPexipRegistration" class="is-false"><fa-icon [icon]="['fas', 'times']"></fa-icon></span>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.Location" | translate}} : </span>{{offer.location}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <span class="is-bold">{{"OfferViewComponent.Networks" | translate}} : </span>
                                    <span *ngIf="offer.networks.length > 0">
                                        <span class="network-details" *ngFor="let network of offer.networks">{{network.ip}}/{{network.mask}} </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
