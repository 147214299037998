import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsentApplicationComponent } from './consent/consent-application/consent-application.component';
import { ConsentResultComponent } from './consent/consent-result/consent-result.component';
import { WaitingConsentComponent } from './consent/waiting-consent/waiting-consent.component';
import { FourHoFourComponent } from './four-ho-four/four-ho-four.component';
import { HistoryMeetingsComponent } from './history-meetings/history-meetings.component';
import { HistoryPolicyRejectsComponent } from './history-policy-rejects/history-policy-rejects.component';
import { HomeComponent } from './home/home.component';
import { DetailsLiveConferenceComponent } from './live-conferences/details-live-conference/details-live-conference.component';
import { LiveConferencesComponent } from './live-conferences/live-conferences.component';
import { PexipParticipantComponent } from './live-conferences/participants-live-conference/pexip-participant.component';
import { AddMeetingRoomComponent } from './meeting-rooms/add-meeting-room/add-meeting-room.component';
import { DetailsMeetingRoomComponent } from './meeting-rooms/details-meeting-room/details-meeting-room.component';
import { EditMeetingRoomComponent } from './meeting-rooms/edit-meeting-room/edit-meeting-room.component';
import { ImportMeetingRoomsComponent } from './meeting-rooms/import-meeting-rooms/import-meeting-rooms.component';
import { MeetingRoomsComponent } from './meeting-rooms/meeting-rooms.component';
import { OverviewComponent } from './overview/overview.component';
import { EditPlannedMeetingComponent } from './planned-meetings/edit-planned-meeting/edit-planned-meeting.component';
import { PlannedMeetingsComponent } from './planned-meetings/planned-meetings.component';
import { AuthGuardService } from './services/guard/auth-guard/auth-guard.guard';
import { StatisticsComponent } from './statistics/statistics.component';
import { SyncTenantComponent } from './tenant/sync-tenant/sync-tenant.component';
import { UserEditPasswordComponent } from './users/user-edit-password/user-edit-password.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UserViewComponent } from './users/user-view/user-view.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { VisioComponent } from './visio/visio.component';
import { HomeAdministrationComponent } from './home-administration/home-administration.component';
import { OffersAdministrationComponent } from './offer/offer-administration/offers-administration.component';
import { UsersComponent } from './users/users.component';
import { HomeManagementComponent } from './home-management/home-management.component';
import { OfferManagementComponent } from './offer/offer-management/offer-management.component';
import { TrialsComponent } from './trials/trials.component';
import { AuthorizationsComponent } from 'src/app/authorizations/authorizations.component';
import { GroupsComponent } from 'src/app/groups/groups.component';
import { EditGroupComponent } from 'src/app/groups/edit-group/edit-group.component';
import { ImportGroupComponent } from 'src/app/groups/import-group/import-group.component';
import { EnvironmentsComponent } from 'src/app/environments/environments.component';
import { AddEnvironmentComponent } from 'src/app/environments/add-environment/add-environment.component';
import { EditEnvironmentComponent } from 'src/app/environments/edit-environment/edit-environment.component';
import { OauthClientsComponent } from './oauth-clients/oauth-clients.component';
import { AddOauthClientComponent } from './oauth-clients/add-oauth-client/add-oauth-client.component';
import { EditOauthClientComponent } from './oauth-clients/edit-oauth-client/edit-oauth-client.component';
import { DetailsOauthClientComponent } from './oauth-clients/details-oauth-client/details-oauth-client.component';
import { GeozonesComponent } from './geozones/geozones.component';
import { UserAddComponent } from './users/user-add/user-add.component';
import { MailsConfigurationComponent } from './mails-configuration/mails-configuration.component';
import { InvitationsConfigurationComponent } from './invitations-configuration/invitations-configuration.component';
import { AddTenantComponent } from './tenant/add-tenant/add-tenant.component';
import { ErrorsComponent } from './errors/errors.component';
import { UsageComponent } from './usage/usage.component';
import { SuperAdminGuardGuard } from './services/guard/super-admin-guard/super-admin-guard.guard';
import { RoleGuardService } from './services/guard/role-guard/role-guard.guard';
import { ObtpComponent } from './obtp/obtp.component';
import { MultiRoleGuardGuard } from './services/guard/multi-role-guard/multi-role-guard.guard';

const routes: Routes = [
  { path: 'administration', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'not-found', component: FourHoFourComponent },
  { path: 'errors', component: ErrorsComponent },
  {
    path: 'administration/:tenantId',
    component: HomeAdministrationComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [RoleGuardService],
        canActivateChild: [RoleGuardService],
        data: { acl: 'USERS' },
        children: [
          { path: 'list', component: UsersListComponent },
          { path: 'add', component: UserAddComponent },
          { path: 'view/:userId', component: UserViewComponent },
          { path: 'edit/:userId', component: UserEditComponent },
          { path: 'edit-password/:userId', component: UserEditPasswordComponent }
        ],
      },
      { path: 'authorizations', component: AuthorizationsComponent, canActivate: [RoleGuardService], data: { acl: 'MASTER' } },
      { path: 'add-tenant', component: AddTenantComponent, canActivate: [SuperAdminGuardGuard] },
      { path: 'admin-stats', component: StatisticsComponent, canActivate: [RoleGuardService], data: { acl: 'STATS' } },
      {
        path: 'groups',
        component: GroupsComponent,
        canActivate: [RoleGuardService],
        canActivateChild: [RoleGuardService],
        data: { acl: 'USERS' },
        children: [
          { path: 'edit/:groupId', component: EditGroupComponent },
          { path: 'import', component: ImportGroupComponent },
        ],
      },
      { path: 'overview', component: OverviewComponent, canActivate: [RoleGuardService] },
      { path: 'sync', component: SyncTenantComponent, canActivate: [RoleGuardService] },
      { path: 'visio-configuration', component: VisioComponent, canActivate: [RoleGuardService], data: { acl: 'MANAGEMENT' } },
      { path: 'consents', component: ConsentApplicationComponent, canActivate: [RoleGuardService], data: { acl: 'MANAGEMENT' } },
      { path: 'app-consented/:appName', component: ConsentResultComponent, canActivate: [RoleGuardService], data: { acl: 'MANAGEMENT' } },
      { path: 'waiting-consent/:appName', component: WaitingConsentComponent, canActivate: [RoleGuardService], data: { acl: 'MANAGEMENT' } },
      { path: 'offers', component: OffersAdministrationComponent, canActivate: [SuperAdminGuardGuard] },
      { path: 'mails-configuration', component: MailsConfigurationComponent, canActivate: [RoleGuardService], data: { acl: 'CONTENT' } },
      { path: 'invitations-configuration', component: InvitationsConfigurationComponent, canActivate: [RoleGuardService], data: { acl: 'CONTENT' } },
      {
        path: 'meeting-rooms',
        component: MeetingRoomsComponent,
        canActivate: [RoleGuardService],
        canActivateChild: [RoleGuardService],
        data: { acl: 'MEETING_ROOMS' },
        children: [
          { path: 'add', component: AddMeetingRoomComponent },
          { path: ':meetingRoomId/edit', component: EditMeetingRoomComponent },
          { path: ':meetingRoomId/details', component: DetailsMeetingRoomComponent }
        ]
      },
      { path: 'meeting-rooms/import', component: ImportMeetingRoomsComponent, canActivate: [RoleGuardService], data: { acl: 'MEETING_ROOMS' } },
      { path: 'history-meetings', component: HistoryMeetingsComponent, canActivate: [RoleGuardService], data: { acl: 'MEETINGS' } },
      {
        path: 'planned-meetings',
        canActivate: [RoleGuardService],
        canActivateChild: [RoleGuardService],
        data: { acl: 'MEETINGS' },
        component: PlannedMeetingsComponent,
        children: [
          { path: ':meetingId', component: EditPlannedMeetingComponent },
        ]
      },
      { path: 'live-conferences', component: LiveConferencesComponent, canActivate: [RoleGuardService], data: { acl: 'CONFERENCES' } },
      { path: 'live-conferences/:conferenceId', component: DetailsLiveConferenceComponent, canActivate: [RoleGuardService], data: { acl: 'CONFERENCES' } },
      { path: 'live-conferences/:conferenceId/:participantId', component: PexipParticipantComponent, canActivate: [RoleGuardService], data: { acl: 'CONFERENCES' } },
      { path: 'historic-policy-rejects', component: HistoryPolicyRejectsComponent, canActivate: [RoleGuardService], data: { acl: 'MEETINGS' } },
      { path: 'obtp', component: ObtpComponent, canActivate: [MultiRoleGuardGuard], data: { acls: ['MANAGEMENT', 'MEETING_ROOMS'] } }
    ],
  },
  {
    path: 'management',
    component: HomeManagementComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: 'environments',
        component: EnvironmentsComponent,
        canActivate: [SuperAdminGuardGuard],
        canActivateChild: [SuperAdminGuardGuard],
        children: [
          { path: 'add', component: AddEnvironmentComponent },
          { path: ':environmentId/edit', component: EditEnvironmentComponent }
        ]
      },
      { path: 'geozones', component: GeozonesComponent, canActivate: [SuperAdminGuardGuard] },
      { path: 'trials', component: TrialsComponent, canActivate: [RoleGuardService] },
      { path: 'offers', component: OfferManagementComponent, canActivate: [SuperAdminGuardGuard] },
      {
        path: 'oauth-clients',
        component: OauthClientsComponent,
        canActivate: [SuperAdminGuardGuard],
        canActivateChild: [SuperAdminGuardGuard],
        children: [
          { path: 'add', component: AddOauthClientComponent },
          { path: 'details/:clientId', component: DetailsOauthClientComponent },
          { path: 'edit/:clientId', component: EditOauthClientComponent },
        ],
      },
    ],
  },
  { path: 'usage', component: UsageComponent, canActivate: [AuthGuardService, SuperAdminGuardGuard] },
  { path: '', redirectTo: 'administration', pathMatch: 'full' },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
