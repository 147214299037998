<div>
    <form #editOfferForm="ngForm" name="editOfferForm" id="editOfferForm" (ngSubmit)="editOfferForm.valid ? validate(editOfferForm.value, kind, offer, tenant) : null">
        <app-date-picker-form-field [connectedUser]="connectedUser" [nameInput]="'expiresAt'" [textLabel]="'OfferFormComponent.Expiration'"
        [myInputModel]="editedOffer.expiresAt" (dateSelect)="onDateSelect($event)" [form]="editOfferForm" class="form-control"></app-date-picker-form-field>
        <div *ngIf="kind !== 'RECORDING'" class="w-50">
            <app-input-form-field [hidden]="illimity" [typeInput]="'number'" [nameInput]="'count'" [textLabel]="'OfferFormComponent.Count'" [minNumber]="-1" [myInputModel]="editedOffer.count" [form]="editOfferForm" class="form-control"></app-input-form-field>
            <div class="form-control" [ngClass]="{'follow-input': !illimity}">
                <label *ngIf="illimity"><span>{{"OfferFormComponent.Count" | translate}}</span></label>
                <app-checkbox-field [nameInput]="'illimity'" [myInputModel]="illimity" [checked]="illimity"
                  [textLabel]="'OfferFormComponent.NoLimit'" (checkedEvent)="onCheckIllimity($event)"></app-checkbox-field>
            </div>
        </div>
        <div *ngIf="kind === 'PRO' || kind === 'PRO_AUDIO'" class="form-control follow-input">
            <app-checkbox-form-field [textLabel]="'OfferFormComponent.Coorganization'" [form]="editOfferForm"
              [nameInput]="'coorganization'" [myInputModel]="editedOffer.coOrganization" [checked]="editedOffer.coOrganization">
            </app-checkbox-form-field>
        </div>
        <div *ngIf="kind === 'RECORDING'">
            <app-input-dropdown-form-field class="form-control" [form]="editOfferForm" [defaultText]="'OfferFormComponent.RecordRetention.Delay'"
                [modelKey]="'value'" [displayKey]="'label'" [dataSource]="recordRetentions" [selectedElement]="editedOffer.recordRetention" [myInputModel]="editedOffer.recordRetention"></app-input-dropdown-form-field>
        </div>
        <div *ngIf="kind === 'TEAMS_GATEWAY'" class="follow-input">
            <app-input-form-field [typeInput]="'text'" [nameInput]="'teamsConnector'" [textLabel]="'OfferFormComponent.TeamsConnector'" [myInputModel]="editedOffer.teamsConnector" [form]="editOfferForm" class="form-control"></app-input-form-field>
            <app-input-form-field [typeInput]="'text'" [nameInput]="'otherConnectionModeUrlTemplate'" [textLabel]="'OfferFormComponent.OtherConnection'" [myInputModel]="editedOffer.otherConnectionModeUrlTemplate" [form]="editOfferForm" class="form-control"></app-input-form-field>
            <div *ngIf="!addNewAlternativeDomain">
                <a (click)="addNewAlternativeDomain = true; alternativeDomains = '';" class="pointer add-new-network-link">
                    <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> {{"OfferFormComponent.AddAlternative" | translate}}
                </a>
            </div>
            <div *ngIf="addNewAlternativeDomain">
                <a (click)="addNewAlternativeDomain = false; alternativeDomains = '';" class="pointer add-new-network-link">
                    <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon> {{"OfferFormComponent.AddAlternative" | translate}}
                </a>
            </div>
            <div *ngIf="addNewAlternativeDomain" class="need-top-margin">
                <form #addNewAlternativeDomainForm="ngForm" name="addNewAlternativeDomainForm" id="addNewAlternativeDomainForm" (ngSubmit)="addNewAlternativeDomainForm.valid ? validAddAlternativeDomains(addNewAlternativeDomainForm.value) : null">
                    <app-input-form-field [typeInput]="'text'" [nameInput]="'alternativeDomains'"
                      [textLabel]="'OfferFormComponent.AlternativeDomains'" [myInputModel]="alternativeDomains"
                      [form]="addNewAlternativeDomainForm" class="form-control"></app-input-form-field>
                    <div class="dual-button-container">
                        <button class="btn btn-app app-secondary width-100" type="reset" (click)="cancelAddAlternativeDomain()">
                            {{"Button.Cancel" | translate }}
                        </button>
                        <button class="btn btn-app app-primary width-100" type="submit">
                            {{"Button.Add" | translate }}
                        </button>
                    </div>
                </form>
            </div>
            <div *ngIf="alternativeDomainsList.length > 0" class="need-top-margin">
                <div class="is-bold">{{"OfferFormComponent.AlternativeDomains" | translate}}</div>
                <div class="dual-div" *ngFor="let domain of alternativeDomainsList">
                    <div>{{ domain }}</div>
                    <div>
                        <a class="pointer remove-network-link" (click)="removeAlternativeDomain(domain)">
                            <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="kind === 'HANGOUT_GATEWAY'" class="follow-input">
            <app-input-form-field [typeInput]="'text'" [nameInput]="'accessToken'" [textLabel]="'OfferFormComponent.AccessToken'" [myInputModel]="editedOffer.accessToken" [form]="editOfferForm" class="form-control"></app-input-form-field>
            <app-input-form-field [typeInput]="'text'" [nameInput]="'idLength'" [textLabel]="'OfferFormComponent.IdLength'" [myInputModel]="editedOffer.idLength" [form]="editOfferForm" class="form-control"></app-input-form-field>
        </div>
        <div *ngIf="kind === 'TEAMS_GATEWAY' || kind === 'HANGOUT_GATEWAY'">
            <app-input-form-field [typeInput]="'text'" [nameInput]="'domain'" [textLabel]="'OfferFormComponent.Domain'" [myInputModel]="editedOffer.domain" [form]="editOfferForm" class="form-control need-top-margin"></app-input-form-field>
            <app-input-form-field [typeInput]="'text'" [nameInput]="'prefix'" [textLabel]="'OfferFormComponent.Prefix'" [myInputModel]="editedOffer.prefix" [form]="editOfferForm" class="form-control"></app-input-form-field>
            <app-checkbox-form-field [nameInput]="'enableLobbyBypass'" [myInputModel]="editedOffer.enableLobbyBypass" [checked]="editedOffer.enableLobbyBypass"
                  [textLabel]="'OfferFormComponent.EnableLobbyBypass'" (checkedEvent)="onCheckEnableLobbyByPass($event)" [form]="editOfferForm" class="form-control"></app-checkbox-form-field>
            <app-admin-infos-bloc *ngIf="editedOffer.enableLobbyBypass" class="follow-input">
                <div class="follow-input">
                    <app-checkbox-form-field [nameInput]="'filterTrustedDevicesOnPexipLocation'" [myInputModel]="editedOffer.filterTrustedDevicesOnPexipLocation" [checked]="editedOffer.filterTrustedDevicesOnPexipLocation" [textLabel]="'OfferFormComponent.FilterTrustedDevicesOnPexipLocation'" (checkedEvent)="onCheckFilterTrustedDevicesOnPexipLocation($event)" [form]="editOfferForm" class="form-control"></app-checkbox-form-field>
                    <app-input-form-field [typeInput]="'text'" [nameInput]="'location'" [textLabel]="'OfferFormComponent.Location'" [myInputModel]="editedOffer.location" [form]="editOfferForm" class="form-control follow-input" *ngIf="editedOffer.filterTrustedDevicesOnPexipLocation"></app-input-form-field>
                    <app-checkbox-form-field [nameInput]="'filterTrustedDevicesOnPexipRegistration'" [myInputModel]="editedOffer.filterTrustedDevicesOnPexipRegistration" [checked]="editedOffer.filterTrustedDevicesOnPexipRegistration" [textLabel]="'OfferFormComponent.FilterTrustedDevicesOnPexipRegistration'" (checkedEvent)="onCheckFilterTrustedDevicesOnPexipRegistration($event)" [form]="editOfferForm" class="form-control" [ngClass]="{'follow-input': !editedOffer.filterTrustedDevicesOnPexipLocation}"></app-checkbox-form-field>
                    <div class="dual-div">
                        <div>
                            {{"OfferFormComponent.FilterTrustedDevicesOnNetwork" | translate}} 
                        </div>
                        <div *ngIf="!addNewNetwork">
                            <a (click)="addNewNetwork = true; newNetwork = {ip: '', mask: 0}" class="pointer add-new-network-link">
                                <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> {{"OfferFormComponent.AddNetwork" | translate}}
                            </a>
                        </div>
                        <div *ngIf="addNewNetwork">
                            <a (click)="addNewNetwork = false; newNetwork = {ip: '', mask: 0}" class="pointer add-new-network-link">
                                <fa-icon [icon]="['fas', 'minus-circle']"></fa-icon> {{"OfferFormComponent.AddNetwork" | translate}}
                            </a>
                        </div>
                    </div>
                    <div *ngIf="addNewNetwork" class="need-top-margin">
                        <form #addNewNetworkForm="ngForm" name="addNewNetworkForm" id="addNewNetworkForm" (ngSubmit)="addNewNetworkForm.valid ? validAddNetwork(addNewNetworkForm.value) : null">
                            <div class="dual-div">
                                <div class="px-1">
                                    <app-input-form-field [typeInput]="'text'" [nameInput]="'ip'" [textLabel]="'OfferFormComponent.Ip'"
                                      [myInputModel]="newNetwork.ip" [form]="addNewNetworkForm" class="form-control" [required]="true"></app-input-form-field>
                                </div>
                                <div class="px-1 w-50">
                                    <app-input-form-field [typeInput]="'number'" [nameInput]="'mask'" [textLabel]="'OfferFormComponent.Mask'"
                                      [myInputModel]="newNetwork.mask" [form]="addNewNetworkForm" class="form-control" [minNumber]="0" [maxNumber]="32" [required]="true">
                                    </app-input-form-field>
                                </div>
                            </div>
                            <div class="dual-button-container">
                                <button class="btn btn-app app-secondary width-100" type="reset" (click)="cancelAddNetwork()">
                                    {{"Button.Cancel" | translate }}
                                </button>
                                <button class="btn btn-app app-primary width-100" type="submit">
                                    {{"Button.Add" | translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="netWorksList.length > 0" class="need-top-margin">
                        <div class="is-bold need-bottom-margin">{{"OfferFormComponent.Networks" | translate}} :</div>
                        <div class="dual-div" *ngFor="let network of netWorksList; let i = index;">
                            <div>{{network.ip}}/{{network.mask}}</div>
                            <div><a class="pointer remove-network-link" (click)="removeNetwork(network)"><fa-icon [icon]="['fas', 'trash-alt']"></fa-icon></a></div>
                        </div>
                    </div>
                </div>
            </app-admin-infos-bloc>
        </div>
        <div class="dual-button-container">
            <button class="btn btn-app app-secondary width-100" type="reset" (click)="cancel()">
                {{"Button.Cancel" | translate }}
            </button>
            <button class="btn btn-app app-primary width-100" type="submit">
                {{"Button.Valid" | translate }}
            </button>
        </div>
    </form>
</div>
