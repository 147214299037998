<ng-container *ngIf="(connectedUser$ | async) as connectedUser">
  <ng-container *ngIf="tenantId && (tenant$ | async) as tenant">
    <div class="row align-items-center p-3">
      <div class="col-auto">
        <h3 class="m-0">
          <span class="is-bold">{{"ObtpComponent.Title" | translate}}</span>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-admin-card>
          <mat-tab-group dynamicHeight animationDuration="500ms" (selectedTabChange)="switchTab($event)" [selectedIndex]="selectedTabIndex">
            <mat-tab label="{{'ObtpComponent.Configuration' | translate}}">
              <app-view-obtp [tenantId]="tenantId" [tenant]="tenant" [selectedTab]="selectedTabIndex"></app-view-obtp>
            </mat-tab>
            <mat-tab label="{{'ObtpComponent.History' | translate}}">
              <app-history-obtp [connectedUser]="connectedUser" [tenantId]="tenantId" [tenant]="tenant"></app-history-obtp>
            </mat-tab>
          </mat-tab-group>
        </app-admin-card>
      </div>
    </div>
  </ng-container>
</ng-container>