import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Tenant } from 'src/app/tenant/tenant.model';

import Debug from 'debug';
import { HistoryObtpService } from './history-obtp.service';
import { HistoryObtp } from '../obtp.model';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';
const debug = Debug('virtuvisio-administration:obtp-history-component');

@Component({
  selector: 'app-history-obtp',
  templateUrl: './history-obtp.component.html',
  styleUrls: ['./history-obtp.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class HistoryObtpComponent implements OnInit, AfterViewInit {

  @Input() tenantId: string;
  @Input() tenant: Tenant;
  @Input() connectedUser: ConnectedUser;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = [
    'time',
    'origin',
    'meetingRoomId',
    'success',
    'description'
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [1, 2, 3, 5, 10, 25, 50, 100];
  listLength: any;
  dataSource: MatTableDataSource<HistoryObtp>;
  search: string;
  sortField = '';
  orderDir = '';

  kloodDateFormat = KloodDateFormat;
  obtpHistorics: HistoryObtp[];
  expandedElement: HistoryObtp;
  loading: false;

  constructor(private obtpService: HistoryObtpService, private matPaginatorIntl: MatPaginatorIntl, private translate: TranslateService) { }

  ngOnInit(): void {
    this.initLabelMatPaginator();
    this.loadHistory(this.tenantId);
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  loadHistory(tenantId: string): void {
    //getMockHistory(tenantId) for having mock data
    //getHistory(tenantId) for api server data
    this.obtpService.getHistory(tenantId).subscribe({
      next: (result: HistoryObtp[]) => {
        debug(result);
        this.obtpHistorics = result;
        this.listLength = this.obtpHistorics.length;
        this.dataSource = new MatTableDataSource(this.obtpHistorics);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error: (error) => {
        debug(error);
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onSort(event: Sort): void {
    debug(event);
  }


  initLabelMatPaginator(): void {
    this.matPaginatorIntl.firstPageLabel = this.translate.instant('MatPaginator.FirstPageLabel');
    this.matPaginatorIntl.itemsPerPageLabel = this.translate.instant('MatPaginator.ItemsPerPageLabel');
    this.matPaginatorIntl.lastPageLabel = this.translate.instant('MatPaginator.LastPageLabel');
    this.matPaginatorIntl.nextPageLabel = this.translate.instant('MatPaginator.NextPageLabel');
    this.matPaginatorIntl.previousPageLabel = this.translate.instant('MatPaginator.PreviousPageLabel');
    const rangeTxtLabel = this.translate.instant('MatPaginator.RangeTxtLabel');
    this.matPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 ` + rangeTxtLabel + ` ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return (`${startIndex + 1} - ${endIndex} ` + rangeTxtLabel + ` ${length} (page n°: ${page + 1})`);
    };
  }

}
