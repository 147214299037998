import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from '../services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';

const debug = Debug('virtuvisio-administration:home-component');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  virtuvisioAppUrl: string;

  constructor(private sharedService: SharedService, private router: Router) {
    const newAppBaseUrl = window.location.origin.replace('admin.', 'app.');
    this.virtuvisioAppUrl = newAppBaseUrl + '/home';
  }

  ngOnInit(): void {
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe((connectedUser: ConnectedUser) => {
      if (connectedUser) {
        this.connectedUser = connectedUser;
        let link = [this.checkAcces(this.connectedUser)];
        this.router.navigate(link);
      }
    }));
  }

  checkAcces(connectedUser: ConnectedUser): string {
    if (connectedUser.isGranted(connectedUser.tenantId)) {
      if (connectedUser.hasRole("SUPER_ADMIN")) {
        return "/administration/" + connectedUser.tenantId + "/overview";
      } else if (connectedUser.isGranted(connectedUser.tenantId, "MANAGEMENT") && connectedUser.isGranted(connectedUser.tenantId, "MEETING_ROOMS") && connectedUser.isGranted(connectedUser.tenantId, "USERS")) {
        return "/administration/" + connectedUser.tenantId + "/overview";
      } else if (connectedUser.hasRole("ADMIN") || connectedUser.hasRole("MARKETING")) {
        const grantedList: string[] = connectedUser.grantedList(connectedUser.tenantId) ? connectedUser.grantedList(connectedUser.tenantId) as string[] : [];
        const firstList = grantedList[0];
        if (firstList === "USERS") {
          return "/administration/" + connectedUser.tenantId + "/users";
        } else if (firstList === "TRIALS") {
          return "/management/trials";
        } else if (firstList === "MEETING_ROOMS") {
          return "/administration/" + connectedUser.tenantId + "/meeting-rooms/configuration";
        } else if (firstList === "MEETINGS") {
          return "/administration/" + connectedUser.tenantId + "/meetings";
        } else if (firstList === "CONFERENCES") {
          return "/administration/" + connectedUser.tenantId + "/live-conferences";
        } else if (firstList === "BOOKINGS") {
          return "/administration/" + connectedUser.tenantId + "/bookings";
        } else if (firstList === "STATS") {
          return "/administration/" + connectedUser.tenantId + "/admin-stats";
        } else if (firstList === "CONTENT") {
          return "/administration/" + connectedUser.tenantId + "/mail/templates";
        } else if (firstList === "BUILDINGS") {
          return "/administration/" + connectedUser.tenantId + "/location";
        } else if (firstList === "MASTER") {
          return "/administration/" + connectedUser.tenantId + "/authorization";
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
