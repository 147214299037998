import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { LoginService } from 'src/app/login/login.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { AppConfigService } from 'src/app/services/config/app.config.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private sharedService: SharedService, private loginService: LoginService, private router: Router, private configUi: AppConfigService, private cookieService: CookieService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sharedService._isLogged().pipe(
      switchMap((logged: boolean) => {
        console.log("LOGGED : " + logged);
        if (!logged) {
          console.log(state.url);
          this.loginService.redirectLogin(state.url);
          return of(false);
        }
        return of(true);
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sharedService._isLogged().pipe(
      switchMap((logged: boolean) => {
        if (!logged) {
          console.log(state.url);
          this.loginService.redirectLogin(state.url);
          return of(false);
        }
        return of(true);
      })
    );
  }
}
