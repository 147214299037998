import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlGeneratorService {
  buildUrl(url?: string): string {
    const baseHref = $('base').attr('href') as any;
    if (url) {
      if (baseHref.substr(baseHref?.length - 1) === '/' && url.charAt(0) === '/') {
        return document.location.origin + baseHref.slice(0, -1) + url;
      } else if (baseHref.substr(baseHref?.length - 1) !== '/' || url.charAt(0) !== '/') {
        return document.location.origin + baseHref + url;
      } else {
        return document.location.origin + baseHref + url;
      }
    } else {
      return document.location.origin + baseHref;
    }
  }
}
