<div class="row align-items-center p-3">
	<ng-container *ngIf="!isRoute('/import')">
		<div class="col-auto">
			<h3 class="m-0">
				<span class="is-bold">{{"GroupsComponent.Title" | translate}}</span>
			</h3>
		</div>
		<div class="col">
			<button class="btn btn-app app-primary float-end" type="submit" [routerLink]="['./import']">
				<fa-icon [icon]="['fas', 'cloud-download-alt']" class="me-1"></fa-icon>{{"GroupsComponent.ImportNewGroups" | translate }}
			</button>
		</div>
	</ng-container>
	<ng-container *ngIf="isRoute('/import')">
		<div class="col-auto">
			<h3 class="m-0">
				<span class="is-bold">{{"GroupsComponent.ListOfImportableGroups" | translate}}</span>
			</h3>
		</div>
		<div class="col">
			<button class="btn btn-app app-secondary float-end" type="submit" [routerLink]="['../groups']">
				<fa-icon [icon]="['fas', 'arrow-left']" class="me-1"></fa-icon>{{"GroupsComponent.BackToGroups" | translate }}
			</button>
		</div>
	</ng-container>
</div>
<ng-container *ngIf="!isRoute('/import')">
	<div class="row">
		<div class="col" [ngClass]="{'col-12': !isRoute('/edit'), 'col-7': isRoute('/edit')}">
			<app-admin-card>
				<app-mat-table-custom *ngIf="displayedColumns && groupsService" [selectedRowItem]="'id'" [selectedRowId]="selectedGroup?.id" [loading]="reload" [hoverable]="false" [componentName]="'GroupsComponent'" [displayedColumns]="displayedColumns" [dataService]="groupsService" [defaultSort]="defaultSort" (dataError)="onDataError($event)" (actionClick)="onActionClick($event)"></app-mat-table-custom>
			</app-admin-card>
		</div>
		<div class="col-5" *ngIf="isRoute('/edit')">
			<router-outlet (deactivate)="onRouterDeactivate()"></router-outlet>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="isRoute('/import')">
	<router-outlet></router-outlet>
</ng-container>
<div class="modal fade custom-app-modal" id="modalDeleteGroup" role="dialog" aria-labelledby="modalDeleteGroupLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content flat">
			<div class="modal-header">
				<h3 class="modal-title text-center" id="modalDeleteGroupLabel">{{"GroupsComponent.Delete" |
				translate}}</h3>
			</div>
			<div class="modal-body">
				<div class="is-bold">{{"GroupsComponent.SureDeleteGroup" | translate}}</div>
				<div class="mb-3" *ngIf="selectedGroup">{{selectedGroup.name}}</div>
				<div class="dual-button-container">
					<button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal">
						{{"GroupsComponent.Cancel" | translate}}
					</button>
					<button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="confirmDeleteGroup()">
						{{"GroupsComponent.Confirm" | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>