import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ObtpConfig } from '../obtp.model';
import { ViewObtpService } from './view-obtp.service';
import Debug from 'debug';
const debug = Debug('virtuvisio-administration:obtp-view-component');

@Component({
  selector: 'app-view-obtp',
  templateUrl: './view-obtp.component.html',
  styleUrls: ['./view-obtp.component.scss'],
  providers: [ViewObtpService]
})
export class ViewObtpComponent implements OnInit, OnChanges {

  @Input() tenantId: string;
  @Input() tenant: Tenant;
  @Input() selectedTab: number

  obtpConf: ObtpConfig;
  editConfig = false;

  constructor(private obtpService: ViewObtpService) { }

  ngOnInit(): void {
    this.editConfig = false;
    this.obtpConf = {} as ObtpConfig;
    this.loadConfig(this.tenantId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.selectedTab && changes.selectedTab.currentValue) {
      if (+changes.selectedTab.currentValue !== 0) {
        this.editConfig = false;
      }
    }
  }

  loadConfig(tenantId: string): void {
    this.obtpService.getConfig(tenantId).subscribe({
      next: (result: ObtpConfig) => {
        this.obtpConf = result;
      },
      error: (error) => {
        debug(error);
      }
    });
  }

  onEditEvent(event: string): void {
    switch (event) {
      case 'save':
        this.loadConfig(this.tenantId);
        this.editConfig = false;
        break;
      case 'cancel':
        this.editConfig = false;
        break;
      default:
        break;
    }
  }

}
