import { SortDirection } from '@angular/material/sort';
import { IconName } from '@fortawesome/fontawesome-common-types';

export interface DisplayColumn {
  name: string;
  type: string;
  actions?: TableAction[];
}

export interface TableAction {
  action: string;
  icon: IconName;
  tooltip?: string;
}

export interface ActionEvent {
  action: TableAction;
  data: any;
}

export class PaginationInfo {
  skip: number;
  limit: number;
  listLength: number;
  pageSize: number;
  pageSizeOptions: number[];
  pageIndex: number;
  sort: string;
  order: SortDirection;
  suggest: string;
  from: Date;
  to: Date;
  displayedColumns: string[];

  constructor() {
    this.skip = 0;
    this.limit = 0;
    this.listLength = 0;
    this.pageSize = 10;
    this.pageSizeOptions = [5, 10, 25, 50, 100];
    this.pageIndex = 0;
    this.sort = '';
    this.order = 'desc';
    this.suggest = '';
    this.from = new Date();
    this.to = new Date();
    this.displayedColumns = [];
  }
}
