import { NavigationExtras } from '@angular/router';
import { IconName } from '@fortawesome/fontawesome-common-types';

export interface Controls {
  icon: IconName;
  controleType: 'LINK' | 'ACTION';
  doubleIcon?: boolean;
  secondIcon?: IconName;
  link?: {
    url: string;
    qParams?: NavigationExtras;
  };
  tooltip: string;
  action?: string;
  isSwitch: boolean;
  switchName?: string;
  switchStatus?: boolean;
}

export enum ControlType {
  Link = 'LINK',
  Action = 'ACTION',
}

export enum Actions {
  Edit = 'EDIT',
  Add = 'ADD',
  Delete = 'DELETE',
  Save = 'SAVE',
  Cancel = 'CANCEL',
  Back = 'BACK',
  Done = 'DONE',
  Renew = 'RENEW',
  Send = 'SEND',
  Reset = 'RESET',
  Password = 'RESET_PASSWORD',
}
