export class UserDataCustom {
    userDataTheme: string;
    userDataLang: string;
    youtubeStreamUrl: string;
    virtuvisioTheme: string;

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}
