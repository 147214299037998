<div *ngFor="let radioValue of listValues">
    <div class="icheck-custom iradio">
        <input
            name="{{nameInput}}"
            type="radio"
            id="{{radioValue.value}}"
            placeholder="{{radioValue.label | translate}}"
            value="{{radioValue.value}}"
            (change)="changeRadio($event)"
            [checked]="checked ? checked : radioValue.value === myInputModel"
            [disabled]="disabled"
        />
        <label for="{{radioValue.value}}"> {{radioValue.label|translate}} </label>
    </div>
</div>
