<div class="row" *ngIf="currentConnectedUser">
  <div class="col-auto my-auto pe-0">
    <div class="label-picker">{{labelFrom | translate}}</div>
  </div>
  <div class="col-auto ps-0">
    <app-date-picker-field [connectedUser]="currentConnectedUser" [nameInput]="labelFrom" [myInputModel]="from" [max]="to" (dateSelect)="onDateFromSelect($event)"></app-date-picker-field>
  </div>
  <div class="col-auto my-auto pe-0">
    <div class="label-picker">{{labelTo | translate}}</div>
  </div>
  <div class="col-auto ps-0">
    <app-date-picker-field [connectedUser]="currentConnectedUser" [nameInput]="labelTo" [myInputModel]="to" [min]="from" (dateSelect)="onDateToSelect($event)"></app-date-picker-field>
  </div>
</div>

