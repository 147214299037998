import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { Tenant } from 'src/app/tenant/tenant.model';

@Injectable()
export class UsersService {
  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService
  ) { }

  getTenant(tenantId: string): Observable<Tenant> {
    if (tenantId === '*') {
      const allTenants: Tenant = new Tenant({});
      allTenants.name = 'all tenants';
      allTenants.id = '*';
      return of(allTenants);
    } else {
      return this.optionService.getApiUrl(tenantId).pipe(
        switchMap((apiUri) => {
          return this.http.get<Tenant>(apiUri, {
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          });
        }),
        map((result) => new Tenant(result))
      );
    }
  }

  importCsvFile(tenantId: string, file: File): Observable<unknown> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http
          .post(apiUri + '/users/csv', file, {
            headers: this.optionService.getHeadersCsv(),
            observe: 'response',
            withCredentials: true,
          })
          .pipe(map((result) => result.body));
      })
    );
  }
}
