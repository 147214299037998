<ng-container *ngIf="connectedUser && (tenant$ | async) as tenant">
    <div *ngIf="connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
        <div class="p-3">
            <h3>
                <span class="is-bold">{{"WaitingConsentComponent.Title" | translate}}:</span>
                <span class="is-bold is-primary"> {{tenant.name | uppercase}}</span>
            </h3>
            <app-admin-bloc>
                <div class="p-5">
                    <app-spinner-custom [isWhite]="false"></app-spinner-custom>
                </div>
            </app-admin-bloc>
        </div>
    </div>
</ng-container>