import { Component } from '@angular/core';
import { Environment } from '../environment.model';
import { EnvironmentsService } from '../environments.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-environment',
  templateUrl: './add-environment.component.html',
  styleUrls: ['./add-environment.component.scss']
})
export class AddEnvironmentComponent {

  newEnvironment = new Environment({});
  finished = false;
  hasError = false;

  constructor(private route: ActivatedRoute, private router: Router, private environmentsService: EnvironmentsService, private notifService: NotifService) { }

  confirmAddEnvironment(addEnvironmentForm: NgForm): void {
    if (addEnvironmentForm.valid) {
      this.hasError = false;
      this.environmentsService.addEnvironment(addEnvironmentForm.value).subscribe({
        next: (result) => {
          this.finished = true;
          this.notifService.addSuccessNotif('AddEnvironmentComponent.SuccessAddEnvironment');
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        error: (error) => {
          this.finished = true;
          this.hasError = true;
          this.notifService.addErrorNotif('AddEnvironmentComponent.ErrorAddEnvironment', error);
        }
      })
    } else {
      this.hasError = true;
    }
  }

}
