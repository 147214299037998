import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { QueryParamsManager } from 'src/app/services/query-params-manager/query-params-manager';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';

@Component({
  selector: 'app-calendar-picker-double',
  templateUrl: './calendar-picker-double.component.html',
  styleUrls: ['./calendar-picker-double.component.scss'],
  providers: [QueryParamsManager]
})
export class CalendarPickerDoubleComponent implements OnInit, OnDestroy {

  @Input() labelFrom: string;
  @Input() labelTo: string;
  @Input() from: Date = this.datesManager.removeMonth(new Date(), 1);
  @Input() to: Date = new Date();
  sharedServiceSub: Subscription;
  queryParamsSub: Subscription;
  currentConnectedUser: ConnectedUser;

  constructor(private sharedService: SharedService, private route: ActivatedRoute, private datesManager: DatesManager, private queryParamsManager: QueryParamsManager) { }

  ngOnInit(): void {
    this.sharedServiceSub = this.sharedService.currentConnectedUser.subscribe((currentConnectedUser: ConnectedUser) => {
      this.currentConnectedUser = currentConnectedUser;
    });
    this.queryParamsSub = this.route.queryParams.subscribe(async (params) => {
      if (params.from && this.datesManager.isValidDate(params.from)) {
        this.from = new Date(params.from);
      }
      if (params.to && this.datesManager.isValidDate(params.to)) {
        this.to = new Date(params.to);
      }
      await this.onSetDates();
    });
  }

  ngOnDestroy(): void {
    this.sharedServiceSub.unsubscribe();
    this.queryParamsSub.unsubscribe();
  }

  async onDateFromSelect(date: Date): Promise<void> {
    this.from = date;
    await this.onSetDates();
  }

  async onDateToSelect(date: Date): Promise<void> {
    this.to = date;
    await this.onSetDates();
  }

  async onSetDates(): Promise<void> {
    await this.queryParamsManager.changeQueryParams({ from: new Date(this.from), to: new Date(this.to) });
  }

  changeCalendarClass(): void {
    document.querySelector(".cdk-overlay-pane")?.classList.add("cdk-overlay-pane-custom-matpicker");
  }
}
