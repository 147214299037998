<div class="bloc position-relative">
  <div class="bloc-header align-items-center" *ngIf="blocTitle || blocControls">
    <div class="bloc-title" *ngIf="blocTitle">{{blocTitle | translate:translateParams}}</div>
    <div class="control-bloc push">
      <div class="control">
        <app-toolbar [blocControls]="blocControls" (requestAction)="onRequestAction($event)"></app-toolbar>
      </div>
    </div>
  </div>
  <div class="bloc-body">
    <ng-content></ng-content>
  </div>
</div>