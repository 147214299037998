import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';

const debug = Debug('virtuvisio-administration:offer-management-component');

@Component({
  selector: 'app-offer-management',
  templateUrl: './offer-management.component.html',
  styleUrls: ['./offer-management.component.scss'],
})
export class OfferManagementComponent {
  connectedUser$: Observable<ConnectedUser>;
  nowDate = new Date();
  kloodDateFormat = KloodDateFormat;

  constructor(private sharedService: SharedService) {
    this.connectedUser$ = this.sharedService.currentConnectedUser;
  }

}
