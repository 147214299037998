<!-- <div class="loader-app">
    <img src="./assets/images/picto_white.png" alt="" />
    <div class="dot-pulse" id="dots">
        <p class="dot1"></p>
        <p class="dot2"></p>
        <p class="dot3"></p>
    </div>
</div> -->

<div class="loader-app">
    <img *ngIf="isWhite" src="./assets/images/picto_white.png" alt="" />
    <img *ngIf="!isWhite" src="./assets/images/arrow-primary.png" alt="" />
    <div class="dot-pulse" id="dots">
        <p class="dot1" [ngClass]="{white:isWhite, raspberry:!isWhite}"></p>
        <p class="dot2" [ngClass]="{white:isWhite, raspberry:!isWhite}"></p>
        <p class="dot3" [ngClass]="{white:isWhite, raspberry:!isWhite}"></p>
    </div>
</div>
