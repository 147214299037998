import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from "../services/http-encoder/custom-http-param-encoder.service";

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private http: HttpClient, private optionService: DataApiOptionService) { }

  getTopUser(tenantId: string, startDate: Date, endDate: Date): Observable<any> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() })
    params = params.set('startDate', startDate.toISOString())
    params = params.set('endDate', endDate.toISOString())
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http.get<any>(apiUri + '/stats/users', {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        // console.log(result);
        return result;
      }),
    );
  }

  getDistributionUses(tenantId: string, startDate: Date, endDate: Date): Observable<any> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() })
    params = params.set('startDate', startDate.toISOString())
    params = params.set('endDate', endDate.toISOString())
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http.get<any>(apiUri + '/stats/offers', {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        // console.log(result);
        return result;
      }),
    );
  }

  getUseRate(tenantId: string, startDate: Date, endDate: Date): Observable<any> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() })
    params = params.set('startDate', startDate.toISOString())
    params = params.set('endDate', endDate.toISOString())
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http.get<any>(apiUri + '/stats/meetings/graph', {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        // console.log(result);
        return result;
      }),
    );
  }

  getMeetings(tenantId: string, startDate: Date, endDate: Date, duration: string): Observable<any> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() })
    params = params.set('startDate', startDate.toISOString())
    params = params.set('endDate', endDate.toISOString())
    params = params.set('duration', duration)
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http.get<any>(apiUri + '/stats/meetings', {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        // console.log(result);
        return result;
      }),
    );
  }
}
