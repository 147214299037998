<div class="container-grid">
    <div class="grid">
        <div>
            <h3 class="text-center is-bold p-3">{{"GeozonesComponent.ContinentsGeozones" | translate }}</h3>
            <div *ngIf="continents" class="accordion stacked" id="accordionContinents">
                <div *ngFor="let continent of continents; let first=first;let last=last;" class="accordion-item">
                    <h2 class="accordion-header" id="{{ 'heading' + continent.id }}">
                        <button (click)="currentContinent=continent;getGeozones()" [attr.aria-controls]="'collapse-' + continent.id" [attr.data-bs-target]="'#collapse-' + continent.id"
                                aria-expanded="false" class="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                type="button">
                            <span *ngIf="currentConnectedUser && currentConnectedUser.lang === 'fr-FR'">{{continent.translations['fr-FR']}}</span>
                            <span *ngIf="currentConnectedUser && currentConnectedUser.lang !== 'fr-FR'">{{continent.translations['en-US']}}</span>
                        </button>
                    </h2>
                    <div [attr.aria-labelledby]="'heading' + continent.id" class="accordion-collapse collapse"
                         data-bs-parent="#accordionContinents" id="{{ 'collapse-' + continent.id }}">
                        <div class="accordion-body">
                            <div class="dual-button-container">
                                <button (click)="addNewGeozone(continent)" class="btn btn-app app-primary margin-bottom-20"
                                        data-bs-target="#modalOnAddGeozone" data-bs-toggle="modal"
                                        type="button">
                                    <fa-icon [icon]="['fas', 'plus-circle']" class="me-1"></fa-icon>
                                    {{"GeozonesComponent.AddGeozone" | translate }}
                                </button>
                                <button (click)="currentContinent=continent" class="btn btn-app app-secondary margin-bottom-20"
                                        data-bs-target="#modalOnEditContinent" data-bs-toggle="modal"
                                        type="button">
                                    <fa-icon [icon]="['fas', 'edit']" class="me-1"></fa-icon>
                                    {{"GeozonesComponent.EditContinent" | translate }}
                                </button>
                            </div>
                            <ng-container *ngIf="geozones">
                                <div *ngFor="let geozone of geozones" class="row align-items-center">
                                    <div class="col-auto">
                                        <img [src]="'assets/images/flags/code/'+geozone.code+'.png' | makeSrcUrl"
                                             class="width-40"/>
                                    </div>
                                    <div class="col-auto">
                                        <span *ngIf="currentConnectedUser.lang === 'fr-FR'">{{geozone.translations['fr-FR']}}</span>
                                        <span *ngIf="currentConnectedUser.lang !== 'fr-FR'">{{geozone.translations['en-US']}}</span>
                                    </div>
                                    <div class="col-auto">
                                        <span>{{geozone.phoneNumber}}</span>
                                    </div>
                                    <div class="col">
                                        <hr>
                                    </div>
                                    <div class="col-auto">
                                        <a (click)="currentGeozone=geozone;" class="pointer float-end"
                                           data-bs-target="#modalOnDeleteGeozone" data-bs-toggle="modal">
                                            <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                                        </a>
                                        <a (click)="currentGeozone=geozone;" class="pointer float-end me-2"
                                           data-bs-target="#modalOnEditGeozone" data-bs-toggle="modal">
                                            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="map">
            <img src="../../assets/images/map.png" alt="">
        </div>
        <div class="countries">
            <h3 class="text-center is-bold p-3">{{"GeozonesComponent.Countries" | translate }}</h3>
            <div>
                <app-admin-bloc class="countries-card">
                    <div *ngFor="let country of countries">
                        <div class="row align-items-center">
                            <div class="col">
                                <div class="row">
                                    <div class="col-6">
                                        <span class="is-bold">FR - </span><span
                                            *ngIf="!country.translations['fr-FR']">{{"GeozonesComponent.MissingTraduction" | translate}}</span>{{country.translations['fr-FR']}}
                                    </div>
                                    <div class="col-6">
                                        <span class="is-bold">EN - </span><span
                                            *ngIf="!country.translations['en-US']">{{"GeozonesComponent.MissingTraduction" | translate}}</span>{{country.translations['en-US']}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <a (click)="currentCountry=country" class="pointer" data-bs-target="#modalOnEditCountry"
                                   data-bs-toggle="modal">
                                    <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                                </a>
                            </div>
                        </div>
                        <hr class="my-1"/>
                    </div>
                </app-admin-bloc>
            </div>
        </div>
    </div>
</div>

<div aria-hidden="true" aria-labelledby="modalOnDeleteGeozoneLabel" class="modal fade custom-app-modal"
     id="modalOnDeleteGeozone" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content flat">
            <div class="modal-header">
                <h3 class="modal-title"
                    id="modalOnDeleteGeozoneLabel">{{"GeozonesComponent.Delete" | translate}}</h3>
            </div>
            <div class="modal-body">
                <div class="is-bold">{{"GeozonesComponent.SureDeleteGeozone" | translate}}</div>
                <div *ngIf="currentGeozone" class="mb-3">
                    <span *ngIf="currentConnectedUser.lang === 'fr-FR'">{{currentGeozone.translations['fr-FR']}}</span>
                    <span *ngIf="currentConnectedUser.lang !== 'fr-FR'">{{currentGeozone.translations['en-US']}}</span>
                </div>
                <div class="dual-button-container">
                    <button class="btn btn-sm btn-app app-secondary w-100" data-bs-dismiss="modal" type="submit">
                        {{"GeozonesComponent.Cancel" | translate}}
                    </button>
                    <button (click)="confirmDeleteGeozone()" class="btn btn-sm btn-app app-primary w-100" data-bs-dismiss="modal"
                            type="submit">
                        {{"GeozonesComponent.Confirm" | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div aria-hidden="true" aria-labelledby="modalOnAddGeozoneLabel" class="modal fade custom-app-modal" id="modalOnAddGeozone"
     role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content flat">
            <div class="modal-header">
                <h3 class="modal-title"
                    id="modalOnAddGeozoneLabel">{{"GeozonesComponent.AddGeozone" | translate}}</h3>
            </div>
            <div class="modal-body">
                <form #addGeozone="ngForm" class="mt-2" id="addGeozone" name="addGeozone">
                    <app-input-form-field [form]="addGeozone" [myInputModel]="newGeozone.code" [nameInput]="'code'"
                                          [required]="true" [textLabel]="'GeozonesComponent.Code'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <app-input-form-field [form]="addGeozone" [myInputModel]="newGeozone.phoneNumber"
                                          [nameInput]="'phoneNumber'"
                                          [required]="true" [textLabel]="'GeozonesComponent.PhoneNumber'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <app-input-form-field [form]="addGeozone" [myInputModel]="newGeozone.translations['en-US']"
                                          [nameInput]="'en'"
                                          [required]="true" [textLabel]="'GeozonesComponent.EnTraduction'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <app-input-form-field [form]="addGeozone" [myInputModel]="newGeozone.translations['fr-FR']"
                                          [nameInput]="'fr'"
                                          [required]="true" [textLabel]="'GeozonesComponent.FrTraduction'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <div class="dual-button-container">
                        <button class="btn btn-app app-secondary w-100 margin-bottom-20" data-bs-dismiss="modal"
                                type="reset">{{"GeozonesComponent.Cancel" | translate }}</button>
                        <button (click)="confirmAddGeozone(addGeozone)" class="btn btn-app app-primary w-100 margin-bottom-20"
                                type="submit">{{"GeozonesComponent.Add" | translate}}</button>
                    </div>
                    <div *ngIf="addGeozone.submitted && !addGeozone.valid"
                         class="is-third fields-error">{{'GeozonesComponent.SomeFields' | translate}}</div>
                </form>
            </div>
        </div>
    </div>
</div>

<div aria-hidden="true" aria-labelledby="modalOnEditContinentLabel" class="modal fade custom-app-modal"
     id="modalOnEditContinent" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content flat">
            <div class="modal-header">
                <h3 class="modal-title"
                    id="modalOnEditContinentLabel">{{"GeozonesComponent.EditContinent" | translate}}</h3>
            </div>
            <div *ngIf="currentContinent" class="modal-body">
                <form #editContinent="ngForm" class="mt-2" id="editContinent" name="editContinent">
                    <app-input-form-field [form]="editContinent" [myInputModel]="currentContinent.code"
                                          [nameInput]="'code'" [required]="true"
                                          [textLabel]="'GeozonesComponent.Code'" [typeInput]="'text'"
                                          class="form-control"></app-input-form-field>
                    <app-input-form-field [form]="editContinent" [myInputModel]="currentContinent.translations['en-US']"
                                          [nameInput]="'en'"
                                          [required]="true" [textLabel]="'GeozonesComponent.EnTraduction'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <app-input-form-field [form]="editContinent" [myInputModel]="currentContinent.translations['fr-FR']"
                                          [nameInput]="'fr'"
                                          [required]="true" [textLabel]="'GeozonesComponent.FrTraduction'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <div class="dual-button-container">
                        <button class="btn btn-app app-secondary w-100 margin-bottom-20" data-bs-dismiss="modal"
                                type="button">{{"GeozonesComponent.Cancel" | translate }}</button>
                        <button (click)="confirmEditContinent(editContinent)" class="btn btn-app app-primary w-100 margin-bottom-20"
                                type="submit">{{"GeozonesComponent.Edit" | translate}}</button>
                    </div>
                    <div *ngIf="addGeozone.submitted && !addGeozone.valid"
                         class="is-third fields-error">{{'GeozonesComponent.SomeFields' | translate}}</div>
                </form>
            </div>
        </div>
    </div>
</div>

<div aria-hidden="true" aria-labelledby="modalOnEditGeozoneLabel" class="modal fade custom-app-modal"
     id="modalOnEditGeozone" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content flat">
            <div class="modal-header">
                <h3 class="modal-title"
                    id="modalOnEditGeozoneLabel">{{"GeozonesComponent.EditGeozone" | translate}}</h3>
            </div>
            <div *ngIf="currentGeozone" class="modal-body">
                <form #editGeozone="ngForm" class="mt-2" id="editGeozone" name="editGeozone">
                    <app-input-form-field [form]="editGeozone" [myInputModel]="currentGeozone.code" [nameInput]="'code'"
                                          [required]="true" [textLabel]="'GeozonesComponent.Code'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <app-input-form-field [form]="editGeozone" [myInputModel]="currentGeozone.phoneNumber"
                                          [nameInput]="'phoneNumber'"
                                          [required]="true" [textLabel]="'GeozonesComponent.PhoneNumber'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <app-input-form-field [form]="editGeozone" [myInputModel]="currentGeozone.translations['en-US']"
                                          [nameInput]="'en'"
                                          [required]="true" [textLabel]="'GeozonesComponent.EnTraduction'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <app-input-form-field [form]="editGeozone" [myInputModel]="currentGeozone.translations['fr-FR']"
                                          [nameInput]="'fr'"
                                          [required]="true" [textLabel]="'GeozonesComponent.FrTraduction'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <div class="dual-button-container">
                        <button class="btn btn-app app-secondary w-100 margin-bottom-20" data-bs-dismiss="modal"
                                type="reset">{{"GeozonesComponent.Cancel" | translate }}</button>
                        <button (click)="confirmEditGeozone(editGeozone)" class="btn btn-app app-primary w-100 margin-bottom-20"
                                type="submit">{{"GeozonesComponent.Edit" | translate}}</button>
                    </div>
                    <div *ngIf="addGeozone.submitted && !addGeozone.valid"
                         class="is-third fields-error">{{'GeozonesComponent.SomeFields' | translate}}</div>
                </form>
            </div>
        </div>
    </div>
</div>

<div aria-hidden="true" aria-labelledby="modalOnEditCountryLabel" class="modal fade custom-app-modal"
     id="modalOnEditCountry" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div *ngIf="currentCountry" class="modal-content flat">
            <div class="modal-header">
                <h3 class="modal-title"
                    id="modalOnEditCountryLabel">{{"GeozonesComponent.EditCountry" | translate}}
                    - {{currentCountry.code}}</h3>
            </div>
            <div class="modal-body">
                <form #editCountry="ngForm" class="mt-2" id="editCountry" name="editCountry">
                    <app-input-form-field [form]="editCountry" [myInputModel]="currentCountry.translations['en-US']"
                                          [nameInput]="'en'"
                                          [required]="true" [textLabel]="'GeozonesComponent.EnTraduction'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <app-input-form-field [form]="editCountry" [myInputModel]="currentCountry.translations['fr-FR']"
                                          [nameInput]="'fr'"
                                          [required]="true" [textLabel]="'GeozonesComponent.FrTraduction'"
                                          [typeInput]="'text'" class="form-control"></app-input-form-field>
                    <div class="dual-button-container">
                        <button class="btn btn-app app-secondary w-100 margin-bottom-20" data-bs-dismiss="modal"
                                type="reset">{{"GeozonesComponent.Cancel" | translate }}</button>
                        <button (click)="confirmEditCountry(editCountry)" class="btn btn-app app-primary w-100 margin-bottom-20"
                                type="submit">{{"GeozonesComponent.Edit" | translate}}</button>
                    </div>
                    <div *ngIf="editCountry.submitted && !editCountry.valid"
                         class="is-third fields-error">{{'GeozonesComponent.SomeFields' | translate}}</div>
                </form>
            </div>
        </div>
    </div>
</div>
