import { Component, Input, OnInit } from "@angular/core";
import { ConnectedUser } from "../users/user-connected/user.connected.model";

@Component({
    selector: "app-context",
    template: `
    <div *ngFor="let context of contexts">
        {{ context.name }} : {{ context | translateItem }}<span *ngIf="context.format"> (Format : {{ context.format }})</span>
    </div>
`,
})
export class ContextComponent implements OnInit {
    @Input() schema: any;
    @Input() currentUser: ConnectedUser;

    contexts: any[] = [];

    ngOnInit(): void {
        this.getContext(this.schema);
    }

    getContext(properties: any, key?: string): void {
        for (const c in properties) {
            if (properties[c].type === "object") {
                let subKey;
                if (key) {
                    subKey = key + "." + c;
                } else {
                    subKey = "." + c;
                }
                this.getContext(properties[c].properties, subKey);
                subKey = null;
            } else if (properties[c].type === "array") {
                let name;
                if (key) {
                    name = key + "." + c;
                } else {
                    name = "." + c;
                }
                if (properties[c].items) {
                    properties[c].translations = {
                        "fr-FR": "Tableau de " + properties[c].items.type,
                        "en-US": "Array of " + properties[c].items.type,
                    };
                } else {
                    properties[c].translations = {
                        "fr-FR": "Pas de description",
                        "en-US": "No description",
                    };
                }
                const context = {
                    name: name,
                    translations: properties[c].translations,
                };
                this.contexts.push(context);
            } else {
                let name;
                if (key) {
                    name = key + "." + c;
                } else {
                    name = "." + c;
                }
                if (!properties[c].translations) {
                    properties[c].translations = {
                        "fr-FR": "Pas de description",
                        "en-US": "No description",
                    };
                }
                const context = {
                    name: name,
                    translations: properties[c].translations,
                    format: properties[c].format,
                };
                this.contexts.push(context);
            }
        }
    }
}
