import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Debug from 'debug';
const debug = Debug('virtuvisio-administration:GlobalErrorHandler');

@Injectable()
export class GlobalErrorHandlerService {
  environment = environment;

  constructor(
    // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
    private injector: Injector
  ) { }

  handleError(error: HttpErrorResponse): void {
    const router = this.injector.get(Router);

    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
      } else {
        // Handle Http Error (error.status === 403, 404...)
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      if (environment.production) {
        console.error('client side :', error);
        router.navigate(['/errors'], { queryParams: { clientSide: error } });
        // TODO
        // ADD a way to log this error on server side
        //
      }
      // router.navigate(['/errors'], { queryParams: {clientSide: error} })
    }
    // Log the error anyway
    if (!environment.production) {
      if (error.status) {
        console.error('Bad: ', error.status);
      }
      console.error('It happens: ', error);
    } else {
      debug('Bad : ', error.status);
      debug('It happens : %O', error);
    }
  }
}
