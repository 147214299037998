export const mockedTopUserData: TopUser[] = [
  {
    "user": {
      "email": "maxime.marie@dev.kross.io",
      "id": "a372dcab-e7ad-4a86-8d72-32e28bccf891",
      "lastName": "Marie",
      "firstName": "Maxime"
    }, "meetingsCount": 170, "meetingsTotalDuration": 92198
  }, {
    "user": {
      "email": "nicolas.debeaupte@dev.kross.io",
      "id": "f8080434-a100-40aa-b496-e6a1ce5fa2d5",
      "lastName": "Debeaupte",
      "firstName": "Nicolas"
    }, "meetingsCount": 185, "meetingsTotalDuration": 52384
  }, {
    "user": {
      "email": "Robert@dev.kross.io",
      "id": "ab7c0f8d-5905-4fad-baf9-e3bcc3a7a405",
      "lastName": "",
      "firstName": ""
    }, "meetingsCount": 1, "meetingsTotalDuration": 20
  }, {
    "user": {
      "email": "antoine.loyer@dev.kross.io",
      "id": "c02a19b9-d572-494f-bdd0-4a6a9428f0bf",
      "lastName": "LOYER",
      "firstName": "Antoine"
    }, "meetingsCount": 369, "meetingsTotalDuration": 58072
  }, {
    "user": {
      "email": "John@dev.kross.io",
      "id": "d9d751f3-1c79-494e-9736-2e08a149c186",
      "lastName": "klood",
      "firstName": "John"
    }, "meetingsCount": 1, "meetingsTotalDuration": 475
  }, {
    "user": { "email": "", "id": "0db723c2-50e7-423c-ab94-867ef38d8646", "lastName": "", "firstName": "" },
    "meetingsCount": 4,
    "meetingsTotalDuration": 678
  }, {
    "user": {
      "email": "nbertoni@dev.kross.io",
      "id": "3945a0af-5015-43fc-8a71-3cd34b0029d5",
      "lastName": "BERTONI",
      "firstName": "Nicolas"
    }, "meetingsCount": 2, "meetingsTotalDuration": 177
  }, {
    "user": { "email": "", "id": "dc57ef72-59dc-483a-ab23-e67753172e1c", "lastName": "", "firstName": "" },
    "meetingsCount": 3,
    "meetingsTotalDuration": 495
  }, {
    "user": { "email": "", "id": "b8492cf1-4a9b-4354-8a1e-86ad75c9dd0a", "lastName": "", "firstName": "" },
    "meetingsCount": 52,
    "meetingsTotalDuration": 23931
  }, {
    "user": {
      "email": "geoffrey.ragot@dev.kross.io",
      "id": "41dd5c19-d924-427a-a623-a7f30df20649",
      "lastName": "Ragot",
      "firstName": "Geoffrey"
    }, "meetingsCount": 17, "meetingsTotalDuration": 13631
  }, {
    "user": {
      "email": "aurelien.delasalle@dev.kross.io",
      "id": "7b3327fa-1b53-41b2-9d15-409d2f63987f",
      "lastName": "DELASALLE",
      "firstName": "Aurélien"
    }, "meetingsCount": 2416, "meetingsTotalDuration": 742997
  }]

export const mockedDistributionUsesData: DistributionUses = { "BUSINESS": 1, "ONE": 5, "PRO": 6 }

export const mockedUseRateData: UseRate = {
  "startDate": "2021-10-20T22:00:00Z",
  "stopDate": "2021-10-28T21:59:59Z",
  "statsByOffer": {
    "GATEWAY": { "total": 14, "maxConcurrent": 4 },
    "ONE": { "total": 22, "maxConcurrent": 11 },
    "PRO": { "total": 33, "maxConcurrent": 22 }
  },
  "graphPoints": [{
    "time": "2021-10-20T22:00:00Z",
    "data": { "GATEWAY": 3, "ONE": 4, "PRO": 5 }
  }, {
    "time": "2021-10-21T22:00:00Z",
    "data": { "GATEWAY": 6, "ONE": 8, "PRO": 3 }
  }, {
    "time": "2021-10-22T22:00:00Z",
    "data": { "GATEWAY": 43, "ONE": 6, "PRO": 8 }
  }, {
    "time": "2021-10-23T22:00:00Z",
    "data": { "GATEWAY": 56, "ONE": 67, "PRO": 8 }
  }, {
    "time": "2021-10-24T22:00:00Z",
    "data": { "GATEWAY": 112, "ONE": 56, "PRO": 678 }
  }, {
    "time": "2021-10-25T22:00:00Z",
    "data": { "GATEWAY": 34, "ONE": 567, "PRO": 56 }
  }, {
    "time": "2021-10-26T22:00:00Z",
    "data": { "GATEWAY": 234, "ONE": 567, "PRO": 789 }
  }, {
    "time": "2021-10-27T22:00:00Z",
    "data": { "GATEWAY": 19, "ONE": 27, "PRO": 456 }
  }]
}

export interface ScatterUsageRate {
  gateway: ScatterUsageRateOffer[],
  one: ScatterUsageRateOffer[],
  pro: ScatterUsageRateOffer[]
}

export interface ScatterUsageRateOffer {
  x: string,
  y: number
}


export interface UseRate {
  startDate: string,
  stopDate: string,
  statsByOffer: {
    GATEWAY: { total: number, maxConcurrent: number },
    ONE: { total: number, maxConcurrent: number },
    PRO: { total: number, maxConcurrent: number }
  },
  graphPoints: GraphPoints[]

}

interface GraphPoints {
  time: string,
  data: {
    GATEWAY: number,
    ONE: number,
    PRO: number
  }
}

export const mockedMeetingsData: Meetings[] = [
  {
    "date": "2021-10-17T22:00:00Z",
    "totalCount": 0,
    "totalParticipantCount": 0,
    "totalDuration": 0
  }, {
    "date": "2021-10-18T22:00:00Z",
    "totalCount": 1,
    "totalParticipantCount": 3,
    "totalDuration": 630
  }, {
    "date": "2021-10-19T22:00:00Z",
    "totalCount": 0,
    "totalParticipantCount": 0,
    "totalDuration": 0
  }, {
    "date": "2021-10-20T22:00:00Z",
    "totalCount": 0,
    "totalParticipantCount": 0,
    "totalDuration": 0
  }, {
    "date": "2021-10-21T22:00:00Z",
    "totalCount": 7,
    "totalParticipantCount": 7,
    "totalDuration": 2506
  }, {
    "date": "2021-10-22T22:00:00Z",
    "totalCount": 4,
    "totalParticipantCount": 15,
    "totalDuration": 2506
  }, {
    "date": "2021-10-23T22:00:00Z",
    "totalCount": 0,
    "totalParticipantCount": 0,
    "totalDuration": 0
  }]

export interface Meetings {
  date: string,
  totalCount: number,
  totalParticipantCount: number,
  totalDuration: number
}

export interface MeetingsData {
  x: string | null,
  meetings: number,
  participants: number
}

export interface DataTopUserMeeting {
  x: string,
  y: number
}

export interface TopUser {
  user: {
    email: string,
    id: string,
    lastName: string,
    firstName: string
  },
  meetingsCount: number,
  meetingsTotalDuration: number
}

export interface DistributionUses {
  BUSINESS: number,
  ONE: number,
  PRO: number
}

export interface DataDistributionUses {
  id: string,
  nested: {
    value: number
  }
}

export interface DataUseRate {
  x: string,
  total: number,
  maxConcurrent: number
}

export enum Offer {
  one = 'one',
  pro = 'pro',
  gateway = 'gateway'
}
