<div class="row align-items-center p-3">
  <div class="col-auto">
      <h3 class="m-0">
        <span class="is-bold">{{"HistoryMeetings.HistoryMeetings" | translate}}</span>
      </h3>
  </div>
  <div class="col">
    <button class="btn btn-app app-primary float-end me-2" type="submit" (click)="exportHistoryMeetings()">
      <fa-icon [icon]="['fas', 'file-csv']" class="me-2"></fa-icon>{{"HistoryMeetings.ExportHistoryMeetings" | translate }}
    </button>
  </div>
</div>
<app-admin-card>
  <div class="row" *ngIf="!selectedHistoryMeeting">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col-12">              
          <app-calendar-picker-double [labelFrom]="'HistoryMeetings.StartDate'" [labelTo]="'HistoryMeetings.EndDate'"></app-calendar-picker-double>
        </div>
      </div>
      <app-mat-table-custom *ngIf="displayedColumns && historyMeetingsService" [componentName]="'HistoryMeetings'" [displayedColumns]="displayedColumns" [dataService]="historyMeetingsService" [defaultSort]="defaultSort" (dataError)="onDataError($event)" (rowClick)="onRowClick($event)"></app-mat-table-custom>
    </div>
  </div>
  <div *ngIf="selectedHistoryMeeting && currentConnectedUser">
    <app-details-history-meetings [currentConnectedUser]="currentConnectedUser" [selectedHistoryMeeting]="selectedHistoryMeeting" (closeDetailsEvent)="onCloseDetailsEvent()"></app-details-history-meetings>
  </div>
</app-admin-card>
