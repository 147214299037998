import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first, Subscription } from 'rxjs';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { QueryParamsManager } from 'src/app/services/query-params-manager/query-params-manager';
import { PaginationInfo } from 'src/app/commons/mat-table-custom/mat-table-custom.model';
import { UsersListService } from 'src/app/users/users-list/users-list.service';
import { User, UserRoles } from 'src/app/users/user.model';
import { Tenant } from 'src/app/tenant/tenant.model';
import { TenantService } from 'src/app/tenant/tenant.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { SharedService } from 'src/app/services/shared/shared.service';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { AccessibleService } from 'src/app/accessible/accessible.service';
import { Accessible } from 'src/app/accessible/accessible.model';
import { UserEditService } from 'src/app/users/user-edit/user-edit.service';

@Component({
  selector: 'app-authorizations',
  templateUrl: './authorizations.component.html',
  styleUrls: ['./authorizations.component.scss', '../commons/mat-table-custom/mat-table-custom.component.scss'],
  providers: [QueryParamsManager, TenantService, AccessibleService, UsersListService, UserEditService]
})
export class AuthorizationsComponent implements OnInit, OnDestroy {
  defaultSort = "email";
  loading = false;
  dataSource: MatTableDataSource<User>;
  paginationInfo: PaginationInfo | any;
  queryParamSub: Subscription;
  paginatorReady = false;
  onInitSubscriptions: Subscription[] = [];
  tenants: Tenant[] = [];
  currentTenantId: string;
  currentConnectedUser: ConnectedUser;
  targetTenant: string;
  userRoles = UserRoles;
  isTenantSelectorLocked = true;
  constructor(
    private sharedService: SharedService,
    private tenantService: TenantService,
    private usersListService: UsersListService,
    private translate: TranslateService,
    private matPaginatorIntl: MatPaginatorIntl,
    private route: ActivatedRoute,
    private queryParamsManager: QueryParamsManager,
    private datesManager: DatesManager,
    private notifService: NotifService,
    private accessibleService: AccessibleService,
    private userEditService: UserEditService
  ) { }

  ngOnInit(): void {
    this.paginationInfo = new PaginationInfo();
    this.paginationInfo.sort = this.defaultSort;
    this.paginationInfo.displayedColumns = ["email", "role", "acls"];
    this.initLabelMatPaginator();
    this.onInitSubscriptions.push(this.route.queryParams.subscribe((params) => {
      for (let q in params) {
        if (q in this.paginationInfo) {
          if (q === "from" || q === "to") {
            if (this.datesManager.isValidDate(params[q])) {
              this.paginationInfo[q] = new Date(params[q]);
            }
          } else {
            this.paginationInfo[<keyof PaginationInfo>q] = params[q];
          }
        }
      }
      this.getHeadUsers();
    }));
    if (this.route.parent) {
      this.onInitSubscriptions.push(this.route.parent.params.subscribe((params) => {
        if (params.tenantId) {
          this.currentTenantId = params.tenantId;
          this.getTenants();
        }
      }));
    }
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe(user => {
      this.currentConnectedUser = user;
      if (!this.currentConnectedUser.hasRole("SUPER_ADMIN")) {
        this.userRoles = this.userRoles.filter((role) => {
          return (role.label !== "SUPER_ADMIN" && role.label !== "MARKETING");
        });
      }
    }));
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }

  getTenants(): void {
    this.onInitSubscriptions.push(this.tenantService.getTenants().pipe(first()).subscribe((tenants: Tenant[]) => {
      this.tenants = tenants;
      if (this.tenants?.length > 1) {
        const allTenants: Tenant = new Tenant({});
        allTenants.name = this.translate.instant("all tenants");
        allTenants.id = "*";
        this.tenants.unshift(allTenants);
      }
      if (this.currentTenantId) {
        this.targetTenant = this.currentTenantId;
      } else {
        this.targetTenant = this.currentConnectedUser.tenantId;
      }
    }));
  }

  onSelectTenant(tenant: any): void {
    this.targetTenant = tenant.id;
    this.getDataUsers();
  }

  getHeadUsers(): void {
    this.loading = true;
    this.onInitSubscriptions.push(this.usersListService.getHead(this.paginationInfo).pipe(first()).subscribe({
      next: (result: number) => {
        this.loading = false;
        this.paginationInfo.listLength = result;
        this.paginationInfo.limit = this.paginationInfo.pageSize;
        if (this.paginationInfo.limit * this.paginationInfo.pageIndex > this.paginationInfo.listLength) {
          this.paginationInfo.skip = 0;
          this.paginationInfo.pageIndex = 0;
        }
        this.getDataUsers();
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
      }
    }));
  }

  getDataUsers(): void {
    this.loading = true;
    this.onInitSubscriptions.push(this.usersListService.getData(this.paginationInfo).pipe(first()).subscribe({
      next: (result: User[]) => {
        this.loading = false;
        this.dataSource = new MatTableDataSource(result);
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
      }
    }));
  }

  async onChangePagination(event: PageEvent): Promise<void> {
    this.paginationInfo.pageSize = this.paginationInfo.limit = event.pageSize;
    this.paginationInfo.pageIndex = event.pageIndex;
    this.paginationInfo.skip = event.pageSize * event.pageIndex;
    await this.updateQueryParams();
  }

  async onSuggest(suggest: string): Promise<void> {
    this.paginationInfo.suggest = suggest;
    this.paginationInfo.pageIndex = 0;
    this.paginationInfo.skip = 0;
    await this.updateQueryParams();
  }

  async onSort(event: Sort): Promise<void> {
    this.paginationInfo.sort = "email";
    this.paginationInfo.order = "";
    if (event.direction) {
      this.paginationInfo.sort = event.active;
      this.paginationInfo.order = event.direction;
    }
    await this.updateQueryParams();
  }

  async updateQueryParams(): Promise<void> {
    await this.queryParamsManager.changeQueryParams({
      pageSize: this.paginationInfo.pageSize,
      pageIndex: this.paginationInfo.pageIndex,
      skip: this.paginationInfo.skip,
      limit: this.paginationInfo.limit,
      order: this.paginationInfo.order,
      sort: this.paginationInfo.sort,
      suggest: this.paginationInfo.suggest,
    });
  }

  switchAcl(event: boolean, user: User, targetAcl: string, acl: string): void {
    if (!targetAcl) {
      event = !event;
      this.notifService.addErrorNotif("AuthorizationsComponent.ErrorSelectTenant");
    } else {
      if (event) {
        this.accessibleService.save(user.id, targetAcl, acl).subscribe({
          next: () => {
            this.notifService.addSuccessNotif("AuthorizationsComponent.UserAclsEdited");
            if (!user.access) {
              user.access = new Accessible({});
              user.access[targetAcl] = [];
            }
            if (!user.access[targetAcl]) {
              user.access[targetAcl] = [];
            }
            user.access[targetAcl].push(acl);
          },
          error: (error) => {
            this.notifService.addErrorNotif("AuthorizationsComponent.ErrorUserAclsEdit", error);
            event = !event;
          }
        });
      } else {
        this.accessibleService.delete(user.id, targetAcl, acl).subscribe({
          next: () => {
            this.notifService.addSuccessNotif("AuthorizationsComponent.UserAclsEdited");
            user.access[targetAcl].splice(user.access[targetAcl].indexOf(acl), 1);
          },
          error: (error) => {
            this.notifService.addErrorNotif("AuthorizationsComponent.ErrorUserAclsEdit", error);
            event = !event;
          }
        });
      }
    }
  }

  changeRole(user: User): void {
    this.userEditService.save(user.id, user).subscribe({
      next: () => {
        this.notifService.addSuccessNotif("AuthorizationsComponent.RoleEdited");
        this.getHeadUsers();
      },
      error: (error) => {
        this.notifService.addErrorNotif("AuthorizationsComponent.ErrorEditRole", error);
      }
    });
  }

  isUserDisabled(user: User): boolean {
    if (user.id === this.currentConnectedUser.id || user.disabled) {
      return true;
    }
    return false;
  }

  isUserChecked(user: User, role: string): boolean {
    if (user.access && user.isSpecificGranted(this.targetTenant, role)) {
      return true;
    }
    return false;
  }

  async initLabelMatPaginator(): Promise<void> {
    await setTimeout(async () => {
      this.matPaginatorIntl.firstPageLabel = await this.translate.instant("MatPaginator.FirstPageLabel");
      this.matPaginatorIntl.itemsPerPageLabel = await this.translate.instant("MatPaginator.ItemsPerPageLabel");
      this.matPaginatorIntl.lastPageLabel = await this.translate.instant("MatPaginator.LastPageLabel");
      this.matPaginatorIntl.nextPageLabel = await this.translate.instant("MatPaginator.NextPageLabel");
      this.matPaginatorIntl.previousPageLabel = await this.translate.instant("MatPaginator.PreviousPageLabel");
      const rangeTxtLabel = await this.translate.instant("MatPaginator.RangeTxtLabel");
      this.matPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
          return `0 ` + rangeTxtLabel + ` ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ` + rangeTxtLabel + ` ${length} (page n°: ${page + 1})`;
      };
      this.paginatorReady = true;
    })
  }
}
