export class Environment {
  // need for new
  host: string;
  pexipDomain: string;
  public: boolean;
  // add by broker
  id: string;
  createdAt: Date;
  deleted: boolean;
  deletedAt: Date;
  remoteId: string;
  updatedAt: Date;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  getId(): string {
    return this.id;
  }
}
