import { Component, OnInit, OnDestroy } from '@angular/core';
import { GroupsService } from './groups.service';
import { DisplayColumn, ActionEvent } from 'src/app/commons/mat-table-custom/mat-table-custom.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Group } from 'src/app/groups/group.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { Router, RouterEvent, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  providers: [GroupsService]
})
export class GroupsComponent implements OnInit, OnDestroy {

  displayedColumns: DisplayColumn[] = [];
  defaultSort = "name";
  selectedGroup: Group;
  reload = false;
  onInitSubscriptions: Subscription[] = [];
  routerEvent: RouterEvent;
  constructor(public groupsService: GroupsService, private notifService: NotifService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(this.router.events.subscribe(event => {
      this.routerEvent = event as RouterEvent;
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/groups')) {
          this.reloadMatTable();
        }
      }
    }))
    this.displayedColumns = [
      { name: "name", type: "any" },
      { name: "autoSync", type: "any" },
      { name: "action", type: "action", actions: [{ action: "autoSync", icon: "history", tooltip: "GroupsComponent.ActivateAutoSync" }, { action: "edit", icon: "edit", tooltip: "GroupsComponent.Edit" }, { action: "delete", icon: "trash-alt", tooltip: "GroupsComponent.Delete" }] }
    ];
    if (this.route.firstChild) {
      this.onInitSubscriptions.push(this.route.firstChild.params.subscribe(params => {
        if (params['groupId']) {
          this.selectedGroup = new Group({ id: params['groupId'] });
        }
      }));
    }
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  onDataError(error: HttpErrorResponse): void {
    console.log(error);
  }

  onActionClick(event: ActionEvent): void {
    this.selectedGroup = event.data;
    switch (event.action.action) {
      case 'autoSync': {
        this.activateAutoSync();
        break;
      }
      case 'edit': {
        this.router.navigate(['./edit/' + this.selectedGroup.id], { relativeTo: this.route });
        break;
      }
      case 'delete': {
        $('#modalDeleteGroup').modal('show');
        break;
      }
      default: {
        break;
      }
    }
  }

  confirmDeleteGroup(): void {
    this.groupsService.delete(this.selectedGroup).subscribe({
      next: () => {
        $('#modalDeleteGroup').modal('hide');
        this.reloadMatTable();
        this.notifService.addSuccessNotif("GroupsComponent.SuccesDeleteGroup");
      },
      error: (error) => {
        this.reloadMatTable();
        this.notifService.addErrorNotif("GroupsComponent.ErrorWhileDeletingGroup", error);
      }
    })
  }

  activateAutoSync(): void {
    this.selectedGroup.autoSync = !this.selectedGroup?.autoSync;
    this.groupsService.save(this.selectedGroup).subscribe({
      next: (group) => {
        if (this.selectedGroup.autoSync) {
          this.notifService.addSuccessNotif("GroupsComponent.SuccessAutoSyncOn");
        } else {
          this.notifService.addSuccessNotif("GroupsComponent.SuccessAutoSyncOff");
        }
      },
      error: (error) => {
        this.notifService.addErrorNotif("GroupsComponent.ErrorWhileActivatingAutoSync", error);
        this.reloadMatTable();
      }
    })
  }

  isRoute(param: string): boolean {
    if (this.routerEvent?.url) {
      if (this.routerEvent.url.includes(param)) {
        return true;
      }
    }
    if (this.router?.url) {
      if (this.router.url.includes(param)) {
        return true;
      }
    }
    return false;
  }

  reloadMatTable(): void {
    this.reload = true;
    setTimeout(() => {
      this.reload = false;
    })
  }

  onRouterDeactivate(): void {
    this.selectedGroup = null as any;
  }

}
