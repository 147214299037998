import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { UsersService } from '../users/users.service';
import { ExportService } from 'src/app/services/export/export.service';
import { NotifService } from 'src/app/services/notifs/notif.service';
import Debug from 'debug';
const debug = Debug('virtuvisio-administration:users-component');
declare var navigator: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [ExportService, UsersService],
})
export class UsersComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  tenant$: Observable<Tenant>;
  file: File | null;
  reload = false;
  routerEvent: RouterEvent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private sharedService: SharedService,
    private exportService: ExportService,
    private notifs: NotifService
  ) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(
      this.sharedService.currentConnectedUser.subscribe(
        (connectedUser: ConnectedUser) => {
          if (connectedUser) {
            this.connectedUser = connectedUser;
          }
        }
      )
    );
    if (this.route.parent) {
      this.onInitSubscriptions.push(
        this.route.parent.paramMap.subscribe((params) => {
          if (params.get('tenantId')) {
            debug(params.get('tenantId'));
            this.getTenant(params.get('tenantId') as string);
          }
        })
      );
    }
    this.onInitSubscriptions.push(this.router.events.subscribe(event => {
      this.routerEvent = event as RouterEvent;
    }))
  }

  getTenant(tenantId: string): void {
    this.tenant$ = this.usersService.getTenant(tenantId).pipe(
      catchError(this.handleError<Tenant>('getTenant')),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  onInputFileChange(event: Event): void {
    const fileUpload = document.getElementById('fileUpload') as HTMLFormElement;
    this.file = null;
    const target = event.target as HTMLInputElement;
    const files = target.files;
    console.log(files);
    if (files && (files[0]?.type === 'text/csv' || files[0]?.type === 'application/vnd.ms-excel' || files[0]?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      this.file = files[0];
    } else {
      this.file = null;
      fileUpload.value = null;
      this.notifs.addErrorNotif('UsersComponent.PleaseSelectCsvFile');
    }
  }

  isRoute(param: string): boolean {
    if (this.routerEvent?.url) {
      if (this.routerEvent.url.includes(param)) {
        return true;
      }
    }
    if (this.router?.url) {
      if (this.router.url.includes(param)) {
        return true;
      }
    }
    return false;
  }

  sendFile(): void {
    const fileUpload = document.getElementById('fileUpload') as HTMLFormElement;
    this.reload = true;
    if (this.file) {
      this.tenant$.subscribe((tenant) => {
        this.usersService.importCsvFile(tenant.id, <File>this.file).subscribe({
          next: (result) => {
            this.notifs.addSuccessNotif('UsersComponent.UsersImported');
            $('#modalImportUserCsv').modal('hide');
            this.reload = false;
          },
          error: (error) => {
            this.file = null;
            fileUpload.value = null;
            this.notifs.addErrorNotif('UsersComponent.ErroWhileImportingUsers', error);
            this.reload = false;
          }
        });
      });
    } else {
      this.file = null;
      fileUpload.value = null;
      this.notifs.addErrorNotif('UsersComponent.PleaseSelectCsvFile');
    }
  }

  exportUsers(): void {
    const fields = 'firstName,lastName,email,phone,lastAccess';
    const url = '/users';
    this.exportService
      .exportDataUsers(url, fields)
      .subscribe((result: string) => {
        this.downloadExport(result);
      });
  }

  downloadExport(res: string): void {
    this.tenant$.subscribe((result) => {
      let exportFilename = 'export_des_utilisateurs.csv';
      if (result) {
        exportFilename =
          'export_des_utilisateurs_tenant_' + result.name + '.csv';
      }
      const csvData = new Blob([res], { type: 'text/csv;charset=utf-8;' });
      // IE11 & Edge
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csvData, exportFilename);
      } else {
        // In FF link must be added to DOM to be clicked
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(csvData);
        link.setAttribute('download', exportFilename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  onDestroyUserEvent(): void {
    setTimeout(() => {
      this.reload = true;
      setTimeout(() => {
        this.reload = false;
      })
    })
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
