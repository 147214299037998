import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { Tenant } from '../tenant/tenant.model';
import { ObtpConfig } from './obtp.model';

@Injectable()
export class ObtpService {

  tenantId: Observable<string> = of('');

  constructor(private http: HttpClient, private optionService: DataApiOptionService, private route: ActivatedRoute) {
    this.tenantId = <Observable<string>>(
      this.route.parent?.paramMap.pipe(map((params) => params.get('tenantId')))
    );
  }

  getTenant(tenantId: string): Observable<Tenant> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http.get<Tenant>(apiUri, {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        });
      }),
      map((result) => new Tenant(result))
    );
  }

  getConfig(): Observable<ObtpConfig> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .get<ObtpConfig>(apiUri + '/obtp/regexp', {
                headers: this.optionService.getHeaders(),
                withCredentials: true,
              })
              .pipe(
                map((result) => {
                  return result;
                }),
                tap((result) => result),
                catchError(this.handleError<ObtpConfig>('getConfig', undefined))
              );
          })
        );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
