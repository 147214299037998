<div class="masquerade-menu">
    <fa-icon id="userMenuIcon" class="pointer menu-icon" [icon]="['fas', 'user-secret']" (click)="displayMenu()"></fa-icon>
    <div class="menu" *ngIf="display" [@enterAnimation]>
        <span class="triangle-header-menu"></span>
        <div class="name">
            {{"MasqueradeMenuComponent.Title" | translate}}
        </div>
        <p class="raspberry-line"></p>
        <div class="mt-4">
            <app-input-field
                [typeInput]="'text'"
                [nameInput]="'masqueradeUser'"
                [textLabel]="'MasqueradeMenuComponent.Users'"
                [myInputModel]="masqueradeUser"
                (modelChangeEvent)="onMasqueradeChange($event)"
            ></app-input-field>
        </div>
        <div class="suggestUser scrollable" *ngIf="displaySuggest">
            <div class="suggestUserItem" *ngFor="let user of usersSuggest"
                [ngClass]="{
                    'pointer': !user.disabled && user.lastAccess && user.id !== connectedUser.id,
                    'disabled-cursor': !user.lastAccess || user.disabled || user.id === connectedUser.id}"
                (click)="!user.disabled && user.lastAccess && user.id !== connectedUser.id ? validateImpersonate(user) : wrongImpersonate(user);">
                <div class="row">
                    <div class="col-12">
                        <div class="singleUser">
                            <div class="float-start mx-2">
                                <div class="userImg" *ngIf="!user.avatar || !user.avatar.id">
                                    <fa-icon [icon]="['far', 'user']"></fa-icon>
                                </div>
                                <img class="userImg"
                                  [src]="api_uri+'/tenants/'+user.tenantId+'/pictures/'+user.avatar.id | makeSrcUrl"
                                  *ngIf="user.avatar && user.avatar.id" />
                            </div>
                            <div>
                                <span class="isRegular">{{user.getDisplayName()}}</span>&nbsp;&nbsp;
                                <i class="connection-info">
                                    (
                                    <span *ngIf="user.id === connectedUser.id">{{"it is you" | translate}}</span>
                                    <span *ngIf="!user.disabled && user.lastAccess && user.id !== connectedUser.id">
                                        {{user.lastAccess | date:kloodDateFormat.pipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
                                    </span>
                                    <span *ngIf="!user.lastAccess && !user.disabled">{{"user never logged in" | translate}}</span>
                                    <span *ngIf="user.disabled">{{"disabled user" | translate}}
                                    </span>
                                    )
                                </i>
                                <br />
                                <div class="details">{{user.email}}</div>
                                <div class="details">{{user.tenantName}}</div>
                                <div class="details">{{user.role}}</div>
                                <div class="details" *ngIf="user.role === 'ADMIN' && user.access">
                                    &nbsp;&nbsp;
                                    <i>({{"acls" | translate}} :  {{user.access[user.tenantId]}})</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
