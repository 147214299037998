import { User } from 'src/app/users/user.model';

export class ConnectedUser extends User {
    avatar: Avatar;

    constructor(obj: any) {
        super(obj);
        Object.assign(this, obj);
    }
}

interface Avatar {
    createdAt: Date;
    id: string;
}
