<app-admin-bloc [blocTitle]="'Add'">
  <form #addMeetingRoom="ngForm" id="addMeetingRoom" name="addMeetingRoom">
    <app-input-form-field [nameInput]="'name'" [typeInput]="'text'" [myInputModel]="newMeetingRoom.name"
      [textLabel]="'AddMeetingRoom.Form.Name'" [form]="addMeetingRoom" class="form-control" [required]="true">
    </app-input-form-field>
    <app-input-form-field [nameInput]="'description'" [typeInput]="'text'" [myInputModel]="newMeetingRoom.description"
      [textLabel]="'AddMeetingRoom.Form.Description'" [form]="addMeetingRoom" class="form-control">
    </app-input-form-field>
    <app-input-form-field [nameInput]="'email'" [typeInput]="'text'" [myInputModel]="newMeetingRoom.email"
      [textLabel]="'AddMeetingRoom.Form.Email'" [form]="addMeetingRoom" class="form-control" [required]="true">
    </app-input-form-field>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary w-100" type="reset"
      [routerLink]="['../']">{{"AddMeetingRoom.Form.Button.Cancel" | translate }}</button>
      <app-loading-button (submitEvent)="confirmAddMeetingRoom(addMeetingRoom, addMeetingRoom.value)" [targetStyle]="'primary'"
        [finished]="finished" [error]="hasError" [initialText]="'AddMeetingRoom.Form.Button.Add'"
        [finalText]="'AddMeetingRoom.Form.Button.Added'">
      </app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="addMeetingRoom.submitted && !addMeetingRoom.valid">
      {{'AddMeetingRoom.Form.Button.SomeFields' | translate}}</div>
  </form>
</app-admin-bloc>