<app-admin-bloc [blocTitle]="'Edit'">
  <form #editEnvironment="ngForm" id="editEnvironment" name="editEnvironment" *ngIf="currentEnvironment">
    <app-input-form-field [nameInput]="'host'" [typeInput]="'text'" [myInputModel]="currentEnvironment.host" [textLabel]="'EditEnvironmentComponent.host'" [form]="editEnvironment" class="form-control" [required]="true">
    </app-input-form-field>
    <app-input-form-field [nameInput]="'pexipDomain'" [typeInput]="'text'" [myInputModel]="currentEnvironment.pexipDomain" [textLabel]="'EditEnvironmentComponent.pexipDomain'" [form]="editEnvironment" class="form-control">
    </app-input-form-field>
    <div class="row align-items-center mb-3">
      <div class="col-auto pe-0">
        <app-checkbox-form-field [nameInput]="'public'" [form]="editEnvironment" [checked]="currentEnvironment.public" [myInputModel]="currentEnvironment.public" (checkedEvent)="currentEnvironment.public=$event"></app-checkbox-form-field>
      </div>
      <div class="col ps-0">
        {{"EditEnvironmentComponent.Public" | translate}}
      </div>
    </div>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary w-100" type="button" [routerLink]="['../../']">{{"EditEnvironmentComponent.Cancel" | translate }}</button>
      <app-loading-button (submitEvent)="confirmEditEnvironment(editEnvironment)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'EditEnvironmentComponent.Edit'" [finalText]="'EditEnvironmentComponent.Edited'">
      </app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="editEnvironment.submitted && !editEnvironment.valid">
      {{'EditEnvironmentComponent.SomeFields' | translate}}</div>
  </form>
</app-admin-bloc>