import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { UserDataCustom } from 'src/app/users/user-connected/user.connected.data.custom';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';

@Injectable()
export class SharedService {
  private connectedUser = new ReplaySubject<ConnectedUser>();
  private isLogged = new ReplaySubject<boolean>();
  private connectedUserData = new ReplaySubject<UserDataCustom>();
  private updatedTenantList = new ReplaySubject<boolean>();
  private theme = new ReplaySubject<string>();
  // Observable streams
  currentConnectedUser = this.connectedUser.asObservable();
  currentIsLogged = this.isLogged.asObservable();
  currentConnectedUserData = this.connectedUserData.asObservable();
  currentUpdateTenantList = this.updatedTenantList.asObservable();
  currentTheme = this.theme.asObservable();

  // Service message commands
  _connectedUser(): Observable<ConnectedUser> {
    return this.connectedUser;
  }

  _isLogged(): Observable<boolean> {
    return this.isLogged;
  }

  _currentTheme(): Observable<string> {
    return this.theme;
  }

  updateIsLogged(isLogged: boolean): void {
    this.isLogged.next(isLogged);
  }

  updateConnectedUser(user: ConnectedUser): void {
    this.connectedUser.next(user);
  }

  updateConnectedUserData(userData: UserDataCustom): void {
    this.connectedUserData.next(userData);
  }

  updateTenantList(reload: boolean): void {
    this.updatedTenantList.next(reload);
  }

  updateTheme(theme: string): void {
    this.theme.next(theme);
  }

}
