export class Endpoint {
  // need for new
  kind: string | null;
  active: boolean | null;
  adminContact: string;
  capability: string;
  config: {
    ip: string | null;
    username: string | null;
    password: string | null;
    dialString: string | null;
    scheme: string | null;
    port: number | null;
    checkCertificates: boolean | null;
  };
  // add by broker
  id: string;
  createdAt: Date;
  deleted: boolean;
  deletedAt: Date;
  email: string;
  name: string;
  meetingRoomId: string;
  moderatorCode: string;
  number: string;
  sipAccount: {
    alias: string;
    registered: true;
    username: string;
    password: string;
  };
  oldId: number;
  password: string;
  registered: boolean;
  remoteId: string;
  tenantId: string;
  updatedAt: Date;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.active = null;
    this.kind = null;
    this.config = {
      ip: null,
      username: null,
      password: null,
      dialString: null,
      scheme: null,
      port: null,
      checkCertificates: false,
    };
  }

  getId(): string {
    return this.id;
  }
}

export class EndpointStatus {
  when: Date;
  error: string;
  id: string;
  createdAt: Date;
  meetingRoomId?: string;
  items?: any;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export class SipAccount {
  alias: string;
  username: string;
  password: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export const EndpointKinds = [{ value: "POLYCOM_GROUP_SERIES", label: "POLYCOM_GROUP_SERIES" }, { value: "CISCO", label: "CISCO" }, { value: "OTHER", label: "OTHER" }];

export const EndpointCapabilities = [{ value: "", label: "NONE", id: "NONE" }, { value: "GATEWAY", label: "GATEWAY", id: "GATEWAY" }, { value: "ONE", label: "ONE_CODEC", id: "ONE_CODEC" }, { value: "BOTH", label: "BOTH", id: "BOTH" }];

export const EndpointConfigSchemes = [{ value: "http", label: "http" }, { value: "https", label: "https" }];
