<div class="d-flex w-100 vh-100 mx-auto flex-column">
    <app-header></app-header>
    <main role="main" class="cover h-100">
        <div class="home h-100">
            <div class="container-fluid h-100">
                <div class="row h-100">
                    <div class="col-12 text-center my-auto">
                        <div style="font-weight: bold; height: -webkit-fill-available; padding: 10px" class="align-center">
                            <p>
                                <img [src]="'assets/images/logo-primary.png' | makeSrcUrl" height="80px" class="pointer" (click)="navigateToHome()" />
                            </p>
                            <p style="font-size: 3em">{{"ErrorsComponent.Wrong" | translate}}</p>
                            <br /><br />
                            <div *ngIf="routeParams?.clientSide">
                                <p style="font-size: 1.5em">
                                    {{"ErrorsComponent.ERROR" | translate}} :<br />
                                    {{ routeParams?.clientSide }}
                                </p>
                            </div>
                            <br /><br />
                            <div>
                                <button class="btn btn-app app-primary" (click)="reloadNewVersion()">{{"ErrorsComponent.Reload" | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
