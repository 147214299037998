<ng-container *ngIf="(user$ | async) as user">
    <app-admin-details-bloc [blocTitle]="user.displayName" (closeEvent)="backToView(user.id)">
        <div class="mb-3">
            {{"UserEditPasswordComponent.Title" | translate}}
        </div>
        <form #editUserPasswordForm="ngForm" name="editUserPasswordForm" id="editUserPasswordForm" (ngSubmit)="editUserPassword(editUserPasswordForm.value, user)">
            <app-input-form-field
                [textLabel]="'UserEditPasswordComponent.NewPassword'" 
                [typeInput]="'password'"
                [nameInput]="'newPassword'" 
                [myInputModel]="newPassword" 
                [form]="editUserPasswordForm" 
                class="form-control"
                [required]="true" [targetValidators]="validatorsList.Password"></app-input-form-field>
            <app-input-form-field
                [textLabel]="'UserEditPasswordComponent.ConfirmPassword'" 
                [typeInput]="'password'"
                [nameInput]="'confirmPassword'" 
                [myInputModel]="confirmPassword" 
                [form]="editUserPasswordForm" 
                class="form-control"
                [required]="true" [targetValidators]="validatorsList.Password"></app-input-form-field>
            <div class="dual-button-container">
                <button class="btn btn-sm btn-app app-secondary" type="reset" (click)="backToView(user.id)">
                    {{"Button.Cancel" | translate}}
                </button>
                <button class="btn btn-sm btn-app app-primary" type="submit">
                    {{"Button.Valid" | translate}}
                </button>
            </div>
        </form>
    </app-admin-details-bloc>
</ng-container>
