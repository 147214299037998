import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
	selector: '[appValidPassword]',
	providers: [{ provide: NG_VALIDATORS, useExisting: ValidPasswordValidatorDirective, multi: true }],
})
export class ValidPasswordValidatorDirective implements Validator {
	validate(control: AbstractControl): { [key: string]: any } | null {
		const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
		const correct = regex.test(control.value);
		return !correct ? { appValidPassword: { value: control.value } } : null;
	}
}
