import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { Geozone } from './geozone.model';

@Injectable()
export class GeozonesService {
  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService
  ) { }

  getGeozones(continentId: string): Observable<Geozone[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<Geozone[]>(
          apiUri + '/continents/' + continentId + '/geoZones',
          {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          }
        );
      })
    );
  }

  save(geozone: Geozone): Observable<Geozone> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.put<Geozone>(
          apiUri +
          '/continents/' +
          geozone.continentId +
          '/geoZones/' +
          geozone.id,
          geozone,
          {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          }
        );
      })
    );
  }

  create(continentId: string, geozone: Geozone): Observable<Geozone> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.post<Geozone>(
          apiUri + '/continents/' + continentId + '/geoZones',
          geozone,
          {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          }
        );
      })
    );
  }

  delete(geozone: Geozone): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.delete(
          apiUri +
          '/continents/' +
          geozone.continentId +
          '/geoZones/' +
          geozone.id,
          {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          }
        );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
