<app-admin-bloc [blocTitle]="'Edit'">
  <form #editGroup="ngForm" id="editGroup" name="editGroup" *ngIf="currentGroup">
    <app-input-form-field [nameInput]="'name'" [typeInput]="'text'" [myInputModel]="currentGroup.name" [textLabel]="'EditGroup.Name'" [form]="editGroup" class="form-control" [required]="true">
    </app-input-form-field>
    <div class="row align-items-center mb-3">
      <div class="col-auto pe-0">
        <app-checkbox-form-field [nameInput]="'autoSync'" [form]="editGroup" [checked]="currentGroup.autoSync" [myInputModel]="currentGroup.autoSync" (checkedEvent)="currentGroup.autoSync=$event"></app-checkbox-form-field>
      </div>
      <div class="col ps-0">
        {{"EditGroup.AutoSync" | translate}}
      </div>
    </div>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary w-100" type="reset" [routerLink]="['../../']">{{"EditGroup.Cancel" | translate }}</button>
      <app-loading-button (submitEvent)="confirmEditGroup(editGroup)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'EditGroup.Edit'" [finalText]="'EditGroup.Edited'">
      </app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="editGroup.submitted && !editGroup.valid">
      {{'EditGroup.SomeFields' | translate}}</div>
  </form>
</app-admin-bloc>