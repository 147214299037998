<div class="row align-items-center p-3">
  <div class="col-auto">
    <h3 class="m-0">
      <span class="is-bold">{{"TrialsComponent.Title" | translate}}</span>
    </h3>
  </div>
  <div class="col">
    <button class="btn btn-app app-primary float-end me-2" type="submit" (click)="exportTrials()">
      <fa-icon [icon]="['fas', 'file-csv']" class="me-2"></fa-icon>{{"TrialsComponent.ExportTrials" | translate }}
    </button>
  </div>
</div>
<div class="row">
  <div [ngClass]="{'col-9': editTrial, 'col-12': !editTrial}">
    <app-admin-card>
      <div class="row align-items-center">
        <div class="col-auto pe-0">
          <app-checkbox-field [nameInput]="'activeTrialCheck'" [checked]="activeTrialCheck" [myInputModel]="activeTrialCheck" (checkedEvent)="showActiveTrials($event)"></app-checkbox-field>
        </div>
        <div class="col-auto ps-0">
          {{"TrialsComponent.ShowActiveTrials" | translate}}
        </div>
      </div>
      <app-mat-table-custom *ngIf="displayedColumns && !refreshTable && trialsService" [selectedRowId]="selectedTrial?.id" [selectedRowItem]="'id'" [hoverable]="false" [componentName]="'TrialsComponent'" [displayedColumns]="displayedColumns" [dataService]="trialsService" [defaultSort]="defaultSort" (dataError)="onDataError($event)" (actionClick)="onActionClick($event)">
      </app-mat-table-custom>
    </app-admin-card>
  </div>
  <div class="col-3" *ngIf="editTrial">
  	<app-edit-trial *ngIf="selectedTrial && currentConnectedUser" [currentConnectedUser]="currentConnectedUser" [selectedTrial]="selectedTrial" (trialEditedEvent)="onTrialEditedEvent($event)"></app-edit-trial>
  </div>
</div>
<div class="modal fade custom-app-modal" id="modalOnDeleteTrial" role="dialog" aria-labelledby="modalOnDeleteTrialLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content flat">
      <div class="modal-header">
        <h3 class="modal-title text-center" id="modalOnDeleteTrialLabel">{{"TrialsComponent.Delete" | translate}}</h3>
      </div>
      <div class="modal-body">
        <div class="is-bold mb-3">{{"TrialsComponent.SureDeleteTrial" | translate}}</div>
        <div class="dual-button-container">
          <button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal">
            {{"TrialsComponent.Cancel" | translate}}
          </button>
          <button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="confirmDeleteTrial()">
            {{"TrialsComponent.Confirm" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>