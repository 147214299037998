<ng-container *ngIf="connectedUser && (tenant$ | async) as tenant">
  <div class="row align-items-center p-3">
    <div class="col-auto">
      <h3 class="m-0">
        <span class="is-bold">{{"UsersComponent.Title" | translate}}</span>
      </h3>
    </div>
    <div class="col">
      <ng-container *ngIf="connectedUser.isGranted(tenant.id)">
        <button class="btn btn-app app-primary float-end" type="submit" [routerLink]="['./add']" *ngIf="tenant.authProvider !== 'microsoft'">
          <fa-icon [icon]="['fas', 'plus-circle']" class="me-2"></fa-icon>{{"UsersComponent.Button.Add" | translate }}
        </button>
        <button class="btn btn-app app-primary float-end me-2" type="submit" (click)="exportUsers()">
          <fa-icon [icon]="['fas', 'file-csv']" class="me-2"></fa-icon>{{"UsersComponent.Button.ExportUsers" | translate }}
        </button>
        <button class="btn btn-app app-primary float-end me-2" type="submit" *ngIf="tenant.authProvider !== 'microsoft'" data-bs-toggle="modal" data-bs-target="#modalImportUserCsv">
          <fa-icon [icon]="['fas', 'file-csv']" class="me-2"></fa-icon>{{"UsersComponent.Button.ImportUsers" | translate }}
        </button>
      </ng-container>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
          <div class="row">
            <div [ngClass]="{'col-12': !isRoute('/view') && !isRoute('/edit') && !isRoute('/add') && !isRoute('/edit-password'), 'col-6': isRoute('/view'), 'col-7': isRoute('/add') || isRoute('/edit') || isRoute('/edit-password')}">
              <app-admin-card>
                <app-users-list *ngIf="!reload" [tenant]="tenant" [connectedUser]="connectedUser"></app-users-list>
              </app-admin-card>
            </div>
            <div class="col-6" *ngIf="isRoute('/view')">
              <app-user-view *ngIf="isRoute('/view')" [tenant]="tenant" [connectedUser]="connectedUser" (destroyUserAddEvent)="onDestroyUserEvent()"></app-user-view>
            </div>
            <div class="col-5" *ngIf="isRoute('/add')">
              <app-user-add [tenant]="tenant" [connectedUser]="connectedUser" (destroyUserAddEvent)="onDestroyUserEvent()"></app-user-add>
            </div>
            <div class="col-5" *ngIf="isRoute('/edit') && !isRoute('/edit-password')">
              <app-user-edit [tenant]="tenant" [connectedUser]="connectedUser" (destroyUserAddEvent)="onDestroyUserEvent()"></app-user-edit>
            </div>
            <div class="col-5" *ngIf="isRoute('/edit-password')">
              <app-user-edit-password [tenant]="tenant" [connectedUser]="connectedUser" (destroyUserAddEvent)="onDestroyUserEvent()"></app-user-edit-password>
            </div>
          </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="modal fade custom-app-modal" id="modalImportUserCsv" role="dialog" aria-labelledby="modalImportUserCsvLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content flat">
      <div class="modal-header">
        <h3 class="modal-title text-center" id="modalImportUserCsvLabel">{{"UsersComponent.Modal.ImportUsers" | translate}}</h3>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="formFile" class="form-label">{{"UsersComponent.Modal.PleaseSelectCsvFile" | translate}}</label>
          <input class="form-control" type="file" id="fileUpload" name="fileUpload" (change)="onInputFileChange($event)">
        </div>
        <div class="mb-3 is-bold">{{"UsersComponent.Modal.FormatImportCsv1" | translate}} :</div>
        <div>{{"UsersComponent.Modal.FormatImportCsv2" | translate}}</div>
        <div>{{"UsersComponent.Modal.FormatImportCsv3" | translate}}</div>
        <div>{{"UsersComponent.Modal.FormatImportCsv4" | translate}}</div>
        <div class="dual-button-container mt-3">
          <button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal">
            {{"UsersComponent.Modal.Cancel" | translate}}
          </button>
          <button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="sendFile()">
            {{"UsersComponent.Modal.Confirm" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>