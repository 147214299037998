<label for="{{nameInput}}" class="w-100" *ngIf="typeInput !== 'number'">
    <input
        name="{{nameInput}}"
        type="{{typeInput}}"
        id="{{nameInput}}"
        placeholder="{{textLabel|translate}}"
        [(ngModel)]="myInputModel"
        [required]="required"
        [readonly]="readonly"
        [disabled]="disabled"
        (ngModelChange)="onChange()"
        [ngClass]="{'light': light}"
    />
    <span [ngClass]="{'light': light}">{{textLabel|translate}}</span>
</label>
<label for="{{nameInput}}" class="w-100" *ngIf="typeInput === 'number'">
    <input 
        name="{{nameInput}}" 
        type="number" 
        id="{{nameInput}}" 
        placeholder="{{textLabel|translate}}"
        [(ngModel)]="myInputModel" 
        [required]="required" 
        [readonly]="readonly" 
        [disabled]="disabled"
        [min]="minNumber"
        [max]="maxNumber"
        (ngModelChange)="onChange()" 
        [ngClass]="{'light': light}" />
    <span [ngClass]="{'light': light}">{{textLabel|translate}}</span>
</label>