import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { Accessible } from "src/app/accessible/accessible.model";
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class AccessibleService {
  tenantId: Observable<string> = of("");
  constructor(private http: HttpClient, private optionService: DataApiOptionService, private route: ActivatedRoute) {
    this.tenantId = <Observable<string>>(this.route.parent?.paramMap.pipe(map((params) => params.get('tenantId'))));
  }

  save(userId: string, on: string, acl: string): Observable<Accessible> {
    return this.tenantId.pipe(switchMap((tenantId) => {
      return this.optionService.getApiUrl(tenantId).pipe(switchMap((apiUri) => {
        return this.http.put(apiUri + '/acls/' + userId + '/' + on + '/' + acl, null, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        }).pipe(map((result) => result));
      }));
    }));
  }

  delete(userId: string, on: string, acl: string): Observable<Accessible> {
    return this.tenantId.pipe(switchMap((tenantId) => {
      return this.optionService.getApiUrl(tenantId).pipe(switchMap((apiUri) => {
        return this.http.delete(apiUri + '/acls/' + userId + '/' + on + '/' + acl, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        }).pipe(map((result) => result));
      }));
    }));
  }
}
