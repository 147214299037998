import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { Country } from './country.model';

@Injectable()
export class CountryService {
  constructor(private http: HttpClient, private optionService: DataApiOptionService) {}

  getCountries(): Observable<Country[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<Country[]>(apiUri + '/countries', {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      })
    );
  }

  save(country: Country): Observable<Country> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.put<Country>(apiUri + '/countries/' + country.id, country, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      })
    );
  }
}