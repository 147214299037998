import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { RequestFilterBuilder } from 'src/app/services/request-filter-builder/request-filter-builder';
import { suggestPattern } from 'src/app/operators/mongo-operators/mongo-operators';
import { ActivatedRoute } from '@angular/router';
import { PaginationInfo } from '../commons/mat-table-custom/mat-table-custom.model';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from '../services/http-encoder/custom-http-param-encoder.service';
import { TrialUser } from './trial.model';

@Injectable()
export class TrialsService {
  showActive = true;

  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.showActive = params.active;
    });
  }

  buildParamsUrl(
    paginationInfo: PaginationInfo,
    moreParams?: string[]
  ): HttpParams {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoderService(),
    });
    if (moreParams) {
      for (let p in moreParams) {
        let customParam = <string>(
          paginationInfo[<keyof PaginationInfo>moreParams[p]]
        );
        params = params.set(moreParams[p], customParam);
      }
    }
    const filter = new RequestFilterBuilder();
    if (paginationInfo.suggest) {
      filter.set('suggests', suggestPattern(paginationInfo.suggest));
    }
    const q = filter.serialize();
    return (params = params.set('q', q));
  }

  getHead(paginationInfo: PaginationInfo): Observable<number> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        let params = this.buildParamsUrl(paginationInfo);
        params = params.set('active', this.showActive);
        return this.http
          .head(apiUri + '/trial', {
            params: params,
            headers: this.optionService.getHeaders(),
            observe: 'response',
            withCredentials: true,
          })
          .pipe(map((result) => parseInt(<string>result.headers.get('Count'))));
      })
    );
  }

  getData(paginationInfo: PaginationInfo): Observable<TrialUser[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        let params = this.buildParamsUrl(paginationInfo, [
          'sort',
          'order',
          'skip',
          'limit',
        ]);
        params = params.set('active', this.showActive);
        return this.http.get<TrialUser[]>(apiUri + '/trial', {
          params: params,
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        });
      })
    );
  }

  delete(trial: TrialUser): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.delete(apiUri + '/trial/' + trial.id, {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        });
      })
    );
  }

  renew(trial: TrialUser, delay: { delay: number }): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.post(apiUri + '/trial/' + trial.id + '/renew', delay, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
