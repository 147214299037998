<div class="custom-notif" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="custom-notif-header pb-2">
        <div [class]="options.titleClass" [attr.aria-label]="title" class="me-auto ms-5 notif-title">
            <span class="success-title">{{"ToastNotif.Title.Success" | translate}}</span>
            <span class="error-title">{{"ToastNotif.Title.Error" | translate}}</span>
            <span class="warning-title">{{"ToastNotif.Title.Warning" | translate}}</span>
            <ng-container *ngIf="title !== 'new-version-notif'">
                <span class="info-title">{{"ToastNotif.Title.Info" | translate}}</span>
            </ng-container>
            <ng-container *ngIf="title === 'new-version-notif'">
                {{"AppComponent.NewVersionToast1" | translate}}
            </ng-container>
        </div>
        <button type="button" *ngIf="options.closeButton" (click)="remove()" class="btn-close" aria-label="Close"></button>
    </div>
    <div class="custom-notif-body p-3" *ngIf="title">
        <div role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
            <ng-container *ngIf="title !== 'new-version-notif'">{{ title }}</ng-container>
            <ng-container *ngIf="title === 'new-version-notif'">
                {{"AppComponent.NewVersionToast2" | translate}} <a class="error-link is-bold is-primary pointer" (click)="reloadNewVersion()">{{"AppComponent.NewVersionToast3" | translate}}</a> {{"AppComponent.NewVersionToast4" | translate}}
            </ng-container>
            <div *ngIf="message">
                <a class="error-link pointer is-primary" (click)="showFullError(message);">{{"ToastNotif.Link" | translate}}</a>
            </div>
        </div>
    </div>
</div>

<!-- <div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div class="col-9">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }}
        </div>
        <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass"
          [innerHTML]="message">
        </div>
        <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass"
          [attr.aria-label]="message">
            {{ message }}
        </div>
    </div>
    <div class="col-3 text-right">
        <button type="button" *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div> -->


<!-- <button type="button" *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
    <span aria-hidden="true">&times;</span>
</button>
<div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" (dblclick)="showFullError(message);">{{ title }}</div> -->
<div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>

<div class="modal fade custom-app-modal" id="notifErrorModal" role="dialog" aria-labelledby="notifErrorModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content flat">
            <div class="modal-header">
                <h5 class="modal-title text-center" id="notifErrorModalLabel">{{"error" | translate}} - {{happenTime | date:kloodDateFormat.pipe["fr-FR"]:undefined:kloodDateFormat.locale["fr-FR"]}}</h5>
            </div>
            <div class="modal-body body-modal-custom">
                {{happenTime | date:kloodDateFormat.pipe["en-US"]:undefined:kloodDateFormat.locale["en-US"]}}
                <pre>{{message | json}}</pre>
            </div>
            <div class="modal-footer">
                <div class="row justify-content-center w-100">
                    <div class="col-6 align-self-center">
                        <button type="button" class="btn btn-app app-primary w-100" data-bs-dismiss="modal">{{"Button.Close" | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
