import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PaginationInfo } from 'src/app/commons/mat-table-custom/mat-table-custom.model';
import { suggestPattern } from 'src/app/operators/mongo-operators/mongo-operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from 'src/app/services/http-encoder/custom-http-param-encoder.service';
import { RequestFilterBuilder } from 'src/app/services/request-filter-builder/request-filter-builder';
import { User } from '../user.model';

@Injectable()
export class UsersListService {
  tenantId: Observable<string> = of('');

  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService,
    private route: ActivatedRoute
  ) {
    this.tenantId = <Observable<string>>(
      this.route.parent?.paramMap.pipe(
        map((params) => params.get('tenantId'))
      )
    );
  }

  buildParamsUrl(
    paginationInfo: PaginationInfo,
    moreParams?: string[]
  ): HttpParams {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoderService(),
    });
    if (moreParams) {
      for (let p in moreParams) {
        let customParam = <string>(
          paginationInfo[<keyof PaginationInfo>moreParams[p]]
        );
        params = params.set(moreParams[p], customParam);
      }
    }
    const filter = new RequestFilterBuilder();
    if (paginationInfo.suggest) {
      filter.set('suggests', suggestPattern(paginationInfo.suggest));
    }
    const q = filter.serialize();
    return (params = params.set('q', q));
  }

  getHead(paginationInfo: PaginationInfo): Observable<number> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            const params = this.buildParamsUrl(paginationInfo);
            return this.http
              .head(apiUri + '/users', {
                params: params,
                headers: this.optionService.getHeaders(),
                observe: 'response',
                withCredentials: true,
              })
              .pipe(
                map((result) => parseInt(<string>result.headers.get('Count')))
              );
          })
        );
      })
    );
  }

  getData(paginationInfo: PaginationInfo): Observable<User[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            const params = this.buildParamsUrl(paginationInfo, [
              'sort',
              'order',
              'skip',
              'limit',
            ]);
            return this.http
              .get<User[]>(apiUri + '/users', {
                params: params,
                headers: this.optionService.getHeaders(),
                withCredentials: true,
                observe: 'body',
              })
              .pipe(
                map((result) => {
                  const users = [];
                  for (const res of result) {
                    const user = new User(res);
                    users.push(user);
                  }
                  return users;
                })
              );
          })
        );
      })
    );
  }

  delete(user: User): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .delete(apiUri + '/users/' + user.id, {
                headers: this.optionService.getHeaders(),
                observe: 'body',
                withCredentials: true,
              })
              .pipe(map((result) => result));
          })
        );
      })
    );
  }
}
