import { trigger, style, animate, transition } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from 'src/app/services/config/app.config.service';
import { UrlGeneratorService } from 'src/app/services/url-generator/url-generator.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';
import { MasqueradeMenuService } from '../masquerade-menu/masquerade-menu.service';
import { Router } from '@angular/router';
import { NotifService } from 'src/app/services/notifs/notif.service';

const debug = Debug('virtuvisio-administration:header-user-menu');

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  providers: [MasqueradeMenuService],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class UserMenuComponent {
  @Input() connectedUser: ConnectedUser;
  @Input() display = false;
  @Output() justDisplayUserMenu = new EventEmitter<boolean>();
  hasCookie = false;
  api_uri: string;
  logOutUri: string;

  constructor(
    private cookieService: CookieService,
    private urlGeneratorService: UrlGeneratorService,
    private configUi: AppConfigService,
    private eRef: ElementRef,
    private masqueradeService: MasqueradeMenuService,
    private router: Router,
    private notif: NotifService
  ) {
    this.api_uri = this.configUi.settings.API_URI;
    this.logOutUri =
      this.api_uri +
      '/logout?redirect_uri=' +
      this.urlGeneratorService.buildUrl('/login');
    this.logOutUri = encodeURI(this.logOutUri);
  }

  displayMenu(): void {
    this.display = !this.display;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (this.eRef.nativeElement.contains(event.target) && this.display) {
      this.display = true;
    } else {
      this.display = false;
    }
    this.justDisplayUserMenu.emit(this.display);
  }

  navigateTo(path: string) {
    const front_uri = window.location.protocol + '//' + window.location.host.replace('admin', 'app') + '/';
    switch (path) {
      case 'profil':
        window.location.href = front_uri + "profil";
        break;
      case 'contacts':
        window.location.href = front_uri + 'contacts';
        break;
      case 'conference':
        window.location.href = front_uri + 'conference-informations/' + this.connectedUser.privateRoomAlias;
        break;
      case 'moderation':
        window.location.href = front_uri + 'kloodApp/moderation';
        break;
      default:
        break;
    }
  }

  deactivateImpersonateMode(): void {
    this.masqueradeService.deactiveImpersonate().subscribe({
      next: (result) => {
        debug('impersonate off : %O', result);
        if (localStorage.getItem('impersonatePreviousUrl')) {
          const url = localStorage.getItem('impersonatePreviousUrl') as string;
          localStorage.removeItem('impersonatePreviousUrl');
          window.location.href = url;
        } else {
          const link = ['/'];
          this.router.navigate(link).then(() => {
            window.location.reload();
          });
        }
      },
      error: (error) => {
        this.notif.addErrorNotif(
          'MasqueradeMenuComponent.Error.Deactivate',
          error
        );
      }
    });
  }

  hasOneOffer(): boolean {
    if (this.connectedUser && this.connectedUser.allowedOffers && this.connectedUser.allowedOffers.indexOf('ONE') !== -1 && this.connectedUser.hasPhone()) {
      return true;
    }
    return false;
  }
}
