import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Controls } from 'src/app/commons/toolbar/toolbar.model';
import { LiveConference } from '../live-conference.model';
import { LiveConferenceService } from '../live-conferences.service';
import { PexipParticipantService } from '../participants-live-conference/pexip-participants.service';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { PexipMediaStream, PexipParticipant } from 'src/app/pexip-participant/pexip-participant.model';

@Component({
	selector: 'app-pexip-participant',
	templateUrl: './pexip-participant.component.html',
	styleUrls: ['./pexip-participant.component.scss'],
	providers: [LiveConferenceService, PexipParticipantService],
})
export class PexipParticipantComponent implements OnInit, OnDestroy {
	
	kloodDateFormat = KloodDateFormat;
	onInitSubscriptions: Subscription[] = [];
	conferenceId: string;
	participantId: string;
	loading = false;
	mediaStreams: PexipMediaStream[];
	controls: Controls[] = [{
		icon: 'times',
		doubleIcon: false,
		secondIcon: 'times',
		controleType: 'ACTION',
		action: 'BACK',
		tooltip: 'back to previous',
		isSwitch: false
	}];
	currentConference: LiveConference;
	currentParticipant: PexipParticipant;
	connectedUser: ConnectedUser;
	mediaStreamVideo: PexipMediaStream;
	mediaStreamAudio: PexipMediaStream;
	mediaStreamPresentation: PexipMediaStream;
	
	constructor(private sharedService: SharedService, private location: Location, private route: ActivatedRoute, private liveConferenceService: LiveConferenceService, public pexipParticipantService: PexipParticipantService) { }
	
	ngOnInit(): void {
		this.onInitSubscriptions.push(this.route.paramMap.subscribe((params) => {
			this.participantId = <string>params.get('participantId');
			this.conferenceId = <string>params.get('conferenceId');
			this.getLiveConferences();
		}))
		this.sharedService.currentConnectedUser.subscribe(user => {
			this.connectedUser = user;
		})
	}
	
	getLiveConferences(): void {
		this.loading = true;
		this.liveConferenceService.getLiveConferences().subscribe((liveConferences: LiveConference[]) => {
			for(let conference of liveConferences) {
				if(conference.id === this.conferenceId){
					this.currentConference = conference;
					this.getParticipants();
				}
			}
		})
	}
	
	getParticipants(): void {
		this.pexipParticipantService.getParticipants(this.currentConference.name).subscribe((participants: PexipParticipant[]) => {
			for(let participant of participants) {
				if(participant.id === this.participantId){
					this.currentParticipant = participant;
					this.getMediaStreams();
				}
			}
		})
	}
	
	getMediaStreams(): void {
		this.pexipParticipantService.getMediaStreams(this.currentConference.name, this.participantId).subscribe((mediaStreams: PexipMediaStream[]) => {
			this.mediaStreams = mediaStreams;
			for (const m in this.mediaStreams) {
				if (this.mediaStreams[m].type === "video") {
					this.mediaStreamVideo = this.mediaStreams[m];
				}
				if (this.mediaStreams[m].type === "audio") {
					this.mediaStreamAudio = this.mediaStreams[m];
				}
				if (this.mediaStreams[m].type === "presentation") {
					this.mediaStreamPresentation = this.mediaStreams[m];
				}
			}
			this.loading = false;
		})
	}
	
	closeAction(action: string): void {
		if(action === 'BACK') {
			this.location.back();
		}
	}
	
	ngOnDestroy(): void {
		this.onInitSubscriptions.forEach(subscription => {
			subscription.unsubscribe();
		})
	}
}
