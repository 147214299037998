import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-only-loading-button',
  templateUrl: './only-loading-button.component.html',
  styleUrls: ['./only-loading-button.component.scss'],
})
export class OnlyLoadingButtonComponent {
  @Input() targetStyle = '';
}
