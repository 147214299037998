import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from 'src/app/services/http-encoder/custom-http-param-encoder.service';
import { Tenant } from 'src/app/tenant/tenant.model';

@Injectable()
export class ExpiredOfferService {
  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService
  ) { }

  getHead(query: string): Observable<number> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        let params = new HttpParams({
          encoder: new CustomHttpParamEncoderService(),
        });
        if (query) {
          params = params.set('q', query);
        }
        return this.http
          .head(apiUri + '/tenants', {
            params: params,
            headers: this.optionService.getHeaders(),
            observe: 'response',
            withCredentials: true,
          })
          .pipe(map((result) => parseInt(<string>result.headers.get('Count'))));
      })
    );
  }

  getPagination(
    query: string,
    skip: number,
    limit: number,
    sort?: string,
    order?: string
  ): Observable<Tenant[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        let params = new HttpParams({
          encoder: new CustomHttpParamEncoderService(),
        });
        if (query) {
          params = params.set('q', query);
        }
        params = params.set('skip', skip);
        params = params.set('limit', limit);
        if (sort) {
          params = params.set('sort', sort);
        }
        if (order) {
          params = params.set('order', order);
        }
        return this.http.get<Tenant[]>(apiUri + '/tenants', {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        const tenants = [];
        for (const u in result) {
          tenants.push(new Tenant(result[u]));
        }
        return tenants;
      }),
      tap((result) => result),
      catchError(this.handleError<Tenant[]>('gestTenants', []))
    );
  }

  getSuggest(
    query: string,
    suggest: string,
    limit: number,
    sort?: string,
    order?: string
  ): Observable<Tenant[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        let params = new HttpParams({
          encoder: new CustomHttpParamEncoderService(),
        });
        if (query) {
          params = params.set('q', query);
        } else {
          const filter = {
            suggests: {
              $regularExpression: {
                pattern: suggest,
                options: 'im',
              },
            },
          };
          params = params.set('q', JSON.stringify(filter));
        }
        params = params.set('limit', limit);
        if (sort) {
          params = params.set('sort', sort);
        }
        if (order) {
          params = params.set('order', order);
        }
        return this.http.get<Tenant[]>(apiUri + '/tenants', {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        const tenants = [];
        for (const u in result) {
          tenants.push(new Tenant(result[u]));
        }
        return tenants;
      }),
      tap((result) => result),
      catchError(this.handleError<Tenant[]>('getTenants', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
