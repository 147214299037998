import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Actions, Controls, ControlType } from '../toolbar/toolbar.model';

@Component({
  selector: 'app-admin-bloc',
  templateUrl: './admin-bloc.component.html',
  styleUrls: ['./admin-bloc.component.scss'],
})
export class AdminBlocComponent {
  @Input() scrollable: boolean;
  @Input() blocTitle: string;
  @Input() translateParams: any;
  @Input() blocControls: Controls[];
  @Output() requestAction = new EventEmitter<string>();
  Type = ControlType;
  actions = Actions;

  onRequestAction(action?: string): void {
    this.requestAction.emit(action);
  }

}
