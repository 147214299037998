<div class="d-flex w-100 vh-100 mx-auto flex-column">
    <app-header></app-header>
    <main role="main" class="cover h-100">
        <div class="home h-100">
            <div class="container-fluid h-100">
                <div class="row h-100">
                    <div class="col-12 text-center my-auto">
                        <span class="four-ho-four-text">{{"404.Title"|translate}}</span>
                        <h1 class="text-center">{{"404.Description"|translate}}<fa-icon [icon]="['fas', 'frown']" class="ms-2"></fa-icon>
                        </h1>
                        <ng-container *ngIf="(connectedUser$ | async) as connectedUser">
                            <ng-container *ngIf="!connectedUser">
                                <button class="btn btn-sm btn-app app-primary" [routerLink]="['/administration']">{{"404.Button"|translate}}</button>
                            </ng-container>
                            <ng-container *ngIf="connectedUser">
                                <button class="btn btn-sm btn-app app-primary" [routerLink]="['/administration', connectedUser.tenantId, 'overview']">{{"404.Link"|translate}}</button>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
