import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PexipParticipant, PexipMediaStream } from 'src/app/pexip-participant/pexip-participant.model';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { CustomHttpParamEncoderService } from 'src/app/services/http-encoder/custom-http-param-encoder.service';

@Injectable()
export class PexipParticipantService {
  tenantId: Observable<string> = of('');
  conferenceId: string;

  constructor(private datesManager: DatesManager, private http: HttpClient, private optionService: DataApiOptionService, private route: ActivatedRoute) {
    this.tenantId = <Observable<string>>(
      this.route.parent?.paramMap.pipe(map((params) => params.get('tenantId')))
    );
  }

  getParticipants(conferenceName: string): Observable<PexipParticipant[]> {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoderService(),
    }).set('conferenceId', conferenceName);
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .get<PexipParticipant[]>(
                apiUri + '/conferences/live/participants',
                {
                  params: params,
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => {
                  const participants = [];
                  for (const u in result) {
                    result[u].duration = this.datesManager.calculateDuration(
                      result[u].connectTime as Date
                    );
                    participants.push(<PexipParticipant>result[u]);
                  }
                  return participants;
                }),
                tap((result) => result),
                catchError(
                  this.handleError<PexipParticipant[]>('getParticipants', [])
                )
              );
          })
        );
      })
    );
  }

  getMediaStreams(
    conferenceName: string,
    participantId: string
  ): Observable<PexipMediaStream[]> {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoderService(),
    })
      .set('conferenceId', conferenceName)
      .set('participantId', participantId);
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .get<PexipMediaStream[]>(
                apiUri + '/conferences/live/participants/mediaStreams',
                {
                  params: params,
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => {
                  const mediaStreams = [];
                  for (const u in result) {
                    result[u].startTime = new Date(result[u].startTime);
                    result[u].duration = this.datesManager.calculateDuration(
                      new Date(result[u].startTime)
                    );
                    mediaStreams.push(<PexipMediaStream>result[u]);
                  }
                  return mediaStreams;
                }),
                tap((result) => result),
                catchError(
                  this.handleError<PexipMediaStream[]>('getMediaStreams', [])
                )
              );
          })
        );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
