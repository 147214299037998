<div class="container">
  <div class="row align-items-center p-3">
    <div class="col-auto">
      <h3 class="m-0">
        <span class="is-bold">{{"AddTenantComponent.AddTenant" | translate}}</span>
      </h3>
    </div>
  </div>
  <app-admin-card>
    <form #addTenant="ngForm" id="addTenant" name="addTenant">
      <app-input-form-field [nameInput]="'name'" [typeInput]="'text'" [myInputModel]="newTenant.name" [textLabel]="'AddTenantComponent.Name'" [form]="addTenant" class="form-control" [required]="true">
      </app-input-form-field>
      <app-input-form-field [nameInput]="'description'" [typeInput]="'text'" [myInputModel]="newTenant.description" [textLabel]="'AddTenantComponent.Description'" [form]="addTenant" class="form-control">
      </app-input-form-field>
      <app-input-dropdown-form-field 
        *ngIf="environments" 
        [nameInput]="'environmentId'" 
        [defaultText]="'AddTenantComponent.Environment'" 
        [modelKey]="'id'" 
        [displayKey]="'host'" 
        [myInputModel]="newTenant.environmentId" 
        [selectedElement]="newTenant.environmentId"
        [dataSource]="environments" 
        [form]="addTenant" 
        (selectedData)="newTenant.environmentId=$event.id" 
        class="form-control" 
        [required]="true">
      </app-input-dropdown-form-field>
      <app-input-form-field [nameInput]="'reportEmail'" [typeInput]="'text'" [myInputModel]="newTenant.reportEmail" [textLabel]="'AddTenantComponent.ReportEmail'" [form]="addTenant" class="form-control">
      </app-input-form-field>
      <app-input-dropdown-form-field 
        *ngIf="defaultTenantLanguages" 
        [nameInput]="'defaultLanguage'" 
        [defaultText]="'AddTenantComponent.DefaultLanguage'" 
        [modelKey]="'id'" 
        [displayKey]="'label'" 
        [myInputModel]="newTenant.defaultLanguage" 
        [selectedElement]="newTenant.defaultLanguage"
        [dataSource]="defaultTenantLanguages" 
        [form]="addTenant" 
        (selectedData)="newTenant.defaultLanguage=$event.id" 
        class="form-control" 
        [required]="true">
      </app-input-dropdown-form-field>
      <app-checkbox-form-field class="form-control" [textLabel]="'AddTenantComponent.Support'" [nameInput]="'support'" [myInputModel]="newTenant.support" [checked]="newTenant.support" [form]="addTenant" (checkedEvent)="newTenant.support=$event"></app-checkbox-form-field>
      <app-input-dropdown-form-field 
        *ngIf="defaultTenantAuthProviders" 
        [nameInput]="'authProvider'" 
        [defaultText]="'AddTenantComponent.AuthProvider'" 
        [modelKey]="'id'" 
        [displayKey]="'label'" 
        [myInputModel]="newTenant.authProvider" 
        [selectedElement]="newTenant.authProvider"
        [dataSource]="defaultTenantAuthProviders" 
        [form]="addTenant" 
        (selectedData)="selectAuthProvider($event)" 
        class="form-control mt-3" 
        [required]="true">
      </app-input-dropdown-form-field>
      <ng-container *ngIf="newTenant?.authProvider === 'microsoft'">
        <div class="row mb-3">
          <div class="col-10 offset-1">
            {{'AddTenantComponent.ConfigAuthProvider' | translate}}
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-1"></div>
          <div class="col-5">
            <app-input-field [nameInput]="'authConfigKey'" [typeInput]="'text'" [myInputModel]="newTenant.authConfigKey" [textLabel]="'AddTenantComponent.AuthConfigKey'" [disabled]="true"></app-input-field>
          </div>
          <div class="col-5">
            <app-input-field [nameInput]="'authConfigValue'" [typeInput]="'text'" [myInputModel]="newTenant.authConfigValue" [textLabel]="'AddTenantComponent.AuthConfigValue'" (modelChangeEvent)="newTenant.authConfigValue=$event"></app-input-field>
            <small>{{'AddTenantComponent.MustBeValidFormatUUID' | translate}}</small>
          </div>
        </div>
      </ng-container>
      <div class="dual-button-container mt-3">
        <button class="btn btn-app app-secondary w-100" type="reset" [routerLink]="['../overview']">{{"AddTenantComponent.Cancel" | translate }}</button>
        <app-loading-button (submitEvent)="confirmAddTenant(addTenant)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'AddTenantComponent.Add'" [finalText]="'AddTenantComponent.Added'">
        </app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="addTenant.submitted && !addTenant.valid">{{'AddTenantComponent.SomeFields' | translate}}</div>
    </form>
  </app-admin-card>
</div>
