import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { SharedService } from "src/app/services/shared/shared.service";
import { TemplateMailService } from "src/app/mails/template.service";
import { first, Subscription } from "rxjs";
import { AppConfigService } from "src/app/services/config/app.config.service"
import { TemplateKeysFull, TemplateKeys, TemplateKeysTrial, TemplateKeysKloodApp, TemplateLinks, Mail, MailPreviewContent } from "src/app/mails/template.model";
import { TenantService } from "../tenant/tenant.service";
import { Tenant } from "../tenant/tenant.model";
import { HttpErrorResponse } from "@angular/common/http";
import { ConnectedUser } from "../users/user-connected/user.connected.model";
import { QueryParamsManager } from "../services/query-params-manager/query-params-manager";
import { NotifService } from "src/app/services/notifs/notif.service";

@Component({
	selector: 'app-mails-configuration',
	templateUrl: './mails-configuration.component.html',
	styleUrls: ['./mails-configuration.component.scss'],
	providers: [TenantService, TemplateMailService, QueryParamsManager]
})
export class MailsConfigurationComponent implements OnInit, OnDestroy {

	private onInitSubscriptions: Subscription[] = [];
	currentUser: ConnectedUser;
	administrationTenantId: string;
	tenant: Tenant;
	langs = ["fr-FR", "en-US"];
	lang = "fr-FR";
	mails: string[];
	links = TemplateLinks;
	mail: any;
	preview: any;
	render: any;
	renderUrl: SafeResourceUrl;
	previewUrl: SafeResourceUrl;
	selectedMail: string;
	rendering = false;
	contexts: any[];
	tempContext: string;
	apiUri: string;
	loading = false;

	constructor(private notifService: NotifService, private queryParamsManager: QueryParamsManager, private route: ActivatedRoute, private tenantService: TenantService, private templateMailService: TemplateMailService, private sharedService: SharedService, private configUi: AppConfigService, private urlSanitizer: DomSanitizer) {
		this.apiUri = this.configUi.settings.API_URI;
	}

	ngOnInit(): void {
		this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe((user: ConnectedUser) => {
			this.currentUser = user;
		}));
		if (this.route.parent) {
			this.onInitSubscriptions.push(this.route.parent.params.subscribe((params) => {
				this.administrationTenantId = params["tenantId"];
				this.getTenant(this.administrationTenantId);
			}));
		}
		this.onInitSubscriptions.push(this.route.queryParams.subscribe((params) => {
			if (params["lang"]) {
				this.lang = params["lang"];
				this.switchLang(this.lang);
			}
			if (params["template"]) {
				this.selectedMail = params["template"];
				this.renderMail(this.selectedMail);
			}
		}));
	}

	ngOnDestroy(): void {
		this.onInitSubscriptions.forEach((subscription) => {
			subscription.unsubscribe();
		});
	}

	getTenant(tenantId: string): void {
		this.loading = true;
		this.onInitSubscriptions.push(this.tenantService.getTenant(tenantId).pipe(first()).subscribe({
			next: (tenant: Tenant) => {
				this.loading = false;
				this.tenant = tenant;
				if (this.tenant.alias === "trials") {
					this.mails = TemplateKeysTrial;
				} else if (this.tenant.allowedServices.indexOf("KLOOD_APP") !== -1) {
					this.mails = TemplateKeysKloodApp;
				} else if (this.tenant.allowedServices.indexOf("KLOOD_APP") === -1) {
					this.mails = TemplateKeys;
				} else {
					this.mails = TemplateKeysFull;
				}
			},
			error: (error) => {
				console.log(error)
				this.loading = false;
			}
		}));
	}

	transformValueArrays(data: any[]): any[] {
		let newArray = [];
		for (let el in data) {
			newArray.push({ value: data[el], label: "MailsConfigurationComponent." + data[el] })
		}
		return newArray;
	}

	async switchLang(lang: string): Promise<void> {
		this.lang = lang;
		await this.queryParamsManager.changeQueryParams({ lang: lang });
		if (this.selectedMail) {
			this.renderMail(this.selectedMail);
		}
	}

	getMail(name: string): void {
		if (name) {
			this.loading = true;
			this.rendering = false;
			this.onInitSubscriptions.push(this.templateMailService.getMail(this.administrationTenantId, name, this.lang).pipe(first()).subscribe({
				next: (result: any) => {
					this.loading = false;
					this.selectedMail = name;
					this.mail = result;
					this.previewMail(name, this.mail);
				},
				error: (error: HttpErrorResponse) => {
					this.loading = false;
					this.notifService.addErrorNotif("MailsConfigurationComponent.ErrorGettingTpl", error);
				}
			}));
		}
	}

	confirmEditMail(name: string, mail: Mail): void {
		this.loading = true;
		this.templateMailService.save(this.administrationTenantId, name, this.lang, mail).subscribe({
			next: (result: any) => {
				this.loading = false;
				this.notifService.addSuccessNotif("MailsConfigurationComponent.TplSaved");
				this.getMail(name);
			},
			error: (error: HttpErrorResponse) => {
				this.loading = false;
				this.notifService.addErrorNotif("MailsConfigurationComponent.ErrorSaveTpl", error);
			}
		});
	}

	previewMail(name: string, mail: Mail): void {
		this.loading = true;
		const previewContent: MailPreviewContent = {
			subject: mail.subject,
			content: mail.content,
		};
		this.onInitSubscriptions.push(this.templateMailService.preview(this.administrationTenantId, name, this.lang, previewContent).pipe(first()).subscribe({
			next: (result) => {
				this.loading = false;
				this.preview = result;
				this.previewUrl = this.getBlobUrl(this.preview.content, "text/html");
			},
			error: (error) => {
				this.loading = false;
				this.notifService.addErrorNotif("MailsConfigurationComponent.ErrorRenderTpl", error);
			}
		}));
	}

	getBlobUrl(code: string, type: string): SafeResourceUrl {
		const blob = new Blob([code], { type: type });
		return this.urlSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
	}

	async renderMail(name: string): Promise<void> {
		if (name) {
			await this.queryParamsManager.changeQueryParams({ template: name });
			this.loading = true;
			this.templateMailService.render(this.administrationTenantId, name, this.lang).subscribe({
				next: (result) => {
					this.loading = false;
					this.rendering = true;
					this.preview = null;
					this.selectedMail = name;
					this.mail = result;
					const renderTempUrl = this.apiUri + "/tenants/" + this.administrationTenantId + "/mails/" + name + "/" + this.lang + "/render/content";
					this.renderUrl = this.urlSanitizer.bypassSecurityTrustResourceUrl(renderTempUrl);
				},
				error: (error) => {
					this.loading = false;
					this.selectedMail = name;
					this.notifService.addErrorNotif("MailsConfigurationComponent.ErrorRenderTpl", error);
				}
			});
		}
	}

	confirmDeleteMail(name: string): void {
		this.loading = true;
		this.templateMailService.delete(this.administrationTenantId, name, this.lang).subscribe({
			next: (result) => {
				this.loading = false;
				this.notifService.addSuccessNotif("MailsConfigurationComponent.TplReset");
				this.renderMail(name);
			},
			error: (error) => {
				this.loading = false;
				if (error.status === 404) {
					this.notifService.addErrorNotif("MailsConfigurationComponent.AlreadyDefaultTpl");
				} else {
					this.notifService.addErrorNotif("MailsConfigurationComponent.ErrorResetTpl", error);
				}
			}
		});
	}
}

