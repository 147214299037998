import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { InvitationMailService } from "src/app/mails/invitation.service";
import { IcsKeysApp, Invitation, OutlookKeysApp, TemplateLinks } from "../mails/template.model";
import { Tenant } from "src/app/tenant/tenant.model";
import { AppConfigService } from "src/app/services/config/app.config.service"
import { NotifService } from "src/app/services/notifs/notif.service";
import { SharedService } from "src/app/services/shared/shared.service";
import { TenantService } from "../tenant/tenant.service";
import { ConnectedUser } from "../users/user-connected/user.connected.model";
import { QueryParamsManager } from "../services/query-params-manager/query-params-manager";

@Component({
	selector: 'app-invitations-configuration',
	templateUrl: './invitations-configuration.component.html',
	styleUrls: ['./invitations-configuration.component.scss'],
	providers: [TenantService, InvitationMailService, QueryParamsManager]
})
export class InvitationsConfigurationComponent implements OnInit, OnDestroy {
	editorConfigNoToolbar = {
		language: "fr",
	};
	private lang_user: string;
	currentUser: ConnectedUser;
	tenantId: string;
	tenant: Tenant;
	langs = ["fr-FR", "en-US"];
	lang = "fr-FR";
	apiUri: string;
	icsInvitations: any[];
	outlookInvitations: any[];
	links = TemplateLinks;
	selectedInvitation: string | null;
	invitation: any;
	preview: any;
	render: any;
	renderUrl: SafeResourceUrl;
	previewUrl: SafeResourceUrl;
	rendering = false;
	private onInitSubscriptions: Subscription[] = [];
	loading = false;
	selectedTabIndex = 0;

	constructor(private queryParamsManager: QueryParamsManager, private route: ActivatedRoute, private notifService: NotifService, private tenantService: TenantService, private invitationMailService: InvitationMailService, private sharedService: SharedService, private configUi: AppConfigService, private urlSanitizer: DomSanitizer) {
		this.apiUri = this.configUi.settings.API_URI;
	}

	ngOnInit(): void {
		this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe((user: ConnectedUser) => {
			if (user) {
				this.currentUser = user;
				this.lang_user = this.currentUser.lang;
			}
		}));
		if (this.route.parent) {
			this.onInitSubscriptions.push(this.route.parent.params.subscribe((params) => {
				this.tenantId = params["tenantId"];
				this.getTenant(this.tenantId);
			}));
		}
		this.onInitSubscriptions.push(this.route.queryParams.subscribe((params) => {
			if (params["lang"]) {
				this.lang = params["lang"];
				this.switchLang(this.lang);
			}
			if (params["template"]) {
				this.selectedInvitation = params["template"];
				this.renderInvitation(this.selectedInvitation);
			}
			if (params["tabIndex"]) {
				this.selectedTabIndex = params["tabIndex"];
			}
		}));
	}

	ngOnDestroy(): void {
		this.onInitSubscriptions.forEach((subscription) => {
			subscription.unsubscribe();
		});
	}

	getTenant(tenantId: string): void {
		this.loading = true;
		this.tenantService.getTenant(tenantId).subscribe({
			next: (tenant: Tenant) => {
				this.loading = false;
				this.tenant = tenant;
				if (this.tenant.alias === "trials") {
					this.icsInvitations = IcsKeysApp;
					this.outlookInvitations = OutlookKeysApp;
				} else if (this.tenant.allowedServices.indexOf("KLOOD_APP") !== -1) {
					this.icsInvitations = IcsKeysApp;
					this.outlookInvitations = OutlookKeysApp;
				} else {
					this.icsInvitations = [];
					this.outlookInvitations = [];
				}
			},
			error: (error: HttpErrorResponse) => {
				console.log(error)
				this.loading = false;
			}
		});
	}

	transformValueArrays(data: any[]): any[] {
		let newArray = [];
		for (let el in data) {
			newArray.push({ value: data[el], label: "InvitationsConfigurationComponent." + data[el] })
		}
		return newArray;
	}

	async switchLang(lang: string): Promise<void> {
		this.lang = lang;
		await this.queryParamsManager.changeQueryParams({ lang: lang });
		if (this.selectedInvitation) {
			this.renderInvitation(this.selectedInvitation);
		}
	}

	getInvitation(name: string | null): void {
		if (name) {
			this.loading = true;
			this.rendering = false;
			this.invitationMailService.getInvitation(this.tenantId, name, this.lang).subscribe({
				next: (result: any) => {
					this.loading = false;
					this.selectedInvitation = name;
					this.invitation = result;
					this.previewInvitation(name, this.invitation);
				},
				error: (error: HttpErrorResponse) => {
					this.loading = false;
					console.log(error);
					this.notifService.addErrorNotif("error getting invitation", error);
				}
			});
		}
	}

	async switchTab(event: any): Promise<void> {
		await this.queryParamsManager.changeQueryParams({ tabIndex: event.index, template: null, lang: null });
		this.selectedInvitation = null;
		this.invitation = null;
	}

	confirmEditInvitation(name: string | null, invitation: Invitation): void {
		this.loading = true;
		const content = invitation.content;
		this.invitationMailService.save(this.tenantId, name as string, this.lang, content).subscribe({
			next: (result) => {
				this.loading = false;
				this.notifService.addSuccessNotif("invitation saved");
				this.getInvitation(name);
			},
			error: (error) => {
				this.loading = false;
				this.notifService.addErrorNotif("error save invitation", error);
			}
		});
	}

	previewInvitation(name: string | null, invitation: Invitation): void {
		this.loading = true;
		const content = invitation.content;
		this.invitationMailService.preview(this.tenantId, name as string, this.lang, content).subscribe({
			next: (result) => {
				this.loading = false;
				this.preview = result;
				this.previewUrl = this.getBlobUrl(this.preview, "text/html");
			},
			error: (error) => {
				this.loading = false;
				this.notifService.addErrorNotif("error render invitation", error);
			}
		});
	}

	getBlobUrl(code: string, type: string): SafeResourceUrl {
		const blob = new Blob([code], { type: type });
		return this.urlSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
	}

	async renderInvitation(name: string | null): Promise<void> {
		if (name) {
			await this.queryParamsManager.changeQueryParams({ template: name });
			this.loading = true;
			this.invitationMailService.render(this.tenantId, name, this.lang).subscribe({
				next: (result) => {
					this.loading = false;
					this.rendering = true;
					this.preview = null;
					this.selectedInvitation = name;
					this.invitation = result;
					this.renderUrl = this.getBlobUrl(this.invitation, "text/html");
				},
				error: (error) => {
					this.loading = false;
					this.selectedInvitation = name;
					this.notifService.addErrorNotif("error render invitation", error);
				}
			});
		}
	}

	confirmDeleteInvitation(name: string | null): void {
		this.loading = true;
		this.invitationMailService.delete(this.tenantId, name as string, this.lang).subscribe({
			next: (result) => {
				this.loading = false;
				this.notifService.addSuccessNotif("invitation reset");
				this.renderInvitation(name);
			},
			error: (error) => {
				this.loading = false;
				if (error.status === 404) {
					this.notifService.addErrorNotif("already default invitation");
				} else {
					this.notifService.addErrorNotif("error reset invitation", error);
				}
			}
		});
	}
}

