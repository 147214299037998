import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { Offer } from '../offer.model';
import { OfferViewService } from './offer-view.service';

@Component({
  selector: 'app-offer-view',
  templateUrl: './offer-view.component.html',
  styleUrls: ['./offer-view.component.scss'],
  providers: [OfferViewService]
})
export class OfferViewComponent implements OnInit {
  @Input() connectedUser: ConnectedUser;
  @Input() tenant: Tenant;
  @Input() offer: Offer;
  @Input() kind: string;
  @Input() renewOffer: boolean;
  @Output() cancelRenew = new EventEmitter<boolean>();
  nowDate = new Date();
  renewDate: Date;
  preivousOffer: Offer;
  kloodDateFormat = KloodDateFormat;

  constructor(private notif: NotifService, private translate: TranslateService, private offerService: OfferViewService) { }

  ngOnInit(): void {
    this.renewDate = this.offer.expiresAt;
  }

  checkExpired(): number {
    if (new Date().getTime() > new Date(this.offer.expiresAt).getTime()) {
      return -1;
    } else {
      if (0 < new Date(this.offer.expiresAt).getTime() - new Date().getTime() && new Date(this.offer.expiresAt).getTime() - new Date().getTime() <= 1296000000) {
        return 15;
      } else if (1296000000 < new Date(this.offer.expiresAt).getTime() - new Date().getTime() && new Date(this.offer.expiresAt).getTime() - new Date().getTime() <= 2592000000) {
        return 30;
      }
      return 0;
    }
  }

  editCoOrganization(active: boolean, kind: string, offer: Offer, tenant: Tenant): void {
    const translateValueParameter = this.translate.instant('OfferFormComponent.Offer.' + kind);
    offer.coOrganization = active;
    tenant.offers[kind] = offer;
    this.offerService.saveTenant(tenant).subscribe({
      next: (tenant: Tenant) => {
        this.offer = tenant.offers[kind];
        const successNotif = this.translate.instant('OfferFormComponent.SUCCESS.Edit', { value: translateValueParameter });
        this.notif.addSuccessNotif(successNotif);
      },
      error: (error) => {
        const errorNotif = this.translate.instant('OfferFormComponent.ERROR.Edit', { value: translateValueParameter });
        this.notif.addErrorNotif(errorNotif, error);
        this.offer.coOrganization = !active;
        this.cancel(offer);
      }
    });
  }

  onDateSelect(date: Date): void {
    this.renewDate = new Date(date.toISOString());
  }

  renew(renewDate: Date, offer: Offer, kind: string, tenant: Tenant): void {
    const translateValueParameter = this.translate.instant('OfferFormComponent.Offer.' + kind);
    tenant.offers[kind].expiresAt = renewDate;
    this.offerService.saveTenant(tenant).subscribe({
      next: (tenant: Tenant) => {
        this.offer = tenant.offers[kind];
        const successNotif = this.translate.instant('OfferFormComponent.SUCCESS.Renew', { value: translateValueParameter });
        this.notif.addSuccessNotif(successNotif);
        this.cancel();
      },
      error: (error) => {
        const errorNotif = this.translate.instant('OfferFormComponent.ERROR.Renew', { value: translateValueParameter });
        this.notif.addErrorNotif(errorNotif, error);
        this.cancel(offer);
      }
    });
  }

  cancel(previous?: Offer): void {
    if (previous) {
      this.offer = previous;
    }
    this.renewDate = this.offer.expiresAt;
    this.cancelRenew.emit(true);
  }
}
