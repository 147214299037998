import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { DatesManager } from '../services/dates/dates.manager';
import { LiveConference } from './live-conference.model';

@Injectable()
export class LiveConferenceService {
  tenantId: Observable<string> = of('');

  constructor(
    private datesManager: DatesManager,
    private http: HttpClient,
    private optionService: DataApiOptionService,
    private route: ActivatedRoute
  ) {
    this.tenantId = <Observable<string>>(
      this.route.parent?.paramMap.pipe(map((params) => params.get('tenantId')))
    );
  }

  getLiveConferences(): Observable<LiveConference[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .get<LiveConference[]>(apiUri + '/conferences/live', {
                headers: this.optionService.getHeaders(),
                withCredentials: true,
              })
              .pipe(
                map((result) => {
                  const liveConferences = [];
                  for (const u in result) {
                    result[u].startedAt = new Date(result[u].startedAt);
                    result[u].duration = this.datesManager.calculateDuration(
                      result[u].startedAt
                    );
                    liveConferences.push(<LiveConference>result[u]);
                  }
                  return liveConferences;
                }),
                tap((result) => result),
                catchError(
                  this.handleError<LiveConference[]>('getLiveConferences', [])
                )
              );
          })
        );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
