import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { ServerInfos } from 'src/app/server-infos/server.infos.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UrlGeneratorService } from 'src/app/services/url-generator/url-generator.service';
import { AppConsent, ConsentResult, Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { ValidatorsList } from 'src/app/validators/validators.model';
import { ConsentApplicationService } from './consent-application.service';

@Component({
  selector: 'app-consent-application',
  templateUrl: './consent-application.component.html',
  styleUrls: ['./consent-application.component.scss'],
  providers: [ConsentApplicationService]
})
export class ConsentApplicationComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  tenant$: Observable<Tenant>;
  serverInfos$: Observable<ServerInfos>;
  askConsent: boolean;
  adminEmail: string | null;
  selectedConsent: string | null;
  validatorsList = ValidatorsList;
  loadingResult = false;
  showResult = false;
  selectedResult: string;
  errors: any;

  constructor(private route: ActivatedRoute, private consentService: ConsentApplicationService, private sharedService: SharedService, private notif: NotifService, private urlGenerator: UrlGeneratorService) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe((connectedUser: ConnectedUser) => {
      if (connectedUser) {
        this.connectedUser = connectedUser;
      }
    }
    ));
    if (this.route.parent) {
      this.onInitSubscriptions.push(this.route.parent.paramMap.subscribe((params) => {
        if (params.get('tenantId')) {
          this.getTenant(params.get('tenantId') as string);
        }
      }));
    }
    this.getServerInfos();
    try {
      const bc = new BroadcastChannel('consentingApp');
      bc.onmessage = (ev) => {
        if (ev.data) {
          this.showingResult(ev.data);
        }
      };
      window.addEventListener('focus', () => true);
    } catch {
      console.log("browser is safari and can't use BroadcastChannel");
    }
  }

  getTenant(tenantId: string): void {
    this.tenant$ = this.consentService.getTenant(tenantId).pipe(catchError(this.handleError<Tenant>('getTenant')),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  getServerInfos(): void {
    this.serverInfos$ = this.consentService.getServerInfos().pipe(catchError(this.handleError<ServerInfos>('getServerInfos')),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  showingResult(data: ConsentResult): void {
    this.selectedResult = data.appName;
    if (data.error) {
      this.errors = data.error;
    }
    this.showResult = true;
    this.loadingResult = false;
  }

  closeResult(): void {
    this.showResult = false;
    this.selectedResult = '';
    this.errors = null;
  }

  cancelConsent(): void {
    this.askConsent = false;
    this.selectedConsent = null;
    this.adminEmail = null;
  }

  consentApp(tenant: Tenant, type: string, app?: AppConsent): void {
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      this.notif.addSuccessNotif('ConsentApplicationComponent.BrowserNotCompatible');
    } else {
      this.showResult = false;
      this.selectedResult = type;
      this.errors = null;
      this.loadingResult = true;
      const redirectUri = this.urlGenerator.buildUrl('/administration/' + tenant.id + '/waiting-consent/' + type);
      window.open(this.consentService.consentApp(tenant, redirectUri, type, app), 'dialog', 'width=1200, height=900');
    }
  }

  askConsentApp(email: string, tenant: Tenant, type: string, app?: AppConsent): void {
    const redirectUri = this.urlGenerator.buildUrl('/administration/' + tenant.id + '/app-consented/' + type);
    this.consentService.askConsentApp(tenant, redirectUri, email, type, app).subscribe({
      next: (result) => {
        this.notif.addSuccessNotif('ConsentApplicationComponent.Email.Sent.SUCCESS');
        this.cancelConsent();
      },
      error: (error) => {
        this.notif.addErrorNotif('ConsentApplicationComponent.Email.Send.ERROR', error);
      }
    });
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
