import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-border-bloc',
  templateUrl: './admin-border-bloc.component.html',
  styleUrls: ['./admin-border-bloc.component.scss']
})
export class AdminBorderBlocComponent {

  constructor() { }

}
