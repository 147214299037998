<div class="row align-items-center">
    <div class="col-auto my-auto pe-0">
        <span>{{textLabel | translate}}</span>
    </div>
    <div class="col-auto">
        <label for="{{nameInput}}">
            <input
                class="left-align-input"
                #inputModel="ngModel"
                matInput
                [matDatepicker]="picker"
                name="{{nameInput}}"
                placeholder="{{textLabel | translate}}"
                [(ngModel)]="myInputModel"
                (ngModelChange)="checkDate($event)"
                [min]="nowDate"
                [required]="required"
                readonly
            />
            <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
            <mat-datepicker #picker (opened)="changeCalendarClass();"></mat-datepicker>
        </label>
    </div>
</div>

