<div class="row my-3">
    <div class="col-12">
        <h1>{{"LiveConferencesComponent.LiveConferences" | translate}}</h1>
    </div>
</div>
<app-admin-bloc [blocTitle]="'PexipParticipantComponent.MediaStreamDetails'" [blocControls]="controls" (requestAction)="closeAction($event)">
    <div class="row h-100 d-flex justify-content-center align-items-center" *ngIf="loading">
        <div class="col-auto my-auto">
            <app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
        </div>
    </div>
    <div class="row" *ngIf="!loading">
        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <app-admin-infos-bloc [blocTitle]="'PexipParticipantComponent.Participant'">
                        <ng-container *ngIf="!currentParticipant">
                            {{"PexipParticipantComponent.NoInfo" | translate}}
                        </ng-container>
                        <ng-container *ngIf="currentParticipant">
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">{{"PexipParticipantComponent.ParticipantAlias" | translate}} :</div>
                                <div class="col">{{currentParticipant.participantAlias}}</div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">{{"PexipParticipantComponent.DisplayName" | translate}} :</div>
                                <div class="col">{{currentParticipant.displayName}}</div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">{{"PexipParticipantComponent.ConnectTime" | translate}} :</div>
                                <div class="col">{{currentParticipant.connectTime | date:kloodDateFormat.pipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}</div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">{{"PexipParticipantComponent.CallQuality" | translate}} :</div>
                                <div class="col">{{currentParticipant.callQuality}}</div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">{{"PexipParticipantComponent.Duration" | translate}} :</div>
                                <div class="col">{{currentParticipant.duration}}</div>
                            </div>
                        </ng-container>
                    </app-admin-infos-bloc>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-admin-infos-bloc [blocTitle]="'PexipParticipantComponent.AudioStream'">
                        <ng-container *ngIf="!mediaStreamAudio">
                            {{"PexipParticipantComponent.NoInfo" | translate}}
                        </ng-container>
                        <ng-container *ngIf="mediaStreamAudio">
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.node" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.node}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.startTime" | translate }} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.startTime | date:kloodDateFormat.pipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.duration" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.duration}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxCodec" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.rxCodec}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxJitter" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.rxJitter}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxPacketLoss" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.rxPacketLoss}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxPacketsLost" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.rxPacketsLost}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxPacketsReceived" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.rxPacketsReceived}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxBitrate" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.rxBitrate}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txCodec" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.txCodec}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txJitter" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.txJitter}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txPacketLoss" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.txPacketLoss}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txPacketsLost" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.txPacketsLost}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txPacketsSent" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.txPacketsSent}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txBitrate" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamAudio.txBitrate}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txResolution" | translate}} :
                                </div>
                                <div class="col align-items-center">
                                    {{mediaStreamAudio.txResolution}}
                                </div>
                            </div>
                        </ng-container>
                    </app-admin-infos-bloc>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <app-admin-infos-bloc [blocTitle]="'PexipParticipantComponent.VideoStream'">
                        <ng-container *ngIf="!mediaStreamVideo">
                            {{"PexipParticipantComponent.NoInfo" | translate}}
                        </ng-container>
                        <ng-container *ngIf="mediaStreamVideo">
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.node" | translate}} :
                                </div>
                                <div class="col align-items-center">
                                    {{mediaStreamVideo.node}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.startTime" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.startTime | date:kloodDateFormat.pipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.duration" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.duration}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxCodec" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.rxCodec}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxJitter" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.rxJitter}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxPacketLoss" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.rxPacketLoss}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxPacketsLost" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.rxPacketsLost}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxPacketsReceived" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.rxPacketsReceived}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxBitrate" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.rxBitrate}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txCodec" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.txCodec}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txJitter" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.txJitter}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txPacketLoss" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.txPacketLoss}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txPacketsLost" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.txPacketsLost}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txPacketsSent" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.txPacketsSent}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txBitrate" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.txBitrate}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txResolution" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamVideo.txResolution}}
                                </div>
                            </div>
                        </ng-container>
                    </app-admin-infos-bloc>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-admin-infos-bloc [blocTitle]="'PexipParticipantComponent.PresentationStream'">
                        <ng-container *ngIf="!mediaStreamPresentation">
                            {{"PexipParticipantComponent.NoInfo" | translate}}
                        </ng-container>
                        <ng-container *ngIf="mediaStreamPresentation">
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.node" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.node}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.startTime" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.startTime | date:kloodDateFormat.pipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.duration" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.duration}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxCodec" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.rxCodec}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxJitter" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.rxJitter}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxPacketLoss" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.rxPacketLoss}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxPacketsLost" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.rxPacketsLost}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxPacketsReceived" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.rxPacketsReceived}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.rxBitrate" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.rxBitrate}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txCodec" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.txCodec}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txJitter" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.txJitter}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txPacketLoss" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.txPacketLoss}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txPacketsLost" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.txPacketsLost}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txPacketsSent" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.txPacketsSent}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txBitrate" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.txBitrate}}
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-auto is-bold pe-0">
                                    {{"PexipParticipantComponent.txResolution" | translate}} :
                                </div>
                                <div class="col">
                                    {{mediaStreamPresentation.txResolution}}
                                </div>
                            </div>
                        </ng-container>
                    </app-admin-infos-bloc>
                </div>
            </div>
        </div>
    </div>
</app-admin-bloc>