<form #addEndpointForm="ngForm" id="addEndpointForm" name="addEndpointForm" class="form-control">
  <div class="row align-items-center my-3">
    <div class="col">
      <app-input-dropdown-form-field [nameInput]="'AddEndpoint.Kind'" [defaultText]="'AddEndpoint.Kind'" [modelKey]="'value'" [displayKey]="'label'" [selectedElement]="newEndpoint.kind" [myInputModel]="newEndpoint.kind" [dataSource]="endpointKinds" (selectedData)="onSelectEndpointKind($event)" [required]="true" [form]="addEndpointForm"></app-input-dropdown-form-field>
    </div>
    <div class="col-auto pe-0">
      <app-checkbox-form-field [nameInput]="'AddEndpoint.Polling'" [form]="addEndpointForm" [checked]="newEndpoint.active" [myInputModel]="newEndpoint.active" (checkedEvent)="newEndpoint.active=$event"></app-checkbox-form-field>
    </div>
    <div class="col ps-0">
      {{"AddEndpoint.Polling" | translate}}
    </div>
  </div>
  <div class="is-bold mb-3" *ngIf="newEndpoint.kind  === 'CISCO' || newEndpoint.kind === 'OTHER'">{{"AddEndpoint.Configuration" | translate}}</div>
  <ng-container *ngIf="newEndpoint.kind==='CISCO'">
    <app-input-form-field class="form-control" [textLabel]="'AddEndpoint.Ip'" [typeInput]="'text'" [nameInput]="'AddEndpoint.Number'" [form]="addEndpointForm" [myInputModel]="newEndpoint.config.ip" [required]="true" (modelChangeEvent)="newEndpoint.config.ip=$event"></app-input-form-field>
    <app-input-form-field class="form-control" [textLabel]="'AddEndpoint.Username'" [typeInput]="'text'" [nameInput]="'AddEndpoint.Username'" [form]="addEndpointForm" [myInputModel]="newEndpoint.config.username" [required]="true" (modelChangeEvent)="newEndpoint.config.username=$event"></app-input-form-field>
    <app-input-form-field class="form-control" [textLabel]="'AddEndpoint.Password'" [typeInput]="'text'" [nameInput]="'AddEndpoint.Password'" [form]="addEndpointForm" [myInputModel]="newEndpoint.config.password" [required]="true" (modelChangeEvent)="newEndpoint.config.password=$event"></app-input-form-field>
    <app-input-dropdown-form-field [nameInput]="'AddEndpoint.Scheme'" [defaultText]="'AddEndpoint.Scheme'" [modelKey]="'value'" [displayKey]="'label'" [selectedElement]="newEndpoint.config.scheme" [myInputModel]="newEndpoint.config.scheme" [dataSource]="endpointConfigSchemes" class="form-control" (selectedData)="newEndpoint.config.scheme=$event.value" [required]="true" [form]="addEndpointForm"></app-input-dropdown-form-field>
    <app-input-form-field class="form-control" [textLabel]="'AddEndpoint.Port'" [typeInput]="'number'" [nameInput]="'AddEndpoint.Port'" [form]="addEndpointForm" [myInputModel]="newEndpoint.config.port" [required]="true" (modelChangeEvent)="newEndpoint.config.port=$event"></app-input-form-field>
    <div class="row align-items-center">
      <div class="col-auto">
        <app-checkbox-form-field [nameInput]="'AddEndpoint.CheckCertificates'" [form]="addEndpointForm" [checked]="newEndpoint.config.checkCertificates" [myInputModel]="newEndpoint.config.checkCertificates" (checkedEvent)="newEndpoint.config.checkCertificates=$event"></app-checkbox-form-field>
      </div>
      <div class="col">
        {{"AddEndpoint.CheckCertificates" | translate}}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="newEndpoint.kind==='OTHER'">
    <app-input-form-field class="form-control" [textLabel]="'AddEndpoint.DialString'" [typeInput]="'text'" [nameInput]="'AddEndpoint.DialString'" [form]="addEndpointForm" [myInputModel]="newEndpoint.config.dialString" [required]="true" (modelChangeEvent)="newEndpoint.config.dialString=$event"></app-input-form-field>
  </ng-container>
  <ng-container>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary width-100" type="reset" (click)="cancelAddEndpoint.emit(false)">{{"AddEndpoint.Cancel" | translate}}</button>
      <app-loading-button *ngIf="newEndpoint.kind" (submitEvent)="addEndpoint(addEndpointForm)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'AddEndpoint.Valid'" [finalText]="'AddEndpoint.Validate'"></app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="addEndpointForm.submitted && !addEndpointForm.valid">{{'AddEndpoint.SomeFields' | translate}}</div>
  </ng-container>
</form>