import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-dropdown-field',
  templateUrl: './input-dropdown-field.component.html',
  styleUrls: ['./input-dropdown-field.component.scss'],
})
export class InputDropdownFieldComponent implements AfterViewInit {
  @Input() dataSource: any[];
  @Input() displayKey: string;
  @Input() modelKey: string;
  @Input() selectedElement: any;
  @Input() defaultText: string;
  @Input() disabled = false;
  @Output() selectedData = new EventEmitter<any>();
  showIcon = false;
  firstUse = false;

  constructor(private eRef: ElementRef) { }

  ngAfterViewInit(): void {
    $('.dropdown-menu').on('click', () => {
      this.showIcon = !this.showIcon;
      this.firstUse = true;
    });
  }

  switchIcon(): void {
    this.showIcon = !this.showIcon;
    this.firstUse = true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (this.eRef.nativeElement.contains(event.target) && this.showIcon) {
      this.showIcon = true;
    } else {
      this.showIcon = false;
    }
  }

  selectData(data: any): void {
    this.selectedElement = data[this.modelKey];
    this.selectedData.emit(data);
  }
}
