import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Controls } from 'src/app/commons/toolbar/toolbar.model';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';
import { ExportService } from 'src/app/services/export/export.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { MeetingHistory } from '../history-meeting.model';

declare var navigator: any;

@Component({
	selector: 'app-details-history-meetings',
	templateUrl: './details-history-meetings.component.html',
	styleUrls: ['./details-history-meetings.component.scss'],
	providers: [ExportService]
})
export class DetailsHistoryMeetingsComponent {

	@Input() selectedHistoryMeeting: MeetingHistory;
	@Input() currentConnectedUser: ConnectedUser;
	@Output() closeDetailsEvent = new EventEmitter<string>();
	kloodDateFormat = KloodDateFormat;
	controls: Controls[] = [{
		icon: 'file-csv',
		doubleIcon: false,
		secondIcon: 'file-csv',
		controleType: 'ACTION',
		action: 'EXPORT',
		tooltip: 'DetailsHistoryMeetings.ExportParticipants',
		isSwitch: false
	}]

	constructor(private exportService: ExportService, private datePipe: DatePipe) { }

	onRequestAction(action?: string): void {
		this.closeDetailsEvent.emit(action);
	}

	exportParticipantsHistoricMeetings(): void {
		const fields = "displayName,startTime,endTime,protocol,remoteAddress,systemLocation,signallingNode,mediaNode,callQuality,role";
		const url = "/conferences/history/" + this.selectedHistoryMeeting.id + "/participants";
		this.exportService.exportData(url, fields).subscribe({
			next: (result: string) => {
				this.downloadExport(result);
			},
			error: (error) => {
				console.log(error)
			}
		});
	}

	downloadExport(exported: string): void {
		const startDate = this.datePipe.transform(new Date(this.selectedHistoryMeeting.startedAt), this.kloodDateFormat.pipe[this.currentConnectedUser.lang], undefined, this.kloodDateFormat.locale[this.currentConnectedUser.lang])?.replace(":", "h");
		const endDate = this.datePipe.transform(new Date(this.selectedHistoryMeeting.terminatedAt), this.kloodDateFormat.pipe[this.currentConnectedUser.lang], undefined, this.kloodDateFormat.locale[this.currentConnectedUser.lang])?.replace(":", "h");
		const exportFilename = "export des participants de la reunion " + this.selectedHistoryMeeting.alias + " de " + startDate + " à " + endDate + ".csv";
		const csvData = new Blob([exported], { type: "text/csv;charset=utf-8;" });
		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(csvData, exportFilename);
		} else {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(csvData);
			link.setAttribute("download", exportFilename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
}
