import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { NotifService } from '../services/notifs/notif.service';
import { SharedService } from '../services/shared/shared.service';
import { Tenant } from '../tenant/tenant.model';
import { ConnectedUser } from '../users/user-connected/user.connected.model';
import { VisioService } from './visio.service';

@Component({
  selector: 'app-visio',
  templateUrl: './visio.component.html',
  styleUrls: ['./visio.component.scss'],
  providers: [VisioService]
})
export class VisioComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  tenant$: Observable<Tenant>;
  limitParticipant: number;
  finished = false;
  hasError = false;

  constructor(
    private route: ActivatedRoute,
    private visioService: VisioService,
    private sharedService: SharedService,
    private notif: NotifService
  ) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(
      this.sharedService.currentConnectedUser.subscribe(
        (connectedUser: ConnectedUser) => {
          if (connectedUser) {
            this.connectedUser = connectedUser;
          }
        }
      )
    );
    if (this.route.parent) {
      this.onInitSubscriptions.push(
        this.route.parent.paramMap.subscribe((params) => {
          if (params.get('tenantId')) {
            this.getTenant(params.get('tenantId') as string);
          }
        })
      );
    }
  }

  getTenant(tenantId: string): void {
    this.tenant$ = this.visioService.getTenant(tenantId).pipe(
      catchError(this.handleError<Tenant>('getTenant')),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  switchTchatOption(enableOption: boolean, tenant: Tenant): void {
    tenant.disableChat = !enableOption;
    if (tenant.disableChat) {
      this.editTenant(
        tenant,
        'VisioComponent.Options.Chat.Disable.SUCCESS',
        'VisioComponent.Options.Chat.Disable.ERROR',
        'CHAT'
      );
    } else {
      this.editTenant(
        tenant,
        'VisioComponent.Options.Chat.Enable.SUCCESS',
        'VisioComponent.Options.Chat.Enable.ERROR',
        'CHAT'
      );
    }
  }

  switchThumbnailOption(enableOption: boolean, tenant: Tenant): void {
    tenant.enableOverlayText = enableOption;
    if (tenant.enableOverlayText) {
      this.editTenant(
        tenant,
        'VisioComponent.Options.Thumbnails.Enable.SUCCESS',
        'VisioComponent.Options.Thumbnails.Enable.SUCCESS',
        'THUMBNAILS'
      );
    } else {
      this.editTenant(
        tenant,
        'VisioComponent.Options.Thumbnails.Disable.SUCCESS',
        'VisioComponent.Options.Thumbnails.Disable.SUCCESS',
        'THUMBNAILS'
      );
    }
  }

  editTenant(
    tenant: Tenant,
    succesNotif: string,
    errorNotif: string,
    option: 'CHAT' | 'THUMBNAILS'
  ): void {
    if (this.connectedUser.hasRole('SUPER_ADMIN')) {
      this.saveTenant(tenant, succesNotif, errorNotif, option);
    } else {
      this.saveInfos(tenant, succesNotif, errorNotif, option);
    }
  }

  saveTenant(
    tenant: Tenant,
    succesNotif: string,
    errorNotif: string,
    option: 'CHAT' | 'THUMBNAILS'
  ): void {
    this.visioService.save(tenant).subscribe({
      next: (tenant: Tenant) => {
        this.notif.addSuccessNotif(succesNotif);
      },
      error: (error) => {
        if (option === 'CHAT') {
          tenant.disableChat = !tenant.disableChat;
        }
        if (option === 'THUMBNAILS') {
          tenant.enableOverlayText = !tenant.enableOverlayText;
        }
        this.notif.addErrorNotif(errorNotif, error);
      }
    });
  }

  saveInfos(
    tenant: Tenant,
    succesNotif: string,
    errorNotif: string,
    option: 'CHAT' | 'THUMBNAILS'
  ): void {
    this.visioService.saveSettings(tenant).subscribe({
      next: (result: any) => {
        this.notif.addSuccessNotif(succesNotif);
      },
      error: (error) => {
        if (option === 'CHAT') {
          tenant.disableChat = !tenant.disableChat;
        }
        if (option === 'THUMBNAILS') {
          tenant.enableOverlayText = !tenant.enableOverlayText;
        }
        this.notif.addErrorNotif(errorNotif, error);
      }
    });
  }

  saveParticipantLimit(tenant: Tenant, limit: number): void {
    const previsousLimit = tenant.participantLimit;
    if (limit) {
      tenant.participantLimit = +limit;
    }
    this.visioService.save(tenant).subscribe({
      next: (result) => {
        this.notif.addSuccessNotif(
          'VisioComponent.ParticipantsLimit.Update.ERROR'
        );
        this.finished = true;
      },
      error: (error) => {
        this.notif.addErrorNotif(
          'VisioComponent.ParticipantsLimit.Update.ERROR',
          error
        );
        this.finished = true;
        this.hasError = true;
        tenant.participantLimit = previsousLimit;
      }
    });
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
