<div class="row my-3">
    <div class="col-12">
        <h1>{{"LiveConferencesComponent.LiveConferences" | translate}}</h1>
    </div>
</div>
<app-admin-bloc [blocTitle]="'DetailsLiveConferenceComponent.ConferenceDetails'" [blocControls]="controls" (requestAction)="closeAction($event)">
    <app-admin-details-bloc [closeControl]="false">
        <div class="row h-100 d-flex justify-content-center align-items-center" *ngIf="loading">
            <div class="col-auto my-auto">
                <app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
            </div>
        </div>
        <div *ngIf="!loading">
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsLiveConferenceComponent.name" | translate}}</div>
                <div class="col">{{currentConference.name}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsLiveConferenceComponent.serviceType" | translate}}</div>
                <div class="col">{{currentConference.serviceType}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsLiveConferenceComponent.duration" | translate}}</div>
                <div class="col">{{currentConference.duration}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsLiveConferenceComponent.tag.alias" | translate}}</div>
                <div class="col">{{currentConference.tag?.alias}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsLiveConferenceComponent.tag.offerType" | translate}}</div>
                <div class="col">{{currentConference.tag?.offerType}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsLiveConferenceComponent.participantCount" | translate}}</div>
                <div class="col">{{currentConference.participantCount}}</div>
            </div>
        </div>
    </app-admin-details-bloc>
    <app-admin-bloc [blocTitle]="'DetailsLiveConferenceComponent.Participants'">
        <app-mat-table-custom *ngIf="displayedColumns && participants" [dataSource]="participants" [loading]="loadingParticipants" [componentName]="'DetailsLiveConferenceComponent'" [displayedColumns]="displayedColumns" [defaultSort]="defaultSort" (dataError)="onDataError($event)" (rowClick)="onRowClick($event)"></app-mat-table-custom>
    </app-admin-bloc>
</app-admin-bloc>
