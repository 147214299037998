<app-admin-bloc [blocTitle]="'Edit'">
	<form #editOauthClient="ngForm" id="editOauthClient" name="editOauthClient">
		<app-input-form-field [nameInput]="'name'" [typeInput]="'text'" [myInputModel]="currentOauthClient.name" [textLabel]="'EditOauthClientComponent.Name'" [form]="editOauthClient" class="form-control">
		</app-input-form-field>
		<app-input-form-field [nameInput]="'description'" [typeInput]="'text'" [myInputModel]="currentOauthClient.description" [textLabel]="'EditOauthClientComponent.Description'" [form]="editOauthClient" class="form-control">
		</app-input-form-field>
		<app-input-form-field [nameInput]="'secret'" [typeInput]="'text'" [myInputModel]="currentOauthClient.secret" [textLabel]="'EditOauthClientComponent.Secret'" [form]="editOauthClient" class="form-control" [required]="true">
		</app-input-form-field>
		<app-input-dropdown-form-field *ngIf="tenants" [nameInput]="'target'" [defaultText]="'EditOauthClientComponent.TargetTenant'"
		[modelKey]="'id'" [displayKey]="'name'" [myInputModel]="currentOauthClient.target" [dataSource]="tenants" [form]="editOauthClient" (selectedData)="selectTenant($event)"
		class="form-control" [required]="true"></app-input-dropdown-form-field>
		<ng-container *ngIf="currentOauthClient.target">
			<div class="row">
				<div class="col-4">
					<button class="btn btn-app app-primary w-100 margin-bottom-20" type="button" (click)="swicthAllReadAccess()">{{"EditOauthClientComponent.AllReadAccess" | translate }}</button>
				</div>
				<div class="col-4">
					<button class="btn btn-app app-primary w-100 margin-bottom-20" type="button" (click)="swicthAllReadWriteAccess()">{{"EditOauthClientComponent.AllReadWriteAccess" | translate }}</button>
				</div>
				<div class="col-4">
					<button class="btn btn-app app-secondary w-100 margin-bottom-20" type="button" (click)="removeAllAccesses()">{{"EditOauthClientComponent.RemoveAllAccess" | translate }}</button>
				</div>
			</div>
			<div class="row">
				<div class="col-12" *ngFor="let access of accessList; let i = index;">
					<div class="row">
						<div [ngClass]="{'col-12': !clientAccesses.includes(access+'/Read') && !clientAccesses.includes(access+'/ReadWrite'), 'col-6': clientAccesses.includes(access+'/Read') || clientAccesses.includes(access+'/ReadWrite')}">
							<app-checkbox-field class="main-access" [textLabel]="'EditOauthClientComponent.'+access" [nameInput]="access" [myInputModel]="isCheckedAccess(access+'/Read') || isCheckedAccess(access+'/ReadWrite')" [checked]="isCheckedAccess(access+'/Read') || isCheckedAccess(access+'/ReadWrite')" (checkedEvent)="switchAccess($event, access)"></app-checkbox-field>
						</div>
						<div class="col-6" *ngIf="clientAccesses.includes(access+'/Read') || clientAccesses.includes(access+'/ReadWrite')">
							<app-checkbox-field [textLabel]="'EditOauthClientComponent.Read'" [nameInput]="access+'/Read'" [myInputModel]="isCheckedAccess(access+'/Read')" [checked]="isCheckedAccess(access+'/Read')" (checkedEvent)="switchReadAccess($event, access)" [disabled]="isCheckedAccess(access+'/Read')"></app-checkbox-field>
							<app-checkbox-field [textLabel]="'EditOauthClientComponent.ReadWrite'" [nameInput]="access+'/ReadWrite'" [myInputModel]="isCheckedAccess(access+'/ReadWrite')" [checked]="isCheckedAccess(access+'/ReadWrite')" (checkedEvent)="switchReadWriteAccess($event, access)" [disabled]="isCheckedAccess(access+'/ReadWrite')"></app-checkbox-field>
						</div>
					</div>
					<hr class="my-1"/>
				</div>
			</div>
		</ng-container>
		<div class="dual-button-container mt-3">
			<button class="btn btn-app app-secondary w-100" type="reset" [routerLink]="['../../']">{{"EditOauthClientComponent.Cancel" | translate }}</button>
			<app-loading-button (submitEvent)="confirmEditOauthClient(editOauthClient)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'EditOauthClientComponent.Edit'" [finalText]="'EditOauthClientComponent.Edited'">
			</app-loading-button>
		</div>
		<div class="is-third fields-error" *ngIf="editOauthClient.submitted && !editOauthClient.valid">
			{{'EditOauthClientComponent.SomeFields' | translate}}</div>
	</form>
</app-admin-bloc>