import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';
import { OffersType } from '../offer.model';
import { ExpiredOfferService } from './expired-offer-list.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';
import { TranslateService } from '@ngx-translate/core';

const debug = Debug('virtuvisio-administration:expired-offer-list-component');

@Component({
  selector: 'app-expired-offer-list',
  templateUrl: './expired-offer-list.component.html',
  styleUrls: ['./expired-offer-list.component.scss', '../../commons/mat-table-custom/mat-table-custom.component.scss'],
  providers: [ExpiredOfferService]
})
export class ExpiredOfferListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = [
    'name',
    'oneOffer',
    'proOffer',
    'gatewayOffer',
    'recordingOffer',
    'oneCodecOffer',
    'hangoutGatewayOffer',
    'teamsGatewayOffer',
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  listLength: any;
  dataSource: MatTableDataSource<Tenant>;
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  showExpireOnly = false;
  expireQuery: string;
  offersType = OffersType;
  kloodDateFormat = KloodDateFormat;
  search: string;
  sortField = '';
  orderDir = '';
  tenants: Tenant[];

  constructor(private sharedService: SharedService, public offerService: ExpiredOfferService, private matPaginatorIntl: MatPaginatorIntl, private translate: TranslateService) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe((connectedUser: ConnectedUser) => {
      if (connectedUser) {
        this.connectedUser = connectedUser;
        this.initLabelMatPaginator();
      }
    }));
    this.getTenants();
  }

  showExpired(event: boolean): void {
    this.showExpireOnly = event;
    this.getTenants();
  }

  getTenants(): void {
    if (this.showExpireOnly) {
      this.expireQuery = this.getExpiresQuery();
    } else {
      this.expireQuery = '';
    }
    this.offerService.getHead(this.expireQuery).subscribe((result) => {
      const headerCount = result;
      const skip = 0;
      const limit = this.pageSize;
      if (this.showExpireOnly) {
        this.expireQuery = this.getExpiresQuery();
      } else {
        this.expireQuery = '';
      }
      this.offerService.getPagination(this.expireQuery, skip, limit, this.sortField, this.orderDir).subscribe((tenants: Tenant[]) => {
        this.tenants = tenants;
        this.listLength = headerCount;
        this.dataSource = new MatTableDataSource(this.tenants);
        this.checkExpired();
      });
    });
  }

  getExpiresQuery(suggest?: string): string {
    const gt = new Date(new Date().getTime() - 604800000).toISOString();
    const lt = new Date(new Date().getTime() + 2592000000).toISOString();
    let filter: any = {};
    if (suggest) {
      filter = {
        $or: [],
        suggests: {
          $regularExpression: {
            pattern: suggest,
            options: 'im',
          },
        },
      };
      for (const o in this.offersType) {
        const key = 'offers.' + this.offersType[o] + '.expiresAt';
        const obj: any = {};
        obj[key] = {
          $lt: {
            $date: lt,
          },
          $gt: {
            $date: gt,
          },
        };
        filter['$or'].push(obj);
      }
    } else {
      filter = {
        $or: [],
      };
      for (const o in this.offersType) {
        const key = 'offers.' + this.offersType[o] + '.expiresAt';
        const obj: any = {};
        obj[key] = {
          $lt: {
            $date: lt,
          },
          $gt: {
            $date: gt,
          },
        };
        filter['$or'].push(obj);
      }
    }
    const query = JSON.stringify(filter);
    return query;
  }

  checkExpired(): void {
    for (const t in this.tenants) {
      if (this.tenants[t].offers) {
        for (const o in this.tenants[t].offers) {
          if (new Date().getTime() > new Date(this.tenants[t].offers[o].expiresAt).getTime()) {
            this.tenants[t].offers[o].isExpired = true;
          } else {
            if (0 < new Date(this.tenants[t].offers[o].expiresAt).getTime() - new Date().getTime() && new Date(this.tenants[t].offers[o].expiresAt).getTime() - new Date().getTime() <= 1296000000) {
              this.tenants[t].offers[o].expiresIn = 15;
            } else if (1296000000 < new Date(this.tenants[t].offers[o].expiresAt).getTime() - new Date().getTime() && new Date(this.tenants[t].offers[o].expiresAt).getTime() - new Date().getTime() <= 2592000000) {
              this.tenants[t].offers[o].expiresIn = 30;
            }
          }
        }
      }
    }
    if (!this.dataSource) {
      this.dataSource = new MatTableDataSource(this.tenants);
    } else {
      this.dataSource.data = this.tenants;
    }
  }

  getNext(event: PageEvent): void {
    this.pageSize = event.pageSize;
    const skip = event.pageSize * event.pageIndex;
    const limit = event.pageSize;
    if (this.showExpireOnly) {
      this.expireQuery = this.getExpiresQuery();
    } else {
      this.expireQuery = '';
    }
    this.offerService.getPagination(this.expireQuery, skip, limit, this.sortField, this.orderDir).subscribe((tenants: Tenant[]) => {
      this.tenants = tenants;
      if (!this.dataSource) {
        this.dataSource = new MatTableDataSource(this.tenants);
      } else {
        this.dataSource.data = this.tenants;
      }
      this.checkExpired();
    });
  }

  applyFilterFromEvent(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.value !== '') {
      this.applyFilter(target.value);
    } else {
      this.getTenants();
    }
  }

  applyFilter(suggest: string): void {
    if (suggest) {
      if (this.showExpireOnly) {
        this.expireQuery = this.getExpiresQuery(suggest);
      } else {
        this.expireQuery = '';
      }
      this.offerService.getSuggest(this.expireQuery, suggest, this.pageSize, this.sortField, this.orderDir).subscribe((tenants: Tenant[]) => {
        this.listLength = tenants?.length;
        this.tenants = tenants;
        if (!this.dataSource) {
          this.dataSource = new MatTableDataSource(this.tenants);
        } else {
          this.dataSource.data = this.tenants;
        }
        this.checkExpired();
      });
    } else {
      this.getTenants();
    }
  }

  newSort(event: Sort): void {
    this.paginator.pageIndex = 0;
    if (event.direction === '') {
      this.sortField = '';
      this.orderDir = '';
    } else {
      this.sortField = event.active;
      this.orderDir = event.direction;
    }
    if (this.search !== '') {
      this.applyFilter(this.search);
    } else {
      this.getTenants();
    }
  }

  initLabelMatPaginator(): void {
    this.matPaginatorIntl.firstPageLabel = this.translate.instant('MatPaginator.FirstPageLabel');
    this.matPaginatorIntl.itemsPerPageLabel = this.translate.instant('MatPaginator.ItemsPerPageLabel');
    this.matPaginatorIntl.lastPageLabel = this.translate.instant('MatPaginator.LastPageLabel');
    this.matPaginatorIntl.nextPageLabel = this.translate.instant('MatPaginator.NextPageLabel');
    this.matPaginatorIntl.previousPageLabel = this.translate.instant('MatPaginator.PreviousPageLabel');
    const rangeTxtLabel = this.translate.instant('MatPaginator.RangeTxtLabel');
    this.matPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 ` + rangeTxtLabel + ` ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return (`${startIndex + 1} - ${endIndex} ` + rangeTxtLabel + ` ${length} (page n°: ${page + 1})`);
    };
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
