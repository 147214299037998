<div class="row">
  <div class="col-12" *ngIf="!addEndpoint">
    <div class="row mb-2 align-items-center" *ngIf="!currentMeetingRoom.endpoint">
      <div class="col-6">
        {{"Endpoints.NoEndpoint" | translate}}
      </div>
      <div class="col-6">
        <button type="button" class="btn btn-app app-primary float-end" *ngIf="canAddEndpoint()" (click)="addEndpoint=true">
          <fa-icon [icon]="['fas', 'plus']" class="me-2"></fa-icon>{{"Endpoints.AddEndpoint" | translate}} 
        </button>
      </div>
    </div>
    <div *ngIf="currentMeetingRoom.endpoint">
      <div *ngIf="editEndpoint">
        <app-edit-endpoint [currentMeetingRoom]="currentMeetingRoom" [currentEndpoint]="currentEndpoint" (cancelEndpointEdit)="editEndpoint=$event"></app-edit-endpoint>
      </div>
      <div *ngIf="!editEndpoint">
        <div class="row">
          <div class="col-12" *ngIf="currentConnectedUser?.canEdit(currentTenant.id)">
            <a class="pointer mx-1 float-end" (click)="deletingMeetingRoomEndpoint=true;" (click)="showModalDeleteEndpoint()" #tooltip="matTooltip" matTooltip="{{'Endpoints.Tooltip.Delete' | translate}}" matTooltipClass="custom-tooltip">
              <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
            </a>
            <a class="pointer mx-1 float-end" (click)="editEndpoint=true;currentEndpoint=currentMeetingRoom.endpoint;" #tooltip="matTooltip" matTooltip="{{'Endpoints.Tooltip.Edit' | translate}}" matTooltipClass="custom-tooltip">
              <fa-icon [icon]="['fas', 'edit']"></fa-icon>
            </a>
            <a class="pointer mx-1 float-end" (click)="sendEndpointInfo(currentMeetingRoom);" #tooltip="matTooltip" matTooltip="{{'Endpoints.Tooltip.Send' | translate}}" matTooltipClass="custom-tooltip" *ngIf="currentMeetingRoom.endpoint.adminContact">
              <fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
            </a>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-12 status-endpoint">
            <div class="is-bold me-1">{{"Endpoints.Endpoint" | translate}} <span *ngIf="currentMeetingRoom.endpoint.number">({{currentMeetingRoom.endpoint.number}})</span></div>
            <div *ngIf="!loading">
              <fa-icon class="is-green" [icon]="['fas', 'dot-circle']" *ngIf="endpointStatusActive && errorEndpoint && !errorEndpoint.error"></fa-icon>
              <fa-icon class="is-red" [icon]="['fas', 'dot-circle']" *ngIf="(!endpointStatusActive && !endpointStatusUnknown) || (endpointStatusActive && errorEndpoint && errorEndpoint.error !== '')"></fa-icon>
              <fa-icon class="is-grey" [icon]="['fas', 'dot-circle']" *ngIf="!endpointStatusActive && endpointStatusUnknown"></fa-icon>
            </div>
            <div class="w-50">
              <div class="row h-100 d-flex justify-content-center align-items-center" *ngIf="loading">
                <div class="col-auto my-auto">
                    <app-loading-spinner [diameter]="20" class="app-loader-primay"></app-loading-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center" *ngIf="errorEndpoint && errorEndpoint.error">
          <div class="col-auto pe-0">
            <span class="is-bold">{{"Endpoints.Error" | translate}} :</span>
          </div>
          <div class="col is-red">{{errorEndpoint.error}} - {{errorEndpoint.when | date:kloodDateFormat.pipe[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</div>
        </div>
        <div class="row align-items-center" *ngIf="errorEndpoint && errorEndpoint.when">
          <div class="col-auto pe-0">
            <span class="is-bold">{{"Endpoints.LastAccess" | translate}}</span>
          </div>
          <div class="col">{{errorEndpoint.when | date:kloodDateFormat.pipe[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</div>
        </div>
        <div class="row align-items-center">
          <div class="col-12">
            <span class="is-bold">{{"Endpoints.AdminContact" | translate}} : </span>{{currentMeetingRoom.endpoint.adminContact}}
            <a class="pointer ms-1" *ngIf="currentConnectedUser?.canEdit(currentTenant.id)" (click)="editAdminContact=true">
              <fa-icon [icon]="['fas', 'edit']"></fa-icon>
            </a>
            <div class="row my-3 align-items-center" *ngIf="editAdminContact">
              <div class="col-6">
                <app-input-field [typeInput]="'text'" [nameInput]="'Endpoints.AdminContact'" [textLabel]="'Endpoints.AdminContact'" [myInputModel]="currentMeetingRoom.endpoint.adminContact" (modelChangeEvent)="onAdminContactChange($event)"></app-input-field>
              </div>
              <div class="col-6">
                <div class="dual-button-container">
                  <button type="button" class="btn btn-app app-secondary" (click)="editAdminContact=false">
                    {{"Endpoints.Cancel" | translate}}
                  </button>
                  <button type="button" class="btn btn-app app-primary" (click)="confirmEditAdminContact(currentMeetingRoom)">
                    {{"Endpoints.Confirm" | translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="currentConnectedUser?.canEdit(currentTenant.id)">
          <div class="col-12">
            <a class="pointer me-1" (click)="resetPassword(currentMeetingRoom)">{{"Endpoints.ResetPassword" | translate}} <fa-icon [icon]="['fas', 'key']"></fa-icon></a>
          </div>
        </div>
        <app-admin-infos-bloc [blocTitle]="'Endpoints.EndpointType'">
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-12 is-bold">
                  {{currentMeetingRoom.endpoint.kind}}
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto pe-0">
                  <app-checkbox-field [nameInput]="'Endpoints.IsActive'" [checked]="currentMeetingRoom.endpoint.active" [myInputModel]="currentMeetingRoom.endpoint.active" (checkedEvent)="onActiveEndpointCheckEvent($event)"></app-checkbox-field>
                </div>
                <div class="col ps-0">
                  {{"Endpoints.Polling" | translate}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div *ngIf="currentMeetingRoom.endpoint.kind  === 'CISCO' || currentMeetingRoom.endpoint.kind === 'OTHER'">
                <div class="row">
                  <div class="col-12 is-bold">
                    <u>{{"Endpoints.Configuration" | translate}}</u>
                  </div>
                </div>
                <div *ngIf="currentMeetingRoom.endpoint.kind === 'CISCO' && currentMeetingRoom.endpoint.config">
                  <div class="row">
                    <div class="col-auto is-bold pe-0">{{"Endpoints.Ip" | translate}} :</div>
                    <div class="col">{{currentMeetingRoom.endpoint.config.ip}}</div>
                  </div>
                  <div class="row">
                    <div class="col-auto is-bold pe-0">{{"Endpoints.Username" | translate}} :</div>
                    <div class="col">{{currentMeetingRoom.endpoint.config.username}}</div>
                  </div>
                  <div class="row">
                    <div class="col-auto is-bold pe-0">{{"Endpoints.Password" | translate}} :</div>
                    <div class="col">{{hidePasswordText(currentMeetingRoom.endpoint.config.password)}}</div>
                  </div>
                </div>
                <div class="row" *ngIf="currentMeetingRoom.endpoint.kind === 'OTHER' && currentMeetingRoom.endpoint.config">
                  <div class="col-auto is-bold pe-0">{{"Endpoints.DialString" | translate}} :</div>
                  <div class="col">
                    <span *ngIf="currentMeetingRoom.endpoint.config.dialString">{{currentMeetingRoom.endpoint.config.dialString}}</span>
                    <span *ngIf="!currentMeetingRoom.endpoint.config.dialString">{{"Endpoints.None" | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-admin-infos-bloc>
        <app-admin-infos-bloc [blocTitle]="'Endpoints.Capability'">
          <div class="row">
            <div class="col-6">
              <div class="row align-items-center">
                <div class="col-auto pe-0">
                  <ng-container *ngFor="let capability of endpointCapabilites">
                    <div class="icheck-custom iradio">
                      <input
                          name="endpointCapability"
                          type="radio"
                          id="{{capability.id}}"
                          placeholder="'Endpoints.Capabilties.'{{capability.label | translate}}"
                          value="{{capability.value}}"
                          (change)="updateCapabilityEndpoint()"
                          [(ngModel)]="currentMeetingRoom.endpoint.capability"
                          [disabled]="checkCapabiltyDisabled(capability.label)"
                      />
                      <label for="{{capability.id}}"> {{"Endpoints.Capabilities."+capability.label | translate}} </label>
                  </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div *ngIf="currentMeetingRoom.endpoint.capability === 'ONE' || currentMeetingRoom.endpoint.capability === 'BOTH'">
                <div class="row align-items-center">
                  <div class="col-12 is-bold">
                    <u>{{"Endpoints.VirtualRoomInfo" | translate}}</u>
                    <a class="pointer ms-1" *ngIf="currentConnectedUser && currentConnectedUser.canEdit(currentTenant.id)" (click)="currentEndpoint=currentMeetingRoom.endpoint;editEndpoint=true;">
                      <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                    </a>
                  </div>
                </div>
                <div class="row" *ngIf="!editEndpoint">
                  <div class="col-auto is-bold pe-0">{{"Endpoints.Number" | translate}} :</div>
                  <div class="col">{{currentMeetingRoom.endpoint.number}}</div>
                </div>
                <div class="row" *ngIf="!editEndpoint">
                  <div class="col-auto is-bold pe-0">{{"Endpoints.ModeratorCode" | translate}} :</div>
                  <div class="col">{{currentMeetingRoom.endpoint.moderatorCode}}</div>
                </div>
              </div>
            </div>
          </div>
        </app-admin-infos-bloc>
        <app-admin-infos-bloc [blocTitle]="'Endpoints.SipAccount'" *ngIf="!addingSipAccount">
          <div class="row">
            <div class="col-6">
              <div class="row align-items-center">
                <div class="col-auto pe-0">
                  <app-checkbox-field [nameInput]="'Endpoints.SipAccount'" [checked]="hasSipAccount" [myInputModel]="hasSipAccount" (checkedEvent)="onSipAccountCheckEvent($event)"></app-checkbox-field>
                </div>
                <div class="col ps-0">
                  {{"Endpoints.SipAccount" | translate}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div *ngIf="currentMeetingRoom.endpoint.sipAccount">
                <div class="row">
                  <div class="col-12 is-bold">
                    <u>{{"Endpoints.SipAccount" | translate}}</u>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto is-bold pe-0">{{"Endpoints.Status" | translate}} :</div>
                  <div class="col">
                    <fa-icon *ngIf="currentMeetingRoom.endpoint.sipAccount.registered" class="is-green" [icon]="['fas', 'check']"></fa-icon>
                    <fa-icon *ngIf="!currentMeetingRoom.endpoint.sipAccount.registered" class="is-red" [icon]="['fas', 'times']"></fa-icon>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto is-bold pe-0">{{"Endpoints.Alias" | translate}} :</div>
                  <div class="col">{{currentMeetingRoom.endpoint.sipAccount.alias}}</div>
                </div>
                <div class="row">
                  <div class="col-auto is-bold pe-0">{{"Endpoints.Username" | translate}} :</div>
                  <div class="col">{{currentMeetingRoom.endpoint.sipAccount.username}}</div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <a class="pointer" (click)="resetSipAccountPassword(currentMeetingRoom)">
                      {{"Endpoints.ResetPassword" | translate}}
                      <fa-icon [icon]="['fas', 'key']"></fa-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-admin-infos-bloc>
        <app-add-sip-account *ngIf="addingSipAccount" [currentMeetingRoom]="currentMeetingRoom" (addedSipAccount)="onAddSipAccount($event)" (hasSipAccountEvent)="hasSipAccount=$event" (addingSipAccountEvent)="addingSipAccount=$event" (deletedSipAccount)="getMeetingRoom()"></app-add-sip-account>
      </div>
    </div>
  </div>
  <div class="col-12 adding-endpoint" *ngIf="addEndpoint">
    <app-add-endpoint [currentMeetingRoom]="currentMeetingRoom" (cancelAddEndpoint)="addEndpoint=$event;getMeetingRoom()"></app-add-endpoint>
  </div>
</div>
<div class="modal fade custom-app-modal" id="modalOnDeleteEndpoint" role="dialog" aria-labelledby="modalOnDeleteEndpointLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content flat">
      <div class="modal-header">
        <h3 class="modal-title text-center" id="modalOnDeleteEndpointLabel">{{"Endpoints.Delete" |
          translate}}</h3>
      </div>
      <div class="modal-body">
        <div class="is-bold mb-3">{{"Endpoints.SureDeleteEdnpoint" | translate}}</div>
        <div class="dual-button-container">
          <button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal">
            {{"Endpoints.Cancel" | translate}}
          </button>
          <button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="confirmDeleteMeetingRoomEndpoint(currentMeetingRoom)">
            {{"Endpoints.Confirm" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade custom-app-modal" id="modalSipAccountResetPassword" role="dialog" aria-labelledby="modalSipAccountResetPasswordLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content flat">
      <div class="modal-header">
        <h3 class="modal-title text-center" id="modalSipAccountResetPasswordLabel" *ngIf="resetedPassword && !justAddSipAccount">{{"Endpoints.SipAccountPasswordReset" |
          translate}}</h3>
        <h3 class="modal-title text-center" id="modalSipAccountResetPasswordLabel" *ngIf="!resetedPassword && justAddSipAccount">{{"Endpoints.SipAccountJustAdd" |
          translate}}</h3>
      </div>
      <div class="modal-body">
        <div class="mb-3" *ngIf="resetedPassword && !justAddSipAccount">
          <div>{{"Endpoints.Username" | translate}} : {{resetedPassword.username}}</div>
          <div>{{"Endpoints.Password" | translate}} : {{resetedPassword.password}}</div>
        </div>
        <div class="mb-3" *ngIf="!resetedPassword && justAddSipAccount">
          <div>{{"Endpoints.Username" | translate}} : {{justAddSipAccount.username}}</div>
          <div>{{"Endpoints.Password" | translate}} : {{justAddSipAccount.password}}</div>
        </div>
        <button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal">
          {{"Button.Ok" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>