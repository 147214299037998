<app-admin-bloc [blocTitle]="'Edit'">
	<form #trialEditForm="ngForm" id="trialEditForm" name="trialEditForm">
		<app-date-picker-form-field class="mb-3 form-control" [textLabel]="'TrialsComponent.ExpirationDate'" [nameInput]="'expirationDate'" [form]="trialEditForm" [required]="true" [myInputModel]="selectedTrial.trial.expires" [connectedUser]="currentConnectedUser"></app-date-picker-form-field>
		<div class="dual-button-container">
			<button class="btn btn-app app-secondary w-100" type="reset" (click)="trialEditedEvent.emit(true)">{{"TrialsComponent.Cancel" | translate }}</button>
			<app-loading-button (submitEvent)="editTrialExpiration(trialEditForm)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'TrialsComponent.Edit'" [finalText]="'TrialsComponent.Edited'">
			</app-loading-button>
		</div>
		<div class="is-third fields-error" *ngIf="trialEditForm.submitted && !trialEditForm.valid">{{'TrialsComponent.SomeFields' | translate}}</div>
	</form>
</app-admin-bloc>
