<app-admin-bloc [blocTitle]="'UserAddComponent.NewUser'">
  <form #addUserForm="ngForm" id="addUserForm" name="addUserForm">
    <app-input-form-field [nameInput]="'email'" [typeInput]="'text'" [myInputModel]="newUser.email" [textLabel]="'UserAddComponent.Email'" [form]="addUserForm" class="form-control" [required]="true" (blurEvent)="onEmailBlur($event)"></app-input-form-field>
    <app-input-form-field [nameInput]="'password'" [typeInput]="'password'" [myInputModel]="newUser.password" [textLabel]="'UserAddComponent.Password'" [form]="addUserForm" class="form-control" [required]="true"></app-input-form-field>
    <app-input-form-field [nameInput]="'lastName'" [typeInput]="'text'" [myInputModel]="newUser.lastName" [textLabel]="'UserAddComponent.LastName'" [form]="addUserForm" class="form-control" [required]="true"></app-input-form-field>
    <app-input-form-field [nameInput]="'firstName'" [typeInput]="'text'" [myInputModel]="newUser.firstName" [textLabel]="'UserAddComponent.FirstName'" [form]="addUserForm" class="form-control" [required]="true"></app-input-form-field>
    <app-input-dropdown-form-field [nameInput]="'lang'" [defaultText]="'UserAddComponent.Lang'" [modelKey]="'value'" [displayKey]="'label'" [selectedElement]="newUser.lang" [myInputModel]="newUser.lang" [dataSource]="userLangs" class="form-control" (selectedData)="newUser.lang=$event.value" [form]="addUserForm" [required]="true"></app-input-dropdown-form-field>
    <app-input-dropdown-field [defaultText]="'UserAddComponent.Country'" [modelKey]="'code'" [displayKey]="'displaySelect'" [selectedElement]="countryCode" [dataSource]="countries" class="form-control" (selectedData)="countryCode=$event.value"></app-input-dropdown-field>
    <app-input-form-field [nameInput]="'phone'" [typeInput]="'text'" [myInputModel]="newUser.phone" [textLabel]="'UserAddComponent.Phone'" [form]="addUserForm" class="form-control"></app-input-form-field>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary w-100" type="reset" (click)="backToList()">{{"UserAddComponent.Cancel" | translate }}</button>
      <app-loading-button (submitEvent)="confirmAddUser(addUserForm)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'UserAddComponent.Add'" [finalText]="'UserAddComponent.Added'">
      </app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="addUserForm.submitted && !addUserForm.valid">{{'UserAddComponent.SomeFields' | translate}}</div>
  </form>
</app-admin-bloc>