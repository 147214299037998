import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';

@Injectable()
export class LiveUsageService {

  constructor(private http: HttpClient, private optionService: DataApiOptionService) { }

  getUsage() {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http
          .get<any>(apiUri + '/live/usage', {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
            observe: 'body',
          })
          .pipe(
            map((result) => result),
            catchError(this.handleError<any>('getLiveUsage', null))
          );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
