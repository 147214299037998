<div class="row align-items-center p-3">
	<div class="col-auto">
			<h3 class="m-0">
					<span class="is-bold">{{"MailsConfigurationComponent.Title" | translate}}</span>
			</h3>
	</div>
</div>
<ng-container *ngIf="tenant">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 p-0">
				<div class="mails-configuration">
					<app-admin-card>
						<div class="row align-items-center">
							<div class="col-auto">
								{{"MailsConfigurationComponent.Template" | translate}}
							</div>
							<div class="col">
								<app-input-dropdown-field [selectedElement]="selectedMail" [defaultText]="'InvitationsConfigurationComponent.Template'" [modelKey]="'value'" [displayKey]="'label'" [dataSource]="transformValueArrays(mails)" (selectedData)="renderMail($event.value)"></app-input-dropdown-field>
							</div>
							<div class="col-auto">
								{{"MailsConfigurationComponent.Language" | translate}}
							</div>
							<div class="col">
								<app-input-dropdown-field [selectedElement]="lang" [defaultText]="'InvitationsConfigurationComponent.Language'" [modelKey]="'value'" [displayKey]="'label'" [dataSource]="transformValueArrays(langs)" (selectedData)="switchLang($event.value)"></app-input-dropdown-field>
							</div>
						</div>
						<ng-container *ngIf="!mail && !loading && !selectedMail">
							<div class="row mt-4">
								<div class="col">
									{{"MailsConfigurationComponent.NoTemplateAndLangSelected" | translate}}
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="!mail && !loading && selectedMail">
							<div class="row mt-3">
								<div class="col-12">
									<div class="float-end">
										<button class="btn btn-sm btn-app app-secondary mx-3" (click)="confirmDeleteMail(selectedMail);">{{"MailsConfigurationComponent.ResetToDefault" | translate}}</button>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="mail && !loading">
							<div class="row mt-3">
								<div class="col-12">
									<button class="btn btn-sm btn-app app-primary float-end ms-3" *ngIf="!rendering" (click)="previewMail(selectedMail, mail);">{{"MailsConfigurationComponent.Preview" | translate}}</button>
									<button class="btn btn-sm btn-app app-primary float-end ms-3" *ngIf="!rendering" (click)="confirmEditMail(selectedMail, mail);">{{"Button.Save" | translate}}</button>
									<button class="btn btn-sm btn-app app-secondary float-start me-3" *ngIf="!rendering" (click)="renderMail(selectedMail);">{{"MailsConfigurationComponent.BackRender" | translate}}</button>
									<button class="btn btn-sm btn-app app-secondary float-start me-3" (click)="confirmDeleteMail(selectedMail);">{{"MailsConfigurationComponent.ResetToDefault" | translate}}</button>
									<button class="btn btn-sm btn-app app-primary float-end ms-3" *ngIf="rendering" (click)="getMail(selectedMail);">{{"Button.Edit" | translate}}</button>
								</div>
							</div>
							<div class="row mt-3">
								<div class="col-12">
									<div class="row">
										<div class="col mt-3">
											<app-input-field [textLabel]="'MailsConfigurationComponent.Subject'" [typeInput]="'text'" [nameInput]="'subject'"
											[myInputModel]="mail.subject" (modelChangeEvent)="mail.subject = $event" [disabled]="rendering"></app-input-field>
										</div>
									</div>
								</div>
							</div>
							<div class="row mt-3" *ngIf="rendering">
								<div class="col-12">
									<iframe [src]="renderUrl" id="renderFrame"></iframe>
								</div>
							</div>
							<div class="row mt-3" *ngIf="!rendering">
								<div class="col-6">
									<div class="accordion" id="accordionContext">
										<div class="accordion-item">
											<h2 class="accordion-header" id="headingAvailableFields">
												<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFields" aria-expanded="false" aria-controls="collapseFields">
													{{"MailsConfigurationComponent.AvailableFields" | translate}}
												</button>
											</h2>
											<div id="collapseFields" class="accordion-collapse collapse" aria-labelledby="headingAvailableFields" data-bs-parent="#accordionContext">
												<div class="accordion-body">
													<app-context *ngIf="mail.schema?.properties && currentUser" [schema]="mail.schema.properties" [currentUser]="currentUser"></app-context>
												</div>
											</div>
										</div>
										<div class="accordion-item">
											<h2 class="accordion-header" id="headingAvailableLinks">
												<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLinks" aria-expanded="false" aria-controls="collapseLinks">
													{{"MailsConfigurationComponent.AvailableLinks" | translate}}
												</button>
											</h2>
											<div id="collapseLinks" class="accordion-collapse collapse" aria-labelledby="headingAvailableLinks" data-bs-parent="#accordionContext">
												<div class="accordion-body">
													<div *ngFor="let link of links">- {{link}} : {{"MailsConfigurationComponent."+link+" description" | translate}}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row mt-3">
										<div class="col-12">
											<textarea class="textarea-tpl" [(ngModel)]="mail.content"></textarea>
										</div>
									</div>
								</div>
								<div class="col-6">
									<ng-container *ngIf="preview && !loading">
										<iframe [src]="previewUrl" id="previewFrame"></iframe>
									</ng-container >
								</div>
							</div>
						</ng-container>
						<div class="row h-100 d-flex justify-content-center align-items-center" *ngIf="loading">
							<div class="col-auto my-auto">
									<app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
							</div>
						</div>
					</app-admin-card>
				</div>
			</div>
		</div>
	</div>
</ng-container>