import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  template: `<div *ngIf="!grow && !breath">
      <mat-spinner
        [diameter]="diameter"
        class="custom-mat-spinner"
        [ngClass]="{ center: position === 'center' }"
      ></mat-spinner>
    </div>
    <div *ngIf="grow">
      <div class="spinner-grow" role="status">
        <span class="sr-only">{{ 'Spinner.Loading' | translate }}</span>
      </div>
    </div>
    <div *ngIf="breath">
      <div class="spinner-box">
        <div class="logo-box" [ngStyle]="{ 'margin-left.px': diameter }">
          <div
            class="base-orbit orbit"
            [ngStyle]="{
              'width.px': 2.5 * diameter,
              'height.px': 2.5 * diameter
            }"
          >
            <div
              class="complete-orbit"
              [ngStyle]="{
                'width.px': 2.5 * diameter,
                'height.px': 2.5 * diameter
              }"
            ></div>
          </div>
          <div
            class="breathing-logo"
            [ngStyle]="{
              'width.px': diameter,
              'height.px': diameter,
              'left.px': -1.75 * diameter
            }"
            *ngIf="!tooSmall"
          ></div>
        </div>
      </div>
    </div> `,
  styles: [
    `
      @keyframes spin {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(359deg);
        }
      }

      @keyframes breathing {
        from {
          transform: scale(1);
        }
        to {
          transform: scale(0.7);
        }
      }

      .spinner-box {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
      }

      .logo-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 60px;
      }

      .orbit {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid rgba(98, 79, 144, 0.3);
        border-radius: 50%;
      }

      .base-orbit {
        width: 150px;
        height: 150px;
        -webkit-animation: spin 1.5s linear 0s infinite;
      }

      .complete-orbit {
        position: absolute;
        top: -2px;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: solid 2px rgb(98, 79, 144);
        border-color: rgb(98, 79, 144) rgb(98, 79, 144) transparent
          rgb(98, 79, 144);
      }

      .breathing-logo {
        width: 60px;
        height: 60px;
        background: transparent url('/assets/images/arrow-primary.png')
          no-repeat center center;
        background-size: contain;
        position: relative;
        left: -105px;
        animation-name: breathing;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-direction: alternate;
      }

      .mat-progress-spinner::ng-deep circle,
      .mat-spinner::ng-deep circle {
        stroke: #624f90;
      }

      .custom-mat-spinner.center {
        left: 50%;
        transform: translate(-50%);
      }
      .custom-mat-spinner.full-center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .custom-mat-spinner.left {
        left: 0%;
        transform: translate(-0%);
      }
      .custom-mat-spinner.right {
        left: 100%;
        transform: translate(-100%);
      }
    `,
  ],
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() diameter = 16;
  @Input() grow = false;
  @Input() breath = false;
  @Input() position = '';

  tooSmall = false;

  ngOnInit(): void {
    if (this.diameter < 16) {
      this.tooSmall = true;
    }
  }
}
