import { Access } from './access.model';

export class User {
  // need for new
  lastName: string;
  firstName: string;
  email: string;
  phone: any;
  password?: string;
  // add by broker
  id: string;
  access: any;
  avatar: Avatar;
  createdAt: Date;
  allowedOffers: string[];
  deleted: boolean;
  deletedAt: Date;
  disabled: boolean;
  disabledOffers: string[];
  displayName: string;
  enabledOffers: string[];
  expired: boolean;
  lang: string;
  lastAccess: Date;
  moderatorCode: string;
  oldId: number;
  offers: any;
  authProvider: string;
  preferences: {};
  principalCompanyId: string;
  privateRoomAlias: string;
  privateRoomEnabled: boolean;
  remoteId: string;
  role: string; /* UNKNOWN,GUEST(3),ADMIN(2),SUPER_ADMIN(1)*/
  tenantId: string;
  // trial: TrialInfo;
  updatedAt: Date;
  recordRetention?: number | null;
  // for profil and /me
  services: string[];
  tenantName: string;
  tenantOffers: string[];
  isImpersonated: boolean;

  constructor(obj: any) {
    if (obj.access) {
      obj.access = new Access(obj.access);
    }
    Object.assign(this, obj);
    if (!this.enabledOffers) {
      this.enabledOffers = [];
    }
    if (!this.disabledOffers) {
      this.disabledOffers = [];
    }
    if (!this.tenantOffers) {
      this.tenantOffers = [];
    }
    if (!this.services) {
      this.services = [];
    }
    // this.password = null;
    // this.lastName = null;
    // this.firstName = null;
    // this.email = null;
    // this.phone = null;
  }

  getId(): string {
    return this.id;
  }

  getDisplayName(): string {
    return this.displayName;
  }

  hasPhone(): boolean {
    if (this.phone && this.phone !== '') {
      return true;
    }
    return false;
  }

  canEdit(tenantId: string): boolean {
    if (this.isGranted(tenantId) && !this.hasRole('MARKETING')) {
      return true;
    } else {
      return false;
    }
  }

  hasRole(role: string): boolean {
    if (this.role === role) {
      return true;
    } else {
      return false;
    }
  }

  isGranted(tenantId: string, acl?: string): boolean {
    if (this.role === 'SUPER_ADMIN') {
      return true;
    } else if (this.role === 'ADMIN' || this.role === 'MARKETING') {
      if (!this.access) {
        return false;
      } else if (!acl) {
        return true;
      } else if (this.access['*'] && this.access['*']?.length > 0 && this.access['*'].indexOf(acl) !== -1) {
        return true;
      } else if (this.access[tenantId] && this.access[tenantId]?.length > 0 && this.access[tenantId].indexOf(acl) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isMultipleGranted(tenantId: string, acls: string[]): boolean {
    if (this.role === 'SUPER_ADMIN') {
      return true;
    } else if (this.role === 'ADMIN' || this.role === 'MARKETING') {
      if (!this.access) {
        return false;
      } else if (acls.length > 0) {
        if (this.access[tenantId] && this.access[tenantId]?.length > 0) {
          let allow: boolean[] = [];
          for (let acl of acls) {
            allow.push(this.access[tenantId].indexOf(acl) !== -1);
          }
          if (allow.indexOf(false) !== -1) {
            return false
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isSpecificGranted(tenantId: string, acl: string): boolean {
    if (!this.access) {
      return false;
    } else if (this.access[tenantId] && this.access[tenantId]?.length > 0) {
      if (!acl || this.access[tenantId]?.length >= 11) {
        return true;
      } else if (this.access[tenantId].indexOf(acl) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  grantedList(tenantId: string): boolean | Access {
    if (this.access[tenantId] && this.access[tenantId]?.length > 0) {
      return this.access[tenantId];
    } else {
      return false;
    }
  }

  hasAccess(service: string): boolean {
    if (this.services.indexOf(service) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  hasAllowedOffer(offerType: string): boolean {
    return this.allowedOffers.indexOf(offerType) !== -1;
  }
}

export const UserRoles = [{ label: 'GUEST', value: 'GUEST' }, { label: 'ADMIN', value: 'ADMIN' }, { label: 'SUPER_ADMIN', value: 'SUPER_ADMIN' }, { label: 'MARKETING', value: 'MARKETING' }];
export const MasquaradeErrors = ['NEVER_LOGGED', 'MISSING_SCOPES', 'INVALIDATED_TOKEN', 'USER_DISABLED'];

interface Avatar {
  createdAt: Date;
  id: string;
}