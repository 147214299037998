import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { Continent } from './continent.model';

@Injectable()
export class ContinentsService {
  constructor(private http: HttpClient, private optionService: DataApiOptionService) { }

  getContinents(): Observable<Continent[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<Continent[]>(apiUri + '/continents', {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        });
      })
    );
  }

  save(continent: Continent): Observable<Continent> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.put<Continent>(
          apiUri + '/continents/' + continent.id,
          continent,
          {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          }
        );
      })
    );
  }

  create(continent: Continent): Observable<Continent> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.post<Continent>(
          apiUri + '/continents/' + continent.id,
          continent,
          {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          }
        );
      })
    );
  }

  delete(continentId: string): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.delete(apiUri + '/continents/' + continentId, {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        });
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
