export class ServerInfos {
  gatewayPhoneNumber: string;
  gateways: {
    isdn: string;
    pstn: string;
  };
  pexipDomain: string;
  turn: {
    url: string;
    username: string;
    credential: string;
  };
  apiUri: string;
  companionApplications: {};
  oauth2Providers: {
    default: {
      authorizeUrl: string;
      clientId: string;
      tokenUrl: string;
    };
    microsoft: {
      applicationUri: string;
      authorizeUrl: string;
      clientId: string;
      tokenUrl: string;
    };
  };

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
