import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { RequestFilterBuilder } from 'src/app/services/request-filter-builder/request-filter-builder';
import { suggestPattern } from 'src/app/operators/mongo-operators/mongo-operators';
import { ActivatedRoute } from '@angular/router';
import { PaginationInfo } from '../commons/mat-table-custom/mat-table-custom.model';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from '../services/http-encoder/custom-http-param-encoder.service';
import { ImportableMeetingRoom, MeetingRoom } from './meeting-room.model';

@Injectable()
export class MeetingRoomsService {
  tenantId: Observable<string> = of('');

  constructor(private http: HttpClient, private optionService: DataApiOptionService, private route: ActivatedRoute) {
    this.tenantId = <Observable<string>>(
      this.route.parent?.paramMap.pipe(map((params) => params.get('tenantId')))
    );
  }

  buildParamsUrl(
    paginationInfo: PaginationInfo,
    moreParams?: string[]
  ): HttpParams {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoderService(),
    });
    if (moreParams) {
      for (let p in moreParams) {
        let customParam = <string>(
          paginationInfo[<keyof PaginationInfo>moreParams[p]]
        );
        params = params.set(moreParams[p], customParam);
      }
    }
    const filter = new RequestFilterBuilder();
    if (paginationInfo.suggest) {
      filter.set('suggests', suggestPattern(paginationInfo.suggest));
    }
    const q = filter.serialize();
    return (params = params.set('q', q));
  }

  getHead(paginationInfo: PaginationInfo): Observable<number> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            const params = this.buildParamsUrl(paginationInfo);
            return this.http
              .head(apiUri + '/meetingRooms', {
                params: params,
                headers: this.optionService.getHeaders(),
                observe: 'response',
                withCredentials: true,
              })
              .pipe(
                map((result) => parseInt(<string>result.headers.get('Count')))
              );
          })
        );
      })
    );
  }

  getData(paginationInfo: PaginationInfo): Observable<MeetingRoom[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            const params = this.buildParamsUrl(paginationInfo, [
              'sort',
              'order',
              'skip',
              'limit',
            ]);
            return this.http.get<MeetingRoom[]>(apiUri + '/meetingRooms', {
              params: params,
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            });
          })
        );
      })
    );
  }

  delete(meetingRoomId: string): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.delete(apiUri + '/meetingRooms/' + meetingRoomId, {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            });
          })
        );
      })
    );
  }

  getImportableMeetingRooms(): Observable<ImportableMeetingRoom[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .get<ImportableMeetingRoom[]>(
                apiUri + '/meetingRooms/importable',
                {
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => {
                  const importableMeetingRooms = [];
                  for (const u in result) {
                    importableMeetingRooms.push(
                      <ImportableMeetingRoom>result[u]
                    );
                  }
                  return importableMeetingRooms;
                }),
                tap((result) => result),
                catchError(
                  this.handleError<ImportableMeetingRoom[]>(
                    'getImportableMeetingRooms',
                    []
                  )
                )
              );
          })
        );
      })
    );
  }

  getAllMeetingRooms(): Observable<MeetingRoom[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .get<MeetingRoom[]>(apiUri + '/meetingRooms', {
                headers: this.optionService.getHeaders(),
                withCredentials: true,
              })
              .pipe(
                map((result) => {
                  const meetingRoom = [];
                  for (const u in result) {
                    meetingRoom.push(<MeetingRoom>result[u]);
                  }
                  return meetingRoom;
                }),
                tap((result) => result),
                catchError(
                  this.handleError<MeetingRoom[]>('getAllMeetingRooms', [])
                )
              );
          })
        );
      })
    );
  }

  getMeetingRoom(meetingRoomId: string): Observable<MeetingRoom> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.get(apiUri + '/meetingRooms/' + meetingRoomId, {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            });
          }),
          map((result) => new MeetingRoom(result)),
          catchError(this.handleError<MeetingRoom>('getMeetingRoom', undefined))
        );
      })
    );
  }

  addMeetingRoom(meetingRoom: MeetingRoom): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.post(apiUri + '/meetingRooms', meetingRoom, {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            });
          }),
          map((result) => new MeetingRoom(result)),
          catchError(
            this.handleError<MeetingRoom[]>('addMeetingRoom', undefined)
          )
        );
      })
    );
  }

  editMeetingRoom(
    meetingRoomId: string,
    meetingRoom: MeetingRoom
  ): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.put(
              apiUri + '/meetingRooms/' + meetingRoomId,
              meetingRoom,
              {
                headers: this.optionService.getHeaders(),
                withCredentials: true,
              }
            );
          }),
          map((result) => new MeetingRoom(result)),
          catchError(
            this.handleError<MeetingRoom[]>('editMeetingRoom', undefined)
          )
        );
      })
    );
  }

  importMeetingRoom(email: string): Observable<MeetingRoom> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.post(
              apiUri + '/meetingRooms/' + email + '/import',
              null,
              {
                headers: this.optionService.getHeaders(),
                withCredentials: true,
              }
            );
          }),
          map((result) => new MeetingRoom(result)),
          catchError(
            this.handleError<MeetingRoom>('importMeetingRoom', undefined)
          )
        );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
