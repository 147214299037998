import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxMatDateAdapter, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { MY_NGXMATCALENDAR_MOMENT_FORMATS } from 'src/app/services/calendar/calendar.format.service';
import { NgModel } from '@angular/forms';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { DateAdapter } from '@angular/material/core';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';

@Component({
  selector: 'app-datetime-picker-field',
  templateUrl: './datetime-picker-field.component.html',
  styleUrls: ['./datetime-picker-field.component.scss'],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: MY_NGXMATCALENDAR_MOMENT_FORMATS,
    },
  ],
})
export class DatetimePickerFieldComponent implements OnInit {
  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() textLabel = '';
  @Input() nameInput = '';
  @Input() myInputModel!: unknown;
  @Input() connectedUser: ConnectedUser;
  @Input() min: Date;
  @Input() max: Date;
  @Output() dateSelect = new EventEmitter<any>();
  nowDate: Date;

  constructor(private dateAdapter: DateAdapter<Date>, private ngxDateAdapter: NgxMatDateAdapter<Date>) { }

  ngOnInit(): void {
    this.dateAdapter.setLocale(KloodDateFormat.adapterLocale[this.connectedUser.lang]);
    this.ngxDateAdapter.setLocale(KloodDateFormat.adapterLocale[this.connectedUser.lang]);
    this.nowDate = new Date();
    if (this.nowDate.getMinutes() >= 0 && this.nowDate.getMinutes() < 30) {
      this.nowDate.setMinutes(0);
    } else if (this.nowDate.getMinutes() >= 30 && this.nowDate.getMinutes() <= 59) {
      this.nowDate.setMinutes(30);
    }
    this.nowDate.setSeconds(0);
  }

  checkDate(date: Date): void {
    this.myInputModel = new Date(date);
    this.dateSelect.emit(date);
  }

  changeCalendarClass(): void {
    $('.cdk-overlay-pane').addClass('cdk-overlay-pane-custom-matpicker');
  }
}
