import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { Tenant } from './tenant.model';

@Injectable()
export class TenantService {
  tenantId: Observable<string> = of('');

  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService,
    private route: ActivatedRoute
  ) {
    this.tenantId = <Observable<string>>(
      this.route.parent?.paramMap.pipe(map((params) => params.get('tenantId')))
    );
  }

  getTenant(selectedTenantId?: string): Observable<Tenant> {
    if (selectedTenantId === '*') {
      const allTenants: Tenant = new Tenant({});
      allTenants.name = 'all tenants';
      allTenants.id = '*';
      return of(allTenants);
    } else {
      return this.tenantId.pipe(
        switchMap((tenantId) => {
          if (tenantId === '*') {
            const allTenants: Tenant = new Tenant({});
            allTenants.name = 'all tenants';
            allTenants.id = '*';
            return of(allTenants);
          } else {
            return this.optionService.getApiUrl().pipe(
              switchMap((apiUri) => {
                if (selectedTenantId) {
                  return this.http.get<Tenant>(
                    apiUri + '/tenants/' + selectedTenantId,
                    {
                      headers: this.optionService.getHeaders(),
                      observe: 'body',
                      withCredentials: true,
                    }
                  );
                } else {
                  return this.http.get<Tenant>(
                    apiUri + '/tenants/' + tenantId,
                    {
                      headers: this.optionService.getHeaders(),
                      observe: 'body',
                      withCredentials: true,
                    }
                  );
                }
              }),
              map((result) => new Tenant(result)),
              tap((result) => result),
              catchError(this.handleError<Tenant>('getTenant', undefined))
            );
          }
        })
      );
    }
  }

  getTenants(): Observable<Tenant[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<Tenant[]>(apiUri + '/tenants', {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        const tenants = [];
        for (const u in result) {
          tenants.push(new Tenant(result[u]));
        }
        return tenants;
      }),
      tap((result) => result),
      catchError(this.handleError<Tenant[]>('getTenants', []))
    );
  }

  addTenant(tenant: Tenant): Observable<Tenant> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.post<Tenant>(apiUri + '/tenants', tenant, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      tap((result) => result),
      catchError(this.handleError<Tenant>('addTenant', undefined))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
