import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfigService } from './services/config/app.config.service';

export function initializeApp(config: AppConfigService) {
  return (): Promise<any> => {
    return config.loadStart();
  };
}

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  const jsonFile = '.json';
  const randId = Math.floor(Math.random() * 1000000000);
  const urlFile = jsonFile + '?randId=' + randId;
  return new TranslateHttpLoader(http, './assets/i18n/admin/', urlFile);
}
