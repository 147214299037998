<app-checkbox-field
    [nameInput]="'isCoOrganized'"
    [myInputModel]="isCoOrganized"
    [checked]="isCoOrganized"
    [textLabel]="'Coorganizers.IsCoorganized'"
    (checkedEvent)="setCoOrganized($event)"
    [disabled]="noChange"
></app-checkbox-field>
<div *ngIf="isCoOrganized">
    <div class="coorganizer-email-field mt-3">
        <label for="coorganizer">
            <input
                type="email"
                name="coorganizer"
                placeholder='{{"Coorganizers.Email" | translate}}'
                (keydown.enter)="$event.preventDefault();"
                (keydown)="addCoorganizer($event)"
                (blur)="addCoorganizer($event)"
                (keyup)="checkContact()"
                [(ngModel)]="emailCoorganizer"
                email
                [disabled]="noChange"
            />
            <span>{{"Coorganizers.Email" | translate}}</span>
        </label>
    </div>
    <app-suggest-contact *ngIf="displaySuggest" [textInput]="emailCoorganizer" (selectContactEvent)="onSelectContactEvent($event)" (closeSuggestEvent)="onCloseSuggestEvent($event)">
    </app-suggest-contact>
    <ul id="email-tag-ul" class="list-tag-ul">
        <div class="list-tag" role="alert" *ngFor="let coorganizer of coorganizersList">
            <span (click)="!noChange ? editCoorganizer(coorganizer) : false">{{ coorganizer }}</span>
            <button type="button" class="button-list-tag" data-dismiss="alert" aria-label="Close" (click)="removeCoorganizer(coorganizer)" *ngIf="!noChange">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
            </button>
        </div>
    </ul>
</div>
