import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { UserViewService } from '../user-view/user-view.service';
import { User } from '../user.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { UserEditService } from 'src/app/users/user-edit/user-edit.service';
import { Country } from 'src/app/country/country.model';
import { CountryService } from 'src/app/country/country.service';
import Debug from 'debug';
const debug = Debug('virtuvisio-administration:user-edit-component');
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  providers: [CountryService, UserEditService, UserViewService],
})
export class UserEditComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  @Input() connectedUser: ConnectedUser;
  @Input() tenant: Tenant;
  hasError = false;
  finished = false;
  countryCode: CountryCode;
  countries: Country[];
  currentUser: User;
  userLangs = [
    { label: 'UserEditComponent.French', value: 'fr-FR' },
    { label: 'UserEditComponent.English', value: 'en-US' },
  ];
  @Output() destroyUserAddEvent = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserViewService,
    private notifService: NotifService,
    private userEditService: UserEditService,
    private countryService: CountryService
  ) { }

  ngOnInit(): void {
    if (this.route.firstChild) {
      this.onInitSubscriptions.push(
        this.route.firstChild.paramMap.subscribe((params) => {
          console.warn(params);
          if (params.get('userId')) {
            debug(params.get('userId'));
            this.getUser(params.get('userId') as string);
          }
        })
      );
    }
    this.getCountries();
  }

  getUser(userId: string): void {
    this.userService.getUser(this.tenant, userId).subscribe((user) => {
      this.currentUser = user;
    });
  }

  confirmEditUser(editUserForm: NgForm): void {
    if (editUserForm.valid) {
      if (editUserForm.valid) {
        const user = editUserForm.value;
        user.email = editUserForm.controls.email.value;
        if (user.phone) {
          const phoneNumber = parsePhoneNumberFromString(
            user.phone,
            this.countryCode
          );
          if (!phoneNumber?.isValid()) {
            this.notifService.addErrorNotif(
              'UserEditComponent.PhoneNumberNotValid'
            );
          } else {
            user.phone = phoneNumber.number;
            this.userEditService.save(this.currentUser.id, user).subscribe({
              next: (result) => {
                this.hasError = false;
                this.finished = true;
                this.notifService.addSuccessNotif(
                  'UserEditComponent.UserEdited'
                );
                this.backToView(this.currentUser.id);
              },
              error: (error) => {
                this.hasError = true;
                if (error.status === 409) {
                  this.notifService.addErrorNotif(
                    'UserEditComponent.UserAlreadyExists'
                  );
                } else {
                  this.notifService.addErrorNotif(
                    'UserEditComponent.ErrorWhileEditingUser'
                  );
                }
              }
            });
          }
        } else {
          this.userEditService.save(this.currentUser.id, user).subscribe({
            next: (result) => {
              this.hasError = false;
              this.finished = true;
              this.notifService.addSuccessNotif('UserEditComponent.UserEdited');
              this.backToView(this.currentUser.id);
            },
            error: (error) => {
              this.hasError = true;
              if (error.status === 409) {
                this.notifService.addErrorNotif(
                  'UserEditComponent.UserAlreadyExists'
                );
              } else {
                this.notifService.addErrorNotif(
                  'UserEditComponent.ErrorWhileEditingUser'
                );
              }
            }
          });
        }
      } else {
        this.hasError = true;
      }
    } else {
      this.hasError = true;
    }
  }

  getCountries(): void {
    this.countryService.getCountries().subscribe((countries) => {
      this.countries = countries;
      for (const c in this.countries) {
        this.countries[c].displaySelect =
          this.countries[c].code +
          ' (' +
          this.countries[c].phoneNumberPrefix +
          ')';
      }
      this.countryCode = 'FR';
    });
  }

  backToView(userId: string): void {
    this.router.navigate(['./view', userId], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve',
    });
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.destroyUserAddEvent.emit(true);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
