import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateItem',
  pure: false,
})
export class TranslateItemPipe implements PipeTransform {
  constructor(private translate: TranslateItemService) { }

  transform(value: any): string | undefined {
    if (!value) {
      return;
    }
    return this.translate.instantItem(value);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TranslateItemService {
  currentLang: string;
  defaultLang: string;

  constructor(private translate: TranslateService) {
    this.initLang();
  }

  initLang(): void {
    const uiDefaultLang = this.translate.getDefaultLang();
    const uiCurrentLang = this.translate.currentLang;
    if (uiDefaultLang === 'en') {
      this.defaultLang = 'en-US';
    } else if (uiDefaultLang === 'fr') {
      this.defaultLang = 'fr-FR';
    } else {
      this.defaultLang = 'fr-FR';
    }
    if (uiCurrentLang === 'en') {
      this.currentLang = 'en-US';
    } else if (uiCurrentLang === 'fr') {
      this.currentLang = 'fr-FR';
    } else {
      this.currentLang = 'fr-FR';
    }
  }

  private translateItem(item: any): string {
    // private perform translation
    const translation = this.translate.instant('TranslatePipe.NoFound');

    // found in current language
    if (item.translations && item.translations[this.currentLang]) {
      return item.translations[this.currentLang];
    }

    // found in default language
    if (item.translations && item.translations[this.defaultLang]) {
      return item.translations[this.defaultLang];
    }

    // not found
    return translation;
  }

  public instantItem(item: any): string {
    const itemTranslation: string = this.translateItem(item);

    return itemTranslation;
  }
}
