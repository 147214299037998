import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Endpoint, EndpointKinds, EndpointConfigSchemes } from 'src/app/endpoints/endpoint.model';
import { NgForm } from '@angular/forms';
import { EndpointsService } from 'src/app/endpoints/endpoints.service';
import { MeetingRoom } from 'src/app/meeting-rooms/meeting-room.model';
import { NotifService } from 'src/app/services/notifs/notif.service';

@Component({
  selector: 'app-add-endpoint',
  templateUrl: './add-endpoint.component.html',
  styleUrls: ['./add-endpoint.component.scss']
})
export class AddEndpointComponent implements OnInit {
  @Input() currentMeetingRoom: MeetingRoom;
  @Output() cancelAddEndpoint: EventEmitter<boolean> = new EventEmitter();
  newEndpoint: Endpoint;
  finished = false;
  hasError = false;
  endpointKinds = EndpointKinds;
  endpointConfigSchemes = EndpointConfigSchemes;

  constructor(private endpointService: EndpointsService, private notifService: NotifService) { }

  ngOnInit(): void {
    this.newEndpoint = new Endpoint({});
  }

  addEndpoint(newEndoint: NgForm): void {
    if (newEndoint.valid) {
      this.endpointService.create(this.currentMeetingRoom.id, this.newEndpoint).subscribe({
        next: () => {
          this.finished = true;
          this.hasError = false;
          this.notifService.addSuccessNotif('AddEndpoint.SuccessAddEndpoint');
          this.cancelAddEndpoint.emit(false);
        },
        error: (error) => {
          this.finished = true;
          this.hasError = true;
          this.notifService.addErrorNotif('AddEndpoint.ErrorAddEndpoint', error);
        }
      })
    } else {
      this.hasError = true;
    }
  }

  onSelectEndpointKind(event: { value: string, label: string }): void {
    this.newEndpoint = new Endpoint({});
    this.newEndpoint.kind = event.value;
  }

}
