import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MeetingRoom } from 'src/app/meeting-rooms/meeting-room.model';
import { SipAccount } from 'src/app/endpoints/endpoint.model';
import { EndpointsService } from 'src/app/endpoints/endpoints.service';
import { NotifService } from 'src/app/services/notifs/notif.service';

@Component({
  selector: 'app-add-sip-account',
  templateUrl: './add-sip-account.component.html',
  styleUrls: ['./add-sip-account.component.scss']
})
export class AddSipAccountComponent implements OnInit {

  @Input() currentMeetingRoom: MeetingRoom;
  @Output() addedSipAccount: EventEmitter<SipAccount> = new EventEmitter();
  @Output() hasSipAccountEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() addingSipAccountEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() deletedSipAccount: EventEmitter<boolean> = new EventEmitter();
  newSipAccount: SipAccount;
  finished = false;
  hasError = false;

  constructor(private endpointsService: EndpointsService, private notifService: NotifService) { }

  ngOnInit(): void {
    this.newSipAccount = new SipAccount({});
  }

  confirmAddSipAccount(sipAccountForm: NgForm): void {
    if (sipAccountForm.valid) {
      this.endpointsService.addSipAccount(this.currentMeetingRoom.id, sipAccountForm.value).subscribe({
        next: (result) => {
          this.newSipAccount = new SipAccount({});
          this.hasError = false;
          this.finished = true;
          this.notifService.addSuccessNotif("AddSipAccount.SipAccountAddSuccess");
          this.addedSipAccount.emit(result);
        },
        error: (error) => {
          this.newSipAccount = new SipAccount({});
          this.hasError = true;
          this.finished = true;
          this.notifService.addErrorNotif("AddSipAccount.SipAccountAddError", error);
        }
      })
    } else {
      this.hasError = true;
    }
  }

  deleteSipAccount(): void {
    if (this.currentMeetingRoom.endpoint.sipAccount) {
      this.endpointsService.deleteSipAccount(this.currentMeetingRoom.id).subscribe({
        next: (result) => {
          this.addingSipAccountEvent.emit(false);
          this.deletedSipAccount.emit(true);
          this.notifService.addSuccessNotif("AddSipAccount.SipAccountDeleteSuccess");
        },
        error: (error) => {
          this.hasSipAccountEvent.emit(true);
          this.notifService.addErrorNotif("AddSipAccount.SipAccountDeleteError", error);
        }
      })
    } else {
      this.addingSipAccountEvent.emit(false);
      this.hasSipAccountEvent.emit(false);
    }
  }

  addSipAccount(): void {
    this.endpointsService.addSipAccount(this.currentMeetingRoom.id).subscribe({
      next: (result) => {
        this.addedSipAccount.emit(result);
        this.addingSipAccountEvent.emit(false);
        this.newSipAccount = new SipAccount({});
        this.notifService.addSuccessNotif("Endpoints.SipAccountCreatedSuccess");
      },
      error: (error) => {
        this.hasSipAccountEvent.emit(false);
        this.notifService.addErrorNotif("Endpoints.SipAccountCreatedError", error);
      }
    })
  }
}
