import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Tenant } from 'src/app/tenant/tenant.model';
import { OauthClientsService } from '../oauth-clients.service';
import { OauthClient, ClientAccess, ClientModes, ClientReadAccess, ClientReadWriteAccess } from "../oauth-client.model";
import { ActivatedRoute, Router } from '@angular/router';
import { NotifService } from 'src/app/services/notifs/notif.service';

@Component({
  selector: 'app-add-oauth-client',
  templateUrl: './add-oauth-client.component.html',
  styleUrls: ['./add-oauth-client.component.scss']
})
export class AddOauthClientComponent implements OnInit {
  newOauthClient = new OauthClient({});
  finished = false;
  hasError = false;
  tenants: Tenant[];
  modes = ClientModes;
  mode = "Read";
  accessList = ClientAccess;
  clientAccesses: string[] = [];
  loading = false;
  constructor(private oauthClientsService: OauthClientsService, private router: Router, private route: ActivatedRoute, private notifService: NotifService) { }

  ngOnInit(): void {
    this.newOauthClient.scopes = new Map();
    this.getTenants();
  }

  confirmAddOauthClient(addOauthClient: NgForm): void {
    if (addOauthClient.valid) {
      let client = this.newOauthClient;
      client.name = addOauthClient.value.name;
      client.description = addOauthClient.value.description;
      client.secret = addOauthClient.value.secret;
      const jsonObject: any = {};
      client.scopes.forEach((value: string, key: string) => {
        if (value.length > 0) {
          jsonObject[key] = value;
        }
      });
      client.scopes = jsonObject;
      this.hasError = false;
      this.oauthClientsService.add(client).subscribe({
        next: (result) => {
          this.finished = true;
          this.notifService.addSuccessNotif('AddOauthClientComponent.SuccessAddOauthClient');
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        error: (error) => {
          this.finished = true;
          this.hasError = true;
          this.notifService.addErrorNotif('AddOauthClientComponent.ErrorAddOauthClient', error);
        }
      })
    } else {
      this.hasError = true;
    }
  }

  getTenants(): void {
    this.oauthClientsService.getTenants().subscribe({
      next: result => {
        this.tenants = result;
        const allTenants: Tenant = new Tenant({});
        allTenants.name = "AddOauthClientComponent.AllTenants";
        allTenants.id = "*";
        this.tenants.unshift(allTenants);
      },
      error: (error: HttpErrorResponse) => {
        console.log("Error getting tenants");
      }
    });
  }

  switchTarget(target: string): void {
    if (this.newOauthClient.scopes.has(target)) {
      this.clientAccesses = this.newOauthClient.scopes.get(target);
    } else {
      this.clientAccesses = [];
    }
  }

  switchAccess(event: boolean, access: string): void {
    if (event) {
      if (!this.clientAccesses.includes(access)) {
        this.clientAccesses.push(access + "/Read");
      }
    } else {
      if (this.clientAccesses.includes(access + "/Read")) {
        this.clientAccesses.splice(this.clientAccesses.indexOf(access + "/Read"), 1);
      } else if (this.clientAccesses.includes(access + "/ReadWrite")) {
        this.clientAccesses.splice(this.clientAccesses.indexOf(access + "/ReadWrite"), 1);
      }
    }
    this.newOauthClient.scopes.set(this.newOauthClient.target, this.clientAccesses);
  }

  switchReadAccess(event: boolean, access: string): void {
    if (this.clientAccesses.includes(access + "/ReadWrite")) {
      this.clientAccesses.splice(this.clientAccesses.indexOf(access + "/ReadWrite"), 1);
    }
    if (!this.clientAccesses.includes(access + "/Read")) {
      this.clientAccesses.push(access + "/Read");
    }
    this.newOauthClient.scopes.set(this.newOauthClient.target, this.clientAccesses);
  }

  switchReadWriteAccess(event: boolean, access: string): void {
    if (this.clientAccesses.includes(access + "/Read")) {
      this.clientAccesses.splice(this.clientAccesses.indexOf(access + "/Read"), 1);
    }
    if (!this.clientAccesses.includes(access + "/ReadWrite")) {
      this.clientAccesses.push(access + "/ReadWrite");
    }
    this.newOauthClient.scopes.set(this.newOauthClient.target, this.clientAccesses);
  }

  swicthAllReadAccess(): void {
    this.clientAccesses = [...ClientReadAccess];
    this.newOauthClient.scopes.set(this.newOauthClient.target, this.clientAccesses);
  }

  swicthAllReadWriteAccess(): void {
    this.clientAccesses = [...ClientReadWriteAccess];
    this.newOauthClient.scopes.set(this.newOauthClient.target, this.clientAccesses);
  }

  removeAllAccesses(): void {
    for (let access of this.accessList) {
      this.switchAccess(false, access);
    }
  }

  isCheckedAccess(access: string): boolean {
    if (this.clientAccesses.includes(access)) {
      return true;
    }
    return false
  }

  selectTenant(tenant: Tenant): void {
    this.newOauthClient.target = tenant.id;
    this.switchTarget(this.newOauthClient.target);
  }

}
