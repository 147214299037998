import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActionEvent, DisplayColumn } from '../commons/mat-table-custom/mat-table-custom.model';
import { Tenant } from '../tenant/tenant.model';
import { TenantService } from '../tenant/tenant.service';
import { MeetingRoom } from './meeting-room.model';
import { MeetingRoomsService } from './meeting-rooms.service';

@Component({
  selector: 'app-meeting-rooms',
  templateUrl: './meeting-rooms.component.html',
  styleUrls: ['./meeting-rooms.component.scss'],
  providers: [MeetingRoomsService, TenantService]
})
export class MeetingRoomsComponent implements OnInit, OnDestroy {

  selectedMeetingRoom: MeetingRoom | null;
  defaultSort = "";
  displayedColumns: DisplayColumn[];
  deleteMeetingRoom = false;
  refreshTable = false;
  addMeetingRoom = false;
  currentTenant: Tenant;
  routerEvent: RouterEvent;
  onInitSubscriptions: Subscription[] = [];

  constructor(private tenantService: TenantService, private route: ActivatedRoute, public router: Router, public meetingRoomsService: MeetingRoomsService) { }

  ngOnInit(): void {
    this.getTenant();
    this.onInitSubscriptions.push(this.router.events.subscribe(event => {
      this.routerEvent = event as RouterEvent;
    }))
    this.onInitSubscriptions.push(<Subscription>this.route.parent?.params.subscribe(params => {
      this.refreshTableData();
    }))
    if (this.route.firstChild) {
      this.onInitSubscriptions.push(this.route.firstChild.paramMap.subscribe(params => {
        let meetingRoomId = params.get('meetingRoomId');
        if (meetingRoomId) {
          this.getMeetingRoom(meetingRoomId);
        }
      }))
    }
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  getTenant(): void {
    this.tenantService.getTenant().subscribe(tenant => {
      this.currentTenant = tenant;
      if (this.currentTenant.hasAccess('ONE_CODEC') || this.currentTenant.id === '*') {
        this.displayedColumns = [
          { name: "name", type: "any" },
          { name: "email", type: "any" },
          { name: "endpoint.number", type: "any" },
          { name: "description", type: "any" },
          {
            name: "action", type: "action",
            actions: [
              { action: "edit", icon: "edit" },
              { action: "delete", icon: "trash-alt" }
            ]
          }
        ];
      } else {
        this.displayedColumns = [
          { name: "name", type: "any" },
          { name: "email", type: "any" },
          { name: "description", type: "any" },
          {
            name: "action", type: "action",
            actions: [
              { action: "edit", icon: "edit" },
              { action: "delete", icon: "trash-alt" }
            ]
          }
        ];;
      }
    })
  }

  getMeetingRoom(meetingRoomId: string): void {
    this.meetingRoomsService.getMeetingRoom(meetingRoomId).subscribe(meetingRoom => {
      this.selectedMeetingRoom = meetingRoom;
    })
  }

  onActionClick(data: ActionEvent): void {
    this.selectedMeetingRoom = data.data as MeetingRoom;
    switch (data.action.action) {
      case 'edit': {
        this.router.navigate(['/administration/' + this.selectedMeetingRoom.tenantId + '/meeting-rooms/' + this.selectedMeetingRoom.id + '/edit'], { relativeTo: this.route });
        break;
      }
      case 'delete': {
        this.deleteMeetingRoom = true;
        $('#modalOnDeleteMeetingRooms').modal('show');
        break;
      }
      default: {
        break;
      }
    }
  }

  isRoute(param: string): boolean {
    if (this.routerEvent?.url) {
      if (this.routerEvent.url.includes(param)) {
        return true;
      }
    }
    if (this.router?.url) {
      if (this.router.url.includes(param)) {
        return true;
      }
    }
    return false;
  }

  onDataError(error: any): void {
    console.log(error);
  }

  componentMeetingRoomRemoved(): void {
    this.selectedMeetingRoom = null;
    this.refreshTableData();
  }

  confirmDeleteMeetingRoom(): void {
    let meetingRoom = this.selectedMeetingRoom as MeetingRoom;
    this.meetingRoomsService.delete(meetingRoom.id).subscribe({
      next: () => {
        this.refreshTableData();
        this.deleteMeetingRoom = false;
        this.selectedMeetingRoom = null;
      },
      error: error => {
        this.refreshTableData();
        this.selectedMeetingRoom = null;
      }
    })
  }

  refreshTableData(): void {
    this.refreshTable = true;
    setTimeout(() => {
      this.refreshTable = false;
    })
  }

  onRowClick(data: any): void {
    this.selectedMeetingRoom = data;
    this.router.navigate(['/administration/' + data.tenantId + '/meeting-rooms/' + data.id + '/details'], { relativeTo: this.route, queryParams: { selectedTab: 0 } });
  }
}
