import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Meeting } from 'src/app/meeting/meeting.model';
import { PlannedMeetingsService } from '../planned-meetings.service';
import { TenantService } from 'src/app/tenant/tenant.service';
const debug = Debug('virtuvisio-administration:edit-meeting-form');

@Component({
  selector: 'app-edit-planned-meeting',
  templateUrl: './edit-planned-meeting.component.html',
  styleUrls: ['./edit-planned-meeting.component.scss'],
  providers: [TenantService]
})
export class EditPlannedMeetingComponent implements OnInit, OnDestroy {
  @ViewChild('editForm') public editForm: NgForm;
  private onInitSubscriptions: Subscription[] = [];
  currentConnectedUser: ConnectedUser;
  currentMeeting: Meeting;
  tenant: Tenant;
  private meetingId: string;
  finished = false;
  hasError = false;
  attendeesList: any[];
  coorganizersList: any[];
  offerTypes: OfferType[];
  validateMailBeforeValidate: EventEmitter<EmailValidate> = new EventEmitter();
  attendeesEmailBeforeValidateOk: boolean | undefined;
  coorganizersEmailBeforeValidationOk: boolean | undefined;
  internationalPhoneNumbers: string[] = [];
  beginDate: Date;
  endDate: Date;
  datesOk = false;
  endOk = true;
  startOk = true;

  constructor(private tenantService: TenantService, private sharedService: SharedService, private plannedMeetingsService: PlannedMeetingsService, public route: ActivatedRoute, private datesManager: DatesManager, private notif: NotifService, private router: Router) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe((connectedUser: ConnectedUser) => {
      if (connectedUser) {
        this.currentConnectedUser = connectedUser;
        this.meetingId = <string>this.route.snapshot.paramMap.get('meetingId');
        this.getMeeting(this.meetingId);
      }
    }));
    if (this.route.parent && this.route.parent.parent) {
      this.onInitSubscriptions.push(this.route.parent.parent.params.subscribe(params => {
        this.getTenant(params["tenantId"]);
      }));
    }
  }

  getTenant(tenantId: string): void {
    this.tenantService.getTenant(tenantId).subscribe((tenant: Tenant) => {
      this.tenant = tenant;
    })
  }

  getMeeting(meetingId: string): void {
    this.plannedMeetingsService.getMeeting(meetingId).subscribe({
      next: (meeting: Meeting) => {
        this.currentMeeting = new Meeting(meeting);
        this.attendeesList = meeting.attendees;
        this.coorganizersList = meeting.coManagers;
        this.beginDate = this.currentMeeting.beginDate;
        this.endDate = this.currentMeeting.endDate;
        this.checkDate(this.beginDate, this.endDate);
        this.initOfferTypes(this.currentConnectedUser);
      },
      error: (error: HttpErrorResponse) => {
        debug(error);
      }
    });
  }

  select(ev: OfferType): void {
    this.currentMeeting.offerType = ev.value;
  }

  checkDate(beginDate: Date, endDate: Date): void {
    beginDate = new Date(beginDate);
    endDate = new Date(endDate);
    this.datesOk = this.datesManager.checkDate(beginDate, endDate);
    if (!this.datesOk) {
      this.startOk = this.datesManager.invalidStart(beginDate, endDate);
      this.endOk = this.datesManager.invalidEnd(beginDate, endDate);
      if (beginDate >= endDate) {
        this.endDate = new Date(beginDate);
        this.endDate = new Date(this.endDate.setHours(beginDate.getHours() + 1));
        this.checkDate(beginDate, this.endDate);
      }
    }
    if (this.datesOk) {
      this.startOk = true;
      this.endOk = true;
    }
  }

  initOfferTypes(user: ConnectedUser): void {
    this.offerTypes = [];
    if (user.hasAllowedOffer('ONE')) {
      this.offerTypes.push({
        label: 'Invitation.OneOffer',
        value: 'ONE',
      });
    }
    if (user.hasAllowedOffer('PRO')) {
      this.offerTypes.push({
        label: 'Invitation.ProOffer',
        value: 'PRO',
      });
    }
  }

  beginDateSelect(beginDate: Date): void {
    this.beginDate = new Date(beginDate);
    this.checkDate(this.beginDate, this.endDate);
  }

  endDateSelect(endDate: Date): void {
    this.endDate = new Date(endDate);
    this.checkDate(this.beginDate, this.endDate);
  }

  editMeeting(editForm: NgForm, meeting: Meeting): void {
    if (editForm.valid) {
      this.hasError = false;
      if (!this.attendeesEmailBeforeValidateOk || !this.coorganizersEmailBeforeValidationOk) {
        this.validateMailBeforeValidate.emit({ meeting: meeting });
      } else {
        if (meeting.title && this.beginDate && this.endDate) {
          this.currentMeeting.title = meeting.title;
          this.currentMeeting.beginDate = this.beginDate;
          this.currentMeeting.endDate = this.endDate;
          this.currentMeeting.attendees = this.attendeesList;
          this.currentMeeting.coManagers = this.coorganizersList;
          if (this.internationalPhoneNumbers) {
            this.currentMeeting.internationalPhoneNumbers = this.internationalPhoneNumbers;
          }
          this.currentMeeting.body = {
            content: meeting.content ? meeting.content : '',
            contentType: 'TEXT',
          };
          delete meeting.content;
          this.plannedMeetingsService.saveMeeting(this.currentMeeting).subscribe({
            next: () => {
              this.notif.addSuccessNotif('EditPlannedMeetings.Form.Success');
              this.router.navigate(['../'], { relativeTo: this.route });
            },
            error: (error: HttpErrorResponse) => {
              if (error.status) {
                if (error.status === 412) {
                  this.notif.addErrorNotif('EditPlannedMeetings.ErrorQuota', error);
                } else {
                  this.notif.addErrorNotif('EditPlannedMeetings.ErrorStandard', error);
                }
              } else {
                this.notif.addErrorNotif('EditPlannedMeetings.ErrorStandard', error);
              }
              this.hasError = true;
            }
          })
            .add(() => {
              this.finished = true;
            });
        } else {
          this.hasError = true;
          this.notif.addErrorNotif('EditPlannedMeetings.ErrorMissingFields');
        }
      }
      this.attendeesEmailBeforeValidateOk = false;
      this.coorganizersEmailBeforeValidationOk = false;
    } else {
      this.hasError = true;
    }
  }

  setIntPhoneNumbers(event: string[]): void {
    this.internationalPhoneNumbers = event;
  }

  mailCheckEventHandlerFunction(data: CheckEmailAttendee): void {
    this.attendeesEmailBeforeValidateOk = data.attendeesEmailOk;
    this.coorganizersEmailBeforeValidationOk = data.coorganizersEmailOk;
    if (data.meeting.offerType !== 'PRO') {
      this.coorganizersEmailBeforeValidationOk = true;
    }
    if (data.meeting?.offerType === 'PRO' && !this.tenant.offers['PRO'].coOrganization) {
      this.coorganizersEmailBeforeValidationOk = true;
    }
    if (this.attendeesEmailBeforeValidateOk && this.coorganizersEmailBeforeValidationOk) {
      debug(data.meeting);
      this.editMeeting(this.editForm, data.meeting);
    }
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}

interface OfferType {
  label: string;
  value: string;
}

interface EmailValidate {
  meeting: Meeting;
}

interface CheckEmailAttendee {
  attendeesEmailOk?: boolean;
  coorganizersEmailOk?: boolean;
  meeting: Meeting;
}

