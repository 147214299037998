<label for="{{nameInput}}" class="w-100" *ngIf="typeInput !== 'number'">
    <input
        #inputModel="ngModel"
        name="{{nameInput}}"
        type="{{typeInput}}"
        id="{{nameInput}}"
        placeholder="{{textLabel|translate}}"
        [ngModelOptions]="{updateOn: 'change'}"
        [(ngModel)]="myInputModel"
        [required]="required"
        [readonly]="readonly"
        [disabled]="disabled"
        (blur)="focused=false;onBlur($event)"
        (focus)="focused=true"
        (ngModelChange)="onChange()" 
        [ngClass]="{
            'is-form-invalid': !focused && !form.controls[nameInput]?.valid && (inputModel.touched || form.submitted),
            'light': light}"
    />
    <span [ngClass]="{'light': light}">{{textLabel|translate}}</span>
    <div class="form-text is-third" *ngIf="!focused && !form.controls[nameInput]?.valid && (inputModel.touched || form.submitted)">{{'InputFormField.IsInvalid'|translate}}</div>
</label>
<label for="{{nameInput}}" class="w-100" *ngIf="typeInput === 'number'">
    <input 
        #inputModel="ngModel" 
        name="{{nameInput}}" 
        type="number" 
        id="{{nameInput}}"
        placeholder="{{textLabel|translate}}" 
        [ngModelOptions]="{updateOn: 'change'}" 
        [(ngModel)]="myInputModel"
        [required]="required" 
        [readonly]="readonly"
        [disabled]="disabled"
        [min]="minNumber"
        [max]="maxNumber" 
        (blur)="focused=false;onBlur($event)" 
        (focus)="focused=true"
        (ngModelChange)="onChange()" 
        [ngClass]="{
            'is-form-invalid': !focused && !form.controls[nameInput]?.valid && (inputModel.touched || form.submitted),
            'light': light}" />
    <span [ngClass]="{'light': light}">{{textLabel|translate}}</span>
    <!-- <div class="form-text is-third"
        *ngIf="!focused && !form.controls[nameInput]?.valid && (inputModel.touched || form.submitted)">
        {{'InputFormField.IsInvalid'|translate}}</div> -->
</label>
