import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotifService {
  constructor(private toastr: ToastrService, private translate: TranslateService) { }

  addSuccessNotif(text: string): void {
    text = this.translate.instant(text);
    this.toastr.success(undefined, text);
  }

  addErrorNotif(text: string, error?: HttpErrorResponse): void {
    text = this.translate.instant(text);
    if (error) {
      const httpError: any = error;
      this.toastr.error(httpError, text, {
        disableTimeOut: true,
        tapToDismiss: false,
      });
    } else {
      this.toastr.error(undefined, text);
    }
  }

  addErrorNotifExplicit(text: string, reason: any, error?: HttpErrorResponse): void {
    text = this.translate.instant(text);
    const completeError = text + ' : ' + reason;
    if (error) {
      const httpError: any = error;
      this.toastr.error(httpError, completeError, {
        disableTimeOut: true,
        tapToDismiss: false,
      });
    } else {
      this.toastr.error(undefined, completeError);
    }
  }

  addNoDismissNotif(text: string): void {
    text = this.translate.instant(text);
    this.toastr.warning(undefined, text, {
      tapToDismiss: false,
      disableTimeOut: true,
    });
  }

  addWarningNotif(text: string): void {
    text = this.translate.instant(text);
    this.toastr.warning(undefined, text);
  }

  addInfoNotif(text: string): void {
    text = this.translate.instant(text);
    this.toastr.info(undefined, text);
  }

  addNewVersionNotif(): void {
    this.toastr.info(undefined, "new-version-notif", {
      tapToDismiss: false,
      disableTimeOut: true,
      toastClass: "ngx-toastr new-version-toastr"
    });
  }

  addLogOutNotif(text: string): void {
    text = this.translate.instant(text);
    this.toastr.error(undefined, text);
  }
}
