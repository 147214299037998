import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { DataApiOptionService } from "src/app/services/data-api-option/data-api-option.service";
import { Group, ImportableGroup } from "../group.model";

@Injectable()
export class ImportGroupService {
  tenantId: Observable<string> = of("");

  constructor(private http: HttpClient, private optionService: DataApiOptionService, private route: ActivatedRoute) {
    this.tenantId = <Observable<string>>this.route.parent?.parent?.paramMap.pipe(map((params) => params.get("tenantId")));
  }


  getGroups(): Observable<Group[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.get<Group[]>(apiUri + "/groups", {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            })
          })
          );
      })
    );
  }

  getImportableGroups(): Observable<ImportableGroup[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.get<ImportableGroup[]>(apiUri + "/groups/importable", {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            })
          })
          );
      })
    );
  }

  import(group: ImportableGroup): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.post<unknown[]>(apiUri + "/groups/"+group.ref+"/import", null, {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            })
          })
          );
      })
    );
  }

}
