<div class="d-flex w-100 h-100 mx-auto flex-column">
  <app-header></app-header>
  <main role="main" class="cover">
    <div class="home">
      <ng-container *ngIf="(connectedUser$ | async) as connectedUser">
        <app-sidebar [connectedUser]="connectedUser" *ngIf="connectedUser"></app-sidebar>
      </ng-container>
      <div class="container-fluid p-2">
        <ng-container *ngIf="(connectedUser$ | async) as connectedUser">
          <ng-container *ngIf="connectedUser.hasRole('SUPER_ADMIN')">
            <app-admin-bloc>
              <mat-tab-group dynamicHeight animationDuration="500ms" (selectedTabChange)="switchTab($event)" [selectedIndex]="selectedTabIndex">
						    <mat-tab label="{{'UsageComponent.Live' | translate}}">
                  <app-live-usage></app-live-usage>
                </mat-tab>
                <mat-tab label="{{'UsageComponent.Stats' | translate}}">
                  <app-stats-usage></app-stats-usage>
                </mat-tab>
              </mat-tab-group>
            </app-admin-bloc>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </main>
</div>