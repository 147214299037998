<div class="d-flex w-100 h-100 mx-auto flex-column">
    <app-header></app-header>
    <main role="main" class="cover">
        <div class="home">
            <div class="container-fluid align-center p-2">
                <app-admin-card>
                    <h1>{{"HomeComponent.Welcome" | translate}}</h1>
                    <br><br>
                    <div [ngClass]="{'dual-button-container': connectedUser.hasRole('SUPER_ADMIN')}" *ngIf="connectedUser.hasRole('ADMIN') || connectedUser.hasRole('SUPER_ADMIN')">
                        <div>
                            <p>{{"HomeComponent.Admin" | translate}}</p>
                            <br>
                            <a [routerLink]="['/administration/'+connectedUser.tenantId+'/overview']" class="btn btn-app app-primary">
                                {{"HomeComponent.Button.Admin" | translate}}
                            </a>
                        </div>
                        <div *ngIf="connectedUser.hasRole('SUPER_ADMIN')">
                            <p>{{"HomeComponent.Management" | translate}}</p>
                            <br>
                            <a [routerLink]="['/management/trials']" [queryParams]="{active: true}" class="btn btn-app app-primary">
                                {{"HomeComponent.Button.Management" | translate}}
                            </a>
                        </div>
                    </div>
                    <div *ngIf="!connectedUser.hasRole('ADMIN') && !connectedUser.hasRole('SUPER_ADMIN')">
                        <p>{{"HomeComponent.NoAcces" | translate}}</p>
                        <p class="my-5">
                            <a href="{{virtuvisioAppUrl}}" class="btn btn-app app-primary">Retour sur l'app</a>
                        </p>
                    </div>
                </app-admin-card>
            </div>
            <!-- <app-footer class="footer"></app-footer> -->
        </div>
    </main>
</div>

