<div class="row">
    <div class="col-12" *ngIf="!selectedParticipant">
        <table class="table table-bordered table-striped table-hover">
            <thead class="is-bold is-primary text-center">
                <tr class="is-bold is-primary text-center">
                    <th>
                        {{"ParticipantDetailsHistoryMeeting.Name" | translate}}
                        <a class="pointer" (click)="oby='displayName'">
                            <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                        </a>
                        <a class="pointer" (click)="oby='-displayName'">
                            <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
                        </a>
                    </th>
                    <th>
                        {{"ParticipantDetailsHistoryMeeting.ParticipantEntrance" | translate}}
                        <a class="pointer" (click)="oby='startTime'">
                            <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                        </a>
                        <a class="pointer" (click)="oby='-startTime'">
                            <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
                        </a>
                    </th>
                    <th>
                        {{"ParticipantDetailsHistoryMeeting.ParticipantExit" | translate}}
                        <a class="pointer" (click)="oby='endTime'">
                            <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                        </a>
                        <a class="pointer" (click)="oby='-endTime'">
                            <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
                        </a>
                    </th>
                    <th>
                        {{"ParticipantDetailsHistoryMeeting.Protocol" | translate}}
                        <a class="pointer" (click)="oby='protocol'">
                            <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                        </a>
                        <a class="pointer" (click)="oby='-protocol'">
                            <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
                        </a>
                    </th>
                    <th>
                        {{"ParticipantDetailsHistoryMeeting.SystemLocation" | translate}}
                        <a class="pointer" (click)="oby='systemLocation'">
                            <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                        </a>
                        <a class="pointer" (click)="oby='-systemLocation'">
                            <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
                        </a>
                    </th>
                    <th>
                        {{"ParticipantDetailsHistoryMeeting.SignallingNode" | translate}}
                        <a class="pointer" (click)="oby='signallingNode'">
                            <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                        </a>
                        <a class="pointer" (click)="oby='-signallingNode'">
                            <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
                        </a>
                    </th>
                    <th>
                        {{"ParticipantDetailsHistoryMeeting.MediaNode" | translate}}
                        <a class="pointer" (click)="oby='mediaNode'">
                            <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                        </a>
                        <a class="pointer" (click)="oby='-mediaNode'">
                            <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
                        </a>
                    </th>
                    <th>
                        {{"ParticipantDetailsHistoryMeeting.CallQuality" | translate}}
                        <a class="pointer" (click)="oby='callQuality'">
                            <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                        </a>
                        <a class="pointer" (click)="oby='-callQuality'">
                            <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
                        </a>
                    </th>
                    <th>
                        {{"ParticipantDetailsHistoryMeeting.Role" | translate}}
                        <a class="pointer" (click)="oby='role'">
                            <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
                        </a>
                        <a class="pointer" (click)="oby='-role'">
                            <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let participant of selectedHistoryMeeting.participants | orderBy : oby ? oby : ''; let i = index;">
                    <td attr.data-th='{{"ParticipantDetailsHistoryMeeting.Name" | translate}}' class="pointer" (click)="selectedParticipant=participant;">{{participant.displayName}}</td>
                    <td attr.data-th='{{"ParticipantDetailsHistoryMeeting.ParticipantEntrance" | translate}}' class="pointer" (click)="selectedParticipant=participant;" *ngIf="!selectedParticipant">{{participant.startTime | date:kloodDateFormat.pipeSeconds[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</td>
                    <td attr.data-th='{{"ParticipantDetailsHistoryMeeting.ParticipantExit" | translate}}' class="pointer" (click)="selectedParticipant=participant;" *ngIf="!selectedParticipant">{{participant.endTime | date:kloodDateFormat.pipeSeconds[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</td>
                    <td attr.data-th='{{"ParticipantDetailsHistoryMeeting.Protocol" | translate}}' class="pointer" (click)="selectedParticipant=participant;" *ngIf="!selectedParticipant">{{participant.protocol}}</td>
                    <td attr.data-th='{{"ParticipantDetailsHistoryMeeting.SystemLocation" | translate}}' class="pointer" (click)="selectedParticipant=participant;" *ngIf="!selectedParticipant">{{participant.systemLocation}}</td>
                    <td attr.data-th='{{"ParticipantDetailsHistoryMeeting.SignallingNode" | translate}}' class="pointer" (click)="selectedParticipant=participant;" *ngIf="!selectedParticipant">{{participant.signallingNode}}</td>
                    <td attr.data-th='{{"ParticipantDetailsHistoryMeeting.MediaNode" | translate}}' class="pointer" (click)="selectedParticipant=participant;" *ngIf="!selectedParticipant">{{participant.mediaNode}}</td>
                    <td attr.data-th='{{"ParticipantDetailsHistoryMeeting.CallQuality" | translate}}' class="pointer" (click)="selectedParticipant=participant;" *ngIf="!selectedParticipant">
                        <span [ngClass]="{'is-secondary': participant.callQuality.indexOf('good') !== -1, 'is-expired': participant.callQuality.indexOf('good') === -1}">{{participant.callQuality}}</span>
                    </td>
                    <td attr.data-th='{{"ParticipantDetailsHistoryMeeting.Role" | translate}}' class="pointer" (click)="selectedParticipant=participant;" *ngIf="!selectedParticipant">{{participant.role}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12" *ngIf="selectedParticipant">
        <app-admin-bloc [blocTitle]="selectedParticipant.displayName" [blocControls]="[{ icon: 'times', controleType: 'ACTION', isSwitch: false, tooltip: 'Close', action: 'Back' }]" (requestAction)="closeAction()">
            <div class="row">
                <div class="col is-bold my-auto text-center">
                    <h5>{{"ParticipantDetailsHistoryMeeting.Information" | translate}}</h5>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-6">
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.participantEntrance" | translate}} :</div>
                        <div class="col">{{selectedParticipant.startTime | date:kloodDateFormat.pipeSeconds[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.participantExit" | translate}} :</div>
                        <div class="col">{{selectedParticipant.endTime | date:kloodDateFormat.pipeSeconds[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.connectTime" | translate}} :</div>
                        <div class="col">{{getConnectedTime(selectedParticipant)}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.callUuid" | translate}} :</div>
                        <div class="col">{{selectedParticipant.callUuid}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.callQuality" | translate}} :</div>
                        <div class="col">
                            <span [ngClass]="{'is-secondary': selectedParticipant && selectedParticipant.callQuality.indexOf('good') !== -1, 'is-expired': selectedParticipant && selectedParticipant.callQuality.indexOf('good') === -1}">{{selectedParticipant.callQuality}}</span>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.conversationId" | translate}} :</div>
                        <div class="col">{{selectedParticipant.conversationId}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.remoteAlias" | translate}} :</div>
                        <div class="col">{{selectedParticipant.remoteAlias}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.displayName" | translate}} :</div>
                        <div class="col">{{selectedParticipant.displayName}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.encryption" | translate}} :</div>
                        <div class="col">{{selectedParticipant.encryption}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.hasMedia" | translate}} :</div>
                        <div class="col">{{selectedParticipant.hasMedia}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.isDisconnectSupported" | translate}} :</div>
                        <div class="col">{{selectedParticipant.isDisconnectSupported}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.isMuteSupported" | translate}} :</div>
                        <div class="col">{{selectedParticipant.isMuteSupported}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.isPresentationSupported" | translate}} :</div>
                        <div class="col">{{selectedParticipant.isPresentationSupported}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.isPresenting" | translate}} :</div>
                        <div class="col">{{selectedParticipant.isPresenting}}</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.isRecording" | translate}} :</div>
                        <div class="col">{{selectedParticipant.isRecording}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.isStreaming" | translate}} :</div>
                        <div class="col">{{selectedParticipant.isStreaming}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.isTransferSupported" | translate}} :</div>
                        <div class="col">{{selectedParticipant.isTransferSupported}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.licenseCount" | translate}} :</div>
                        <div class="col">{{selectedParticipant.licenseCount}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.licenseType" | translate}} :</div>
                        <div class="col">{{selectedParticipant.licenseType}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.mediaNode" | translate}} :</div>
                        <div class="col">{{selectedParticipant.mediaNode}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.localAlias" | translate}} :</div>
                        <div class="col">{{selectedParticipant.localAlias}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.proxyNode" | translate}} :</div>
                        <div class="col">{{selectedParticipant.proxyNode}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.remoteAddress" | translate}} :</div>
                        <div class="col">{{selectedParticipant.remoteAddress}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.remotePort" | translate}} :</div>
                        <div class="col">{{selectedParticipant.remotePort}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.role" | translate}} :</div>
                        <div class="col">{{selectedParticipant.role}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.signallingNode" | translate}} :</div>
                        <div class="col">{{selectedParticipant.signallingNode}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.systemLocation" | translate}} :</div>
                        <div class="col">{{selectedParticipant.systemLocation}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"ParticipantDetailsHistoryMeeting.vendor" | translate}} :</div>
                        <div class="col">{{selectedParticipant.vendor}}</div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col is-bold my-auto text-center">
                    <h5>{{"ParticipantDetailsHistoryMeeting.MediaStreams" | translate}}</h5>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 overflow-auto">
                    <table class="table table-bordered table-striped">
                        <thead class="is-bold is-primary text-center">
                            <tr>
                                <th>{{"ParticipantDetailsHistoryMeeting.type" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.node" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.txCodec" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.txBitrate" | translate}} (kbps)</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.txResolution" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.txPacketsSent" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.txPacketsLost" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.txPacketLoss" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.txJitter" | translate}} (ms)</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.rxCodec" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.rxBitrate" | translate}} (kbps)</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.rxResolution" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.rxPacketsReceived" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.rxPacketsLost" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.rxPacketLoss" | translate}}</th>
                                <th>{{"ParticipantDetailsHistoryMeeting.rxJitter" | translate}} (ms)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let stream of selectedParticipant.streams | orderBy : oby ? oby : ''; let i = index;">
                                <td>
                                    <span *ngIf="stream.type && stream.type !== ''">{{stream.type}}</span>
                                    <span *ngIf="stream.type === '' && stream.txResolution === ''">{{"ParticipantDetailsHistoryMeeting.audio" | translate}}</span>
                                    <span *ngIf="stream.type === '' && stream.txResolution !== ''">{{"ParticipantDetailsHistoryMeeting.video" | translate}}</span>
                                </td>
                                <td>{{stream.node}}</td>
                                <td>{{stream.txCodec}}</td>
                                <td>{{stream.txBitrate}}</td>
                                <td>{{stream.txResolution}}</td>
                                <td>{{stream.txPacketsSent}}</td>
                                <td>{{stream.txPacketsLost}}</td>
                                <td>{{stream.txPacketLoss}}%</td>
                                <td>{{stream.txJitter}}</td>
                                <td>{{stream.rxCodec}}</td>
                                <td>{{stream.rxBitrate}}</td>
                                <td>{{stream.rxResolution}}</td>
                                <td>{{stream.rxPacketsReceived}}</td>
                                <td>{{stream.rxPacketsLost}}</td>
                                <td>{{stream.rxPacketLoss}}%</td>
                                <td>{{stream.rxJitter}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </app-admin-bloc>
        <div class="row" *ngIf="!selectedHistoryMeeting.participants || selectedHistoryMeeting.participants?.length === 0">
            <div class="col-12">{{"ParticipantDetailsHistoryMeeting.NoParticipantInformation" | translate}}</div>
        </div>
    </div>
</div>
