import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from '../config/app.config.service';
import { TranslateService } from '@ngx-translate/core';
import Debug from 'debug';
const debug = Debug('virtuvisio-administration:ErrorHandler');

@Injectable()
export class ErrorService {
  environment = environment;

  constructor(
    private injector: Injector,
    private router: Router,
    private configUi: AppConfigService
  ) { }

  handleError(error: HttpErrorResponse): void {
    const translateService = this.injector.get(TranslateService);
    const notifService = this.injector.get(NotifService);
    if (error.status) {
      if (error.status === 401) {
        if (!environment.production) {
          console.error(error.status);
          console.error(error);
        }
      } else if (error.status === 403) {
        const msg = translateService.instant('Error.403');
        notifService.addErrorNotif(msg, error);
        const link = ['/administration'];
        this.router.navigate(link);
      } else {
        if (!environment.production) {
          console.error(error.status);
          console.error(error);
          console.error('trace-id : ', error.headers.get('trace-id'));
          console.error('span-id : ', error.headers.get('span-id'));
        }
        if (error.status >= 500 && error.status < 600) {
          console.error('trace-id : ', error.headers.get('trace-id'));
          console.error('span-id : ', error.headers.get('span-id'));
          let uri = this.configUi.settings.API_URI;
          if (
            this.configUi.settings.API_URI.indexOf('broker.local') !== -1 ||
            this.configUi.settings.API_URI.indexOf('broker.dev') !== -1 ||
            this.configUi.settings.API_URI.indexOf('broker.integration') !== -1
          ) {
            uri = uri.replace('broker', 'logs');
          } else if (
            this.configUi.settings.API_URI.indexOf('broker.preprod') !== -1
          ) {
            uri = uri.replace('broker.', 'logs-');
          } else {
            uri = uri.replace('broker.', 'logs-prod.');
          }
          console.error(
            'link to logs : ',
            uri + '/logs/trace/' + error.headers.get('trace-id')
          );
        }
      }
    } else {
      if (!environment.production) {
        console.error(error);
      } else {
        debug('%O', error);
      }
    }
  }
}
