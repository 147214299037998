import { Validators } from '@angular/forms';
import { MatchingNameValidatorDirective } from '../directives/matching-name.directive';
import { maxMaskValidator } from '../directives/max-mask.directive';
import { minMaskValidator } from '../directives/min-mask.directive';
import { ipValidator } from '../directives/valid-ip.directive';
import { ValidPasswordValidatorDirective } from '../directives/valid-password.directive';

export const ValidatorsList = {
  RequiredOnly: [Validators.required],
  EmailOnly: [Validators.email],
  Email: [Validators.required, Validators.email],
  Password: [Validators.required, Validators.minLength(8)],
  ModeratorCode: [Validators.minLength(4), Validators.maxLength(4)],
  ValidIp: [ipValidator],
  ValidMask: [minMaskValidator, maxMaskValidator],
  ValidPassword: [ValidPasswordValidatorDirective],
  MatchingName: [MatchingNameValidatorDirective],
};
