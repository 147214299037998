export class OauthClient {
  createdAt: Date;
  deleted: boolean;
  deletedAt: Date;
  description: string;
  id: string;
  name: string;
  secret: string;
  target: string;
  scopes: any | Map<string, string[]>;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export const ClientModes = ["Read", "ReadWrite"];

export const ClientAccess = ["Meetings", "MeetingRooms", "Environments", "Continents", "Countries", "OAuth2.Clients", "Users", "Conferences.Live", "Conferences.History", "Trials", "Tenants", "Settings", "Stats", "Mails", "Contents", "Acls"];

export const ClientReadAccess = ["Meetings/Read", "MeetingRooms/Read", "Environments/Read", "Continents/Read", "Countries/Read", "OAuth2.Clients/Read", "Users/Read", "Conferences.Live/Read", "Conferences.History/Read", "Trials/Read", "Tenants/Read", "Settings/Read", "Stats/Read", "Mails/Read", "Contents/Read", "Acls/Read"];

export const ClientReadWriteAccess = ["Meetings/ReadWrite", "MeetingRooms/ReadWrite", "Environments/ReadWrite", "Continents/ReadWrite", "Countries/ReadWrite", "OAuth2.Clients/ReadWrite", "Users/ReadWrite", "Conferences.Live/ReadWrite", "Conferences.History/ReadWrite", "Trials/ReadWrite", "Tenants/ReadWrite", "Settings/ReadWrite", "Stats/ReadWrite", "Mails/ReadWrite", "Contents/ReadWrite", "Acls/ReadWrite"];
