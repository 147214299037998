<div class="chart-usage">
  <div class="timer-info">
    {{"LiveUsageComponent.Next" | translate}} : <span class="timer-counter"><b>{{timer}}</b></span>
  </div>
  <div [hidden]="usageNull || usageError" class="chart-container">
    <canvas class="charts" id="live-usage-chart" ></canvas>
  </div>
  <div *ngIf="usageNull && !usageError" class="chart-container">
    <div class="no-data">{{"LiveUsageComponent.NoData" | translate}}</div>
  </div>
  <div *ngIf="usageError && !usageNull" class="chart-container">
    <div class="no-data">{{"LiveUsageComponent.Error" | translate}}</div>
  </div>
</div>