import { Endpoint } from "../endpoints/endpoint.model";

export class MeetingRoom {
    name: string;
    adminContact: string;
    id: string;
    buildingId: string;
    createdAt: Date;
    deleted: boolean;
    deletedAt: Date;
    description: string;
    email: string;
    floorId: string;
    network: boolean;
    ethernet: boolean;
    pictures: any[];
    plugs: number;
    projectorScreen: boolean;
    remoteId: string;
    seats: number;
    tenantId: string;
    updatedAt: Date;
    visio: boolean;
    board: boolean;
    wifi: boolean;
    handicapAccess: boolean;
    videoProjector: boolean;
    screen: boolean;
    discoverable: boolean;
    endpoint: Endpoint;
    meetingRoomPics: [
        {
            pic: string;
            picId: string;
        }
    ];
    hasPictures: boolean;
    config: {};

    constructor(obj: any) {
      Object.assign(this, obj);
    }

    getId(): string {
        return this.id;
    }

    getDisplayName(): string {
        if (this.name) {
            return this.name;
        } else {
            return this.email;
        }
    }
}

export const meetingRoomEquipments = ["seats", "plugs", "screen", "videoProjector", "visio", "wifi", "network", "board", "handicapAccess"];

export class ImportableMeetingRoom {
    name: string;
    description: string;
    ref: string;

    constructor(obj: any) {
      Object.assign(this, obj);
    }
}
