<ng-container *ngIf="connectedUser && (tenant$ | async) as tenant">
    <div *ngIf="connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
        <div class="row align-items-center p-3">
            <div class="col-auto">
                <h3 class="m-0">
                    <span class="is-bold">{{"ConsentApplicationComponent.Title" | translate}}:</span>
                    <span class="is-bold is-primary"> {{tenant.name | uppercase}}</span>
                </h3>
            </div>
        </div>
        <app-admin-bloc>
            <div class="p-3 is-bold">
                {{"ConsentApplicationComponent.Explain" | translate}}
            </div>
            <div class="row">
                <div class="col-6">
                    <app-admin-infos-bloc [blocTitle]="'ConsentApplicationComponent.SyncGroup.Title'">
                        <div *ngIf="!loadingResult && !showResult || selectedResult !== 'CONSENT_SYNCHRONISATION'">
                            <div>{{"ConsentApplicationComponent.SyncGroup.Explain" | translate}}</div>
                            <div class="dual-button-container" *ngIf="!askConsent || selectedConsent !== 'CONSENT_SYNCHRONISATION'">
                                <button class="btn btn-app app-primary width-100" type="submit"
                                    (click)="consentApp(tenant, 'CONSENT_SYNCHRONISATION')">
                                    {{"ConsentApplicationComponent.Button.Window" | translate }}
                                </button>
                                <button class="btn btn-app app-secondary width-100" type="submit"
                                    (click)="askConsent = true; selectedConsent = 'CONSENT_SYNCHRONISATION'">
                                    {{"ConsentApplicationComponent.Button.Mail" | translate }}
                                </button>
                            </div>
                            <div *ngIf="askConsent && selectedConsent === 'CONSENT_SYNCHRONISATION'">
                                <form #consentSyncGroupForm="ngForm" id="consentSyncGroupForm" name="consentSyncGroupForm" (ngSubmit)="consentSyncGroupForm.valid ? askConsentApp(consentSyncGroupForm.value.adminEmail, tenant, 'CONSENT_SYNCHRONISATION') : null">
                                    <app-input-form-field [textLabel]="'ConsentApplicationComponent.Email.Input'" [typeInput]="'email'"
                                        [nameInput]="'adminEmail'" [myInputModel]="adminEmail" [form]="consentSyncGroupForm" class="form-control"
                                        [required]="true" [targetValidators]="validatorsList.Email">
                                    </app-input-form-field>
                                    <div class="dual-button-container">
                                        <button class="btn btn-app app-secondary width-100" type="reset" (click)="cancelConsent()">
                                            {{"Button.Cancel" | translate }}
                                        </button>
                                        <button class="btn btn-app app-primary width-100" type="submit">
                                            {{"Button.Send" | translate }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="result-info" *ngIf="loadingResult && !showResult && selectedResult === 'CONSENT_SYNCHRONISATION'">
                            <app-spinner-custom [isWhite]="false"></app-spinner-custom>
                        </div>
                        <div class="result-infos" *ngIf="!loadingResult && showResult && selectedResult === 'CONSENT_SYNCHRONISATION'">
                            <div *ngIf="!errors">
                                <h4 class="is-bold">{{"ConsentApplicationComponent.ConsentedApp.SUCCESS.AppName.CONSENT_SYNCHRONISATION" | translate}}</h4>
                            </div>
                            <div *ngIf="errors">
                                <h4 class="is-bold">{{"ConsentApplicationComponent.ConsentedApp.ERROR.AppName.CONSENT_SYNCHRONISATION" | translate}}</h4>
                                <p class="is-bold">{{"ConsentResultComponent.Details" | translate}}</p>
                                <p>
                                    <span class="is-bold">{{"ConsentResultComponent.Error" | translate}}</span> : {{errors.error}}
                                </p>
                                <p>
                                    <span class="is-bold">{{"ConsentResultComponent.Description" | translate}}</span> : {{errors.description}}
                                </p>
                                <p>
                                    <span class="is-bold">{{"ConsentResultComponent.Url" | translate}}</span> : {{errors.url}}
                                </p>
                            </div>
                            <button class="btn btn-app app-primary width-100" type="submit" (click)="closeResult()">
                                {{"Button.Ok" | translate }}
                            </button>
                        </div>
                    </app-admin-infos-bloc>
                </div>
                <div class="col-6">
                    <app-admin-infos-bloc [blocTitle]="'ConsentApplicationComponent.UserConnexion.Title'">
                        <div *ngIf="!loadingResult && !showResult || selectedResult !== 'CONSENT_UI_WEB'">
                            <div>{{"ConsentApplicationComponent.UserConnexion.Explain" | translate}}</div>
                            <div class="dual-button-container" *ngIf="!askConsent || selectedConsent !== 'CONSENT_UI_WEB'">
                                <button class="btn btn-app app-primary width-100" type="submit" (click)="consentApp(tenant, 'CONSENT_UI_WEB')">
                                    {{"ConsentApplicationComponent.Button.Window" | translate }}
                                </button>
                                <button class="btn btn-app app-secondary width-100" type="submit"
                                    (click)="askConsent = true; selectedConsent = 'CONSENT_UI_WEB'">
                                    {{"ConsentApplicationComponent.Button.Mail" | translate }}
                                </button>
                            </div>
                            <div *ngIf="askConsent && selectedConsent === 'CONSENT_UI_WEB'">
                                <form #consentUiWebForm="ngForm" id="consentUiWebForm" name="consentUiWebForm" (ngSubmit)="consentUiWebForm.valid ? askConsentApp(consentUiWebForm.value.adminEmail, tenant, 'CONSENT_UI_WEB') : null">
                                    <app-input-form-field [textLabel]="'ConsentApplicationComponent.Email.Input'" [typeInput]="'email'"
                                        [nameInput]="'adminEmail'" [myInputModel]="adminEmail" [form]="consentUiWebForm" class="form-control"
                                        [required]="true" [targetValidators]="validatorsList.Email">
                                    </app-input-form-field>
                                    <div class="dual-button-container">
                                        <button class="btn btn-app app-secondary width-100" type="reset" (click)="cancelConsent()">
                                            {{"Button.Cancel" | translate }}
                                        </button>
                                        <button class="btn btn-app app-primary width-100" type="submit">
                                            {{"Button.Send" | translate }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="result-info" *ngIf="loadingResult && !showResult && selectedResult === 'CONSENT_UI_WEB'">
                            <app-spinner-custom [isWhite]="false"></app-spinner-custom>
                        </div>
                        <div class="result-infos" *ngIf="!loadingResult && showResult && selectedResult === 'CONSENT_UI_WEB'">
                            <div *ngIf="!errors">
                                <h4 class="is-bold">{{"ConsentApplicationComponent.ConsentedApp.SUCCESS.AppName.CONSENT_UI_WEB" |
                                    translate}}</h4>
                            </div>
                            <div *ngIf="errors">
                                <h4 class="is-bold">{{"ConsentApplicationComponent.ConsentedApp.ERROR.AppName.CONSENT_UI_WEB" | translate}}
                                </h4>
                                <p class="is-bold">{{"ConsentResultComponent.Details" | translate}}</p>
                                <p>
                                    <span class="is-bold">{{"ConsentResultComponent.Error" | translate}}</span> : {{errors.error}}
                                </p>
                                <p>
                                    <span class="is-bold">{{"ConsentResultComponent.Description" | translate}}</span> : {{errors.description}}
                                </p>
                                <p>
                                    <span class="is-bold">{{"ConsentResultComponent.Url" | translate}}</span> : {{errors.url}}
                                </p>
                            </div>
                            <button class="btn btn-app app-primary width-100" type="submit" (click)="closeResult()">
                                {{"Button.Ok" | translate }}
                            </button>
                        </div>
                    </app-admin-infos-bloc>
                </div>
                <ng-container  *ngIf="(serverInfos$ | async) as serverInfos">
                    <ng-container *ngIf="serverInfos.companionApplications">
                        <div class="col-6" *ngFor="let app of serverInfos.companionApplications | keyvalue">
                            <app-admin-infos-bloc [blocTitle]="'ConsentApplicationComponent.'+app.key+'.Title'" >
                                <div *ngIf="!loadingResult && !showResult || selectedResult !== app.key">
                                    <div>{{"ConsentApplicationComponent."+app.key+".Explain" | translate}}</div>
                                    <div class="dual-button-container" *ngIf="!askConsent || selectedConsent !== app.key">
                                        <button class="btn btn-app app-primary width-100" type="submit" (click)="consentApp(tenant, app.key, app)">
                                            {{"ConsentApplicationComponent.Button.Window" | translate }}
                                        </button>
                                        <button class="btn btn-app app-secondary width-100" type="submit"
                                            (click)="askConsent = true; selectedConsent = app.key">
                                            {{"ConsentApplicationComponent.Button.Mail" | translate }}
                                        </button>
                                    </div>
                                    <div *ngIf="askConsent && selectedConsent === app.key">
                                        <form #consentAppForm="ngForm" id="consentAppForm" name="consentAppForm" (ngSubmit)="consentAppForm.valid ? askConsentApp(consentAppForm.value.adminEmail, tenant, app.key, app) : null">
                                            <app-input-form-field [textLabel]="'ConsentApplicationComponent.Email.Input'" [typeInput]="'email'"
                                                [nameInput]="'adminEmail'" [myInputModel]="adminEmail" [form]="consentAppForm" class="form-control"
                                                [required]="true" [targetValidators]="validatorsList.Email">
                                            </app-input-form-field>
                                            <div class="dual-button-container">
                                                <button class="btn btn-app app-secondary width-100" type="reset" (click)="cancelConsent()">
                                                    {{"Button.Cancel" | translate }}
                                                </button>
                                                <button class="btn btn-app app-primary width-100" type="submit">
                                                    {{"Button.Send" | translate }}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="result-info" *ngIf="loadingResult && !showResult && selectedResult === app.key">
                                    <app-spinner-custom [isWhite]="false"></app-spinner-custom>
                                </div>
                                <div class="result-infos" *ngIf="!loadingResult && showResult && selectedResult === app.key">
                                    <div *ngIf="!errors">
                                        <h4 class="is-bold">{{"ConsentApplicationComponent.ConsentedApp.SUCCESS.AppName."+app.key |
                                            translate}}</h4>
                                    </div>
                                    <div *ngIf="errors">
                                        <h4 class="is-bold">{{"ConsentApplicationComponent.ConsentedApp.ERROR.AppName."+app.key | translate}}
                                        </h4>
                                        <p class="is-bold">{{"ConsentResultComponent.Details" | translate}}</p>
                                        <p>
                                            <span class="is-bold">{{"ConsentResultComponent.Error" | translate}}</span> : {{errors.error}}
                                        </p>
                                        <p>
                                            <span class="is-bold">{{"ConsentResultComponent.Description" | translate}}</span> : {{errors.description}}
                                        </p>
                                        <p>
                                            <span class="is-bold">{{"ConsentResultComponent.Url" | translate}}</span> : {{errors.url}}
                                        </p>
                                    </div>
                                    <button class="btn btn-app app-primary width-100" type="submit" (click)="closeResult()">
                                        {{"Button.Ok" | translate }}
                                    </button>
                                </div>
                            </app-admin-infos-bloc>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </app-admin-bloc>
    </div>
</ng-container>
