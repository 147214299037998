import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { Actions, Controls, ControlType } from '../toolbar/toolbar.model';

@Component({
  selector: 'app-admin-details-bloc',
  templateUrl: './admin-details-bloc.component.html',
  styleUrls: ['./admin-details-bloc.component.scss'],
})
export class AdminDetailsBlocComponent implements OnDestroy, OnInit {
  @Input() scrollable: boolean;
  @Input() blocTitle: string;
  @Input() blocControls: Controls[];
  @Input() closeControl = true;
  @Output() requestAction = new EventEmitter<string>();
  @Output() switchChanged = new EventEmitter<boolean>();
  @Output() closeEvent = new EventEmitter<null>();
  connectedUser: ConnectedUser;
  onInitSubscriptions: Subscription[] = [];
  Type = ControlType;
  actions = Actions;
  joinFrom: string;
  fullParams: ParamMap;

  constructor(
    private sharedService: SharedService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(
      this.sharedService.currentConnectedUser.subscribe(
        (user: ConnectedUser) => {
          if (user) {
            this.connectedUser = user;
          }
        }
      )
    );
    this.onInitSubscriptions.push(
      this.route.queryParamMap.subscribe((params) => {
        this.joinFrom = params.get('joinFrom') as string;
        this.fullParams = params;
      })
    );
  }

  onSwitchChange(event: boolean) {
    this.switchChanged.emit(event);
  }

  onRequestAction(action?: string): void {
    this.requestAction.emit(action);
  }

  closeDetails(): void {
    this.closeEvent.emit();
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
