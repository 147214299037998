import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PexipParticipant } from 'src/app/pexip-participant/pexip-participant.model';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { DatesManager } from 'src/app/services/dates/dates.manager';

@Injectable()
export class ParticipantDetailsHistoryMeetingsService {
  tenantId: Observable<string> = of('');

  constructor(private datesManager: DatesManager, private http: HttpClient, private optionService: DataApiOptionService, private route: ActivatedRoute) {
    this.tenantId = <Observable<string>>(
      this.route.parent?.paramMap.pipe(map((params) => params.get('tenantId')))
    );
  }

  getParticipants(conferenceId: string): Observable<PexipParticipant[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .get<PexipParticipant[]>(
                apiUri +
                '/conferences/history/' +
                conferenceId +
                '/participants',
                {
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => {
                  const participants = [];
                  for (const u in result) {
                    result[u].duration = this.datesManager.calculateDuration(
                      result[u].connectTime as Date
                    );
                    participants.push(<PexipParticipant>result[u]);
                  }
                  return participants;
                }),
                tap((result) => result),
                catchError(
                  this.handleError<PexipParticipant[]>('getParticipants', [])
                )
              );
          })
        );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
