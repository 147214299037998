import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DisplayColumn } from 'src/app/commons/mat-table-custom/mat-table-custom.model';
import { Controls } from 'src/app/commons/toolbar/toolbar.model';
import { PexipParticipant } from 'src/app/pexip-participant/pexip-participant.model';
import { LiveConference } from '../live-conference.model';
import { LiveConferenceService } from '../live-conferences.service';
import { PexipParticipantService } from '../participants-live-conference/pexip-participants.service';

@Component({
	selector: 'app-details-live-conference',
	templateUrl: './details-live-conference.component.html',
	styleUrls: ['./details-live-conference.component.scss'],
	providers: [LiveConferenceService, PexipParticipantService]
})
export class DetailsLiveConferenceComponent implements OnInit, OnDestroy {
	
	participants: MatTableDataSource<PexipParticipant> = new MatTableDataSource();
	onInitSubscriptions: Subscription[] = [];
	conferenceId: string;
	defaultSort = "participantAlias";
	loading = false;
	loadingParticipants = false;
	currentConference: LiveConference;
	controls: Controls[] = [{
		icon: 'times',
		doubleIcon: false,
		secondIcon: 'times',
		controleType: 'ACTION',
		action: 'BACK',
		tooltip: 'back to previous',
		isSwitch: false
	}];
	displayedColumns: DisplayColumn[] = [];
	tenantId: string | null;
	
	constructor(private router: Router, private location: Location, private route: ActivatedRoute, private liveConferenceService: LiveConferenceService, public pexipParticipantService: PexipParticipantService) { }
	
	ngOnInit(): void {
		if(this.route.parent) {
			this.onInitSubscriptions.push(this.route.parent.paramMap.subscribe((params) => {
				this.tenantId = params.get('tenantId');
			}));
		}
		this.onInitSubscriptions.push(this.route.paramMap.subscribe((params) => {
			this.conferenceId = <string>params.get('conferenceId');
			this.getLiveConferences();
		}))
		this.displayedColumns = [
			{ name: "participantAlias", type: "any" },
			{ name: "callQuality", type: "any" },
			{ name: "connectTime", type: "date" },
			{ name: "duration", type: "any" },
			{ name: "displayName", type: "any" },
			{ name: "systemLocation", type: "any" },
			{ name: "signallingNode", type: "any" },
			{ name: "mediaNode", type: "any" },
			{ name: "protocol", type: "any" },
		];
	}
	
	getLiveConferences(): void {
		this.loading = true;
		this.liveConferenceService.getLiveConferences().subscribe((liveConferences: LiveConference[]) => {
			for(let conference of liveConferences) {
				if(conference.id === this.conferenceId){
					this.currentConference = conference;
					this.getParticipants();
				}
			}
			this.loading = false;
		})
	}
	
	getParticipants(): void {
		this.loadingParticipants = true;
		this.pexipParticipantService.getParticipants(this.currentConference.name).subscribe((participants: PexipParticipant[]) => {
			this.loadingParticipants = false;
			this.participants = new MatTableDataSource(participants);
		})
	}
	
	closeAction(action: string): void {
		if(action === 'BACK') {
			this.location.back();
		}
	}
	
	onRowClick(data: PexipParticipant): void {
		let link = ["/administration/"+ this.tenantId + "/live-conferences/"+this.conferenceId+"/"+data.id];
		this.router.navigate(link);
	}
	
	onDataError(event: any): void {
		console.log(event);
	}
	
	ngOnDestroy(): void {
		this.onInitSubscriptions.forEach(subscription => {
			subscription.unsubscribe();
		})
	}
}
