import { Component, Input } from '@angular/core';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';

const debug = Debug('virtuvisio-administration:header-management-menu');

@Component({
  selector: 'app-management-navbar',
  templateUrl: './management-navbar.component.html',
  styleUrls: ['./management-navbar.component.scss'],
})
export class ManagementNavbarComponent {
  @Input() connectedUser: ConnectedUser;

  constructor() { }
}
