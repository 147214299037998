import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DisplayColumn } from '../commons/mat-table-custom/mat-table-custom.model';
import { SharedService } from '../services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { MeetingHistory } from './history-meeting.model';
import { HistoryMeetingsService } from './history-meetings.service';
import { ExportService } from 'src/app/services/export/export.service';
import { ActivatedRoute } from '@angular/router';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { NotifService } from '../services/notifs/notif.service';
declare var navigator: any;

@Component({
  selector: 'app-history-meetings',
  templateUrl: './history-meetings.component.html',
  styleUrls: ['./history-meetings.component.scss'],
  providers: [HistoryMeetingsService, ExportService]
})
export class HistoryMeetingsComponent implements OnInit, OnDestroy {

  constructor(public historyMeetingsService: HistoryMeetingsService, public sharedService: SharedService, private exportService: ExportService, private route: ActivatedRoute, private datesManager: DatesManager, private notif: NotifService) { }

  defaultSort = "startedAt";
  displayedColumns: DisplayColumn[];
  selectedHistoryMeeting: MeetingHistory;
  currentConnectedUser: ConnectedUser;
  currentConnectedUserSubscription: Subscription;
  beginDate = new Date();
  endDate = new Date();
  onInitSubscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.currentConnectedUserSubscription = this.sharedService.currentConnectedUser.subscribe((user: ConnectedUser) => {
      this.currentConnectedUser = user;
    })
    this.displayedColumns = [
      { name: "organizer.displayName", type: "any" },
      { name: "offer", type: "any" },
      { name: "alias", type: "any" },
      { name: "startedAt", type: "date" },
      { name: "terminatedAt", type: "date" },
      { name: "instantMessageCount", type: "any" },
      { name: "participantCount", type: "any" },
      { name: "maxConcurrentParticipants", type: "any" }
    ];

    this.route.queryParamMap.subscribe(queryParams => {
      let from = queryParams.get('from');
      let to = queryParams.get('to');
      if (from) {
        this.beginDate = new Date(from);
      }
      if (to) {
        this.endDate = new Date(to);
      }
    })
  }

  onRowClick(event: MeetingHistory): void {
    this.selectedHistoryMeeting = event;
  }

  onDataError(error: any): void {
    console.log(error);
    this.notif.addErrorNotif('HistoryMeetings.Error', error);
  }

  onCloseDetailsEvent(): void {
    this.selectedHistoryMeeting = null as any;
  }

  ngOnDestroy(): void {
    if (this.currentConnectedUserSubscription) {
      this.currentConnectedUserSubscription.unsubscribe();
    }
    this.onInitSubscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  exportHistoryMeetings(): void {
    const fields = "organizer.firstName,organizer.lastName,organizer.email,offer,alias,startedAt,terminatedAt,duration,instantMessageCount,participantCount,maxConcurrentParticipants";
    const url = "/conferences/history";
    this.exportService.exportDataHistoryMeetings(url, fields, this.beginDate, this.endDate).subscribe((result) => {
      this.downloadExport(result);
    });
  }

  downloadExport(res: string): void {
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe(result => {
      let from = this.route.snapshot.queryParamMap.get('from');
      if (from) {
        this.beginDate = new Date(from);
      }
      let to = this.route.snapshot.queryParamMap.get('to');
      if (to) {
        this.endDate = new Date(to);
      }
      if (result) {
        let localeDateString = result.lang.split('-')[0];
        let date = new Date().toLocaleDateString(localeDateString);
        let time = new Date().toLocaleTimeString(localeDateString);
        const splitDate = date.split("/");
        date = splitDate[0] + "-" + splitDate[1] + "-" + splitDate[2];
        const splitTime = time.split(":");
        time = splitTime[0] + "h" + splitTime[1] + "m" + splitTime[2];
        let split;
        if (localeDateString === "fr") {
          split = " à ";
        } else {
          split = ", ";
        }
        const exportDate = date + split + time;
        let fromDate = new Date(this.beginDate).toLocaleDateString(localeDateString);
        let fromTime = new Date(this.datesManager.fromStartDay(this.beginDate)).toLocaleTimeString(localeDateString);
        const splitFromDate = fromDate.split("/");
        fromDate = splitFromDate[0] + "-" + splitFromDate[1] + "-" + splitFromDate[2];
        const splitFromTime = fromTime.split(":");
        fromTime = splitFromTime[0] + "h" + splitFromTime[1] + "m" + splitFromTime[2];
        const exportFromDate = fromDate + split + fromTime;
        let toDate = new Date(this.endDate).toLocaleDateString(localeDateString);
        let toTime = new Date(this.datesManager.toEndDay(this.endDate)).toLocaleTimeString(localeDateString);
        const splitToDate = toDate.split("/");
        toDate = splitToDate[0] + "-" + splitToDate[1] + "-" + splitToDate[2];
        const splitToTime = toTime.split(":");
        toTime = splitToTime[0] + "h" + splitToTime[1] + "m" + splitToTime[2];
        const exportToDate = toDate + split + toTime;
        const exportFilename = "export historique des réunions " + exportDate + ", reunions du " + exportFromDate + " au " + exportToDate + ".csv";
        const csvData = new Blob([res], { type: "text/csv;charset=utf-8;" });
        // IE11 & Edge
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(csvData, exportFilename);
        } else {
          // In FF link must be added to DOM to be clicked
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(csvData);
          link.setAttribute("download", exportFilename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }))
  }

}
