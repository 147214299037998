<div class="header my-auto" [ngClass]="{'shadow-element': !isRoute('/administration/')}">
    <app-main-navbar [connectedUser]="connectedUser"></app-main-navbar>
    <div class="shadow-container" [ngClass]="{'shadow-element': isRoute('/administration/')}">
        <app-management-navbar [connectedUser]="connectedUser"></app-management-navbar>
        <div class="push">
            <div class="row w-100 justify-content-end">
                <div class="col-3 text-center">
                    <div class="home-menu">
                        <a href="{{customAppUrl ? customAppUrl : virtuvisioAppUrl}}" *ngIf="connectedUser">
                            <fa-icon
                                class="pointer menu-icon"
                                [icon]="['fas', 'home']"
                                #tooltip="matTooltip"
                                matTooltip="{{'Tooltip.HomeApp' | translate}}"
                                matTooltipClass="custom-tooltip">
                            </fa-icon>
                        </a>
                    </div>
                </div>
                <div class="col-3 text-center" *ngIf="connectedUser && connectedUser.hasRole('SUPER_ADMIN')">
                    <app-masquerade-menu
                        class="text-start"
                        #tooltip="matTooltip" 
                        (justDisplayMasqueradeMenu)="justDisplayMasqueradeMenu($event)"
                        [connectedUser]="connectedUser" 
                        [display]="displayMasqueradeMenu"
                        [matTooltipDisabled]="displayMasqueradeMenu" 
                        matTooltip="{{'Tooltip.MasqueradeMenu' | translate}}"
                        matTooltipClass="custom-tooltip">
                    </app-masquerade-menu>
                </div>
                <div class="col-3 text-center" *ngIf="connectedUser">
                    <app-user-menu 
                        class="text-start" 
                        #tooltip="matTooltip" 
                        (justDisplayUserMenu)="justDisplayUserMenu($event)"
                        [connectedUser]="connectedUser" 
                        [display]="displayUserMenu"
                        [matTooltipDisabled]="displayUserMenu" 
                        matTooltip="{{'Tooltip.UserMenu' | translate}}"
                        matTooltipClass="custom-tooltip">
                    </app-user-menu>
                </div>
            </div>
        </div>
    </div>
</div>

