import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notif-toastr-component',
  styles: [],
  templateUrl: './notif.toastr.component.html',
})
export class NotifToastrComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer!: ToastContainerDirective;

  @Input() size: string;
  @Input() isMiniNotif: boolean;

  constructor(private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.toastrService.overlayContainer = this.toastContainer;
  }

  checkNewVersion(): HTMLElement {
    return <HTMLElement>document.querySelector('#info-new-version');
  }
}
