<ng-container *ngIf="connectedUser && (tenant$ | async) as tenant">
	<div class="row align-items-center p-3">
		<div class="col-auto">
			<h3 class="m-0">
				<span class="is-bold">{{"OverviewComponent.Title.General" | translate}}:</span> 
				<span class="is-bold is-primary"> {{tenant.name | uppercase}}</span>
			</h3>
		</div>
		<div class="col">
			<button class="btn btn-app app-primary float-end" type="submit" [routerLink]="['../sync']">
				<fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>&nbsp;{{"OverviewComponent.Button.Sync" | translate }}
			</button>
		</div>
	</div>
	<app-admin-bloc>
		<div class="parent-offers">
			<div class="child-offer">
				<app-admin-infos-bloc [blocTitle]="'OverviewComponent.Title.Infos'" [blocControls]="connectedUser.isGranted(tenant.id, 'MANAGEMENT') ? infosToolbar : []" (requestAction)="onInformationsAction($event, tenant)" >
					<div *ngIf="!editingInfos">
						<p>
							<span class="is-bold">{{"OverviewComponent.Infos.Auth" | translate}}:</span>
							<span>{{tenant.authProvider}}</span>
						</p>
						<p>
							<span class="is-bold">{{"OverviewComponent.Infos.Description" | translate}}:</span>
							<span>{{tenant.description}}</span>
						</p>
						<p>
							<span class="is-bold">{{"OverviewComponent.Infos.EmailReport" | translate}}:</span>
							<span>{{tenant.reportEmail}}</span>
						</p>
						<p>
							<span class="is-bold">{{"OverviewComponent.Infos.Environment" | translate}}:</span>
							<span>{{getEnvironmentName(tenant.environmentId) | translate}}</span>
						</p>
						<p>
							<span class="is-bold">{{"OverviewComponent.Infos.Lang" | translate}}:</span>
							<span>{{tenant.defaultLanguage | translate}}</span>
						</p>
					</div>
					<div id="editInfosForm" name="editInfosForm" *ngIf="editingInfos">
						<app-input-field [textLabel]="'OverviewComponent.Infos.Description'" [typeInput]="'text'" [nameInput]="'description'" [myInputModel]="tenant.description" (modelChangeEvent)="tenant.description = $event" class="form-control"></app-input-field>
						<app-input-field [textLabel]="'OverviewComponent.Email'" [typeInput]="'email'" [nameInput]="'reportEmail'" [myInputModel]="tenant.reportEmail" (modelChangeEvent)="tenant.reportEmail = $event" class="form-control"></app-input-field>
						<app-input-dropdown-field [defaultText]="'OverviewComponent.Infos.Environment' | translate" [modelKey]="'id'" [displayKey]="'host'" [selectedElement]="tenant.environmentId" [dataSource]="environments" (selectedData)="tenant.environmentId = $event.id" class="form-control"></app-input-dropdown-field>
						<app-input-dropdown-field [defaultText]="'OverviewComponent.Infos.Lang' | translate" [modelKey]="'lang'" [displayKey]="'lang'" [selectedElement]="tenant.defaultLanguage" [dataSource]="languages" (selectedData)="tenant.defaultLanguage = $event.lang" class="form-control"></app-input-dropdown-field>
						<div class="dual-button-container">
							<button class="btn btn-app app-secondary width-100" type="reset" (click)="onInformationsAction('CANCEL', tenant)">{{"OverviewComponent.EWSConfig.Form.Button.Cancel" | translate }}</button>
							<app-loading-button (submitEvent)="onInformationsAction('SAVE', tenant)" [targetStyle]="'primary'" [initialText]="'OverviewComponent.EWSConfig.Form.Button.Valid'" [finalText]="'OverviewComponent.EWSConfig.Form.Button.Validate'"></app-loading-button>
						</div>
					</div>
				</app-admin-infos-bloc>
			</div>
			<div class="child-offer" *ngIf="connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
				<app-admin-infos-bloc [blocTitle]="'OverviewComponent.Title.Services'">
					<div *ngIf="connectedUser.hasRole('SUPER_ADMIN')">
						<app-checkbox-field [nameInput]="'kloodApp'" [myInputModel]="tenant.allowedServices.indexOf('KLOOD_APP') !== -1" [checked]="tenant.allowedServices.indexOf('KLOOD_APP') !== -1" [textLabel]="'OverviewComponent.Services.KloodApp.Label'" (checkedEvent)="allowKloodApp($event, tenant);">
						</app-checkbox-field>
					</div>
					<div *ngIf="!connectedUser.hasRole('SUPER_ADMIN')">
						<app-checkbox-field [nameInput]="'kloodApp'" [myInputModel]="tenant.disabledServices.indexOf('KLOOD_APP') === -1 && tenant.allowedServices.indexOf('KLOOD_APP') !== -1" [checked]="tenant.disabledServices.indexOf('KLOOD_APP') === -1 && tenant.allowedServices.indexOf('KLOOD_APP') !== -1" [textLabel]="'OverviewComponent.Services.KloodApp.Label'" (checkedEvent)="disableKloodApp($event, tenant, 'KLOOD_APP');" [disabled]="tenant.allowedServices.indexOf('KLOOD_APP') === -1 || !connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
						</app-checkbox-field>
					</div>
				</app-admin-infos-bloc>
			</div>
			<div class="child-offer" *ngIf="connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
				<app-admin-infos-bloc [blocTitle]="'OverviewComponent.Title.Options'">
					<app-checkbox-field [nameInput]="'support'" [myInputModel]="tenant.support" [checked]="tenant.support" [textLabel]="'OverviewComponent.Options.Support'" (checkedEvent)="tenant.support = $event; editTenant(tenant)" [disabled]="!connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
					</app-checkbox-field>
					<app-checkbox-field [nameInput]="'shareContacts'" [myInputModel]="tenant.shareContacts" [checked]="tenant.shareContacts" [textLabel]="'OverviewComponent.Options.ShareContacts'" (checkedEvent)="tenant.shareContacts = $event; editTenant(tenant)" [disabled]="!connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
					</app-checkbox-field>
				</app-admin-infos-bloc>
			</div>
			<div class="child-offer">
				<app-admin-infos-bloc [blocTitle]="'OverviewComponent.Title.Overview'">
					<p>
						<span class="is-bold">{{"OverviewComponent.Overview.Offers" | translate}}:</span>
						<span *ngIf="!tenant.offers"></span>
						<span *ngIf="tenant.offers">{{"OverviewComponent.Overview.EnableOffers" | translate}} : <a [routerLink]="['../offers']" [class.disabled]="!connectedUser.hasRole('SUPER_ADMIN')" >{{countEnabledOffers(tenant.offers)}}</a> / {{"OverviewComponent.Overview.AvailableOffers" | translate}} : {{countOffers(tenant.offers)}} </span>
					</p>
					<p *ngIf="tenant.authProvider === 'microsoft' && connectedUser.isGranted(tenant.id, 'USERS') && !connectedUser.hasRole('MARKETING')">
						<span class="is-bold">{{"OverviewComponent.Overview.Groups" | translate}}:</span>
						<span><a [routerLink]="['../groups']" [class.disabled]="!connectedUser.isGranted(tenant.id, 'USERS')">{{groupsLength$ | async}}</a></span>
					</p>
					<p *ngIf="connectedUser.isGranted(tenant.id, 'USERS')">
						<span class="is-bold">{{"OverviewComponent.Overview.Users" | translate}}:</span>
						<span><a [routerLink]="['../users']" [class.disabled]="!connectedUser.isGranted(tenant.id, 'USERS')">{{usersLength$ | async}}</a></span>
					</p>
					<p *ngIf="connectedUser.isGranted(tenant.id, 'MEETING_ROOMS')">
						<span class="is-bold">{{"OverviewComponent.Overview.MeetingRooms" | translate}}:</span>
						<span><a [routerLink]="['../meeting-rooms']" [class.disabled]="!connectedUser.isGranted(tenant.id, 'MANAGEMENT')" >{{meetingRoomsLength$ | async}}</a></span>
					</p>
				</app-admin-infos-bloc>
			</div>
			<div class="child-offer" *ngIf="tenant.authProvider === 'microsoft' && tenant.authConfig && connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
				<app-admin-infos-bloc [blocTitle]="'OverviewComponent.Title.Auth'">
					<p>
						<span class="is-bold">{{"OverviewComponent.Auth.TenantId" | translate}}:</span>
						<span>{{tenant.authConfig.tenantId}}</span>
					</p>
					<p>
						<span class="is-bold">{{"OverviewComponent.Auth.Validated" | translate}}:</span>
						<span *ngIf="tenant.authConfig.validated"><fa-icon class="consent-valid" [icon]="['fas', 'check']"></fa-icon> {{"OverviewComponent.Auth.Valid.Yes" | translate}}</span>
						<span *ngIf="!tenant.authConfig.validated"><fa-icon class="consent-invalid" [icon]="['fas', 'times']"></fa-icon> {{"OverviewComponent.Auth.Valid.No" | translate}}</span>
					</p>
				</app-admin-infos-bloc>
			</div>
			<div class="child-offer" *ngIf="connectedUser.isGranted(tenant.id, 'MANAGEMENT')">
				<app-admin-infos-bloc [blocTitle]="'OverviewComponent.Title.Ews'" [blocControls]="connectedUser.isGranted(tenant.id, 'MANAGEMENT') ? ewsToolbar : []" (requestAction)="onEwsConfigAction($event, tenant, ewsConfig)">
					<div *ngIf="tenant.ewsConfig">
						<div *ngIf="!editingEwsConfig && !deletingEwsConfig">
							<div class="row margin-bottom-20">
								<div class="col-6">
									<button class="btn btn-sm btn-app app-secondary flat" (click)="validateEwsConfig(tenant)" *ngIf="!loading">{{"OverviewComponent.EWSConfig.Form.Check" | translate}}</button>
									<app-loading-spinner [diameter]="25" class="app-loader-primay" *ngIf="loading"></app-loading-spinner>
								</div>
								<div class="col-6">
									<div *ngIf="checkResult">
										<div *ngIf="!ewsOk" class="ews-invalid">{{"OverviewComponent.EWSConfig.Form.Nok" | translate}}</div>
										<div *ngIf="ewsOk" class="ews-valid">{{"OverviewComponent.EWSConfig.Form.Ok" | translate}}</div>
									</div>
								</div>
							</div>
							<p>
								<span class="is-bold">{{"OverviewComponent.EWSConfig.Domain" | translate}} : </span>
								<span>{{tenant.ewsConfig.domain}}</span>
							</p>
							<p>
								<span class="is-bold">{{"OverviewComponent.EWSConfig.Username" | translate}} : </span>
								<span>{{tenant.ewsConfig.username}}</span>
							</p>
							<p>
								<span class="is-bold">{{"OverviewComponent.EWSConfig.Password" | translate}} : </span>
								<span>{{hidePasswordText(tenant.ewsConfig.password)}}</span>
							</p>
							<p>
								<span class="is-bold">{{"OverviewComponent.EWSConfig.EwsUrl" | translate}} : </span>
								<span>{{tenant.ewsConfig.ewsUrl}}</span>
							</p>
						</div>                                
					</div>
					<div *ngIf="deletingEwsConfig && !editingEwsConfig">
						<div class="p-3 is-bold">
							{{"OverviewComponent.EWSConfig.ConfirmDelete" | translate}}
						</div>
						<div class="dual-button-container">
							<button class="btn btn-app app-secondary width-100" type="reset" (click)="onEwsConfigAction('CANCEL', tenant, ewsConfig)">{{"OverviewComponent.EWSConfig.Form.Button.Cancel" | translate }}</button>
							<app-loading-button (submitEvent)="confirmDeleteEwsConfig(tenant)" [targetStyle]="'primary'" [initialText]="'OverviewComponent.EWSConfig.Form.Button.Valid'" [finalText]="'OverviewComponent.EWSConfig.Form.Button.Validate'"></app-loading-button>
						</div>
					</div>
					<div *ngIf="editingEwsConfig && !deletingEwsConfig">
						<form #editEwsConfigForm="ngForm" id="editEwsConfigForm" name="editEwsConfigForm">
							<app-input-form-field [nameInput]="'domain'" [typeInput]="'text'" [myInputModel]="ewsConfig.domain" [textLabel]="'OverviewComponent.EWSConfig.Domain'" [form]="editEwsConfigForm" class="form-control" [required]="true">
							</app-input-form-field>
							<app-input-form-field [nameInput]="'username'" [typeInput]="'text'" [myInputModel]="ewsConfig.username" [textLabel]="'OverviewComponent.EWSConfig.Username'" [form]="editEwsConfigForm" class="form-control" [required]="true">
							</app-input-form-field>
							<app-input-form-field [nameInput]="'password'" [typeInput]="'password'" [myInputModel]="ewsConfig.password" [textLabel]="'OverviewComponent.EWSConfig.Password'" [form]="editEwsConfigForm" class="form-control" [required]="true">
							</app-input-form-field>
							<app-input-form-field [nameInput]="'ewsurl'" [typeInput]="'text'" [myInputModel]="ewsConfig.ewsUrl" [textLabel]="'OverviewComponent.EWSConfig.EwsUrl'" [form]="editEwsConfigForm" class="form-control">
							</app-input-form-field>
							<div class="dual-button-container">
								<button class="btn btn-app app-secondary width-100" type="reset" (click)="onEwsConfigAction('CANCEL', tenant, editEwsConfigForm.value)">{{"OverviewComponent.EWSConfig.Form.Button.Cancel" | translate }}</button>
								<app-loading-button (submitEvent)="onEwsConfigAction('SAVE', tenant, editEwsConfigForm.value)" [targetStyle]="'primary'" [initialText]="'OverviewComponent.EWSConfig.Form.Button.Valid'" [finalText]="'OverviewComponent.EWSConfig.Form.Button.Validate'"></app-loading-button>
							</div>
						</form>
					</div>
				</app-admin-infos-bloc>
			</div>
		</div>
	</app-admin-bloc>
	<div class="modal fade custom-app-modal" id="modalOnDeleteTenant" role="dialog" aria-labelledby="modalOnDeleteTenantLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content flat">
				<div class="modal-header">
					<h3 class="modal-title text-center" id="modalOnDeleteTenantLabel">{{"OverviewComponent.Modal.Delete" | translate}}</h3>
				</div>
				<div class="modal-body">
					<div class="is-bold">{{"OverviewComponent.Modal.SureDeleteTenant" | translate}}</div>
					<div class="mb-3">
						<app-input-field [nameInput]="'tenantName'" [typeInput]="'text'" [myInputModel]="tenantNameToDelete"
						[textLabel]="'OverviewComponent.Modal.TenantName'" class="form-control mt-3" [required]="true" (modelChangeEvent)="tenantNameToDelete=$event">
					</app-input-field>
				</div>
				<div class="dual-button-container">
					<button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal">
						{{"OverviewComponent.Modal.Cancel" | translate}}
					</button>
					<button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" (click)="confirmDeleteTenant()" [disabled]="tenantNameToDelete !== tenant.name">
						{{"OverviewComponent.Modal.Confirm" | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
</ng-container>