import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

export interface CustomParam {
  [id: string]: string | number | Date | boolean | null;
}

@Injectable()
export class QueryParamsManager {
  constructor(private router: Router, private route: ActivatedRoute) { }

  changeQueryParams(customParams: CustomParam): Promise<boolean> {
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: customParams,
      queryParamsHandling: "merge",
    });
  }
}
