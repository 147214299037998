<div class="dropdown">
  <button *ngIf="!dataSource || (dataSource && dataSource.length === 0)" class="btn btn-secondary dropdown-toggle truncate" type="button" disabled>
    <span>{{"none" | translate}}</span>
    <fa-icon class="arrow" [icon]="['fas', 'angle-down']"></fa-icon>
  </button>
  <button
  *ngIf="dataSource && dataSource.length > 0"
  class="btn btn-secondary dropdown-toggle truncate"
  type="button"
  id="{{modelKey}}"
  data-bs-toggle="dropdown"
  aria-expanded="false"
  (click)="switchIcon()"
  [disabled]="disabled"
  [ngClass]="{'is-form-invalid': !focused && !form.controls[nameInput]?.valid && (inputModel.touched || form.submitted)}"
  >
  <span *ngFor="let data of dataSource">
    <div class="truncate" *ngIf="selectedElement === data[modelKey]">{{data[displayKey] | translate}}</div>
    <span class="label-when-selected" *ngIf="selectedElement === data[modelKey]">{{defaultText | translate}}</span>
  </span>
  <span *ngIf="selectedElement === null || selectedElement === undefined">{{defaultText | translate}}</span>
  <fa-icon class="arrow" [icon]="['fas', 'angle-down']" [ngClass]="{active:showIcon, inactive:!showIcon && firstUse}"></fa-icon>
</button>
<ul id="ul-{{modelKey}}" class="scrollable dropdown-menu dropdown-menu-lg-start w-100" aria-labelledby="dropdownMenuButton">
  <li *ngFor="let data of dataSource">
    <button [ngClass]="{'selected': selectedElement === data[modelKey]}" class="dropdown-item" type="button" (click)="selectData(data)">{{data[displayKey] | translate}}</button>
  </li>
</ul>
</div>
<input #inputModel="ngModel" name="{{nameInput}}" type="text" id="{{nameInput}}" [(ngModel)]="myInputModel" [ngModelOptions]="{updateOn: 'change'}" [required]="required" [readonly]="readonly" (blur)="focused=false" (focus)="focused=true"/>
<div class="form-text is-third" *ngIf="!focused && !form.controls[nameInput]?.valid && (inputModel.touched || form.submitted)">
  {{'InputFormField.IsInvalid'|translate}}
</div>