import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { HistoryObtp } from '../obtp.model';

@Injectable({
  providedIn: 'root'
})
export class HistoryObtpService {

  constructor(private http: HttpClient, private optionService: DataApiOptionService, private route: ActivatedRoute) { }

  getMockHistory(tenantId: string): Observable<HistoryObtp[]> {
    return of(MockHistory);
  }

  getHistory(tenantId: string): Observable<HistoryObtp[]> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http
          .get<HistoryObtp[]>(apiUri + '/obtp/history', {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          })
          .pipe(
            map((result) => {
              return result;
            }),
            tap((result) => result),
            catchError(this.handleError<HistoryObtp[]>('history', undefined))
          );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}

const MockHistory: HistoryObtp[] = [
  {
    id: '0-0-0-0',
    time: '2022-03-03T15:18:31.799079789+01:00',
    origin: 'obtp.dev.virtuvisio.fr',
    tenantId: 'c2b101b0-863c-46c8-afff-57af63a6b196',
    meetingRoomId: '1234',
    endpointEmail: 'test@test.com',
    endpointKind: 'POLYCOM_GROUP_SERIES',
    meetingProvider: 'teams',
    calendarProvider: 'MICROSOFT',
    regexps: [
      '(http|https):\/\/webrtc.*.klood.io\/\S([^\\]+)',
      'conf=([^&]*)&'
    ],
    injectedUrl: '1234@test.com',
    actionFlow: [
      {
        kind: "findEndpointEmail",
        success: true,
        info: "test@test.com"
      },
      {
        kind: "findMeetingRoomCorrelation",
        success: true,
        info: "1234"
      },
      {
        kind: "findTenantCorrelation",
        success: true,
        info: "c2b101b0-863c-46c8-afff-57af63a6b196"
      }
    ],
    success: true,
    description: 'Endpoint with email test@test.com has successfully been injected with a OBTP token containing a teams invitation link 1234@test.com formatted with POLYCOM_GROUP_SERIES token format.\r\nInjected token: Im a token'
  },
  {
    id: '0-0-0-1',
    time: '2022-03-03T16:18:31.799079789+01:00',
    origin: 'obtp.dev.virtuvisio.fr',
    tenantId: 'c2b101b0-863c-46c8-afff-57af63a6b196',
    meetingRoomId: '5678',
    endpointEmail: 'test@test.com',
    endpointKind: 'POLYCOM_GROUP_SERIES',
    meetingProvider: 'teams',
    calendarProvider: 'MICROSOFT',
    regexps: [
      '(http|https):\/\/webrtc.*.klood.io\/\S([^\\]+)',
      'conf=([^&]*)&'
    ],
    injectedUrl: '5678@test.com',
    actionFlow: [
      {
        kind: "findEndpointEmail",
        success: true,
        info: "test@test.com"
      },
      {
        kind: "findMeetingRoomCorrelation",
        success: true,
        info: "5678"
      },
      {
        kind: "findTenantCorrelation",
        success: true,
        info: "c2b101b0-863c-46c8-afff-57af63a6b196"
      }
    ],
    success: true,
    description: 'Endpoint with email test@test.com has successfully been injected with a OBTP token containing a teams invitation link 1234@test.com formatted with POLYCOM_GROUP_SERIES token format.\r\nInjected token: Im a token'
  },
  {
    id: '0-0-0-2',
    time: '2022-03-03T17:18:31.799079789+01:00',
    origin: 'obtp.dev.virtuvisio.fr',
    tenantId: 'c2b101b0-863c-46c8-afff-57af63a6b196',
    meetingRoomId: '9101',
    endpointEmail: 'test@test.com',
    endpointKind: 'POLYCOM_GROUP_SERIES',
    meetingProvider: 'teams',
    calendarProvider: 'MICROSOFT',
    regexps: [
      '(http|https):\/\/webrtc.*.klood.io\/\S([^\\]+)',
      'conf=([^&]*)&'
    ],
    injectedUrl: '9101@test.com',
    actionFlow: [
      {
        kind: "findEndpointEmail",
        success: true,
        info: "test@test.com"
      },
      {
        kind: "findMeetingRoomCorrelation",
        success: false,
        info: "9101"
      },
      {
        kind: "findTenantCorrelation",
        success: true,
        info: "c2b101b0-863c-46c8-afff-57af63a6b196"
      }
    ],
    success: false,
    description: 'Endpoint with email test@test.com has not been successfully injected with a OBTP token containing a teams invitation link 1234@test.com formatted with POLYCOM_GROUP_SERIES token format.\r\nInjected token: Im a token'
  }
]
