import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { suggestPattern } from '../operators/mongo-operators/mongo-operators';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from '../services/http-encoder/custom-http-param-encoder.service';
import { RequestFilterBuilder } from '../services/request-filter-builder/request-filter-builder';
import { User } from '../users/user.model';
import { Contact, RecentContact } from './contact.model';

@Injectable()
export class ContactService {
  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService
  ) { }

  getContacts(): Observable<Contact[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<Contact[]>(apiUri + '/me/contacts', {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        const contacts = [];
        for (const contact of result) {
          contacts.push(new Contact(contact));
        }
        return contacts;
      }),
      tap((result) => result),
      catchError(this.handleError<Contact[]>('getContacts', []))
    );
  }

  getSuggest(suggest: string): Observable<any> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        let params = new HttpParams({
          encoder: new CustomHttpParamEncoderService(),
        });
        const filter = new RequestFilterBuilder();
        filter.set('suggests', suggestPattern(suggest));
        const q = filter.serialize();
        params = params.set('q', q);
        return this.http.get<any[]>(apiUri + '/me/contacts/search', {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        const contactsSuggest = [];
        for (const contact of result) {
          if (contact['kind'] === 'CONTACT') {
            contactsSuggest.push(new Contact(contact));
          } else if (contact['kind'] === 'USER') {
            contactsSuggest.push(new User(contact));
          } else if (contact['kind'] === 'RECENT') {
            contactsSuggest.push(new RecentContact(contact));
          } else {
            contactsSuggest.push(contact);
          }
        }
        return contactsSuggest;
      }),
      tap((result) => result),
      catchError(this.handleError<any[]>('getContactsSuggest', []))
    );
  }

  getContact(contactId: string): Observable<Contact> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get(apiUri + '/me/contacts/' + contactId, {
          headers: this.optionService.getHeaders(),
          observe: 'response',
          withCredentials: true,
        });
      }),
      map((result) => new Contact(result.body))
    );
  }

  create(contact: Contact): Observable<Contact> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.post(apiUri + '/me/contacts', contact, {
          headers: this.optionService.getHeaders(),
          observe: 'response',
          withCredentials: true,
        });
      }),
      map((result) => new Contact(result.body))
    );
  }

  save(contact: Contact): Observable<Contact> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.put(apiUri + '/me/contacts/' + contact.id, contact, {
          headers: this.optionService.getHeaders(),
          observe: 'response',
          withCredentials: true,
        });
      }),
      map((result) => new Contact(result.body))
    );
  }

  delete(contact: Contact): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.delete(apiUri + '/me/contacts/' + contact.id, {
          headers: this.optionService.getHeaders(),
          observe: 'response',
          withCredentials: true,
        });
      }),
      map((result) => result.body)
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
