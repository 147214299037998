import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-radio-field',
  templateUrl: './radio-field.component.html',
  styleUrls: ['./radio-field.component.scss'],
})
export class RadioFieldComponent {
  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() nameInput = '';
  @Input() disabled = false;
  @Input() checked: boolean | null = false;
  @Input() myInputModel!: unknown;
  @Input() listValues: RadioValue[];
  @Output() checkedEvent = new EventEmitter<Event>();

  changeRadio(ev: Event): void {
    const r = ev.target as HTMLInputElement;
    this.myInputModel = r.value;
    this.checkedEvent.emit(ev);
    console.log(r.value)
    console.log(r.checked)
  }
}

interface RadioValue {
  label: string;
  value: string;
}
