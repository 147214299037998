<div class="row">
    <div class="col-12 right-col is-text-shadow" id="lang-menu">
        <div class="fr" [ngClass]="{'active-lang': activeLang === 'fr'}">FR</div>
        <label class="switch switch-full" for="checkbox">
            <input type="checkbox" id="checkbox" [checked]="activeLang === 'en'" (change)="switchLang($event)" />
            <div class="slider dark-background round"></div>
        </label>
        <div class="en is-text-shadow" [ngClass]="{'active-lang': activeLang === 'en'}">EN</div>
    </div>
</div>
