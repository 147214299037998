import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { DisplayColumn } from '../commons/mat-table-custom/mat-table-custom.model';
import { Environment } from './environment.model';
import { ActionEvent } from 'src/app/commons/mat-table-custom/mat-table-custom.model';
import { EnvironmentsService } from './environments.service';
import { NotifService } from 'src/app/services/notifs/notif.service';

@Component({
  selector: 'app-environments',
  templateUrl: './environments.component.html',
  styleUrls: ['./environments.component.scss'],
  providers: [EnvironmentsService]
})
export class EnvironmentsComponent implements OnInit {
  environments: MatTableDataSource<Environment> = new MatTableDataSource();
  onInitSubscriptions: Subscription[] = [];
  displayedColumns: DisplayColumn[];
  defaultSort = "host";
  loading = false;
  selectedEnvironment: Environment;
  addEnvironment = false;
  routerEvent: RouterEvent;
  constructor(public environmentsService: EnvironmentsService, private notifService: NotifService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.displayedColumns = [
      { name: "host", type: "any" },
      { name: "public", type: "any" },
      { name: "pexipDomain", type: "any" },
      {
        name: "action", type: "action",
        actions: [
          { action: "changePrivacy", icon: "eye", tooltip: "EnvironmentsComponent.ChangePrivacy" },
          { action: "edit", icon: "edit", tooltip: "EnvironmentsComponent.Edit" },
          { action: "delete", icon: "trash-alt", tooltip: "EnvironmentsComponent.Delete" }
        ]
      }
    ];
    this.getEnvironments();
    this.onInitSubscriptions.push(this.router.events.subscribe(event => {
      this.routerEvent = event as RouterEvent;
    }))
    if (this.route.firstChild) {
      this.onInitSubscriptions.push(this.route.firstChild?.params.subscribe(params => {
        if (params.environmentId) {
          this.selectedEnvironment = new Environment({ id: params.environmentId });
        }
      }))
    }
  }

  onDataError(event: any): void {
    console.log(event);
  }

  onActionClick(action: ActionEvent): void {
    this.selectedEnvironment = action.data;
    switch (action.action.action) {
      case 'changePrivacy': {
        this.changeEnvironmentPrivacy();
        break;
      }
      case 'edit': {
        this.router.navigate(['./' + this.selectedEnvironment.id + '/edit'], { relativeTo: this.route });
        break;
      }
      case 'delete': {
        $('#modalDeleteEnvironment').modal('show');
        break;
      }
      default: {
        break;
      }
    }
  }

  getEnvironments(): void {
    this.environmentsService.getEnvironments().subscribe(result => {
      this.environments = new MatTableDataSource(result);
      this.refreshTable();
    })
  }

  changeEnvironmentPrivacy(): void {
    this.selectedEnvironment.public = !this.selectedEnvironment.public;
    this.environmentsService.saveEnvironment(this.selectedEnvironment).subscribe({
      next: (result) => {
        this.getEnvironments();
        this.selectedEnvironment = null as any;
        this.notifService.addSuccessNotif('EnvironmentsComponent.EnvironmentEdited');
      },
      error: (error) => {
        this.notifService.addErrorNotif('EnvironmentsComponent.ErrorWhileEditingEnvironment', error);
      }
    })
  }

  confirmDeleteEnvironment(): void {
    this.environmentsService.deleteEnvironment(this.selectedEnvironment).subscribe({
      next: (result) => {
        this.getEnvironments();
        this.notifService.addSuccessNotif('EnvironmentsComponent.EnvironmentDeleted');
      },
      error: (error) => {
        this.notifService.addErrorNotif('EnvironmentsComponent.ErrorWhileDeletingEnvironment', error);
      }
    })
  }

  componentEnvironmentRemoved(): void {
    this.getEnvironments();
    this.selectedEnvironment = null as any;
  }

  refreshTable(): void {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    })
  }

  isRoute(param: string): boolean {
    if (this.routerEvent?.url) {
      if (this.routerEvent.url.includes(param)) {
        return true;
      }
    }
    if (this.router?.url) {
      if (this.router.url.includes(param)) {
        return true;
      }
    }
    return false;
  }

}
