import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { Environment } from '../environment.model';
import { EnvironmentsService } from '../environments.service';

@Component({
  selector: 'app-edit-environment',
  templateUrl: './edit-environment.component.html',
  styleUrls: ['./edit-environment.component.scss']
})
export class EditEnvironmentComponent implements OnInit, OnDestroy {

  currentEnvironment: Environment;
  hasError = false;
  finished = false;
  paramSubscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute, private environmentsService: EnvironmentsService, private notifService: NotifService) { }

  ngOnInit(): void {
    this.paramSubscription = this.route.paramMap.subscribe(params => {
      let environmentId = params.get('environmentId');
      if (environmentId) {
        this.getEnvironment(environmentId);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  getEnvironment(environmentId: string): void {
    this.environmentsService.getEnvironment(environmentId).subscribe(result => {
      this.currentEnvironment = result;
    })
  }

  confirmEditEnvironment(environmentForm: NgForm): void {
    if (environmentForm.valid) {
      this.currentEnvironment.host = environmentForm.value.host;
      this.currentEnvironment.pexipDomain = environmentForm.value.pexipDomain;
      this.currentEnvironment.public = environmentForm.value.public;
      this.hasError = false;
      this.environmentsService.saveEnvironment(this.currentEnvironment).subscribe({
        next: result => {
          this.finished = true;
          this.notifService.addSuccessNotif('EditEnvironmentComponent.SuccessEditEnvironment');
          this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: error => {
          this.finished = true;
          this.hasError = true;
          this.notifService.addErrorNotif('EditEnvironmentComponent.ErrorEditEnvironment', error);
        }
      })
    } else {
      this.hasError = true;
    }
  }

}
