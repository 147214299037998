import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { UserDataCustom } from 'src/app/users/user-connected/user.connected.data.custom';
import { CookieService } from 'ngx-cookie-service';
import Debug from 'debug';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TenantService } from 'src/app/tenant/tenant.service';
import { EnvironmentsService } from 'src/app/environments/environments.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { Environment } from 'src/app/environments/environment.model';
const debug = Debug('virtuvisio-administration:header-component');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [TenantService, EnvironmentsService]
})
export class HeaderComponent implements OnInit, OnDestroy {

  environment = environment;

  displayUserMenu = false;
  displayMasqueradeMenu = false;
  displayThemeMenu = false;
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  userData: UserDataCustom;
  virtuvisioAppUrl: string;
  customAppUrl: string;

  constructor(
    private sharedService: SharedService,
    private cookieService: CookieService,
    private router: Router,
    private tenantService: TenantService,
    private environmentsService: EnvironmentsService
  ) {
    const newAdminBaseUrl = window.location.origin.replace('admin.', 'app.');
    this.virtuvisioAppUrl = newAdminBaseUrl + '/home';
  }

  ngOnInit(): void {
    this.onInitSubscriptions.push(
      this.sharedService.currentConnectedUser.subscribe(
        (connectedUser: ConnectedUser) => {
          if (connectedUser) {
            this.connectedUser = connectedUser;
            this.getCustomAppUrl(this.connectedUser);
          }
        }
      )
    );
    this.onInitSubscriptions.push(
      this.sharedService.currentConnectedUserData.subscribe(
        (connectedUserData: UserDataCustom) => {
          if (connectedUserData) {
            const oneYearFutur = new Date().setFullYear(
              new Date().getFullYear() + 1
            );
            const expiresDate = new Date(oneYearFutur);
          }
        }
      )
    );
  }

  getCustomAppUrl(connectedUser: ConnectedUser) {
    this.tenantService.getTenant(connectedUser.tenantId).subscribe({
      next: (tenant: Tenant) => {
        this.environmentsService.getEnvironment(tenant.environmentId).subscribe({
          next: (environment: Environment) => {
            this.customAppUrl = window.location.protocol + '//' + environment.host + '/home';
          },
          error: (error) => {
            debug(error);
          }
        })
      },
      error: (error) => {
        debug(error);
      }
    })
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  justDisplayUserMenu(displayUserMenu: boolean): void {
    this.displayUserMenu = displayUserMenu;
    if (displayUserMenu) {
      this.displayMasqueradeMenu = false;
      this.displayThemeMenu = false;
    }
  }

  justDisplayMasqueradeMenu(displayMasqueradeMenu: boolean): void {
    this.displayMasqueradeMenu = displayMasqueradeMenu;
    if (displayMasqueradeMenu) {
      this.displayUserMenu = false;
      this.displayThemeMenu = false;
    }
  }

  justDisplayThemeMenu(displayThemeMenu: boolean): void {
    this.displayThemeMenu = displayThemeMenu;
    if (displayThemeMenu) {
      this.displayUserMenu = false;
      this.displayMasqueradeMenu = false;
    }
  }

  isRoute(url: string): boolean {
    if (this.router.url.includes(url)) {
      return true;
    }
    return false;
  }
}
