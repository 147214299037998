import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customSearch',
  pure: false,
})
export class CustomSearch implements PipeTransform {
  value: string[] = [];

  transform(input: any, args?: any): any {
    if (!input) {
      return null;
    }

    if (!args) {
      return input;
    }

    args = args.toLowerCase();

    return input.filter(function (item: any) {
      let toSearch = '';
      for (const i in item) {
        if (i !== 'role') {
          toSearch += item[i] + ',';
        }
      }
      return toSearch.toLowerCase().includes(args);
    });
  }
}
