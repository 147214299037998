<ng-container *ngIf="connectedUser && (tenant$ | async) as tenant">
    <div class="row align-items-center p-3">
        <div class="col-auto">
            <h3 class="m-0">
                <span class="is-bold">{{"VisioComponent.Title" | translate}}:</span>
                <span class="is-bold is-primary"> {{tenant.name | uppercase}}</span>
            </h3>
        </div>
    </div>
    <app-admin-bloc>
        <div class="p-3 is-bold">
            {{"VisioComponent.Explain" | translate}}
        </div>
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <app-admin-infos-bloc [blocTitle]="'VisioComponent.Options.Thumbnails.Title'">
                            <div>{{"VisioComponent.Options.Thumbnails.Explain" | translate}}</div>
                            <app-checkbox-field [textLabel]="tenant.enableOverlayText ? 'VisioComponent.Options.Enable' : 'VisioComponent.Options.Disable'" [nameInput]="'enableOverlayText'"
                                [myInputModel]="tenant.enableOverlayText" [checked]="tenant.enableOverlayText" (checkedEvent)="switchThumbnailOption($event, tenant)">
                            </app-checkbox-field>
                        </app-admin-infos-bloc>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-admin-infos-bloc [blocTitle]="'VisioComponent.Options.Chat.Title'">
                            <div>{{"VisioComponent.Options.Chat.Explain" | translate}}</div>
                            <app-checkbox-field [textLabel]="!tenant.disableChat ? 'VisioComponent.Options.Enable' : 'VisioComponent.Options.Disable'" [nameInput]="'disableChat'"
                                [myInputModel]="!tenant.disableChat" [checked]="!tenant.disableChat" (checkedEvent)="switchTchatOption($event, tenant)">
                            </app-checkbox-field>
                        </app-admin-infos-bloc>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <app-admin-infos-bloc [blocTitle]="'VisioComponent.Options.ParticipantsLimit.Title'">
                            <div>{{"VisioComponent.Options.ParticipantsLimit.Explain" | translate}}</div>
                            <div class="is-bold is-italic options-warn">({{"VisioComponent.Options.ParticipantsLimit.Warn" | translate}})</div>
                            <div *ngIf="connectedUser.hasRole('SUPER_ADMIN')" class="mt-4 dual-button-container w-100 align-items-center">
                                <app-input-field [textLabel]="'VisioComponent.Options.ParticipantsLimit.Maximum'" [typeInput]="'number'" [nameInput]="'participantLimit'"
                                    [myInputModel]="tenant.participantLimit" (modelChangeEvent)="limitParticipant = $event">
                                </app-input-field>
                                <app-loading-button (submitEvent)="saveParticipantLimit(tenant, limitParticipant)" [targetStyle]="'primary'"
                                    [initialText]="'VisioComponent.Options.ParticipantsLimit.Button.Valid'"
                                    [finalText]="'VisioComponent.Options.ParticipantsLimit.Button.Validate'"
                                    [finished]="finished"
                                    [error]="hasError"
                                ></app-loading-button>
                
                            </div>
                            <div *ngIf="!connectedUser.hasRole('SUPER_ADMIN')" class="mt-4">{{"VisioComponent.Options.ParticipantsLimit.Maximum" | translate}} :
                                <span class="is-bold">{{tenant.participantLimit}}</span></div>
                        </app-admin-infos-bloc>
                    </div>
                </div>
            </div>
        </div>
    </app-admin-bloc>
</ng-container>
