import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  email(mail: string): boolean {
    // eslint-disable-next-line no-useless-escape
    const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (reg.test(mail)) {
      return true;
    } else {
      return false;
    }
  }

  numberToMSISDN(phoneNumber: string): string {
    return phoneNumber;
  }

  msisdn(msisdn: string): boolean {
    if (msisdn?.length !== 0) {
      const reg = /^[0-9]{4,20}$/;
      return reg.test(msisdn);
    } else {
      return false;
    }
  }

  macAddress(macAddress: string): string | boolean {
    const reg = /^[A-F0-9]{12}$/;
    let mac;
    if (macAddress?.length === 16) {
      mac = macAddress.substr(4, 16);
    } else if (macAddress?.length === 12) {
      mac = macAddress;
    } else {
      return false;
    }
    if (reg.test(mac)) {
      return mac;
    } else {
      mac = mac.toUpperCase();
      if (reg.test(mac)) {
        return mac;
      } else {
        return false;
      }
    }
  }

  validateEmail(email: string): boolean {
    // eslint-disable-next-line no-control-regex
    const regex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    if (!regex.test(email)) {
      return false;
    }
    return true;
  }
}
