<label for="{{nameInput}}">
    <span class="date-label">{{textLabel | translate}}</span>
    <input
            class="left-align-input"
            #inputModel="ngModel"
            matInput
            [ngxMatDatetimePicker]="picker"
            name="{{nameInput}}"
            placeholder="{{textLabel | translate}}"
            [(ngModel)]="myInputModel"
            (ngModelChange)="checkDate($event)"
            [min]="min"
            [max]="max"
            readonly
    />
    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker [enableMeridian]="connectedUser.lang === 'en-US'" (opened)="changeCalendarClass();"></ngx-mat-datetime-picker>
</label>
