export class Contact {
  name: string;
  email: string;
  phone: string;
  company: string;
  createdAt: Date;
  deleted: boolean;
  id: string;
  remoteId: string;
  tenantId: string;
  updatedAt: Date;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  getDisplayName(): string {
    return this.name;
  }
}

export class RecentContact {
  email: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  getDisplayName(): string {
    return this.email;
  }
}
