<ng-container *ngIf="tenantId && tenant">
  <div class="row align-items-center p-3">
    <div class="col" *ngIf="tenant.ewsConfig?.domain">
      <button class="btn btn-app app-primary" type="submit" *ngIf="!editConfig" (click)="editConfig = true;">
        <fa-icon [icon]="['fas', 'edit']" class="me-2"></fa-icon>{{"ObtpComponent.Button.EditConfig" | translate }}
      </button>
      <button class="btn btn-app app-secondary" type="submit" *ngIf="editConfig" (click)="editConfig = false;">
        <fa-icon [icon]="['fas', 'ban']" class="me-2"></fa-icon>{{"ObtpComponent.Button.CancelEdit" | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ng-container *ngIf="!tenant.ewsConfig?.domain">
        <div class="no-ews-config my-5">
          {{"ObtpComponent.NoEwsConfig" | translate}}
        </div>
      </ng-container>
      <ng-container *ngIf="tenant.ewsConfig?.domain">
        <ng-container *ngIf="!editConfig">
          <ng-container *ngIf="obtpConf.regexps">
            <div *ngFor="let regexp of obtpConf.regexps | keyvalue" class="mb-3">
              <app-admin-border-bloc>
                <div class="row align-items-center">
                  <div class="col-auto is-bold pe-0">
                    {{"ObtpComponent.Target" | translate}} : 
                  </div>
                  <div class="col">
                    {{regexp.key}}
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-auto is-bold pe-0">
                    {{"ObtpComponent.FinalLinkFormat" | translate}} : 
                  </div>
                  <div class="col">
                    {{regexp.value.finalMeetingLinkFormat}}
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-auto is-bold pe-0 align-self-start">
                    {{"ObtpComponent.Matchers" | translate}} : 
                  </div>
                  <div class="col">
                    <div *ngFor="let matcher of regexp.value.matchers">
                      {{matcher}}
                    </div>
                  </div>
                </div>
              </app-admin-border-bloc>
            </div>
          </ng-container>
          <ng-container *ngIf="!obtpConf.regexps || (obtpConf.regexps | json) === ({} | json)">
            <div class="no-data my-5">
              {{"ObtpComponent.NoConfig" | translate}}
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="editConfig">
          <app-edit-obtp [tenantId]="tenantId" [editObtpRegexps]="obtpConf.regexps" (brodcastEditEvent)="onEditEvent($event)"></app-edit-obtp>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
