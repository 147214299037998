<div class="infos-bloc">
    <div class="infos-bloc-header" *ngIf="blocTitle || blocControls">
        <div class="infos-bloc-title my-auto">{{blocTitle | translate}}</div>
        <div class="infos-control-bloc push">
            <div class="control">
                <app-toolbar [blocControls]="blocControls" (requestAction)="onRequestAction($event)" (switchChange)="onSwitchChange($event)"></app-toolbar>
            </div>
        </div>
    </div>
    <div class="details-bloc-body">
        <ng-content></ng-content>
    </div>
</div>