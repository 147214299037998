import { Component, OnDestroy, OnInit } from '@angular/core';
import { ObtpService } from './obtp.service';
import { ObtpConfig, ObtpRegexps } from './obtp.model';
import { ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject, shareReplay, takeUntil } from 'rxjs';
import Debug from 'debug';
import { Tenant } from '../tenant/tenant.model';
import { QueryParamsManager } from '../services/query-params-manager/query-params-manager';
import { SharedService } from '../services/shared/shared.service';
import { ConnectedUser } from '../users/user-connected/user.connected.model';
const debug = Debug('virtuvisio-administration:obtp-component');

@Component({
  selector: 'app-obtp',
  templateUrl: './obtp.component.html',
  styleUrls: ['./obtp.component.scss'],
  providers: [ObtpService, QueryParamsManager]
})
export class ObtpComponent implements OnInit, OnDestroy {

  tenantId: string;
  tenant$: Observable<Tenant>;
  connectedUser$: Observable<ConnectedUser>;
  selectedTabIndex = 0;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private obtpService: ObtpService, private route: ActivatedRoute, private queryParamsManager: QueryParamsManager, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.connectedUser$ = this.sharedService.currentConnectedUser
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params["tabIndex"]) {
        this.selectedTabIndex = params["tabIndex"];
      }
    })
    this.route.parent?.params.subscribe((params) => {
      if (params.tenantId) {
        this.tenantId = params.tenantId;
        this.getTenant(this.tenantId);
      }
    });
  }

  getTenant(tenantId: string): void {
    this.tenant$ = this.obtpService.getTenant(tenantId).pipe(
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  async switchTab(event: any): Promise<void> {
    await this.queryParamsManager.changeQueryParams({ tabIndex: event.index });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
