<div class="row align-items-center p-3">
  <div class="col-auto">
    <h3 class="m-0">
      <span class="is-bold">{{"EnvironmentsComponent.Title" | translate}}</span>
    </h3>
  </div>
  <div class="col">
    <button class="btn btn-app app-primary float-end me-2" type="submit" [routerLink]="['./add']" >
			<fa-icon [icon]="['fas', 'plus-circle']" class="me-2"></fa-icon>{{"EnvironmentsComponent.AddEnvironment" | translate }}
		</button>
  </div>
</div>
<div class="row">
	<div class="col" [ngClass]="{'col-12': !isRoute('/add') && !isRoute('/edit'), 'col-8': isRoute('/add') || isRoute('/edit')}">
		<app-admin-card>
			<app-mat-table-custom *ngIf="displayedColumns && environments" [selectedRowId]="selectedEnvironment?.id" [selectedRowItem]="'id'" [hoverable]="false" [componentName]="'EnvironmentsComponent'" [displayedColumns]="displayedColumns" [dataSource]="environments" [dataService]="environmentsService" [defaultSort]="defaultSort" (dataError)="onDataError($event)" (actionClick)="onActionClick($event)"></app-mat-table-custom>
		</app-admin-card>
	</div>
	<div class="col-4" *ngIf="isRoute('/add') || isRoute('/edit')">
		<router-outlet (deactivate)="componentEnvironmentRemoved()"></router-outlet>
	</div>
</div>

<div class="modal fade custom-app-modal" id="modalDeleteEnvironment" role="dialog" aria-labelledby="modalDeleteEnvironmentLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content flat">
			<div class="modal-header">
				<h3 class="modal-title text-center" id="modalDeleteEnvironmentLabel">{{"EnvironmentsComponent.Delete" | translate}}</h3>
			</div>
			<div class="modal-body">
				<div class="is-bold">{{"EnvironmentsComponent.SureDeleteEnvironment" | translate}}</div>
				<div class="mb-3" *ngIf="selectedEnvironment">{{selectedEnvironment.host}}</div>
				<div class="dual-button-container">
					<button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal">
						{{"EnvironmentsComponent.Cancel" | translate}}
					</button>
					<button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" (click)="confirmDeleteEnvironment()">
						{{"EnvironmentsComponent.Confirm" | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
