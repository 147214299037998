export class TemplateMail {
    // add by broker
    id: string;

    constructor(obj: any) {
        Object.assign(this, obj);
    }

    getId(): string {
        return this.id;
    }
}

export interface Mail {
    content: string;
    subject: string;
    exampleContext: {
        ctx?: string;
    };
    schema: {
        additionalProperties: boolean;
        properties: {
            type?: string;
            additionalProperties?: boolean;
            properties?: {
                type?: string;
            };
        };
    };
}

export interface Invitation {
    content: string;
    exampleContext: {
        ctx?: string;
    };
    schema: {
        additionalProperties: boolean;
        properties: {
            type?: string;
            additionalProperties?: boolean;
            properties?: {
                type?: string;
            };
        };
    };
}

export interface MailPreviewContent {
    subject: string;
    content: string;
}

export const TemplateKinds = ["html", "text"];

export const TemplateKeysFull = ["mailing.layout", "superadmin.added", "superadmin.removed", "user.reset.password", "endpoint.created", "endpoint.password.reset", "conference.invitation.mail", "user.create.password", "microsoft.consent.request", "microsoft.consent.validated", "trial.welcome", "sync.report", "meeting.request.attendee", "meeting.request.comanager", "meeting.request.cancellation"];

export const TemplateKeys = ["mailing.layout", "superadmin.added", "superadmin.removed", "user.reset.password", "endpoint.created", "endpoint.password.reset", "user.create.password", "microsoft.consent.request", "microsoft.consent.validated", "sync.report"];

export const TemplateKeysTrial = ["mailing.layout", "user.reset.password", "conference.invitation.mail", "user.create.password", "trial.welcome", "meeting.request.attendee", "meeting.request.comanager", "meeting.request.cancellation"];

export const TemplateKeysKloodApp = ["mailing.layout", "superadmin.added", "superadmin.removed", "user.reset.password", "endpoint.created", "endpoint.password.reset", "conference.invitation.mail", "user.create.password", "microsoft.consent.request", "microsoft.consent.validated", "sync.report", "meeting.request.attendee", "meeting.request.comanager", "meeting.request.cancellation"];

export const InvitationKeys = ["meeting.request.ics", "meeting.request.invit"];
export const IcsKeysApp = ["meeting.request.ics"];
export const OutlookKeysApp = ["meeting.request.invit"];

export const TemplateLinks = ["create-password", "endpoint-connection", "join-meeting", "manage-meeting", "other-connections-mode", "reset-password", "forgot-password", "login"];
