import { Component, OnInit } from '@angular/core';
import { DisplayColumn, ActionEvent } from 'src/app/commons/mat-table-custom/mat-table-custom.model';
import { HttpErrorResponse } from '@angular/common/http';
import { combineLatest } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ImportableGroup } from 'src/app/groups/group.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { ImportGroupService } from './import-group.service';

@Component({
  selector: 'app-import-group',
  templateUrl: './import-group.component.html',
  styleUrls: ['./import-group.component.scss'],
  providers: [ImportGroupService]
})
export class ImportGroupComponent implements OnInit {

  importableGroups: MatTableDataSource<ImportableGroup> = new MatTableDataSource();
  defaultSort = "name";
  displayedColumns: DisplayColumn[];
  loading = false;

  constructor(private groupsService: ImportGroupService, private notifService: NotifService) { }

  ngOnInit(): void {
    this.displayedColumns = [
      { name: "name", type: "any" },
      { name: "description", type: "any" },
      { name: "action", type: "action", actions: [{ action: "import", icon: "cloud-download-alt" }] }
    ];
    this.getAvailableGroups();
  }

  getAvailableGroups(): void {
    this.loading = true;
    combineLatest([this.groupsService.getImportableGroups(), this.groupsService.getGroups()]).subscribe({
      next: (result) => {
        let importableGroups = result[0];
        let importedGroups = result[1];
        let filteredGroupsArray = importableGroups.filter(group => !(importableGroups.filter(el => {
          return importedGroups.some(f => {
            return f.email === el.ref;
          });
        })).includes(group));
        this.loading = false;
        this.importableGroups = new MatTableDataSource(filteredGroupsArray);
      },
      error: (error) => {
        this.loading = false;
      }
    })
  }

  onDataError(error: HttpErrorResponse): void {
    console.log(error);
  }

  onActionClick(event: ActionEvent): void {
    if (event.action.action === "import") {
      this.groupsService.import(event.data).subscribe({
        next: () => {
          this.notifService.addSuccessNotif("ImportGroupComponent.SuccessImportGroup");
          this.getAvailableGroups();
        },
        error: (error: HttpErrorResponse) => {
          this.notifService.addSuccessNotif("ImportGroupComponent.ErroWhileImportingGroup");
        }
      })
    }
  }

}
