<ng-container *ngIf="connectedUser">
  <app-checkbox-field [textLabel]="'ExpiredOfferListComponent.ShowExpired'" [nameInput]="'showExpireOnly'" [myInputModel]="showExpireOnly" [checked]="showExpireOnly" (checkedEvent)="showExpired($event)"></app-checkbox-field>
  <div class="custom-table-filter">
    <mat-form-field appearance="outline" class="mat-form-field-custom" id="custom-search-2">
      <mat-icon matPrefix>
        <fa-icon [icon]="['fas', 'search']"></fa-icon>
      </mat-icon>
      <input matInput (keyup)="applyFilterFromEvent($event)" [(ngModel)]="search" placeholder="{{'MatTable.Search' | translate}}" />
    </mat-form-field>
    <div class="border-container" id="custom-table">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="newSort($event);" class="custom-table" *ngIf="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"ExpiredOfferListComponent.Name" | translate}}</th>
          <td mat-cell *matCellDef="let tenant" attr.data-th='{{"ExpiredOfferListComponent.Name" | translate}}' class="small-right pointer" [routerLink]="['/administration/'+tenant.id+'/offers']">{{tenant.name}}</td>
        </ng-container>
        <ng-container matColumnDef="oneOffer">
          <th mat-header-cell *matHeaderCellDef class="text-center offers-header">{{"ONE" | translate}}</th>
          <td mat-cell *matCellDef="let tenant" attr.data-th='{{"ONE" | translate}}' class="small-right pointer" [routerLink]="['/administration/'+tenant.id+'/offers']">
            <span *ngIf="tenant.offers.ONE">
              <span *ngIf="!tenant.offers.ONE.isExpired" [ngClass]="{
                                            'expires-at-15-txt': tenant.offers.ONE.expiresIn === 15 && !tenant.offers.ONE.disabledAt,
                                            'expires-at-30-txt': tenant.offers.ONE.expiresIn === 30 && !tenant.offers.ONE.disabledAt,
                                            'is-regular': (tenant.offers.ONE.expiresIn === 15 || tenant.offers.ONE.expiresIn === 30) && !tenant.offers.ONE.disabledAt,
                                            'line-txt': tenant.offers.ONE.disabledAt}">
                {{tenant.offers.ONE.expiresAt |
                date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
              </span>
              <span *ngIf="tenant.offers.ONE.isExpired" class="is-expired-txt is-bold">
                <i>{{"ExpiredOfferListComponent.Expired" | translate}}</i>
              </span>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="proOffer">
          <th mat-header-cell *matHeaderCellDef class="text-center offers-header">{{"PRO" | translate}}</th>
          <td mat-cell *matCellDef="let tenant" attr.data-th='{{"PRO" | translate}}' class="small-right pointer" [routerLink]="['/administration/'+tenant.id+'/offers']">
            <span *ngIf="tenant.offers.PRO">
              <span *ngIf="!tenant.offers.PRO.isExpired" [ngClass]="{
                                            'expires-at-15-txt': tenant.offers.PRO.expiresIn === 15 && !tenant.offers.PRO.disabledAt,
                                            'expires-at-30-txt': tenant.offers.PRO.expiresIn === 30 && !tenant.offers.PRO.disabledAt,
                                            'is-regular': (tenant.offers.PRO.expiresIn === 15 || tenant.offers.PRO.expiresIn === 30) && !tenant.offers.PRO.disabledAt,
                                            'line-txt': tenant.offers.PRO.disabledAt}">
                {{tenant.offers.PRO.expiresAt |
                date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
              </span>
              <span *ngIf="tenant.offers.PRO.isExpired" class="is-expired-txt is-bold">
                <i>{{"ExpiredOfferListComponent.Expired" | translate}}</i>
              </span>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="gatewayOffer">
          <th mat-header-cell *matHeaderCellDef class="text-center offers-header">{{"GATEWAY" | translate}}</th>
          <td mat-cell *matCellDef="let tenant" attr.data-th='{{"GATEWAY" | translate}}' class="small-right pointer" [routerLink]="['/administration/'+tenant.id+'/offers']">
            <span *ngIf="tenant.offers.GATEWAY">
              <span *ngIf="!tenant.offers.GATEWAY.isExpired" [ngClass]="{
                                            'expires-at-15-txt': tenant.offers.GATEWAY.expiresIn === 15 && !tenant.offers.GATEWAY.disabledAt,
                                            'expires-at-30-txt': tenant.offers.GATEWAY.expiresIn === 30 && !tenant.offers.GATEWAY.disabledAt,
                                            'is-regular': (tenant.offers.GATEWAY.expiresIn === 15 || tenant.offers.GATEWAY.expiresIn === 30) && !tenant.offers.GATEWAY.disabledAt,
                                            'line-txt': tenant.offers.GATEWAY.disabledAt}">
                {{tenant.offers.GATEWAY.expiresAt |
                date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
              </span>
              <span *ngIf="tenant.offers.GATEWAY.isExpired" class="is-expired-txt is-bold">
                <i>{{"ExpiredOfferListComponent.Expired" | translate}}</i>
              </span>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="recordingOffer">
          <th mat-header-cell *matHeaderCellDef class="text-center offers-header">{{"RECORDING" | translate}}</th>
          <td mat-cell *matCellDef="let tenant" attr.data-th='{{"RECORDING" | translate}}' class="small-right pointer" [routerLink]="['/administration/'+tenant.id+'/offers']">
            <span *ngIf="tenant.offers.RECORDING">
              <span *ngIf="!tenant.offers.RECORDING.isExpired" [ngClass]="{
                                            'expires-at-15-txt': tenant.offers.RECORDING.expiresIn === 15 && !tenant.offers.RECORDING.disabledAt,
                                            'expires-at-30-txt': tenant.offers.RECORDING.expiresIn === 30 && !tenant.offers.RECORDING.disabledAt,
                                            'is-regular': (tenant.offers.RECORDING.expiresIn === 15 || tenant.offers.RECORDING.expiresIn === 30) && !tenant.offers.RECORDING.disabledAt,
                                            'line-txt': tenant.offers.RECORDING.disabledAt}">
                {{tenant.offers.RECORDING.expiresAt |
                date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
              </span>
              <span *ngIf="tenant.offers.RECORDING.isExpired" class="is-expired-txt is-bold">
                <i>{{"ExpiredOfferListComponent.Expired" | translate}}</i>
              </span>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="oneCodecOffer">
          <th mat-header-cell *matHeaderCellDef class="text-center offers-header">{{"ONE_CODEC" | translate}}</th>
          <td mat-cell *matCellDef="let tenant" attr.data-th='{{"ONE_CODEC" | translate}}' class="small-right pointer" [routerLink]="['/administration/'+tenant.id+'/offers']">
            <span *ngIf="tenant.offers.ONE_CODEC">
              <span *ngIf="!tenant.offers.ONE_CODEC.isExpired" [ngClass]="{
                                            'expires-at-15-txt': tenant.offers.ONE_CODEC.expiresIn === 15 && !tenant.offers.ONE_CODEC.disabledAt,
                                            'expires-at-30-txt': tenant.offers.ONE_CODEC.expiresIn === 30 && !tenant.offers.ONE_CODEC.disabledAt,
                                            'is-regular': (tenant.offers.ONE_CODEC.expiresIn === 15 || tenant.offers.ONE_CODEC.expiresIn === 30) && !tenant.offers.ONE_CODEC.disabledAt,
                                            'line-txt': tenant.offers.ONE_CODEC.disabledAt}">
                {{tenant.offers.ONE_CODEC.expiresAt |
                date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
              </span>
              <span *ngIf="tenant.offers.ONE_CODEC.isExpired" class="is-expired-txt is-bold">
                <i>{{"ExpiredOfferListComponent.Expired" | translate}}</i>
              </span>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="hangoutGatewayOffer">
          <th mat-header-cell *matHeaderCellDef class="text-center offers-header">{{"HANGOUT_GATEWAY" | translate}}</th>
          <td mat-cell *matCellDef="let tenant" attr.data-th='{{"HANGOUT_GATEWAY" | translate}}' class="small-right pointer" [routerLink]="['/administration/'+tenant.id+'/offers']">
            <span *ngIf="tenant.offers.HANGOUT_GATEWAY">
              <span *ngIf="!tenant.offers.HANGOUT_GATEWAY.isExpired" [ngClass]="{
                                            'expires-at-15-txt': tenant.offers.HANGOUT_GATEWAY.expiresIn === 15 && !tenant.offers.HANGOUT_GATEWAY.disabledAt,
                                            'expires-at-30-txt': tenant.offers.HANGOUT_GATEWAY.expiresIn === 30 && !tenant.offers.HANGOUT_GATEWAY.disabledAt,
                                            'is-regular': (tenant.offers.HANGOUT_GATEWAY.expiresIn === 15 || tenant.offers.HANGOUT_GATEWAY.expiresIn === 30) && !tenant.offers.HANGOUT_GATEWAY.disabledAt,
                                            'line-txt': tenant.offers.HANGOUT_GATEWAY.disabledAt}">
                {{tenant.offers.HANGOUT_GATEWAY.expiresAt |
                date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
              </span>
              <span *ngIf="tenant.offers.HANGOUT_GATEWAY.isExpired" class="is-expired-txt is-bold">
                <i>{{"ExpiredOfferListComponent.Expired" | translate}}</i>
              </span>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="teamsGatewayOffer">
          <th mat-header-cell *matHeaderCellDef class="text-center offers-header">{{"TEAMS_GATEWAY" | translate}}</th>
          <td mat-cell *matCellDef="let tenant" attr.data-th='{{"TEAMS_GATEWAY" | translate}}' class="small-right pointer" [routerLink]="['/administration/'+tenant.id+'/offers']">
            <span *ngIf="tenant.offers.TEAMS_GATEWAY">
              <span *ngIf="!tenant.offers.TEAMS_GATEWAY.isExpired" [ngClass]="{
                                            'expires-at-15-txt': tenant.offers.TEAMS_GATEWAY.expiresIn === 15 && !tenant.offers.TEAMS_GATEWAY.disabledAt,
                                            'expires-at-30-txt': tenant.offers.TEAMS_GATEWAY.expiresIn === 30 && !tenant.offers.TEAMS_GATEWAY.disabledAt,
                                            'is-regular': (tenant.offers.TEAMS_GATEWAY.expiresIn === 15 || tenant.offers.TEAMS_GATEWAY.expiresIn === 30) && !tenant.offers.TEAMS_GATEWAY.disabledAt,
                                            'line-txt': tenant.offers.TEAMS_GATEWAY.disabledAt}">
                {{tenant.offers.TEAMS_GATEWAY.expiresAt |
                date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}
              </span>
              <span *ngIf="tenant.offers.TEAMS_GATEWAY.isExpired" class="is-expired-txt is-bold">
                <i>{{"ExpiredOfferListComponent.Expired" | translate}}</i>
              </span>
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pointer table-hover"></tr>
      </table>
    </div>
    <div *ngIf="dataSource && dataSource.data?.length === 0" class="ms-2 mt-2">
      <i>{{"MatTable.NoAvailableData" | translate}}...</i>
    </div>
    <mat-paginator class="mat-paginator-custom" [length]="listLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons (page)="getNext($event)"> </mat-paginator>
  </div>
  <div class="row d-flex justify-content-center align-items-center my-5" *ngIf="!dataSource">
    <div class="col-auto my-auto">
        <app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
    </div>
  </div>
</ng-container>