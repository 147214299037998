import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { Tenant } from 'src/app/tenant/tenant.model';

@Injectable()
export class OfferViewService {
  constructor(private http: HttpClient, private optionService: DataApiOptionService) {}

  saveTenant(tenant: Tenant): Observable<Tenant> {
    return this.optionService.getApiUrl(tenant.id).pipe(
      switchMap((apiUri) => {
        return this.http.put<Tenant>(apiUri, tenant, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new Tenant(result))
    );
  }
}
