import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';

const debug = Debug('virtuvisio-administration:four-ho-four-component');

@Component({
  selector: 'app-four-ho-four',
  templateUrl: './four-ho-four.component.html',
  styleUrls: ['./four-ho-four.component.scss'],
})
export class FourHoFourComponent {
  connectedUser$: Observable<ConnectedUser>;

  constructor(private sharedService: SharedService) {
    this.connectedUser$ = this.sharedService.currentConnectedUser;
  }

}
