import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';

@Component({
  selector: 'app-date-picker-field',
  templateUrl: './date-picker-field.component.html',
  styleUrls: ['./date-picker-field.component.scss'],
})
export class DatePickerFieldComponent implements OnInit {
  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() textLabel = '';
  @Input() nameInput = '';
  @Input() myInputModel!: unknown;
  @Input() connectedUser: ConnectedUser;
  @Input() min: Date;
  @Input() max: Date;
  @Output() dateSelect = new EventEmitter<Date>();
  nowDate: Date;

  constructor(private dateAdapter: DateAdapter<Date>) { }

  ngOnInit(): void {
    this.dateAdapter.setLocale(KloodDateFormat.adapterLocale[this.connectedUser.lang]);
    if (this.min) {
      if (this.min.getMinutes() >= 0 && this.min.getMinutes() < 30) {
        this.min.setMinutes(0);
      } else if (this.min.getMinutes() >= 30 && this.min.getMinutes() <= 59) {
        this.min.setMinutes(30);
      }
      this.min.setSeconds(0);
    }
    if (this.max) {
      if (this.max.getMinutes() >= 0 && this.max.getMinutes() < 30) {
        this.max.setMinutes(0);
      } else if (this.max.getMinutes() >= 30 && this.max.getMinutes() <= 59) {
        this.max.setMinutes(30);
      }
      this.max.setSeconds(0);
    }
  }

  checkDate(date: Date): void {
    this.myInputModel = new Date(date);
    this.dateSelect.emit(date);
  }

  changeCalendarClass(): void {
    $('.cdk-overlay-pane').addClass('cdk-overlay-pane-custom-matpicker');
  }
}
