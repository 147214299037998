import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { GraphGeneratorService } from './graph-generator.service';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom, Observable, of, Subscription, throwError } from 'rxjs';
import { StatisticsService } from './statistics.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DataDistributionUses, DataTopUserMeeting, DataUseRate, DistributionUses, Meetings, MeetingsData, Offer, ScatterUsageRate, ScatterUsageRateOffer, TopUser, UseRate } from './statistics.model';
import { DatePipe } from '@angular/common';
import { DatesManager } from '../services/dates/dates.manager';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit, OnDestroy {
  topUser = true;
  usageRate = true;
  fullscreen: string;
  topUserChart: Chart;
  useRateChart: Chart;
  distributionUsesChart: Chart;
  meetingsChart: Chart;
  useRate = Offer.one;
  offers = Offer;
  onInitSubscriptions: Subscription[] = [];
  tenantId: string;
  distributionDataNull = false;
  topUserDataNull = false;
  useRateDataNull = false;
  meetingDataNull = false;
  topUserError = false;
  distributionError = false;
  meetingError = false;
  useRateError = false;

  @ViewChild('topUserChartId') topUserChartElementRef: ElementRef;

  to = new Date();
  from = new Date(new Date().setDate(this.to.getDate() - 7));

  constructor(
    private graphService: GraphGeneratorService,
    private route: ActivatedRoute,
    private statService: StatisticsService,
    private datePipe: DatePipe,
    private dateManager: DatesManager,
    private translations: TranslateService
  ) { }

  ngOnInit(): void {
    this.to = this.dateManager.toEndDay(this.to);
    this.from = this.dateManager.fromStartDay(this.from);

    if (this.route.parent) {
      this.onInitSubscriptions.push(
        this.route.parent.paramMap.subscribe((params) => {
          if (params.get('tenantId')) {
            this.tenantId = params.get('tenantId') as string;
          }
        })
      );
    }

    this.onInitSubscriptions.push(
      this.route.queryParamMap.subscribe((queryParams) => {
        const from = queryParams.get('from');
        const to = queryParams.get('to');
        if (from && to) {
          this.from = new Date(from);
          this.to = new Date(to);
          this.loadTopUser();
          this.loadDistributionUses();
          this.loadUseRate();
          this.loadMeeting();
        }
      })
    );
  }

  // top user --------------------------------------------------------------------------------------------------------------------------

  async loadTopUser() {
    this.topUserDataNull = false;
    this.topUserError = false;
    if (this.topUserChart) {
      await this.topUserChart.destroy();
    }

    let label = '';
    if (this.topUser) {
      label = this.translations.instant(
        'StatisticsComponent.Graphs.NbMeetings'
      );
      let topUserChartCanvas = document.getElementById(
        'top-user-chart'
      ) as HTMLCanvasElement;
      this.topUserChart = await this.graphService.generateTopUserChartCanvas(
        topUserChartCanvas,
        label,
        await this.getTopUsersMeetingNumber(this.tenantId, this.from, this.to)
      );
    } else {
      label = this.translations.instant(
        'StatisticsComponent.Graphs.MeetingsDuration'
      );
      let topUserChartCanvas = document.getElementById(
        'top-user-chart'
      ) as HTMLCanvasElement;
      this.topUserChart = this.graphService.generateTopUserChartCanvas(
        topUserChartCanvas,
        label,
        await this.getTopUsersMeetingDuration(this.tenantId, this.from, this.to)
      );
    }
  }

  async getTopUsersMeetingNumber(
    tenantId: string,
    startDate: Date,
    endDate: Date
  ): Promise<DataTopUserMeeting[]> {
    try {
      const users = await lastValueFrom(this.statService.getTopUser(tenantId, startDate, endDate));
      return this.parseTopUserMeetingNumberData(users);
    } catch (e) {
      this.topUserError = true;
      throw e
    }
  }

  async getTopUsersMeetingDuration(
    tenantId: string,
    startDate: Date,
    endDate: Date
  ): Promise<DataTopUserMeeting[]> {
    try {
      const users = await lastValueFrom(this.statService.getTopUser(tenantId, startDate, endDate));
      return this.parseTopUserMeetingDurationData(users);
    } catch (e) {
      this.topUserError = true;
      throw e
    }
  }

  async parseTopUserMeetingNumberData(
    data: TopUser[]
  ): Promise<DataTopUserMeeting[]> {
    if (Object.keys(data).length === 0) {
      this.topUserDataNull = true;
      return [];
    } else {
      this.topUserDataNull = false;
      let dataArray = [];
      for (let i = 0; i < data.length; i++) {
        let name = data[i].user.firstName + ' ' + data[i].user.lastName;
        let dataObject = { x: name, y: data[i].meetingsCount };
        dataArray.push(dataObject);
      }
      return dataArray;
    }
  }

  async parseTopUserMeetingDurationData(
    data: TopUser[]
  ): Promise<DataTopUserMeeting[]> {
    if (Object.keys(data).length === 0) {
      this.topUserDataNull = true;
      return [];
    } else {
      this.topUserDataNull = false;
      let dataArray = [];
      for (let i = 0; i < data.length; i++) {
        let name = data[i].user.firstName + ' ' + data[i].user.lastName;
        let dataObject = { x: name, y: data[i].meetingsTotalDuration };
        dataArray.push(dataObject);
      }
      return dataArray;
    }
  }

  // usage distribution ------------------------------------------------------------------------------------------------------------------

  async loadDistributionUses() {
    this.distributionDataNull = false;
    this.distributionError = false;
    if (this.distributionUsesChart) {
      await this.distributionUsesChart.destroy();
    }
    let distributionUsesChartCanvas = document.getElementById(
      'distribution-uses-chart'
    ) as HTMLCanvasElement;
    this.distributionUsesChart =
      await this.graphService.generateDistributionUsesCanvas(
        distributionUsesChartCanvas,
        await this.getDistributionUses(this.tenantId, this.from, this.to)
      );
  }

  async getDistributionUses(
    tenantId: string,
    startDate: Date,
    endDate: Date
  ): Promise<DataDistributionUses[]> {
    try {
      const offers = await lastValueFrom(this.statService.getDistributionUses(tenantId, startDate, endDate));
      return await this.parseDistributionUsesData(offers);
    } catch (e) {
      this.distributionError = true;
      throw e
    }
  }

  async parseDistributionUsesData(
    data: DistributionUses
  ): Promise<DataDistributionUses[]> {
    if (Object.keys(data).length === 0) {
      this.distributionDataNull = true;
      return [];
    } else {
      let dataArray: DataDistributionUses[] = [];
      let dataMap = new Map([
        ['BUSINESS', 0],
        ['ONE', 0],
        ['PRO', 0],
      ]);
      for (let [key, value] of Object.entries(data)) {
        if (key == 'BUSINESS') {
          dataMap.set('BUSINESS', value);
        }
        if (key == 'ONE') {
          dataMap.set('ONE', value);
        }
        if (key == 'PRO') {
          dataMap.set('PRO', value);
        }
      }

      for (let [key, value] of dataMap) {
        let dataObject = { id: key, nested: { value: value } };
        dataArray.push(dataObject);
      }
      this.distributionDataNull = false;
      return dataArray;
    }
  }

  // usage rate ---------------------------------------------------------------------------------------------------------------------------

  async loadUseRate() {
    this.useRateDataNull = false;
    this.useRateError = false;
    if (this.useRateChart) {
      await this.useRateChart.destroy();
    }
    if (this.usageRate) {
      let useRateChartCanvas = document.getElementById(
        'use-rate-chart'
      ) as HTMLCanvasElement;
      this.useRateChart = await this.graphService.generateUseRateMaxConcurrentChartCanvas(useRateChartCanvas, await this.getUseRateMaxConcurrent(this.tenantId, this.from, this.to)
      );
    } else {
      let useRateChartCanvas = document.getElementById(
        'use-rate-chart'
      ) as HTMLCanvasElement;
      this.useRateChart = await this.graphService.generateUseRateChartCanvas(
        useRateChartCanvas,
        await this.getUseRate(this.tenantId, this.from, this.to)
      );
    }
  }

  async getUseRateMaxConcurrent(
    tenantId: string,
    startDate: Date,
    endDate: Date
  ): Promise<DataUseRate[]> {
    try {
      const meetings = await lastValueFrom(this.statService.getUseRate(tenantId, startDate, endDate));
      return this.parseUseRateDataMaxConcurrent(meetings);
    } catch (e) {
      this.useRateError = true;
      throw e
    }
  }

  async getUseRate(
    tenantId: string,
    startDate: Date,
    endDate: Date
  ): Promise<any> {
    try {
      const meetings = await lastValueFrom(this.statService.getUseRate(tenantId, startDate, endDate));
      return this.parseUseRateData(meetings);
    } catch (e) {
      this.useRateError = true;
      throw e
    }
  }

  async parseUseRateDataMaxConcurrent(data: UseRate): Promise<DataUseRate[]> {
    if (
      data.statsByOffer.GATEWAY.total == 0 &&
      data.statsByOffer.ONE.total == 0 &&
      data.statsByOffer.PRO.total == 0
    ) {
      this.useRateDataNull = true;
      return [];
    } else {
      this.useRateDataNull = false;
      let dataArray = [];
      for (let [key, value] of Object.entries(data.statsByOffer)) {
        let dataObject = {
          x: key,
          total: value.total,
          maxConcurrent: value.maxConcurrent,
        };
        dataArray.push(dataObject);
      }
      return dataArray;
    }
  }

  async parseUseRateData(data: UseRate): Promise<ScatterUsageRate> {
    let dataArray: ScatterUsageRate = { gateway: [], one: [], pro: [] };
    if (
      data.statsByOffer.GATEWAY.total == 0 &&
      data.statsByOffer.ONE.total == 0 &&
      data.statsByOffer.PRO.total == 0
    ) {
      this.useRateDataNull = true;
      return dataArray;
    } else {
      this.useRateDataNull = false;

      for (let i = 0; i < data.graphPoints.length; i++) {
        let parsedDate = await this.parseDate(data.graphPoints[i].time);
        if (parsedDate) {
          let gatewayObject: ScatterUsageRateOffer = {
            x: parsedDate,
            y: data.graphPoints[i].data.GATEWAY,
          };
          let oneObject: ScatterUsageRateOffer = {
            x: parsedDate,
            y: data.graphPoints[i].data.ONE,
          };
          let proObject: ScatterUsageRateOffer = {
            x: parsedDate,
            y: data.graphPoints[i].data.PRO,
          };
          dataArray.gateway?.push(gatewayObject);
          dataArray.one?.push(oneObject);
          dataArray.pro?.push(proObject);
        }
      }
      return dataArray;
    }
  }

  // meetings ---------------------------------------------------------------------------------------------------------------------------

  async loadMeeting() {
    this.meetingDataNull = false;
    this.meetingError = false;
    if (this.meetingsChart) {
      await this.meetingsChart.destroy();
    }
    let meetingChartCanvas = document.getElementById(
      'meetings-chart'
    ) as HTMLCanvasElement;
    this.meetingsChart = await this.graphService.generateMeetingChartCanvas(
      meetingChartCanvas,
      await this.getMeetings(this.tenantId, this.from, this.to)
    );
  }

  async getMeetings(
    tenantId: string,
    startDate: Date,
    endDate: Date
  ): Promise<MeetingsData[]> {
    try {
      let duration = '24h';
      const meetings = await lastValueFrom(this.statService.getMeetings(tenantId, startDate, endDate, duration));
      return this.parseMeetingData(meetings);
    } catch (e) {
      this.meetingError = true;
      throw e
    }
  }

  async parseMeetingData(data: Meetings[]): Promise<MeetingsData[]> {
    let testIfWeHaveData = false;

    for (let i = 0; i < data.length; i++) {
      if (data[i].totalCount != 0) {
        testIfWeHaveData = true;
        break;
      }
    }
    this.meetingDataNull = !testIfWeHaveData;
    let dataArray = [];
    for (let i = 0; i < data.length; i++) {
      let parsedDate = await this.parseDate(data[i].date);
      let dataObject = {
        x: parsedDate,
        meetings: data[i].totalCount,
        participants: data[i].totalParticipantCount,
      };
      dataArray.push(dataObject);
    }
    return dataArray;
  }

  async parseDate(date: string) {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

  async ngOnDestroy(): Promise<void> {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    if (this.topUserChart) {
      await this.topUserChart.destroy();
    }
    if (this.distributionUsesChart) {
      await this.distributionUsesChart.destroy();
    }
    if (this.useRateChart) {
      await this.useRateChart.destroy();
    }
    if (this.meetingsChart) {
      await this.meetingsChart.destroy();
    }
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
