// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
export const MY_CALENDAR_NATIVE_FORMATS = {
  parseInput: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  fullPickerInput: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'long' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

// https://momentjs.com/docs/#/displaying/format/
export const MY_CALENDAR_MOMENT_FORMATS = {
  parseInput: 'L LT',
  fullPickerInput: 'L      LT',
  datePickerInput: 'L',
  timePickerInput: 'LT',
  monthYearLabel: 'MMMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

export const MY_MATCALENDAR_MOMENT_FORMATS = {
  parse: {
    dateInput: 'L LT',
  },
  display: {
    dateInput: 'L',
    timeInput: 'LT',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};

export const MY_NGXMATCALENDAR_MOMENT_FORMATS = {
  parse: {
    dateInput: 'L LT',
  },
  display: {
    dateInput: 'L LT',
    timeInput: 'LT',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};
