import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserDataCustom } from './user.connected.data.custom';
import { ConnectedUser } from './user.connected.model';

@Injectable()
export class ConnectedUserService {
  private renderer: Renderer2;

  constructor(
    private router: Router,
    private http: HttpClient,
    private optionService: DataApiOptionService,
    private sharedService: SharedService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  getConnectedUser(): Observable<ConnectedUser> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<ConnectedUser>(apiUri + '/me', {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new ConnectedUser(result)),
      tap((result) => {
        this.renderer.removeClass(document.body, 'logged');
        this.renderer.removeClass(document.body, 'unlogged');
        this.renderer.addClass(document.body, 'logged');
        this.sharedService.updateIsLogged(true);
        this.sharedService.updateConnectedUser(result);
        result;
      }),
      catchError((error: any) => {
        this.renderer.removeClass(document.body, 'logged');
        this.renderer.removeClass(document.body, 'unlogged');
        if (
          this.router.url.indexOf('/trial') !== -1 ||
          this.router.url.indexOf('/kloodApp/meeting-room') !== -1
        ) {
          this.renderer.addClass(document.body, 'logged');
        } else {
          this.renderer.addClass(document.body, 'unlogged');
        }
        this.sharedService.updateIsLogged(false);
        return of(error);
      })
    );
  }

  editConnectedUser(user: ConnectedUser): Observable<ConnectedUser> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.put<ConnectedUser>(apiUri + '/me', user, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new ConnectedUser(result)),
      tap((result) => {
        this.sharedService.updateConnectedUser(result);
        result;
      }),
      catchError(this.handleError<ConnectedUser>('editUser', undefined))
    );
  }

  getUserData(): Observable<UserDataCustom> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<UserDataCustom>(apiUri + '/me/data', {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new UserDataCustom(result)),
      tap((result) => {
        this.sharedService.updateConnectedUserData(result);
        result;
      }),
      catchError(
        this.handleError<UserDataCustom>('getUserDataCustom', undefined)
      )
    );
  }

  editUserData(userData: UserDataCustom): Observable<UserDataCustom> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.patch<UserDataCustom>(apiUri + '/me/data', userData, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new UserDataCustom(result)),
      tap((result) => result),
      catchError(
        this.handleError<UserDataCustom>('editUserDataCustom', undefined)
      )
    );
  }

  addAvatar(file: File): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return new Observable((observer) => {
          const request = new XMLHttpRequest();
          request.open('PUT', apiUri + '/me/avatar', true);
          request.withCredentials = true;
          request.setRequestHeader('Content-Type', file.type);
          request.send(file);
          request.onreadystatechange = function () {
            if (
              request.status === 201 ||
              request.status === 200 ||
              request.status === 204
            ) {
              observer.next(request.status);
              observer.complete();
            } else {
              observer.error(request.response);
            }
          };
        });
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
