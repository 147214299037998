<div class="wrapper">
    <div class="input_wrapper" #tooltip="matTooltip" 
            matTooltip="{{toolTipSwitch | translate}}"
            matTooltipClass="custom-tooltip">
        <label for="{{nameInput}}">
            <input type="checkbox" id="{{nameInput}}" [checked]="switchState" (change)="emmitState($event)"
                [(ngModel)]="myInputModel">
            <fa-icon [icon]="['fas', 'check']" class="is_checked"></fa-icon>
            <fa-icon [icon]="['fas', 'times']" class="is_unchecked"></fa-icon>
        </label>
    </div>
</div>