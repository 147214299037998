<app-admin-bloc *ngIf="currentUser" [blocTitle]="currentUser.displayName">
  <form #editUserForm="ngForm" id="editUserForm" name="editUserForm">
    <app-input-form-field [nameInput]="'email'" [typeInput]="'text'" [myInputModel]="currentUser.email" [textLabel]="'UserEditComponent.Email'" [form]="editUserForm" class="form-control" [required]="true" [disabled]="true"></app-input-form-field>
    <app-input-form-field [nameInput]="'lastName'" [typeInput]="'text'" [myInputModel]="currentUser.lastName" [textLabel]="'UserEditComponent.LastName'" [form]="editUserForm" class="form-control" [required]="true"></app-input-form-field>
    <app-input-form-field [nameInput]="'firstName'" [typeInput]="'text'" [myInputModel]="currentUser.firstName" [textLabel]="'UserEditComponent.FirstName'" [form]="editUserForm" class="form-control" [required]="true"></app-input-form-field>
    <app-input-dropdown-form-field [nameInput]="'lang'" [defaultText]="'UserEditComponent.Lang'" [modelKey]="'value'" [displayKey]="'label'" [selectedElement]="currentUser.lang" [myInputModel]="currentUser.lang" [dataSource]="userLangs" class="form-control" (selectedData)="currentUser.lang=$event.value" [form]="editUserForm" [required]="true"></app-input-dropdown-form-field>
    <app-input-dropdown-field [defaultText]="'UserEditComponent.Country'" [modelKey]="'code'" [displayKey]="'displaySelect'" [selectedElement]="countryCode" [dataSource]="countries" class="form-control" (selectedData)="countryCode=$event.value"></app-input-dropdown-field>
    <app-input-form-field [nameInput]="'phone'" [typeInput]="'text'" [myInputModel]="currentUser.phone" [textLabel]="'UserEditComponent.Phone'" [form]="editUserForm" class="form-control"></app-input-form-field>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary w-100" type="reset" (click)="backToView(currentUser.id)">{{"UserEditComponent.Cancel" | translate }}</button>
      <app-loading-button (submitEvent)="confirmEditUser(editUserForm)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'UserEditComponent.Edit'" [finalText]="'UserEditComponent.Edited'">
      </app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="editUserForm.submitted && !editUserForm.valid">{{'UserEditComponent.SomeFields' | translate}}</div>
  </form>
</app-admin-bloc>