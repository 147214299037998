import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent implements OnChanges {
  @Input() initialText = '';
  @Input() finalText = '';
  @Input() finished: boolean;
  @Input() disabled = false;
  @Input() error: boolean;
  @Input() targetStyle = '';
  @Input() additionalStyle = '';
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  loading = false;
  dotEnd = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.finished && changes.finished.currentValue) {
      this.finished = changes.finished.previousValue;
      this.endingDots();
    }
    if (changes && changes.error && changes.error.currentValue) {
      this.error = changes.error.previousValue;
      this.endingDots(changes.error.currentValue);
    }
  }

  submit(): void {
    this.loading = true;
    this.submitEvent.emit();
  }

  endingDots(error?: boolean): void {
    const dots = document.getElementById('dots');
    const dot1 = dots?.getElementsByClassName('dot1')[0];
    dot1?.classList.add('endingDots');
    const dot3 = document.getElementsByClassName('dot3')[0];
    dot3?.classList.add('endingDots');
    setTimeout(async () => {
      this.loading = false;
      if (error) {
        this.finished = false;
      } else {
        this.finished = true;
      }
    }, 500);
  }
}
