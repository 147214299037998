<app-mat-table-custom *ngIf="displayedColumns && userService" [selectedRowItem]="'id'" [selectedRowId]="selectedUser?.id" [loading]="reload" [componentName]="'UsersListComponent'" [hoverable]="true" [displayedColumns]="displayedColumns" [dataService]="userService" [defaultSort]="defaultSort" (dataError)="onDataError($event)" (rowClick)="onRowClick($event)" (actionClick)="onActionClick($event)"></app-mat-table-custom>
<div class="modal fade custom-app-modal" id="modalOnDeleteUserFromList" role="dialog" aria-labelledby="modalOnDeleteUserFromListLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content flat">
      <div class="modal-header">
        <h3 class="modal-title text-center" id="modalOnDeleteUserFromListLabel">
          {{"UsersListComponent.Modal.Deleting" | translate}}</h3>
      </div>
      <div class="modal-body">
        <div class="is-bold">{{"UsersListComponent.Modal.SureDeleteUser" | translate}}</div>
        <div class="mb-3" *ngIf="selectedUser">{{selectedUser.email}}, {{selectedUser.getDisplayName()}}</div>
        <div class="dual-button-container">
          <button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal">
            {{"UsersListComponent.Modal.Cancel" | translate}}
          </button>
          <button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="confirmDeleteUser(selectedUser)">
            {{"UsersListComponent.Modal.Confirm" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>