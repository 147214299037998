import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Invitation } from "./template.model";
import { DataApiOptionService } from "../services/data-api-option/data-api-option.service";

@Injectable()
export class InvitationMailService {
    constructor(private http: HttpClient, private optionService: DataApiOptionService) {}

    getInvitations(tenantId: string): Observable<unknown> {
			return this.optionService.getApiUrl(tenantId).pipe(switchMap((apiUri) => {
        return this.http.get(apiUri + "/contents", { headers: this.optionService.getHeaders(), withCredentials: true });
			}));
    }

    getInvitation(tenantId: string, name: string, lang: string): Observable<unknown> {
			return this.optionService.getApiUrl(tenantId).pipe(switchMap((apiUri) => {
        return this.http.get(apiUri + "/contents/" + name + "/" + lang, { headers: this.optionService.getHeaders(), withCredentials: true });
			}));
    }

    save(tenantId: string, name: string, lang: string, invitation: string): Observable<unknown> {
			return this.optionService.getApiUrl(tenantId).pipe(switchMap((apiUri) => {
        return this.http.put(apiUri + "/contents/" + name + "/" + lang, invitation, { headers: this.optionService.getHeaders(), withCredentials: true });
			}));
    }

    delete(tenantId: string, name: string, lang: string): Observable<unknown> {
			return this.optionService.getApiUrl(tenantId).pipe(switchMap((apiUri) => {
        return this.http.delete(apiUri + "/contents/" + name + "/" + lang, { headers: this.optionService.getHeaders(), withCredentials: true });
			}));
    }

    preview(tenantId: string, name: string, lang: string, invitation: string): Observable<unknown> {
			return this.optionService.getApiUrl(tenantId).pipe(switchMap((apiUri) => {
        return this.http.post(apiUri + "/contents/" + name + "/" + lang + "/render", invitation, { headers: this.optionService.getTextHtmlHeaders(), responseType: "text", withCredentials: true });
			}));
    }

    previewContent(tenantId: string, name: string, lang: string, invitation: Invitation): Observable<unknown> {
			return this.optionService.getApiUrl(tenantId).pipe(switchMap((apiUri) => {
        return this.http.post(apiUri + "/contents/" + name + "/" + lang + "/render", invitation, { headers: this.optionService.getHeaders(), withCredentials: true });
			}));
    }

    render(tenantId: string, name: string, lang: string): Observable<unknown> {
			return this.optionService.getApiUrl(tenantId).pipe(switchMap((apiUri) => {
				return this.http.get(apiUri + "/contents/" + name + "/" + lang + "/render", { headers: this.optionService.getTextHtmlHeaders(), responseType: "text", withCredentials: true })
			}));
    }

		renderContent(tenantId: string, name: string, lang: string): Observable<unknown> {
			return this.optionService.getApiUrl(tenantId).pipe(switchMap((apiUri) => {
				return this.http.get(apiUri + "/contents/" + name + "/" + lang + "/render", { headers: this.optionService.getTextHtmlHeaders(), withCredentials: true })
			}));
    }

}
