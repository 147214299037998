import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { SharedService } from '../../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuardGuard implements CanActivate, CanActivateChild {

  constructor(private sharedService: SharedService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sharedService._connectedUser().pipe(
      switchMap((user: ConnectedUser) => {
        if (!user) {
          return of(false);
        } else {
          if (user.hasRole('SUPER_ADMIN')) {
            return of(true);
          } else {
            this.router.navigate(['/administration']);
            return of(false);
          }
        }
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sharedService._connectedUser().pipe(
      switchMap((user: ConnectedUser) => {
        if (!user) {
          return of(false);
        } else {
          if (user.hasRole('SUPER_ADMIN')) {
            return of(true);
          } else {
            this.router.navigate(['/administration']);
            return of(false);
          }
        }
      })
    );
  }

}
