import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Tenant } from 'src/app/tenant/tenant.model';
import { OauthClientsService } from '../oauth-clients.service';
import { OauthClient } from "../oauth-client.model";
import { ActivatedRoute, Router } from '@angular/router';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { Subscription } from 'rxjs';
import { Actions, Controls } from 'src/app/commons/toolbar/toolbar.model';

@Component({
  selector: 'app-details-oauth-client',
  templateUrl: './details-oauth-client.component.html',
  styleUrls: ['./details-oauth-client.component.scss']
})
export class DetailsOauthClientComponent implements OnInit, OnDestroy {
  currentOauthClient = new OauthClient({});
  tenants: Tenant[];
  paramSubscription: Subscription;
  controls: Controls[] = [];
  constructor(private oauthClientsService: OauthClientsService, private router: Router, private route: ActivatedRoute, private notifService: NotifService) { }

  ngOnInit(): void {
    this.controls.push({
      icon: 'edit',
      controleType: 'ACTION',
      isSwitch: false,
      tooltip: "Edit",
      action: Actions.Edit,
    }, {
      icon: 'times',
      controleType: 'ACTION',
      isSwitch: false,
      tooltip: "Close",
      action: Actions.Back
    })
    this.getTenants();
    this.paramSubscription = this.route.paramMap.subscribe(params => {
      let clientId = params.get('clientId');
      if (clientId) {
        this.getClient(clientId);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  getClient(clientId: string): void {
    this.oauthClientsService.getOauthClient(clientId).subscribe({
      next: result => {
        this.currentOauthClient = result;
      },
      error: (error: HttpErrorResponse) => {
        console.log('Error getting oauth client');
      }
    })
  }

  getTenants(): void {
    this.oauthClientsService.getTenants().subscribe({
      next: result => {
        this.tenants = result;
        const allTenants: Tenant = new Tenant({});
        allTenants.name = "EditOauthClientComponent.AllTenants";
        allTenants.id = "*";
        this.tenants.unshift(allTenants);
      },
      error: (error: HttpErrorResponse) => {
        console.log("Error getting tenants");
      }
    });
  }

  backToList(): void {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  scopesAsArray(scopes: any): string[] {
    return scopes;
  }

  onRequestAction(event: string): void {
    if (event === "EDIT") {
      this.router.navigate(['../../edit/' + this.currentOauthClient.id], { relativeTo: this.route });
    }
    if (event === "BACK") {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }
  }

}
