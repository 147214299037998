import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { RequestFilterBuilder } from 'src/app/services/request-filter-builder/request-filter-builder';
import { suggestPattern, dateGreaterOrEqual, dateLowerOrEqual, notEqual } from 'src/app/operators/mongo-operators/mongo-operators';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { ActivatedRoute } from '@angular/router';
import { PaginationInfo } from '../commons/mat-table-custom/mat-table-custom.model';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from '../services/http-encoder/custom-http-param-encoder.service';
import { Meeting } from '../meeting/meeting.model';

@Injectable()
export class PlannedMeetingsService {
  tenantId: Observable<string> = of('');

  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService,
    private route: ActivatedRoute,
    private datesManager: DatesManager
  ) {
    this.tenantId = <Observable<string>>(
      this.route.parent?.paramMap.pipe(
        map((params) => params.get('tenantId'))
      )
    );
  }

  buildParamsUrl(
    paginationInfo: PaginationInfo,
    moreParams?: string[]
  ): HttpParams {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoderService(),
    });
    params = params.set('showDeleted', true);
    if (moreParams) {
      for (let p in moreParams) {
        let customParam = <string>(
          paginationInfo[<keyof PaginationInfo>moreParams[p]]
        );
        params = params.set(moreParams[p], customParam);
      }
    }
    const filter = new RequestFilterBuilder();
    if (paginationInfo.from && paginationInfo.to) {
      filter.set(
        'beginDate',
        dateGreaterOrEqual(this.datesManager.fromStartDay(paginationInfo.from))
      );
      filter.set(
        'endDate',
        dateLowerOrEqual(this.datesManager.toEndDay(paginationInfo.to))
      );
    }
    if (paginationInfo.suggest) {
      filter.set('suggests', suggestPattern(paginationInfo.suggest));
    }
    filter.set('type', notEqual('SERIES_MASTER'));
    const q = filter.serialize();
    return (params = params.set('q', q));
  }

  getHead(paginationInfo: PaginationInfo): Observable<number> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            const params = this.buildParamsUrl(paginationInfo);
            return this.http
              .head(apiUri + '/meetings', {
                params: params,
                headers: this.optionService.getHeaders(),
                observe: 'response',
                withCredentials: true,
              })
              .pipe(
                map((result) => parseInt(<string>result.headers.get('Count')))
              );
          })
        );
      })
    );
  }

  getData(paginationInfo: PaginationInfo): Observable<Meeting[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            const params = this.buildParamsUrl(paginationInfo, [
              'sort',
              'order',
              'skip',
              'limit',
            ]);
            return this.http
              .get<Meeting[]>(apiUri + '/meetings', {
                params: params,
                headers: this.optionService.getHeaders(),
                withCredentials: true,
              })
              .pipe(
                map((plannedMeetings) =>
                  plannedMeetings.map(
                    ({ beginDate, endDate, ...otherData }) => ({
                      ...otherData,
                      beginDate: new Date(beginDate),
                      endDate: new Date(endDate),
                    })
                  )
                )
              );
          })
        );
      })
    );
  }

  deleteMeeting(meetingId: string): Observable<any> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.delete(apiUri + '/meetings/' + meetingId, {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            });
          })
        );
      })
    );
  }

  getMeeting(meetingId: string): Observable<Meeting> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.get<Meeting>(apiUri + '/meetings/' + meetingId, {
              headers: this.optionService.getHeaders(),
              observe: 'body',
              withCredentials: true,
            });
          }),
          map((result) => new Meeting(result)),
          tap((result) => result),
          catchError(this.handleError<Meeting>('getMeeting', undefined))
        );
      })
    );
  }

  saveMeeting(meeting: Meeting): Observable<Meeting> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.put<Meeting>(
              apiUri + '/meetings/' + meeting.id,
              meeting,
              {
                headers: this.optionService.getHeaders(),
                observe: 'body',
                withCredentials: true,
              }
            );
          }),
          map((result) => new Meeting(result)),
          tap((result) => result),
          catchError(this.handleError<Meeting>('saveMeeting', undefined))
        );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
