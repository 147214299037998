<div class="row align-items-center p-3">
  <div class="col-auto">
      <h3 class="m-0">
        <span class="is-bold">{{"LiveConferencesComponent.LiveConferences" | translate}}</span>
      </h3>
  </div>
</div>
<app-admin-card>
    <div class="row">
        <div class="col-12">
            <app-mat-table-custom *ngIf="displayedColumns && liveConferences" [selectedRowItem]="'id'" [selectedRowId]="selectedLiveConferenceId" [loading]="loading" [componentName]="'LiveConferencesComponent'" [displayedColumns]="displayedColumns" [dataSource]="liveConferences" [defaultSort]="defaultSort" (dataError)="onDataError($event)" (rowClick)="onRowClick($event)"></app-mat-table-custom>
        </div>
    </div>
</app-admin-card>

