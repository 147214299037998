import { Injectable } from '@angular/core';
import Chart from "chart.js/auto";
import { Subscription } from "rxjs";
import { DataDistributionUses, DataTopUserMeeting, DataUseRate, MeetingsData, ScatterUsageRate } from "./statistics.model";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class GraphGeneratorService {

  onInitSubscriptions: Subscription[] = [];
  tenantId: string;
  backGroundColor = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)'
  ];
  borderColor = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)'
  ];

  constructor(private translations: TranslateService) { }

  generateTopUserChartCanvas(ctx: any, label: string, data: DataTopUserMeeting[]): any {
    return new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: [{
          label: label,
          data: data,
          backgroundColor: this.backGroundColor,
          borderColor: this.borderColor,
          borderWidth: 1
        }]
      },
      options: {
        // responsive: true,
        maintainAspectRatio: false,
        // scales: {
        //     y: {
        //         beginAtZero: true
        //     }
        // }
      }
    });
  }

  generateDistributionUsesCanvas(ctx: any, data: DataDistributionUses[]): any {
    return new Chart(ctx, {
      type: 'pie',
      data: {
        labels: [
          "Business",
          "One",
          "Pro"
        ],
        datasets: [{
          // label: 'Répartiton de l\'utilisation',
          data: data,
          backgroundColor: this.backGroundColor,
          borderColor: this.borderColor,
          borderWidth: 1,
        }]
      },
      options: {
        parsing: {
          key: 'nested.value'
        },
        // responsive: true,
        maintainAspectRatio: false
      }

    });
  }

  generateUseRateMaxConcurrentChartCanvas(ctx: any, data: DataUseRate[]): any {
    return new Chart(ctx, {
      type: 'bar',
      data: {

        datasets: [{
          label: this.translations.instant("StatisticsComponent.Graphs.TotalMeetings"),
          data: data,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          parsing: {
            yAxisKey: 'total'
          }
        }, {
          label: this.translations.instant("StatisticsComponent.Graphs.ConcurrentMeetings"),
          data: data,
          backgroundColor: 'rgba(255, 206, 86, 0.2)',
          borderColor: 'rgba(255, 206, 86, 1)',
          borderWidth: 1,
          parsing: {
            yAxisKey: 'maxConcurrent'
          }
        }]
      },
      options: {
        // responsive: true,
        maintainAspectRatio: false,
      }
    });
  }

  generateUseRateChartCanvas(ctx: any, data: ScatterUsageRate): any {
    return new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [{
          label: 'Gateway',
          data: data.gateway,
          backgroundColor: 'rgba(255, 99, 132, 1)',
        }, {
          label: 'One',
          data: data.one,
          backgroundColor: 'rgba(54, 162, 235, 1)',
        }, {
          label: 'Pro',
          data: data.pro,
          backgroundColor: 'rgba(255, 206, 86, 1)',
        }]
      },
      options: {
        scales: {
          x: {
            type: 'category',
            position: 'bottom'
          }
        },
        // responsive: true,
        maintainAspectRatio: false
      }

    });
  }

  generateMeetingChartCanvas(ctx: any, data: MeetingsData[]): any {
    return new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: [{
          label: this.translations.instant("StatisticsComponent.Graphs.NbMeetings"),
          data: data,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          parsing: {
            yAxisKey: 'meetings'
          }
        }, {
          label: this.translations.instant("StatisticsComponent.Graphs.NbParticipants"),
          data: data,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          parsing: {
            yAxisKey: 'participants'
          }
        }]
      },
      options: {
        // responsive: true,
        maintainAspectRatio: false,
      }
    });
  }
}
