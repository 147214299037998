import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { ObtpRegexps, VerifiedObtpRegexp, VerifyObtpRegexp } from '../obtp.model';

@Injectable()
export class EditObtpService {

  constructor(private http: HttpClient, private optionService: DataApiOptionService, private route: ActivatedRoute) { }

  addRegexp(tenantId: string, newRegexp: ObtpRegexps): Observable<ObtpRegexps> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http
          .post<ObtpRegexps>(apiUri + '/obtp/regexp', newRegexp, {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          })
          .pipe(
            map((result) => {
              return result;
            }),
            tap((result) => result),
            catchError(this.handleError<ObtpRegexps>('addRegexp', undefined))
          );
      })
    );
  }

  verifyRegexp(tenantId: string, newRegexp: VerifyObtpRegexp): Observable<VerifiedObtpRegexp> {
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http
          .post<VerifiedObtpRegexp>(apiUri + '/obtp/regexp/verify', newRegexp, {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          })
          .pipe(
            map((result) => {
              return result;
            }),
            tap((result) => result),
            catchError(this.handleError<any>('verifyRegexp', null))
          );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
