import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Contact } from '../contact.model';
import { ContactService } from '../contact.service';

@Component({
	selector: 'app-suggest-contact',
	templateUrl: './suggest-contact.component.html',
	styleUrls: ['./suggest-contact.component.scss'],
	providers: [ContactService]
})
export class SuggestContactComponent implements OnChanges {
	@Input() textInput: string;
	@Output() selectContactEvent = new EventEmitter<Contact>();
	@Output() closeSuggestEvent = new EventEmitter<boolean>();
	contacts$: Observable<Contact[]> = of([]);
	
	constructor(private elementRef: ElementRef, private contactService: ContactService) {}
	
	ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges && simpleChanges.textInput) {
			if (simpleChanges.textInput.previousValue !== simpleChanges.textInput.currentValue) {
				this.getSuggest();
			}
		}
	}
	
	getSuggest(): void {
		this.contacts$ = this.contactService.getSuggest(this.textInput);
	}
	
	selectContact(contact: Contact): void {
		this.selectContactEvent.emit(contact);
		this.closeSuggestEvent.emit(false);
	}
	
	@HostListener('document:click', ['$event'])
	clickout(event: Event): void {
		if (this.elementRef.nativeElement.contains(event.target)) {
			this.closeSuggestEvent.emit(true);
		} else {
			this.closeSuggestEvent.emit(false);
		}
	}
}
