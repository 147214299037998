import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch-field-simple',
  templateUrl: './switch-field-simple.component.html',
  styleUrls: ['./switch-field-simple.component.scss'],
})
export class SwitchFieldSimpleComponent {
  @Input() iconName: string;
  @Input() switchState: boolean;
  @Input() nameInput = '';
  @Output() switchStateEvent = new EventEmitter<boolean>();
  @Input() toolTipSwitch: string;

  emmitState(event: Event): void {
    const checkBox = event.target as HTMLInputElement;
    const value = checkBox.checked;
    this.switchStateEvent.emit(value);
  }
}
