import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, RouterEvent, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { QueryParamsManager } from 'src/app/services/query-params-manager/query-params-manager';
import { PaginationInfo } from 'src/app/commons/mat-table-custom/mat-table-custom.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { OauthClientsService } from './oauth-clients.service';
import { OauthClient } from './oauth-client.model';
import { Tenant } from 'src/app/tenant/tenant.model';

@Component({
  selector: 'app-oauth-clients',
  templateUrl: './oauth-clients.component.html',
  styleUrls: ['./oauth-clients.component.scss', '../commons/mat-table-custom/mat-table-custom.component.scss'],
  providers: [QueryParamsManager, OauthClientsService]
})
export class OauthClientsComponent implements OnInit, OnDestroy {
  defaultSort = "id";
  loading = false;
  dataSource: MatTableDataSource<OauthClient>;
  paginationInfo: PaginationInfo | any;
  queryParamSub: Subscription;
  paginatorReady = false;
  onInitSubscriptions: Subscription[] = [];
  tenants: Tenant[];
  selectedOauthClient: OauthClient;
  routerEvent: RouterEvent;
  constructor(private translate: TranslateService, private matPaginatorIntl: MatPaginatorIntl, private route: ActivatedRoute, private router: Router, private queryParamsManager: QueryParamsManager, private datesManager: DatesManager, private notifService: NotifService, private oauthClientsService: OauthClientsService) { }

  ngOnInit(): void {
    this.paginationInfo = new PaginationInfo();
    this.paginationInfo.sort = this.defaultSort;
    this.paginationInfo.displayedColumns = ["id", "name", "description", "scopes", "action"];
    this.initLabelMatPaginator();
    this.onInitSubscriptions.push(this.route.queryParams.subscribe((params) => {
      for (let q in params) {
        if (q in this.paginationInfo) {
          if (q === "from" || q === "to") {
            if (this.datesManager.isValidDate(params[q])) {
              this.paginationInfo[q] = new Date(params[q]);
            }
          } else {
            this.paginationInfo[<keyof PaginationInfo>q] = params[q];
          }
        }
      }
      this.getHeadOauthClients();
    }));
    this.onInitSubscriptions.push(this.router.events.subscribe(event => {
      this.routerEvent = event as RouterEvent;
    }))
    this.getTenants();
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }

  getTenants(): void {
    this.oauthClientsService.getTenants().subscribe({
      next: (result) => {
        this.tenants = result;
      },
      error: (error: HttpErrorResponse) => {
        console.log("Error getting tenants");
      }
    });
  }

  getHeadOauthClients(): void {
    this.loading = true;
    this.oauthClientsService.getHead(this.paginationInfo).subscribe({
      next: (result: number) => {
        this.loading = false;
        this.paginationInfo.listLength = result;
        this.paginationInfo.limit = this.paginationInfo.pageSize;
        if (this.paginationInfo.limit * this.paginationInfo.pageIndex > this.paginationInfo.listLength) {
          this.paginationInfo.skip = 0;
          this.paginationInfo.pageIndex = 0;
        }
        this.getDataOauthClients();
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
      }
    });
  }

  getDataOauthClients(): void {
    this.loading = true;
    this.oauthClientsService.getData(this.paginationInfo).subscribe({
      next: (result: OauthClient[]) => {
        this.loading = false;
        this.dataSource = new MatTableDataSource(result);
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
      }
    });
  }

  async onChangePagination(event: PageEvent): Promise<void> {
    this.paginationInfo.pageSize = this.paginationInfo.limit = event.pageSize;
    this.paginationInfo.pageIndex = event.pageIndex;
    this.paginationInfo.skip = event.pageSize * event.pageIndex;
    await this.updateQueryParams();
  }

  async onSuggest(suggest: string): Promise<void> {
    this.paginationInfo.suggest = suggest;
    this.paginationInfo.pageIndex = 0;
    this.paginationInfo.skip = 0;
    await this.updateQueryParams();
  }

  async onSort(event: Sort): Promise<void> {
    this.paginationInfo.sort = "email";
    this.paginationInfo.order = "";
    if (event.direction) {
      this.paginationInfo.sort = event.active;
      this.paginationInfo.order = event.direction;
    }
    await this.updateQueryParams();
  }

  async updateQueryParams(): Promise<void> {
    await this.queryParamsManager.changeQueryParams({
      pageSize: this.paginationInfo.pageSize,
      pageIndex: this.paginationInfo.pageIndex,
      skip: this.paginationInfo.skip,
      limit: this.paginationInfo.limit,
      order: this.paginationInfo.order,
      sort: this.paginationInfo.sort,
      suggest: this.paginationInfo.suggest,
    });
  }

  async initLabelMatPaginator(): Promise<void> {
    await setTimeout(async () => {
      this.matPaginatorIntl.firstPageLabel = await this.translate.instant("MatPaginator.FirstPageLabel");
      this.matPaginatorIntl.itemsPerPageLabel = await this.translate.instant("MatPaginator.ItemsPerPageLabel");
      this.matPaginatorIntl.lastPageLabel = await this.translate.instant("MatPaginator.LastPageLabel");
      this.matPaginatorIntl.nextPageLabel = await this.translate.instant("MatPaginator.NextPageLabel");
      this.matPaginatorIntl.previousPageLabel = await this.translate.instant("MatPaginator.PreviousPageLabel");
      const rangeTxtLabel = await this.translate.instant("MatPaginator.RangeTxtLabel");
      this.matPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
          return `0 ` + rangeTxtLabel + ` ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ` + rangeTxtLabel + ` ${length} (page n°: ${page + 1})`;
      };
      this.paginatorReady = true;
    })
  }

  confirmDeleteOauthClient(): void {
    this.oauthClientsService.delete(this.selectedOauthClient).subscribe({
      next: (result) => {
        this.getHeadOauthClients();
      },
      error: (error: HttpErrorResponse) => {
        this.notifService.addErrorNotif('OauthClientsComponent.ErrorDeleteOauthClient', error);
      }
    });
  }

  getScopes(data: any): string[] {
    if (data && data.length > 0) {
      return data;
    }
    return [];
  }

  getTenant(tenantId: unknown): string {
    tenantId = tenantId as string;
    if (tenantId == "*") {
      return "OauthClientsComponent.AllOrgnizations"
    } else {
      if (this.tenants) {
        for (let tenant of this.tenants) {
          if (tenant.id === tenantId) {
            return tenant.id + " - " + tenant.name;
          }
        }
      }
    }
    return <string>tenantId;
  }

  isRoute(param: string): boolean {
    if (this.routerEvent?.url) {
      if (this.routerEvent.url.includes(param)) {
        return true;
      }
    }
    if (this.router?.url) {
      if (this.router.url.includes(param)) {
        return true;
      }
    }
    return false;
  }

  componentOauthClientRemoved(): void {
    this.getHeadOauthClients();
  }

  viewClient(client: OauthClient): void {
    this.router.navigate(['./details/' + client.id], { relativeTo: this.route });
  }

  editClient(client: OauthClient): void {
    this.router.navigate(['./edit/' + client.id], { relativeTo: this.route });
  }
}
