import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppConfigService } from './services/config/app.config.service';
import { NotifService } from './services/notifs/notif.service';
import { SharedService } from './services/shared/shared.service';
import { ThemeService } from './services/theme/theme.service';
import { ConnectedUserService } from './users/user-connected/connected-user.service';
import { UserDataCustom } from './users/user-connected/user.connected.data.custom';
import { ConnectedUser } from './users/user-connected/user.connected.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ConnectedUserService]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'virtuvisio-administration';
  private userSubscription: Subscription;
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  userData: UserDataCustom;
  timeoutCheck: ReturnType<typeof setTimeout>;

  constructor(
    translate: TranslateService,
    private userService: ConnectedUserService,
    private sharedService: SharedService,
    private configService: AppConfigService,
    private notif: NotifService,
    private themeService: ThemeService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (localStorage.getItem('currentLang')) {
      translate.use(localStorage.getItem('currentLang') || 'fr');
    } else {
      translate.use('fr');
    }

    translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      // do something
      translate.use(event.lang);
      localStorage.setItem('currentLang', event.lang);
    });

    this.themeService.initTheme();
  }

  ngOnInit(): void {
    this.userSubscription = this.userService.getConnectedUser().subscribe();
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe((connectedUser: ConnectedUser) => {
      if (connectedUser) {
        this.connectedUser = connectedUser;
      }
    }));
    this.runCheck();
  }

  runCheck(): void {
    this.checkConfig();
    this.timeoutCheck = setTimeout(() => {
      this.runCheck();
    }, 10 * 60 * 1000);
  }

  checkConfig(): void {
    this.configService.load().subscribe((result) => {
      console.log("Version de l'application : " + result.APP_VERSION);
      if (!localStorage.getItem('currentAdminVersion')) {
        localStorage.setItem('currentAdminVersion', result.APP_VERSION);
      } else if (localStorage.getItem('currentAdminVersion') !== result.APP_VERSION) {
        localStorage.setItem('currentAdminVersion', result.APP_VERSION);
        this.notif.addNewVersionNotif();
        clearTimeout(this.timeoutCheck);
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
