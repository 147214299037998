import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { QueryParamsManager } from '../services/query-params-manager/query-params-manager';
import { SharedService } from '../services/shared/shared.service';
import { ConnectedUser } from '../users/user-connected/user.connected.model';

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss'],
  providers: [QueryParamsManager]
})
export class UsageComponent implements OnInit, OnDestroy {

  connectedUser$: Observable<ConnectedUser>;
  selectedTabIndex = 0;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private sharedService: SharedService, private queryParamsManager: QueryParamsManager, private route: ActivatedRoute) {
    this.connectedUser$ = this.sharedService.currentConnectedUser;
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params["tabIndex"]) {
        this.selectedTabIndex = params["tabIndex"];
      }
    })
  }

  async switchTab(event: any): Promise<void> {
    await this.queryParamsManager.changeQueryParams({ tabIndex: event.index });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
