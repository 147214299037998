import { Component, OnDestroy, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { LiveUsageService } from './live-usage.service';
import Debug from 'debug';
import { first, interval, Subscription } from 'rxjs';
const debug = Debug('virtuvisio-administration:live-usage-component');

@Component({
  selector: 'app-live-usage',
  templateUrl: './live-usage.component.html',
  styleUrls: ['./live-usage.component.scss'],
  providers: [LiveUsageService]
})
export class LiveUsageComponent implements OnInit, OnDestroy {

  usageError = false;
  usageNull = false;
  usageChart: Chart
  timerLoad: Subscription;
  timerCounter: Subscription;
  timer: number;
  onInitSubscriptions: Subscription[] = [];

  constructor(private usageService: LiveUsageService) { }

  ngOnInit(): void {
    this.loadUsage();
    this.timer = 5;
    if (this.timerCounter) {
      this.timerCounter.unsubscribe()
    }
    this.timerCounter = interval(1000).subscribe(() => this.timer--)
    this.timerLoad = interval(5000).subscribe(() => {
      this.timer = 5;
      if (this.timerCounter) {
        this.timerCounter.unsubscribe()
      }
      this.timerCounter = interval(1000).subscribe(() => this.timer--)
      this.loadUsage()
    })
  }

  async loadUsage() {
    this.usageNull = false;
    this.usageError = false;
    if (this.usageChart) {
      await this.usageChart.destroy();
    }
    this.onInitSubscriptions.push(this.usageService.getUsage().pipe(first()).subscribe({
      next: (result: UsageData) => {
        if (!result) {
          this.usageNull = true;
        } else {
          if (result.justiceUsageCounter === 0 && result.legacyUsageCounter === 0 && result.virtuvisioUsageCounter === 0) {
            this.usageNull = true;
          } else {
            let usageChartCanvas = document.getElementById('live-usage-chart') as HTMLCanvasElement;
            this.usageChart = this.generateCanvas(usageChartCanvas, result);
          }
        }
      },
      error: (error) => {
        debug(error);
        this.usageError = true;
      }
    }))
  }

  generateCanvas(ctx: any, data: UsageData): any {
    return new Chart(ctx, {
      type: 'pie',
      data: {
        labels: [
          "Virtuvisio",
          "Legacy",
          "Justice"
        ],
        datasets: [{
          data: [
            data.virtuvisioUsageCounter,
            data.legacyUsageCounter,
            data.justiceUsageCounter
          ],
          backgroundColor: [
            'rgba(189, 49, 86, 0.6)',
            'rgba(230, 162, 3, 0.6)',
            'rgba(0, 33, 83, 0.6)',
          ],
          borderColor: [
            'rgba(189, 49, 86, 1)',
            'rgba(230, 162, 3, 1)',
            'rgba(0, 33, 83, 1)',
          ],
          borderWidth: 1,
        }]
      },
      options: {
        maintainAspectRatio: false
      }
    });
  }

  ngOnDestroy(): void {
    if (this.timerLoad) {
      this.timerLoad.unsubscribe();
    }
    if (this.timerCounter) {
      this.timerCounter.unsubscribe()
    }
    this.onInitSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}

interface UsageData {
  from: Date;
  justiceUsageCounter: number;
  legacyUsageCounter: number;
  resetCounter: number;
  to: Date
  virtuvisioUsageCounter: number;
}
