import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.scss'],
})
export class TextareaFieldComponent implements AfterViewInit {
  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() textLabel = '';
  @Input() nameInput = '';
  @Input() form!: NgForm;
  @Input() targetValidators!: ValidatorFn[];
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() myInputModel!: unknown;
  @Output() blurEvent = new EventEmitter<Event>();

  ngAfterViewInit(): void {
    if (!this.form.getControl(this.inputModel)) {
      this.form.addControl(this.inputModel);
      if (this.targetValidators) {
        setTimeout(() => {
          this.form.controls[this.nameInput].setValidators(this.targetValidators);
        }, 100);
      }
    }
  }

  onBlur(event: Event): void {
    this.blurEvent.emit(event);
  }
}
