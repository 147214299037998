import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-switch-field',
  templateUrl: './switch-field.component.html',
  styleUrls: ['./switch-field.component.scss'],
})
export class SwitchFieldComponent {
  @Input() iconName: string;
  @Input() switchState: boolean;
  @Input() nameInput = '';
  @Output() switchStateEvent = new EventEmitter<boolean>();
  @Input() toolTipSwitch: string;

  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() myInputModel!: unknown;

  emmitState(event: Event): void {
    const checkBox = event.target as HTMLInputElement;
    const value = checkBox.checked;
    this.myInputModel = value;
    this.switchStateEvent.emit(value);
  }
}
