import { Component, Input, OnInit } from '@angular/core';
import { MeetingRoom } from '../meeting-rooms/meeting-room.model';
import { MeetingRoomsService } from '../meeting-rooms/meeting-rooms.service';
import { KloodDateFormat } from '../services/dates/dates.format.service';
import { NotifService } from '../services/notifs/notif.service';
import { Tenant } from '../tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { Endpoint, EndpointCapabilities, SipAccount } from './endpoint.model';
import { EndpointsService } from './endpoints.service';

@Component({
  selector: 'app-endpoints',
  templateUrl: './endpoints.component.html',
  styleUrls: ['./endpoints.component.scss'],
  providers: [EndpointsService]
})
export class EndpointsComponent implements OnInit {

  @Input() currentMeetingRoom: MeetingRoom;
  @Input() currentTenant: Tenant;
  @Input() currentConnectedUser: ConnectedUser;

  addEndpoint = false;
  editEndpoint = false;
  currentEndpoint: Endpoint;
  newSipAccount: SipAccount;
  addingSipAccount = false;
  loading = false;
  endpointStatusActive = false;
  endpointStatusUnknown = false;
  errorEndpoint: any;
  kloodDateFormat = KloodDateFormat;
  deletingMeetingRoomEndpoint = false;
  editAdminContact = false;
  resetedPassword: any;
  justAddSipAccount: SipAccount;
  hasSipAccount = false;
  hasError = false;
  finished = false;
  endpointCapabilites = EndpointCapabilities;

  constructor(private meetingRoomsService: MeetingRoomsService, private endpointsService: EndpointsService, private notifService: NotifService) { }

  ngOnInit(): void {
    if (this.currentMeetingRoom.endpoint) {
      if (this.currentMeetingRoom.endpoint.sipAccount) {
        this.hasSipAccount = true;
      }
      this.getEndpointStatus(this.currentMeetingRoom.id);
    }
  }

  getMeetingRoom(): void {
    this.meetingRoomsService.getMeetingRoom(this.currentMeetingRoom.id).subscribe(result => {
      this.currentMeetingRoom = result;
    })
  }

  sendEndpointInfo(meetingRoom: MeetingRoom): void {
    this.loading = true;
    this.endpointsService.sendEndpointInfos(meetingRoom.id).subscribe({
      next: (result) => {
        this.loading = false;
        this.notifService.addSuccessNotif("Endpoints.SendInfosSuccess");
      },
      error: (error) => {
        this.loading = false;
        this.notifService.addErrorNotif("Endpoints.SendInfosError", error);
      }
    })
  }

  getEndpointStatus(meetingRoomId: string): void {
    this.errorEndpoint = null;
    this.loading = true;
    this.endpointsService.getEndpointStatus(meetingRoomId).subscribe({
      next: (result) => {
        this.endpointStatusActive = true;
        this.endpointStatusUnknown = false;
        this.errorEndpoint = result;
        this.loading = false;
      },
      error: (error) => {
        this.errorEndpoint = null;
        if (error.status === 404) {
          this.endpointStatusUnknown = true;
        } else {
          this.endpointStatusUnknown = false;
        }
        this.endpointStatusActive = false;
        this.loading = false;
      }
    })
  }

  confirmDeleteMeetingRoomEndpoint(meetingRoom: MeetingRoom): void {
    this.loading = true;
    this.endpointsService.delete(meetingRoom.id).subscribe({
      next: (result) => {
        this.deletingMeetingRoomEndpoint = false;
        this.loading = false;
        this.getMeetingRoom();
        this.notifService.addSuccessNotif("Endpoints.EndpointDeletedSuccess");
      },
      error: (error) => {
        this.loading = false;
        this.notifService.addErrorNotif("Endpoints.EndpointDeletedError", error);
      }
    })
  }

  onAdminContactChange(text: string): void {
    this.currentMeetingRoom.endpoint.adminContact = text;
  }

  confirmEditAdminContact(meetingRoom: MeetingRoom): void {
    this.loading = true;
    this.endpointsService.save(meetingRoom.id, meetingRoom.endpoint).subscribe({
      next: (result) => {
        this.editAdminContact = false;
        this.loading = false;
        this.notifService.addSuccessNotif("Endpoints.EditAdminContactSuccess");
      },
      error: (error) => {
        this.loading = false;
        this.notifService.addErrorNotif("Endpoints.EditAdminContactError", error);
      }
    })
  }

  resetPassword(meetingRoom: MeetingRoom): void {
    this.loading = true;
    this.endpointsService.resetEndpointPassword(meetingRoom.id).subscribe({
      next: (result) => {
        this.resetedPassword = result;
        $("#modalSipAccountResetPassword").modal("show");
        this.loading = false;
        this.notifService.addSuccessNotif("Endpoints.PasswordResetSuccess");
      },
      error: (error) => {
        this.loading = false;
        this.notifService.addErrorNotif("Endpoints.PasswordResetError", error);
      }
    })
  }

  onActiveEndpointCheckEvent(event: boolean): void {
    this.currentMeetingRoom.endpoint.active = event;
    this.activateEndpoint();
  }

  activateEndpoint(): void {
    this.loading = true;
    this.endpointsService.save(this.currentMeetingRoom.id, this.currentMeetingRoom.endpoint).subscribe({
      next: (result) => {
        this.loading = false;
        this.notifService.addSuccessNotif("Endpoints.ActivateSuccess");
      },
      error: (error) => {
        this.loading = false;
        this.notifService.addErrorNotif("Endpoints.ActivateError", error);
      }
    })
  }

  onSipAccountCheckEvent(event: boolean): void {
    this.hasSipAccount = event;
    this.activateSipAccount(this.hasSipAccount);
  }

  checkCapabiltyDisabled(capability: string): boolean {
    switch (capability) {
      case "GATEWAY":
        return !this.currentTenant.hasAccess('GATEWAY');
      case "ONE_CODEC":
        return !this.currentTenant.hasAccess('ONE_CODEC');
      case "BOTH":
        return !(this.currentTenant.hasAccess('GATEWAY') && this.currentTenant.hasAccess('ONE_CODEC'));
      default:
        return false;
    }
  }

  updateCapabilityEndpoint(): void {
    this.loading = true;
    this.endpointsService.save(this.currentMeetingRoom.id, this.currentMeetingRoom.endpoint).subscribe({
      next: (result) => {
        this.loading = false;
        this.notifService.addSuccessNotif("Endpoints.EditEndpointSuccess");
      },
      error: (error) => {
        this.loading = false;
        this.notifService.addErrorNotif("Endpoints.EditEndpointError", error);
      }
    })
  }

  activateSipAccount(hasSipAccount: boolean): void {
    if (hasSipAccount) {
      if (this.currentConnectedUser.isGranted(this.currentTenant.id, "MEETING_ROOMS")) {
        this.addingSipAccount = true;
        this.newSipAccount = new SipAccount({});
      } else {
        this.addSipAccount();
      }
    } else {
      this.deleteSipAccount();
    }
  }

  addSipAccount(): void {
    this.loading = true;
    this.endpointsService.addSipAccount(this.currentMeetingRoom.id).subscribe({
      next: (result) => {
        this.newSipAccountAdd(result);
        this.addingSipAccount = false;
        this.newSipAccount = new SipAccount({});
        this.loading = false;
        this.notifService.addSuccessNotif("Endpoints.SipAccountCreatedSuccess");
        this.getMeetingRoom();
      },
      error: (error) => {
        this.hasSipAccount = false;
        this.loading = false;
        this.notifService.addErrorNotif("Endpoints.SipAccountCreatedError", error);
      }
    })
  }

  deleteSipAccount(): void {
    if (this.currentMeetingRoom.endpoint.sipAccount) {
      this.loading = true;
      this.endpointsService.deleteSipAccount(this.currentMeetingRoom.id).subscribe({
        next: (result) => {
          this.loading = false;
          this.addingSipAccount = false;
          this.notifService.addSuccessNotif("Endpoints.SipAccountDeleteSuccess");
          this.getMeetingRoom();
        },
        error: (error) => {
          this.hasSipAccount = true;
          this.loading = false;
          this.notifService.addErrorNotif("Endpoints.SipAccountDeleteError", error);
        }
      })
    } else {
      this.hasSipAccount = false;
      this.addingSipAccount = false;
    }
  }

  resetSipAccountPassword(meetingRoom: MeetingRoom): void {
    this.loading = true;
    this.endpointsService.resetSipAccountPassword(meetingRoom.id).subscribe({
      next: (result) => {
        this.resetedPassword = result;
        $("#modalSipAccountResetPassword").modal("show");
        this.loading = false;
        this.notifService.addSuccessNotif("Endpoints.PasswordResetSuccess");
      },
      error: (error) => {
        this.loading = false;
        this.notifService.addErrorNotif("Endpoints.PasswordResetError", error);
      }
    })
  }

  onAddSipAccount(event: SipAccount): void {
    this.addingSipAccount = false;
    this.newSipAccountAdd(event);
    this.getMeetingRoom();
  }

  newSipAccountAdd(newSipAccountAdd: SipAccount): void {
    this.justAddSipAccount = newSipAccountAdd;
    $("#modalSipAccountResetPassword").appendTo("body").modal('show');
  }

  canAddEndpoint(): boolean {
    return (this.currentTenant && (this.currentTenant.hasAccess('GATEWAY') || this.currentTenant.hasAccess('ONE_CODEC') || this.currentTenant.hasAccess('TEAMS_GATEWAY') || this.currentTenant.hasAccess('HANGOUT_GATEWAY')) && this.currentConnectedUser && this.currentConnectedUser.canEdit(this.currentTenant.id));
  }

  hidePasswordText(pass: string | null): string | null {
    if (pass) {
      return pass.replace(/./g, "*");
    } else {
      return pass;
    }
  }

  showModalDeleteEndpoint(): void {
    $('#modalOnDeleteEndpoint').appendTo("body").modal('show');
  }

}
