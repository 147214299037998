import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Continent } from '../continent/continent.model';
import { ContinentsService } from '../continent/continents.service';
import { Country } from '../country/country.model';
import { CountryService } from '../country/country.service';
import { NotifService } from '../services/notifs/notif.service';
import { SharedService } from '../services/shared/shared.service';
import { ConnectedUser } from '../users/user-connected/user.connected.model';
import { Geozone } from './geozone.model';
import { GeozonesService } from './geozones.service';

@Component({
  selector: 'app-geozones',
  templateUrl: './geozones.component.html',
  styleUrls: ['./geozones.component.scss'],
  providers: [GeozonesService, ContinentsService, CountryService]
})
export class GeozonesComponent implements OnInit, OnDestroy {
  continents: Continent[];
  onInitSubscriptions: Subscription[] = [];
  currentConnectedUser: ConnectedUser;
  currentContinent: Continent;
  geozones: Geozone[];
  currentGeozone: Geozone;
  newGeozone: Geozone = new Geozone({});
  countries: Country[];
  currentCountry: Country;
  constructor(private countriesService: CountryService, private notifService: NotifService, private geozonesService: GeozonesService, private continentsService: ContinentsService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe((user: ConnectedUser) => {
      this.currentConnectedUser = user;
    }));
    this.getContinents();
    this.getCountries();
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }

  getContinents(): void {
    this.continentsService.getContinents().subscribe({
      next: (continents: Continent[]) => {
        this.continents = continents;
      },
      error: (error) => {
        console.log("Error while getting continents");
      }
    });
  }

  getGeozones(): void {
    this.geozonesService.getGeozones(this.currentContinent.id).subscribe({
      next: (geozones: Geozone[]) => {
        this.geozones = geozones;
      },
      error: (error) => {
        console.log("Error while getting geozones");
      }
    });
  }

  getCountries(): void {
    this.countriesService.getCountries().subscribe({
      next: (countries: Country[]) => {
        this.countries = countries;
      },
      error: (error) => {
        console.log("Error while getting countries");
      }
    });
  }

  confirmDeleteGeozone(): void {
    this.geozonesService.delete(this.currentGeozone).subscribe({
      next: (result) => {
        this.notifService.addSuccessNotif("GeozonesComponent.SuccessDeleteGeozone");
        this.getGeozones();
      },
      error: (error) => {
        this.notifService.addErrorNotif("GeozonesComponent.ErrorWhileDeleteGeozone", error);
      }
    });
  }

  addNewGeozone(continent: Continent): void {
    this.currentContinent = continent;
    this.newGeozone = new Geozone({});
  }

  confirmAddGeozone(addGeozone: NgForm): void {
    if (addGeozone.valid) {
      this.newGeozone.code = addGeozone.value.code;
      this.newGeozone.phoneNumber = addGeozone.value.phoneNumber;
      this.newGeozone.translations['en-US'] = addGeozone.value.en;
      this.newGeozone.translations['fr-FR'] = addGeozone.value.fr;
      this.geozonesService.create(this.currentContinent.id, this.newGeozone).subscribe({
        next: (result) => {
          this.notifService.addSuccessNotif('GeozonesComponent.SuccessAddGeozone');
          this.getGeozones();
          $("#modalOnAddGeozone").modal("hide");
        },
        error: (error) => {
          this.notifService.addErrorNotif('GeozonesComponent.ErrorAddGeozone', error);
        }
      })
    }
  }

  confirmEditGeozone(editGeozone: NgForm): void {
    if (editGeozone.valid) {
      this.currentGeozone.code = editGeozone.value.code;
      this.currentGeozone.phoneNumber = editGeozone.value.phoneNumber;
      this.currentGeozone.translations['en-US'] = editGeozone.value.en;
      this.currentGeozone.translations['fr-FR'] = editGeozone.value.fr;
      this.geozonesService.save(this.currentGeozone).subscribe({
        next: (result) => {
          this.notifService.addSuccessNotif('GeozonesComponent.SuccessEditGeozone');
          this.getGeozones();
          $("#modalOnEditGeozone").modal("hide");
        },
        error: (error) => {
          this.notifService.addErrorNotif('GeozonesComponent.ErrorEditGeozone', error);
        }
      })
    }
  }

  confirmEditContinent(editContinent: NgForm): void {
    if (editContinent.valid) {
      this.currentContinent.code = editContinent.value.code;
      this.currentContinent.translations['en-US'] = editContinent.value.en;
      this.currentContinent.translations['fr-FR'] = editContinent.value.fr;
      this.continentsService.save(this.currentContinent).subscribe({
        next: (result) => {
          this.notifService.addSuccessNotif('GeozonesComponent.SuccessEditContinent');
          this.getContinents();
          $("#modalOnEditContinent").modal("hide");
        },
        error: (error) => {
          this.notifService.addErrorNotif('GeozonesComponent.ErrorEditContinent', error);
        }
      })
    }
  }

  confirmEditCountry(editCountry: NgForm): void {
    if (editCountry.valid) {
      this.currentCountry.translations['en-US'] = editCountry.value.en;
      this.currentCountry.translations['fr-FR'] = editCountry.value.fr;
      this.countriesService.save(this.currentCountry).subscribe({
        next: result => {
          this.notifService.addSuccessNotif('GeozonesComponent.SuccessEditCountry');
          this.getCountries();
          $("#modalOnEditCountry").modal("hide");
        },
        error: (error) => {
          this.notifService.addErrorNotif('GeozonesComponent.ErrorEditCountry', error);
        }
      })
    }
  }

}
