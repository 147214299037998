import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';

@Component({
  selector: 'app-admin-card',
  templateUrl: './admin-card.component.html',
  styleUrls: ['./admin-card.component.scss'],
})
export class AdminCardComponent implements OnDestroy, OnInit {
  @Input() scrollable: boolean;
  connectedUser: ConnectedUser;
  onInitSubscriptions: Subscription[] = [];

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(
      this.sharedService.currentConnectedUser.subscribe((user: ConnectedUser) => {
        if (user) {
          this.connectedUser = user;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
