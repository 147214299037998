<div class="row align-items-center p-3">
	<div class="col-auto">
        <h3 class="m-0">
            <span class="is-bold">{{"OfferManagementComponent.Title" | translate}}</span>
        </h3>
	</div>
</div>
<app-admin-card>
    <ng-container *ngIf="connectedUser$ | async as connectedUser">
        <div *ngIf="connectedUser">
            {{"OfferManagementComponent.Help" | translate}}
            <ul>
                <li>{{"OfferManagementComponent.Less30" | translate}} : <span class="expires-at-30-txt is-regular">{{nowDate
                        |
                        date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}</span>
                </li>
                <li>{{"OfferManagementComponent.Less15" | translate}} : <span class="expires-at-15-txt is-regular">{{nowDate
                        |
                        date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}</span>
                </li>
                <li>
                    {{"OfferManagementComponent.Expired" | translate}} : <span class="is-expired-txt is-bold"><i>{{"expired" |
                            translate}}</i></span>
                </li>
                <li>{{"OfferManagementComponent.Disabled" | translate}} : <span class="line-txt">{{nowDate |
                        date:kloodDateFormat.offerPipe[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}</span>
                </li>
            </ul>
            <app-expired-offer-list></app-expired-offer-list>
        </div>
    </ng-container>
</app-admin-card>
