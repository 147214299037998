import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { Environment } from './environment.model';
import { PaginationInfo } from '../commons/mat-table-custom/mat-table-custom.model';
import { CustomHttpParamEncoderService } from '../services/http-encoder/custom-http-param-encoder.service';
import { RequestFilterBuilder } from '../services/request-filter-builder/request-filter-builder';
import { suggestPattern } from '../operators/mongo-operators/mongo-operators';

@Injectable()
export class EnvironmentsService {
  showActive = true;

  constructor(private http: HttpClient, private optionService: DataApiOptionService) { }

  buildParamsUrl(paginationInfo: PaginationInfo, moreParams?: string[]): HttpParams {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() });
    if (moreParams) {
      for (let p in moreParams) {
        let customParam = <string>paginationInfo[<keyof PaginationInfo>moreParams[p]];
        params = params.set(moreParams[p], customParam);
      }
    }
    const filter = new RequestFilterBuilder();
    if (paginationInfo.suggest) {
      filter.set("suggests", suggestPattern(paginationInfo.suggest));
    }
    const q = filter.serialize();
    return (params = params.set("q", q));
  }

  getHead(paginationInfo: PaginationInfo): Observable<number> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        const params = this.buildParamsUrl(paginationInfo);
        return this.http.head(apiUri + "/environments", {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: "response",
          withCredentials: true,
        }).pipe(map((result) => parseInt(<string>result.headers.get("Count"))));
      })
    );
  }

  getData(paginationInfo: PaginationInfo): Observable<Environment[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        const params = this.buildParamsUrl(paginationInfo, ["sort", "order", "skip", "limit"]);
        return this.http.get<Environment[]>(apiUri + "/environments", {
          params: params,
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        }).pipe(map((result) => {
          const environments = [];
          for (const res of result) {
            const environment = new Environment(res);
            environments.push(environment);
          }
          return environments;
        }));
      })
    );
  }

  getEnvironments(): Observable<Environment[]> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<Environment[]>(apiUri + '/environments', {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        });
      })
    );
  }

  getEnvironment(id: string): Observable<Environment> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<Environment>(apiUri + '/environments/' + id, {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        });
      })
    );
  }

  addEnvironment(environment: Environment): Observable<Environment> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.post<Environment>(
          apiUri + '/environments',
          environment,
          {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          }
        );
      })
    );
  }

  saveEnvironment(environment: Environment): Observable<Environment> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.put<Environment>(
          apiUri + '/environments/' + environment.id,
          environment,
          {
            headers: this.optionService.getHeaders(),
            withCredentials: true,
          }
        );
      })
    );
  }

  deleteEnvironment(environment: Environment): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.delete(apiUri + '/environments/' + environment.id, {
          headers: this.optionService.getHeaders(),
          withCredentials: true,
        });
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
