<div class="row align-items-center p-3">
    <div class="col-auto">
        <h3 class="m-0">
            <span class="is-bold">{{"StatisticsComponent.Title" | translate}}</span>
        </h3>
    </div>
</div>
<app-admin-card>
    <div class="date-picker" id="date-picker">
        <app-calendar-picker-double [from]="from" [labelFrom]="'PlannedMeetings.StartDate'" [labelTo]="'PlannedMeetings.EndDate'" [to]="to"></app-calendar-picker-double>
    </div>
    <div class="grid" id="stats-grid">
        <div [ngClass]="{'fullscreen' : fullscreen === 'cell1'}" class="cells cell1">
            <fa-icon class="float-start" (click)="fullscreen = 'cell1'" *ngIf="fullscreen !== 'cell1'" [icon]="['fas', 'expand-arrows-alt']"></fa-icon>
            <fa-icon class="float-start" (click)="fullscreen = ''" *ngIf="fullscreen === 'cell1'" [icon]="['fas', 'compress-arrows-alt']"></fa-icon>
            <h3 class="title is-bold">{{"StatisticsComponent.Graphs.TopUsers" | translate}}</h3>
            <div class="tabs">
                <span (click)="topUser=!topUser; loadTopUser()" [ngClass]="(topUser) ? 'is-selected' : ''">{{"StatisticsComponent.Graphs.NbMeetings" | translate}}</span>
                |
                <span (click)="topUser=!topUser; loadTopUser()" [ngClass]="(!topUser) ? 'is-selected' : ''">{{"StatisticsComponent.Graphs.MeetingsDuration" | translate}}</span>
            </div>
            <div [hidden]="topUserDataNull || topUserError" class="chart-container">
                <canvas class="charts" id="top-user-chart"></canvas>
            </div>
            <div *ngIf="topUserDataNull && !topUserError" class="chart-container">
                <div class="no-data">{{"StatisticsComponent.Graphs.NoData" | translate}}</div>
            </div>
            <div *ngIf="topUserError && !topUserDataNull" class="chart-container">
                <div class="no-data">{{"StatisticsComponent.Graphs.Error" | translate}}</div>
            </div>
        </div>
        <div [ngClass]="{'fullscreen' : fullscreen === 'cell2'}" class="cells cell2">
            <fa-icon class="float-start" (click)="fullscreen = 'cell2'" *ngIf="fullscreen !== 'cell2'" [icon]="['fas', 'expand-arrows-alt']"></fa-icon>
            <fa-icon class="float-start" (click)="fullscreen = ''" *ngIf="fullscreen === 'cell2'" [icon]="['fas', 'compress-arrows-alt']"></fa-icon>
            <h3 class="title is-bold">{{"StatisticsComponent.Graphs.UsageDistribution" | translate}}</h3>

            <div [hidden]="distributionDataNull || distributionError" class="chart-container">
                <canvas class="charts" id="distribution-uses-chart" width="200" height="200"></canvas>
            </div>
            <div *ngIf="distributionDataNull && !distributionError" class="chart-container">
                <div class="no-data">{{"StatisticsComponent.Graphs.NoData" | translate}}</div>
            </div>
            <div *ngIf="distributionError && !distributionDataNull" class="chart-container">
                <div class="no-data">{{"StatisticsComponent.Graphs.Error" | translate}}</div>
            </div>
        </div>
        <div [ngClass]="{'fullscreen' : fullscreen === 'cell3'}" class="cells cell3">
            <fa-icon class="float-start" (click)="fullscreen = 'cell3'" *ngIf="fullscreen !== 'cell3'" [icon]="['fas', 'expand-arrows-alt']"></fa-icon>
            <fa-icon class="float-start" (click)="fullscreen = ''" *ngIf="fullscreen === 'cell3'" [icon]="['fas', 'compress-arrows-alt']"></fa-icon>
            <h3 class="title is-bold">{{"StatisticsComponent.Graphs.ConcurrentMeetings" | translate}}</h3>
            <div class="tabs">
                <span (click)="usageRate=!usageRate; loadUseRate()" [ngClass]="(usageRate) ? 'is-selected' : ''">{{"StatisticsComponent.Graphs.OnTimeRange" | translate}}</span>
                |
                <span (click)="usageRate=!usageRate; loadUseRate()" [ngClass]="(!usageRate) ? 'is-selected' : ''">{{"StatisticsComponent.Graphs.DayByDay" | translate}}</span>
            </div>
            <div [hidden]="useRateDataNull || useRateError" class="chart-container">
                <canvas class="charts" id="use-rate-chart"></canvas>
            </div>
            <div *ngIf="useRateDataNull && !useRateError" class="chart-container">
                <div class="no-data">{{"StatisticsComponent.Graphs.NoData" | translate}}</div>
            </div>
            <div *ngIf="useRateError && !useRateDataNull" class="chart-container">
                <div class="no-data">{{"StatisticsComponent.Graphs.Error" | translate}}</div>
            </div>
        </div>
        <div [ngClass]="{'fullscreen' : fullscreen === 'cell4'}" class="cells cell4">
            <fa-icon class="float-start" (click)="fullscreen = 'cell4'" *ngIf="fullscreen !== 'cell4'" [icon]="['fas', 'expand-arrows-alt']"></fa-icon>
            <fa-icon class="float-start" (click)="fullscreen = ''" *ngIf="fullscreen === 'cell4'" [icon]="['fas', 'compress-arrows-alt']"></fa-icon>
            <h3 class="title is-bold">{{"StatisticsComponent.Graphs.Meetings" | translate}}</h3>

            <div [hidden]="meetingDataNull || meetingError" class="chart-container">
                <canvas class="charts" id="meetings-chart"></canvas>
            </div>
            <div *ngIf="meetingDataNull && !meetingError" class="chart-container">
                <div class="no-data">{{"StatisticsComponent.Graphs.NoData" | translate}}</div>
            </div>
            <div *ngIf="meetingError && !meetingDataNull" class="chart-container">
                <div class="no-data">{{"StatisticsComponent.Graphs.Error" | translate}}</div>
            </div>
        </div>
    </div>
</app-admin-card>
