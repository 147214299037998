import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { User } from '../user.model';

@Injectable()
export class UserEditPasswordService {
  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService
  ) {}

  getUser(tenant: Tenant, userId: string): Observable<User> {
    return this.optionService.getApiUrl(tenant.id).pipe(
      switchMap((apiUri) => {
        return this.http.get<User>(apiUri + '/users/' + userId, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new User(result))
    );
  }

  save(user: User): Observable<User> {
    return this.optionService.getApiUrl(user.tenantId).pipe(
      switchMap((apiUri) => {
        return this.http.put<User>(apiUri + '/users/' + user.id, user, {
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => new User(result))
    );
  }
}
