import { Pipe, PipeTransform } from '@angular/core';
import { escapeCharacters } from 'src/app/services/escape-characters/escape-characters';

@Pipe({
  name: 'boldTextPipe',
})
export class BoldTextPipe implements PipeTransform {
  constructor() { }

  transform(str: string, regex: string): string {
    if (typeof str === 'string') {
      return str.replace(new RegExp(`(${escapeCharacters(regex)})`, 'gi'), '<b>$1</b>');
    } else {
      return '';
    }
  }
}
