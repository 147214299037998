<app-admin-bloc (requestAction)="onRequestAction($event)" [blocControls]="controls" [blocTitle]="'Details'" *ngIf="currentMeetingRoom$ | async as currentMeetingRoom; else loading" (requestAction)="onRequestAction($event)">
  <mat-tab-group dynamicHeight animationDuration="500ms" [selectedIndex]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
    <mat-tab label="{{'DetailsMeetingRoom.General.General' | translate}}">
      <div class="container-fluid">
        <div class="row">
          <div class="col-auto is-bold pe-0">
            {{"DetailsMeetingRoom.General.Name" | translate}} :
          </div>
          <div class="col">
            {{currentMeetingRoom.name}}
          </div>
        </div>
        <div class="row">
          <div class="col-auto is-bold pe-0">
            {{"DetailsMeetingRoom.General.Description" | translate}} :
          </div>
          <div class="col">
            {{currentMeetingRoom.description}}
          </div>
        </div>
        <div class="row">
          <div class="col-auto is-bold pe-0">
            {{"DetailsMeetingRoom.General.Email" | translate}} :
          </div>
          <div class="col">
            {{currentMeetingRoom.email}}
          </div>
        </div>
        <div class="row">
          <div class="col-auto is-bold pe-0">
            {{"DetailsMeetingRoom.General.LastUpdate" | translate}} :
          </div>
          <div class="col" *ngIf="currentConnectedUser">
            {{currentMeetingRoom.updatedAt | date:kloodDateFormat.pipe[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}
          </div>
        </div>
        <div class="row">
          <div class="col-auto is-bold pe-0">
            {{"DetailsMeetingRoom.General.CreationDate" | translate}} :
          </div>
          <div class="col" *ngIf="currentConnectedUser">
            {{currentMeetingRoom.createdAt | date:kloodDateFormat.pipe[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'DetailsMeetingRoom.Endpoints.Endpoint' | translate}}" >
      <div class="container-fluid">
        <app-endpoints *ngIf="canShowEndpointTab()" [currentTenant]="currentTenant" [currentConnectedUser]="currentConnectedUser" [currentMeetingRoom]="currentMeetingRoom"></app-endpoints>
      </div>
    </mat-tab>
    <mat-tab label="{{'DetailsMeetingRoom.Status.Status' | translate}}">
      <div class="container-fluid">
        <app-endpoint-status [currentMeetingRoom]="currentMeetingRoom"></app-endpoint-status>
      </div>
    </mat-tab>
  </mat-tab-group>
</app-admin-bloc>
<ng-template #loading>
  <div class="row h-100 d-flex justify-content-center align-items-center">
    <div class="col-auto my-auto">
      <app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
    </div>
  </div>
</ng-template>
