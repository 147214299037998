import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent {
  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() textLabel = '';
  @Input() typeInput = '';
  @Input() nameInput = '';
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() minNumber = 0;
  @Input() maxNumber: number;
  @Input() light = false;
  @Input() myInputModel: any;
  @Output() modelChangeEvent = new EventEmitter<any>();

  onChange(): void {
    this.modelChangeEvent.emit(this.myInputModel);
  }
}
