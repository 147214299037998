import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appValidIp]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidIpValidatorDirective,
      multi: true,
    },
  ],
})
export class ValidIpValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    const regex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const forbidden = !regex.test(control.value);
    return forbidden ? { appValidIp: { value: control.value } } : null;
  }
}

export function ipValidator(ip: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const forbidden = !regex.test(control.value);
    return forbidden ? { appValidIp: { value: control.value } } : null;
  };
}
