<app-admin-infos-bloc [blocTitle]="'DetailsHistoryMeetings.MeetingDetails'" [blocControls]="[{ icon: 'times', controleType: 'ACTION', isSwitch: false, tooltip: 'Close', action: 'Back' }]" (requestAction)="onRequestAction($event)">
    <div class="row">
        <div class="col-6">
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.Organizer" | translate}} :</div>
                <div class="col">{{selectedHistoryMeeting.organizer?.displayName}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.StartedAt" | translate}} :</div>
                <div class="col">{{selectedHistoryMeeting.startedAt | date:kloodDateFormat.pipe[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.TerminatedAt" | translate}} :</div>
                <div class="col">{{selectedHistoryMeeting.terminatedAt | date:kloodDateFormat.pipe[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.OfferType" | translate}} :</div>
                <div class="col">{{selectedHistoryMeeting.offer}}</div>
            </div>
        </div>
        <div class="col-6">
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.Alias" | translate}} :</div>
                <div class="col">{{selectedHistoryMeeting.alias}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.MessagesNumber" | translate}} :</div>
                <div class="col">{{selectedHistoryMeeting.instantMessageCount}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.ParticipantsNumber" | translate}} :</div>
                <div class="col">{{selectedHistoryMeeting.participantCount}}</div>
            </div>
            <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.ConcurrentParticipants" | translate}} :</div>
                <div class="col">{{selectedHistoryMeeting.maxConcurrentParticipants}}</div>
            </div>
        </div>
    </div>
</app-admin-infos-bloc>
<app-admin-infos-bloc [blocTitle]="'DetailsHistoryMeetings.Participants'" [blocControls]="controls" (requestAction)="exportParticipantsHistoricMeetings()">
    <app-participant-details-history-meetings *ngIf="selectedHistoryMeeting" [currentConnectedUser]="currentConnectedUser" [selectedHistoryMeeting]="selectedHistoryMeeting"></app-participant-details-history-meetings>
</app-admin-infos-bloc>
<app-admin-infos-bloc [blocTitle]="'DetailsHistoryMeetings.Meeting'">
    <div *ngIf="selectedHistoryMeeting.meeting">
        <div class="row">
            <div class="col-6">
                <div class="row align-items-center">
                    <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.Title" | translate}} :</div>
                    <div class="col">{{selectedHistoryMeeting.meeting.title}}</div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.Attendees" | translate}} :</div>
                    <div class="col">
                        <span *ngFor="let a of selectedHistoryMeeting.meeting.attendees"> {{a.email}} </span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row align-items-center">
                    <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.Beginning" | translate}} :</div>
                    <div class="col">{{selectedHistoryMeeting.meeting.beginDate | date:kloodDateFormat.pipe[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</div>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto is-bold pe-0">{{"DetailsHistoryMeetings.End" | translate}} :</div>
                    <div class="col">{{selectedHistoryMeeting.meeting.endDate | date:kloodDateFormat.pipe[currentConnectedUser.lang]:undefined:kloodDateFormat.locale[currentConnectedUser.lang]}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!selectedHistoryMeeting.meeting">
        <div class="col-12">{{"DetailsHistoryMeetings.NoMeetingInformation" | translate}}</div>
    </div>
</app-admin-infos-bloc>

