import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomHttpParamEncoderService } from 'src/app/services/http-encoder/custom-http-param-encoder.service';
import Debug from 'debug';
import { AppConfigService } from '../services/config/app.config.service';
const debug = Debug('virtuvisio-administration:login-service');

@Injectable()
export class LoginService {
  constructor(private http: HttpClient, private optionService: DataApiOptionService, private configUi: AppConfigService) { }

  logout(): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get(apiUri + '/logout', {
          headers: this.optionService.getHeaders(),
          observe: 'response',
          withCredentials: true,
        });
      }),
      map((result) => result.body)
    );
  }

  redirectLogin(redirectUri?: string): void {
    debug('redirectUri : ', redirectUri);
    let kloodWebappUrl: string;
    let qParams: string;
    // const newWebappBaseUrl = window.location.origin.replace('admin.', 'app.');
    // console.log('Navigating to : ' + kloodWebappUrl);
    // kloodWebappUrl = newWebappBaseUrl + '/login';
    kloodWebappUrl = this.configUi.settings.REDIRECT_LOGIN_URI;
    if (redirectUri) {
      qParams = '?afterLogin=' + redirectUri;
      kloodWebappUrl = kloodWebappUrl + qParams;
      console.log("REDIRECTING TO : " + kloodWebappUrl);
      window.location.href = kloodWebappUrl;
    } else {
      qParams = '?afterLogin=' + window.location.origin;
      kloodWebappUrl = kloodWebappUrl + qParams;
      console.log("REDIRECTING TO : " + kloodWebappUrl);
      window.location.href = kloodWebappUrl;
    }
  }

  getAvailableUser(email: string): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(switchMap((apiUri) => {
      const params = new HttpParams({ encoder: new CustomHttpParamEncoderService() }).set("email", email);
      return this.http.get(apiUri + "/login/availables", {
        params: params,
        headers: this.optionService.getHeaders(),
        observe: "response",
      }).pipe(map((result) => result.body));
    }))
  }
}
