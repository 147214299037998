<!-- If user is on home page route (temporary before remove home route) -->
<div *ngIf="connectedUser" class="management-nav">
    <div class="nav-buttons text-center">
        <a [routerLink]="['/administration']" routerLinkActive="active" class="nav-links pointer" *ngIf="connectedUser.isGranted(connectedUser.tenantId)">
            <p><fa-icon [icon]="['fas', 'users-cog']" class="me-1"></fa-icon>{{"ManagementNavbarComponent.Administration"|translate}}</p>
        </a>
    </div>
    <div class="nav-buttons text-center">
        <a [routerLink]="['/management/trials']" routerLinkActive="active" class="nav-links pointer"
            *ngIf="connectedUser.hasRole('SUPER_ADMIN') || connectedUser.hasRole('MARKETING') || (connectedUser.hasRole('ADMIN') && connectedUser.isGranted(connectedUser.tenantId, 'TRIALS'))"> 
            <p><fa-icon [icon]="['fas', 'clipboard-list']" class="me-1"></fa-icon>{{"ManagementNavbarComponent.Trials"|translate}}</p>
        </a>
    </div>
    <div class="nav-buttons text-center">
        <a [routerLink]="['/management/offers']" routerLinkActive="active" class="nav-links pointer"
            *ngIf="connectedUser.hasRole('SUPER_ADMIN')">
            <p><fa-icon [icon]="['fas', 'tags']" class="me-1"></fa-icon>{{"ManagementNavbarComponent.Offers"|translate}}</p>
        </a>
    </div>
    <div class="nav-buttons text-center">
        <a [routerLink]="['/management/oauth-clients']" routerLinkActive="active" class="nav-links pointer"
            *ngIf="connectedUser.hasRole('SUPER_ADMIN')">
            <p><fa-icon [icon]="['fas', 'user-lock']" class="me-1"></fa-icon>{{"ManagementNavbarComponent.OauthClients"|translate}}</p>
        </a>
    </div>
    <div class="nav-buttons text-center">
        <a [routerLink]="['/management/environments']" routerLinkActive="active" class="nav-links pointer"
            *ngIf="connectedUser.hasRole('SUPER_ADMIN')">
            <p><fa-icon [icon]="['fab', 'buffer']" class="me-1"></fa-icon>{{"ManagementNavbarComponent.Environments"|translate}}</p>
        </a>
    </div>
    <div class="nav-buttons text-center">
        <a [routerLink]="['/management/geozones']" routerLinkActive="active" class="nav-links pointer"
            *ngIf="connectedUser.hasRole('SUPER_ADMIN')">
            <p><fa-icon [icon]="['fas', 'globe']" class="me-1"></fa-icon>{{"ManagementNavbarComponent.ContinentsGeozonesCountries"|translate}}</p>
        </a>
    </div>
</div>