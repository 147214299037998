import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
	selector: '[appMatchingName]',
	providers: [{ provide: NG_VALIDATORS, useExisting: MatchingNameValidatorDirective, multi: true }],
})
export class MatchingNameValidatorDirective implements Validator {
	@Input() appMatchingName!: string;
	validate(control: AbstractControl): { [key: string]: any } | null {
		return this.appMatchingName ? matchingNameValidator(new RegExp(this.appMatchingName, 'i'))(control) : null;
	}
}

export function matchingNameValidator(nameRe: RegExp): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const matching = nameRe.test(control.value?.trim());
		return !matching ? { appMatchingName: { value: control.value } } : null;
	};
}
