<div class="date-picker" id="date-picker">
  <app-calendar-picker-double [from]="from" [labelFrom]="'StatsUsageComponent.StartDate'" [labelTo]="'StatsUsageComponent.EndDate'" [to]="to"></app-calendar-picker-double>
</div>
<div class="chart-usage">
  <div [hidden]="usageNull || usageError" class="chart-container">
    <canvas class="charts" id="stats-usage-chart" ></canvas>
  </div>
  <div *ngIf="usageNull && !usageError" class="chart-container">
    <div class="no-data">{{"StatsUsageComponent.NoData" | translate}}</div>
  </div>
  <div *ngIf="usageError && !usageNull" class="chart-container">
    <div class="no-data">{{"StatsUsageComponent.Error" | translate}}</div>
  </div>
</div>