<div class="icheck-custom icheck" [ngClass]="{inverted: inverted}">
    <input
        #inputModel="ngModel"
        name="{{nameInput}}"
        type="checkbox"
        id="{{nameInput}}"
        placeholder="{{textLabel | translate}}"
        [(ngModel)]="myInputModel"
        (change)="changeCheckbox($event)"
        [checked]="checked"
        [disabled]="disabled"
    />
    <label for="{{nameInput}}"> {{textLabel|translate}} </label>
</div>
