import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first, Observable, Subscription } from 'rxjs';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { QueryParamsManager } from 'src/app/services/query-params-manager/query-params-manager';
import { ActionEvent, DisplayColumn, PaginationInfo, TableAction } from './mat-table-custom.model';

export interface DataService {
  getHead(paginationInfo: PaginationInfo): Observable<number>;
  getData(paginationInfo: PaginationInfo): Observable<any>;
}

@Component({
  selector: 'app-mat-table-custom',
  templateUrl: './mat-table-custom.component.html',
  styleUrls: ['./mat-table-custom.component.scss'],
  providers: [QueryParamsManager]
})
export class MatTableCustomComponent implements OnInit, OnDestroy, OnChanges {

  @Input() displayedColumns: DisplayColumn[] = [];
  @Input() dataService: DataService;
  @Input() defaultSort = "";
  @Input() componentName = "";
  @Input() dataSource: MatTableDataSource<any>;
  @Input() loading = false;
  @Input() hoverable = true;
  @Input() selectedRowItem: string | undefined | null;
  @Input() selectedRowId: string | undefined | null;
  @Output() dataError = new EventEmitter<HttpErrorResponse>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() actionClick = new EventEmitter<ActionEvent>();
  @Output() paginationChange = new EventEmitter<PaginationInfo>();
  paginationInfo: PaginationInfo | any;
  queryParamSub: Subscription;
  paginatorReady = false;
  onInitSubscriptions: Subscription[] = [];

  constructor(private translate: TranslateService, private matPaginatorIntl: MatPaginatorIntl, private route: ActivatedRoute, private queryParamsManager: QueryParamsManager, private datesManager: DatesManager) { }

  ngOnInit(): void {
    this.paginationInfo = new PaginationInfo();
    this.paginationInfo.sort = this.defaultSort;
    this.paginationInfo.displayedColumns = this.displayedColumns.map((col) => col.name);
    this.initLabelMatPaginator();
    this.onInitSubscriptions.push(this.route.queryParams.subscribe((params) => {
      for (let q in params) {
        if (q in this.paginationInfo) {
          if (q === "from" || q === "to") {
            if (this.datesManager.isValidDate(params[q])) {
              this.paginationInfo[q] = new Date(params[q]);
            }
          } else {
            this.paginationInfo[<keyof PaginationInfo>q] = params[q];
          }
        }
      }
      if (this.dataService) {
        this.getHead();
      }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
      if (changes.loading.currentValue === true && this.dataService) {
        this.getHead();
      }
    }
    if (this.dataSource && changes.dataSource?.currentValue) {
      if (changes.dataSource?.currentValue !== changes.dataSource?.previousValue) {
        if (changes.dataSource?.currentValue.data && changes.dataSource?.currentValue.data.length > 0) {
          if (this.paginationInfo) {
            this.paginationInfo.listLength = changes.dataSource?.currentValue.data.length;
          }
          this.onInitSubscriptions.push(this.route.queryParams.subscribe(async (params) => {
            if (params.suggest) {
              await this.applySuggest(params.suggest);
            }
          }));
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }

  getHead(): void {
    this.loading = true;
    this.onInitSubscriptions.push(this.dataService.getHead(this.paginationInfo).pipe(first()).subscribe({
      next: (result: number) => {
        this.loading = false;
        this.paginationInfo.listLength = result;
        this.paginationInfo.limit = this.paginationInfo.pageSize;
        if (this.paginationInfo.limit * this.paginationInfo.pageIndex > this.paginationInfo.listLength) {
          this.paginationInfo.skip = 0;
          this.paginationInfo.pageIndex = 0;
        }
        this.getData();
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        this.dataError.emit(error);
      }
    }));
  }

  getData(): void {
    this.loading = true;
    this.onInitSubscriptions.push(this.dataService.getData(this.paginationInfo).pipe(first()).subscribe({
      next: (result: any) => {
        this.loading = false;
        this.dataSource = new MatTableDataSource(result);
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        this.dataError.emit(error);
      }
    }));
  }

  async onChangePagination(event: PageEvent): Promise<void> {
    this.paginationInfo.pageSize = this.paginationInfo.limit = event.pageSize;
    this.paginationInfo.pageIndex = event.pageIndex;
    this.paginationInfo.skip = event.pageSize * event.pageIndex;
    this.paginationChange.emit(this.paginationInfo);
    await this.updateQueryParams();
  }

  async onSuggest(suggest: string): Promise<void> {
    this.paginationInfo.suggest = suggest;
    this.paginationInfo.pageIndex = 0;
    this.paginationInfo.skip = 0;
    await this.updateQueryParams();
  }

  async onSort(event: Sort): Promise<void> {
    this.paginationInfo.sort = "date";
    this.paginationInfo.order = "";
    if (event.direction) {
      this.paginationInfo.sort = event.active;
      this.paginationInfo.order = event.direction;
    }
    await this.updateQueryParams();
  }

  async updateQueryParams(): Promise<void> {
    await this.queryParamsManager.changeQueryParams({
      pageSize: this.paginationInfo.pageSize,
      pageIndex: this.paginationInfo.pageIndex,
      skip: this.paginationInfo.skip,
      limit: this.paginationInfo.limit,
      order: this.paginationInfo.order,
      sort: this.paginationInfo.sort,
      suggest: this.paginationInfo.suggest,
    });
  }

  isActionColumn(displayedColumn: string): boolean | string[] {
    //check for action column header
    for (let column of this.displayedColumns) {
      if (displayedColumn === 'action' && column.name === displayedColumn && column.type === 'action') {
        return true;
      }
    }
    return false;
  }

  getActionName(displayedColumn: string): TableAction[] {
    for (let column of this.displayedColumns) {
      if (displayedColumn === 'action' && column.name === displayedColumn && column.type === 'action') {
        return <TableAction[]>column.actions;
      }
    }
    return [];
  }

  findPropertyValue(data: any, displayedColumn: string): string {
    const splitColumnName = displayedColumn.split(".");
    if (splitColumnName.length > 1) {
      for (const s in splitColumnName) {
        if (data) {
          if (data[splitColumnName[s]] !== undefined) {
            data = data[splitColumnName[s]];
          } else {
            data = "";
          }
        }
      }
      return this.formatRowData(data, displayedColumn);
    }
    return this.formatRowData(data[displayedColumn], displayedColumn);
  }

  getNameColumn(displayedColumn: string): string {
    return this.componentName + "." + displayedColumn;
  }

  formatRowData(value: any, name: string): string {
    for (const c in this.displayedColumns) {
      if (this.displayedColumns[c].name === name && this.displayedColumns[c].type === "date") {
        return this.datesManager.toDatePipeLocale(value);
      }
      if (this.displayedColumns[c].name === name && this.displayedColumns[c].type === "boolean") {
        if (value) {
          return this.translate.instant(this.componentName + '.yes');
        }
        return '';
      }
    }
    return value;
  }

  async applySuggest(suggest: any): Promise<void> {
    this.dataSource.filter = suggest.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.paginationInfo.suggest = suggest;
    await this.updateQueryParams();
  }

  initLabelMatPaginator(): void {
    // await setTimeout(async () => {
    this.matPaginatorIntl.firstPageLabel = this.translate.instant("MatPaginator.FirstPageLabel");
    this.matPaginatorIntl.itemsPerPageLabel = this.translate.instant("MatPaginator.ItemsPerPageLabel");
    this.matPaginatorIntl.lastPageLabel = this.translate.instant("MatPaginator.LastPageLabel");
    this.matPaginatorIntl.nextPageLabel = this.translate.instant("MatPaginator.NextPageLabel");
    this.matPaginatorIntl.previousPageLabel = this.translate.instant("MatPaginator.PreviousPageLabel");
    const rangeTxtLabel = this.translate.instant("MatPaginator.RangeTxtLabel");
    this.matPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 ` + rangeTxtLabel + ` ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} ` + rangeTxtLabel + ` ${length} (page n°: ${page + 1})`;
    };
    this.paginatorReady = true;
    // })
  }

  onRowClick(rowData: any): void {
    this.rowClick.emit(rowData);
  }

  onActionClick(data: any, action: TableAction): void {
    this.actionClick.emit({ action: action, data: data });
  }
}
