import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { TrialUser } from 'src/app/trials/trial.model';
import { NgForm } from '@angular/forms';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { TrialsService } from 'src/app/trials/trials.service';

@Component({
  selector: 'app-edit-trial',
  templateUrl: './edit-trial.component.html',
  styleUrls: ['./edit-trial.component.scss']
})
export class EditTrialComponent {

  @Input() currentConnectedUser: ConnectedUser;
  @Input() selectedTrial: TrialUser;
  @Output() trialEditedEvent = new EventEmitter<boolean>();
  finished = false;
  hasError = false;

  constructor(private notifService: NotifService, private datesManager: DatesManager, private trialsService: TrialsService) { }

  editTrialExpiration(editTrialForm: NgForm): void {
    const currentDate = this.datesManager.toEndDay(new Date(this.selectedTrial.trial.expires)).getTime() * 1000000;
    const futureDate = this.datesManager.toEndDay(new Date(editTrialForm.value.expirationDate)).getTime() * 1000000;
    const delay = futureDate - currentDate;
    if (editTrialForm.valid) {
      this.hasError = false;
      this.trialsService.renew(this.selectedTrial, { delay: delay }).subscribe({
        next: () => {
          this.hasError = false;
          this.finished = true;
          this.trialEditedEvent.emit(true);
          this.notifService.addSuccessNotif('TrialsComponent.TrialExpirationEdited');
        },
        error: error => {
          this.hasError = true;
          this.notifService.addErrorNotif('TrialsComponent.ErrorEditTrialExpiration', error);
        }
      })
    } else {
      this.hasError = true;
    }
  }

}
