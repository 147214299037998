import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { User } from '../user.model';

@Injectable()
export class UserAddService {
  tenantId: Observable<string> = of('');

  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService,
    private route: ActivatedRoute
    ) {
    this.tenantId = <Observable<string>>(
      this.route.parent?.paramMap.pipe(
        map((params) => params.get('tenantId'))
        )
      );
  }

  create(user: User): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
            .post(apiUri + '/users', user, {
              headers: this.optionService.getHeaders(),
              observe: 'body',
              withCredentials: true,
            })
            .pipe(map((result) => result));
          })
          );
      })
      );
  }
}
