<app-admin-infos-bloc>
  <div class="row mb-3">
    <div class="col-auto">
      <h2>{{"AddSipAccount.NewSipAccount" | translate}}</h2>
    </div>
    <div class="col">
      <button type="button" class="btn btn-app app-primary float-end" (click)="addSipAccount()">{{"AddSipAccount.RandomSipAccount" | translate}}</button>
    </div>
  </div>
  <div class="row" *ngIf="newSipAccount">
    <div class="col-12">
      <form #sipAccountForm="ngForm" id="sipAccountForm" name="sipAccountForm" class="form-control">
        <app-input-form-field class="form-control" [textLabel]="'AddSipAccount.Alias'" [typeInput]="'text'" [nameInput]="'AddSipAccount.Alias'" [form]="sipAccountForm" [myInputModel]="newSipAccount.alias" [required]="true" (modelChangeEvent)="newSipAccount.alias=$event"></app-input-form-field>
        <app-input-form-field class="form-control" [textLabel]="'AddSipAccount.Username'" [typeInput]="'text'" [nameInput]="'AddSipAccount.Username'" [form]="sipAccountForm" [myInputModel]="newSipAccount.username" [required]="true" (modelChangeEvent)="newSipAccount.username=$event"></app-input-form-field>
        <app-input-form-field class="form-control" [textLabel]="'AddSipAccount.Password'" [typeInput]="'text'" [nameInput]="'AddSipAccount.Password'" [form]="sipAccountForm" [myInputModel]="newSipAccount.password" [required]="true" (modelChangeEvent)="newSipAccount.password=$event"></app-input-form-field>
        <div class="dual-button-container">
          <button class="btn btn-app app-secondary width-100 margin-bottom-20" type="reset" (click)="deleteSipAccount()">{{"AddSipAccount.Cancel" | translate }}</button>
          <app-loading-button (submitEvent)="confirmAddSipAccount(sipAccountForm)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'AddSipAccount.Valid'" [finalText]="'AddSipAccount.Validate'"></app-loading-button>
          <div class="is-third fields-error" *ngIf="sipAccountForm.submitted && !sipAccountForm.valid">{{'AddSipAccount.SomeFields' | translate}}</div>
        </div>
      </form>
    </div>
  </div>
</app-admin-infos-bloc>