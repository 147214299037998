<div class="row align-items-center p-3">
  <div class="col-auto">
    <h3 class="m-0">
      <span class="is-bold">{{"MeetingRooms.MeetingRooms" | translate}}</span>
    </h3>
  </div>
  <div class="col">
    <button class="btn btn-app app-primary float-end" type="submit" [routerLink]="['./add']" *ngIf="currentTenant && currentTenant.authProvider !== 'microsoft' && currentTenant.authProvider">
      <fa-icon [icon]="['fas', 'plus-circle']" class="me-2"></fa-icon>{{"MeetingRooms.AddMeetingRoom" | translate }}
    </button>
    <button class="btn btn-app app-primary float-end me-2" type="submit" [routerLink]="['./import']" *ngIf="(currentTenant && currentTenant.authProvider === 'microsoft') || (currentTenant && currentTenant.authProvider === 'default' && currentTenant.ewsConfig)">
      <fa-icon [icon]="['fas', 'cloud-download-alt']" class="me-2"></fa-icon>{{"MeetingRooms.ImportMeetingRooms" | translate }}
    </button>
  </div>
</div>
<div class="row">
  <div [ngClass]="{'col-12': !selectedMeetingRoom && !deleteMeetingRoom && !isRoute('/add') && !isRoute('/edit') && !isRoute('/details'), 'col-8': isRoute('/add') || isRoute('/edit'), 'col-6': isRoute('/details')}">
    <app-admin-card>
      <app-mat-table-custom *ngIf="displayedColumns && !refreshTable && meetingRoomsService" [componentName]="'MeetingRooms'" [displayedColumns]="displayedColumns" [selectedRowItem]="'id'" [selectedRowId]="selectedMeetingRoom?.id" [dataService]="meetingRoomsService" [defaultSort]="defaultSort" (rowClick)="onRowClick($event)" (dataError)="onDataError($event)" (actionClick)="onActionClick($event)">
      </app-mat-table-custom>
    </app-admin-card>
  </div>
  <div class="col-4" [ngClass]="{'col-4': isRoute('/add') || isRoute('/edit'), 'col-6': isRoute('/details')}" *ngIf="(selectedMeetingRoom || isRoute('/add') || isRoute('/edit') || isRoute('/details')) && !deleteMeetingRoom">
    <router-outlet (deactivate)="componentMeetingRoomRemoved()"></router-outlet>
  </div>
</div>
<div class="modal fade custom-app-modal" id="modalOnDeleteMeetingRooms" role="dialog" aria-labelledby="modalOnDeleteMeetingRoomsLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content flat">
      <div class="modal-header">
        <h3 class="modal-title text-center" id="modalOnDeleteMeetingRoomsLabel">{{"MeetingRooms.Delete" |
        translate}}</h3>
      </div>
      <div class="modal-body">
        <div class="is-bold">{{"MeetingRooms.SureDeleteMeeting" | translate}}</div>
        <div class="mb-3" *ngIf="selectedMeetingRoom">{{selectedMeetingRoom.name}} {{selectedMeetingRoom.email}}</div>
        <div class="dual-button-container">
          <button class="btn btn-sm btn-app app-secondary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal">
            {{"MeetingRooms.Cancel" | translate}}
          </button>
          <button class="btn btn-sm btn-app app-primary w-100" type="submit" data-bs-dismiss="modal" data-bs-dismiss="modal" (click)="confirmDeleteMeetingRoom()">
            {{"MeetingRooms.Confirm" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>