import { Attendee } from "src/app/attendee/attendee.model";
import { User } from "src/app/users/user.model";

export class Meeting {
    attendees: Attendee[];
    title: string;
    body: {
        contentType: string;
        content: string;
    };
    startDate: Date;
    beginDate: Date;
    endDate: Date;
    offerType: string;
    id: string;
    alias: string;
    companyId: string;
    coManagers: string[];
    createdAt: Date;
    deleted: boolean;
    deletedAt: Date;
    floorId: string;
    internationalPhoneNumbers: string[];
    managed: boolean;
    manager: User;
    managerId: string;
    moderatorCode: string;
    remoteId: string;
    skypeId: string;
    status: string;
    type: string;
    tenantId: string;
    updatedAt: Date;
    hasVR: boolean;
    managerName: string;
    isPassed: boolean;
    content?: string;

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}

export const MeetingType = ["SINGLE_INSTANCE", "SERIES_MASTER", "OCCURRENCE", "EXCEPTION"];
