import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-input-dropdown-form-field',
  templateUrl: './input-dropdown-form-field.component.html',
  styleUrls: ['./input-dropdown-form-field.component.scss'],
})
export class InputDropdownFormFieldComponent implements AfterViewInit {
  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() nameInput = '';
  @Input() myInputModel!: unknown;
  @Input() form!: NgForm;
  @Input() targetValidators!: ValidatorFn[];
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() dataSource: any[];
  @Input() displayKey: string;
  @Input() modelKey: string;
  @Input() selectedElement: any;
  @Input() defaultText: string;
  @Output() selectedData = new EventEmitter<any>();
  showIcon = false;
  firstUse = false;
  focused = false;

  constructor(private eRef: ElementRef) { }

  ngAfterViewInit(): void {
    if (!this.form.getControl(this.inputModel)) {
      this.form.addControl(this.inputModel);
      if (this.targetValidators) {
        setTimeout(() => {
          this.form.controls[this.nameInput].setValidators(this.targetValidators);
        }, 100);
      }
    }
    $('.dropdown-menu').on('click', () => {
      this.showIcon = !this.showIcon;
    });
  }

  switchIcon(): void {
    this.showIcon = !this.showIcon;
    this.firstUse = true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (this.eRef.nativeElement.contains(event.target) && this.showIcon) {
      this.showIcon = true;
    } else {
      this.showIcon = false;
    }
  }

  selectData(data: any): void {
    this.selectedElement = data[this.modelKey];
    this.myInputModel = data[this.modelKey];
    this.selectedData.emit(data);
  }
}
