import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { ActionEvent, DisplayColumn } from '../commons/mat-table-custom/mat-table-custom.model';
import { Meeting } from '../meeting/meeting.model';
import { DatesManager } from '../services/dates/dates.manager';
import { ExportService } from '../services/export/export.service';
import { NotifService } from '../services/notifs/notif.service';
import { SharedService } from '../services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { PlannedMeetingsService } from './planned-meetings.service';
declare var navigator: any;

@Component({
  selector: 'app-planned-meetings',
  templateUrl: './planned-meetings.component.html',
  styleUrls: ['./planned-meetings.component.scss'],
  providers: [ExportService, PlannedMeetingsService]
})
export class PlannedMeetingsComponent implements OnInit, OnDestroy {

  defaultSort = "beginDate";
  displayedColumns: DisplayColumn[];
  selectedPlannedMeeting: Meeting;
  refreshTable = false;
  beginDate: Date;
  endDate: Date;
  from = new Date();
  to = new Date();
  onInitSubscriptions: Subscription[] = [];
  currentConnecteUser: ConnectedUser;
  selectedPlannedMeetingId: string | null;

  constructor(private datesManager: DatesManager, private exportService: ExportService, private notifService: NotifService, private router: Router, private route: ActivatedRoute, private sharedService: SharedService, public plannedMeetingsService: PlannedMeetingsService) {
    this.from = new Date();
    this.to = new Date(this.to.setMonth(this.to.getMonth() + 1));
  }

  ngOnInit(): void {
    if (this.route.parent) {
      this.onInitSubscriptions.push(combineLatest([this.sharedService.currentConnectedUser, this.route.parent.paramMap]).subscribe(result => {
        let connectUser = result[0];
        let tenantId = result[1].get('tenantId');
        this.displayedColumns = [
          { name: "title", type: "any" },
          { name: "manager.displayName", type: "any" },
          { name: "offerType", type: "any" },
          { name: "alias", type: "any" },
          { name: "beginDate", type: "date" },
          { name: "endDate", type: "date" },
          { name: "deleted", type: "boolean" },
        ];
        if (tenantId && connectUser.canEdit(tenantId)) {
          this.displayedColumns.push({ name: "action", type: "action", actions: [{ action: "edit", icon: "edit" }, { action: "delete", icon: "trash-alt" }] });
        }
      }))
    }
    if (this.route.children[0]) {
      this.onInitSubscriptions.push(this.route.children[0].paramMap.subscribe(paramMap => {
        let meetingId = paramMap.get('meetingId')
        if (meetingId) {
          this.selectedPlannedMeetingId = meetingId;
        }
      }));
    }
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  componentEditPlannedMeetingRemoved(): void {
    this.selectedPlannedMeetingId = null;
  }

  onDataError(error: any): void {
    console.log(error);
  }

  onActionClick(data: ActionEvent): void {
    this.selectedPlannedMeeting = data.data;
    switch (data.action.action) {
      case 'edit': {
        this.selectedPlannedMeetingId = data.data.id;
        this.router.navigate(['/administration/' + this.selectedPlannedMeeting.tenantId + '/planned-meetings/' + this.selectedPlannedMeeting.id], { relativeTo: this.route });
        break;
      }
      case 'delete': {
        $('#modalOnDeletePlannedMeetings').modal('show');
        break;
      }
      default: {
        break;
      }
    }
  }

  confirmDeletePlannedMeeting(): void {
    this.refreshTable = true;
    this.plannedMeetingsService.deleteMeeting(this.selectedPlannedMeeting.id).subscribe({
      next: () => {
        this.refreshTable = false;
        console.log('meeting deleted');
      },
      error: (error: HttpErrorResponse) => {
        this.refreshTable = false;
        this.notifService.addErrorNotifExplicit('PlannedMeetings.ErrorDeleteMeeting', error.status + + error.error, error);
      }
    });
  }

  exportPlannedMeetings(): void {
    const fields = "title,user.firstName,user.lastName,user.email,offerType,alias,beginDate,endDate,deleted";
    const url = "/meetings";
    this.exportService.exportDataScheduledMeetings(url, fields, this.beginDate, this.endDate).subscribe((result) => {
      this.downloadExport(result);
    });
  }

  downloadExport(res: string): void {
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe(result => {
      let from = this.route.snapshot.queryParamMap.get('from');
      if (from) {
        this.beginDate = new Date(from);
      }
      let to = this.route.snapshot.queryParamMap.get('to');
      if (to) {
        this.endDate = new Date(to);
      }
      if (result) {
        let localeDateString = result.lang.split('-')[0];
        let date = new Date().toLocaleDateString(localeDateString);
        let time = new Date().toLocaleTimeString(localeDateString);
        const splitDate = date.split("/");
        date = splitDate[0] + "-" + splitDate[1] + "-" + splitDate[2];
        const splitTime = time.split(":");
        time = splitTime[0] + "h" + splitTime[1] + "m" + splitTime[2];
        let split;
        if (localeDateString === "fr") {
          split = " à ";
        } else {
          split = ", ";
        }
        const exportDate = date + split + time;
        let fromDate = new Date(this.beginDate).toLocaleDateString(localeDateString);
        let fromTime = new Date(this.datesManager.fromStartDay(this.beginDate)).toLocaleTimeString(localeDateString);
        const splitFromDate = fromDate.split("/");
        fromDate = splitFromDate[0] + "-" + splitFromDate[1] + "-" + splitFromDate[2];
        const splitFromTime = fromTime.split(":");
        fromTime = splitFromTime[0] + "h" + splitFromTime[1] + "m" + splitFromTime[2];
        const exportFromDate = fromDate + split + fromTime;
        let toDate = new Date(this.endDate).toLocaleDateString(localeDateString);
        let toTime = new Date(this.datesManager.toEndDay(this.endDate)).toLocaleTimeString(localeDateString);
        const splitToDate = toDate.split("/");
        toDate = splitToDate[0] + "-" + splitToDate[1] + "-" + splitToDate[2];
        const splitToTime = toTime.split(":");
        toTime = splitToTime[0] + "h" + splitToTime[1] + "m" + splitToTime[2];
        const exportToDate = toDate + split + toTime;
        const exportFilename = "export réunions planifiées " + exportDate + ", reunions du " + exportFromDate + " au " + exportToDate + ".csv";
        const csvData = new Blob([res], { type: "text/csv;charset=utf-8;" });
        // IE11 & Edge
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(csvData, exportFilename);
        } else {
          // In FF link must be added to DOM to be clicked
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(csvData);
          link.setAttribute("download", exportFilename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }))
  }
}
