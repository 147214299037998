import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { User } from '../user.model';
import { UserEditPasswordService } from './user-edit-password.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, shareReplay } from 'rxjs/operators';
import { ValidatorsList } from 'src/app/validators/validators.model';
import Debug from 'debug';
import { NotifService } from 'src/app/services/notifs/notif.service';
const debug = Debug('virtuvisio-administration:user-edit-password-component');

@Component({
  selector: 'app-user-edit-password',
  templateUrl: './user-edit-password.component.html',
  styleUrls: ['./user-edit-password.component.scss'],
  providers: [UserEditPasswordService]
})
export class UserEditPasswordComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  @Input() connectedUser: ConnectedUser;
  @Input() tenant: Tenant;
  user$: Observable<User>;
  newPassword: string;
  confirmPassword: string;
  validatorsList = ValidatorsList;
  @Output() destroyUserAddEvent = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserEditPasswordService,
    private notif: NotifService
  ) { }

  ngOnInit(): void {
    if (this.route.firstChild) {
      this.onInitSubscriptions.push(
        this.route.firstChild.paramMap.subscribe((params) => {
          console.warn(params);
          if (params.get('userId')) {
            debug(params.get('userId'));
            this.getUser(params.get('userId') as string);
          }
        })
      );
    }
  }

  getUser(userId: string): void {
    this.user$ = this.userService.getUser(this.tenant, userId).pipe(
      catchError(this.handleError<User>('getUser')),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  backToView(userId: string): void {
    this.router.navigate(['./view', userId], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve',
    });
  }

  editUserPassword(passForm: NewPassForm, user: User): void {
    console.log(passForm);
    if (passForm.newPassword === passForm.confirmPassword) {
      user.password = passForm.newPassword;
      this.userService.save(user).subscribe({
        next: () => {
          this.notif.addSuccessNotif('UserEditPasswordComponent.SUCCESS');
          this.backToView(user.id);
        },
        error: (error) => {
          this.notif.addErrorNotif('UserEditPasswordComponent.ERROR', error);
        }
      });
    } else {
      this.notif.addErrorNotif('UserEditPasswordComponent.NoMatch');
    }
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.destroyUserAddEvent.emit(true);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}

interface NewPassForm {
  newPassword: string;
  confirmPassword: string;
}
