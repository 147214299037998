import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { UserAddService } from './user-add.service';
import { User } from '../user.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { Observable } from 'rxjs';
import { CountryService } from "src/app/country/country.service";
import { Country } from "src/app/country/country.model";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss'],
  providers: [CountryService, UserAddService]
})
export class UserAddComponent implements OnInit, OnDestroy {
  @Input() connectedUser: ConnectedUser;
  @Input() tenant: Tenant;
  userLangs = [{ label: "UserAddComponent.French", value: "fr-FR" }, { label: "UserAddComponent.English", value: "en-US" }];
  newUser = new User({});
  hasError = false;
  finished = false;
  countries$: Observable<Country[]>
  countries: Country[];
  countryCode: CountryCode;
  @Output() destroyUserAddEvent = new EventEmitter<boolean>();


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userAddService: UserAddService,
    private notifService: NotifService,
    private countryService: CountryService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.getCountries();
  }

  ngOnDestroy(): void {
    this.destroyUserAddEvent.emit(true);
  }

  confirmAddUser(addUserForm: NgForm): void {
    if (!this.isValidPassword(addUserForm.value.password)) {
      this.hasError = true;
      this.notifService.addErrorNotif("UserAddComponent.PasswordNotValid");
    } else {
      if (addUserForm.valid) {
        const user = addUserForm.value;
        if (user.phone) {
          const phoneNumber = parsePhoneNumberFromString(user.phone, this.countryCode);
          if (!phoneNumber?.isValid()) {
            this.notifService.addErrorNotif("UserAddComponent.PhoneNumberNotValid");
          } else {
            user.phone = phoneNumber.number;
            this.userAddService.create(user).subscribe({
              next: (result) => {
                this.hasError = false;
                this.finished = true;
                this.notifService.addSuccessNotif("UserAddComponent.UserAdded");
                this.backToList();
              },
              error: (error) => {
                this.hasError = true;
                if (error.status === 409) {
                  this.notifService.addErrorNotif("UserAddComponent.UserAlreadyExists");
                } else {
                  this.notifService.addErrorNotif("UserAddComponent.ErrorWhileAddingUser");
                }
              }
            })
          }
        } else {
          this.userAddService.create(user).subscribe({
            next: (result) => {
              this.hasError = false;
              this.finished = true;
              this.notifService.addSuccessNotif("UserAddComponent.UserAdded");
              this.backToList();
            },
            error: (error) => {
              this.hasError = true;
              if (error.status === 409) {
                this.notifService.addErrorNotif("UserAddComponent.UserAlreadyExists");
              } else {
                this.notifService.addErrorNotif("UserAddComponent.ErrorWhileAddingUser");
              }
            }
          })
        }
      } else {
        this.hasError = true;
      }
    }
  }

  getCountries(): void {
    this.countryService.getCountries().subscribe(countries => {
      this.countries = countries;
      for (const c in this.countries) {
        this.countries[c].displaySelect = this.countries[c].code + " (" + this.countries[c].phoneNumberPrefix + ")";
      }
      this.countryCode = "FR";
    })
  }

  onEmailBlur(event: Event): void {
    const email = (<HTMLTextAreaElement>event.target).value;
    if (email) {
      this.loginService.getAvailableUser(email).subscribe({
        next: () => {
          console.log('User available');
        },
        error: (error) => {
          if (error.status === 404) {
            this.notifService.addErrorNotif("UserAddComponent.UserAlreadyExists");
          }
        }
      });
    }
  }

  isValidPassword(password: string): boolean {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (regex.test(password)) {
      return true;
    }
    return false;
  }

  backToList(): void {
    this.router.navigate(['./list'], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve',
    });
  }
}
