<div class="d-flex w-100 mx-auto flex-column">
	<app-header></app-header>
	<div class="home">
		<ng-container *ngIf="(connectedUser$ | async) as connectedUser">
			<app-sidebar [connectedUser]="connectedUser" *ngIf="connectedUser"></app-sidebar>
		</ng-container>
		<div class="container-fluid">
			<ng-container *ngIf="(connectedUser$ | async) as connectedUser">
				<router-outlet></router-outlet>
			</ng-container>
		</div>
	</div>
</div>
