import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { TenantService } from 'src/app/tenant/tenant.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { MeetingRoom } from '../meeting-room.model';
import { MeetingRoomsService } from '../meeting-rooms.service';
import { QueryParamsManager } from 'src/app/services/query-params-manager/query-params-manager';
import { Observable } from 'rxjs';
import { Actions, Controls } from 'src/app/commons/toolbar/toolbar.model';

@Component({
  selector: 'app-details-meeting-room',
  templateUrl: './details-meeting-room.component.html',
  styleUrls: ['./details-meeting-room.component.scss'],
  providers: [QueryParamsManager]
})
export class DetailsMeetingRoomComponent implements OnInit, OnDestroy {
  
  tab: string | null = 'general';
  onInitSubscriptions: Subscription[] = [];
  currentConnectedUser: ConnectedUser;
  kloodDateFormat = KloodDateFormat;
  currentTenant: Tenant;
  selectedTabIndex: number = 0;
  currentMeetingRoom$: Observable<MeetingRoom>;
  controls: Controls[] = [];
  
  constructor(private router: Router, private queryParamsManager: QueryParamsManager, private tenantService: TenantService, private sharedService: SharedService, private route: ActivatedRoute, private meetingRoomsService: MeetingRoomsService) { }
  
  ngOnInit(): void {
    this.controls.push({
      icon: 'edit',
      controleType: 'ACTION',
      isSwitch: false,
      tooltip: "Edit",
      action: Actions.Edit,
    }, {
      icon: 'times',
      controleType: 'ACTION',
      isSwitch: false,
      tooltip: "Close",
      action: Actions.Back
    })
    this.onInitSubscriptions.push(this.route.paramMap.subscribe(params => {
      let meetingRoomId = params.get('meetingRoomId');
      if(meetingRoomId){
        this.getMeetingRoom(meetingRoomId);
      }
    }))
    this.onInitSubscriptions.push(this.sharedService.currentConnectedUser.subscribe(result => {
      this.currentConnectedUser = result;
    }))
    if(this.route.parent?.parent){
      this.onInitSubscriptions.push(this.route.parent.parent.paramMap.subscribe(params => {
        let tenantId = params.get('tenantId');
        if(tenantId){
          this.getTenant(tenantId);
        }
      }))
    }
    this.onInitSubscriptions.push(this.route.queryParamMap.subscribe(params => {
      let tab = params.get('selectedTab');
      if(tab){
        this.selectedTabIndex = parseInt(tab);
      } 
    }))
  }
  
  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }
  
  getMeetingRoom(meetingRoomId: string): void {
    this.currentMeetingRoom$ = this.meetingRoomsService.getMeetingRoom(meetingRoomId);
  }
  
  getTenant(tenantId: string): void {
    this.tenantService.getTenant(tenantId).subscribe(result => {
      this.currentTenant = result;
    })
  }
  
  canShowEndpointTab(): boolean {
    return (this.currentTenant && (this.currentTenant.hasAccess('GATEWAY') || this.currentTenant.hasAccess('ONE_CODEC') || this.currentTenant.hasAccess('TEAMS_GATEWAY') || this.currentTenant.hasAccess('HANGOUT_GATEWAY')) && this.currentConnectedUser && this.currentConnectedUser.canEdit(this.currentTenant.id));
  }
  
  onTabChanged(event: any): void {
    this.queryParamsManager.changeQueryParams({selectedTab: event.index});
  }
  
  onRequestAction(event: string): void {
    if(event === "EDIT"){
      this.currentMeetingRoom$.subscribe(meetingRoom => {
        this.router.navigate(['../../' + meetingRoom.id + '/edit'], {relativeTo: this.route});
      })
    }
    if(event === "BACK"){
      this.router.navigate(['../../'], {relativeTo: this.route});
    }
  }
  
}
