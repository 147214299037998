<form #editEndpointForm="ngForm" id="editEndpointForm" name="editEndpointForm" class="form-control">
  <app-input-form-field class="form-control" [textLabel]="'EditEndpoint.Number'" [typeInput]="'text'" [nameInput]="'EditEndpoint.Number'" [form]="editEndpointForm" [myInputModel]="currentEndpoint.number" [required]="true" (modelChangeEvent)="currentEndpoint.number=$event"></app-input-form-field>
  <app-input-form-field class="form-control" [textLabel]="'EditEndpoint.ModeratorCode'" [typeInput]="'text'" [nameInput]="'EditEndpoint.ModeratorCode'" [form]="editEndpointForm" [myInputModel]="currentEndpoint.moderatorCode" [required]="true" (modelChangeEvent)="currentEndpoint.moderatorCode=$event"></app-input-form-field>
  <ng-container *ngIf="currentEndpoint.kind === 'CISCO'">
    <app-input-form-field class="form-control" [textLabel]="'EditEndpoint.Ip'" [typeInput]="'text'" [nameInput]="'EditEndpoint.Ip'" [form]="editEndpointForm" [myInputModel]="currentEndpoint.config.ip" [required]="true" (modelChangeEvent)="currentEndpoint.config.ip=$event"></app-input-form-field>
    <app-input-form-field class="form-control" [textLabel]="'EditEndpoint.Username'" [typeInput]="'text'" [nameInput]="'EditEndpoint.Username'" [form]="editEndpointForm" [myInputModel]="currentEndpoint.config.username" [required]="true" (modelChangeEvent)="currentEndpoint.config.username=$event"></app-input-form-field>
    <app-input-form-field class="form-control" [textLabel]="'EditEndpoint.Password'" [typeInput]="'text'" [nameInput]="'EditEndpoint.Password'" [form]="editEndpointForm" [myInputModel]="currentEndpoint.config.password" [required]="true" (modelChangeEvent)="currentEndpoint.config.password=$event"></app-input-form-field>
    <app-input-dropdown-form-field [nameInput]="'EditEndpoint.Scheme'" [defaultText]="'EditEndpoint.Scheme'" [modelKey]="'value'" [displayKey]="'label'" [selectedElement]="currentEndpoint.config.scheme" [myInputModel]="currentEndpoint.config.scheme" [dataSource]="endpointConfigSchemes" class="form-control" (selectedData)="currentEndpoint.config.scheme=$event.value" [form]="editEndpointForm" [required]="true"></app-input-dropdown-form-field>
    <app-input-form-field class="form-control" [textLabel]="'EditEndpoint.Port'" [typeInput]="'text'" [nameInput]="'EditEndpoint.Port'" [form]="editEndpointForm" [myInputModel]="currentEndpoint.config.port" [required]="true" (modelChangeEvent)="currentEndpoint.config.port=$event"></app-input-form-field>
    <div class="row align-items-center">
      <div class="col-auto">
        <app-checkbox-form-field [nameInput]="'EditEndpoint.CheckCertificates'" [form]="editEndpointForm" [checked]="currentEndpoint.config.checkCertificates" [myInputModel]="currentEndpoint.config.checkCertificates" (checkedEvent)="currentEndpoint.config.checkCertificates=$event"></app-checkbox-form-field>
      </div>
      <div class="col">
        {{"EditEndpoint.CheckCertificates" | translate}}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="currentEndpoint.kind === 'OTHER'">
    <app-input-form-field class="form-control" [textLabel]="'EditEndpoint.DialString'" [typeInput]="'text'" [nameInput]="'EditEndpoint.DialString'" [form]="editEndpointForm" [myInputModel]="currentEndpoint.config.dialString" [required]="true" (modelChangeEvent)="currentEndpoint.config.dialString=$event"></app-input-form-field>
  </ng-container>
  <ng-container>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary width-100" type="reset" (click)="cancelEndpointEdit.emit(false)">{{"EditEndpoint.Cancel" | translate }}</button>
      <app-loading-button (submitEvent)="editEndpoint(editEndpointForm)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'EditEndpoint.Valid'" [finalText]="'EditEndpoint.Validate'"></app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="editEndpointForm.submitted && !editEndpointForm.valid">{{'EditEndpoint.SomeFields' | translate}}</div>
  </ng-container>
</form>