<div class="custom-table-filter" *ngIf="paginatorReady">
    <mat-form-field appearance="outline" class="mat-form-field-custom" id="custom-search-1" *ngIf="!dataService">
        <mat-icon matPrefix><fa-icon [icon]="['fas', 'search']"></fa-icon></mat-icon>
        <input matInput (ngModelChange)="applySuggest($event)" [(ngModel)]="paginationInfo.suggest" name="search" placeholder="{{'MatTable.Search' | translate}}" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="mat-form-field-custom" id="custom-search-2" *ngIf="dataService">
        <mat-icon matPrefix><fa-icon [icon]="['fas', 'search']"></fa-icon></mat-icon>
        <input matInput (ngModelChange)="onSuggest($event)" [(ngModel)]="paginationInfo.suggest" name="search" placeholder="{{'MatTable.Search' | translate}}" />
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" [matSortActive]="paginationInfo.sort" [matSortDirection]="paginationInfo.order" matSort (matSortChange)="onSort($event);" class="custom-table" [hidden]="loading">
        <ng-container *ngFor="let displayedColumn of paginationInfo.displayedColumns" [matColumnDef]="displayedColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'th-border-radius-bottom': dataSource && dataSource.data?.length === 0}">{{getNameColumn(displayedColumn) | translate}}</th>
            <td mat-cell *matCellDef="let data" [ngClass]="{'selected-row': selectedRowItem && selectedRowId && data[selectedRowItem] === selectedRowId}" attr.data-th="{{getNameColumn(displayedColumn) | translate}}" class="small-right" (click)="!isActionColumn(displayedColumn) ? onRowClick(data) : null">
                <span *ngIf="!isActionColumn(displayedColumn)">{{findPropertyValue(data, displayedColumn)}}</span>
                <div *ngIf="isActionColumn(displayedColumn)" class="actions-list text-center">
                    <span *ngFor="let action of getActionName(displayedColumn); let last = last;">
                        <a *ngIf="action.tooltip" class="pointer" (click)="onActionClick(data, action)" #tooltip="matTooltip" matTooltip="{{action.tooltip | translate}}" matTooltipClass="custom-tooltip">
                            <fa-icon [icon]="['fas', action.icon]" class="mx-1"></fa-icon>
                        </a>
                        <a *ngIf="!action.tooltip" class="pointer" (click)="onActionClick(data, action)">
                            <fa-icon [icon]="['fas', action.icon]" class="mx-1"></fa-icon>
                        </a>
                        <span *ngIf="!last"> - </span>
                    </span>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="paginationInfo.displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: paginationInfo.displayedColumns;" [ngClass]="{'pointer': hoverable, 'table-hover': hoverable}"></tr>
    </table>
    <div *ngIf="!loading && (dataSource && dataSource.data?.length === 0)" class="ms-2 mt-2">
        <i>{{"MatTable.NoAvailableData" | translate}}...</i>
    </div>
    <mat-paginator *ngIf="!loading" class="mat-paginator-custom" [length]="paginationInfo.listLength" [pageSize]="paginationInfo.pageSize" [pageIndex]="paginationInfo.pageIndex" [pageSizeOptions]="paginationInfo.pageSizeOptions" showFirstLastButtons (page)="onChangePagination($event)"></mat-paginator>
</div>
<div class="row d-flex justify-content-center align-items-center my-5" *ngIf="loading">
    <div class="col-auto my-auto">
        <app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
    </div>
</div>
