import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUserService } from 'src/app/users/user-connected/connected-user.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import Debug from 'debug';
import { Router } from '@angular/router';
const debug = Debug('virtuvisio-administration:FooterComponent');

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  activeLang: string;

  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
    private userService: ConnectedUserService,
    private router: Router
  ) {
    this.activeLang = this.translate.currentLang;
  }

  async ngOnInit(): Promise<void> {
    this.onInitSubscriptions.push(
      await this.sharedService.currentConnectedUser.subscribe(
        async (connectedUser: ConnectedUser) => {
          if (connectedUser) {
            this.connectedUser = connectedUser;
            await this.setLang(this.connectedUser);
          }
        }
      )
    );
  }

  async setLang(connectedUser: ConnectedUser): Promise<void> {
    const userLang = connectedUser.lang;
    let lang = 'fr';
    if (userLang === 'fr-FR') {
      lang = 'fr';
    } else if (userLang === 'en-GB' || userLang === 'en-US') {
      lang = 'en';
    } else {
      lang = 'fr';
    }
    await lastValueFrom(this.translate.use(lang));
  }

  async switchLang(event: Event): Promise<void> {
    const checkbox = event.target as HTMLInputElement;
    let lang;
    if (checkbox.checked) {
      await lastValueFrom(this.translate.use('en'));
      lang = 'en-US';
    } else {
      await lastValueFrom(this.translate.use('fr'));
      lang = 'fr-FR';
    }
    // this.translate.use(lang);
    if (this.connectedUser) {
      this.saveUserLang(lang);
    } else {
      this.activeLang = this.translate.currentLang;
    }
  }

  saveUserLang(lang: string): void {
    this.connectedUser.lang = lang;
    this.userService.editConnectedUser(this.connectedUser).subscribe({
      next: (user: ConnectedUser) => {
        this.connectedUser = user;
        this.activeLang = this.translate.currentLang;
      },
      error: (error) => {
        debug(error);
      }
    });
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
