import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Environment } from 'src/app/environments/environment.model';
import { EnvironmentsService } from 'src/app/environments/environments.service';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { AuthConfig } from '../authConfig.model';
import { Tenant } from '../tenant.model';
import { TenantService } from '../tenant.service';

@Component({
  selector: 'app-add-tenant',
  templateUrl: './add-tenant.component.html',
  styleUrls: ['./add-tenant.component.scss'],
  providers: [EnvironmentsService, TenantService]
})
export class AddTenantComponent implements OnInit {

  newTenant = new Tenant({});
  finished = false;
  hasError = false;
  environments: Environment[] = [];
  defaultTenantLanguages: ListElement[] = [{ id: 'fr-FR', label: 'AddTenantComponent.French' }, { id: 'en-US', label: 'AddTenantComponent.English' }];
  defaultTenantAuthProviders: ListElement[] = [{ id: 'default', label: 'AddTenantComponent.Default' }, { id: 'microsoft', label: 'AddTenantComponent.Microsoft' }];
  constructor(private environmentsService: EnvironmentsService, private notifService: NotifService, private tenantService: TenantService, private sharedService: SharedService, private router: Router) { }

  ngOnInit(): void {
    this.newTenant.authProvider = "default";
    this.newTenant.support = false;
    this.newTenant.authConfigKey = "tenanId";
    this.getEnvironments();
  }

  confirmAddTenant(addTenant: NgForm): void {
    if (addTenant.valid) {
      this.newTenant.name = addTenant.value.name;
      this.newTenant.description = addTenant.value.description;
      this.newTenant.environmentId = addTenant.value.environmentId === "id" ? "" : addTenant.value.environmentId;
      this.newTenant.reportEmail = addTenant.value.reportEmail;
      this.newTenant.defaultLanguage = addTenant.value.defaultLanguage;
      this.newTenant.support = addTenant.value.support;
      this.newTenant.authProvider = addTenant.value.authProvider;
      if (this.newTenant.authProvider === "microsoft") {
        if (this.newTenant.authConfigKey && this.newTenant.authConfigValue) {
          if (this.validateUuidFormat(this.newTenant.authConfigValue)) {
            this.newTenant.authConfig.tenantId = this.newTenant.authConfigValue;
          } else {
            this.hasError = true;
            return;
          }
        } else {
          this.notifService.addErrorNotif("AddTenantComponent.InvalidUUIDFormat");
          this.notifService.addErrorNotif("AddTenantComponent.SomeFields");
          this.hasError = true;
          return;
        }
      }
      delete this.newTenant.authConfigKey;
      delete this.newTenant.authConfigValue;
      this.tenantService.addTenant(this.newTenant).subscribe({
        next: (result) => {
          this.hasError = false;
          this.finished = true;
          this.notifService.addSuccessNotif("AddTenantComponent.SuccessCreatingTenant");
          this.sharedService.updateTenantList(true);
          const url = "/administration/" + result.id + "/overview";
          const link = [url];
          this.router.navigate(link);
        },
        error: (error) => {
          if (error.status === 409) {
            this.hasError = true;
            this.notifService.addErrorNotif("AddTenantComponent.ConflictWhileCreatingTenant", error);
          } else {
            this.hasError = true;
            this.notifService.addErrorNotif("AddTenantComponent.ErrorWhileCreatingTenant", error);
          }
        }
      })
    } else {
      this.hasError = true;
    }
  }

  selectAuthProvider(authProvider: ListElement): void {
    this.newTenant.authProvider = authProvider.id;
    if (authProvider.id === "microsoft") {
      this.newTenant.authConfig = new AuthConfig({});
      this.newTenant.authConfigKey = "tenantId";
      this.newTenant.authConfigValue = "";
    }
  }

  getEnvironments(): void {
    this.environmentsService.getEnvironments().subscribe({
      next: (result) => {
        this.environments = result;
        const defEnv = new Environment({});
        defEnv.host = "default";
        defEnv.id = "id";
        this.environments.unshift(defEnv);
      },
      error: (error) => {
        console.log("Error while getting environments");
      }
    })
  }

  validateUuidFormat(uuid: string): boolean {
    const regex = /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/;
    if (regex.test(uuid)) {
      return true;
    }
    this.notifService.addErrorNotif("AddTenantComponent.InvalidUUIDFormat");
    return false;
  }

}

interface ListElement {
  id: string;
  label: string;
}
