import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-checkbox-form-field',
  templateUrl: './checkbox-form-field.component.html',
  styleUrls: ['./checkbox-form-field.component.scss'],
})
export class CheckboxFormFieldComponent implements AfterViewInit {
  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() textLabel = '';
  @Input() nameInput = '';
  @Input() form!: NgForm;
  @Input() targetValidators!: ValidatorFn[];
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() checked: boolean | null = false;
  @Input() inverted = false;
  @Input() myInputModel!: unknown;
  @Output() checkedEvent = new EventEmitter<boolean>();

  ngAfterViewInit(): void {
    if (!this.form.getControl(this.inputModel)) {
      this.form.addControl(this.inputModel);
      if (this.targetValidators) {
        setTimeout(() => {
          this.form.controls[this.nameInput].setValidators(
            this.targetValidators
          );
        }, 100);
      }
    }
  }

  changeCheckbox(ev: Event): void {
    const ch = ev.target as HTMLInputElement;
    this.myInputModel = ch.checked;
    this.checkedEvent.emit(ch.checked);
  }
}
