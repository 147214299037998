import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DisplayColumn } from '../commons/mat-table-custom/mat-table-custom.model';
import { Controls } from '../commons/toolbar/toolbar.model';
import { NotifService } from '../services/notifs/notif.service';
import { HistoricPolicyReject } from './history-policy-rejects.model';
import { HistoricPolicyRejectsService } from './history-policy-rejects.service';

@Component({
  selector: 'app-history-policy-rejects',
  templateUrl: './history-policy-rejects.component.html',
  styleUrls: ['./history-policy-rejects.component.scss'],
  providers: [HistoricPolicyRejectsService]
})
export class HistoryPolicyRejectsComponent implements OnInit {

	displayedColumns: DisplayColumn[];
	selectedPolicyReject: HistoricPolicyReject | null;
	defaultSort = "date";
	controls: Controls[] = [{
		icon: 'times',
		doubleIcon: false,
		secondIcon: 'times',
		controleType: 'ACTION',
		action: 'BACK',
		tooltip: 'back to previous',
		isSwitch: false
	}]

	constructor(public historicPolicyRejectsService: HistoricPolicyRejectsService, private notifService: NotifService) {}

	ngOnInit(): void {
		this.displayedColumns = [
			{ name: "date", type: "date" },
			{ name: "status.description", type: "any" },
			{ name: "request.remoteAlias", type: "any" },
			{ name: "request.localAlias", type: "any" },
		];
	}

	onRowClick(policyReject: HistoricPolicyReject): void {
		this.selectedPolicyReject = policyReject;
	}

	onDataError(error: HttpErrorResponse): void {
		this.notifService.addErrorNotif("Error : " + error.message);
	}

	closeAction(event: string): void {
		if(event === 'BACK'){
			this.selectedPolicyReject = null;
		}
	}

}
