<app-admin-bloc [blocTitle]="'Edit'">
  <form #editForm="ngForm" id="editForm" name="editForm" *ngIf="currentConnectedUser && currentMeeting">
    <app-input-form-field [nameInput]="'title'" [typeInput]="'text'" [myInputModel]="currentMeeting.title"
      [textLabel]="'EditPlannedMeetings.Form.Title'" [form]="editForm" class="form-control" [required]="true"
      [disabled]="currentMeeting.managed || currentMeeting.type !== 'SINGLE_INSTANCE'">
    </app-input-form-field>
    <app-attendees-list (checkEmailDone)="mailCheckEventHandlerFunction($event)"
      [validateMailBeforeValidate]="validateMailBeforeValidate" [attendeesList]="attendeesList" class="form-control"
      [noChange]="currentMeeting.managed || currentMeeting.type !== 'SINGLE_INSTANCE'">
    </app-attendees-list>
    <app-textarea-field [nameInput]="'content'" [myInputModel]="currentMeeting.body.content"
      [textLabel]="'EditPlannedMeetings.Form.Content'" [form]="editForm" class="form-control"
      [disabled]="currentMeeting.managed || currentMeeting.type !== 'SINGLE_INSTANCE'"></app-textarea-field>
    <app-datetime-picker-form-field [connectedUser]="currentConnectedUser" [nameInput]="'beginDate'" [myInputModel]="beginDate"
      [textLabel]="'EditPlannedMeetings.Form.BeginDate'" [form]="editForm" (dateSelect)="beginDateSelect($event)"
      class="form-control" [disabled]="currentMeeting.managed || currentMeeting.type !== 'SINGLE_INSTANCE'">
    </app-datetime-picker-form-field>
    <app-datetime-picker-form-field [connectedUser]="currentConnectedUser" [nameInput]="'endDate'" [myInputModel]="endDate"
      [textLabel]="'EditPlannedMeetings.Form.EndDate'" [form]="editForm" (dateSelect)="endDateSelect($event)"
      class="form-control" [disabled]="currentMeeting.managed || currentMeeting.type !== 'SINGLE_INSTANCE'">
    </app-datetime-picker-form-field>
    <app-input-dropdown-form-field *ngIf="offerTypes" [nameInput]="'offerType'" [defaultText]="'EditPlannedMeetings.Form.OfferType'"
      [modelKey]="'value'" [displayKey]="'label'" [selectedElement]="currentMeeting.offerType"
      [myInputModel]="currentMeeting.offerType" [dataSource]="offerTypes" [form]="editForm" (selectedData)="select($event)"
      class="form-control" [required]="true"></app-input-dropdown-form-field>
    <app-coorganizers-list *ngIf="((currentMeeting.offerType === 'PRO' && tenant && tenant.offers['PRO'].coOrganization))"
      [connectedUser]="currentConnectedUser" (checkEmailDone)="mailCheckEventHandlerFunction($event)"
      [validateMailBeforeValidate]="validateMailBeforeValidate" [coorganizersList]="coorganizersList"
      class="form-control" [noChange]="currentMeeting.managed || currentMeeting.type !== 'SINGLE_INSTANCE'"></app-coorganizers-list>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary w-100 margin-bottom-20" type="reset"
      [routerLink]="['../']">{{"EditPlannedMeetings.Form.Button.Cancel" | translate }}</button>
      <app-loading-button (submitEvent)="editMeeting(editForm, editForm.value)" [targetStyle]="'primary'"
        [finished]="finished" [error]="hasError" [initialText]="'EditPlannedMeetings.Form.Button.Edit'"
        [finalText]="'EditPlannedMeetings.Form.Button.Edited'">
      </app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="editForm.submitted && !editForm.valid">
      {{'EditPlannedMeetings.Form.Button.SomeFields' | translate}}</div>
  </form>
</app-admin-bloc>