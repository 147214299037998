import { Component, OnDestroy, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { StatsUsageService } from './stats-usage.service';
import Debug from 'debug';
import { first, ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
const debug = Debug('virtuvisio-administration:stats-usage-component');

@Component({
  selector: 'app-stats-usage',
  templateUrl: './stats-usage.component.html',
  styleUrls: ['./stats-usage.component.scss'],
  providers: [StatsUsageService]
})
export class StatsUsageComponent implements OnInit, OnDestroy {

  usageError = false;
  usageNull = false;
  usageChart: Chart
  to = new Date();
  from = new Date(new Date().setDate(this.to.getDate() - 7));
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  onInitSubscriptions: Subscription[] = [];

  constructor(private usageService: StatsUsageService, private route: ActivatedRoute, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(takeUntil(this.destroyed$)).subscribe((queryParams) => {
      const from = queryParams.get('from');
      const to = queryParams.get('to');
      if (from && to) {
        this.from = new Date(from);
        this.to = new Date(to);
        this.loadUsage();
      }
    })
  }

  async loadUsage() {
    this.usageNull = false;
    this.usageError = false;
    if (this.usageChart) {
      await this.usageChart.destroy();
    }
    this.onInitSubscriptions.push(this.usageService.getUsage(this.from, this.to).pipe(first()).subscribe({
      next: (result: UsageData[]) => {
        if (!result) {
          this.usageNull = true;
        } else {
          let usageChartCanvas = document.getElementById('stats-usage-chart') as HTMLCanvasElement;
          this.usageChart = this.generateCanvas(usageChartCanvas, result);
        }
      },
      error: (error) => {
        debug(error);
        this.usageError = true;
      }
    }))
  }

  generateCanvas(ctx: any, data: UsageData[]): any {
    const parsedData: GraphData = this.parseData(data);
    return new Chart(ctx, {
      type: 'scatter',
      data: {
        labels: parsedData.labels,
        datasets: [
          {
            type: 'bar',
            label: 'Virtuvisio',
            data: parsedData.virtuvisio,
            backgroundColor: 'rgba(189, 49, 86, 0.6)',
            borderColor: 'rgba(189, 49, 86, 1)',
            borderWidth: 1,
            parsing: {
              yAxisKey: 'virtuvisio'
            }
          },
          {
            type: 'bar',
            label: 'Legacy',
            data: parsedData.legacy,
            backgroundColor: 'rgba(230, 162, 3, 0.6)',
            borderColor: 'rgba(230, 162, 3, 1)',
            borderWidth: 1,
            parsing: {
              yAxisKey: 'legacy'
            }
          },
          {
            type: 'bar',
            label: 'Justice',
            data: parsedData.justice,
            backgroundColor: 'rgba(0, 33, 83, 0.6)',
            borderColor: 'rgba(0, 33, 83, 1)',
            borderWidth: 1,
            parsing: {
              yAxisKey: 'justice'
            }
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
      }
    });
  }

  parseData(data: UsageData[]): GraphData {
    let labels = [];
    let virtuvisioCounts = [];
    let legacyCounts = [];
    let justiceCounts = [];
    for (let i = 0; i < data.length; i++) {
      let label = this.parseDate(data[i].from);
      let virtuvisioCounter = data[i].virtuvisioUsageCounter;
      let legacyCounter = data[i].legacyUsageCounter;
      let justiceCounter = data[i].justiceUsageCounter;
      labels.push(label);
      virtuvisioCounts.push(virtuvisioCounter);
      legacyCounts.push(legacyCounter);
      justiceCounts.push(justiceCounter);
    }
    const dataGraph = {
      labels: labels,
      virtuvisio: virtuvisioCounts,
      legacy: legacyCounts,
      justice: justiceCounts
    }
    return dataGraph;
  }

  parseDate(date: string) {
    return this.datePipe.transform(date, 'dd/MM/yyyy') as string;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
     this.onInitSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}

interface UsageData {
  from: string;
  justiceUsageCounter: number;
  legacyUsageCounter: number;
  resetCounter: number;
  to: string
  virtuvisioUsageCounter: number;
}

interface GraphData {
  labels: string[],
  virtuvisio: number[],
  legacy: number[],
  justice: number[]
}