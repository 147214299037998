import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from '../services/shared/shared.service';
import { UrlGeneratorService } from '../services/url-generator/url-generator.service';
import { ConnectedUser } from '../users/user-connected/user.connected.model';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit {
  
  connectedUser$: Observable<ConnectedUser>;
  routeParams: any;
  
  constructor(private sharedService: SharedService, private urlGeneratorService: UrlGeneratorService, private route: ActivatedRoute) {
    this.connectedUser$ = this.sharedService.currentConnectedUser;
  }
  
  ngOnInit(): void {
    this.routeParams = this.route.snapshot.queryParams;
  }
  
  navigateToHome(): void {
    document.location.href = this.urlGeneratorService.buildUrl();
  }
  
  reloadNewVersion(): void {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach(async (name) => {
          await caches.delete(name);
        });
      });
      document.location.href = this.urlGeneratorService.buildUrl();
    }
  }
  
}
