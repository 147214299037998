import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Mail, MailPreviewContent } from "./template.model";
import { DataApiOptionService } from "../services/data-api-option/data-api-option.service";

@Injectable()
export class TemplateMailService {
    constructor(private http: HttpClient, private optionService: DataApiOptionService) {}

    getMails(tenantId: string): Observable<unknown> {
        return this.optionService.getApiUrl(tenantId).pipe(
            switchMap((apiUri) => {
                return this.http.get<unknown>(apiUri + "/mails/", { headers: this.optionService.getHeaders(), observe: "body", withCredentials: true }).pipe(map((result) => result))
            }),
        );
    }

    getMail(tenantId: string, name: string, lang: string): Observable<unknown> {
        return this.optionService.getApiUrl(tenantId).pipe(
            switchMap((apiUri) => {
                return this.http.get<unknown>(apiUri + "/mails/" + name + "/" + lang, { headers: this.optionService.getHeaders(), observe: "body", withCredentials: true }).pipe(map((result) => result))
            }),
        );
    }

    save(tenantId: string, name: string, lang: string, mail: Mail): Observable<unknown> {
        return this.optionService.getApiUrl(tenantId).pipe(
            switchMap((apiUri) => {
                return this.http.put<unknown>(apiUri + "/mails/" + name + "/" + lang, mail, { headers: this.optionService.getHeaders(), observe: "body", withCredentials: true }).pipe(map((result) => result))
            }),
        );
    }

    delete(tenantId: string, name: string, lang: string): Observable<unknown> {
        return this.optionService.getApiUrl(tenantId).pipe(
            switchMap((apiUri) => {
                return this.http.delete<unknown>(apiUri + "/mails/" + name + "/" + lang, { headers: this.optionService.getHeaders(), observe: "body", withCredentials: true }).pipe(map((result) => result))
            }),
        );
    }

    preview(tenantId: string, name: string, lang: string, mail: MailPreviewContent): Observable<unknown> {
        return this.optionService.getApiUrl(tenantId).pipe(
            switchMap((apiUri) => {
                return this.http.post<unknown>(apiUri + "/mails/" + name + "/" + lang + "/render", mail, { headers: this.optionService.getHeaders(), observe: "body", withCredentials: true }).pipe(map((result) => result))
            }),
        );
    }

    previewContent(tenantId: string, name: string, lang: string, mail: Mail): Observable<unknown> {
        return this.optionService.getApiUrl(tenantId).pipe(
            switchMap((apiUri) => {
                return this.http.post<unknown>(apiUri + "/mails/" + name + "/" + lang + "/render/content", mail, { headers: this.optionService.getHeaders(), observe: "body", withCredentials: true }).pipe(map((result) => result))
            }),
        );
    }

    render(tenantId: string, name: string, lang: string): Observable<unknown> {
        return this.optionService.getApiUrl(tenantId).pipe(
            switchMap((apiUri) => {
                return this.http.get<unknown>(apiUri + "/mails/" + name + "/" + lang + "/render", { headers: this.optionService.getHeaders(), observe: "body", withCredentials: true }).pipe(map((result) => result))
            }),
        );
    }

    renderContent(tenantId: string, name: string, lang: string): Observable<unknown> {
        return this.optionService.getApiUrl(tenantId).pipe(
            switchMap((apiUri) => {
                return this.http.get<unknown>(apiUri + "/mails/" + name + "/" + lang + "/render/content", { headers: this.optionService.getHeaders(), observe: "body", withCredentials: true }).pipe(map((result) => result))
            })
        );
    }
}
