<div class="row align-items-center p-3">
	<div class="col-auto">
			<h3 class="m-0">
					<span class="is-bold">{{"InvitationsConfigurationComponent.Title" | translate}}</span>
			</h3>
	</div>
</div>
<div class="container-fluid">
	<div class="row">
		<div class="col-12 p-0">
			<div class="invitations-configuration">
				<app-admin-card>
					<mat-tab-group dynamicHeight animationDuration="500ms" (selectedTabChange)="switchTab($event)" [selectedIndex]="selectedTabIndex">
						<mat-tab label="{{'InvitationsConfigurationComponent.IcsInvit' | translate}}">
							<div class="row align-items-center pt-2">
								<div class="col-auto">
									{{"InvitationsConfigurationComponent.Invitations" | translate}}
								</div>
								<div class="col">
									<app-input-dropdown-field [selectedElement]="selectedInvitation" [defaultText]="'InvitationsConfigurationComponent.Invitations'" [modelKey]="'value'" [displayKey]="'label'" [dataSource]="transformValueArrays(icsInvitations)" (selectedData)="renderInvitation($event.value)"></app-input-dropdown-field>
								</div>
								<div class="col-auto">
									{{"InvitationsConfigurationComponent.Language" | translate}}
								</div>
								<div class="col">
									<app-input-dropdown-field [selectedElement]="lang" [defaultText]="'InvitationsConfigurationComponent.Language'" [modelKey]="'value'" [displayKey]="'label'" [dataSource]="transformValueArrays(langs)" (selectedData)="switchLang($event.value)"></app-input-dropdown-field>
								</div>
							</div>
							<ng-container *ngIf="!invitation && !loading && !selectedInvitation">
								<div class="row mt-4">
									<div class="col">
										{{"InvitationsConfigurationComponent.NoTemplateAndLangSelected" | translate}}
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="!invitation && !loading && selectedInvitation">
								<div class="row mt-3">
									<div class="col-12">
										<div class="col-12">
											<button class="btn btn-sm btn-app app-secondary" (click)="confirmDeleteInvitation(selectedInvitation);">{{"InvitationsConfigurationComponent.ResetToDefault" | translate}}</button>
										</div>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="invitation && !loading">
								<div class="row mt-3">
									<div class="col-12">
										<button class="btn btn-sm btn-app app-primary float-end ms-3" *ngIf="!rendering" (click)="previewInvitation(selectedInvitation, invitation);">{{"InvitationsConfigurationComponent.Preview" | translate}}</button>
										<button class="btn btn-sm btn-app app-primary float-end ms-3" *ngIf="!rendering" (click)="confirmEditInvitation(selectedInvitation, invitation);">{{"InvitationsConfigurationComponent.Save" | translate}}</button>
										<button class="btn btn-sm btn-app app-secondary float-start me-3" *ngIf="!rendering" (click)="renderInvitation(selectedInvitation);">{{"InvitationsConfigurationComponent.BackToRender" | translate}}</button>
										<button class="btn btn-sm btn-app app-secondary float-start me-3" (click)="confirmDeleteInvitation(selectedInvitation);">{{"InvitationsConfigurationComponent.ResetToDefault" | translate}}</button>
										<button class="btn btn-sm btn-app app-primary float-end ms-3" *ngIf="rendering" (click)="getInvitation(selectedInvitation);">{{"InvitationsConfigurationComponent.Edit" | translate}}</button>
									</div>
								</div>
								<div class="row mt-3" *ngIf="rendering">
									<div class="col-12">
										<textarea class="tpl-custom-textarea" [readonly]="true" [(ngModel)]="invitation"></textarea>
									</div>
								</div>
								<div class="row mt-3" *ngIf="!rendering">
									<div class="col-6">
										<div class="accordion" id="accordionContext">
											<div class="accordion-item">
												<h2 class="accordion-header" id="headingAvailableFields">
													<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFields" aria-expanded="false" aria-controls="collapseFields">
														{{"InvitationsConfigurationComponent.AvailableFields" | translate}}
													</button>
												</h2>
												<div id="collapseFields" class="accordion-collapse collapse" aria-labelledby="headingAvailableFields" data-bs-parent="#accordionContext">
													<div class="accordion-body">
														<app-context *ngIf="invitation.schema?.properties && currentUser" [schema]="invitation.schema.properties" [currentUser]="currentUser"></app-context>
													</div>
												</div>
											</div>
											<div class="accordion-item">
												<h2 class="accordion-header" id="headingAvailableLinks">
													<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLinks" aria-expanded="false" aria-controls="collapseLinks">
														{{"InvitationsConfigurationComponent.AvailableLinks" | translate}}
													</button>
												</h2>
												<div id="collapseLinks" class="accordion-collapse collapse" aria-labelledby="headingAvailableLinks" data-bs-parent="#accordionContext">
													<div class="accordion-body">
														<div *ngFor="let link of links">- {{link}} : {{link+" description" | translate}}</div>                                                
													</div>
												</div>
											</div>
										</div>
										<div class="row mt-3">
											<div class="col-12">
												<textarea class="textarea-tpl" [(ngModel)]="invitation.content"></textarea>
											</div>
										</div>
									</div>
									<div class="col-6">
										<div *ngIf="preview && !loading">
											<textarea class="tpl-custom-textarea" [readonly]="true" [(ngModel)]="preview"></textarea>
										</div>
									</div>
								</div>
							</ng-container>
							<div class="row h-100 d-flex justify-content-center align-items-center" *ngIf="loading">
								<div class="col-auto my-auto">
										<app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="{{'InvitationsConfigurationComponent.OutlookInvit' | translate}}">
							<div class="row align-items-center">
								<div class="col-auto">
									{{"InvitationsConfigurationComponent.Invitations" | translate}}
								</div>
								<div class="col">
									<app-input-dropdown-field [defaultText]="'InvitationsConfigurationComponent.Invitations'" [modelKey]="'value'" [displayKey]="'label'" [dataSource]="transformValueArrays(outlookInvitations)" (selectedData)="renderInvitation($event.value)"></app-input-dropdown-field>
								</div>
								<div class="col-auto">
									{{"InvitationsConfigurationComponent.Language" | translate}}
								</div>
								<div class="col">
									<app-input-dropdown-field [defaultText]="'InvitationsConfigurationComponent.Language'" [modelKey]="'value'" [displayKey]="'label'" [dataSource]="transformValueArrays(langs)" (selectedData)="switchLang($event.value)"></app-input-dropdown-field>
								</div>
							</div>
							<ng-container *ngIf="!invitation && !loading && selectedInvitation">
								<div class="row mt-3">
									<div class="col-12">
										<div class="col-12 align-right">
											<button class="btn btn-sm btn-app app-secondary margin-left-20 margin-right-20" (click)="confirmDeleteInvitation(selectedInvitation);">{{"InvitationsConfigurationComponent.ResetToDefault" | translate}}</button>
										</div>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="invitation && !loading">
								<div class="row mt-3">
									<div class="col-12">
										<div class="col-12">
											<button class="btn btn-sm btn-app app-primary float-end ms-3" *ngIf="!rendering" (click)="previewInvitation(selectedInvitation, invitation);">{{"InvitationsConfigurationComponent.Preview" | translate}}</button>
											<button class="btn btn-sm btn-app app-primary float-end ms-3" *ngIf="!rendering" (click)="confirmEditInvitation(selectedInvitation, invitation);">{{"InvitationsConfigurationComponent.Save" | translate}}</button>
											<button class="btn btn-sm btn-app app-secondary float-start me-3" *ngIf="!rendering" (click)="renderInvitation(selectedInvitation);">{{"InvitationsConfigurationComponent.BackToRender" | translate}}</button>
											<button class="btn btn-sm btn-app app-secondary float-start me-3" (click)="confirmDeleteInvitation(selectedInvitation);">{{"InvitationsConfigurationComponent.ResetToDefault" | translate}}</button>
											<button class="btn btn-sm btn-app app-primary float-end ms-3" *ngIf="rendering" (click)="getInvitation(selectedInvitation);">{{"InvitationsConfigurationComponent.Edit" | translate}}</button>
										</div>
									</div>
								</div>
								<div class="row mt-3" *ngIf="rendering">
									<div class="col-12">
										<iframe [src]="renderUrl" id="renderFrame"></iframe>
									</div>
								</div>
								<div class="row mt-3" *ngIf="!rendering">
									<div class="col-6">
										<div class="accordion" id="accordionContext">
											<div class="accordion-item">
												<h2 class="accordion-header" id="headingAvailableFields">
													<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFields" aria-expanded="false" aria-controls="collapseFields">
														{{"InvitationsConfigurationComponent.AvailableFields" | translate}}
													</button>
												</h2>
												<div id="collapseFields" class="accordion-collapse collapse" aria-labelledby="headingAvailableFields" data-bs-parent="#accordionContext">
													<div class="accordion-body">
														<app-context *ngIf="invitation.schema?.properties && currentUser" [schema]="invitation.schema.properties" [currentUser]="currentUser"></app-context>
													</div>
												</div>
											</div>
											<div class="accordion-item">
												<h2 class="accordion-header" id="headingAvailableLinks">
													<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLinks" aria-expanded="false" aria-controls="collapseLinks">
														{{"InvitationsConfigurationComponent.AvailableLinks" | translate}}
													</button>
												</h2>
												<div id="collapseLinks" class="accordion-collapse collapse" aria-labelledby="headingAvailableLinks" data-bs-parent="#accordionContext">
													<div class="accordion-body">
														<div *ngFor="let link of links">- {{link}} : {{link+" description" | translate}}</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row mt-3">
											<div class="col-12">
												<textarea class="textarea-tpl" name="invitation" placeholder='{{"mail tpl" | translate}}' [(ngModel)]="invitation.content"></textarea>
											</div>
										</div>
									</div>
									<div class="col-6">
										<ng-container *ngIf="preview && !loading">
											<iframe [src]="previewUrl" id="previewFrame"></iframe>
										</ng-container>
									</div>
								</div>
							</ng-container>
							<div class="row h-100 d-flex justify-content-center align-items-center" *ngIf="loading">
								<div class="col-auto my-auto">
										<app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>      
				</app-admin-card>
			</div>
		</div>
	</div>
</div>


