import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { DatesManager } from 'src/app/services/dates/dates.manager';
import { ActivatedRoute } from '@angular/router';
import { DataApiOptionService } from '../services/data-api-option/data-api-option.service';
import { Endpoint } from './endpoint.model';
import { EndpointStatus, SipAccount } from './endpoint.model';
import { CustomHttpParamEncoderService } from '../services/http-encoder/custom-http-param-encoder.service';

@Injectable()
export class EndpointsService {
  tenantId: Observable<string> = of('');

  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService,
    private route: ActivatedRoute,
    private datesManager: DatesManager
  ) {
    this.tenantId = <Observable<string>>(
      this.route.parent?.parent?.paramMap.pipe(map((params) => params.get('tenantId')))
    );
  }

  getEndpoint(meetingRoomId: string): Observable<Endpoint> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.get(
              apiUri + '/meetingRooms/' + meetingRoomId + '/endpoint',
              {
                headers: this.optionService.getHeaders(),
                withCredentials: true,
              }
            );
          }),
          map((result) => new Endpoint(result)),
          catchError(this.handleError<Endpoint>('getEndpoint'))
        );
      })
    );
  }

  create(meetingRoomId: string, endpoint: Endpoint): Observable<Endpoint> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .post<Endpoint>(
                apiUri + '/meetingRooms/' + meetingRoomId + '/endpoint',
                endpoint,
                {
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => result),
                catchError(this.handleError<Endpoint>('create'))
              );
          })
        );
      })
    );
  }

  save(meetingRoomId: string, endpoint: Endpoint): Observable<Endpoint> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .put<Endpoint>(
                apiUri + '/meetingRooms/' + meetingRoomId + '/endpoint',
                endpoint,
                {
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => new Endpoint(result)),
                catchError(this.handleError<Endpoint>('save'))
              );
          })
        );
      })
    );
  }

  delete(meetingRoomId: string): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .delete(apiUri + '/meetingRooms/' + meetingRoomId + '/endpoint', {
                headers: this.optionService.getHeaders(),
                withCredentials: true,
              })
              .pipe(
                map((result) => result),
                catchError(this.handleError('delete'))
              );
          })
        );
      })
    );
  }

  resetEndpointPassword(meetingRoomId: string): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .post(
                apiUri +
                '/meetingRooms/' +
                meetingRoomId +
                '/endpoint/resetPassword',
                null,
                {
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => result),
                catchError(this.handleError('resetEndpointPassword'))
              );
          })
        );
      })
    );
  }

  getEndpointStatus(meetingRoomId: string): Observable<EndpointStatus> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .get<EndpointStatus>(
                apiUri + '/meetingRooms/' + meetingRoomId + '/endpoint/status',
                {
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => new EndpointStatus(result)),
                catchError(
                  this.handleError<EndpointStatus>('getEndpointStatus')
                )
              );
          })
        );
      })
    );
  }

  getEndpointStatuses(
    meetingRoomId: string,
    startDate?: Date,
    endDate?: Date
  ): Observable<EndpointStatus[]> {
    let params: any;
    if (startDate && endDate) {
      const gt = new Date(new Date(startDate).getTime()).toISOString();
      const lt = new Date(new Date(endDate).getTime()).toISOString();
      const filter = {
        $and: [
          {
            when: {
              $lte: {
                $date: lt,
              },
              $gte: {
                $date: gt,
              },
            },
          },
          {
            $or: [
              {
                error: {
                  $exists: true,
                  $ne: '',
                },
              },
              {
                $expr: {
                  $gt: [
                    {
                      $size: {
                        $ifNull: [
                          {
                            $objectToArray: '$items',
                          },
                          [],
                        ],
                      },
                    },
                    0,
                  ],
                },
              },
            ],
          },
        ],
      };
      params = new HttpParams({
        encoder: new CustomHttpParamEncoderService(),
      }).set('q', JSON.stringify(filter));
    }
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .get<EndpointStatus[]>(
                apiUri +
                '/meetingRooms/' +
                meetingRoomId +
                '/endpoint/statuses',
                {
                  params: params,
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => {
                  const importableMeetingRooms = [];
                  for (const u in result) {
                    importableMeetingRooms.push(<EndpointStatus>result[u]);
                  }
                  return importableMeetingRooms;
                }),
                tap((result) => result),
                catchError(
                  this.handleError<EndpointStatus[]>('getEndpointStatuses', [])
                )
              );
          })
        );
      })
    );
  }

  sendEndpointInfos(meetingRoomId: string): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .post(
                apiUri +
                '/meetingRooms/' +
                meetingRoomId +
                '/endpoint/sendInfos',
                null,
                {
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => result),
                catchError(this.handleError('sendEndpointInfos', []))
              );
          })
        );
      })
    );
  }

  /** SIP ACCOUNT */
  addSipAccount(
    meetingRoomId: string,
    sipAccount?: SipAccount
  ): Observable<SipAccount> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .post<SipAccount>(
                apiUri +
                '/meetingRooms/' +
                meetingRoomId +
                '/endpoint/sipAccount',
                sipAccount,
                {
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => result),
                catchError(this.handleError<SipAccount>('addSipAccount'))
              );
          })
        );
      })
    );
  }

  deleteSipAccount(meetingRoomId: string): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .delete<unknown>(
                apiUri +
                '/meetingRooms/' +
                meetingRoomId +
                '/endpoint/sipAccount',
                {
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => result),
                catchError(this.handleError<unknown>('deleteSipAccount'))
              );
          })
        );
      })
    );
  }

  resetSipAccountPassword(meetingRoomId: string): Observable<SipAccount> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http
              .post<SipAccount>(
                apiUri +
                '/meetingRooms/' +
                meetingRoomId +
                '/endpoint/sipAccount/resetPassword',
                null,
                {
                  headers: this.optionService.getHeaders(),
                  withCredentials: true,
                }
              )
              .pipe(
                map((result) => result),
                catchError(
                  this.handleError<SipAccount>('resetSipAccountPassword')
                )
              );
          })
        );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
