import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-checkbox-field',
  templateUrl: './checkbox-field.component.html',
  styleUrls: ['./checkbox-field.component.scss'],
})
export class CheckboxFieldComponent {
  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() textLabel = '';
  @Input() nameInput = '';
  @Input() disabled = false;
  @Input() checked: boolean | null = false;
  @Input() inverted = false;
  @Input() myInputModel!: unknown;
  @Output() checkedEvent = new EventEmitter<boolean>();

  changeCheckbox(ev: Event): void {
    const ch = ev.target as HTMLInputElement;
    this.myInputModel = ch.checked;
    this.checkedEvent.emit(ch.checked);
  }
}
