import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from 'src/app/services/http-encoder/custom-http-param-encoder.service';
import { Tenant } from 'src/app/tenant/tenant.model';

@Injectable()
export class SidebarService {
  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService
  ) { }

  getOrderedTenants(
    sort: string,
    order: string,
    active: boolean
  ): Observable<Tenant[]> {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoderService(),
    })
      .set('sort', sort)
      .set('order', order)
      .set('active', active);
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http.get<Tenant[]>(apiUri + '/tenants', {
          params: params,
          headers: this.optionService.getHeaders(),
          observe: 'body',
          withCredentials: true,
        });
      }),
      map((result) => {
        const tenants = [];
        for (const u in result) {
          tenants.push(new Tenant(result[u]));
        }
        return tenants;
      }),
      tap((result) => result),
      catchError(this.handleError<Tenant[]>('getOrderedTenants', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
