<div class="dropdown">
    <button *ngIf="!dataSource || (dataSource && dataSource.length === 0)" class="btn btn-secondary dropdown-toggle truncate" type="button" disabled>
        <span>{{"none" | translate}}</span>
        <fa-icon class="arrow" [icon]="['fas', 'angle-down']"></fa-icon>
    </button>
    <button
        *ngIf="dataSource && dataSource.length > 0"
        class="btn btn-secondary dropdown-toggle truncate"
        type="button"
        id="{{modelKey}}"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        [disabled]="disabled"
        (click)="switchIcon()"
    >
        <span *ngFor="let data of dataSource">
            <div class="truncate" *ngIf="selectedElement === data[modelKey]">{{data[displayKey] | translate}}</div>
        </span>
        <span *ngIf="selectedElement === null || selectedElement === undefined">{{defaultText | translate}}</span>
        <fa-icon class="arrow" [icon]="['fas', 'angle-down']" [ngClass]="{active:showIcon, inactive:!showIcon && firstUse}"></fa-icon>
    </button>
    <ul id="ul-{{modelKey}}" class="dropdown-menu dropdown-menu-lg-start w-100 scrollable" aria-labelledby="dropdownMenuButton">
        <li *ngFor="let data of dataSource">
            <button [ngClass]="{'selected': selectedElement === data[modelKey]}" class="dropdown-item" type="button" (click)="selectData(data)">{{data[displayKey] | translate}}</button>
        </li>
    </ul>
</div>
