import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Endpoint, EndpointConfigSchemes } from 'src/app/endpoints/endpoint.model';
import { NgForm } from '@angular/forms';
import { EndpointsService } from 'src/app/endpoints/endpoints.service';
import { MeetingRoom } from 'src/app/meeting-rooms/meeting-room.model';
import { NotifService } from 'src/app/services/notifs/notif.service';

@Component({
  selector: 'app-edit-endpoint',
  templateUrl: './edit-endpoint.component.html',
  styleUrls: ['./edit-endpoint.component.scss']
})
export class EditEndpointComponent {
  @Input() currentEndpoint: Endpoint;
  @Input() currentMeetingRoom: MeetingRoom;
  @Output() cancelEndpointEdit: EventEmitter<boolean> = new EventEmitter();
  finished = false;
  hasError = false;
  endpointConfigSchemes = EndpointConfigSchemes;

  constructor(private endpointService: EndpointsService, private notifService: NotifService) { }

  editEndpoint(editForm: NgForm): void {
    if (editForm.valid) {
      this.endpointService.save(this.currentMeetingRoom.id, this.currentEndpoint).subscribe({
        next: () => {
          this.finished = true;
          this.hasError = false;
          this.notifService.addSuccessNotif('EditEndpoint.SuccessEditEndpoint');
          this.cancelEndpointEdit.emit(false);
        },
        error: (error) => {
          this.finished = true;
          this.hasError = true;
          this.notifService.addErrorNotif('EditEndpoint.ErrorEditEndpoint', error);
        }
      });
    } else {
      this.hasError = true;
    }
  }

}
