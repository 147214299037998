import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '../config/app.config.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class DataApiOptionService {
  private apiUri: Observable<string> = of('');
  private authUri: Observable<string> = of('');

  constructor(private configUi: AppConfigService) {
    this.apiUri = this.configUi.load().pipe(map((config) => config.API_URI));
    this.authUri = this.configUi.load().pipe(map((config) => config.AUTH_URI));
  }

  private commonHeader = new HttpHeaders({
    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    Pragma: 'no-cache',
    Expires: '0',
  });

  private jsonHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  private textHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  });
  private textHtmlHeaders = new HttpHeaders({ 'Content-Type': 'text/html' });
  private jsonHeadersMultiTenant = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Multi-Tenant': 'true',
  });
  private csvHeaders = new HttpHeaders({ 'Content-Type': 'text/csv' });
  private exportCsvHeaders = new HttpHeaders({
    'Content-Type': 'text/csv',
    Accept: 'text/csv',
  });

  getApiUrl(tenantId?: string): Observable<string> {
    if (tenantId) {
      return this.apiUri.pipe(map((apiUri) => apiUri + '/tenants/' + tenantId));
    } else {
      return this.apiUri;
    }
  }

  getApiUrlString(tenantId?: string): string {
    if (tenantId) {
      return this.configUi.settings.API_URI + '/tenants/' + tenantId;
    } else {
      return this.configUi.settings.API_URI;
    }
  }

  getAuthUrl(): Observable<string> {
    return this.authUri;
  }

  getHeaders(): HttpHeaders {
    const headers = this.jsonHeaders;
    return headers;
  }

  getTextHeaders(): HttpHeaders {
    const headers = this.textHeaders;
    return headers;
  }

  getTextHtmlHeaders(): HttpHeaders {
    const headers = this.textHtmlHeaders;
    return headers;
  }

  getHeadersCsv(): HttpHeaders {
    const headers = this.csvHeaders;
    return headers;
  }

  getHeadersExportCsv(): HttpHeaders {
    const headers = this.exportCsvHeaders;
    return headers;
  }

  getHeadersImg(fileType: string): HttpHeaders {
    const headers = new HttpHeaders({ 'Content-Type': fileType });
    return headers;
  }

  getHeadersMailTpl(kind: string): HttpHeaders {
    let headers;
    if (kind === 'text') {
      headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
    } else if (kind === 'html') {
      headers = new HttpHeaders({ 'Content-Type': 'text/html' });
    } else {
      headers = this.jsonHeaders;
    }
    return headers;
  }

  getHeadersMultiTenant(): HttpHeaders {
    const headers = this.jsonHeadersMultiTenant;
    return headers;
  }
}
