<div class="user-menu">
    <ng-container *ngIf="!connectedUser.avatar || (connectedUser.avatar && connectedUser.avatar.id === '')">
        <fa-icon id="userMenuIcon" class="pointer menu-icon" [icon]="['fas', 'user']" (click)="displayMenu()"></fa-icon>
    </ng-container>
    <ng-container *ngIf="connectedUser.avatar && connectedUser.avatar.id !== ''">
        <fa-icon id="circleMenuIcon" class="pointer menu-icon" [icon]="['fas', 'circle']"></fa-icon>
        <img class="menu-icon pointer circle-avatar" (click)="displayMenu()" [src]="api_uri + '/tenants/' + connectedUser.tenantId + '/pictures/' + connectedUser.avatar.id"/>
    </ng-container>
    <div class="menu" *ngIf="display" [@enterAnimation]>
        <span class="triangle-header-menu"></span>
        <p class="name">{{connectedUser.getDisplayName()}}</p>
        <p class="pointer" (click)="navigateTo('profil')" data-cy="user-menu-profil-button">{{"UserMenuComponent.Profile"|translate}}</p>
        <p class="raspberry-line"></p>
        <p class="pointer" (click)="navigateTo('contacts')" data-cy="user-menu-contacts-button">{{"UserMenuComponent.Contacts"|translate}}</p>
        <p class="pointer" *ngIf="hasOneOffer()" (click)="navigateTo('conference')" data-cy="user-menu-conference-info-button">{{"UserMenuComponent.Infos"|translate}}</p>
        <a *ngIf="!connectedUser.isImpersonated" class="btn btn-app app-primary w-100" href="{{logOutUri}}">{{"UserMenuComponent.Logout"|translate}}</a>
        <a *ngIf="connectedUser.isImpersonated" class="btn btn-app app-primary w-100" (click)="deactivateImpersonateMode()">{{"UserMenuComponent.Leave"|translate}}</a>
    </div>
</div>
