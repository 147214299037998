import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { MeetingRoom } from '../meeting-room.model';
import { MeetingRoomsService } from '../meeting-rooms.service';

@Component({
  selector: 'app-add-meeting-room',
  templateUrl: './add-meeting-room.component.html',
  styleUrls: ['./add-meeting-room.component.scss']
})
export class AddMeetingRoomComponent {

  newMeetingRoom = new MeetingRoom({});
  finished = false;
  hasError = false;

  constructor(private route: ActivatedRoute, private router: Router, private meetingRoomsService: MeetingRoomsService, private notifService: NotifService) { }

  confirmAddMeetingRoom(addMeetingRoomForm: NgForm, addMeetingRoomFormData: MeetingRoom): void {
    if (addMeetingRoomForm.valid) {
      this.hasError = false;
      this.meetingRoomsService.addMeetingRoom(addMeetingRoomFormData).subscribe({
        next: result => {
          this.finished = true;
          this.notifService.addSuccessNotif('AddMeetingRoom.SuccessAddMeetingRoom');
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        error: error => {
          this.finished = true;
          this.hasError = true;
          this.notifService.addErrorNotif('AddMeetingRoom.ErrorAddMeetingRoom', error);
        }
      })
    } else {
      this.hasError = true;
    }
  }

}
