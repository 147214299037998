import { escapeCharacters } from 'src/app/services/escape-characters/escape-characters';

export class DateGreaterOrEqual {
  constructor(private date: Date) { }
  serialize(): Gte {
    return {
      $gte: {
        $date: this.date.toISOString(),
      },
    };
  }
}
export const dateGreaterOrEqual = (date: Date): DateGreaterOrEqual => new DateGreaterOrEqual(date);

export class DateLowerOrEqual {
  constructor(private date: Date) { }
  serialize(): Lte {
    return {
      $lte: {
        $date: this.date.toISOString(),
      },
    };
  }
}
export const dateLowerOrEqual = (date: Date): DateLowerOrEqual => new DateLowerOrEqual(date);

export class DateBetween {
  constructor(private startDate: Date, private endDate: Date) { }
  serialize(): Btwn {
    return {
      $gte: {
        $date: this.startDate.toISOString(),
      },
      $lte: {
        $date: this.endDate.toISOString(),
      },
    };
  }
}
export const dateBetween = (startDate: Date, endDate: Date): DateBetween => new DateBetween(startDate, endDate);

export class SuggestPattern {
  constructor(private suggest: string) { }
  serialize(): SuggestPtrn {
    return {
      $regularExpression: {
        pattern: escapeCharacters(this.suggest),
        options: 'im',
      },
    };
  }
}
export const suggestPattern = (suggest: string): SuggestPattern => new SuggestPattern(suggest);

export class NotEqual {
  constructor(private value: any) { }
  serialize() {
    return {
      $ne: this.value,
    };
  }
}

export const notEqual = (value: any): NotEqual => new NotEqual(value);

export class Equal {
  constructor(private value: any) { }
  serialize() {
    return this.value;
  }
}

export const equal = (value: any): Equal => new Equal(value);

interface Gte {
  $gte: {
    $date: string;
  };
}

interface Lte {
  $lte: {
    $date: string;
  };
}

interface Btwn {
  $gte: {
    $date: string;
  };
  $lte: {
    $date: string;
  };
}

interface SuggestPtrn {
  $regularExpression: {
    pattern: string;
    options: string;
  };
}
