import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
  pure: false,
})
export class FilterBy implements PipeTransform {
  value: string[] = [];

  transform(input: any, args?: any): any {
    if (!input) {
      return null;
    }

    if (!args) {
      return input;
    }

    if (args === 'undefined') {
      return input;
    }

    return input.filter((item: any) => item.tenantId.indexOf(args) !== -1);
  }
}
