import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from 'src/app/services/http-encoder/custom-http-param-encoder.service';
import { User } from 'src/app/users/user.model';

@Injectable()
export class MasqueradeMenuService {
  constructor(
    private http: HttpClient,
    private optionService: DataApiOptionService
  ) { }

  getSuggest(
    tenantId: string,
    suggest: string,
    limit: number
  ): Observable<User[]> {
    const filter = {
      suggests: {
        $regularExpression: {
          pattern: suggest,
          options: 'im',
        },
      },
    };
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoderService(),
    })
      .set('q', JSON.stringify(filter))
      .set('limit', limit);
    return this.optionService.getApiUrl(tenantId).pipe(
      switchMap((apiUri) => {
        return this.http
          .get<User[]>(apiUri + '/users', {
            params: params,
            headers: this.optionService.getHeaders(),
            withCredentials: true,
            observe: 'body',
          })
          .pipe(
            map((result) => {
              const users = [];
              for (const u of result) {
                const user = new User(u);
                users.push(user);
              }
              return users;
            })
          );
      })
    );
  }

  activeImpersonate(user: User): Observable<unknown> {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoderService(),
    }).set('userId', user.id);
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http
          .post(apiUri + '/impersonate', null, {
            params: params,
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          })
          .pipe(map((result) => result));
      })
    );
  }

  deactiveImpersonate(): Observable<unknown> {
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http
          .delete(apiUri + '/impersonate', {
            headers: this.optionService.getHeaders(),
            observe: 'body',
            withCredentials: true,
          })
          .pipe(map((result) => result));
      })
    );
  }
}
