import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DisplayColumn } from '../commons/mat-table-custom/mat-table-custom.model';
import { LiveConference } from './live-conference.model';
import { LiveConferenceService } from './live-conferences.service';

@Component({
	selector: 'app-live-conferences',
	templateUrl: './live-conferences.component.html',
	styleUrls: ['./live-conferences.component.scss'],
	providers: [LiveConferenceService]
})
export class LiveConferencesComponent implements OnInit, OnDestroy {
	
	liveConferences: MatTableDataSource<LiveConference> = new MatTableDataSource();
	onInitSubscriptions: Subscription[] = [];
	tenantId: string | null;
	displayedColumns: DisplayColumn[];
	defaultSort = "name";
	loading = false;
	selectedLiveConferenceId: string;
	
	constructor(private route: ActivatedRoute, private router: Router, public liveConferenceService: LiveConferenceService) { }
	
	ngOnInit(): void {
		this.displayedColumns = [
			{ name: "name", type: "any" },
			{ name: "serviceType", type: "any" },
			{ name: "duration", type: "any" },
			{ name: "tag.alias", type: "any" },
			{ name: "tag.offerType", type: "any"},
			{ name: "participantCount", type: "any" }
		];
		if(this.route.parent) {
			this.onInitSubscriptions.push(this.route.parent.paramMap.subscribe((params) => {
				this.tenantId = params.get('tenantId');
			}));
		}
		this.getLiveConferences();
		if(this.route.firstChild){
			this.onInitSubscriptions.push(this.route.firstChild.params.subscribe(params => {
				if(params['conferenceId']){
					this.selectedLiveConferenceId = params['conferenceId'];
				}
			}));
		}
	}
	
	onDataError(error: any): void{
		console.log(error);
	}
	
	onRowClick(data: LiveConference): void {
		this.selectedLiveConferenceId = data.id;
		let link = ["/administration/"+ this.tenantId + "/live-conferences/"+data.id]
		this.router.navigate(link);
	}
	
	getLiveConferences(): void {
		this.loading = true;
		this.liveConferenceService.getLiveConferences().subscribe((liveConferences: LiveConference[]) => {
			this.liveConferences = new MatTableDataSource(liveConferences);
			this.loading = false;
		})
	}
	
	ngOnDestroy(): void {
		this.onInitSubscriptions.forEach(subscription => {
			subscription.unsubscribe();
		})
	}
	
}
