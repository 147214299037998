<app-admin-bloc [blocTitle]="'Add'">
  <form #addOauthClient="ngForm" id="addOauthClient" name="addOauthClient">
    <app-input-form-field [nameInput]="'name'" [typeInput]="'text'" [myInputModel]="newOauthClient.name" [textLabel]="'AddOauthClientComponent.Name'" [form]="addOauthClient" class="form-control">
    </app-input-form-field>
    <app-input-form-field [nameInput]="'description'" [typeInput]="'text'" [myInputModel]="newOauthClient.description" [textLabel]="'AddOauthClientComponent.Description'" [form]="addOauthClient" class="form-control">
    </app-input-form-field>
    <app-input-form-field [nameInput]="'secret'" [typeInput]="'text'" [myInputModel]="newOauthClient.secret" [textLabel]="'AddOauthClientComponent.Secret'" [form]="addOauthClient" class="form-control" [required]="true">
    </app-input-form-field>
    <app-input-dropdown-form-field *ngIf="tenants" [nameInput]="'target'" [defaultText]="'AddOauthClientComponent.TargetTenant'"
    [modelKey]="'id'" [displayKey]="'name'"
    [myInputModel]="newOauthClient.target" [dataSource]="tenants" [form]="addOauthClient" (selectedData)="selectTenant($event)"
    class="form-control" [required]="true"></app-input-dropdown-form-field>
    <ng-container *ngIf="newOauthClient.target">
      <div class="row">
        <div class="col-4">
          <button class="btn btn-app app-primary w-100 margin-bottom-20" type="button" (click)="swicthAllReadAccess()">{{"AddOauthClientComponent.AllReadAccess" | translate }}</button>
        </div>
        <div class="col-4">
          <button class="btn btn-app app-primary w-100 margin-bottom-20" type="button" (click)="swicthAllReadWriteAccess()">{{"AddOauthClientComponent.AllReadWriteAccess" | translate }}</button>
        </div>
        <div class="col-4">
          <button class="btn btn-app app-secondary w-100 margin-bottom-20" type="button" (click)="removeAllAccesses()">{{"AddOauthClientComponent.RemoveAllAccess" | translate }}</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12" *ngFor="let access of accessList; let i = index;">
          <div class="row">
            <div [ngClass]="{'col-12': !clientAccesses.includes(access+'/Read') && !clientAccesses.includes(access+'/ReadWrite'), 'col-6': clientAccesses.includes(access+'/Read') || clientAccesses.includes(access+'/ReadWrite')}">
              <app-checkbox-field class="main-access" [textLabel]="'AddOauthClientComponent.'+access" [nameInput]="access" [myInputModel]="isCheckedAccess(access+'/Read') || isCheckedAccess(access+'/ReadWrite')" [checked]="isCheckedAccess(access+'/Read') || isCheckedAccess(access+'/ReadWrite')" (checkedEvent)="switchAccess($event, access)"></app-checkbox-field>
            </div>
            <div class="col-6" *ngIf="clientAccesses.includes(access+'/Read') || clientAccesses.includes(access+'/ReadWrite')">
              <app-checkbox-field [textLabel]="'AddOauthClientComponent.Read'" [nameInput]="access+'/Read'" [myInputModel]="isCheckedAccess(access+'/Read')" [checked]="isCheckedAccess(access+'/Read')" (checkedEvent)="switchReadAccess($event, access)" [disabled]="isCheckedAccess(access+'/Read')"></app-checkbox-field>
              <app-checkbox-field [textLabel]="'AddOauthClientComponent.ReadWrite'" [nameInput]="access+'/ReadWrite'" [myInputModel]="isCheckedAccess(access+'/ReadWrite')" [checked]="isCheckedAccess(access+'/ReadWrite')" (checkedEvent)="switchReadWriteAccess($event, access)" [disabled]="isCheckedAccess(access+'/ReadWrite')"></app-checkbox-field>
            </div>
          </div>
          <hr class="my-1" *ngIf="i !== 14 && i !== 15"/>
        </div>
      </div>
    </ng-container>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary w-100" type="reset" [routerLink]="['../']">{{"AddOauthClientComponent.Cancel" | translate }}</button>
      <app-loading-button (submitEvent)="confirmAddOauthClient(addOauthClient)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'AddOauthClientComponent.Add'" [finalText]="'AddOauthClientComponent.Added'">
      </app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="addOauthClient.submitted && !addOauthClient.valid">
      {{'AddOauthClientComponent.SomeFields' | translate}}</div>
  </form>
</app-admin-bloc>