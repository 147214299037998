import { Component, OnInit, Input } from '@angular/core';
import { MeetingRoom } from 'src/app/meeting-rooms/meeting-room.model';
import { EndpointStatus } from 'src/app/endpoints/endpoint.model';
import { EndpointsService } from 'src/app/endpoints/endpoints.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Observable } from 'rxjs'
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { KloodDateFormat } from 'src/app/services/dates/dates.format.service';

@Component({
  selector: 'app-endpoint-status',
  templateUrl: './endpoint-status.component.html',
  styleUrls: ['./endpoint-status.component.scss'],
  providers: [EndpointsService]
})
export class EndpointStatusComponent implements OnInit {
  @Input() currentMeetingRoom: MeetingRoom; 
  from = new Date();
  to = new Date();
  currentConnectedUser$: Observable<ConnectedUser>;
  endpointStatuses$: Observable<EndpointStatus[]>;
  kloodDateFormat = KloodDateFormat;
  constructor(private endpointService: EndpointsService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.from = new Date(this.from.setTime(this.to.getTime() - 1000 * 60 * 2));
    this.from = new Date(this.from.setSeconds(0));
    this.to = new Date(this.to.setSeconds(59));
    this.getEndpointStatuses();
    this.currentConnectedUser$ = this.sharedService.currentConnectedUser;
  }

  getEndpointStatuses(): void {
    this.endpointStatuses$ = this.endpointService.getEndpointStatuses(this.currentMeetingRoom.id, this.from, this.to);
  }

  getEndpointStatusError(endpointStatus: EndpointStatus): string {
    if (endpointStatus.error) {
      return "is-red";
    } else {
      if (endpointStatus.items) {
        for (const i in endpointStatus.items) {
          if (endpointStatus.items[i].error) {
            return "is-orange";
          } 
          return "is-green";
        }
      } 
      return "is-green";
    }
  }

  onFromChange(event: Date): void {
    this.from = event;
    this.getEndpointStatuses();
  }

  onToChange(event: Date): void {
    this.to = event;
    this.getEndpointStatuses();
  }

}
