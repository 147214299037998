<app-admin-bloc *ngIf="currentOauthClient" [blocTitle]="'Details'" (requestAction)="onRequestAction($event)" [blocControls]="controls">
  <div class="row align-items-center">
    <div class="col-auto is-bold">
      {{"DetailsOauthClientComponent.IdClient" | translate}} :
    </div>
    <div class="col-auto ps-0" *ngIf="currentOauthClient.id">{{currentOauthClient.id}}</div>
  </div>
  <div class="row align-items-center"> 
    <div class="col-auto is-bold">
      {{"DetailsOauthClientComponent.Name" | translate}} :
    </div>
    <div class="col ps-0">{{currentOauthClient.name}}</div>
  </div>
  <div class="row align-items-center">
    <div class="col-auto is-bold">
      {{"DetailsOauthClientComponent.Description" | translate}} : 
    </div>
    <div class="col ps-0">{{currentOauthClient.description}}</div>
  </div>
  <div class="row align-items-center">
    <div class="col-auto is-bold">
      {{"DetailsOauthClientComponent.Secret" | translate}} : 
    </div>
    <div class="col ps-0">{{currentOauthClient.secret}}</div>
  </div>
  <div class="row align-items-center">
    <div class="col-auto is-bold">
      {{"DetailsOauthClientComponent.Scopes" | translate}} : 
    </div>
    <div class="col ps-0">
      <div class="accordion stacked mt-2" id="accordionScopes">
        <div class="accordion-item" *ngFor="let scope of currentOauthClient.scopes | keyvalue">
          <ng-container *ngIf="scope.key !== '*'">
            <h2 class="accordion-header" id="{{ 'heading' + scope.key }}">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-' + scope.key" aria-expanded="false" [attr.aria-controls]="'collapse-' + scope.key">
                {{scope.key}}
              </button>
            </h2>
            <div id="{{ 'collapse-' + scope.key }}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + scope.key" data-bs-parent="#accordionScopes">
              <div class="accordion-body">
                <div *ngFor="let access of scopesAsArray(scope.value)" class="ms-3">
                  {{access}}
                </div>
                <div *ngIf="scopesAsArray(scope.value).length === 0">{{"DetailsOauthClientComponent.NoAccesses" | translate}}</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="scope.key === '*'">
            <h2 class="accordion-header" id="heading-all">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-all'" aria-expanded="false" [attr.aria-controls]="'collapse-all'">
                {{scope.key}} - {{"DetailsOauthClientComponent.AllTenants" | translate}}
              </button>
            </h2>
            <div id="collapse-all" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading-all'" data-bs-parent="#accordionScopes">
              <div class="accordion-body">
                <div *ngFor="let access of scopesAsArray(scope.value)" class="ms-3">
                  {{access}}
                </div>
                <div *ngIf="scopesAsArray(scope.value).length === 0">{{"DetailsOauthClientComponent.NoAccesses" | translate}}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</app-admin-bloc>
