<app-admin-bloc [blocTitle]="'Add'">
  <form #addEnvironment="ngForm" id="addEnvironment" name="addEnvironment">
    <app-input-form-field [nameInput]="'host'" [typeInput]="'text'" [myInputModel]="newEnvironment.host" [textLabel]="'AddEnvironmentComponent.host'" [form]="addEnvironment" class="form-control" [required]="true">
    </app-input-form-field>
    <app-input-form-field [nameInput]="'pexipDomain'" [typeInput]="'text'" [myInputModel]="newEnvironment.pexipDomain" [textLabel]="'AddEnvironmentComponent.pexipDomain'" [form]="addEnvironment" class="form-control">
    </app-input-form-field>
    <div class="row align-items-center mb-3">
      <div class="col-auto pe-0">
        <app-checkbox-form-field [nameInput]="'public'" [form]="addEnvironment" [checked]="newEnvironment.public" [myInputModel]="newEnvironment.public" (checkedEvent)="newEnvironment.public=$event"></app-checkbox-form-field>
      </div>
      <div class="col ps-0">
        {{"AddEnvironmentComponent.Public" | translate}}
      </div>
    </div>
    <div class="dual-button-container">
      <button class="btn btn-app app-secondary w-100" type="reset" [routerLink]="['../']">{{"AddEnvironmentComponent.Cancel" | translate }}</button>
      <app-loading-button (submitEvent)="confirmAddEnvironment(addEnvironment)" [targetStyle]="'primary'" [finished]="finished" [error]="hasError" [initialText]="'AddEnvironmentComponent.Add'" [finalText]="'AddEnvironmentComponent.Added'">
      </app-loading-button>
    </div>
    <div class="is-third fields-error" *ngIf="addEnvironment.submitted && !addEnvironment.valid">
      {{'AddEnvironmentComponent.SomeFields' | translate}}</div>
  </form>
</app-admin-bloc>