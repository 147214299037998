<div
    data-cy="toast-notif"
    toastContainer
    class="custom_toastr_module"
    [ngClass]="{
        'new-version': checkNewVersion(),
        'full-size': size === 'full',
        'custom-70-size': size === 'custom',
        'meeting-room-size': size === 'meeting-room'
    }"
></div>
