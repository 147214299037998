import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { DisplayColumn, PaginationInfo } from 'src/app/commons/mat-table-custom/mat-table-custom.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { ImportableMeetingRoom, MeetingRoom } from '../meeting-room.model';
import { MeetingRoomsService } from '../meeting-rooms.service';

@Component({
  selector: 'app-import-meeting-rooms',
  templateUrl: './import-meeting-rooms.component.html',
  styleUrls: ['./import-meeting-rooms.component.scss'],
  providers: [MeetingRoomsService]
})
export class ImportMeetingRoomsComponent implements OnInit {

  meetingRoomEmail: string;
  finished = false;
  hasError = false;
  emailImportMeetingRoom = { email: '' };
  importableMeetingRooms: MatTableDataSource<ImportableMeetingRoom> = new MatTableDataSource();
  displayedColumns: DisplayColumn[];
  refreshTable = false;
  defaultSort = 'ref';
  paginationInfo: PaginationInfo;

  constructor(private router: Router, private route: ActivatedRoute, private meetingRoomsService: MeetingRoomsService, private notifService: NotifService) { }

  ngOnInit(): void {
    this.paginationInfo = new PaginationInfo();
    this.getImportableMeetingRooms();
    this.displayedColumns = [
      { name: "ref", type: "any" },
      { name: "name", type: "any" },
      { name: "description", type: "any" },
      { name: "action", type: "action", actions: [{ action: "import", icon: "download" }] }
    ];
  }

  getImportableMeetingRooms(): void {
    this.refreshTable = true;
    combineLatest([this.meetingRoomsService.getAllMeetingRooms(), this.meetingRoomsService.getImportableMeetingRooms()]).subscribe({
      next: (results) => {
        let allMeetingRooms = results[0];
        let availableMeetingRooms = results[1];
        let filteredMeetingRoomsArray = availableMeetingRooms.filter(room => !(availableMeetingRooms.filter(el => {
          return allMeetingRooms.some(f => {
            return f.email === el.ref;
          });
        })).includes(room));
        this.importableMeetingRooms = new MatTableDataSource(filteredMeetingRoomsArray);
        this.refreshTable = false;
      },
      error: (error) => {
        this.refreshTable = false;
        this.notifService.addErrorNotif('ImportMeetingRooms.ErrorGettingImportable', error);
      }
    })
  }

  confirmImportMeetingRoom(data: { email: string }, importMeetingRoomForm?: NgForm): void {
    if (importMeetingRoomForm?.valid) {
      this.hasError = false;
      this.meetingRoomsService.importMeetingRoom(data.email).subscribe({
        next: (meetingRoom: MeetingRoom) => {
          this.finished = true;
          this.notifService.addSuccessNotif('ImportMeetingRooms.SuccessImportMeetingRoom');
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        error: (error) => {
          this.finished = true;
          this.hasError = true;
          this.notifService.addErrorNotif("ImportMeetingRooms.ErrorImportMeetingRoom", error);
        }
      });
    } else {
      this.hasError = true;
    }
  }

  onImportClick(data: any): void {
    this.refreshTable = true;
    this.meetingRoomsService.importMeetingRoom(data.data.ref).subscribe({
      next: (meetingRoom: MeetingRoom) => {
        this.refreshTable = false;
        this.notifService.addSuccessNotif('ImportMeetingRooms.SuccessImportMeetingRoom');
        this.getImportableMeetingRooms();
      },
      error: (error) => {
        this.refreshTable = false;
        this.notifService.addErrorNotif("ImportMeetingRooms.ErrorImportMeetingRoom", error);
      }
    });
  }

}
