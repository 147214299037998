export class Offer {
  count: number | null;
  recordRetention: number | null;
  addedAt: Date;
  expiresAt: Date;
  contact: string;
  coOrganization: boolean;
  disabledAt: Date;
  alternativeDomains: string[];
  accessToken: string;
  idLength: string;
  teamsConnector: string;
  domain: string;
  location: string;
  prefix: string;
  enableLobbyBypass: boolean;
  filterTrustedDevicesOnNetwork: boolean;
  filterTrustedDevicesOnPexipLocation: boolean;
  filterTrustedDevicesOnPexipRegistration: boolean;
  networks: Network[];
  otherConnectionModeUrlTemplate: string;
  isExpired: boolean;
  expiresIn: number;

  constructor(obj: any) {
    this.count = null;
    this.recordRetention = null;
    this.networks = new Array<Network>();
    if (obj?.addedAt) {
      obj.addedAt = new Date(obj.addedAt);
    }
    if (obj?.expiresAt) {
      obj.expiresAt = new Date(obj.expiresAt);
    }
    if (obj?.disabledAt) {
      obj.disabledAt = new Date(obj.disabledAt);
    }
    if (!obj?.networks) {
      obj.networks = new Array<Network>();
    }
    if (!obj?.alternativeDomains) {
      obj.alternativeDomains = new Array<string>();
    }
    Object.assign(this, obj);
  }
}

export class Network {
  ip: string;
  mask: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export interface OfferKeyValue {
  key: string;
  value: Offer;
}

export interface AlternativeDomains {
  alternativeDomains: string;
}

export interface RecordRentention {
  value: number;
  label: string;
}

export const OffersType = [
  'ONE',
  'PRO',
  'GATEWAY',
  'RECORDING',
  'ONE_CODEC',
  'HANGOUT_GATEWAY',
  'TEAMS_GATEWAY',
];
export const VirtualRoomOffersType = [
  'ONE',
  'PRO',
  'GATEWAY',
  'ONE_CODEC',
  'HANGOUT_GATEWAY',
  'TEAMS_GATEWAY',
];
export const BookingRoomOffersType = [
  'MEAL',
  'SUPPORT',
  'PARKING',
  'WORKPLACE',
  'MEETING_ROOM',
  'VIRTUAL_ROOM',
  'MAPPING',
  'ISSUES',
];
export const BookingOffersResources = [
  'MEETING_ROOM',
  'PARKING',
  'WORKPLACE',
  'VIRTUAL_ROOM',
];
export const BookingOffersServices = ['MEAL', 'SUPPORT'];
export const OfficialsType = ['MEAL', 'PARKING', 'WORKPLACE', 'MEETING_ROOM'];
