<div class="custom-detail-table-filter">
  <mat-form-field appearance="outline" class="mat-form-field-custom">
    <mat-icon matPrefix>
      <fa-icon [icon]="['fas', 'search']"></fa-icon>
    </mat-icon>
    <input matInput (keyup)="applyFilter($event)" [(ngModel)]="search" placeholder="{{'MatTable.Search' | translate}}" />
  </mat-form-field>
  <div class="border-container" id="custom-detail-table">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="onSort($event)" class="custom-detail-table">
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"HistoryObtpComponent.Time" | translate}}</th>
        <td mat-cell *matCellDef="let obtpHistory" attr.data-th='{{"HistoryObtpComponent.Time" | translate}}' class="small-right pointer">{{obtpHistory.time | date:kloodDateFormat.pipeSeconds[connectedUser.lang]:undefined:kloodDateFormat.locale[connectedUser.lang]}}</td>
      </ng-container>
      <ng-container matColumnDef="origin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"HistoryObtpComponent.Origin" | translate}}</th>
        <td mat-cell *matCellDef="let obtpHistory" attr.data-th='{{"HistoryObtpComponent.Origin" | translate}}' class="small-right pointer">{{obtpHistory.origin}}</td>
      </ng-container>
      <ng-container matColumnDef="meetingRoomId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"HistoryObtpComponent.MeetingRoomId" | translate}}</th>
        <td mat-cell *matCellDef="let obtpHistory" attr.data-th='{{"HistoryObtpComponent.MeetingRoomId" | translate}}' class="small-right pointer">{{obtpHistory.meetingRoomId}}</td>
      </ng-container>
      <ng-container matColumnDef="success">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"HistoryObtpComponent.Success" | translate}}</th>
        <td mat-cell *matCellDef="let obtpHistory" attr.data-th='{{"HistoryObtpComponent.Success" | translate}}' class="small-right pointer">
          <fa-icon [icon]="['fas', 'check']" class="is-valid" *ngIf="obtpHistory.success"></fa-icon>
          <fa-icon [icon]="['fas', 'times']" class="is-invalid" *ngIf="!obtpHistory.success"></fa-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"HistoryObtpComponent.Description" | translate}}</th>
        <td mat-cell *matCellDef="let obtpHistory" attr.data-th='{{"HistoryObtpComponent.Description" | translate}}' class="small-right pointer">{{obtpHistory.description}}</td>
      </ng-container>
      <!-- expanded row -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let obtpHistory" [attr.colspan]="displayedColumns.length" class="custom-detail-td">
          <div class="custom-element-detail"
              [@detailExpand]="obtpHistory === expandedElement ? 'expanded' : 'collapsed'"> <!--obtpHistory === expandedElement ? 'expanded' : 'collapsed'-->      
            <div class="custom-element-description">
              <hr>
              <div class="is-bold">{{"HistoryObtpComponent.MoreDetails" | translate}} :</div>
              <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">
                  {{"HistoryObtpComponent.EndpointEmail" | translate}} : 
                </div>
                <div class="col">
                  {{obtpHistory.endpointEmail}}
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">
                  {{"HistoryObtpComponent.EndpointKind" | translate}} : 
                </div>
                <div class="col">
                  {{obtpHistory.endpointKind}}
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">
                  {{"HistoryObtpComponent.MeetingProvider" | translate}} : 
                </div>
                <div class="col">
                  {{obtpHistory.meetingProvider}}
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">
                  {{"HistoryObtpComponent.CalendarProvider" | translate}} : 
                </div>
                <div class="col">
                  {{obtpHistory.calendarProvider}}
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto is-bold pe-0">
                  {{"HistoryObtpComponent.InjectedUrl" | translate}} : 
                </div>
                <div class="col">
                  {{obtpHistory.injectedUrl}}
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto is-bold pe-0 align-self-start">
                  {{"HistoryObtpComponent.Regexps" | translate}} : 
                </div>
                <div class="col">
                  <div *ngFor="let regexp of obtpHistory.regexps">
                    {{regexp}}
                  </div>
                </div>
              </div>
              <hr>
              <div class="is-bold">{{"HistoryObtpComponent.ActionFlow" | translate}} :</div>
              <table class="custom-table-action-flow" *ngIf="obtpHistory.actionFlow && obtpHistory.actionFlow.length > 0">
                <thead>
                  <tr>
                    <th><div class="custom-table-action-flow-header">{{"HistoryObtpComponent.Kind" | translate}}</div></th>
                    <th><div class="custom-table-action-flow-header">{{"HistoryObtpComponent.Success" | translate}}</div></th>
                    <th><div class="custom-table-action-flow-header">{{"HistoryObtpComponent.Info" | translate}}</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let action of obtpHistory.actionFlow" class="custom-table-action-flow-row">
                    <td>{{action.kind}}</td>
                    <td>
                      <fa-icon [icon]="['fas', 'check']" class="is-valid" *ngIf="action.success"></fa-icon>
                      <fa-icon [icon]="['fas', 'times']" class="is-invalid" *ngIf="!action.success"></fa-icon>
                    </td>
                    <td>{{action.info}}</td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="!obtpHistory.actionFlow || obtpHistory.actionFlow.length === 0">
                {{"HistoryObtpComponent.NoActionFlow" | translate}}
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <!-- end expanded row -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pointer table-hover custom-element-row" [class.custom-expanded-row]="expandedElement === row"
      (click)="expandedElement = expandedElement === row ? null : row"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="custom-detail-row"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">{{"HistoryObtpComponent.NoMatchingFilter" | translate}} "{{search}}"</td>
      </tr>
    </table>
  </div>
  <div *ngIf="dataSource && dataSource.data?.length === 0" class="ms-2 mt-2">
    <i>{{"MatTable.NoAvailableData" | translate}}...</i>
  </div>
  <mat-paginator class="mat-paginator-custom" [length]="listLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons > </mat-paginator>
</div>
<div class="row d-flex justify-content-center align-items-center my-5" *ngIf="!dataSource">
    <div class="col-auto my-auto">
        <app-loading-spinner [diameter]="50" class="app-loader-primay"></app-loading-spinner>
    </div>
</div>
