import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContactService } from 'src/app/contact/contact.service';
import { TranslateService } from '@ngx-translate/core';
import { ValidatorService } from 'src/app/services/validator/validator.service';
import { Attendee } from '../attendee.model';
import { Contact } from 'src/app/contact/contact.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import Debug from 'debug';
const debug = Debug('virtuvisio-administration:attendees-list-component');

@Component({
	selector: 'app-attendees-list',
	templateUrl: './attendees-list.component.html',
	styleUrls: ['./attendees-list.component.scss'],
	providers: [ContactService]
})
export class AttendeesListComponent implements OnInit, OnDestroy {
	@Input() attendeesList: any[];
	@Input() validateMailBeforeValidate: EventEmitter<any>;
	@Input() noChange: boolean;
	@Output() checkEmailDone: EventEmitter<any> = new EventEmitter<any>();
	emailAttendee = '';
	contactsSuggest: any[];
	displaySuggest = false;
	private onInitSubscriptions: Subscription[] = [];

	constructor(private contactService: ContactService, private translateService: TranslateService, private notif: NotifService, private validatorService: ValidatorService) { }

	ngOnInit(): void {
		if (this.validateMailBeforeValidate) {
			this.onInitSubscriptions.push(this.validateMailBeforeValidate.subscribe((data) => {
				this.addAttendeeFromSubmit(data);
			}));
		}
	}

	ngOnDestroy(): void {
		this.onInitSubscriptions.forEach((subscription) => {
			subscription.unsubscribe();
		});
	}

	editAttendee(attendee: Attendee): void {
		for (let i = 0; i < this.attendeesList?.length; i++) {
			if (this.attendeesList[i].email === attendee.email) {
				this.emailAttendee = this.attendeesList[i].email;
				this.attendeesList.splice(i, 1);
			}
		}
	}

	removeAttendee(attendee: Attendee): void {
		for (let i = 0; i < this.attendeesList?.length; i++) {
			if (this.attendeesList[i].email === attendee.email) {
				this.attendeesList.splice(i, 1);
			}
		}
	}

	addAttendee(event: KeyboardEvent | FocusEvent): void {
		const e = event as KeyboardEvent;
		const key = e.key;
		const code = e.code;
		let split;
		if (e.key && e.code) {
			if ((key === 'Enter' && code === 'Enter') || (key === ' ' && code === 'Space') || (key === ',' && code === 'KeyM') || (key === ',' && code === 'Comma') || (key === ';' && code === 'Comma') || (key === ';' && code === 'Semicolon') || e.type === 'blur') {
				if (this.emailAttendee && this.emailAttendee?.length > 0) {
					if (this.emailAttendee.indexOf(',') !== -1) {
						split = ',';
						this.attendeeLoop(this.emailAttendee, split);
					} else if (this.emailAttendee.indexOf(';') !== -1) {
						split = ';';
						this.attendeeLoop(this.emailAttendee, split);
					} else if (this.emailAttendee.indexOf(' ') !== -1) {
						split = ' ';
						this.attendeeLoop(this.emailAttendee, split);
					} else {
						if (this.validatorService.validateEmail(this.emailAttendee)) {
							let attendeeTemp;
							if (this.attendeesList?.length > 0) {
								if (this.attendeesList.some((el) => el.email === this.emailAttendee)) {
									let msg = this.emailAttendee;
									msg += ' ';
									msg += this.translateService.instant('Attendee.AlreadyInList');
									this.notif.addErrorNotif(msg);
								} else {
									attendeeTemp = {
										email: this.emailAttendee,
									};
									this.attendeesList.push(attendeeTemp);
								}
							} else {
								attendeeTemp = {
									email: this.emailAttendee,
								};
								this.attendeesList.push(attendeeTemp);
							}
							this.emailAttendee = '';
						} else {
							this.notif.addErrorNotif('Attendee.NotValidMail');
						}
					}
					e.preventDefault();
				} else {
					this.emailAttendee = '';
					e.preventDefault();
				}
			} else if (key === 'Backspace' && code === 'Backspace' && !this.emailAttendee && this.attendeesList && this.attendeesList?.length > 0) {
				this.emailAttendee = this.attendeesList[this.attendeesList?.length - 1].email;
				this.attendeesList.pop();
				e.preventDefault();
			}
		}
	}

	checkContact(): void {
		if (this.emailAttendee !== '' && this.emailAttendee) {
			this.contactService.getSuggest(this.emailAttendee).subscribe({
				next: (contactsSuggest) => {
					this.contactsSuggest = contactsSuggest;
					let l = this.contactsSuggest?.length;
					while (l--) {
						if (!this.contactsSuggest[l]['email']) {
							this.contactsSuggest.splice(l, 1);
						}
						for (const a in this.attendeesList) {
							if (this.attendeesList[a] && this.contactsSuggest[l]) {
								if (this.attendeesList[a].email === this.contactsSuggest[l]['email']) {
									this.contactsSuggest.splice(l, 1);
								}
							}
						}
					}
					if (this.contactsSuggest?.length > 0) {
						this.displaySuggest = true;
					}
				},
				error: (error) => {
					debug('%O', error);
					this.notif.addErrorNotif('Contact.Error.Get');
				}
			});
		} else {
			this.displaySuggest = false;
		}
	}

	addContactToAttendee(contact: Contact): void {
		let attendeeTemp;
		if (this.attendeesList?.length > 0) {
			if (this.attendeesList.some((el) => el.email === contact.email)) {
				let msg = contact.email;
				msg += ' ';
				msg += this.translateService.instant('Attendee.AlreadyInList');
				this.notif.addErrorNotif(msg);
			} else {
				attendeeTemp = {
					email: contact.email,
				};
				this.attendeesList.push(attendeeTemp);
			}
		} else {
			attendeeTemp = {
				email: contact.email,
			};
			this.attendeesList.push(attendeeTemp);
		}
		this.emailAttendee = '';
		this.displaySuggest = false;
	}

	addAttendeeFromSubmit(data: any): void {
		let split;
		if (this.emailAttendee && this.emailAttendee?.length > 0) {
			if (!this.attendeesList.find((a) => a.email === this.emailAttendee)) {
				if (this.emailAttendee.indexOf(',') !== -1) {
					split = ',';
					this.attendeeLoop(this.emailAttendee, split);
				} else if (this.emailAttendee.indexOf(';') !== -1) {
					split = ';';
					this.attendeeLoop(this.emailAttendee, split);
				} else if (this.emailAttendee.indexOf(' ') !== -1) {
					split = ' ';
					this.attendeeLoop(this.emailAttendee, split);
				} else {
					if (this.validatorService.validateEmail(this.emailAttendee)) {
						const attendeeTemp = {
							email: this.emailAttendee,
						};
						this.attendeesList.push(attendeeTemp);
						data.attendeesEmailOk = true;
						this.checkEmailDone.emit(data);
					} else {
						this.notif.addErrorNotif('Attendee.NotValidMail');
						data.attendeesEmailOk = false;
						this.checkEmailDone.emit(data);
					}
				}
			} else {
				data.attendeesEmailOk = true;
				this.checkEmailDone.emit(data);
			}
		} else {
			data.attendeesEmailOk = true;
			this.checkEmailDone.emit(data);
		}
	}

	attendeeLoop(emailList: string, split: string): void {
		let list: any[];
		if (emailList.endsWith(split)) {
			emailList = emailList.substr(0, emailList?.length - 1);
		}
		list = emailList.split(split);
		for (const j in list) {
			list[j] = list[j].trim();
		}
		// set only allow unique value, all duplicates will be removes
		// spread operatror... convert set back to an array
		list = [...new Set(list)];
		let l = list?.length;
		while (l--) {
			if (this.validatorService.validateEmail(list[l])) {
				if (this.attendeesList.some((el) => el.email === list[l])) {
					let msg = list[l];
					msg += ' ';
					msg += this.translateService.instant('Attendee.AlreadyInList');
					this.notif.addErrorNotif(msg);
				} else {
					const attendeeTemp = {
						email: list[l],
					};
					this.attendeesList.push(attendeeTemp);
					list.splice(l, 1);
				}
			} else {
				this.notif.addErrorNotif('Attendee.NotValidMail');
			}
		}
		if (list?.length > 0) {
			this.emailAttendee = '';
			for (const i in list) {
				this.emailAttendee += list[i];
				this.emailAttendee += split;
			}
		} else {
			this.emailAttendee = '';
		}
	}

	onSelectContactEvent(contact: Contact): void {
		this.emailAttendee = contact.email;
		this.addContactToAttendee(contact);
	}

	onCloseSuggestEvent(value: boolean): void {
		this.displaySuggest = value;
	}
}

