import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-input-form-field',
  templateUrl: './input-form-field.component.html',
  styleUrls: ['./input-form-field.component.scss'],
})
export class InputFormFieldComponent implements AfterViewInit {
  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() textLabel = '';
  @Input() typeInput = '';
  @Input() nameInput = '';
  @Input() form!: NgForm;
  @Input() targetValidators!: ValidatorFn[];
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() minNumber = 0;
  @Input() maxNumber: number;
  @Input() light = false;
  @Input() myInputModel!: unknown;
  @Output() blurEvent = new EventEmitter<Event>();
  @Output() modelChangeEvent = new EventEmitter<any>();
  focused = false;

  ngAfterViewInit(): void {
    if (!this.form.getControl(this.inputModel)) {
      this.form.addControl(this.inputModel);
      if (this.targetValidators) {
        setTimeout(() => {
          this.form.controls[this.nameInput].setValidators(
            this.targetValidators
          );
        }, 100);
      }
    }
  }

  onChange(): void {
    this.modelChangeEvent.emit(this.myInputModel);
  }

  onBlur(event: Event): void {
    this.blurEvent.emit(event);
  }
}
