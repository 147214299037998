import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { Group } from 'src/app/groups/group.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { User } from 'src/app/users/user.model';
import { Tenant } from '../tenant.model';
import { SyncTenantService } from './sync-tenant.service';
import Debug from 'debug';
const debug = Debug('virtuvisio-administration:sync-tenant-component');

@Component({
  selector: 'app-sync-tenant',
  templateUrl: './sync-tenant.component.html',
  styleUrls: ['./sync-tenant.component.scss'],
  providers: [SyncTenantService]
})
export class SyncTenantComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  connectedUser: ConnectedUser;
  tenant$: Observable<Tenant>;

  currentSync: SyncStatus;
  showLoaderSync = false;
  gotErrorSync = false;
  syncedGroups: SyncedGroup[] = [];
  showDetails = false;
  error: any;
  syncDone = false;
  alreadySync = false;

  constructor(
    private route: ActivatedRoute,
    private syncService: SyncTenantService,
    private sharedService: SharedService,
    private notif: NotifService
  ) { }

  ngOnInit(): void {
    this.onInitSubscriptions.push(
      this.sharedService.currentConnectedUser.subscribe(
        (connectedUser: ConnectedUser) => {
          if (connectedUser) {
            this.connectedUser = connectedUser;
          }
        }
      )
    );
    if (this.route.parent) {
      this.onInitSubscriptions.push(
        this.route.parent.paramMap.subscribe((params) => {
          if (params.get('tenantId')) {
            this.getTenant(params.get('tenantId') as string);
          }
        })
      );
    }
  }

  getTenant(tenantId: string): void {
    this.tenant$ = this.syncService.getTenant(tenantId).pipe(
      catchError(this.handleError<Tenant>('getTenant')),
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );
  }

  getSyncStatus(tenant: Tenant): void {
    this.showLoaderSync = true;
    this.alreadySync = false;
    this.syncService.syncStatus(tenant).subscribe({
      next: (result) => {
        if (Object.keys(result).length > 0) {
          for (let status in result) {
            if (status === tenant.id) {
              this.currentSync = result[status];
              this.alreadySync = true;
              this.showLoaderSync = false;
            } else {
              this.syncTenant(tenant);
            }
          }
        } else {
          this.syncTenant(tenant);
        }
      },
      error: (error) => {
        this.notif.addErrorNotif('SyncTenantComponent.StatusError', error);
      }
    });
  }

  syncTenant(tenant: Tenant): void {
    this.syncedGroups = [];
    this.gotErrorSync = false;
    this.syncDone = false;
    this.syncService.sync(tenant).subscribe({
      next: (result) => {
        this.notif.addSuccessNotif('SyncTenantComponent.SUCCESS');
        this.showLoaderSync = false;
        this.syncDone = true;
        this.gotErrorSync = false;
        this.syncedGroups = this.getGroupName(result.groups, tenant);
        this.organizeData();
      },
      error: (error) => {
        this.notif.addErrorNotif('SyncTenantComponent.ERROR', error);
        this.showLoaderSync = false;
        this.syncDone = false;
        this.gotErrorSync = true;
        this.error = error;
      }
    });
  }

  unlockSync(tenant: Tenant): void {
    this.alreadySync = false;
    this.showLoaderSync = true;
    this.syncService.unlock(tenant).subscribe({
      next: (result) => {
        debug(result);
        this.showLoaderSync = false;
        this.notif.addSuccessNotif('SyncTenantComponent.Unlock.SUCCESS');
      },
      error: (error) => {
        this.showLoaderSync = false;
        this.notif.addErrorNotif('SyncTenantComponent.Unlock.ERROR', error);
      }
    });
  }

  getGroupName(fullGroups: SyncedGroup[], tenant: Tenant): SyncedGroup[] {
    for (const g in fullGroups) {
      fullGroups[g].id = g;
      this.syncService.getGroup(tenant, g).subscribe((group: Group) => {
        fullGroups[g].group = group;
      });
      this.syncedGroups.push(fullGroups[g]);
    }
    return this.syncedGroups;
  }

  organizeData(): void {
    for (const s in this.syncedGroups) {
      if (this.syncedGroups[s].newUsers?.length > 0) {
        for (const nU in this.syncedGroups[s].newUsers) {
          this.syncedGroups[s].newUsers[nU] = new User(
            this.syncedGroups[s].newUsers[nU]
          );
        }
      }
      if (this.syncedGroups[s].updatedUsers?.length > 0) {
        for (const uU in this.syncedGroups[s].updatedUsers) {
          this.syncedGroups[s].updatedUsers[uU].user = new User(
            this.syncedGroups[s].updatedUsers[uU].user
          );
        }
      }
      if (this.syncedGroups[s].deletedUsers?.length > 0) {
        for (const dU in this.syncedGroups[s].deletedUsers) {
          this.syncedGroups[s].deletedUsers[dU] = new User(
            this.syncedGroups[s].deletedUsers[dU]
          );
        }
      }
      if (this.syncedGroups[s].addedToGroup?.length > 0) {
        for (const aG in this.syncedGroups[s].addedToGroup) {
          this.syncedGroups[s].addedToGroup[aG] = new User(
            this.syncedGroups[s].addedToGroup[aG]
          );
        }
      }
      if (this.syncedGroups[s].removedFromGroup?.length > 0) {
        for (const rG in this.syncedGroups[s].removedFromGroup) {
          this.syncedGroups[s].removedFromGroup[rG] = new User(
            this.syncedGroups[s].removedFromGroup[rG]
          );
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}

interface SyncStatus {
  dispatcher: any;
  statusData: {
    result: string;
  };
}

interface SyncedGroup {
  newUsers: User[];
  deletedUsers: User[];
  updatedUsers: SyncedUpdateUser[];
  addedToGroup: User[];
  removedFromGroup: User[];
  error: string;
  errorDetails: string;
  group: Group;
  id: string;
}

interface SyncedUpdateUser {
  fields: Field[];
  user: User;
}

interface Field {
  key: string;
  old: string;
  new: string;
}
