import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Actions, Controls, ControlType } from './toolbar.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input() needDetails: boolean;
  @Input() blocControls: Controls[];
  @Output() requestAction = new EventEmitter<string>();
  @Output() switchChange = new EventEmitter<boolean>();
  Type = ControlType;
  actions = Actions;

  constructor() { }

  onSwitchChange(event: boolean) {
    this.switchChange.emit(event);
  }

  doAction(action?: string): void {
    this.requestAction.emit(action);
  }
}
