import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isoDateToDate',
  pure: false,
})
export class IsoDateToDate implements PipeTransform {

  transform(value: any): Date | [] {
    if (!value) {
      return [];
    }

    const date = new Date(value);

    return date;
  }
}
