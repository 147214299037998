import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActionEvent, DisplayColumn } from 'src/app/commons/mat-table-custom/mat-table-custom.model';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { Tenant } from 'src/app/tenant/tenant.model';
import { ConnectedUser } from 'src/app/users/user-connected/user.connected.model';
import { User } from '../user.model';
import { UsersListService } from './users-list.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [UsersListService],
})
export class UsersListComponent implements OnInit, OnDestroy {
  private onInitSubscriptions: Subscription[] = [];
  @Input() connectedUser: ConnectedUser;
  @Input() tenant: Tenant;
  reload = false;
  defaultSort = '';
  longDisplay: DisplayColumn[] = [
    { name: 'displayName', type: 'any' },
    { name: 'email', type: 'any' },
    { name: 'allowedOffers', type: 'any' },
    { name: 'lastAccess', type: 'date' },
  ];
  shortDisplay: DisplayColumn[] = [
    { name: 'displayName', type: 'any' },
    { name: 'email', type: 'any' },
  ];
  displayedColumns: DisplayColumn[];
  selectedUser: User;

  constructor(
    public userService: UsersListService,
    private router: Router,
    private route: ActivatedRoute,
    private notif: NotifService
  ) { }

  ngOnInit(): void {

    this.displayedColumns = this.longDisplay;
    if (this.connectedUser.canEdit(this.tenant.id)) {
      if (this.tenant.authProvider !== 'microsoft') {
        this.displayedColumns.push({
          name: 'action',
          type: 'action',
          actions: [
            { action: 'edit', icon: 'edit' },
            { action: 'delete', icon: 'trash-alt' },
          ],
        });
      }
    }
    this.onInitSubscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event.url.includes('/view') && !event.url.includes('/edit') && !event.url.includes('/edit-password')) {
          this.selectedUser = null as any;
        }
      };
    }))
    if (this.route.firstChild) {
      this.onInitSubscriptions.push(this.route.firstChild.params.subscribe(params => {
        if (params['userId']) {
          this.selectedUser = new User({ id: params['userId'] });
        }
      }));
    }
  }

  columnToDisplay(size: 'short' | 'full'): void {
    if (size === 'short') {
      this.displayedColumns = this.shortDisplay;
    } else {
      this.displayedColumns = this.longDisplay;
      if (this.connectedUser.canEdit(this.tenant.id)) {
        this.displayedColumns.push({
          name: 'action',
          type: 'action',
          actions: [
            { action: 'edit', icon: 'edit' },
            { action: 'delete', icon: 'trash-alt' },
          ],
        });
      }
    }
  }

  onRowClick(event: User): void {
    this.selectedUser = event;
    this.router.navigate(['/administration/' + this.selectedUser.tenantId + '/users/view/' + this.selectedUser.id], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve',
    });
  }

  onActionClick(data: ActionEvent): void {
    this.selectedUser = data.data;
    switch (data.action.action) {
      case 'view': {
        this.router.navigate(['/administration/' + this.selectedUser.tenantId + '/users/view/' + this.selectedUser.id], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve',
        });
        break;
      }
      case 'edit': {
        this.router.navigate(['/administration/' + this.selectedUser.tenantId + '/users/edit/' + this.selectedUser.id], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve',
        });
        break;
      }
      case 'delete': {
        $('#modalOnDeleteUserFromList').modal('show');
        break;
      }
      default: {
        break;
      }
    }
  }

  onDataError(error: any): void {
    console.error(error);
  }

  confirmDeleteUser(user: User): void {
    this.reload = true;
    this.userService.delete(user).subscribe({
      next: (result) => {
        this.reload = false;
        this.notif.addSuccessNotif('UsersListComponent.Modal.Delete.SUCCESS');
      },
      error: (error) => {
        this.reload = false;
        this.notif.addErrorNotif('UsersListComponent.Modal.Delete.ERROR', error);
      }
    });
  }

  ngOnDestroy(): void {
    this.onInitSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
