<div class="row align-items-center p-3">
    <div class="col-auto">
        <h3 class="m-0">
            <span class="is-bold">{{"HistoryPolicyRejects.HistoryPolicyRejects" | translate}}</span>
        </h3>
    </div>
</div>
<app-admin-card [hidden]="selectedPolicyReject">
    <div id="matTableRejectPolicy" class="row">
        <div class="col-12">
            <div class="row mb-2">
                <div class="col-12">
                    <app-calendar-picker-double [labelFrom]="'HistoryPolicyRejects.StartDate'" [labelTo]="'HistoryPolicyRejects.EndDate'"></app-calendar-picker-double>
                </div>
            </div>
            <app-mat-table-custom *ngIf="displayedColumns && historicPolicyRejectsService" [componentName]="'HistoryPolicyRejects'" [displayedColumns]="displayedColumns" [dataService]="historicPolicyRejectsService" [defaultSort]="defaultSort" (dataError)="onDataError($event)" (rowClick)="onRowClick($event)"></app-mat-table-custom>
        </div>
    </div>
</app-admin-card>
<app-admin-bloc [blocTitle]="'HistoryPolicyRejects.DetailsPolicyReject'" [blocControls]="controls" (requestAction)="closeAction($event)" *ngIf="selectedPolicyReject">
    <app-admin-details-bloc [closeControl]="false">
        <div id="selectedPolicyReject" class="row">
            <div class="col-6">
                <app-admin-border-bloc>
                    <div class="align-center is-bold mb-3">{{"HistoryPolicyRejects.GeneralInformations" | translate }}</div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"HistoryPolicyRejects.Date" | translate}} :</div>
                        <div class="col">{{selectedPolicyReject.date}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"HistoryPolicyRejects.Status" | translate}} :</div>
                        <div class="col">{{selectedPolicyReject.status.code}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"HistoryPolicyRejects.Error" | translate}} :</div>
                        <div class="col">{{selectedPolicyReject.status.description}}</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"HistoryPolicyRejects.ResolutionDuration" | translate}} :</div>
                        <div class="col">{{selectedPolicyReject.resolutionDuration/1000000000}} ({{"seconds" | translate}})</div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-auto is-bold pe-0">{{"HistoryPolicyRejects.TenantId" | translate}} :</div>
                        <div class="col">{{selectedPolicyReject.tenantId}}</div>
                    </div>
                </app-admin-border-bloc>
            </div>
            <div class="col-6">
                <app-admin-border-bloc>
                    <div class="align-center is-bold mb-3">{{"HistoryPolicyRejects.Request" | translate }}</div>
                    <div *ngIf="selectedPolicyReject.request">
                        <div class="row align-items-center" *ngFor="let request of selectedPolicyReject.request | keyvalue">
                            <div class="col-auto is-bold pe-0">{{request.key}} :</div>
                            <div class="col">{{request.value}}</div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!selectedPolicyReject.request">
                        <div class="col-12">
                            {{"HistoryPolicyRejects.NoRequestData" | translate}}
                        </div>
                    </div>
                </app-admin-border-bloc>
            </div>
        </div>
    </app-admin-details-bloc>
</app-admin-bloc>
