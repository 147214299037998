import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel, ValidatorFn } from '@angular/forms';
import Debug from 'debug';
const debug = Debug('virtuvisio-administration:radioform-field-component');

@Component({
  selector: 'app-radio-form-field',
  templateUrl: './radio-form-field.component.html',
  styleUrls: ['./radio-form-field.component.scss'],
})
export class RadioFormFieldComponent {
  @ViewChild('inputModel') inputModel!: NgModel;
  @Input() nameInput = '';
  @Input() form!: NgForm;
  @Input() targetValidators!: ValidatorFn[];
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() checked = false;
  @Input() myInputModel!: unknown;
  @Input() listValues: RadioValue[];
  @Output() checkedEvent = new EventEmitter<Event>();

  changeRadio(ev: Event): void {
    const r = ev.target as HTMLInputElement;
    this.myInputModel = r.value;
    this.checkedEvent.emit(ev);
  }
}

interface RadioValue {
  label: string;
  value: string;
}
