import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
	selector: '[appMinMask]',
	providers: [{ provide: NG_VALIDATORS, useExisting: MinMaskValidatorDirective, multi: true }],
})
export class MinMaskValidatorDirective implements Validator {
	@Input('appMinMask') minimumMask!: number;
	
	validate(control: AbstractControl): { [key: string]: any } | null {
		return this.minimumMask ? minMaskValidator(this.minimumMask)(control) : null;
	}
}

export function minMaskValidator(min: number): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const forbidden = control.value < min;
		return forbidden ? { minimumMask: { value: control.value } } : null;
	};
}
