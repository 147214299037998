import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Tenant } from 'src/app/tenant/tenant.model';
import { OauthClientsService } from '../oauth-clients.service';
import { OauthClient, ClientAccess, ClientModes, ClientReadAccess, ClientReadWriteAccess } from "../oauth-client.model";
import { ActivatedRoute, Router } from '@angular/router';
import { NotifService } from 'src/app/services/notifs/notif.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-oauth-client',
  templateUrl: './edit-oauth-client.component.html',
  styleUrls: ['./edit-oauth-client.component.scss']
})
export class EditOauthClientComponent implements OnInit, OnDestroy {
  currentOauthClient = new OauthClient({});
  finished = false;
  hasError = false;
  tenants: Tenant[];
  modes = ClientModes;
  mode = "Read";
  accessList = ClientAccess;
  clientAccesses: string[] = [];
  loading = false;
  paramSubscription: Subscription;
  constructor(private oauthClientsService: OauthClientsService, private router: Router, private route: ActivatedRoute, private notifService: NotifService) { }

  ngOnInit(): void {
    this.getTenants();
    this.paramSubscription = this.route.paramMap.subscribe(params => {
      let clientId = params.get('clientId');
      if (clientId) {
        this.getClient(clientId);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  getClient(clientId: string): void {
    this.oauthClientsService.getOauthClient(clientId).subscribe({
      next: (result) => {
        this.currentOauthClient = result;
        const jsonObject = this.currentOauthClient.scopes;
        const map = new Map<string, string[]>();
        for (const value in jsonObject) {
          map.set(value, jsonObject[value]);
        }
        this.currentOauthClient.scopes = map;
      },
      error: (error: HttpErrorResponse) => {
        console.log('Error getting oauth client');
      }
    })
  }

  confirmEditOauthClient(editOauthClient: NgForm): void {
    if (editOauthClient.valid) {
      let client = this.currentOauthClient;
      client.name = editOauthClient.value.name;
      client.description = editOauthClient.value.description;
      client.secret = editOauthClient.value.secret;
      const jsonObject: any = {};
      client.scopes.forEach((value: string, key: string) => {
        if (value.length > 0) {
          jsonObject[key] = value;
        }
      });
      client.scopes = jsonObject;
      this.hasError = false;
      this.oauthClientsService.save(client).subscribe({
        next: (result) => {
          this.finished = true;
          this.notifService.addSuccessNotif('EditOauthClientComponent.SuccessEditOauthClient');
          this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: (error) => {
          this.finished = true;
          this.hasError = true;
          this.notifService.addErrorNotif('EditOauthClientComponent.ErrorEditOauthClient', error);
        }
      })
    } else {
      this.hasError = true;
    }
  }

  getTenants(): void {
    this.oauthClientsService.getTenants().subscribe({
      next: (result) => {
        this.tenants = result;
        const allTenants: Tenant = new Tenant({});
        allTenants.name = "EditOauthClientComponent.AllTenants";
        allTenants.id = "*";
        this.tenants.unshift(allTenants);
      },
      error: (error: HttpErrorResponse) => {
        console.log("Error getting tenants");
      }
    });
  }

  switchTarget(target: string): void {
    if (this.currentOauthClient.scopes.has(target)) {
      this.clientAccesses = this.currentOauthClient.scopes.get(target);
    } else {
      this.clientAccesses = [];
    }
  }

  switchAccess(event: boolean, access: string): void {
    if (event) {
      if (!this.clientAccesses.includes(access)) {
        this.clientAccesses.push(access + "/Read");
      }
    } else {
      if (this.clientAccesses.includes(access + "/Read")) {
        this.clientAccesses.splice(this.clientAccesses.indexOf(access + "/Read"), 1);
      } else if (this.clientAccesses.includes(access + "/ReadWrite")) {
        this.clientAccesses.splice(this.clientAccesses.indexOf(access + "/ReadWrite"), 1);
      }
    }
    this.currentOauthClient.scopes.set(this.currentOauthClient.target, this.clientAccesses);
  }

  switchReadAccess(event: boolean, access: string): void {
    if (this.clientAccesses.includes(access + "/ReadWrite")) {
      this.clientAccesses.splice(this.clientAccesses.indexOf(access + "/ReadWrite"), 1);
    }
    if (!this.clientAccesses.includes(access + "/Read")) {
      this.clientAccesses.push(access + "/Read");
    }
    this.currentOauthClient.scopes.set(this.currentOauthClient.target, this.clientAccesses);
  }

  switchReadWriteAccess(event: boolean, access: string): void {
    if (this.clientAccesses.includes(access + "/Read")) {
      this.clientAccesses.splice(this.clientAccesses.indexOf(access + "/Read"), 1);
    }
    if (!this.clientAccesses.includes(access + "/ReadWrite")) {
      this.clientAccesses.push(access + "/ReadWrite");
    }
    this.currentOauthClient.scopes.set(this.currentOauthClient.target, this.clientAccesses);
  }

  swicthAllReadAccess(): void {
    this.clientAccesses = [...ClientReadAccess];
    this.currentOauthClient.scopes.set(this.currentOauthClient.target, this.clientAccesses);
  }

  swicthAllReadWriteAccess(): void {
    this.clientAccesses = [...ClientReadWriteAccess];
    this.currentOauthClient.scopes.set(this.currentOauthClient.target, this.clientAccesses);
  }

  removeAllAccesses(): void {
    for (let access of this.accessList) {
      this.switchAccess(false, access);
    }
  }

  isCheckedAccess(access: string): boolean {
    if (this.clientAccesses.includes(access)) {
      return true;
    }
    return false
  }

  selectTenant(tenant: Tenant): void {
    this.currentOauthClient.target = tenant.id;
    this.switchTarget(this.currentOauthClient.target);
  }

}
