import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { RequestFilterBuilder } from "src/app/services/request-filter-builder/request-filter-builder";
import { suggestPattern } from "src/app/operators/mongo-operators/mongo-operators";
import { DatesManager } from "src/app/services/dates/dates.manager";
import { ActivatedRoute } from "@angular/router";
import { PaginationInfo } from "../commons/mat-table-custom/mat-table-custom.model";
import { DataApiOptionService } from "../services/data-api-option/data-api-option.service";
import { CustomHttpParamEncoderService } from "../services/http-encoder/custom-http-param-encoder.service";
import { Group } from "./group.model";

@Injectable()
export class GroupsService {
  tenantId: Observable<string> = of("");

  constructor(private http: HttpClient, private optionService: DataApiOptionService, private route: ActivatedRoute, private datesManager: DatesManager) {
    this.tenantId = <Observable<string>>this.route.parent?.paramMap.pipe(map((params) => params.get("tenantId")));
  }

  buildParamsUrl(paginationInfo: PaginationInfo, moreParams?: string[]): HttpParams {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() });
    if (moreParams) {
      for (let p in moreParams) {
        let customParam = <string>paginationInfo[<keyof PaginationInfo>moreParams[p]];
        params = params.set(moreParams[p], customParam);
      }
    }
    const filter = new RequestFilterBuilder();
    if (paginationInfo.suggest) {
      filter.set("suggests", suggestPattern(paginationInfo.suggest));
    }
    const q = filter.serialize();
    return (params = params.set("q", q));
  }

  getHead(paginationInfo: PaginationInfo): Observable<number> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            const params = this.buildParamsUrl(paginationInfo);
            return this.http.head(apiUri + "/groups", {
              params: params,
              headers: this.optionService.getHeaders(),
              observe: "response",
              withCredentials: true,
            }).pipe(map((result) => parseInt(<string>result.headers.get("Count"))));
          })
          );
      })
      )
  }

  getData(paginationInfo: PaginationInfo): Observable<Group[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            const params = this.buildParamsUrl(paginationInfo, ["sort", "order", "skip", "limit"]);
            return this.http.get<Group[]>(apiUri + "/groups", {
              params: params,
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            })
          })
          );
      })
      );
  }

  save(group: Group): Observable<Group> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.put<Group>(apiUri + "/groups/"+group.id, group, {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            })
          })
          );
      })
      );
  }

  delete(group: Group): Observable<unknown> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.delete<unknown>(apiUri + "/groups/"+group.id, {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            })
          })
          );
      })
      );
  }

  getGroups(): Observable<Group[]> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.get<Group[]>(apiUri + "/groups", {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            })
          })
          );
      })
      );
  }

  getGroup(groupId: string): Observable<Group> {
    return this.tenantId.pipe(
      switchMap((tenantId) => {
        return this.optionService.getApiUrl(tenantId).pipe(
          switchMap((apiUri) => {
            return this.http.get<Group>(apiUri + "/groups/"+groupId, {
              headers: this.optionService.getHeaders(),
              withCredentials: true,
            })
          })
          );
      })
      );
  }

}
