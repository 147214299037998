import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { DataApiOptionService } from 'src/app/services/data-api-option/data-api-option.service';
import { CustomHttpParamEncoderService } from 'src/app/services/http-encoder/custom-http-param-encoder.service';

@Injectable()
export class StatsUsageService {

  constructor(private http: HttpClient, private optionService: DataApiOptionService) { }

  getUsage(from: Date, to: Date) {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoderService() })
    params = params.set('from', from.toISOString())
    params = params.set('to', to.toISOString())
    return this.optionService.getApiUrl().pipe(
      switchMap((apiUri) => {
        return this.http
          .get<any>(apiUri + '/stats/origin', {
            params: params,
            headers: this.optionService.getHeaders(),
            withCredentials: true,
            observe: 'body',
          })
          .pipe(
            map((result) => result),
            catchError(this.handleError<any>('getSpecificUsage', null))
          );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.log(operation + ' failed !');
      console.error(error);
      if (result) {
        // Let the app keep running by returning an empty result.
        return of(result as T);
      } else {
        // or throw error
        return throwError(() => error);
      }
    };
  }
}
