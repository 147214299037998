// définition des formats natives
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
//
//
// définition des formats moments locales
// https://momentjs.com/docs/#/displaying/format/

export const KloodDateFormat: LocalFormat = {
  display: {
    'fr-FR': 'dd-MM-YYYY H:mm',
    'en-US': 'MM-dd-YYYY h:mm a',
  },
  longDisplay: {
    'fr-FR': 'dd MMMM YYYY H:mm',
    'en-US': 'MMMM dd YYYY h:mm a',
  },
  pipe: {
    'fr-FR': 'EEEE dd LLLL yyyy H:mm',
    'en-US': 'EEEE LLLL dd yyyy h:mm a',
  },
  pipeSeconds: {
    'fr-FR': 'EEEE dd LLLL yyyy H:mm:ss',
    'en-US': 'EEEE LLLL dd yyyy h:mm:ss a',
  },
  offerPipe: {
    'fr-FR': 'dd LLLL yyyy',
    'en-US': 'LLLL dd yyyy',
  },
  pipeShort: {
    'fr-FR': 'H:mm',
    'en-US': 'H:mm a',
  },
  locale: {
    'fr-FR': 'fr-FR',
    'en-US': 'en-US',
  },
  adapterLocale: {
    'fr-FR': 'fr',
    'en-US': 'en',
  },
};

interface LocalFormat {
  display: {
    'fr-FR': string;
    'en-US': string;
    [key: string]: string;
  };
  longDisplay: {
    'fr-FR': string;
    'en-US': string;
    [key: string]: string;
  };
  pipe: {
    'fr-FR': string;
    'en-US': string;
    [key: string]: string;
  };
  pipeSeconds: {
    'fr-FR': string;
    'en-US': string;
    [key: string]: string;
  };
  offerPipe: {
    'fr-FR': string;
    'en-US': string;
    [key: string]: string;
  };
  pipeShort: {
    'fr-FR': string;
    'en-US': string;
    [key: string]: string;
  };
  locale: {
    'fr-FR': string;
    'en-US': string;
    [key: string]: string;
  };
  adapterLocale: {
    'fr-FR': string;
    'en-US': string;
    [key: string]: string;
  };
}
